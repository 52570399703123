import { Stack } from '@mui/material';
import { CardTeamId } from 'design/Cards/CardTeamId';
import { Text } from 'design/Text';
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const TeamId = () => {
  const location = useLocation<any>();

  const { publicProfile } = useAppSelector(({ publicProfile }) => ({
    publicProfile: publicProfile?.publicProfile,
  }));

  return publicProfile && publicProfile?.teams?.length > 0 ? (
    <CardTeamId
      gameName={publicProfile?.teams[0].game}
      teamLogo={publicProfile?.teams[0].avatarUrl}
      teamName={publicProfile?.teams[0].name}
      link={{
        pathname: `/team/${publicProfile?.teams[0].gameSlug}/${publicProfile?.teams[0].schoolSlug}/${publicProfile?.teams[0].urlSlug}`,
        state: {
          pathname: location.pathname,
        },
      }}
    />
  ) : (
    <Stack alignItems="center" sx={{ p: '16px 12px' }}>
      <Text sx={{ color: 'neutral400' }}>Pemain belum punya Tim</Text>
    </Stack>
  );
};

export default memo(TeamId);
