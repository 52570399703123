export const ISO_FORMAT = 'YYYY-MM-DD';
export const ISO_MONTH = 'YYYY-MM';
export const APP_DATE_FORMAT = 'DD MMM YYYY';
export const APP_DATE_WITH_DAYNAME_FORMAT = 'ddd, DD MMM YYYY';
export const ISO_TIME_FORMAT = 'HH:mm';
export const DMY_FORMAT = 'DD/MM/YYYY';
export const MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const EVERYDAY = 'Everyday';
export const WEEKEND = 'Weekend';
export const WEEKDAY = 'Weekday';
