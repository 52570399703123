import React, { FC, ReactNode } from 'react';
import {
  Card,
  HeaderCard,
  BorderDate,
  HeaderTitle,
  TextTeam,
  TextTeam1,
  Score1,
  Score2,
  BorderVs,
} from './styles';
import { Row, Column } from '../MatchUpload/styles';
import { TextView } from 'components/TextView/TextView';

export interface MatchResultProps {
  text?: ReactNode;
  header?: ReactNode;
  team1?: ReactNode;
  team2?: ReactNode;
  team1Avatar?: string;
  team2Avatar?: string | 'hide';
  score?: ReactNode;
  score1: ReactNode;
  disable?: boolean | undefined;
  disable1?: boolean | undefined;
  containerWidth?: string;
  //style
  bg?:
    | 'rgba(55, 57, 66, 0.6)'
    | 'linear-gradient(180deg, rgba(213, 31, 64, 0.3) 0%, rgba(213, 31, 64, 0.06) 100%)'
    | 'linear-gradient(180deg, rgba(49, 193, 81, 0.3) 0%, rgba(49, 193, 81, 0.06) 100%)';
  border?: '4px solid rgba(213, 31, 64, 0.3)' | '0' | '4px solid rgba(231, 180, 80, 0.3)';
}

// export const MatchUploadContainer: FC<MatchUploadBgProps> = ({frameMt= '0px', bg, h, w, children}) => (
//     <Card frameMt={frameMt} bg={bg} h={h} w={w}>
//       {children}

//     </Card>
//   );

export const CardMatchResult: FC<MatchResultProps> = ({
  // onClick,
  score,
  header,
  team1,
  team1Avatar,
  text,
  team2,
  team2Avatar,
  score1,
  bg,
  border,
  disable,
  disable1,
  containerWidth
}) => (
  <Card bg={bg} border={border} score1={score1} containerWidth={containerWidth}>
    <HeaderCard>
      <HeaderTitle>{header}</HeaderTitle>
    </HeaderCard>
    <Row>
      <Column>
        <TextTeam style={{ opacity: disable ? '0.3' : '1.0' }}>
          {team1}
          <img
            src={team1Avatar ?? `${process.env.PUBLIC_URL}/media/teamProfile/LogoTeam.svg`}
            style={{
              position: 'absolute',
              border: '2px solid #F1EBEB',
              boxSizing: 'border-box',
              borderRadius: '12px',
              left: '80%',
              top: '-3px',
              width: '25px',
              height: '25px',
            }}
            alt=""
          />
        </TextTeam>
        <Score1>{score}</Score1>
      </Column>
      <Column>
        <TextTeam1 style={{ opacity: disable1 ? '0.3' : '1.0' }}>
          {team2}
          {team2Avatar !== 'hide' ? (
            <img
              src={team2Avatar ?? `${process.env.PUBLIC_URL}/media/teamProfile/LogoTeam.svg`}
              style={{
                position: 'absolute',
                border: '2px solid #F1EBEB',
                boxSizing: 'border-box',
                borderRadius: '12px',
                right: '80%',
                top: '-3px',
                width: '25px',
                height: '25px',
              }}
              alt=""
            />
          ) : (
            ''
          )}
        </TextTeam1>
        <Score2>{score1}</Score2>
      </Column>
      <BorderVs>
        <TextView onClick={() => {}} tSize="14px" tStyle="normal" tWeight="bold" variant="Gradient">
          VS
        </TextView>
      </BorderVs>
      <BorderDate>
        <HeaderTitle>{text}</HeaderTitle>
      </BorderDate>
    </Row>
  </Card>
);

export default CardMatchResult;
