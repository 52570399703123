import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import { FluentValidationProblemDetails } from './TeamInvitationReducer';

export interface TeamDetailResponse {
  id: string;
  name: string;
  urlSlug: string;
  avatarUrl: string;
  openRecruitment: boolean;
  whatsAppGroupUrl: string;
  whatsAppPhoneNumber: string;
  discordUrl: string;
  disband: boolean;
  blocked: boolean;
  blockMessage: string;
  game: TeamChildItem;
  school: TeamChildItem;
  memberCount: number;
  matchCount: number;
  createBy: string;
  createAt: Date;
  modifyBy: string;
  modifyAt: Date;
}

export interface TeamChildItem {
  id: string;
  name: string;
  urlSlug: string;
}

export interface TeamDetailState {
  data: TeamDetailResponse;
  loading: boolean;
  errors: FluentValidationProblemDetails;
  errorCode: number | any;
}

interface TeamDetailError {
  isAxiosError: boolean;
  errors: any;
}

export const fetchTeamDetail = createAsyncThunk(
  'TeamDetail/team-profile',
  async ({ teamSlug, version }: { teamSlug: string; version?: string }) => {
    try {
      const url = `/${version ? `v${version}/` : ''}team/${teamSlug}`;
      const res = await ApiService.get<TeamDetailResponse>(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, errors: error.response.data } as TeamDetailError;
      } else {
        return { isAxiosError: true, errors: error } as TeamDetailError;
      }
    }
  },
);

const initialState: TeamDetailState = {
  data: {
    id: '',
    name: '',
    urlSlug: '',
    avatarUrl: '',
    openRecruitment: false,
    whatsAppGroupUrl: '',
    whatsAppPhoneNumber: '',
    discordUrl: '',
    disband: false,
    blocked: false,
    blockMessage: '',
    game: { id: '', name: '', urlSlug: '' },
    school: { id: '', name: '', urlSlug: '' },
    memberCount: 0,
    matchCount: 0,
    createBy: '',
    createAt: new Date(),
    modifyBy: '',
    modifyAt: new Date(),
  },
  loading: false,
  errors: {},
  errorCode: 0,
};

const TeamDetailSlice = createSlice({
  name: 'TeamDetail',
  initialState: initialState,
  reducers: {
    getTeamDetailAction: (state, action: PayloadAction<{ teamSlug: string; version?: string }>) => {
      state.loading = true;
      state.data = {
        ...state.data,
        urlSlug: action.payload.teamSlug,
      };
    },
    resetTeamDetailAction: (state) => {
      state.data = initialState.data
    },
    successGetTeamDetailAction: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.errorCode = 200;
    },
    failedGetTeamDetailAction: (state, action) => {
      if (action.payload.errors !== undefined) {
        state.errors = action.payload.errors;
        state.errorCode = state.errors.status!!;
      } else {
        state.errorCode = 0;
      }
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTeamDetail.pending, state => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(fetchTeamDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorCode = action.error.message;
      })
      .addCase(fetchTeamDetail.fulfilled, (state, action) => {
        const { isAxiosError, errors } = action.payload as TeamDetailError;
        if (isAxiosError && errors) {
          state.errors = errors;
          state.errorCode = 400;
          state.loading = false;
        } else {
          state.data = action.payload as TeamDetailResponse;
          state.errors = initialState.errors;
          state.loading = false;
        }
      });
  },
});

export const {
  resetTeamDetailAction,
  getTeamDetailAction,
  successGetTeamDetailAction,
  failedGetTeamDetailAction,
} = TeamDetailSlice.actions;

export default TeamDetailSlice.reducer;
