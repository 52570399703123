import { Team } from './Team';
import { Box, Stack, Divider, Button } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { MatchStatusKey, MatchResultKey } from 'redux/scrim/ScrimType';

export interface MatchProps {
  roundCategory?: string;
  status?: ReactNode;
  home: {
    logo?: string;
    name?: string;
    location?: string;
    indicator?: Array<any>;
    score?: string;
    result?: string;
  };
  away: {
    logo?: string;
    name?: string;
    location?: string;
    indicator?: Array<any>;
    score?: string;
    result?: string;
  };
  result?: {
    status?: number;
    type?: number;
  };
  whatsapp?: {
    show?: boolean;
    phone?: string;
    message?: string;
  };
  info?: {
    label?: string;
    date?: string;
  };
  rules?: {
    label?: ReactNode | string;
    onClick?: () => void;
  };
}

export const Match = ({
  roundCategory,
  status,
  home,
  away,
  result,
  whatsapp,
  info,
  rules,
}: MatchProps) => {
  const { t } = useTranslation();

  const disputeAndNotValid =
    result?.status !== MatchStatusKey.Dispute && result?.status !== MatchStatusKey.NotValid;

  const handleScoreColor = (team: MatchProps['home' | 'away']) => {
    if (team?.result === 'win') {
      return 'success500';
    }
    if (team?.result === 'lose') {
      return result?.type == MatchResultKey.Walkover ? 'neutral550' : 'danger500';
    }
    return 'neutral550';
  };
  
  return (
    <Box
      sx={theme => ({
        width: '100%',
        bgcolor: 'neutral700',
        borderRadius: theme.borderRadius.md,
        border: '1px solid #242628',
      })}
    >
      {status && (
        <Stack direction="row" justifyContent="center" sx={{ mb: '18px' }}>
          {status}
        </Stack>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ p: whatsapp?.show ? '0 12px' : '0 12px 16px 12px' }}
      >
        <Team variant="home" team={home} result={result} />

        <Stack
          sx={{
            width: disputeAndNotValid ? '24%' : '40%',
            pt: disputeAndNotValid ? '4px' : '9px',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            {result && disputeAndNotValid ? (
              <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: '5px' }}>
                <Text variant="h2" sx={{ fontSize: '40px', color: handleScoreColor(home) }}>
                  {home.score ?? 0}
                </Text>
                <Text
                  variant="label"
                  sx={{
                    minWidth: '37px',
                    m: '0 4px',
                    textAlign: 'center',
                    fontWeight: '700',
                    letterSpacing: '0.24px',
                  }}
                >
                  {result.status == MatchStatusKey.DataCompleted && result.type == MatchResultKey.Walkover ? 'W.O' : 'VS'}
                </Text>
                <Text variant="h2" sx={{ fontSize: '40px', color: handleScoreColor(away) }}>
                  {away.score ?? 0}
                </Text>
              </Stack>
            ) : (
              <Text
                variant="label"
                sx={{
                  textAlign: 'center',
                  fontWeight: '700',
                  letterSpacing: '0.24px',
                  mb: '10px',
                }}
              >
                {result?.status == MatchStatusKey.NotValid
                  ? t('match_tidak_valid')
                  : t('match_dalam_validasi')}
              </Text>
            )}
          </Stack>

          {roundCategory && (
            <Stack
              alignSelf="center"
              sx={theme => ({
                bgcolor: 'neutral600',
                p: '2px 6px',
                borderRadius: theme.borderRadius.sm,
                mt: disputeAndNotValid ? '20px' : '22px',
                textAlign: 'center',
              })}
            >
              <Text variant="subtitle">{roundCategory}</Text>
            </Stack>
          )}
        </Stack>

        <Team variant="away" team={away} result={result} />
      </Stack>

      {whatsapp?.show && (
        <Stack direction="row" justifyContent="center">
          <Button
            component="a"
            variant="contained"
            sx={theme => ({
              bgcolor: 'neutral600',
              p: '4px 16px',
              borderRadius: theme.borderRadius.sm,
              border: `1px solid ${theme.palette.neutral500}`,
              textTransform: 'none',
              my: '16px',
              '&:hover': {
                bgcolor: 'neutral600',
              },
            })}
            target="_blank"
            href={`https://api.whatsapp.com/send/?phone=${whatsapp?.phone}&text=${whatsapp?.message}&app_absent=0`}
          >
            <img src={IconSource('whatsapp-match', 'png')} alt={t('match_whatsapp_lawan')} />
            <Text variant="button" sx={{ ml: '6px' }}>
              {t('match_whatsapp_lawan')}
            </Text>
          </Button>
        </Stack>
      )}

      {(info || rules) && <Divider sx={{ borderStyle: 'dashed', borderColor: 'neutral530' }} />}

      {info && (
        <Stack direction="row" justifyContent="space-between" sx={{ p: '8px 12px' }}>
          <Text variant="bodySmall" sx={{ color: 'neutral400' }}>
            {info.label}
          </Text>
          <Text variant="bodySmall" sx={{ color: 'neutral400' }}>
            {info.date}
          </Text>
        </Stack>
      )}

      {rules && (
        <Stack
          direction="row"
          component="a"
          justifyContent="center"
          alignItems="center"
          onClick={rules?.onClick}
          sx={{ height: '40px', cursor: 'pointer' }}
        >
          {rules.label ?? (
            <>
              <Text variant="button" sx={{ fontWeight: 700 }}>
                {t('match_rules')}
              </Text>
              <SVG src={IconSource('ic-caret-down-small-white')} />
            </>
          )}
        </Stack>
      )}
    </Box>
  );
};
