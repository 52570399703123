import { Box } from '@mui/material';
import { ScrollContext } from 'context/ScrollContext';
import { Match } from 'design/Cards/Match';
import { MatchStatus } from 'design/Ribbon/MatchStatus';
import { Text } from 'design/Text';
import { MATCH_STATUS } from 'dictionaries/match';
import history from 'modules/history';
import moment from 'moment-timezone';
import React, { createRef, useLayoutEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MatchesResponse } from 'redux/match/matchProps';
import { STATUS } from 'store/constants/match';

export interface MatchListProps {
  handleMatches: MatchesResponse['matches'] | [];
  gameSlug: string;
  tournamentSlug: string;
  searchMatchList: string;
  location: any;
}

const MatchList: any = ({
  handleMatches,
  gameSlug,
  tournamentSlug,
  searchMatchList,
  location,
}: MatchListProps) => {
  const { t } = useTranslation();
  const { value: scrollValue } = useContext(ScrollContext);

  // multiple matchListRef ref
  const matchListRef: any =
    (handleMatches ?? []).length > 0 &&
    Array((handleMatches ?? []).length)
      .fill('')
      .map(() => createRef());

  // scroll to specific target
  useLayoutEffect(() => {
    const matchesLength = handleMatches?.length;
    const scrollMatchTarget = location && location.state && location.state.scrollMatchTarget;
    const scrollMatchTargetNumber = scrollMatchTarget + 1;
    const currentScroll = scrollValue.scrollRef?.current?.scrollTop;

    if (scrollMatchTarget && matchListRef.length > 0) {
      if (matchesLength >= scrollMatchTargetNumber) {
        scrollValue.scrollRef?.current?.scrollTo(0, currentScroll);
        const { y } =
          matchListRef[location.state.scrollMatchTarget].current.getBoundingClientRect();
        const offsetFromHeader = 55 + 16;
        const offsetY = y - offsetFromHeader;

        return scrollValue.scrollRef?.current?.scrollTo(
          0,
          offsetY! >= currentScroll ? offsetY : currentScroll,
        );
      }
    }
  }, [location, matchListRef]);

  return (
    handleMatches?.map((match, index) => {
      const handleMatchStatusColor = (status: number) => {
        const matchNotValid = [
          STATUS.UNKNOWN,
          STATUS.EXPIRED,
          STATUS.REJECT,
          STATUS.NOTVALID,
          STATUS.DISPUTE,
        ];
        const matchScheduled = [STATUS.OPEN, STATUS.INVITATION, STATUS.SCHEDULED];
        const matchCompete = [STATUS.CHECKIN, STATUS.COMPETE, STATUS.RESULTUPLOAD];

        if (matchNotValid.includes(status)) return 'red';
        if (matchCompete.includes(status)) return 'orange';
        if (matchScheduled.includes(status)) return 'green';
        return 'white';
      };

      const handleWinner = (teamId: string) => {
        if (teamId !== match.winnerTeamId) {
          if (match?.results) {
            if (match?.status === STATUS.RESULTUPLOAD) return '';
            return 'lose';
          }
          return '';
        }
        if (teamId == undefined && match.winnerTeamId == undefined) return '';
        return 'win';
      };

      const handleIndicatorTeam = (teamId: string) => {
        const byeResult = Array.from(Array(match.type).keys()).map(() => ({
          result: match.winnerTeamId === teamId ? 'win' : '',
        }));
        const matchResult = match.results?.map(result =>
          result.winnerTeamId === teamId ? { result: 'win' } : { result: 'lose' },
        );
        const noResult = Array.from(Array(match?.type).keys()).map(_item => ({
          result: '',
        }));

        if (match?.status === STATUS.RESULTUPLOAD || match?.status === STATUS.DISPUTE) {
          return noResult;
        }

        if (teamId == undefined && match.winnerTeamId == undefined) return noResult;

        return matchResult ?? byeResult;
      };

      const handleMatchLink = () => {
        history.push({
          pathname: `/tournament/${gameSlug}/${tournamentSlug}/match/${match.id}`,
          state: {
            scrollTarget: location?.state?.scrollTarget,
            scrollMatchTarget: index,
            search: searchMatchList,
          },
        });
      };

      return (
        <Box
          key={match.id}
          sx={{ cursor: 'pointer', width: '100%' }}
          onClick={handleMatchLink}
          ref={matchListRef[index]}
        >
          <Match
            status={
              <MatchStatus
                variant={handleMatchStatusColor(match.status)}
                label={t(MATCH_STATUS[match.status])}
              />
            }
            home={{
              logo: match.firstTeamAvatar,
              name: match.firstTeam,
              location: match.firstTeamSchool,
              indicator: handleIndicatorTeam(match.firstTeamId),
              score: match.firstTeamScore === '' ? '0' : match.firstTeamScore,
              result: handleWinner(match.firstTeamId),
            }}
            away={{
              logo: match.secondTeamAvatar,
              name: match.secondTeam,
              location: match.secondTeamSchool,
              indicator: handleIndicatorTeam(match.secondTeamId),
              score: match.secondTeamScore === '' ? '0' : match.secondTeamScore,
              result: handleWinner(match.secondTeamId),
            }}
            result={{
              status: match.status,
              type: match.matchResultType,
            }}
            roundCategory={match.roundName}
            info={{
              label: t('match_tournament_id', { id: match.id }),
              date: moment(match.startAt).tz('Asia/Jakarta').format('DD MMM YYYY - HH:mm z'),
            }}
          />
        </Box>
      );
    }) ?? (
      <Text variant="bodySmall" sx={{ pt: '24px', color: '#8D8784' }}>
        {t('match_search_not_found')}
      </Text>
    )
  );
};

export default MatchList;
