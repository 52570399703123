import Box from '@mui/material/Box';
import { styled } from '@mui/system';

export const CardContainer = styled('div')(({ theme }: any) => ({
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: theme.palette.gray05,
  border: '1px solid #242628',
}));

export const CardContainerContent = styled('div')(({ theme }: any) => ({
  ...theme.typography.body,
  padding: '12px',
}));

export const CardContainerChildren = styled('div')(({ theme }: any) => ({
  padding: '8px 12px',
  color: theme.palette.neutral150,
  borderTop: '1px dashed #323437',
  borderBottom: '1px dashed #323437',
  marginBottom: '12px',
}));

export const CardThumbnailWrapper = styled('div')(() => ({
  position: 'relative',
  '&:after': {
    content: '""',
    width: '100%',
    height: '24px',
    background: 'linear-gradient(180deg, rgba(25, 24, 23, 0) 0%, #191817 100%)',
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
}));

export const CardTournamentStatus = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray05,
  b: {
    fontWeight: 600,
  },
}));

export const CardTournamentChampion = styled(Box)(({ theme }: any) => ({
  background:
    'linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.36) 100%), linear-gradient(90deg, rgba(213, 31, 64, 0.4) 0%, rgba(243, 112, 36, 0.4) 100%)',
  borderRadius: '0 0 4px 4px',
  display: 'flex',
  alignItems: 'flex-start',
  padding: '10px 12px',
  fontFamily: '"Lexend Deca", sans-serif',
  position: 'relative',
  overflow: 'hidden',
  '&:after': {
    content: '""',
    background: `url(${process.env.PUBLIC_URL}/media/images/star-trophy-mini.png) no-repeat`,
    backgroundSize: '80px',
    width: '80px',
    height: '80px',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 0,
  },
  '.MuiAvatar-root': {
    border: '2px solid #F1EBEB',
    borderRadius: '8px',
    marginRight: '12px',
    position: 'relative',
    overflow: 'visible',
    ':before': {
      content: '""',
      width: '20px',
      height: '20px',
      backgroundImage: `url(${process.env.PUBLIC_URL}/media/icons/ic-small-crown@2x.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '24px',
      position: 'absolute',
      top: '-12px',
      left: '-12px',
    },
    img: {
      borderRadius: '6px',
    },
  },
  p: {
    fontSize: '8px',
    lineHeight: '12px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: theme.palette.primary600,
  },
  h4: {
    lineHeight: '22px',
    fontWeight: 600,
    color: theme.palette.neutral100,
  },
}));

export const ImgThumbnail = styled('img')(() => ({
  width: '100%',
  display: 'block',
}));

export const IconTournamentInfo = styled('img')(() => ({
  width: '18px',
  display: 'block',
  flexShrink: 0,
  marginRight: '10px',
}));

export const TournamentStatusIcon = styled('img')(() => ({
  width: '16px',
  display: 'block',
  flexShrink: 0,
}));
