import { Box, Stack, Avatar } from '@mui/material';
import { Text } from 'design/Text';
import { ImageSource, IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface CardEmptyParticipationProps {
  title?: string | ReactNode;
  cta?: ReactNode;
}

export const CardEmptyParticipation = ({ title, cta }: CardEmptyParticipationProps) => {
  return (
    <Stack
      alignItems="center"
      sx={theme => ({
        position: 'relative',
        p: '24px',
        borderRadius: theme.borderRadius.md,
        border: `1px solid ${theme.palette.neutral600}`,
        bgcolor: 'neutral700',
        boxSizing: 'border-box',
        overflow: 'hidden',
        ':before': {
          content: "''",
          position: 'absolute',
          inset: '0',
          width: '100%',
          height: '100%',
          background: `${theme.palette.neutral700} url(${IconSource('grunge', 'png')}) repeat`,
          zIndex: '0',
          opacity: '0.1',
        },
      })}
    >
      <Avatar
        src={ImageSource('gray_trophy')}
        sx={{
          width: '112px',
          height: '112px',
          bgcolor: 'transparent',
        }}
      />
      <Text
        className="cardlistparticipationaward-title"
        variant="bodySmall"
        sx={{
          lineHeight: '140%',
          color: '#8D8784',
          mt: '8px',
          zIndex: '0',
        }}
      >
        {title}
      </Text>
      {cta ? <Box sx={{ mt: '16px' }}>{cta}</Box> : null}
    </Stack>
  );
};
