import { Theme, Stack, Box, Avatar } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { FC, ReactNode } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export interface CardParticipationFeedProps {
  photo?: string;
  title?: string | ReactNode;
  description?: string | ReactNode;
  cta?: ReactNode;
  sx?: SxProps<Theme>;
}

const CardParticipationFeedStyled: any = ({ sx }: CardParticipationFeedProps) => {
  return {
    '&:first-child': {
      '.CardParticipationFeed-topline': { bgcolor: 'transparent' },
    },
    '&:last-child': {
      '.CardParticipationFeed-bottomline': { bgcolor: 'transparent' },
      '.CardParticipationFeed-content': { borderBottom: 'transparent' },
    },
    ...sx,
  };
};

export const CardParticipationFeed: FC<CardParticipationFeedProps> = ({
  photo,
  title,
  description,
  cta,
  sx,
}) => {
  return (
    <Stack direction="row" sx={() => CardParticipationFeedStyled({ sx })}>
      <Box
        sx={{
          width: '60.5px',
        }}
      >
        <Avatar
          src={photo ?? IconSource('ph_participation_feed')}
          sx={{
            width: '46px',
            height: '46px',
            bgcolor: 'transparent',
            borderRadius: '4px',
            mr: '14.5px',
          }}
        />
      </Box>
      <Stack sx={{ position: 'relative', alignItems: 'stretch', mr: '13.5px' }}>
        <Box
          sx={{
            bgcolor: 'neutral600',
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            backgroundSize: 'cover',
            position: 'absolute',
            top: '19px',
            left: '-2.5px',
            zIndex: 1,
          }}
        />
        <Box
          className="CardParticipationFeed-topline"
          sx={{
            height: '22px',
            width: '1px',
            bgcolor: 'neutral600',
          }}
        />
        <Box
          className="CardParticipationFeed-bottomline"
          sx={{
            width: '1px',
            minHeight: '78px',
            bgcolor: 'neutral600',
            height: '100%',
          }}
        />
      </Stack>
      <Stack
        className="CardParticipationFeed-content"
        sx={theme => ({
          width: '100%',
          p: '12px 16px 12px 0px',
          borderBottom: `1px solid ${theme.palette.neutral600}`,
        })}
      >
        <Text
          variant="label"
          sx={{
            color: 'neutral200',
            fontWeight: 400,
          }}
        >
          <ResponsiveEllipsis text={title} maxLine={2} ellipsis="..." />
        </Text>
        <Box sx={{ mt: '4px' }}>{description}</Box>
        <Box sx={{ mt: '4px' }}>{cta}</Box>
      </Stack>
    </Stack>
  );
};
