import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import XHR from 'i18next-xhr-backend';
import languageEN from 'locales/en/mabar.json';
import languageID from 'locales/id/mabar.json';
import { initReactI18next } from 'react-i18next';

export const defaultNS = 'app';
export const resourcesLanguage = { en: languageEN, id: languageID } as const;

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resourcesLanguage,
    lng: process.env.APP_DEFAULT_LANGUAGE,
    fallbackLng: ['id', 'en'],
    ns: ['app', 'errors'],
    defaultNS,
    keySeparator: '.',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      prefix: '{{',
      suffix: '}}',
    },
    react: {
      transKeepBasicHtmlNodesFor: [],
      bindI18n: 'language loaded',
      bindI18nStore: 'added removed',
      nsMode: 'fallback',
      transEmptyNodeValue: '_MABAR_EMPTY_NODE_',
      transSupportBasicHtmlNodes: true,
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',
      lookupSessionStorage: 'lang',
      // cache user language
      caches: ['cookie', 'localStorage'],
      excludeCacheFor: ['cimode'],
      cookieMinutes: 60,
      cookieDomain: process.env.NODE_ENV === 'production' ? process.env.DOMAIN : 'localhost',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      cookieOptions: {
        path: '/',
        secure: true,
      },
    },
    backend: {
      backends: [HttpBackend, XHR],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
          loadPath: 'locales/_cache/{{lng}}/{{ns}}.json',
          addPath: 'locales/_cache/{{lng}}/{{ns}}.json', // make sure the folders "locales_cache/{{lng}}" exists
        },
        {
          loadPath: 'locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    debug: process.env.NODE_ENV === 'development' ? true : false,
  });

export default i18n;
