import { styled } from '@mui/system';

export const Circular = styled('div', { name: 'Circular' })`
  height: 20px;
  width: 20px;
  position: relative;
`;

export const Inner = styled('div', { name: 'Inner' })`
  position: absolute;
  z-index: 6;
  inset: 0;
  height: 16px;
  width: 16px;
  margin: auto;
  background: ${({ theme }) => theme.palette.neutral700};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BarLeft = styled('div', { name: 'BarLeft' })`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.palette.neutral500};
  border-radius: 50%;
  clip: rect(0px, 20px, 20px, 10px);
  transform: rotate(180deg);
  z-index: 3;
`;
export const BarRight = styled('div', { name: 'BarRight' })`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.palette.neutral500};
  border-radius: 50%;
  clip: rect(0px, 20px, 20px, 10px);
`;

export interface ProgressProps {
  percentageFill?: number;
  isColor?: boolean;
}

export const ProgressLeft = styled('div', { name: 'ProgressLeft' })<ProgressProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  clip: rect(0px, 10px, 20px, 0px);
  background: ${({ isColor, theme }) =>
    isColor ? theme.palette.success500 : theme.palette.neutral400};
  transform: ${props => 'rotate(' + props.percentageFill + 'deg)'};
`;

export const ProgressRight = styled('div', { name: 'ProgressRight' })<ProgressProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  clip: rect(0px, 10px, 20px, 0px);
  background: ${({ isColor, theme }) =>
    isColor ? theme.palette.success500 : theme.palette.neutral400};
  transform: ${props => 'rotate(' + props.percentageFill + 'deg)'};
`;
