import { Box, Button, Stack } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { FC } from 'react';

export interface SubHeaderProps {
  topText?: string;
  bottomText?: string;
  youtubeLink?: string;
  isSticky?: boolean;
}

export const SubHeader: FC<SubHeaderProps> = ({ topText, bottomText, youtubeLink, isSticky }) => {
  return (
    <Box
      sx={{
        position: isSticky ? 'sticky' : 'relative',
        top: isSticky ? '56px' : '0px',
        bgcolor: 'neutral700',
        padding: '0px 16px 15px 16px',
        borderBottom: '1px solid #323437',
        zIndex: isSticky ? '99' : '0',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column">
          <Text
            sx={{ fontWeight: '600', fontSize: '12px', lineHeight: '140%', fontStyle: 'normal' }}
          >
            {topText}
          </Text>
          <Text
            sx={{ fontWeight: '600', fontSize: '12px', lineHeight: '140%', color: 'neutral300' }}
          >
            {bottomText}
          </Text>
        </Stack>
        <Stack>
          {youtubeLink ? (
            <>
              <Button
                component="a"
                variant="contained"
                sx={theme => ({
                  bgcolor: 'neutral600',
                  borderRadius: theme.borderRadius.sm,
                  border: `1px solid ${theme.palette.neutral500}`,
                  textTransform: 'none',
                  '&:hover': {
                    bgcolor: 'neutral600',
                  },
                })}
                target="_blank"
                href={youtubeLink}
              >
                <img src={IconSource('ic-youtube', 'svg')} />
                <Text variant="button" sx={{ ml: '4px' }}>
                  Watch
                </Text>
              </Button>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
