import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC } from 'react';

export interface CardRuleType {
  textTitle1?: string | undefined;
  text1?: string | undefined;
  textTitle2?: string | undefined;
  text2?: string | undefined;
}

const CardRule: FC<CardRuleType> = ({ textTitle1, text1, textTitle2, text2 }) => {
  return (
    <Box
      background="#373942 60%"
      flexGrow={1}
      padding="12px"
      borderRadius="16px"
      margin="10px 0px 5px 0px"
    >
      <Stack direction="row" style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
        <Stack direction="column" align="start">
          <NormalText
            color="#969393"
            fontSize="12px"
            fontWeight="normal"
            margin="0px 0px 0px 0px"
            lineHeight="140%"
          >
            {textTitle1}
          </NormalText>
          <Box margin="10px 0px 0px 0px">
            <Stack direction="row" align="start">
              <NormalText
                textAlign="left"
                color="#FFFFFF"
                fontSize="14px"
                fontWeight="700"
                margin="5px 0px 0px 0px"
              >
                {text1}
              </NormalText>
            </Stack>
          </Box>
        </Stack>
        <Stack direction="column" align="start">
          <NormalText
            color="#969393"
            fontSize="12px"
            fontWeight="normal"
            margin="0px 0px 0px 0px"
            lineHeight="140%"
          >
            {textTitle2}
          </NormalText>
          <Box margin="10px 0px 0px 0px">
            <Stack direction="row" align="start">
              <NormalText
                textAlign="left"
                color="#FFFFFF"
                fontSize="14px"
                fontWeight="700"
                margin="5px 0px 0px 0px"
              >
                {text2}
              </NormalText>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CardRule;
