import { Theme, Box, BoxProps } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface RibbonProps extends BoxProps {
  theme?: Theme;
  label?: string | ReactNode;
  sx?: SxProps<Theme>;
}

const ribbonWing = {
  content: "''",
  position: 'absolute',
  top: 0,
  width: '10px',
  height: '18px',
  backgroundImage: `url(${IconSource('ribbon-wing', 'png')})`,
  backgroundSize: 'cover',
};

const CustomRibbon: any = styled(Box, { name: 'competition-title' })<RibbonProps>(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '19px',
  padding: '0 8px',
  margin: '0 10px',
  textAlign: 'center',
  background: theme.palette.neutral150,
  span: { display: 'block', color: theme.palette.danger700 },
  '&:before': {
    ...ribbonWing,
    left: '-8px',
  },
  '&:after': {
    ...ribbonWing,
    right: '-8px',
    transform: 'scaleX(-1)',
  },
  '.shadow': {
    width: '100%',
    height: '10px',
    position: 'absolute',
    top: '12px',
    zIndex: '-1',
    borderRadius: '30px',
    background: '#D5916B',
  },
}));

export const CompetitionTitle = ({ label, sx, ...props }: RibbonProps) => {
  return (
    <CustomRibbon sx={{ ...sx }} {...props}>
      <Text variant="ribbon" component="span" sx={{ textTransform: 'uppercase' }}>
        {label}
      </Text>
      <Box className="shadow" />
    </CustomRibbon>
  );
};
