import EventContent from './EventData';
import { Blockquoute } from 'components/styled/layout.styled';
import { GradientText, SpanText } from 'components/styled/text.styled';
import { NormalText } from 'components/styled/text.styled';
import { LayoutContext } from 'context/LayoutContext';
import { compiler } from 'markdown-to-jsx';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Image } from 'styled-minimal';
import { TextTitle } from 'styles/text.style';

export const ShareDiv = styled.div<any>`
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  margin: 8px 0px 8px 0px;
  padding: 6px 10px 6px 10px;
  background: #15141a;
  border-top: 1px solid #292b33;
  border-bottom: 1px solid #292b33;
  color: white;
  font-size: 12px;
`;

export const InfoDiv = styled.div`
  height: auto;
  background: rgba(55, 57, 66, 0.6);
  border-radius: 16px;
  padding: 10px 16px;
  margin-bottom: 10px;
`;

export const ADiv = styled.div`
  font-size: 12px;
  color: white;
`;

export const ToastDiv = styled.div<any>`
  background: #373942f0;
  position: fixed;
  color: white;
  width: 100%;
  top: 10%;
  left: -1%;
  padding: 12px 30px;
  opacity: ${p => (p.isShown ? '1' : '0')};
  transition: opacity 1s ease-out;
`;

const Detail = () => {
  const { t } = useTranslation();

  const [toastShow, setToastShow] = useState(false);

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Detail Event' }),
      appBar: {
        logo: false,
        back: {
          to: 'goback',
        },
        title: 'Detail Event',
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, []);

  return (
    <>
      <ToastDiv isShown={toastShow}>
        URL telah berhasil disalin
        <Image
          alt="Close"
          src={`${process.env.PUBLIC_URL}http://localhost:3000/media/icons/ic-close-white.svg`}
          style={{ float: 'right', marginRight: '12vw' }}
          onClick={() => setToastShow(false)}
        />
      </ToastDiv>

      <Image
        alt="Banner Detail"
        src={`${process.env.PUBLIC_URL}/media/images/banner/new-mabar-dbl-tournament-season-1-playoff.jpg`}
      />

      <div>
        <TextTitle
          size="16px"
          style={{
            color: 'white',
            fontWeight: 700,
            marginTop: '10px',
            marginBottom: '6px',
            padding: '10px 16px 8px',
          }}
        >
          DBL PLAY MABAR NATIONAL CHAMPIONSHIP - SEASON 1 2022
        </TextTitle>
        {/* <TextTitle
          size="12px"
          style={{
            color: '#969393',
            fontWeight: 700,
            marginTop: '0px',
            paddingBottom: '12px',
          }}
        >
          ---
        </TextTitle> */}
      </div>

      <InfoDiv>
        <TextTitle
          size="12px"
          style={{
            color: '#969393',
            fontWeight: 700,
            marginTop: '10px',
          }}
        >
          Tanggal main
        </TextTitle>
        <ul style={{ paddingLeft: '20px', color: 'white', margin: '10px 0' }}>
          <li>
            <TextTitle
              size="14px"
              style={{
                color: 'white',
                margin: '10px 0',
                lineHeight: '21px',
              }}
            >
              Group stage : <b>19 November 2022</b> - <b>20 November 2022</b>
            </TextTitle>
          </li>
          <li>
            <TextTitle
              size="14px"
              style={{
                color: 'white',
                margin: '10px 0',
                lineHeight: '21px',
              }}
            >
              Playoffs: <b>25 - 27 November 2022 dan 3 - 4 Desember 2022</b>
            </TextTitle>
          </li>
        </ul>
      </InfoDiv>

      <InfoDiv>
        <TextTitle
          size="12px"
          style={{
            color: '#969393',
            fontWeight: 700,
            marginTop: '10px',
            marginBottom: '20px',
          }}
        ></TextTitle>
        <NormalText
          fontSize="12px"
          color="white"
          lineHeight="17.52px"
          fontWeight="400"
          textAlign="justify"
          textJustify="inter-word"
          letterSpacing="0px"
          onClick={e => e}
        >
          {compiler(EventContent(), {
            overrides: {
              strong: {
                component: SpanText,
                props: {
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: '700',
                  lineHeight: '19.6px',
                },
              },
              h3: {
                component: SpanText,
                props: {
                  color: '#969393',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '19.6px',
                },
              },
              a: {
                component: GradientText,
                props: {
                  fontSize: '12px',
                  fontWeight: '700',
                  lineHeight: '140%',
                  isPressable: true,
                  disableSelectText: true,
                  onClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                    if (e.currentTarget && e.currentTarget.getAttribute('href'))
                      window.open(e.currentTarget.getAttribute('href') ?? '#', '_blank');
                  },
                },
              },
              blockquote: {
                component: Blockquoute,
                props: {
                  display: 'block',
                  background: '#2D2F37',
                  borderRadius: '16px',
                  width: 'auto',
                  padding: '4px 16px',
                },
              },
            },
          })}
        </NormalText>
      </InfoDiv>

      {/* <GradientButton
        variant="primary"
        padding="12px 20px 12px 20px"
        styled={{
          width: '50%',
          marginTop: '10px',
          marginLeft: '25%',
          marginBottom: '50px',
        }}
      >
        <span>Lihat Selanjutnya</span>
      </GradientButton> */}
    </>
  );
};

export default Detail;
