// @ts-ignore
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

history.location = {
  ...history.location,
  state: {},
};

/* istanbul ignore next */
history.listen(() => {
  history.location = {
    ...history.location,
    state: history.location.state || {},
  };
  if(process.env.NODE_ENV !== 'production'){
    console.info('location :', history.location)
  }
});

const { go, goBack, push, replace, action, createHref, goForward, location } = history;

export { go, goBack, push, replace, action, createHref, goForward, location };
export default history;
