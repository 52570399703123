import { IconButton } from 'components/styled/icon.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NavBar } from 'components/styled/nav.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC } from 'react';
import { NavBarHeight } from '../../modules/config';

export interface AppBarType {
  title?: string;
  iconLeft?: string;
  logoLeft?: string;
  iconRight?: string;
  onIconLeftClick?: () => void;
  onIconRightClick?: () => void;
}

const AppBar: FC<AppBarType> = ({
  title,
  iconRight,
  logoLeft,
  iconLeft,
  onIconLeftClick,
  onIconRightClick,
}) => {
  return (
    <NavBar height={`${NavBarHeight}px`}>
      {iconLeft && (
        <IconButton
          margin="0px 0px 0px 8px"
          height="46px"
          width="46px"
          src={iconLeft}
          onClick={onIconLeftClick}
        />
      )}
      {logoLeft && (
        <IconButton
          src={logoLeft}
          onClick={onIconLeftClick}
          height="35.78px"
          width="87.42px"
          margin="0px 16px"
        />
      )}
      {title ? (
        <Box flexGrow={1}>
          <Stack align="center">
            <NormalText fontSize="14px" fontWeight="700">
              {title}
            </NormalText>
          </Stack>
        </Box>
      ) : (
        <Box flexGrow={1} />
      )}
      {iconRight !== undefined && (
        <IconButton
          margin="0px 8px 0px 0px"
          height="46px"
          width="46px"
          src={iconRight}
          onClick={onIconRightClick}
        />
      )}
    </NavBar>
  );
};
export default AppBar;
