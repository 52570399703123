import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { loginAction } from 'redux/auth/AuthReducer';
import { useAppDispatch } from 'redux/hooks';
import { RouteProps } from 'types';
import EmptyAccountPage from '../pages/state/EmptyAccount';

function RoutePrivate({
  component: Component,
  isAuthenticated,
  to,
  redirectToSSO,
  ...rest
}: RouteProps) {
  if (redirectToSSO && isAuthenticated === false) {
    const dispatch = useAppDispatch();
    dispatch(loginAction());
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : to ? (
          <Redirect
            to={{
              pathname: to,
              state: { redirect: props.location.pathname, isAuthenticated },
            }}
          />
        ) : redirectToSSO && isAuthenticated === false ? (
          <></>
        ) : (
          <EmptyAccountPage />
        )
      }
    />
  );
}

export default RoutePrivate;
