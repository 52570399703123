import TeamBody from 'types/API/request/TeamRequest';
import { BaseResponse } from 'types/API/response/BaseResponse';
import { Team } from 'types/API/response/TeamResponse';
import { TeamPlayer, TeamSchool } from 'types/team';
import ApiService from './ApiService';

class TeamManagementService {
  getTeamSchoolByPage(
    schoolSlug: string,
    pageIndex: number,
    pageSize: number,
    search?: string,
    version?: string,
  ) {
    const apiUrl = encodeURI(
      version
        ? `/v${version}/team/school/${schoolSlug}/${pageIndex}/${pageSize}${
            search ? `?search=${search}` : ''
          }`
        : `/team/school/${schoolSlug}/${pageIndex}/${pageSize}${search ? `?search=${search}` : ''}`,
    );
    return ApiService.get<BaseResponse<Array<TeamSchool>>>(apiUrl);
  }

  getMyTeamsByPage(
    playerSlug: string,
    pageIndex: number,
    pageSize: number,
    search?: string,
    version?: string,
  ) {
    const apiUrl = encodeURI(
      version
        ? `/v${version}/team/player/${playerSlug}/${pageIndex}/${pageSize}${
            search ? `?search=${search}` : ''
          }`
        : `/team/player/${playerSlug}/${pageIndex}/${pageSize}${search ? `?search=${search}` : ''}`,
    );
    return ApiService.get<BaseResponse<Array<TeamPlayer>>>(apiUrl);
  }

  createTeamPost(data: TeamBody, version?: string) {
    const apiUrl = encodeURI(version ? `/v${version}/team` : `/team`);
    var TeamFormData = new FormData();
    if(data.name) TeamFormData.append('name', data.name);
    if(data.gameId) TeamFormData.append('gameId', data.gameId);
    if(data.whatsAppGroupUrl) TeamFormData.append('whatsAppGroupUrl', data.whatsAppGroupUrl);
    if(data.discordUrl) TeamFormData.append('discordUrl', data.discordUrl);
    if(data.whatsAppPhoneNumber) TeamFormData.append('whatsAppPhoneNumber', data.whatsAppPhoneNumber);
    if(data.avatarFile) TeamFormData.append('avatarFile', data.avatarFile);
    if(data.members) data.members.map(m => {
      TeamFormData.append('members', m);
    });
    return ApiService.post(apiUrl, TeamFormData);
  }
  
  updateTeamPut(data: TeamBody, version?: string) {
    const apiUrl = encodeURI(version ? `/v${version}/team/${data.id}` : `/team/${data.id}`);
    var TeamFormData = {
      id: data.id,
      whatsAppGroupUrl: data.whatsAppGroupUrl,
      discordUrl: data.discordUrl,
      whatsAppPhoneNumber: data.whatsAppPhoneNumber
    };

    return ApiService.put(apiUrl, TeamFormData);
  }

  getTeamBySlug(urlSlug: string, version?: string) {
    const apiUrl = encodeURI(version ? `/v${version}/team/${urlSlug}` : `/team/${urlSlug}`);
    return ApiService.get<Team>(apiUrl);
  }
}

export default new TeamManagementService();
