import { TextButtonProps } from './index';
import { styled } from '@mui/system';

export const CustomTextButton: any = styled('button')<TextButtonProps>`
  all: unset;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  outline: 0;
  ${({ disabled }) => disabled && 'pointer-events: none'};
  &:active {
    opacity: 0.8;
  }
  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    ${({ theme, disabled }) => disabled && `color:${theme.palette.neutral500}`};
    svg {
      margin: 0;
    }
  }
`;
