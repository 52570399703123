import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import React, { memo } from 'react';

interface JoinModalProps {
  openJoinModal: boolean;
  handleCloseJoinModal: () => void;
}

const JoinModal = ({ openJoinModal, handleCloseJoinModal }: JoinModalProps) => {
  return (
    <Modal show={openJoinModal}>
      <InfoModal
        icon={ImageSource('Paperplane', 'png')}
        cta={
          <PrimaryButton onClick={handleCloseJoinModal} wide>
            Tutup
          </PrimaryButton>
        }
      >
        <Text variant="h2" textAlign="center" mb="8px">
          Permintaan Join terkirim
        </Text>
        <Text textAlign="center" mb="8px">
          Tunggu konfirmasi dari kapten tim
        </Text>
      </InfoModal>
    </Modal>
  );
};

export default memo(JoinModal);
