import { NormalText } from "components/styled/text.styled";
import HeaderTeamProfile from 'components/cards/HeaderTeamProfile';
import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import TeamEmptyPage from 'pages/MyTeam/state/TeamEmpty';
import React from 'react';
import { getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getMyTeamAction } from 'redux/team/MyTeamReducer';
import styled from 'styled-components';
import { TextTitle } from 'styles/text.style';

export const FrameSeason = styled.div`
  background: #37394299;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 211px;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 10px 20px 20px 20px;
`;

export const FrameRelative = styled.div`
  position: relative;
`;

export const Column = styled.div`
  float: left;
  width: 50%;
`;

export const Row = styled.div`
  margin-top: -17px;
  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

function HomePageTeam() {
  const playerInfo = getPlayerProfileFromSession();
  const dispatch = useAppDispatch();
  const { myTeamData } = useAppSelector(({ myTeam }) => ({
    myTeamData: myTeam.data.items,
  }));

  React.useEffect(() => {
    dispatch(getMyTeamAction({ pageIndex: 1, pageSize: 20, playerSlug: playerInfo.userName }));
  }, [dispatch]);

  return (
    <>
      {myTeamData && myTeamData.length > 0 ? (
        <Stack direction="column" align="center" margin="3px 0px 0px 0px">
          {myTeamData !== undefined &&
            myTeamData.map(data => {
              return (
                <FrameSeason key={data.id}>
                  <HeaderTeamProfile
                    key={data.id}
                    bgImage={data.game.avatarUrl ?? './media/images/dummy/profile.background.svg'}
                    gameName={data.game.name ?? 'Mobile Legends'}
                    teamInfo={`${data.memberCount} Anggota, ${data.matchCount} Pertandingan`}
                    teamLogo={data.avatarUrl ?? './media/images/dummy/team1.svg'}
                    teamName={data.name ?? 'Fire Dragon Squad'}
                    dataTeam={data}
                    onTeamClick={(team, _el) =>
                      data.status.key === '2'
                        ? history.push(
                            `/team/${team.game.urlSlug}/${playerInfo.school?.urlSlug}/${team.urlSlug}`,
                          )
                        : undefined
                    }
                    boxColor="transparent"
                    padding="10px 0px 0px 0px"
                    variant={data.status.key === '1' ? 'Waiting' : 'Approve'}
                    styled={{ minHeight: '120px', marginBottom: '20px' }}
                  />
                  <Row>
                    <Column style={{ width: '20%' }}>
                      <Box>
                        <Stack direction="row" align="start">
                          <Box
                            borderRadius="12px 0px 0px 0px"
                            background="#C4C4C4"
                            maxWidth="32px"
                            maxHeight="32px"
                            opacity={0.16}
                          >
                            <Avatar src={IconSource('btn-arrow-left', 'png')} />
                          </Box>
                          <Box
                            borderRadius="0px 12px 0px 0px"
                            background="#C4C4C4"
                            maxWidth="32px"
                            maxHeight="32px"
                            opacity={0.16}
                          >
                            <Avatar src={IconSource('btn-arrow-right', 'png')} />
                          </Box>
                        </Stack>
                      </Box>
                    </Column>
                    <Column style={{ width: '3%' }}>
                      <TextTitle size="16px" style={{ color: '#ffffff' }}>
                        1
                      </TextTitle>
                    </Column>
                    <Column style={{ width: '5%' }}>
                      <TextTitle size="14px" style={{ color: '#969393' }}>
                        /1
                      </TextTitle>
                    </Column>
                    {data.status.key === '2' && (
                      <Column style={{ width: '72%', textAlign: 'right' }}>
                        <NormalText
                            fontSize="16px"
                            fontWeight="bold"
                            color="#F3AA36"
                            onClick={() => {
                              history.push(
                                `/team/${data.game.urlSlug}/${playerInfo.school?.urlSlug}/${data.urlSlug}?members`,
                              );
                            }}
                        >
                            Lihat Tim
                        </NormalText>
                      </Column>
                    )}
                  </Row>
                </FrameSeason>
              );
            })}
        </Stack>
      ) : (
        <TeamEmptyPage noMarginTop urlTo="/my-team" />
      )}
    </>
  );
}

export default HomePageTeam;
