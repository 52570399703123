import React, { useState } from 'react';
import AppLayout from 'components/layouts/AppLayout';
import { IconSource, ImageSource } from 'modules/assetpath';
import HeaderTeamProfile from 'components/cards/HeaderTeamProfile';
import ChoosePlayerTeam from 'components/Lists/ChoosePlayerTeam';
import SelectOption from 'components/Inputs/SelectOption';
import SelectTeam from 'components/Inputs/SelectTeam';
import TeamList from 'components/Lists/TeamList';
import { Team } from 'types/team';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  removePlayerAction,
  resetScrimDraftAction,
  setMatchStartDateAction,
  setMatchStartTimeAction,
  setMatchTypeAction,
} from 'redux/scrim/ScrimDraftReducer';
import { Player } from 'redux/team/TeamMemberReducer';
import { getMyTeamAction } from 'redux/team/MyTeamReducer';
import { useForm } from 'react-hook-form';
import { createScrimService } from 'redux/scrim/ScrimService';
import { AxiosError } from 'axios';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';
import { scrimOptions, scrimSchedule, scrimScheduleTime } from '../Config/scrimConfig';
import { fetchPlayerProfile, getPlayerProfileFromSession } from 'redux/account/AccountServices';
import history from 'modules/history';
import _ from 'lodash';
import { useAppThunkDispatch } from 'redux/store';
import MessageModal from 'components/UniPopup/Modals/MessageModal';
import { NormalText, SpanText } from 'components/styled/text.styled';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'components/styled/avatar.styled';
import { LogDev } from 'modules/helpers';
import dayjs, { Dayjs } from 'dayjs';

const CreateScrim = () => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit } = useForm();
  const playerInfo = getPlayerProfileFromSession();
  const myCurrentTeam = getMyCurrentTeamFromSession();
  const [getPlayerInfo, setPlayerInfo] = useState(playerInfo)
  const thunkDispatch =  useAppThunkDispatch();
  const { t } = useTranslation()
  const playerProfileThunk = fetchPlayerProfile({ username: playerInfo?.userName })
  const fetchAccountProfile = () => {
    thunkDispatch(playerProfileThunk).unwrap().then((res) => {
      res && res.id && setPlayerInfo(res)
    })
  }
  React.useEffect(()=> {
    fetchAccountProfile()
  }, [thunkDispatch])

  const { startDate, startTime, gameSelected, matchType, playerList, teamOpponent, myTeams } =
    useAppSelector(({ scrimDraft, myTeam }) => ({
      startDate: scrimDraft.startDate,
      startTime: scrimDraft.startTime,
      matchType: scrimDraft.matchType,
      playerList: scrimDraft.playerList,
      teamOpponent: scrimDraft.teamOpponent,
      gameSelected: scrimDraft.gameSelected,
      code: scrimDraft.code,
      myTeams: myTeam.data.items,
    }));

  const handleClickBack = () => {
    history.push(`/explore/scrim/${gameSelected.urlSlug}`);
  };
  interface Validation {
    isError: boolean;
    message: string;
  }
  const [playersError, setPlayersError] = React.useState<Validation>();
  const [startDateError, setStartDateError] = React.useState<Validation>();
  const [startTimeError, setStartTimeError] = React.useState<Validation>();
  const [teamOpponentError, setTeamOpponentError] = React.useState<Validation>();
  let [showModal, setShowModal] = useState(false);
  let [modalType, setModalType] = useState<'error' | 'loading'>('loading');
  const [getResponseError, setResponseError] = useState<any>()

  const handleClickCreate = async () => {    
    const playerInvited: string[] = [playerInfo.id];
    playerList?.forEach(player => {
      playerInvited.push(player?.id ?? '');
    });
    if (playerInvited.length < gameSelected.minScrimRoster) {
      setPlayersError({
        isError: true,
        message: `Jumlah Player minimal ${gameSelected.minScrimRoster} Anggota!`,
      });
    }

    if (playerInvited.length > gameSelected.maxScrimRoster) {
      setPlayersError({
        isError: true,
        message: `Jumlah Player maksimal ${gameSelected.maxScrimRoster} Anggota!`,
      });
    }

    if (!getDefaultStartDate()) {
      setStartDateError({ isError: true, message: `Silahkan pilih Tanggal Pertandingan!` });
    }

    if (!getDefaultStartTime()) {
      setStartTimeError({ isError: true, message: `Silahkan pilih Waktu Pertandingan!` });
    }

    if (matchType === 'challenge') {
      if (teamOpponent.id === '') {
        setTeamOpponentError({ isError: true, message: 'Silahkan pilih Tim yang akan Ditantang!' });
      }
    }

    setModalType('loading')
    setShowModal(true)
    if (matchType === 'challenge') {
      await createScrimService({
        gameId: gameSelected.id,
        startAt: dayjs(startTime),
        players: playerInvited,
        secondTeamId: teamOpponent.id,
        scrimType: 'challenge',
      })
        .then(async res => {
          if (res.status === 204) {  
            setResponseError({})
            setShowModal(false)            
            dispatch(resetScrimDraftAction())
            history.push(`/explore/scrim/${gameSelected.urlSlug}`);
          }
        })
        .catch(async (err: AxiosError) => {
          setResponseError(err.response?.data)
          setModalType('error')
          setShowModal(true)
        });
    } else {
      await createScrimService({
        gameId: gameSelected.id,
        startAt: dayjs(startTime),
        players: playerInvited,
        scrimType: 'public',
      })
        .then(async res => {
          if (res.status === 204) {            
            setResponseError({})
            setShowModal(false)
            dispatch(resetScrimDraftAction())
            history.push(`/explore/scrim/${gameSelected.urlSlug}`);
          }
        })
        .catch(async (err: AxiosError) => {
          setResponseError(err.response?.data)
          setModalType('error')
          setShowModal(true)
        });
    }
  };

  const saveMatchType = React.useCallback(
    (type: string) => dispatch(setMatchTypeAction({ matchType: type })),
    [dispatch],
  );
  const saveStartDate = React.useCallback(
    (startDates: Dayjs) => dispatch(setMatchStartDateAction({ startDate: startDates })),
    [dispatch],
  );
  const saveStartTime = React.useCallback(
    (startTimes: string) => dispatch(setMatchStartTimeAction({ startTime: startTimes })),
    [dispatch],
  );
  const removePlayer = React.useCallback(
    (player: Player) => dispatch(removePlayerAction({ player })),
    [dispatch],
  );

  const dateOptions = scrimSchedule();
  
  const timeOptions = scrimScheduleTime(startDate);
  
  const getDefaultStartDate = () => {
    const result = dateOptions.find(v => {
      if (v.value.format('DD/MM/YYYY') === dayjs(startDate).format('DD/MM/YYYY')) {
        return v.value;
      }
    });
    return result;
  };

  const getDefaultStartTime = () => {
    const result = timeOptions.find(v => {
      if (v.value.isSame(dayjs(startTime))) {
        return v.value;
      }
    });
    return result;
  };

  const handleTypeScrim = (v: any) => {
    saveMatchType(v.value);
    const element = document.getElementById('scrim-choose-team');
    if (element != null) {
      if (v.value === 'challenge' && teamOpponent.id === '') {
        element.style.display = 'flex';
      } else {
        element.style.display = 'none';
      }
    }
  };

  const getMyTeamBySlug = (teamSlug: string) => {
    return myTeams?.find(team => team.urlSlug === teamSlug);
  };

  React.useEffect(() => {
    if (myTeams.length <= 0) {
      dispatch(getMyTeamAction({ pageIndex: 1, pageSize: 50, playerSlug: playerInfo.userName }));
    }
    if (gameSelected?.id === '' || gameSelected?.id === undefined) {
      history.replace('/explore/scrim/mobile-legend');
    }
  }, [dispatch]);

  const isCaptain = myCurrentTeam?.find(v => v.gameId === (gameSelected?.id ?? '') && v.level.code === 1);
  React.useEffect(() => {
    if (isCaptain === undefined) history.push(`/explore/scrim/${gameSelected.urlSlug}`);
  });

  const teamSelected = getMyTeamBySlug(isCaptain ? isCaptain.urlSlug : '');

  const parseNickname = (games: any) => {
    LogDev('INFO',games)
    if (games) {
      let gameNickname: string[] = [];
      Object.values(games).forEach((prop: any) => {        
        gameNickname = gameNickname.concat(_.map(prop, _.property('nickName')));
      });
      return gameNickname.join('\n');
    }
    return null;
  };
  const parseError = (errors: any) => {
    if (errors) {
      let errorsList: string[] = [];
      Object.values(errors).forEach((prop: any) => {
        errorsList = errorsList.concat(_.map(prop, _.property('message')));
      });
      return errorsList.join('\r\n');
    }
    return null;
  };

  const ModalError = () => {
    return (
      <MessageModal btnConfirmText={<NormalText padding='0px 60px 0px 60px'>OK</NormalText>} onConfirmClick={()=>setShowModal(false)} 
        title={getResponseError ? getResponseError?.status !== 400 ? getResponseError?.title : t('errors:HTTP400') : 'Membuat tim gagal'}>
          <SpanText fontSize='13px' fontWeight='500' color='#FF4D6D' textAlign='center'>
            {getResponseError && getResponseError.errors ? parseError(getResponseError?.errors)?.replace(`'StartAt'`, 'Schedule') : "Data yang dikirim salah!"}
          </SpanText>
      </MessageModal>
    )
  }

  return (
    <AppLayout
      appBar={{
        bg: {
          bRadius: '0px',
          background: 'rgba(36, 39, 49, 0.94)',
          boxShadow: 'none',
          height: '56px',
          pos: 'fixed',
        },
        navleft: {
          bg: 'ic-arrow-back-light',
          height: '24px',
          width: '24px',
          onClick: handleClickBack,
        },
        navright: {
          idNav: 'btn-create-scrim',
          bg:
            (playerList?.length ?? 0) >= gameSelected?.minScrimRoster &&
            getDefaultStartDate() &&
            getDefaultStartTime()
              ? 'linear-gradient(131.42deg, #d51f40 46.48%, #e24134 62.51%, #e8542e 74.36%, #f37024 83.78%)'
              : '#969393',
          children: 'Buat',
          variant: 'button',
          onClick: () => (document.getElementById('create-scrim') as HTMLInputElement).click(),
        },
        onClick: () => null,
        title: 'Buat Pertandingan Scrim',
      }}
      backgroundImage={ImageSource('app.background')}
      showModal={showModal}
      modalStyled={{background: modalType === 'loading' ? 'transparent' : '#373942'}}
      modalContent={modalType === 'error' ? <ModalError/>: modalType === 'loading' && <Avatar src={IconSource('ic-loader')}/>}
    >
      <HeaderTeamProfile
        bgImage={gameSelected?.avatarUrl}
        gameName={gameSelected?.name}
        teamInfo={`${teamSelected?.memberCount.toLocaleString() ?? 0} Anggota, ${
          teamSelected?.matchCount.toLocaleString() ?? 0
        } Pertandingan`}
        teamLogo={teamSelected?.avatarUrl}
        teamName={teamSelected?.name ?? 'Anda belum bergabung tim'}
      />
      <form id="create-scrim-form" onSubmit={handleSubmit(handleClickCreate)}>
        <ChoosePlayerTeam
          onRemoveAction={removePlayer}
          btnOptions={{
            btnWidth: '198px',
            btnPosition: 'flex',
            btnText: '+ Pilih Player dari Tim Kamu',
            onClick: () => history.push('/create-scrim/choose-player'),
          }}
          showArrow={playerList?.length > 0}
          captain={{
            id: `@${getPlayerInfo?.games?.find(game => game.game?.id === gameSelected.id)?.nickName ?? parseNickname(getPlayerInfo?.games ?? playerInfo.games) ?? 
              getPlayerInfo?.userName ?? ''}, GAME ID : ${
              getPlayerInfo?.games?.find(game => game.game?.id === gameSelected?.id)?.gameProviderId ??
              ''
            }`,
            name: getPlayerInfo.firstName?.concat(` ${getPlayerInfo.lastName}` ?? '') ?? '',
            gameProviderId:
            getPlayerInfo?.games?.find(game => game.game?.id === gameSelected.id)?.gameProviderId ??
              '',
            picture: getPlayerInfo?.picture,
            urlSlug: getPlayerInfo?.userName,
          }}
          playerList={playerList}
          maxPlayer={(teamSelected?.memberCount ?? gameSelected.maxScrimRoster) - 1}
          validationType={playersError?.isError ? 'ERROR' : undefined}
          validationText={playersError?.message}
        />
        <div
          style={{
            marginTop: '16px',
          }}
        >
          <SelectOption
            ids="date-scrim-match"
            label="Pilih Tanggal Pertandingan"
            placeholder="Tanggal/Bulan/Tahun"
            indicatorIcon={IconSource('ic-calendar-dark')}
            option={dateOptions}
            defaultValue={getDefaultStartDate()}
            onItemChange={(newValue: any) => {
              saveStartDate(newValue.value);
              setStartDateError({ isError: false, message: '' });
            }}
            isRequired
            {...register('date-match')}
            isError={startDateError?.isError}
            errorMessage={startDateError?.message}
          />
          <SelectOption
            ids="time-scrim-match"
            label="Pilih Waktu Pertandingan"
            placeholder="Jam:Menit"
            indicatorIcon={IconSource('ic-time-dark')}
            defaultValue={getDefaultStartTime()}
            option={timeOptions}
            onItemChange={(newValue: any) => {
              saveStartTime(newValue.value);
              setStartTimeError({ isError: false, message: '' });
            }}
            margin="12px 0px 0px 0px"
            isRequired
            {...register('time-match')}
            isError={startTimeError?.isError}
            errorMessage={startTimeError?.message}
          />
          <SelectOption
            ids="type-scrim-match"
            label="Tipe Pertandingan"
            defaultValue={scrimOptions.find(v => v.value === matchType) ?? scrimOptions[0]}
            option={scrimOptions}
            onItemChange={newValue => handleTypeScrim(newValue)}
            margin="12px 0px 0px 0px"
            isRequired
            {...register('type-match')}
            isError={teamOpponentError?.isError}
            errorMessage={teamOpponentError?.message}
          />
          {getDefaultStartTime() && (
              <>              
              <SelectTeam
              ids="scrim-choose-team"
              visible={!!(matchType === 'challenge' && teamOpponent.id === '')}
              label="Pilih Tim Yang Akan Ditantang"
              onClick={() => history.push('/create-scrim/choose-team')}
              />
                {teamOpponent.id !== '' && matchType === 'challenge' && (
                  <TeamList
                    margin="12px 0px 0px 0px"
                    gameText={(teamOpponent as Team)?.game?.name ?? ''}
                    matchText={`${(teamOpponent as Team)?.memberCount ?? 0} Anggota, ${
                      (teamOpponent as Team)?.matchCount ?? 0
                    } Pertandingan`}
                    onTopRightClick={() => {
                      history.push('/create-scrim/choose-team');
                    }}
                    teamLogoUrl={(teamOpponent as Team)?.avatarUrl}
                    teamName={(teamOpponent as Team)?.name}
                    textTopRight="Ganti"
                    variant="Variant3"
                  />
                )}
              </>
          )}
        </div>
        <input id="create-scrim" type="submit" style={{ display: 'none' }} />
      </form>
    </AppLayout>
  );
};

export default CreateScrim;
