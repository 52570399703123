import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import { CityListProps, CityProps, ProvinceListProps, ProvinceProps, SubDistrictListProps, SubDistrictProps } from './RegionProps';
import { RegionBaseResponse, Province, City, SubDistrict } from './RegionResponse';

export const fetchProvinceList = createAsyncThunk(
  'region/province-list',
  async ({ pageIndex, pageSize, search, version }: ProvinceListProps) => {
    const url = `/${version ? `v${version}/` : ''}province/${pageIndex}/${pageSize}${
      search ? `?search=${search}` : ''
    }`;
    const res = await ApiService.get<RegionBaseResponse<Province[]>>(url);
    return res.data;
  },
);

export const fetchProvinceDetail = createAsyncThunk(
  'region/province-detail',
  async ({ code, version }: ProvinceProps) => {
    const url = `/${version ? `v${version}/` : ''}province/${code}`;
    const res = await ApiService.get<Province>(url);
    return res.data;
  },
);

export const fetchCityList = createAsyncThunk(
  'region/city-list',
  async ({ provinceCode, pageIndex, pageSize, search, version }: CityListProps) => {
    const url = `/${
      version ? `v${version}/` : ''
    }city/${provinceCode}/${pageIndex}/${pageSize}${search ? `?search=${search}` : ''}`;
    const res = await ApiService.get<RegionBaseResponse<City[]>>(url);
    return res.data;
  },
);

export const fetchCityDetail = createAsyncThunk(
  'region/city-detail',
  async ({ code, version }: CityProps) => {
    const url = `/${version ? `v${version}/` : ''}city/${code}`;
    const res = await ApiService.get<City>(url);
    return res.data;
  },
);


export const fetchSubDistrictList = createAsyncThunk(
  'region/sub-district-list',
  async ({ cityCode, pageIndex, pageSize, search, version }: SubDistrictListProps) => {
    const url = `/${
      version ? `v${version}/` : ''
    }sub-district/${cityCode}/${pageIndex}/${pageSize}${search ? `?search=${search}` : ''}`;
    const res = await ApiService.get<RegionBaseResponse<SubDistrict[]>>(url);
    return res.data;
  },
);

export const fetchSubDistrictDetail = createAsyncThunk(
  'region/sub-district-detail',
  async ({ code, version }: SubDistrictProps) => {
    const url = `/${version ? `v${version}/` : ''}sub-district/${code}`;
    const res = await ApiService.get<SubDistrict>(url);
    return res.data;
  },
);
