import { Avatar } from 'components/styled/avatar.styled';
import { Stack } from 'components/styled/layout.styled';
import { IconSource } from 'modules/assetpath';
import React, { FC, useRef } from 'react';
import { InputDates, BorderInput, Label } from './styles';

export interface InputDateProps {
  ids?: string;
  type?: string;
  placeholder?: string;
  label?: string;
  value?: string;
  icon?: 'ic-calendar-dark' | 'ic-time-dark';
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  styeled?: React.CSSProperties;
}
export const InputDate: FC<InputDateProps> = ({
  ids,
  label,
  value,
  placeholder,
  type,
  icon,
  onChange,
  styeled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <BorderInput style={styeled} height="60px">
      <Label>{label}</Label>
      <Stack align="between">
        <InputDates
          ref={inputRef}
          id={ids}
          icon={icon}
          type="text"
          placeholder={placeholder}
          value={value}
          onFocus={e => {
            e.currentTarget.type = type!!;
          }}
          onBlur={e => {
            e.currentTarget.value === '' && (e.currentTarget.type = 'text');
            e.currentTarget.type === 'time' && (e.currentTarget.type = 'text');
          }}
          onChange={onChange}
          onClick={e => e.currentTarget.focus()}
          data-date-inline-picker="false"
          data-date-popover={{
            inline: false,
          }}
        />
        <Avatar
          src={IconSource(icon!!)}
          width="24px"
          height="24px"
          margin="0px 12px 0px 0px"
          style={{
            right: '0px',
            position: 'absolute',
            filter: 'invert(1)',
          }}
          onClick={() => {
            inputRef!!.current!!.focus();
          }}
        />
      </Stack>
    </BorderInput>
  );
};

export default InputDate;
