import { Box, Stack, Modal as MUIModal, Fade, Backdrop } from '@mui/material';
import { AxiosError } from 'axios';
import { LayoutContext } from 'context/LayoutContext';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { TextInput } from 'design/Forms/TextInput';
import { UploadImage } from 'design/Forms/UploadImage/index';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle, WarningStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import _ from 'lodash';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useEffect, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getErrorMessageLocale } from 'redux/ErrorHelper';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { setTeamLogoAction, setInfoMessageAction } from 'redux/team/TeamDraftReducer';
import { updateTeamAvatar } from 'redux/team/TeamService';
import { resetTeamDetail } from 'redux/teamProfile/TeamProfileReducer';
import { fetchTeamMember } from 'redux/teamProfile/TeamProfileService';
import TeamManagementService from 'services/TeamManagementService';
import TeamBody from 'types/API/request/TeamRequest';
import { EditTeamFormOptions } from 'validation/team/CreateTeamValidation';

const EditTeamPage = () => {
  const { control, handleSubmit, watch } = useForm(EditTeamFormOptions);
  const dispatch = useAppThunkDispatch();
  const { t } = useTranslation();
  const { setValue } = useContext(LayoutContext);
  const { team_slug, game_slug } = useParams() as { team_slug: string; game_slug: string };
  const location = useLocation<any>();
  const [getErrorUploadPhoto, setErrorUploadPhoto] = useState<any>(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [getErrorWhatsapp, setErrorWhatsapp] = useState<any>('');
  const [openToast, setOpenToast] = useState(false);
  const [toastMessages, setToastMessages] = useState('');
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [toastErrorMessages, setToastErrorMessages] = useState<any>('');
  const [imageEdited, setImageEdited] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { playerInfo, teamDetail } = useAppSelector(({ teamProfile, account }) => ({
    teamDetail: teamProfile?.teamDetail,
    playerInfo: account?.data,
  }));

  const valueWhatsAppGroupUrl = String(watch(['WhatsAppGroupUrl']));
  const valueDiscordUrl = String(watch(['DiscordUrl']));
  const valueWhatsAppPhoneNumber = String(watch(['WhatsAppPhoneNumber']));

  const handleFetchTeamMember = (teamSlug: string) => {
    dispatch(
      fetchTeamMember({
        approved: false,
        teamSlug: teamSlug,
        pageIndex: 1,
        pageSize: 20,
        version: '',
      }),
    );
  };

  const saveTeamLogo = React.useCallback(
    (teamLogoUrl: string) => dispatch(setTeamLogoAction({ teamLogoUrl: teamLogoUrl })),
    [dispatch],
  );

  const saveInfoMessage = React.useCallback(
    (show: boolean, message: string) =>
      dispatch(setInfoMessageAction({ infoMessage: { show: show, message: message } })),
    [dispatch],
  );

  function checkPhoneFormat(phNum: string) {
    let prefixFormat = '08';
    let prefix = phNum.slice(0, 2);

    if (prefix != prefixFormat) {
      return false;
    }
    return true;
  }

  const handleOpenToast = (openToast: boolean, openErrorToast: boolean) => {
    if (openToast) {
      setOpenErrorToast(false);
      return openToast;
    }
    if (openErrorToast) {
      setOpenToast(false);
      return openErrorToast;
    }
    return false;
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setOpenErrorToast(false);
  };

  const parseError = (errors: any) => {
    if (errors) {
      let errorsList: string[] = [];
      Object.values(errors).forEach((prop: any) => {
        errorsList = errorsList.concat(_.map(prop, _.property('message')));
      });
      return errorsList.join('\r\n');
    }
    return null;
  };

  const onInvalidCreate = (err: any) => {
    if (process.env.NODE_ENV === 'development') console.error(err);
  };

  const handleFileChange = (b: Blob) => {
    if (imageEdited) {
      updateTeamAvatar({
        Id: teamDetail && teamDetail?.id ? teamDetail?.id : '',
        AvatarFile: b,
      }).then(() => {});
    }
  };

  const updateTeam = (data: any) => {
    if (Boolean(getErrorUploadPhoto) == false) {
      if (getErrorWhatsapp == false) {
        savingAllData(data);
      }
    }
  };

  const savingAllData = (data: any) => {
    setOpenLoader(true);
    const teamBody: TeamBody = {
      id: teamDetail && teamDetail?.id ? teamDetail?.id : '',
      whatsAppGroupUrl: data.WhatsAppGroupUrl,
      whatsAppPhoneNumber: data.WhatsAppPhoneNumber,
      discordUrl: data.DiscordUrl,
    };

    if (teamBody) {
      // update image avatar
      handleFileChange(data.teamLogoUrl);
      // update ither data
      TeamManagementService.updateTeamPut(teamBody)
        .then(async res => {
          if (res.status <= 204) {
            dispatch(resetTeamDetail());
            if (teamDetail) {
              setToastMessages('');
              saveInfoMessage(true, t('success_change_profile'));
              setTimeout(() => {
                handleFetchTeamMember(team_slug);
                setOpenLoader(false);
                history.replace(`/team/${game_slug}/${teamDetail?.school.urlSlug}/${team_slug}`);
              }, 2000);
            }
          }
        })
        .catch((err: AxiosError) => {
          setOpenLoader(false);
          if (err.response && err.response?.status >= 400) {
            if (process.env.NODE_ENV === 'development') {
              console.log(getErrorMessageLocale(err.response.data.errors));
              console.log(parseError(err.response.data.errors));
            }
            setToastErrorMessages(err.response.data);
            setOpenErrorToast(true);
          }
        });
    }
  };

  useEffect(() => {
    if (
      valueWhatsAppPhoneNumber.length > 1 &&
      checkPhoneFormat(valueWhatsAppPhoneNumber) == false
    ) {
      return setErrorWhatsapp('Format No HP salah');
    }
    return setErrorWhatsapp(false);
  }, [watch(['WhatsAppPhoneNumber'])]);

  const whatsAppGroupUrlData = teamDetail?.whatsAppGroupUrl ?? '';
  const discordUrlData = teamDetail?.discordUrl ?? '';

  useEffect(() => {
    if (
      imageEdited ||
      valueWhatsAppGroupUrl != whatsAppGroupUrlData ||
      valueDiscordUrl !== discordUrlData ||
      valueWhatsAppPhoneNumber !== teamDetail?.whatsAppPhoneNumber
    ) {
      return setButtonDisabled(false);
    }

    return setButtonDisabled(true);
  }, [imageEdited, valueWhatsAppGroupUrl, valueDiscordUrl, valueWhatsAppPhoneNumber]);

  useEffect(() => {
    setValue({
      pageTitle: `MABAR | Informasi Tim`,
      appBar: {
        logo: false,
        title: (
          <Stack direction="column" sx={{ textAlign: 'center' }}>
            <Text variant="h3" sx={{ fontWeight: 700, letterSpacing: '0.12px' }}>
              {t('team_create_Header_top')}
            </Text>
          </Stack>
        ),
        back: {
          to:
            location.state?.pathname ??
            `/team/${game_slug}/${
              teamDetail && teamDetail?.school.urlSlug ? teamDetail?.school.urlSlug : undefined
            }/${team_slug}`,
        },
        separator: true,
      },
      background: {
        color: '#131318',
      },
      padding: '16px',
      bottomBar: {
        show: false,
      },
      additional: (
        <>
          <Toast
            show={handleOpenToast(openToast, openErrorToast)}
            onClose={handleCloseToast}
            duration={3000}
          >
            {openToast && (
              <Ribbon
                icon={IconSource('check-gameon')}
                label={
                  <Text variant="body" sx={{ color: 'success900' }}>
                    {toastMessages}
                  </Text>
                }
                sx={GameOnToastStyle}
              />
            )}
            {openErrorToast && (
              <Ribbon
                label={
                  <Text variant="body" sx={{ color: 'success900' }}>
                    {toastErrorMessages}
                  </Text>
                }
                sx={WarningStyle}
              />
            )}
          </Toast>
        </>
      ),
    });
  }, [dispatch]);

  useEffect(() => {
    if (typeof teamDetail == 'undefined') {
      history.push('/my-team');
    }
    if (playerInfo) {
      if (playerInfo?.games.length <= 0) history.push('/account');
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'neutral700',
          border: '1px solid #242628',
          borderRadius: '8px',
          p: '12px 12px 23px 12px',
        }}
      >
        <Text variant="label">
          {t('team_create_game_data', {
            game_name: playerInfo?.games[0].game.name,
          })}
        </Text>
        {playerInfo?.games?.map(item => {
          return (
            <>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  width: '100%',
                  mt: '16px',
                  mb: '10px',
                }}
              >
                <Text
                  variant="label"
                  sx={{
                    width: 'fit-content',
                    mr: '12px',
                    whiteSpace: 'nowrap',
                    color: 'neutral400',
                  }}
                >
                  {t('team_create_game_data_id')}
                </Text>
                <Text
                  variant="subtitle"
                  sx={{
                    width: '100%',
                    textAlign: 'right',
                    wordWrap: 'break-word',
                    overflow: 'auto',
                    color: 'neutral200',
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {item.gameProviderId}
                </Text>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Text
                  variant="label"
                  sx={{
                    width: 'fit-content',
                    mr: '12px',
                    whiteSpace: 'nowrap',
                    color: 'neutral400',
                  }}
                >
                  {t('team_create_game_data_nickname')}
                </Text>
                <Text
                  variant="subtitle"
                  sx={{
                    width: '100%',
                    textAlign: 'right',
                    wordWrap: 'break-word',
                    overflow: 'auto',
                    color: 'neutral200',
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {item.nickName}
                </Text>
              </Stack>
            </>
          );
        })}
      </Box>

      <form noValidate autoComplete="off" autoCapitalize="off">
        <Box
          mt={'16px'}
          sx={{
            bgcolor: 'neutral700',
            border: '1px solid #242628',
            borderRadius: '8px',
            p: '12px 12px 24px 12px',
          }}
        >
          <Text variant="label" sx={{ mb: '4px' }}>
            {t('team_create_team_profile')}
          </Text>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              width: '100%',
              m: '16px 0px',
            }}
          >
            <Text
              variant="label"
              sx={{ width: 'fit-content', mr: '12px', whiteSpace: 'nowrap', color: 'neutral400' }}
            >
              {t('team_create_team_profile_school')}
            </Text>
            <Text
              variant="subtitle"
              sx={{
                width: '100%',
                textAlign: 'right',
                wordWrap: 'break-word',
                overflow: 'auto',
                color: 'neutral200',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {playerInfo?.school?.name}
            </Text>
          </Stack>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              width: '100%',
              m: '16px 0px',
            }}
          >
            <Text
              variant="label"
              sx={{ width: 'fit-content', mr: '12px', whiteSpace: 'nowrap', color: 'neutral400' }}
            >
              {t('team_create_team_name')}
            </Text>
            <Text
              variant="subtitle"
              sx={{
                width: '100%',
                textAlign: 'right',
                wordWrap: 'break-word',
                overflow: 'auto',
                color: 'neutral200',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {teamDetail && teamDetail?.name ? teamDetail?.name : ''}
            </Text>
          </Stack>
          <Controller
            control={control}
            name="teamLogoUrl"
            defaultValue={teamDetail && teamDetail.avatarUrl ? teamDetail.avatarUrl : ''}
            render={({ field: { onChange, ref } }) => (
              <UploadImage
                inputRef={ref}
                defaultImage={teamDetail && teamDetail.avatarUrl ? teamDetail.avatarUrl : ''}
                getImageSource={v => {
                  setImageEdited(true);
                  saveTeamLogo(v);
                }}
                getImageBlob={v => onChange(v)}
                onImageLoad={function noRefCheck() {}}
                title={<Text>Upload Logo Tim</Text>}
                getError={v => setErrorUploadPhoto(v)}
                variant="icon"
              />
            )}
          />
        </Box>

        <Box
          mt={'16px'}
          sx={{
            bgcolor: 'neutral700',
            border: '1px solid #242628',
            borderRadius: '8px',
            p: '12px 12px 4px 12px',
          }}
        >
          <Text variant="label" sx={{ mb: '4px', display: 'block' }}>
            {t('team_create_team_contact_header')}
          </Text>
          <Text variant="subtitle" sx={{ color: 'neutral400', mb: '16px' }}>
            {t('team_create_team_contact_content')}
          </Text>
          <Box>
            <Controller
              control={control}
              name="WhatsAppGroupUrl"
              defaultValue={
                teamDetail && teamDetail.whatsAppGroupUrl ? teamDetail.whatsAppGroupUrl : ''
              }
              render={({ field: { onChange, ref }, formState: { errors } }) => (
                <>
                  <TextInput
                    id="inputnwagrp"
                    label={t('team_create_team_contact_whatsapp')}
                    defaultValue={
                      teamDetail && teamDetail.whatsAppGroupUrl ? teamDetail.whatsAppGroupUrl : ''
                    }
                    onChange={onChange}
                    inputRef={ref}
                  />
                  {errors.WhatsAppGroupUrl && (
                    <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                      {errors?.WhatsAppGroupUrl?.message}
                    </Text>
                  )}
                </>
              )}
            />
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '4px' }}>
              Isi dengan <b>Nomor WhatsApp</b> kamu atau <b>link WhatsApp Group</b> tim kamu"
            </Text>
          </Box>
          <Box mt="12px">
            <Controller
              control={control}
              name="DiscordUrl"
              defaultValue={teamDetail && teamDetail.discordUrl ? teamDetail.discordUrl : ''}
              render={({ field: { onChange, ref } }) => (
                <TextInput
                  id="inputdiscord"
                  label={t('team_create_team_contact_discord')}
                  defaultValue={teamDetail && teamDetail.discordUrl ? teamDetail.discordUrl : ''}
                  onChange={onChange}
                  inputRef={ref}
                />
              )}
            />
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '4px' }}>
              {t('team_create_team_contact_discord_notice')}
            </Text>
          </Box>
        </Box>

        <Box
          mt={'16px'}
          sx={{
            bgcolor: 'neutral700',
            border: '1px solid #242628',
            borderRadius: '8px',
            p: '12px 12px 4px 12px',
          }}
        >
          <Text variant="label" sx={{ mb: '4px', display: 'block' }}>
            {t('team_create_opponent_contact_header')}
          </Text>
          <Text variant="subtitle" sx={{ color: 'neutral400', mb: '16px' }}>
            {t('team_create_opponent_contact_content')}
          </Text>
          <Box>
            <Controller
              control={control}
              name="WhatsAppPhoneNumber"
              defaultValue={
                teamDetail && teamDetail.whatsAppPhoneNumber ? teamDetail.whatsAppPhoneNumber : ''
              }
              render={({ field: { onChange, ref }, formState: { errors } }) => (
                <>
                  <TextInput
                    id="inputwaph"
                    label={t('team_create_opponent_contact_whatsapp')}
                    defaultValue={
                      teamDetail && teamDetail.whatsAppPhoneNumber
                        ? teamDetail.whatsAppPhoneNumber
                        : ''
                    }
                    onChange={onChange}
                    inputRef={ref}
                    error={getErrorWhatsapp || errors.WhatsAppPhoneNumber}
                  />
                  {(errors.WhatsAppPhoneNumber || getErrorWhatsapp) && (
                    <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                      {errors?.WhatsAppPhoneNumber?.message ?? getErrorWhatsapp}
                    </Text>
                  )}
                </>
              )}
            />
          </Box>
        </Box>
        <PrimaryButton
          wide
          sx={{ m: '24px 0px' }}
          onClick={handleSubmit(updateTeam, onInvalidCreate)}
          disabled={buttonDisabled}
        >
          {t('team_create_next')}
        </PrimaryButton>
      </form>

      <MUIModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLoader}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'transparent',
        }}
      >
        <Fade in={openLoader}>
          <img src={IconSource('ic-loading')} style={{ position: 'relative', outline: 0 }} />
        </Fade>
      </MUIModal>
    </>
  );
};

export default EditTeamPage;
