import { RouteComponentProps } from 'react-router-dom';
import { IconSource } from '../modules/assetpath';

export interface RouteType {
  id: number;
  title?: string;
  icon?: string;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined;
  path?: string;
  isSelected?: boolean;
}

export const HomeRouteNav: RouteType[] = [
  { id: 1, title: 'Home', icon: IconSource('home'), isSelected: true, path: '/home' },
  { id: 2, title: 'Juara', icon: IconSource('trophy'), isSelected: false },
  { id: 3, title: 'Pertandingan', icon: IconSource('rocket'), isSelected: false },
  { id: 4, title: 'Team', icon: IconSource('union'), isSelected: false },
  { id: 5, title: 'Profile', icon: IconSource('person'), isSelected: false },
];
