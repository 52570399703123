import { Box, Stack, Grid, Avatar } from '@mui/material';
import copy from 'copy-to-clipboard';
import { TextButton } from 'design/Buttons/TextButton';
import { Player } from 'design/Cards/Player';
import { Ribbon } from 'design/Ribbon';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource } from 'modules/assetpath';
import { limitedChar } from 'modules/helpers';
import React, { useState, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';

export interface CardPlayerDetailProps {
  playerName?: string;
  playerPhoto?: string;
  isCaptain?: boolean;
  gameId: string;
  nickname: string;
  url?: string;
  cta?: ReactNode;
}

export const CardPlayerDetail = ({
  playerName,
  playerPhoto,
  isCaptain,
  gameId,
  nickname,
  url,
  cta,
}: CardPlayerDetailProps) => {
  const [open, setOpen] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState('');

  const handleClose: any = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const copyProfileData = (value: string, message: string) => {
    if (value) {
      if (copy(value)) {
        setCopiedMessage(message);
        setOpen(true);
      }
    }
  };

  const PlayerNameContent = (
    <Text
      variant="label"
      sx={{
        fontWeight: '700',
        color: 'white',
        width: '100%',
      }}
    >
      {limitedChar(playerName, 60)}
    </Text>
  );

  const handlelinkPlayerName = useCallback(() => {
    if (url) {
      return (
        <Link to={url} style={{ display: 'flex' }}>
          {PlayerNameContent}
        </Link>
      );
    }
    return PlayerNameContent;
  }, [url]);

  const handlelinkCta = useCallback(() => {
    if (url) {
      return (
        <Link to={url} style={{ display: 'flex' }}>
          <TextButton icon={IconSource('ic-chevron-right')} />
        </Link>
      );
    }
    return <TextButton icon={IconSource('ic-chevron-right')} />;
  }, [url]);

  return (
    <Box>
      <Player
        playerName={handlelinkPlayerName()}
        playerPhoto={playerPhoto}
        isCaptain={isCaptain}
        sx={{ padding: '0 0 16px' }}
        cta={handlelinkCta()}
        textElipsis={false}
      />
      <Grid container sx={{ pb: '24px' }}>
        <Grid item xs={6}>
          <Text variant="label" sx={{ display: 'block', mb: '2px' }}>
            Game Nickname
          </Text>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
              display: 'inline-flex',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 'calc(100% - 14px)',
            }}
            onClick={() => copyProfileData(nickname, 'Game Nickname berhasil disalin')}
          >
            <Text
              sx={theme => ({
                color: theme.palette.neutral400,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              })}
            >
              {nickname}
            </Text>
            <Avatar
              src={IconSource('ic-copy')}
              sx={{ width: '14px', height: '14px', ml: '4px', borderRadius: 0 }}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Text variant="label" sx={{ display: 'block', mb: '2px' }}>
            Game ID
          </Text>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
              display: 'inline-flex',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 'calc(100% - 14px)',
            }}
            onClick={() => copyProfileData(gameId, 'Game ID berhasil disalin')}
          >
            <Text
              sx={theme => ({
                color: theme.palette.neutral400,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              })}
            >
              {gameId}
            </Text>
            <Avatar
              src={IconSource('ic-copy')}
              sx={{ width: '14px', height: '14px', ml: '4px', borderRadius: 0 }}
            />
          </Stack>
        </Grid>
      </Grid>
      {cta}
      <Toast show={open} onClose={handleClose} key={copiedMessage ?? undefined}>
        <Ribbon
          label={
            <Text variant="body" sx={{ color: '#ffffff' }}>
              {copiedMessage}
            </Text>
          }
          sx={theme => ({
            background: theme.palette.neutral500,
            padding: '10px 12px',
            borderRadius: '8px',
            minHeight: '20px',
          })}
        />
      </Toast>
    </Box>
  );
};
