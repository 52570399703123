import { Snackbar, Box } from '@mui/material';
import React, { ReactNode } from 'react';

const toastWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '10% !important',
  width: '100%',
  maxWidth: '500px',
  left: 0,
};

interface ToastType {
  children?: ReactNode;
  show: boolean;
  onClose?: () => void;
  top?: string;
  key?: any;
  duration?: number | null;
}

export function Toast({ children, show, onClose, top, key, duration }: ToastType) {
  return (
    <Snackbar
      open={show}
      autoHideDuration={duration ?? 2000}
      transitionDuration={{ enter: 300, exit: 500 }}
      sx={{ ...toastWrapperStyle, ...(top && { top: top }) }}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key={key}
    >
      <Box height="fit-content" minWidth="max-content" width="91.47%">
        {children}
      </Box>
    </Snackbar>
  );
}
