import { Box, Stack } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { CardImportantInfo } from 'design/Cards/CardImportantInfo';
import { TextInput } from 'design/Forms/TextInput';
import { UploadImage } from 'design/Forms/UploadImage/index';
import { Text } from 'design/Text';
import _ from 'lodash';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import {
  setTeamDiscordUrlAction,
  setTeamLogoAction,
  setTeamNameAction,
  setTeamWhatsAppGroupUrlAction,
  setTeamWhatsAppPhoneNumberAction,
} from 'redux/team/TeamDraftReducer';
import { postCheckTeamName } from 'redux/team/TeamService';
import { CreateTeamFormOptions } from 'validation/team/CreateTeamValidation';

const TeamCreatePage = () => {
  const { t } = useTranslation();
  const { setValue } = useContext(LayoutContext);
  const location = useLocation<any>();
  const dispatch = useAppThunkDispatch();
  const { handleSubmit, control, watch } = useForm(CreateTeamFormOptions);

  const [getErrorWhatsapp, setErrorWhatsapp] = useState<any>('');
  const [showTeamNameCheckStatus, setTeamNameCheckStatus] = useState(false);
  const [getErrorTeamName, setErrorTeamName] = useState(false);
  const [getErrorTeamNameMessage, setErrorTeamNameMessage] = useState(false);
  const [getErrorUploadPhoto, setErrorUploadPhoto] = useState<any>(false);
  const [getEmptyUploadPhoto, setEmptyUploadPhoto] = useState<any>('');

  const TeamStatusIcon = ({ show, type }: any) => {
    if (show) {
      if (type) {
        return <img src={IconSource('ic-close-circle')} height="17.5px" width="17.5px" />;
      } else {
        return <img src={IconSource('ic-check-green')} height="17.5px" width="17.5px" />;
      }
    }
    return null;
  };

  useEffect(() => {
    setValue({
      pageTitle: `MABAR | Informasi Tim`,
      appBar: {
        logo: false,
        title: (
          <Stack direction="column" sx={{ textAlign: 'center' }}>
            <Text variant="h3" sx={{ fontWeight: 700, letterSpacing: '0.12px' }}>
              {t('team_create_Header_top')}
            </Text>
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '2px' }}>
              {t('team_create_step', {
                page: '1',
                pages: '2',
              })}
            </Text>
          </Stack>
        ),
        back: {
          to: location.state?.pathname ?? `/create-team/team-list`,
        },
        separator: true,
      },
      background: {
        color: '#131318',
      },
      padding: '16px',
      bottomBar: {
        show: false,
      },
    });
  }, [dispatch]);

  const { playerInfo, teamFormInfo } = useAppSelector(({ account, teamDraft }) => ({
    playerInfo: account.data,
    teamFormInfo: teamDraft,
  }));

  function onChangeTeamName(teamName: string) {
    const teamNameCheckThunk = postCheckTeamName({
      name: teamName,
    });
    dispatch(teamNameCheckThunk)
      .unwrap()
      .then(res => {
        if (res && res.isAxiosError) {
          setErrorTeamName(true);
          if (res.data?.errCode >= 400) {
            setErrorTeamNameMessage(true);
          }
        } else {
          setErrorTeamName(false);
        }
      })
      .catch(() => {
        // error lainnya
      });
  }

  useEffect(() => {
    setTeamNameCheckStatus(false);
    setErrorTeamNameMessage(false);
    if (String(watch(['teamName'])).length > 1 && watch(['teamName']) != undefined) {
      setTeamNameCheckStatus(true);
      onChangeTeamName(String(watch(['teamName'])));
    }
    if (String(watch(['teamName'])).length == 0) {
      setErrorTeamName(false);
    }
  }, [watch('teamName')]);

  useEffect(() => {
    setEmptyUploadPhoto('');
  }, [watch('teamLogoUrl')]);

  function checkPhoneFormat(phNum: string) {
    let prefixFormat = '08';
    let prefix = phNum.slice(0, 2);

    if (prefix != prefixFormat) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (String(watch(['WhatsAppPhoneNumber'])).length > 10) {
      checkPhoneFormat(String(watch(['WhatsAppPhoneNumber'])));
    }
    if (String(watch(['WhatsAppPhoneNumber'])).length < 11) {
      setErrorWhatsapp(false);
    }
  }, [watch('WhatsAppPhoneNumber')]);

  const saveTeamLogo = React.useCallback(
    (teamLogoUrl: string) => dispatch(setTeamLogoAction({ teamLogoUrl: teamLogoUrl })),
    [dispatch],
  );

  function savingAllDataToRedux(data: any) {
    dispatch(setTeamWhatsAppGroupUrlAction({ whatsAppGroupUrl: data.WhatsAppGroupUrl }));
    dispatch(setTeamWhatsAppPhoneNumberAction({ whatsAppPhoneNumber: data.WhatsAppPhoneNumber }));
    dispatch(setTeamDiscordUrlAction({ discordUrl: data.DiscordUrl }));
    dispatch(setTeamNameAction({ teamName: data.teamName }));

    history.push('/create-team/choose-player');
  }

  const saveCompleteData = (data: any) => {
    if (!getErrorTeamName && Boolean(getErrorUploadPhoto) == false) {
      if (teamFormInfo.teamLogoUrl !== '') {
        if (checkPhoneFormat(data.WhatsAppPhoneNumber)) {
          savingAllDataToRedux(data);
        } else {
          setErrorWhatsapp('Format No HP salah');
        }
      } else {
        setEmptyUploadPhoto('Logo tim tidak boleh kosong');
      }
    }
  };

  useEffect(() => {
    if (playerInfo) {
      if (playerInfo?.games.length <= 0) history.push('/account');

      if (teamFormInfo) {
        if (teamFormInfo?.game.id == '') history.push('/my-team');
      }
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'neutral700',
          border: '1px solid #242628',
          borderRadius: '8px',
          p: '12px 12px 23px 12px',
        }}
      >
        <Text variant="label">
          {t('team_create_game_data', {
            game_name: teamFormInfo?.game.name,
          })}
        </Text>
        {playerInfo?.games?.map(it => {
          return (
            it.game.id == teamFormInfo?.game.id && (
              <>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    mt: '16px',
                    mb: '10px',
                  }}
                >
                  <Text
                    variant="label"
                    sx={{
                      width: 'fit-content',
                      mr: '12px',
                      whiteSpace: 'nowrap',
                      color: 'neutral400',
                    }}
                  >
                    {t('team_create_game_data_id')}
                  </Text>
                  <Text
                    variant="subtitle"
                    sx={{
                      width: '100%',
                      textAlign: 'right',
                      wordWrap: 'break-word',
                      overflow: 'auto',
                      color: 'neutral200',
                      fontSize: '14px',
                      lineHeight: '20px',
                    }}
                  >
                    {it.gameProviderId}
                  </Text>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Text
                    variant="label"
                    sx={{
                      width: 'fit-content',
                      mr: '12px',
                      whiteSpace: 'nowrap',
                      color: 'neutral400',
                    }}
                  >
                    {t('team_create_game_data_nickname')}
                  </Text>
                  <Text
                    variant="subtitle"
                    sx={{
                      width: '100%',
                      textAlign: 'right',
                      wordWrap: 'break-word',
                      overflow: 'auto',
                      color: 'neutral200',
                      fontSize: '14px',
                      lineHeight: '20px',
                    }}
                  >
                    {it.nickName}
                  </Text>
                </Stack>
              </>
            )
          );
        })}
      </Box>

      <form noValidate autoComplete="off" autoCapitalize="off">
        <Box
          mt={'16px'}
          sx={{
            bgcolor: 'neutral700',
            border: '1px solid #242628',
            borderRadius: '8px',
            p: '12px 12px 24px 12px',
          }}
        >
          <Text variant="label" sx={{ mb: '4px' }}>
            {t('team_create_team_profile')}
          </Text>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              width: '100%',
              m: '16px 0px',
            }}
          >
            <Text
              variant="label"
              sx={{ width: 'fit-content', mr: '12px', whiteSpace: 'nowrap', color: 'neutral400' }}
            >
              {t('team_create_team_profile_school')}
            </Text>
            <Text
              variant="subtitle"
              sx={{
                width: '100%',
                textAlign: 'right',
                wordWrap: 'break-word',
                overflow: 'auto',
                color: 'neutral200',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {playerInfo?.school?.name}
            </Text>
          </Stack>
          <Controller
            control={control}
            name="teamLogoUrl"
            render={({ field: { onChange, ref } }) => (
              <>
                <UploadImage
                  variant="icon"
                  inputRef={ref}
                  defaultImage={teamFormInfo?.teamLogoUrl ?? undefined}
                  getImageSource={v => saveTeamLogo(v)}
                  getImageBlob={v => onChange(v)}
                  onImageLoad={function noRefCheck() {}}
                  getError={v => setErrorUploadPhoto(v)}
                />
                {getEmptyUploadPhoto ? (
                  <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                    {getEmptyUploadPhoto}
                  </Text>
                ) : null}
              </>
            )}
          />
          <CardImportantInfo
            title={t('team_create_important_info')}
            content={t('team_create_important_info_content')}
          />
          <Box sx={{ position: 'relative' }}>
            <Controller
              control={control}
              name="teamName"
              defaultValue={`${teamFormInfo?.teamName}`}
              render={({ field: { onChange, ref }, formState: { errors } }) => (
                <>
                  <TextInput
                    id="inputnnamatim"
                    label={t('team_create_team_name')}
                    defaultValue={`${teamFormInfo?.teamName}`}
                    onChange={_.debounce(onChange, 500)}
                    inputRef={ref}
                    InputProps={{
                      endAdornment: (
                        <TeamStatusIcon show={showTeamNameCheckStatus} type={getErrorTeamName} />
                      ),
                    }}
                    error={getErrorTeamName || errors.teamName}
                  />
                  {(errors.teamName || getErrorTeamNameMessage) && (
                    <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                      {getErrorTeamNameMessage
                        ? t('team_create_error_team_name')
                        : errors?.teamName?.message}
                    </Text>
                  )}
                </>
              )}
            />
          </Box>
        </Box>

        <Box
          mt={'16px'}
          sx={{
            bgcolor: 'neutral700',
            border: '1px solid #242628',
            borderRadius: '8px',
            p: '12px',
          }}
        >
          <Text variant="label" sx={{ mb: '4px', display: 'block' }}>
            {t('team_create_team_contact_header')}
          </Text>
          <Text variant="subtitle" sx={{ color: 'neutral400', mb: '16px' }}>
            {t('team_create_team_contact_content')}
          </Text>
          <Box>
            <Controller
              control={control}
              name="WhatsAppGroupUrl"
              defaultValue={`${teamFormInfo?.WhatsAppGroupUrl}`}
              render={({ field: { onChange, ref }, formState: { errors } }) => (
                <>
                  <TextInput
                    id="inputnwagrp"
                    label={t('team_create_team_contact_whatsapp')}
                    defaultValue={`${teamFormInfo?.WhatsAppGroupUrl}`}
                    onChange={onChange}
                    inputRef={ref}
                  />
                  {errors.WhatsAppGroupUrl && (
                    <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                      {errors?.WhatsAppGroupUrl?.message}
                    </Text>
                  )}
                </>
              )}
            />
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '4px' }}>
              Isi dengan <b>Nomor WhatsApp</b> kamu atau <b>link WhatsApp Group</b> tim kamu"
            </Text>
          </Box>
          <Box mt="12px">
            <Controller
              control={control}
              name="DiscordUrl"
              defaultValue={`${teamFormInfo?.DiscordUrl}`}
              render={({ field: { onChange, ref } }) => (
                <TextInput
                  id="inputdiscord"
                  label={t('team_create_team_contact_discord')}
                  defaultValue={`${teamFormInfo?.DiscordUrl}`}
                  onChange={onChange}
                  inputRef={ref}
                />
              )}
            />
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '4px' }}>
              {t('team_create_team_contact_discord_notice')}
            </Text>
          </Box>
        </Box>

        <Box
          mt={'16px'}
          sx={{
            bgcolor: 'neutral700',
            border: '1px solid #242628',
            borderRadius: '8px',
            p: '12px',
          }}
        >
          <Text variant="label" sx={{ mb: '4px', display: 'block' }}>
            {t('team_create_opponent_contact_header')}
          </Text>
          <Text variant="subtitle" sx={{ color: 'neutral400', mb: '16px' }}>
            {t('team_create_opponent_contact_content')}
          </Text>
          <Box>
            <Controller
              control={control}
              name="WhatsAppPhoneNumber"
              defaultValue={`${teamFormInfo?.WhatsAppPhoneNumber}`}
              render={({ field: { onChange, ref }, formState: { errors } }) => (
                <>
                  <TextInput
                    id="inputwaph"
                    label={t('team_create_opponent_contact_whatsapp')}
                    defaultValue={`${teamFormInfo?.WhatsAppPhoneNumber}`}
                    onChange={onChange}
                    inputRef={ref}
                    error={getErrorWhatsapp || errors.WhatsAppPhoneNumber}
                    inputProps={{
                      maxLength: 13,
                    }}
                  />
                  {errors.WhatsAppPhoneNumber || getErrorWhatsapp ? (
                    <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                      {errors?.WhatsAppPhoneNumber?.message ?? getErrorWhatsapp}
                    </Text>
                  ) : (
                    <Text variant="subtitle" sx={{ color: 'neutral400', mt: '4px' }}>
                      {t('team_create_opponent_contact_whatsapp_notice')}
                    </Text>
                  )}
                </>
              )}
            />
          </Box>
        </Box>
        <PrimaryButton wide sx={{ m: '24px 0px' }} onClick={handleSubmit(saveCompleteData)}>
          {t('team_create_next')}
        </PrimaryButton>
      </form>
    </>
  );
};

export default TeamCreatePage;
