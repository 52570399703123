import React from 'react'
import { Box, Stack } from "components/styled/layout.styled";
import { NormalText } from "components/styled/text.styled";
import { useTranslation } from 'react-i18next'
import { IconSource } from 'modules/assetpath';
import { Avatar } from 'components/styled/avatar.styled';
import copy from 'copy-to-clipboard';
import { ButtonFBShare, ButtonEmailShare, ButtonTwitterShare, BorderIcon } from 'components/Share/styles';

export default function ShareModal(p: { setShowModal: (value: React.SetStateAction<boolean>) => void, link: string }) {
  const { t } = useTranslation()
  const fbRef = React.createRef<HTMLButtonElement>()
  const mailRef = React.createRef<HTMLButtonElement>()
  const twitterRef = React.createRef<HTMLButtonElement>()
  
  const [isCopied, setCopied] = React.useState(false)
  const hashtag = ['mabar', 'event', 'esports']
  function getLinkShare() {
    return p.link
  }
  function copyProfileURL() {
    if (copy(getLinkShare())) {
      setCopied(true)
    } else {
      setCopied(false)
    }
  }
  React.useLayoutEffect(()=> {
    if(fbRef.current){
      fbRef.current.setAttribute('style', "")
    }
    if(mailRef.current){
      mailRef.current.setAttribute('style', "")
    }
    if(twitterRef.current){
      twitterRef.current.setAttribute('style', "")
    }
  }, [fbRef.current, twitterRef.current, mailRef.current])
  return (
    <Box padding="24px 0px" width="100%">
      <Stack direction="column" margin="0px 24px">
        <Stack direction='row' align='between' margin='0px 0px 24px'>
          <NormalText
            textAlign="left"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight="bold"
            margin="0px 0px"
            style={{
              width: 'fit-content',
            }}
          >
            {t('general_share_title')}
          </NormalText>
          <Avatar src={IconSource('ic-close-white')} alt="" isPressable onClick={() => p.setShowModal(false)} />
        </Stack>
        <Box width="100%">
          <Stack direction='column' align='center'>
            {isCopied && (
              <NormalText fontSize='12px' fontWeight='500' lineHeight='17.52px' color='#FFFFFF' textAlign='right' margin='-10px 0px 8px'>
                {t('general_copy_url_success')}
              </NormalText>
            )}
            <Stack direction="row" align="center">
                <ButtonFBShare ref={fbRef}
                  url={getLinkShare()}
                  quote={'Ayo bermain bersama saya di platform MABAR!'} hashtag={hashtag.join(', ')} 
                  onClick={()=> setCopied(false)}>
                  <Avatar src={IconSource('facebook')} alt="facebook" width='5.69px' height='10.83px' />
                </ButtonFBShare>
                <ButtonTwitterShare ref={twitterRef}
                url={getLinkShare()} 
                title={'Ayo bermain bersama saya di platform MABAR!'} hashtags={hashtag} 
                onClick={()=> setCopied(false)}>
                  <Avatar src={IconSource('twitter')} alt="twitter" width='11px' height='9px' />
                </ButtonTwitterShare>
                <ButtonEmailShare ref={mailRef} url={getLinkShare()} openShareDialogOnClick 
                 onClick={()=> setCopied(false)}>
                  <Avatar src={IconSource('mail')} alt="email" width='10px' height='9px' />
                </ButtonEmailShare>
                <BorderIcon onClick={() => copyProfileURL()} >
                  <Avatar src={IconSource('link')} alt="clipboard-link-share" width='10px' height='10px'/>
                </BorderIcon>
            </Stack>

          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}