import styled from 'styled-components';

// layer 1
export const FrameScrimList = styled.article`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: max-content 160px 1fr;
  align-items: center;
  padding-left: 12px;
  padding-bottom: 16px;
  position: relative;
  width: auto;
  height: auto;
  background: linear-gradient(
    295.84deg,
    rgba(213, 31, 64, 0.2) 22.62%,
    rgba(243, 112, 36, 0.2) 74.92%
  );
  border-radius: 16px;
  margin: 0px 0px;
`;

export const FrameAtasList = styled.div`
  position: relative;
  width: auto;
  height: 6px;
  align-items: center;
  justify-content: center;

  background: linear-gradient(
    122.67deg,
    #d51f40 38.55%,
    #e24134 50.61%,
    #e8542e 59.52%,
    #f37024 66.6%
  );
  border-radius: 0px 0px 10px 10px;
  margin-left: 5%;
  margin-right: 8%;
`;

export const FrameIdScrim = styled.div`
  position: absolute;
  padding: 4px 6px;
  width: auto;
  height: auto;
  left: 0%;
  top: 0%;

  background: rgba(255, 255, 255, 0.16);
  border-radius: 6px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 20px;
`;

export const FrameTanggalScrim = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 6px;

  position: static;
  width: 87px;
  height: 25px;
  left: 0px;
  top: 0px;

  background: rgba(255, 255, 255, 0.16);
  border-radius: 6px;
`;
// layer 2
export const FrameScrimHistoryPertandingan = styled.article`
  display: grid;
  grid-template-rows: max-content 120px 1fr;
  align-items: center;
  padding-left: 16px;
  padding-bottom: 16px;
  position: relative;
  width: auto;
  height: auto;
  left: 0px;
  top: 0px;
  bottom: 50px;

  background: rgba(55, 57, 66, 0.6);
  border-radius: 16px;
`;

export const FrameWaktuPertandingan = styled.div`
  /* Auto Layout */
  float: right;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  position: absolute;
  width: auto;
  height: auto;
  top: 0px;
  right: 0%;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
  border-radius: 10px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 55px 16px;
`;
// Layer 3
export const FrameMatchList = styled.div`
  display: grid;
  grid-template-rows: max-content 120px 1fr;
  align-items: center;
  padding-left: 16px;
  padding-bottom: 16px;
  position: relative;
  width: auto;
  height: auto;

  background: rgba(55, 57, 66, 0.6);
  border-radius: 16px;
`;
