import { Avatar } from 'components/styled/avatar.styled'
import { Stack } from 'components/styled/layout.styled'
import { IconSource } from 'modules/assetpath'
import React from 'react'
import { useInView } from 'react-intersection-observer'

const delayPromise = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const DEFAULT_LOADING = () => <Stack direction="row" align="center">
<Avatar
  alt=""
  src={IconSource('ic-loader')}
  style={{ position: 'relative', zIndex: 199, bottom: '2%', backgroundSize: '100%' }}
/>
</Stack>

interface Props {
  /**
   * An async function whose Promise resolves to a truthy value. `true` indicates there's more data to load, while `false` indicates no more data to load.
   */
  loadMore: any
  /**
   * The component to display, when loading data.
   */
  renderLoading?: Function
  /**
   * The component to display, when there is no more data to load.
   */
  renderNoMoreData?: Function
  /**
   * Configuration for the 'react-intersection-observer'.
   */
  inViewConfig?: object
  /**
   * How long to wait to allow React to re-render components (which affects visibility), before re-checking whether to load more data.
   */
  reloadDelayMs?: number
  onLoadMore?: Function
  loading?: boolean;
  isNoMoreData?: boolean;
  style?: React.CSSProperties;
}

// const ExampleComponent = ({ text }: Props) => {
//   return <div>Example Component: {text}</div>
// }

// export default ExampleComponent

/**
 * @preserve
 * A visibility based trigger for loading more data.
 *
 * @param {function} props.loadMore An async function whose Promise resolves to a truthy value. `true` indicates there's more data to load, while `false` indicates no more data to load.
 * @param {function} props.renderLoading The component to display, when loading data.
 * @param {function} props.renderNoMoreData The component to display, when there is no more data to load.
 * @param {object} props.inViewConfig Configuration for the 'react-intersection-observer'.
 * @param {number} props.reloadDelayMs How long to wait to allow React to re-render components (which affects visibility), before re-checking whether to load more data.
 */
const ScrollLoadMore = ({
  loadMore,
  renderLoading: RenderLoading = DEFAULT_LOADING,
  renderNoMoreData: RenderNoMoreData,
  inViewConfig = {},
  reloadDelayMs = 5000,
  onLoadMore,
  loading,
  isNoMoreData,
  ...props
}: Props) => {
  // watch for when the div becomes visible.
  const [ref, inView] = useInView(inViewConfig)

  // used to determine whether to load more data.
  const isVisibleRef = React.useRef(false)
  const hasMoreRef = React.useRef(true)
  const isLoadingRef = React.useRef(loading)

  // used for updating the rendered content.
  const [noMoreData, setNoMoreData] = React.useState(isNoMoreData)

  // continuously loads data, while all conditions are met.
  const loadMoreRef = isVisibleRef.current ? React.useRef(async () => {
    if (isVisibleRef.current && hasMoreRef.current && !isLoadingRef.current) {
        if(process.env.NODE_ENV !== 'production')
            console.debug('ScrollLoadMore - loading...')
      isLoadingRef.current = true
      try {
        hasMoreRef.current = loadMore()
      } catch (err:any) {
        if(process.env.NODE_ENV !== 'production')
            console.error(`ScrollLoadMore caught error - ${err.message}`)
        hasMoreRef.current = false
      }
      if (!hasMoreRef.current) setNoMoreData(true)
      isLoadingRef.current = false
      // give results a chance to render, then check is we still need to load more data...
      delayPromise(reloadDelayMs).then(loadMoreRef.current)
    } else {
      if(process.env.NODE_ENV !== 'production')
        console.debug('ScrollLoadMore - no more loading.', {
            isVisibleRef: isVisibleRef.current,
            hasMoreRef: hasMoreRef.current,
            isLoadingRef: isLoadingRef.current
        })
    }
  }): React.useRef<any>()

  // watch the 'inView' var, store changes and trigger loadMoreRef if necessary.
  React.useEffect(() => {
    isVisibleRef.current = inView
    if(isVisibleRef.current && onLoadMore){
        onLoadMore()
    }
    if (inView) {
      loadMoreRef.current ? loadMoreRef.current() : null
    }
      
  }, [inView])

  return (
    <React.Fragment>
      {/* TLDR; when this component is visible, trigger `loadMore`. */}
      <div ref={ref} {...props} />

      {/* show loading ? */}
      {RenderLoading && !noMoreData && <RenderLoading />}

      {/* show 'no more data' ? */}
      {RenderNoMoreData && noMoreData && <RenderNoMoreData />}
    </React.Fragment>
  )
}

export default ScrollLoadMore