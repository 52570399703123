import { Theme, Box, Stack, BoxProps } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import SVG from 'react-inlinesvg';

export interface RibbonProps extends BoxProps {
  theme?: Theme;
  sx?: SxProps<Theme>;
  variant?: 'success' | 'waiting' | 'waiting-admin' | 'failed' | 'champion';
  icon?: string;
  label?: string | ReactNode;
  cta?: ReactNode;
}

const handleIcon = ({ variant, icon }: RibbonProps) => {
  switch (variant) {
    case 'success':
      return IconSource('ic-ribbon-check');
    case 'waiting':
      return IconSource('ic-ribbon-timer');
    case 'waiting-admin':
      return IconSource('ic-wait-for-admin');
    case 'failed':
      return IconSource('ic-ribbon-cancel');
    case 'champion':
      return IconSource('ic-crown');
    default:
      return icon ? icon : '';
  }
};

const handleVariantStyle = ({ variant }: RibbonProps) => {
  switch (variant) {
    case 'success':
      return { color: 'success500', bgcolor: 'success950' };
    case 'waiting':
      return { color: 'primary500' };
    case 'waiting-admin':
      return { color: 'primary500' };
    case 'failed':
      return { color: 'danger500' };
    default:
      return { color: 'warning100', bgcolor: 'brown100', justifyContent: 'center' };
  }
};

const CustomRibbon: any = styled(Box, { name: 'ribbon' })<RibbonProps>(({ theme, cta }) => ({
  position: 'relative',
  minHeight: '20px',
  borderRadius: theme.borderRadius.sm,
  display: 'flex',
  justifyContent: Boolean(cta) ? 'space-between' : 'flex-start',
  alignItems: 'center',
  padding: '6px 13px',
  color: theme.palette.neutral300,
  background: theme.palette.neutral550,
  svg: {
    marginRight: '5px',
  },
}));

export const Ribbon = ({ icon, label, variant, ...props }: RibbonProps) => {
  return (
    <CustomRibbon sx={{ ...(variant && handleVariantStyle({ variant })), ...props.sx }} {...props}>
      <Stack direction="row" alignItems="center" zIndex="2">
        {(variant || icon) && <SVG src={handleIcon({ variant, icon })} />}
        <Text variant="button" sx={{ color: 'inherit', lineHeight: '16px' }}>
          {label}
        </Text>
      </Stack>
      {props.cta}
    </CustomRibbon>
  );
};
