import { Box, Stack } from 'components/styled/layout.styled';
import { SpanText } from 'components/styled/text.styled';
import React, { FC, ReactNode } from 'react';
export interface StatusProps {
  onClick?: () => void;
  label: ReactNode;
  position?: string;
}
export const Status: FC<StatusProps> = ({ label, position, onClick }) => (
  <Box background='linear-gradient(291.67deg, rgba(213, 31, 64, 0.05) 14.97%, rgba(243, 112, 36, 0.2) 73.8%)' padding='10px 16px' position={position} onClick={onClick}>
    <Stack align='center'>
      <SpanText fontSize='12px' fontWeight='500' lineHeight='17.52px' textAlign='center' color='white'>{label}</SpanText>
    </Stack>
  </Box>
);

export default Status;
