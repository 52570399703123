import CancelJoinModal from '../modal/CancelJoinModal';
import JoinFailedModal from '../modal/JoinFailedModal';
import JoinModal from '../modal/JoinModal';
import JoinOtherModal from '../modal/JoinOtherModal';
import { Avatar, Box, Divider, Stack } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { TextButton } from 'design/Buttons/TextButton';
import { TeamProfileHeader } from 'design/Cards/TeamProfileHeader';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { approveInvite, kickMember, rejectInvite } from 'redux/team/TeamApproval';
import { requestJoinTeam, resetTeamMemberError } from 'redux/team/TeamMemberReducer';
import { fetchMyTeamList } from 'redux/team/TeamService';
import { resetTeamMember } from 'redux/teamProfile/TeamProfileReducer';
import AuthService from 'services/AuthService';

interface HeaderSectionProps {
  paramTeamSlug: string;
  paramGameSlug: string;
  setError: (value: { name: string; message: string; stack: string }) => void;
  setOpenToast: (value: boolean) => void;
  setToastMessages: (value: string) => void;
  setOpenLoader: (value: boolean) => void;
}

const HeaderSection = ({
  paramTeamSlug,
  paramGameSlug,
  setError,
  setOpenToast,
  setToastMessages,
  setOpenLoader,
}: HeaderSectionProps) => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const thunkDispatch = useAppThunkDispatch();

  const {
    playerInfo,
    teamInfo,
    teamDetail,
    tournamentParticipantAwardsFrame,
    joinTeamMemberError,
  } = useAppSelector(({ account, myTeam, teamProfile, award, teamMember }) => ({
    playerInfo: account?.data,
    teamInfo: myTeam?.data?.items,
    teamDetail: teamProfile?.teamDetail,
    tournamentParticipantAwardsFrame: award?.tournamentParticipantAwardsFrame,
    joinTeamMemberError: teamMember?.error?.data,
  }));

  const [openJoinModal, setOpenJoinModal] = useState(false);
  const [openJoinOtherModal, setOpenJoinOtherModal] = useState(false);
  const [openJoinFailedModal, setOpenJoinFailedModal] = useState(false);
  const [openCancelJoinModal, setOpenCancelJoinModal] = useState(false);

  const myTeamList = isAuthenticated
    ? teamInfo?.find(team => team?.urlSlug === paramTeamSlug)
    : undefined;

  const myTeamApproved = isAuthenticated
    ? teamInfo?.find(
        team => team?.game?.urlSlug === paramGameSlug && team?.status?.value === 'Approved',
      )
    : undefined;

  const myTeamPending = isAuthenticated
    ? teamInfo?.find(
        team => team?.game?.urlSlug === paramGameSlug && team?.status?.value === 'Waiting',
      )
    : undefined;

  const myTeamInviting = isAuthenticated
    ? teamInfo?.find(
        team => team?.game?.urlSlug === paramGameSlug && team?.invitationType?.value === 'Invite',
      )
    : undefined;

  const handleFetchMyTeamList = (username: string) => {
    thunkDispatch(
      fetchMyTeamList({
        playerSlug: username,
        pageIndex: 1,
        pageSize: 10,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleJoinTeam = (playerId: string, teamId: string) => {
    setOpenLoader(true);
    thunkDispatch(requestJoinTeam({ playerId: playerId, teamId: teamId }))
      .unwrap()
      .then(res => {
        if (res?.isAxiosError) {
          setOpenLoader(false);
          setOpenJoinFailedModal(true);
        } else {
          if (playerInfo) {
            setTimeout(() => {
              handleFetchMyTeamList(playerInfo?.userName);
              setOpenLoader(false);
              setOpenJoinModal(true);
            }, 1000);
          }
        }
      })
      .catch(error => setError(error));
  };

  const handleCancelJoin = (playerId?: string, teamId?: string, appliedTeamId?: string) => {
    setOpenCancelJoinModal(false);
    setOpenLoader(true);
    if (appliedTeamId) {
      setOpenJoinOtherModal(false);
    }
    if (playerId && teamId) {
      thunkDispatch(kickMember({ playerId: playerId, teamId: teamId }))
        .unwrap()
        .then(() => {
          if (appliedTeamId) {
            handleJoinTeam(playerId, appliedTeamId);
          } else {
            if (playerInfo) {
              setToastMessages('Berhasil membatalkan request join tim');
              setTimeout(() => {
                Promise.all([
                  handleFetchMyTeamList(playerInfo?.userName),
                  setOpenLoader(false),
                  setOpenToast(true),
                ]);
              }, 1000);
            }
          }
        })
        .catch(error => setError(error));
    }
  };

  const handleApproveInvitation = (playerId: string, teamId: string) => {
    setOpenLoader(true);
    thunkDispatch(
      approveInvite({
        playerId: playerId,
        teamId: teamId,
      }),
    )
      .unwrap()
      .then(res => {
        if (res?.isAxiosError) {
          // error messages
        } else {
          if (playerInfo) {
            setToastMessages(t('invitation_msg_success'));
            Promise.all([
              setTimeout(() => {
                Promise.all([
                  handleFetchMyTeamList(playerInfo?.userName),
                  setOpenLoader(false),
                  setOpenToast(true),
                ]);
              }, 1000),
            ]);
          }
        }
      })
      .catch(error => setError(error));
  };

  const handleCancelInvitation = (playerId: string, teamId: string) => {
    setOpenLoader(true);
    thunkDispatch(rejectInvite({ playerId: playerId, teamId: teamId }))
      .unwrap()
      .then(res => {
        if (res?.isAxiosError) {
          // error messages
        } else {
          if (playerInfo) {
            setToastMessages(t('invitation_msg_cancel'));
            setTimeout(() => {
              Promise.all([
                handleFetchMyTeamList(playerInfo?.userName),
                setOpenLoader(false),
                setOpenToast(true),
              ]);
            }, 1000);
          }
        }
      })
      .catch(error => setError(error));
  };

  const handleResetTeamMember = () => {
    // only for the first time load
    if (paramTeamSlug !== teamDetail?.urlSlug) {
      thunkDispatch(resetTeamMember());
    }
  };

  useEffect(() => {
    handleResetTeamMember();
  }, []);

  const handleTeamFrame = useMemo(() => {
    const dataTeamAward = (tournamentParticipantAwardsFrame?.awards ?? []).find(
      data => data?.teamId === teamDetail?.id,
    );

    return dataTeamAward
      ? { image: dataTeamAward?.frameUrl, text: dataTeamAward?.frameTitle?.toUpperCase() }
      : undefined;
  }, [teamDetail?.id, tournamentParticipantAwardsFrame]);

  const handleCloseJoinModal = () => {
    setOpenJoinModal(false);
  };

  const handleCloseJoinOtherModal = () => {
    setOpenJoinOtherModal(false);
  };

  const handleCloseJoinFailedModal = () => {
    setOpenJoinFailedModal(false);
    setTimeout(() => thunkDispatch(resetTeamMemberError()), 300);
  };

  const handleOpenCancelJoinModal = () => {
    setOpenCancelJoinModal(true);
  };

  const handleCloseCancelJoinModal = () => {
    setOpenCancelJoinModal(false);
  };

  const handleJoin = (playerId: string, teamId: string) => {
    if (myTeamPending) {
      return setOpenJoinOtherModal(true);
    }
    return handleJoinTeam(playerId, teamId);
  };

  const parseError = (data: any) => {
    const playerSchoolValidation = data?.errors?.playerId[0]?.formatedValue?.playerSchool;
    const teamSchoolValidation = data?.errors?.playerId[0]?.formatedValue?.teamSchool;
    const memberCount = data?.errors?.playerId[0]?.formatedValue?.memberCount;
    const maxMember = data?.errors?.playerId[0]?.formatedValue?.maxMember;
    const fullSlotValidation = memberCount === maxMember;

    if (playerSchoolValidation && teamSchoolValidation) {
      return 'Tidak bisa join tim dari sekolah yang berbeda.';
    }
    if (fullSlotValidation) {
      return 'Saat ini tim sedang penuh dan tidak dapat menerima anggota baru.';
    }
    return data?.errors?.errors?.playerId[0]?.message;
  };

  const isTeamMatching = Boolean(myTeamList?.id === teamDetail?.id);
  const isJoinWaiting =
    myTeamList?.status?.value === 'Waiting' && myTeamList?.invitationType?.value === 'Request';
  const isTeamInviting =
    myTeamList?.status?.value === 'Waiting' && myTeamList?.invitationType?.value === 'Invite';

  const handleCtaTeamProfileHeader = useCallback(() => {
    if (isAuthenticated && playerInfo && teamDetail) {
      if (!myTeamApproved) {
        if (isTeamMatching && isJoinWaiting) {
          return (
            <Stack direction="row" spacing={1}>
              <Text variant="button" sx={{ color: 'primary500' }}>
                Menunggu konfirmasi
              </Text>

              <Divider
                orientation="vertical"
                flexItem
                sx={theme => ({ borderColor: theme.palette.neutral500 })}
              />
              <TextButton onClick={handleOpenCancelJoinModal} sx={{ span: { lineHeight: '16px' } }}>
                <Avatar
                  src={IconSource('ic-circle-close')}
                  sx={{
                    width: '12px',
                    height: '12px',
                    flexShrink: 0,
                    mr: '2px',
                  }}
                />
                Batal join
              </TextButton>
            </Stack>
          );
        }

        if (isTeamMatching && isTeamInviting) {
          return (
            <Stack direction="row" spacing={1}>
              <SecondaryButton
                variant="small"
                onClick={() => handleCancelInvitation(playerInfo?.id, teamDetail?.id)}
              >
                Tolak
              </SecondaryButton>
              <PrimaryButton
                variant="small"
                onClick={() => handleApproveInvitation(playerInfo?.id, teamDetail?.id)}
              >
                Terima Undangan
              </PrimaryButton>
            </Stack>
          );
        }

        return (
          <PrimaryButton onClick={() => handleJoin(playerInfo?.id, teamDetail?.id)} variant="small">
            Join Tim
          </PrimaryButton>
        );
      }

      return undefined;
    }
    return undefined;
  }, [isTeamMatching, isJoinWaiting, isTeamInviting]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          background: `url(${process.env.PUBLIC_URL}/media/images/game/mlbb/bg-teamprofile-mlbb@3x.png) center center / cover`,
          height: '226px',
          p: '0 16px',
          mt: '-56px',
        }}
      >
        <Box sx={{ mt: '60px', width: '100%' }}>
          <TeamProfileHeader
            logo={teamDetail?.avatarUrl}
            teamName={teamDetail?.name}
            teamSchool={teamDetail?.school?.name}
            teamLocation={teamDetail?.school?.province}
            frame={handleTeamFrame}
            cta={handleCtaTeamProfileHeader()}
          />
        </Box>
      </Box>

      <JoinModal openJoinModal={openJoinModal} handleCloseJoinModal={handleCloseJoinModal} />
      <JoinOtherModal
        openJoinOtherModal={openJoinOtherModal}
        handleCloseJoinOtherModal={handleCloseJoinOtherModal}
        latestTeamJoinName={myTeamPending?.name}
        nowTeamJoinName={teamDetail?.name}
        handleJoinOther={() => handleCancelJoin(playerInfo?.id, myTeamPending?.id, teamDetail?.id)}
        isTeamInviting={Boolean(myTeamInviting)}
      />
      <JoinFailedModal
        openJoinFailedModal={openJoinFailedModal}
        handleCloseJoinFailedModal={handleCloseJoinFailedModal}
        errorMessage={parseError(joinTeamMemberError)}
      />
      <CancelJoinModal
        openCancelJoinModal={openCancelJoinModal}
        handleCloseCancelJoinModal={handleCloseCancelJoinModal}
        handleCancelJoinService={() => handleCancelJoin(playerInfo?.id, teamDetail?.id)}
      />
    </>
  );
};

export default memo(HeaderSection);
