import { TextButton } from 'design/Buttons/TextButton';
import { Ribbon } from 'design/Ribbon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TeamsType } from 'redux/tournament/TournamentProps';
import { STATE, TEAM_STATE } from 'store/constants/tournament';

export interface TeamStatusProps {
  isMyTeamInTournament?: TeamsType;
  isMyTeamRejected?: boolean;
  tournamentState?: number;
  calculatePlayerJoin: number;
  teamState?: string;
  requireParticipantApproval?: string;
  handleDrawerOpen?: () => void;
}

const TeamStatus = ({
  isMyTeamInTournament,
  isMyTeamRejected,
  tournamentState,
  teamState,
  requireParticipantApproval,
  calculatePlayerJoin,
  handleDrawerOpen,
}: TeamStatusProps) => {
  const { t } = useTranslation();

  const joined =
    Boolean(isMyTeamInTournament) == true &&
    isMyTeamRejected === false &&
    tournamentState !== STATE.REGISTRATION_OPENED &&
    teamState === TEAM_STATE.JOINED;

  const pending =
    Boolean(isMyTeamInTournament) == true &&
    isMyTeamRejected == false &&
    tournamentState === STATE.REGISTRATION_OPENED &&
    (teamState === TEAM_STATE.MEMBERS_INVITATION_STATED || teamState === TEAM_STATE.JOINED || teamState === TEAM_STATE.QUEUED);

  const rejected =
    isMyTeamRejected || teamState === TEAM_STATE.REJECTED || teamState === TEAM_STATE.DISQUALIFIED;

  return (
    <>
      {joined && (
        <Ribbon
          variant={requireParticipantApproval ? "waiting-admin" : 'success'}
          label={requireParticipantApproval ? t('tournament_tim_menunggu_admin') : t('tournament_tim_terdaftar')}
          cta={
            <TextButton variant="label" gradient onClick={handleDrawerOpen}>
              {t('tournament_lihat_tim')}
            </TextButton>
          }
        />
      )}
      {pending && (
        <Ribbon
          variant={teamState === TEAM_STATE.JOINED ? 'success' : 'waiting'}
          label={
            teamState === TEAM_STATE.JOINED ? t('tournament_tim_terdaftar')
            : teamState === TEAM_STATE.QUEUED && requireParticipantApproval && calculatePlayerJoin <= 0 ? t('tournament_tim_menunggu_admin')
            : t('tournament_tim_menunggu')
          }
          cta={
            <TextButton variant="label" gradient onClick={handleDrawerOpen}>
              {t('tournament_lihat_tim')}
            </TextButton>
          }
        />
      )}
      {rejected && <Ribbon variant="failed" label={t('tournament_tim_ditolak')} />}
    </>
  );
};

export default TeamStatus;
