import { dateFormat } from 'modules/helpers';

export default function PrivacyContent(dateEffective?: Date): string {
  const updateDate = dateFormat(dateEffective ? dateEffective : new Date(1638118800000)).format(
    'DD MMMM YYYY',
  );
  return `Pada saat Anda mengakses MABAR, maka semua informasi dan data pribadi Anda akan Kami kumpulkan dengan ketentuan sebagai berikut :

1. Kami akan mengumpulkan informasi mengenai komputer dan/atau media apapun yang Anda gunakan, termasuk IP address, sistem operasi, browser yang digunakan, URL, halaman, lokasi geografis dan waktu akses serta data lainnya terkait dengan penggunaan komputer Anda (Detail IP).
2. Kami akan meminta Anda untuk mengisi data-data pribadi Anda secara benar, jelas, lengkap, akurat, dan tidak menyesatkan, seperti nama, alamat email, nomor ponsel, sekolah, angkatan masuk sekolah, nomor induk siswa nasional, kartu pelajar, nomor whatsapp, discord, game id, game nickname dan data-data lain yang Kami perlukan agar Anda dapat memanfaatkan layanan yang Anda butuhkan. Kami tidak bertanggung jawab atas segala kerugian yang mungkin terjadi karena informasi dan atau data yang tidak benar, jelas, lengkap, akurat dan menyesatkan yang Anda berikan;
3. Kami dapat menggunakan data pribadi Anda dan informasi lainnya yang dikumpulkan dengan tujuan pemasaran Media Sosial menggunakan teknik grafik langsung dan terbuka dan untuk tujuan pemasaran digital konvensional, seperti mengirimkan Anda newsletter secara otomatis melalui surat elektronik untuk memberitahukan informasi produk baru, penawaran khusus atau informasi lainnya yang menurut Kami akan menarik bagi Anda. Kami dapat menggunakan data Anda untuk pembelajaran kebiasaan pengguna agar dapat memperbaiki dan meningkatkan layanan.
4. Dengan menggunakan layanan Kami, informasi-informasi yang Anda berikan dapat Kami gunakan dan berikan kepada pihak ketiga yang bekerjasama dengan Kami, sejauh untuk kepentingan peningkatan kualitas penggunaan layanan Kami. Atau jika diwajibkan oleh hukum untuk melakukannya
5. Segala informasi yang Kami terima dapat Kami gunakan untuk melindungi diri Kami terhadap segala tuntutan dan hukum yang berlaku terkait dengan penggunaan layanan dan pelanggaran yang Anda lakukan pada platform Kami atas segala ketentuan sebagaimana diatur dalam persyaratan layanan MABAR dan pedoman penggunaan produk dan layanan Kami, termasuk dan tidak terbatas apabila dibutuhkan atas perintah Pengadilan dalam proses hukum;
6. Anda bertanggung jawab atas kerahasiaan informasi dan data pribadi Anda, termasuk bertanggung jawab atas semua akses dan penggunaan platform yang menggunakan kata sandi dan akun serta token yang Anda miliki yang digunakan oleh siapa saja, baik atas seizin maupun tidak seizin Anda. Demi keamanan data rahasia Anda, Kami sangat menyarankan agar Anda menyimpan akun dan kata sandi yang Anda miliki dengan sebaik-baiknya dan/atau melakukan perubahan kata sandi secara berkala. Setiap penggunaan yang tidak sah dan tanpa sepengetahuan dan izin Anda menjadi tanggung jawab Anda sendiri dan Kami tidak bertanggung-jawab atas segala kerugian yang ditimbulkan sebagai akibat dari kelalaian yang Anda lakukan;
7. Anda harus segera memberitahukan kepada Kami mengenai adanya penggunaan sandi atau akun tanpa izin Anda atau semua bentuk pelanggaran atau ancaman pelanggaran keamanan dalam platform ini;
8. Pengguna menyetujui untuk menerima notifikasi dan berita terbaru ke nomor ponsel Pengguna atau email Pengguna (yang diberikan saat verifikasi awal) dari MABAR;
9. Jika Kami menemukan ada indikasi pengguna MABAR yang melakukan tindakan fiktif, illegal, dan tidak sesuai dengan peraturan maupun hukum yang berlaku baik pada sistem MABAR ataupun sistem partner MABAR dalam rangka untuk mendapatkan keuntungan secara materi maupun non materi, Kami berhak untuk melakukan penangguhan akun atau pemblokiran akun.

Kami berkomitmen untuk menjaga kerahasiaan data pribadi ini. MABAR tidak menyewakan atau menjual Informasi Pribadi Anda kepada pihak ketiga.

Saat merevisi kebijakan privasi ini, kami juga akan merevisi tanggal efektif di bawah ini.

Tanggal Efektif: ${updateDate}`;
}
