import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationItemProp } from 'components/Notification/NotificationItem';
import ApiService from 'services/ApiService';

export interface NotificationState {
  data: GetListNotificationResponse;
  notificationEvent?: INotificationMessage | ICheckInMessage | null | undefined;
  isLoading: boolean;
  errors?: any;
}

export const initState: NotificationState = {
  data: {
    items: [], // example
    total: 0,
    pageIndex: 0,
    pageSize: 0,
    count: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  isLoading: false,
};

// eslint-disable-next-line no-shadow
export const enum ActionType {
  NoAction = 0,
  NeedAction = 1,
}

// eslint-disable-next-line no-shadow
export const enum NotificationTarget {
  None = 0,
  All = 1,
  Group = 2,
  Specific = 3,
}

export interface IKeyValueResponse {
  key: string;
  value: string;
}

export interface ICheckInMessage {
  matchId: string;
  playerId: string;
  status: IKeyValueResponse;
}

export interface INotificationData {
  actionId?: string; // can be team slug, match ID etc..
  actionType: ActionType;
  actionData: NotificationTarget;
  args?: any;
}

export interface INotificationMessage {
  code: string;
  title: string;
  data: INotificationData;
  isRead: boolean;
}

export const subscribeNotificationHub = createAction('subscribe-to-notification-hub');

export const subscribeToMatchHub = createAction(
  'subscribe-to-match-hub',
  function prepare(matchId: string) {
    return {
      payload: {
        matchId,
      },
    };
  },
);

// Get List Notification
export interface GetListNotificationResponse {
  items: NotificationItemProp[];
  total: number;
  pageIndex: number;
  pageSize: number;
  count: number;
  totalCount: number;
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export const getCountUnreadNotification = createAsyncThunk(
  'notification/count-unread',
  async ({
    id,
  }: {
    id: string;
  }) => {
    const url = `user/notification/count-unread/${id}`;
    const response = await ApiService.get(url);
    return response;
  },
);

export const getListNotification = createAsyncThunk(
  'notification/list',
  async ({
    id,
    version,
    pageIndex,
    pageSize,
  }: {
    id: string;
    pageIndex: number;
    pageSize: number;
    version?: string;
  }) => {
    const url = `/${version ? `v${version}/` : ''}user/notification/${id}/${pageSize}/${pageIndex}`;
    const response = await ApiService.get(url);
    return response;
  },
);

export const setItemMarkAsRead = createAsyncThunk(
  'notification/mark-asread',
  async ({ id, version, notifIds }: { id: string; notifIds: string[]; version?: string }) => {
    const url = `/${version ? `v${version}/` : ''}user/notification/mark-asread/${id}`;
    const response = await ApiService.put(url, {
      id: id,
      notificationIds: notifIds,
    });
    return response;
  },
);

export const setItemMarkAllAsRead = createAsyncThunk(
  'notification/mark-asread',
  async ({ id, version }: { id: string; version?: string }) => {
    const url = `/${version ? `v${version}/` : ''}user/notification/markall-asread/${id}`;
    const response = await ApiService.put(url);
    return response;
  },
);

const NotificationSlice = createSlice({
  name: 'notification',
  initialState: initState,
  reducers: {
    resetState: (state, _action) => {
      state.notificationEvent = null;
    },
    setNotificationEvent: (
      state,
      action: PayloadAction<INotificationMessage | ICheckInMessage | null | undefined>,
    ) => {
      state.notificationEvent = action.payload;
    },
    setReadData: (state, action: PayloadAction<{ id: number | string }>) => {
      state.data.items = state.data.items.map((e: NotificationItemProp) => {
        if (e.id === action.payload.id) {
          return {
            ...e,
            isRead: true,
          };
        }
        return e;
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(getListNotification.fulfilled, (state, action) => {
      if(action.payload.status >= 400){
        state.errors = action.payload.data;
      }else{
        state.data = {
          ...action.payload.data,
          items: action.payload.data.items ? 
          action.payload.data.items.sort((a: NotificationItemProp, b: NotificationItemProp) => a.createAt > b.createAt ? -1 : 1) : []
        };
      }
      state.isLoading = false;
    });

    builder.addCase(getListNotification.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getListNotification.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { setReadData, setNotificationEvent } = NotificationSlice.actions;
export default NotificationSlice.reducer;
