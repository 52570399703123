import { Stack } from "components/styled/layout.styled";
import { NormalText } from "components/styled/text.styled";
import { IconSource } from "modules/assetpath";
import React from "react";
import { useTranslation } from "react-i18next";

const TournamentHistoryAccount = () => {
    const { t } = useTranslation()
    return (
        <Stack direction="column" align="center" margin="10px 0px 0px 0px" disableSelectText disableDrag>
            <img alt="" src={IconSource('mabar-typeface-fullwhite')} />
            <NormalText
                fontSize="14px"
                fontWeight="400"
                color="#969393"
                lineHeight="20.44px"
                textAlign="center"
                margin="24px 0px 30px 0px"
            >
            {t('player_account_match_tournament_empty')}
            </NormalText>
        </Stack>
    )
}

export default TournamentHistoryAccount;