import { Avatar } from 'components/styled/avatar.styled';
import { Button } from 'components/styled/button.sytled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import React from 'react';
import { FC } from 'react';

export interface TeamCardType {
  teamPosition?: 'left' | 'right';
  teamPhotos?: string[] | undefined;
  teamLogo?: string | undefined;
  teamName?: string | undefined;
  teamSchool?: string | undefined;
  variant?: 'stretch' | 'wrap';
  background?: string | undefined;
  onWaButtonClick?: (() => void) | 'none' | undefined;
  onClick?: () => void;
}

const TeamCard: FC<TeamCardType> = ({
  variant,
  teamPosition,
  teamPhotos,
  teamLogo,
  teamName,
  teamSchool,
  background,
  onWaButtonClick,
  onClick,
}) => {
  return (
    <Box width="100%" style={{ alignContent: 'center', justifyContent: 'center' }} minHeight='100%'>
      <Box
        margin="8px"
        padding="16px"
        flexGrow={variant === 'stretch' ? 1 : 0}
        borderRadius="16px"
        background={background}
      >
        <Stack direction="column" align="center">
          <Avatar
            alt="Avatar"
            fit="cover"
            width="50px"
            height="50px"
            border="2px solid #FFFFFF99"
            borderRadius="12px"
            src={teamLogo}
            margin="0px 0px 5px 0px"
            onError={(e) => {
              e.currentTarget.style.backgroundColor = "#B1B1B1"
              e.currentTarget.src = IconSource('union-placeholder')
              e.currentTarget.style.objectFit = "contain"
              e.currentTarget.style.objectPosition = "bottom"
            }}
          />
          <NormalText color="#ffffff" fontSize="14px" fontWeight="700" margin="5px 0px" textAlign="center">
            {teamName}
          </NormalText>
          <NormalText
            color="rgba(150, 147, 147, 1)"
            fontSize="10px"
            fontWeight="400"
            margin="5px 0px"
            textAlign="center"
          >
            {teamSchool}
          </NormalText>
          {onWaButtonClick !== 'none' && onWaButtonClick && (
            <Button
              background="#25D366"
              borderRadius="10px"
              padding="4px 12px 3px 12px"
              margin="5px 0px"
              onClick={onWaButtonClick}
            >
              <Avatar alt="Avatar" src={IconSource('whatsapps')} height="12px" width="50px" />
            </Button>
          )}
          {teamPhotos && (
            <Box
              margin={teamPosition === 'left' ? '5px 8px 0px 0px' : '5px 0px 0px 8px'}
              onClick={onClick}
            >
              <Stack direction="row" align="center">
                {teamPhotos.map((photo: string, index: number) => {
                  return (
                    <Avatar
                      alt="Avatar"
                      key={index}
                      src={photo?? IconSource('ic-person-white')}
                      borderRadius="50%"
                      width="20px"
                      height="20px"
                      margin={teamPosition === 'left' ? '0px -8px 0px 0px' : '0px 0px 0px -8px'}
                      zIndex={teamPosition === 'left' ? index + 1 : teamPhotos.length - index + 1}
                      border="2px solid #131318"
                    />
                  );
                })}
              </Stack>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default TeamCard;
