import { Box, Grid, Link } from '@mui/material';
import { Container } from '@mui/material';
import { GradientText, NormalText } from 'components/styled/text.styled';
import he from 'he';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TournamentDetailResponse } from 'redux/tournament/TournamentProps';
import { Image } from 'styled-minimal';

export interface RuleProps {
  tournamentDetail?: TournamentDetailResponse;
}

const RulePage = ({ tournamentDetail }: RuleProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Container
        disableGutters
        sx={{
          bgcolor: '#0A0A0B',
          padding: '25px 16px 24px 16px',
        }}
      >
        {tournamentDetail?.rulesFile && (
          <Box sx={{ bgcolor: '#1A1817', height: '44px', borderRadius: '8px' }}>
            <Grid container>
              <Grid item xs={8} sx={{ margin: '16px -10px 5px 10px', textAlign: 'left' }}>
                <NormalText
                  color="#B4B7BB"
                  textAlign="left"
                  fontSize="12px"
                  fontFamily="Inter"
                  fontStyle="Regular"
                  lineHeight="12px"
                >
                  {t('tournament_rule_detail')}
                </NormalText>
              </Grid>

              <Grid item xs={4} sx={{ marginTop: '10px', marginLeft: '-10px', textAlign: 'right' }}>
                <GradientText
                  color="#F3AA36"
                  fontStyle="SemiBold"
                  fontWeight="600"
                  fontSize="12px"
                  lineHeight="24px"
                  fontFamily="Lexend Deca"
                >
                  <Link href={tournamentDetail?.rulesFile} target="_blank" underline="none">
                    {t('tournament_button_pdf')}
                    <Image
                      src={`${process.env.PUBLIC_URL}/media/icons/arrow-icon-right.svg`}
                      alt={''}
                      style={{ float: 'right', marginLeft: '10px', marginTop: '6px' }}
                    />
                  </Link>
                </GradientText>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box
          sx={{
            padding: '10px 10px 10px 0px',
            margin: '10px 10px 10px 0px',
            color: '#F4F4F5',
            fontSize: '12px',
            lineHeight: '21px',
          }}
          dangerouslySetInnerHTML={{
            __html: tournamentDetail?.rules && he.decode(tournamentDetail?.rules),
          }}
        />
      </Container>
    </>
  );
};

export default RulePage;
