import { Action } from "redux";
import { PersistPartial } from "redux-persist/es/persistReducer";
import AppReducer from "./AppReducer";
import { StoreState } from "./state";

export default function rootReducer(state: (StoreState & PersistPartial) | undefined, action: Action<any>) {
  if(action.type === 'auth/logoutAction'){
    window.localStorage.removeItem('@'+process.env.REDUX_KEY_STORE_NAME)
    state = undefined as any;
  }  
  return AppReducer(state, action);
};