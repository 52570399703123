import React, { FC, ReactNode } from 'react';
import { NavBarHeight } from '../../modules/config';
import AppBar, { AppBarType } from './AppBar';
import BottomNavBar from './BottomNavBar';
import { HomeRouteNav, RouteType } from '../../routes/router';
import useWindowDimensions from 'modules/dimention';
import { Helmet } from 'react-helmet-async';
import config from 'config';
import { Box, Layout, Stack } from 'components/styled/layout.styled';
import { ImageBackground } from 'components/styled/image.styled';

export interface MainLayoutType {
  children?: ReactNode | undefined;
  appBar?: AppBarType | undefined;
  backgroundImage?: string | undefined;
  onBottomNavSelected?: ((route: RouteType) => void) | undefined;
}

const MainLayout: FC<MainLayoutType> = ({
  children,
  appBar,
  backgroundImage,
  onBottomNavSelected,
}) => {
  const { height: deviceHeight } = useWindowDimensions();
  return (
    <Layout>
      <Helmet
        defaultTitle={appBar?.title ?? config.name}
        defer={false}
        encodeSpecialCharacters
        htmlAttributes={{ lang: 'pt-br' }}
        titleAttributes={{ itemprop: 'name', lang: 'pt-br' }}
        titleTemplate={`%s | ${config.name}`}
      />
      <Box position="relative" width="100%">
        <ImageBackground src={backgroundImage} opacity="0.08" />
        {children ? (
          <Box
            margin={`${NavBarHeight}px 0px 24px 0px`}
            width="100%"
            style={{ minHeight: `${deviceHeight * 0.8}px` }}
          >
            <Stack direction="column">{children}</Stack>
          </Box>
        ) : undefined}
      </Box>
      <AppBar {...appBar} />
      <BottomNavBar data={HomeRouteNav} onSelected={onBottomNavSelected} />
    </Layout>
  );
};

export default MainLayout;
