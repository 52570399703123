import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
  currentVersion: string;
  clearCache: boolean;
}

const initialState: AppState = {
  currentVersion: '',
  clearCache: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setCurrentVersion: (state, action) => {
      const { currentVersion } = action.payload;
      return {
        ...state,
        currentVersion: currentVersion,
      };
    },
    setClearCache: (state, action) => {
      const { clearCache } = action.payload;
      return {
        ...state,
        clearCache: clearCache,
      };
    },
  },
});

export const { setCurrentVersion, setClearCache } = appSlice.actions;
export default appSlice.reducer;
