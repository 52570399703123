import { createSlice } from '@reduxjs/toolkit';
import { City, Province, SubDistrict } from './RegionResponse'
import { RegionBaseResponse } from './RegionResponse';
import { fetchCityList, fetchProvinceList, fetchSubDistrictList } from './RegionServices';

export interface RegionState {
  provinceList?: RegionBaseResponse<Province[]>;
  cityList?: RegionBaseResponse<City[]>;
  subDistrictList?: RegionBaseResponse<SubDistrict[]>;
  errors?: string;
  status?: 'idle' | 'loading' | 'succeeded' | 'failed' | 'error';
}

const initialState: RegionState = {
  errors: '',
  status: 'idle',
};

const RegionSlice = createSlice({
  name: 'region',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProvinceList.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchProvinceList.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchProvinceList.fulfilled, (state, action) => {
        state.provinceList = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchCityList.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchCityList.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchCityList.fulfilled, (state, action) => {
        state.cityList = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchSubDistrictList.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchSubDistrictList.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchSubDistrictList.fulfilled, (state, action) => {
        state.cityList = action.payload;
        state.status = 'succeeded';
      })
  },
});

export default RegionSlice.reducer;
