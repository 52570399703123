import { Stack, Box, Avatar, Divider } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

interface team {
  logo?: string;
  name?: string;
  school?: string;
  score?: ReactNode;
}

export interface CardMatchActivityProps {
  type: 'tournament' | 'scrim';
  title: string;
  description: string;
  statusLabel?: ReactNode;
  team: team[];
  cta?: ReactNode;
  active: boolean;
  onClick?: (data?: any) => void;
}

export const CardMatchActivity = ({
  type,
  title,
  description,
  statusLabel,
  team = [],
  cta,
  active = false,
  onClick,
}: CardMatchActivityProps) => {
  return (
    <Box
      sx={theme => ({
        p: '1px',
        background: active
          ? 'linear-gradient(40deg, #17181A 2%, #27282B 80%, #D31F40 100%)'
          : '#242628',
        borderRadius: theme.borderRadius.sm,
        boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.24)',
      })}
      onClick = {onClick}
    >
      <Box
        sx={theme => ({ p: '12px', borderRadius: theme.borderRadius.sm, bgcolor: 'neutral700' })}
      >
        <Stack direction="row" alignItems="flex-start" spacing="4px">
          <img
            src={
              type === 'scrim' ? IconSource('ic-match-scrim') : IconSource('ic-match-tournament')
            }
            alt={title}
            width={24}
          />
          <Box sx={{width: '100%'}}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing="14px"
            >
              <Text
                variant="bodySmall"
                component="h3"
                sx={{ fontWeight: 700, lineHeight: '140%', color: 'neutral150', flex: 'auto' }}
              >
                {title}
              </Text>
              {statusLabel}
            </Stack>
            <Text variant="bodySmall" sx={{ lineHeight: '140%', color: 'neutral400', mt: '2px' }}>
              {description}
            </Text>
          </Box>
        </Stack>

        <Divider sx={{ my: '12px', borderStyle: 'dashed' }} />

        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="14px">
          <Stack spacing="12px" sx={{ width: '100%' }}>
            {team.length > 0 ? (
              team.map(team => {
                return (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Stack direction="row" spacing="12px" sx={{ width: '100%' }}>
                      <Avatar
                        src={team.logo || IconSource('ic-default-team')}
                        alt={team.name}
                        sx={theme => ({
                          width: '32.8px',
                          height: '32.8px',
                          borderRadius: theme.borderRadius.sm,
                          border: `2px solid ${theme.palette.neutral250}`,
                        })}
                      />
                      <Stack sx={{ width: '100%' }}>
                        <Text variant="button" component="h5" sx={{ lineHeight: '140%' }}>
                          <LinesEllipsis text={team.name} maxLine={1} ellipsis="..." />
                        </Text>
                        <Text
                          variant="bodySmall"
                          sx={{ fontSize: '10px', color: 'neutral400', lineHeight: '140%' }}
                        >
                          <LinesEllipsis text={team.school} maxLine={1} ellipsis="..." />
                        </Text>
                      </Stack>
                    </Stack>
                    {team.score}
                  </Stack>
                );
              })
            ) : (
              <Stack direction="row" alignItems="center" spacing="12px">
                <Avatar
                  src={IconSource('ic-waiting')}
                  sx={theme => ({
                    width: '32.8px',
                    height: '32.8px',
                    borderRadius: theme.borderRadius.sm,
                    border: `2px solid ${theme.palette.neutral600}`,
                    img: {
                      width: '18px',
                      height: '18px',
                    },
                  })}
                />
                <Text variant="button" component="h5">
                  Menunggu...
                </Text>
              </Stack>
            )}
          </Stack>

          {cta && <Stack alignItems="flex-end">{cta}</Stack>}
        </Stack>
      </Box>
    </Box>
  );
};
