import { Stack } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import React, { memo } from 'react';
import { useAppSelector } from 'redux/hooks';

interface CancelJoinModalProps {
  openCancelJoinModal: boolean;
  handleCloseCancelJoinModal: () => void;
  handleCancelJoinService: () => void;
}

const CancelJoinModal = ({
  openCancelJoinModal,
  handleCloseCancelJoinModal,
  handleCancelJoinService,
}: CancelJoinModalProps) => {
  const { teamDetail } = useAppSelector(({ teamProfile }) => ({
    teamDetail: teamProfile?.teamDetail,
  }));

  return (
    <Modal show={openCancelJoinModal}>
      <InfoModal
        cta={
          <Stack direction="row" justifyContent="flex-end" spacing={1} width="100%">
            <SecondaryButton onClick={handleCloseCancelJoinModal}>Tutup</SecondaryButton>
            <PrimaryButton onClick={handleCancelJoinService}>Batalkan Request</PrimaryButton>
          </Stack>
        }
      >
        <Text variant="h2" sx={{ textAlign: 'left', mb: '8px', width: '100%' }}>
          Batal Join
        </Text>
        <Text sx={{ color: 'neutral300', textAlign: 'left', mb: '8px', width: '100%' }}>
          Apakah kamu yakin ingin membatalkan request join dengan tim{' '}
          <b style={{ color: 'neutral200' }}>{teamDetail?.name}</b> ?
        </Text>
      </InfoModal>
    </Modal>
  );
};

export default memo(CancelJoinModal);
