import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { SpanText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface MatchData {
  id?: string;
  firstTeam?: ReactNode;
  firstTeamAvatar?: string;
  firstTeamScore?: ReactNode;
  secondTeam?: ReactNode;
  secondTeamAvatar?: string;
  secondTeamScore?: ReactNode;
  startAt?: Date;
  status?: any;
}

interface MatchItemProps {
  matchId: ReactNode;
  matchDate: ReactNode;
  data: MatchData;
  boxRight?: ReactNode;
  isPlayer?: boolean | true;
  noEnemyText?: ReactNode;
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>, item?: MatchData) => void;
}

function MatchItem({
  matchId,
  isPlayer,
  matchDate,
  data,
  boxRight,
  noEnemyText,
  onClick,
}: MatchItemProps) {
  return (
    <Box
      background={'rgba(55, 57, 66, 0.6)'}
      borderRadius={'16px'}
      onClick={e => {
        if (onClick) {
          onClick(e, data);
        }
      }}
      isPressble={onClick ? true : false}
    >
      <Stack direction="column" align="center" padding={'16px 16px 26px'}>
        <Stack direction="row" align="between">
          <Box
            background={'rgba(255, 255, 255, 0.06)'}
            borderRadius="6px"
            padding={'4px 6px'}
            alignSelf="center"
          >
            <SpanText
              color={'#DDD8D8'}
              fontSize={'12px'}
              fontWeight={'400'}
              fontStyle={'normal'}
              lineHeight={'16.8px'}
              maxWidth={'105px'}
              wordWrap={'break-word'}
              textAlign="center"
            >
              {matchId}
            </SpanText>
          </Box>
          <SpanText
            color={'#DDD8D8'}
            fontSize={'12px'}
            fontWeight={'400'}
            fontStyle={'normal'}
            lineHeight={'16.8px'}
            maxWidth={'150px'}
            wordWrap={'break-word'}
            textAlign="right"
            style={{ alignSelf: 'center' }}
          >
            {matchDate}
          </SpanText>
        </Stack>
        <Stack direction="row" align="between" margin="16px 0px 0px 0px">
          <Stack direction="column" align="start">
            <Stack direction="row" align="start">
              {data?.firstTeam && (
                <>
                  <Avatar
                    border="2px solid #F1EBEB"
                    width="20px"
                    height="20px"
                    borderRadius="12px"
                    margin="0px 10px 0px 0px"
                    background={data?.firstTeamAvatar ? 'transparent' : '#B1B1B1'}
                    fit="contain"
                    objectPos={data?.firstTeamAvatar ? 'center' : 'bottom'}
                    src={data?.firstTeamAvatar ?? IconSource('union-placeholder')}
                    onError={e => {
                      e.currentTarget.src = IconSource('union-placeholder');
                      e.currentTarget.style.objectPosition = 'bottom';
                    }}
                    style={{ alignSelf: 'center' }}
                  />
                  <SpanText
                    fontSize="14px"
                    color="white"
                    fontStyle="normal"
                    fontWeight="700"
                    maxWidth="140px"
                    wordWrap="break-word"
                    lineHeight="19.6px"
                  >
                    {data?.firstTeam}
                  </SpanText>
                </>
              )}
            </Stack>
            <Stack direction="row" align="start" margin={'10px 0px 0px'}>
              {data?.secondTeam ? (
                <>
                  <Avatar
                    border="2px solid #F1EBEB"
                    width="20px"
                    height="20px"
                    borderRadius="12px"
                    margin="0px 10px 0px 0px"
                    background={data?.secondTeamAvatar ? 'transparent' : '#B1B1B1'}
                    fit="contain"
                    objectPos={data?.secondTeamAvatar ? 'center' : 'bottom'}
                    src={data?.secondTeamAvatar ?? IconSource('union-placeholder')}
                    onError={e => {
                      e.currentTarget.src = IconSource('union-placeholder');
                      e.currentTarget.style.objectPosition = 'bottom';
                    }}
                    style={{ alignSelf: 'center' }}
                  />
                  <SpanText
                    fontSize="14px"
                    color="white"
                    fontStyle="normal"
                    fontWeight="700"
                    maxWidth="140px"
                    wordWrap="break-word"
                    lineHeight="19.6px"
                  >
                    {data.secondTeam}
                  </SpanText>
                </>
              ) : (
                <SpanText
                  color={'#969393'}
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="19.6px"
                  fontStyle="normal"
                  maxWidth="140px"
                  margin="6px 0px 0px 0px"
                >
                  {noEnemyText}
                </SpanText>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" align="end">
            {isPlayer || isPlayer == undefined ? (
              <Box
                background={
                  'linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06))'
                }
                borderRadius="10px"
                padding={'10px'}
                alignSelf="end"
              >
                <Stack direction="column">{boxRight}</Stack>
              </Box>
            ) : (
              <Stack direction="column">{boxRight}</Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default MatchItem;
