import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import React, { FC, ReactNode } from 'react';

export interface NewsProps {
  title: string;
  content: string | ReactNode;
  cta?: ReactNode;
  sx?: SxProps<Theme>;
}
export const News: FC<NewsProps> = ({ title, content, cta, sx }) => {
  return (
    <Box sx={sx}>
      <Text variant="h2" sx={{ color: 'neutral300', lineHeight: '34px', letterSpacing: '0.4px' }}>
        {title}
      </Text>
      <Box
        component="article"
        sx={{
          typography: 'body',
          color: 'neutral400',
          fontWeight: 500,
          lineHeight: '21px',
          my: '16px',
        }}
      >
        {content}
      </Box>
      {cta}
    </Box>
  );
};
