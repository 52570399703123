import { Theme, SwipeableDrawer, Box, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

export interface DrawerBottomProps {
  title?: string | ReactNode;
  children?: ReactNode;
  open: boolean;
  iconClose?: boolean;
  onClose: () => void;
  onOpen: () => void;
  sx?: SxProps<Theme>;
  boxShadow?: boolean;
  cta?: ReactNode;
  paperSx?: SxProps<Theme>;
}

export const DrawerBottom = ({
  title,
  open,
  iconClose,
  onClose,
  children,
  sx,
  boxShadow,
  cta,
  onOpen,
  paperSx,
}: DrawerBottomProps) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const boxRef = useRef<any>();
  const boxChildRef = useRef<any>();

  useEffect(() => {
    setIsScrollable(boxChildRef.current?.clientHeight > boxRef.current?.clientHeight);
  }, [open]);

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          pt: '8px',
          m: '0 auto',
          backgroundImage: 'none',
          bgcolor: 'neutral700',
          maxHeight: 'calc(100% - (56px + 24px))',
          borderRadius: '12px 12px 0 0',
          justifyContent: 'space-between',
          maxWidth: '500px',
          borderTop: `1px solid #2E3033`,
          ...paperSx,
        },
      }}
      sx={sx}
      transitionDuration={{ enter: 350, exit: 280 }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <Stack alignItems="center">
        <Box sx={{ bgcolor: 'neutral500', width: '50px', height: '4px', borderRadius: '3px' }} />
      </Stack>
      <Stack
        className="drawer-title"
        direction="row"
        alignItems="center"
        spacing="12px"
        sx={{ mt: '20px', mb: '16px', px: '16px' }}
      >
        {iconClose && (
          <Stack onClick={onClose} sx={{ cursor: 'pointer' }}>
            <img src={IconSource('ic-close-white2')} alt="icon close" width={24} />
          </Stack>
        )}
        <Text
          variant="h3"
          component={typeof title === 'string' ? 'h3' : 'div'}
          sx={{ width: '100%' }}
        >
          {title}
        </Text>
      </Stack>

      <Box
        className="drawer-content"
        ref={boxRef}
        sx={theme => ({
          height: 'inherit',
          mb: '0px',
          px: '16px',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: `${theme.palette.neutral500} #26211E`,
          boxShadow: isScrollable ? `inset 0px -38px 20px -20px ${theme.palette.neutral700}` : '',
          '&::placeholder': {
            color: theme.palette.neutral500,
          },
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#26211E',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.neutral500,
            borderRadius: '10px',
          },
        })}
      >
        <Box ref={boxChildRef}>{children}</Box>
      </Box>
      <Box
        className="drawer-cta"
        sx={{
          position: 'relative',
          p: '0 16px 16px 16px',
          ...(boxShadow && {
            '&:before': {
              content: "''",
              background: 'transparent',
              boxShadow: '0px -4px 24px rgba(211, 31, 64, 0.4)',
              borderRadius: '20%',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '0',
            },
            '&:after': {
              content: "''",
              bgcolor: 'neutral700',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '0',
            },
          }),
        }}
      >
        <Box sx={{ position: 'relative', zIndex: '1' }}>{cta}</Box>
      </Box>
    </SwipeableDrawer>
  );
};
