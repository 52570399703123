import NotificationItem, { NotificationItemProp } from 'components/Notification/NotificationItem';
import {
  ContentContainer,
  NotifButton,
  NotifTitle,
  RowContainer,
} from 'components/Notification/notification.style';
import ScrollLoadMore from 'components/ScrollView/ScrollLoadMore';
import MessageModal from 'components/UniPopup/Modals/MessageModal';
import BackgroundImage from 'components/layouts/BackgroundImage';
import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { LayoutContext } from 'context/LayoutContext';
import { Modal } from 'design/Modal';
import _ from 'lodash';
import { IconSource, ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import {
  getListNotification,
  setItemMarkAllAsRead,
  setItemMarkAsRead,
} from 'redux/notification/NotificationReducer';
import { getScrimDetailHeader } from 'redux/scrim/match.details/ScrimMatchDetailReducer';
import { useAppThunkDispatch } from 'redux/store';
import { fetchTeamDetail, TeamDetailResponse } from 'redux/team/TeamDetailReducer';

const NotificationPage = () => {
  const { t } = useTranslation();
  const { data: dataRes, isLoading } = useAppSelector(state => state.notification);
  const userId = useAppSelector(state => state.account.data?.id);
  const { totalCount: totalItems } = dataRes;
  const [getNotificationList, setNotificationList] = React.useState<NotificationItemProp[]>([]);
  const thunkDispatch = useAppThunkDispatch();
  let [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
  let [pageIndex, setPageIndex] = React.useState(2);
  let [moreData, setMoreData] = React.useState(false);
  let [moreLoading, setMoreLoading] = React.useState(false);
  const [getErrorList, setErrorList] = React.useState<any>();
  const [showModal, setShowModal] = React.useState(false);

  const fetchTeamData = async (teamSlug: string, action?: string, isInvitation?: boolean) => {
    await thunkDispatch(fetchTeamDetail({ teamSlug: teamSlug }))
      .unwrap()
      .then(res => {
        const { isAxiosError, errors } = res as { isAxiosError: boolean; errors: any };
        if (isAxiosError && errors) {
          setErrorList(errors);
          setShowModal(true);
        } else {
          const team = res as TeamDetailResponse;
          if (parseInt(action ?? '') > 0 && !isInvitation) {
            history.push(`/scrims/${team.game.urlSlug}/${action}`);
          } else if (parseInt(action ?? '') > 0 && isInvitation) {
            history.push(`/scrim/${team.game.urlSlug}/${action}`);
          } else {
            history.push(
              `/team/${team.game.urlSlug}/${team.school.urlSlug}/${team.urlSlug}${
                action ? '?' + action : ''
              }`,
            );
          }
        }
      })
      .catch(err => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      });
  };

  const getScrimDetail = (scrimId: string, open?: boolean) => {
    thunkDispatch(getScrimDetailHeader({ id: scrimId }))
      .unwrap()
      .then(team => {
        if (team && team.data && team.status <= 204) {
          fetchTeamData(team.data.firstTeamUrlSlug ?? team.data.secondTeamUrlSlug, scrimId, open);
        } else if (team && team.data && team.status >= 400) {
          setErrorList(team.data);
          setShowModal(true);
        }
      })
      .catch(() => {});
  };

  const loadMoreAsync = async () => {
    setMoreLoading(true);
    const thunk = getListNotification({
      id: userId ?? '-',
      pageIndex: pageIndex,
      pageSize: 20,
      version: '',
    });
    await thunkDispatch(thunk)
      .unwrap()
      .then(res => {
        if (res && res.status <= 204 && res.data && res.data.items) {
          setNotificationList(n => [...n, ...res.data.items]);
          setMoreData(res.data.hasNextPage ? true : false);
          countUnread(res.data.items);
        }
        setMoreLoading(false);
      })
      .catch(() => {
        setMoreLoading(false);
      });
  };

  const fetchListNotification = React.useCallback(() => {
    thunkDispatch(
      getListNotification({
        id: userId ?? '-',
        pageIndex: 1,
        pageSize: 20,
      }),
    )
      .unwrap()
      .then(res => {
        if (res && res.status <= 204 && res.data && res.data.items) {
          setNotificationList(res.data.items);
          setMoreData(res.data.hasNextPage ? true : false);
          countUnread(res.data.items);
        } else if (res && res.data && res.status >= 400) {
          setErrorList(res.data);
          setShowModal(true);
        }
      });
  }, [thunkDispatch, userId]);

  const countUnread = (data: NotificationItemProp[]) => {
    var i: number = 0;
    data.forEach(e => {
      if (e.isRead === undefined || e.isRead === false) {
        i++;
      }
    });
    setUnreadMessageCount(p => p + i);
  };

  useEffect(() => {
    fetchListNotification();
  }, []);

  const setUnreadSelected = (notifId: string) => {
    let unreadItem: string[] = [];
    getNotificationList.forEach(e => {
      if ((e.isRead === undefined || e.isRead === false) && e.id === notifId) {
        unreadItem.push(e.id);
      }
    });
    if (unreadItem.length >= 1) {
      thunkDispatch(
        setItemMarkAsRead({
          id: userId ?? '',
          notifIds: unreadItem,
        }),
      )
        .unwrap()
        .then(res => {
          if (res.status <= 204) {
            let temp = [...getNotificationList];
            getNotificationList.find((v, i) => {
              if (v.id === notifId) {
                let updateRead = temp.fill({ ...v, isRead: true }, i, i + 1);
                setNotificationList([...updateRead]);
              }
            });
          } else if (res && res.data && res.status >= 400) {
            setErrorList(res.data);
            setShowModal(true);
          }
        });
    }
  };

  const handleMarkAllAsRead = React.useCallback(() => {
    const thunk = setItemMarkAllAsRead({
      id: userId ?? '',
    });
    thunkDispatch(thunk)
      .unwrap()
      .then(res => {
        if (res.status >= 400) {
          return;
        } else {
          fetchListNotification();
        }
      })
      .catch(err => {
        if (process.env.NODE_ENV !== 'production') console.error(err);
      });
  }, [userId, thunkDispatch]);

  const parseError = (errors: any) => {
    if (errors) {
      let errorsList: string[] = [];
      Object.values(errors).forEach((prop: any) => {
        errorsList = errorsList.concat(_.map(prop, _.property('message')));
      });
      return errorsList.join('\r\n');
    }
    return null;
  };

  const canSeeDetail = (e: NotificationItemProp) => {
    switch (e.code) {
      case NotificationCode.scrim_challengermember_ondirectinvitation:
        return true;
      case NotificationCode.scrim_opponent_ondirectinvitation:
        return true;
      case NotificationCode.scrim_opponentcaptain_ondirectinvitation:
        return true;
      case NotificationCode.scrim_opponentmember_ondirectaccepted:
        return true;
      case NotificationCode.scrim_challenger_ondirectaccepted:
        return true;
      case NotificationCode.scrim_challengercaptain_onrejected:
        return true;
      case NotificationCode.scrim_opponent_onrejected:
        return true;
      case NotificationCode.scrim_challenger_onrejected:
        return true;
      case NotificationCode.scrim_challengermember_onopeninvitation:
        return true;
      case NotificationCode.scrim_opponentmember_onopenaccepted:
        return true;
      case NotificationCode.scrim_challenger_ondirectaccepted:
        return true;
      case NotificationCode.scrim_challenger_onopenaccepted:
        return true;
      case NotificationCode.team_existingmember_oninvite:
        return true;
      case NotificationCode.team_newmember_oninvite:
        return true;
      case NotificationCode.team_general_oninviteaccepted:
        return true;
      case NotificationCode.team_captain_onrequest:
        return true;
      case NotificationCode.team_existingmember_onrequestaccepted:
        return true;
      case NotificationCode.team_newmember_onrequestaccepted:
        return true;
      default:
        return false;
    }
  };
  const notificationFlowAction = (e: NotificationItemProp) => {
    switch (e.code) {
      case NotificationCode.scrim_challengermember_ondirectinvitation: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', true);
        break;
      }
      case NotificationCode.scrim_opponent_ondirectinvitation: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', true);
        break;
      }
      case NotificationCode.scrim_opponentcaptain_ondirectinvitation: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', true);
        break;
      }
      case NotificationCode.scrim_opponentmember_ondirectaccepted: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', true);
        break;
      }
      case NotificationCode.scrim_challenger_ondirectaccepted: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', true);
        break;
      }
      case NotificationCode.scrim_challengercaptain_onrejected: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', false);
        break;
      }
      case NotificationCode.scrim_opponent_onrejected: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', false);
        break;
      }
      case NotificationCode.scrim_challenger_onrejected: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', false);
        break;
      }
      case NotificationCode.scrim_challengermember_onopeninvitation: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', true);
        break;
      }
      case NotificationCode.scrim_opponentmember_onopenaccepted: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', false);
        break;
      }
      case NotificationCode.scrim_challenger_onopenaccepted: {
        setUnreadSelected(e.id);
        getScrimDetail(e.data.actionId ?? '', false);
        break;
      }
      case NotificationCode.team_existingmember_oninvite: {
        fetchTeamData(e.data?.actionId ?? '', 'members');
        break;
      }
      case NotificationCode.team_newmember_oninvite: {
        setUnreadSelected(e.id);
        history.push(`/my-team/invitation`);
        break;
      }
      case NotificationCode.team_general_oninviteaccepted: {
        setUnreadSelected(e.id);
        fetchTeamData(e.data?.actionId ?? '');
        break;
      }
      case NotificationCode.team_captain_onrequest: {
        setUnreadSelected(e.id);
        fetchTeamData(e.data?.actionId ?? '', 'members');
        break;
      }
      case NotificationCode.team_existingmember_onrequestaccepted: {
        setUnreadSelected(e.id);
        fetchTeamData(e.data?.actionId ?? '');
        break;
      }
      case NotificationCode.team_newmember_onrequestaccepted: {
        setUnreadSelected(e.id);
        fetchTeamData(e.data?.actionId ?? '', 'members');
        break;
      }
      default: {
        setUnreadSelected(e.id);
        break;
      }
    }
  };

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: t('notification_header_title') }),
      background: { image: ImageSource('app.background', 'png') },
      appBar: {
        logo: false,
        back: {
          to: 'goback',
        },
        title: t('notification_header_title'),
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, []);

  return (
    <>
      <BackgroundImage
        src={ImageSource('card.background')}
        backgroundColor="linear-gradient(transparent, #00000080, #00000088, transparent)"
        mask="linear-gradient(transparent, #00000080, #00000023, transparent)"
        styled={{
          height: '322px',
        }}
      />
      {getNotificationList && getNotificationList.length >= 1 ? (
        <ContentContainer>
          <RowContainer width="100%" margin="32px 0px">
            <RowContainer grow={1} margin="0px 16px">
              <NotifTitle>{t('notification_title')}</NotifTitle>
              <NotifButton>{unreadMessageCount ?? 0}</NotifButton>
            </RowContainer>
            <RowContainer grow={0} margin="0px 6px">
              {totalItems >= 1 && (
                <NotifButton onClick={handleMarkAllAsRead}>
                  {t('notification_read_all')}
                </NotifButton>
              )}
            </RowContainer>
          </RowContainer>
          {getNotificationList.map((e: NotificationItemProp) => {
            return (
              <NotificationItem
                key={e.id}
                {...e}
                isRead={e.isRead}
                seeDetails={canSeeDetail(e)}
                onClickItem={() => notificationFlowAction(e)}
                onClickDetail={() => notificationFlowAction(e)}
              />
            );
          })}
          {getNotificationList.length >= 20 && moreData && (
            <ScrollLoadMore
              loadMore={async () => {
                if (moreData && !moreLoading) {
                  return await new Promise(() => moreData);
                } else {
                  return false;
                }
              }}
              onLoadMore={async () => {
                if (!moreLoading && moreData && totalItems >= 1) {
                  await loadMoreAsync();
                  setPageIndex(p => p + 1);
                }
              }}
              reloadDelayMs={5000}
              style={{ marginTop: 50 }}
            />
          )}
        </ContentContainer>
      ) : (
        !isLoading && (
          <Stack
            align="center"
            position="relative"
            direction="column"
            margin="50x 0px 0px"
            disableDrag
            disableSelectText
          >
            <img
              style={{ backgroundRepeat: 'no-repeat' }}
              src={IconSource('mabar-typeface-fullwhite')}
              alt="mabar-typeface-fullwhite"
            />
            <NormalText
              color="#969393"
              textAlign="center"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              margin="24px 0px"
            >
              {t('notification_empty')}
            </NormalText>
          </Stack>
        )
      )}

      <Modal show={showModal}>
        <MessageModal
          btnConfirmText={
            <NormalText fontSize="13px" fontWeight="700" color="white" padding="0px 65px 0px 65px">
              OK
            </NormalText>
          }
          onConfirmClick={async () => setShowModal(false)}
          title={t('errors:HTTP400')}
        >
          <NormalText fontSize="13px" fontWeight="500px" color="#FF4D6D">
            {getErrorList
              ? parseError(getErrorList?.errors) ?? getErrorList?.detail
              : 'Ada kesalahan, Silahkan kamu coba lagi!'}
          </NormalText>
        </MessageModal>
      </Modal>
    </>
  );
};

export enum NotificationCode {
  scrim_challengermember_ondirectinvitation = 'scrim.challengermember.ondirectinvitation',
  scrim_opponentcaptain_ondirectinvitation = 'scrim.opponentcaptain.ondirectinvitation',
  scrim_opponent_ondirectinvitation = 'scrim.opponent.ondirectinvitation',
  scrim_opponentmember_ondirectaccepted = 'scrim.opponentmember.ondirectaccepted',
  scrim_challenger_ondirectaccepted = 'scrim.challenger.ondirectaccepted',
  scrim_challengercaptain_onrejected = 'scrim.challengercaptain.onrejected',
  scrim_opponent_onrejected = 'scrim.opponent.onrejected',
  scrim_challenger_onrejected = 'scrim.challenger.onrejected',
  scrim_challengermember_onopeninvitation = 'scrim.challengermember.onopeninvitation',
  scrim_opponentmember_onopenaccepted = 'scrim.opponentmember.onopenaccepted',
  scrim_challenger_onopenaccepted = 'scrim.challenger.onopenaccepted',
  team_existingmember_oninvite = 'team.existingmember.oninvite',
  team_newmember_oninvite = 'team.newmember.oninvite',
  team_general_oninviteaccepted = 'team.general.oninviteaccepted',
  team_captain_onrequest = 'team.captain.onrequest',
  team_newmember_onrequestrejected = 'team.newmember.onrequestrejected',
  team_existingmember_onrequestaccepted = 'team.existingmember.onrequestaccepted',
  team_newmember_onrequestaccepted = 'team.newmember.onrequestaccepted',
  user_general_onregistered = 'user.general.onregistered',
  user_password_onreset = 'user.password.onreset',
  user_password_onresetcompleted = 'user.password.onresetcompleted',
  user_general_oninvite = 'user.general.oninvite',
  user_general_onverify = 'user.general.onverify',
}

export default NotificationPage;
