import { ExpandMore, TeamInfoStyled } from './styles';
import { Box, Divider, CardHeader, CardActions, Collapse, Avatar } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import { Text } from 'design/Text';
import React, { useState, ReactNode } from 'react';
import SVG from 'react-inlinesvg';

export interface ExpandMoreProps extends IconButtonProps {
  theme?: any;
  expand: boolean;
}

export interface TeamInfoProps {
  theme?: any;
  teamLogo?: string;
  teamLabel?: string;
  teamName: string;
  teamLocation?: string;
  teamAvatar?: ReactNode;
  players: Array<any>;
  expanded: boolean;
  gradient?: boolean;
}

export const TeamInfo = ({
  teamLogo,
  teamLabel,
  teamName,
  teamLocation,
  teamAvatar,
  players,
  gradient,
}: TeamInfoProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <TeamInfoStyled gradient={gradient!} expanded={expanded}>
      <Box className="bg-gradient-dark">
        <CardHeader
          avatar={
            <Avatar
              variant="rounded"
              sx={{
                width: '87px',
                height: '87px',
                border: '2px solid rgba(241, 235, 235, 0.6)',
                borderRadius: '12px',
              }}
              aria-label="logo-team"
              src={teamLogo}
            />
          }
          title={
            <>
              <Text variant="labelSmall" sx={{ color: 'white', mb: '6px', display: 'block' }}>
                {teamLabel}
              </Text>
              <Text
                variant="h3"
                sx={{
                  fontWeight: '700',
                  color: 'white',
                  mb: '6px',
                  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                }}
              >
                {teamLocation}
              </Text>
              <Text
                variant="bodySmall"
                sx={{ color: 'white', opacity: 0.7, lineHeight: '16px', mb: '7px' }}
              >
                {teamName}
              </Text>
            </>
          }
          subheader={teamAvatar}
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {players}
        </Collapse>
        <Divider sx={{ borderColor: gradient ? 'transparent ' : 'rgb(255,255,255,0.1)' }} />
        <CardActions disableSpacing>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
          >
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <Text
                variant="button"
                sx={{ fontWeight: '700', color: 'gray04' }}
                gradient={!gradient}
              >
                Lihat Daftar Pemain
              </Text>
              <SVG
                src={
                  gradient
                    ? `${process.env.PUBLIC_URL}/media/icons/ic-caret-down-small-white.svg`
                    : `${process.env.PUBLIC_URL}/media/icons/ic-caret-down-small-gradient.svg`
                }
              />
            </ExpandMore>
          </Box>
        </CardActions>
      </Box>
    </TeamInfoStyled>
  );
};
