import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { ScrollViewProps } from '.';

export const ScrollContainer = styled(motion.div)<ScrollViewProps>`
  display: ${({ display }) => display};
  position: ${({ position }) => position};
  margin: ${({ margin }) => margin};
  box-sizing: border-box;
  scroll-behavior: auto !important;
  -webkit-overflow-scrolling: touch;
  ${({ scrollVisibility }) =>
    scrollVisibility === true
      ? css`
          overflow: scroll;
        `
      : css`
          overflow: -moz-scrollbars-none;
          -ms-overflow-style: none;
          scrollbar-width: none;
          ::-webkit-scrollbar {
            display: none !important;
            pointer-events: none;
            width: 0;
            height: 0;
            background: transparent !important;
            -webkit-appearance: none !important;
          }
          ::-webkit-scrollbar-thumb {
            background: transparent;
          }
        `}
  ${({ direction }) => {
    switch (direction) {
      case 'horizontal':
        return css`
          overflow-x: auto !important;
          overflow-y: hidden !important;
          flex-direction: row;
          -webkit-flex-direction: row;
          white-space: nowrap;
          flex-wrap: nowrap;
          flex-shrink: 0;
          flex-flow: row;
        `;
      case 'vertical':
        return css`
          overflow-y: auto !important;
          overflow-x: hidden !important;
          white-space: pre-wrap;
          flex-direction: column;
          -webkit-flex-direction: column;
          flex-wrap: wrap;
          flex-flow: column;
          flex-shrink: 1;
        `;
      case 'both':
        return css`
          overflow-y: auto !important;
          overflow-x: auto !important;
        `;
      default:
        return css``;
    }
  }}
    ${({ align }) => {
    switch (align) {
      case 'start':
        return css`
          align-items: flex-start;
          align-content: flex-start;
          justify-content: flex-start;
          justify-items: flex-start;
        `;
      case 'center':
        return css`
          align-items: center;
          align-content: center;
          justify-content: center;
          justify-items: center;
        `;
      case 'end':
        return css`
          align-items: flex-end;
          align-content: flex-end;
          justify-content: flex-end;
          justify-items: flex-end;
        `;
      default:
        return css``;
    }
  }}
  max-height: ${({maxHeight})=> maxHeight};
  max-width: ${({maxWidth})=> maxWidth};
`;
