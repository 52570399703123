import { MatchProps } from '.';
import { Box, Stack, Avatar } from '@mui/material';
import { Text } from 'design/Text';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { MatchStatusKey, MatchResultKey } from 'redux/scrim/ScrimType';

interface TeamProps {
  variant: 'home' | 'away';
  team?: MatchProps['home'] | MatchProps['away'];
  result?: MatchProps['result'];
}

export const Team = ({ variant, team, result }: TeamProps) => {
  const disputeAndNotValid =
    result?.status !== MatchStatusKey.Dispute && result?.status !== MatchStatusKey.NotValid;

  const TeamIndicator: any = () =>
    team?.indicator?.map((indicator, idx) => {
      const handleIndicatorColor = () => {
        if (result?.type === MatchResultKey.Walkover && team.result === 'win') {
          return 'success500';
        }
        if (indicator.result === 'win') {
          return 'success500';
        }
        if (indicator.result === 'lose') {
          return 'danger500';
        }
        return 'neutral550';
      };

      return (
        <Box
          key={idx}
          sx={{
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            bgcolor: handleIndicatorColor(),
            margin: '0 2px 4px 2px',
          }}
        />
      );
    });

  return (
    <Box
      sx={{
        width: disputeAndNotValid ? '35%' : '30%',
        textAlign: variant === 'home' ? 'left' : 'right',
      }}
    >
      <Stack
        direction="row"
        justifyContent={variant === 'home' ? 'flex-start' : 'flex-end'}
        alignItems="center"
        sx={{ mb: '10px' }}
      >
        <Avatar
          src={team?.logo}
          alt={team?.name}
          sx={theme => ({
            width: '40px',
            height: '40px',
            border: `2px solid ${theme.palette.gray02}`,
            borderRadius: theme.borderRadius.md,
            order: variant === 'home' ? 0 : 1,
          })}
        />

        <Stack
          direction="row"
          sx={{
            order: variant === 'home' ? 1 : 0,
            flexFlow: variant === 'home' ? 'row-reverse wrap' : 'row wrap',
            justifyContent: variant === 'home' ? 'end' : 'start',
            m: variant === 'home' ? '0 10px 0 8px' : '0 8px 0 10px',
          }}
        >
          <TeamIndicator />
        </Stack>
      </Stack>

      <Text
        variant="label"
        sx={{ fontSize: '12px', fontWeight: 600, lineHeight: '140%', mb: '2px' }}
        component="div"
      >
        <LinesEllipsis text={team?.name ?? ''} maxLine={2} ellipsis="..." />
      </Text>

      <Text
        variant="bodySmall"
        sx={{ fontSize: '10px', color: 'neutral400', letterSpacing: '0.16px', lineHeight: '140%' }}
        component="div"
      >
        <LinesEllipsis text={team?.location ?? ''} maxLine={2} ellipsis="..." />
      </Text>
    </Box>
  );
};
