import React, { ReactNode, createContext, useState, useMemo } from 'react';

interface ScrollProviderProps {
  children?: ReactNode;
}

interface ScrollProps {
  scrollRef: any;
}
export const defaultScrollValue: ScrollProps = {
  scrollRef: undefined,
};

export const ScrollContext = createContext<any>({});

const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const [value, setValue] = useState<ScrollProps>();

  const scrollValue = useMemo(
    () => ({
      value: {
        scrollRef: value?.scrollRef ?? defaultScrollValue.scrollRef,
      },

      setValue,
    }),
    [value],
  );

  return <ScrollContext.Provider value={scrollValue}>{children}</ScrollContext.Provider>;
};

export default ScrollProvider;
