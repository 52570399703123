import { CardTournamentStatus, CardTournamentChampion, TournamentStatusIcon } from './styles';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Text } from 'design/Text';
import he from 'he';
import { IconSource } from 'modules/assetpath';
import React from 'react';

interface TournamentStatusProps {
  title?: any;
  status?: string;
  name?: string;
  logo?: string;
}

const handleIcon = ({ status }: TournamentStatusProps) => {
  switch (status) {
    case 'success':
      return 'ic-check-green';
    case 'warning':
      return 'ic-timer-orange';
    case 'closed':
      return 'ic-battlesword';
    case 'failed':
      return 'ic-close-circle';
    case 'waiting-admin':
      return 'ic-wait-for-admin';
    default:
      return status ? status : '';
  }
};

const handleColor = ({ status }: TournamentStatusProps) => {
  switch (status) {
    case 'success':
      return 'success500';
    case 'warning':
      return 'primary500';
    case 'waiting-admin':
        return 'primary500';
    case 'closed':
      return 'primary600';
    case 'failed':
      return 'danger500';
    default:
      return 'neutral200';
  }
};
const handleBackground = ({ status }: TournamentStatusProps) => {
  switch (status) {
    case 'success':
      return 'success900';
    case 'warning':
      return 'neutral600';
    case 'waiting-admin':
      return 'neutral600';
    case 'closed':
      return 'primary900';
    case 'failed':
      return 'neutral550';
    default:
      return 'neutral600';
  }
};

export const TournamentStatus = ({ title, status, name, logo }: TournamentStatusProps) => {
  return status == 'complete' ? (
    <CardTournamentChampion>
      <Avatar variant="rounded" src={logo} />
      <Stack sx={{ paddingTop: '5px' }}>
        <Text variant="labelSmall" sx={{ color: 'primary600' }}>
          CHAMPION
        </Text>
        <Text variant="h3">{name}</Text>
      </Stack>
    </CardTournamentChampion>
  ) : (
    <CardTournamentStatus>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '8px',
          bgcolor: handleBackground({ status }),
        }}
      >
        <TournamentStatusIcon src={IconSource(handleIcon({ status }))} />
        <Text
          variant="subtitle"
          sx={theme => ({
            mb: 0,
            fontFamily: theme.fontFamily.lexendDeca,
            color: handleColor({ status }),
          })}
          dangerouslySetInnerHTML={{ __html: he.decode(title) }}
        ></Text>
      </Stack>
    </CardTournamentStatus>
  );
};
