import { Box, Stack } from '@mui/material';
import BackgroundImage from 'components/layouts/BackgroundImage';
import { Blockquoute } from 'components/styled/layout.styled';
import { GradientText, SpanText } from 'components/styled/text.styled';
import { LayoutContext } from 'context/LayoutContext';
import { Text } from 'design/Text';
import { compiler } from 'markdown-to-jsx';
import { ImageSource } from 'modules/assetpath';
import React, { FC, ReactNode, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export interface SupportLayoutProps {
  headers: HeaderSupportType;
  content?: string;
  children?: ReactNode;
}

const SupportLayout = ({ headers, content, children }: SupportLayoutProps) => {
  const { t } = useTranslation();

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: headers.title }),
      background: {
        image: ImageSource('app.background', 'png'),
        opacity: '0.04',
        mask: 'linear-gradient(359.1deg, #131318 0.29%, rgba(19, 19, 24, 0.97299) 32.28%, rgba(19, 19, 24, 0.934402) 43.9%, rgba(19, 19, 24, 0.89284) 56.38%, rgba(19, 19, 24, 0.02) 80.4%)',
        container: 'rgb(10, 10, 11) none repeat scroll 0% 0%',
      },
      appBar: {
        title: headers.title,
        back: {
          to: 'goback',
        },
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, []);

  return (
    <HeaderSupport {...headers}>
      {content ? (
        <Box
          sx={{
            background: 'rgba(55, 57, 66, 0.6)',
            padding: '16px',
            borderRadius: '16px',
            margin: '10px 10px 80px',
          }}
        >
          <Stack direction="row" style={{ alignItems: 'flex-start' }}>
            <Stack direction="column" alignItems="start">
              <SpanText
                fontSize="12px"
                color="white"
                lineHeight="17.52px"
                fontWeight="400"
                textAlign="justify"
                textJustify="inter-word"
                letterSpacing="0px"
                onClick={e => e}
              >
                {compiler(content, {
                  overrides: {
                    strong: {
                      component: SpanText,
                      props: {
                        color: 'white',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '19.6px',
                      },
                    },
                    h3: {
                      component: SpanText,
                      props: {
                        color: '#969393',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '19.6px',
                      },
                    },
                    a: {
                      component: GradientText,
                      props: {
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '140%',
                        isPressable: true,
                        disableSelectText: true,
                        onClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                          if (e.currentTarget && e.currentTarget.getAttribute('href'))
                            window.open(e.currentTarget.getAttribute('href') ?? '#', '_blank');
                        },
                      },
                    },
                    blockquote: {
                      component: Blockquoute,
                      props: {
                        display: 'block',
                        background: '#2D2F37',
                        borderRadius: '16px',
                        width: 'auto',
                        padding: '4px 16px',
                      },
                    },
                  },
                })}
              </SpanText>
            </Stack>
          </Stack>
        </Box>
      ) : (
        children
      )}
    </HeaderSupport>
  );
};

export interface HeaderSupportType {
  headerBackground?: string | undefined;
  title?: string | undefined;
  update?: string | undefined;
  children?: React.ReactNode;
}

export const HeaderSupport: FC<HeaderSupportType> = ({
  headerBackground,
  title,
  update,
  children,
}) => {
  return (
    <Box width="100%">
      <BackgroundImage
        src={headerBackground}
        backgroundColor="#0b0b0ee6"
        minHeight="242px"
        mask="linear-gradient(transparent, #000000a4, #2e29294c, #2e292926, transparent)"
      >
        <Box width="100%">
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="end"
            sx={{ margin: '-55px 24px 0px' }}
          >
            <Text
              variant="h1"
              sx={{
                fontFamily: 'Roboto',
                textAlign: 'center',
                color: 'white',
                fontSize: '25.2px',
                lineHeight: 'normal',
              }}
            >
              {title}
            </Text>
            <Text
              variant="bodySmall"
              sx={{
                fontFamily: 'Roboto',
                textAlign: 'center',
                color: '#969393',
                margin: '8px 0px 0px',
                fontWeight: '700',
                lineHeight: '17.52px',
              }}
            >
              {update}
            </Text>
          </Stack>
          <Stack
            position="absolute"
            display="flex"
            zIndex={1945}
            margin="16px 0px 0px"
            width="100%"
          >
            {children}
          </Stack>
        </Box>
      </BackgroundImage>
    </Box>
  );
};
export default SupportLayout;
