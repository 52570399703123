import { IconSource } from 'modules/assetpath';
import { Colors } from 'modules/theme';
import React, { FC } from 'react';
import {
  IcProfileBackground,
  ImageProfile,
  PhotoProfileBackground,
  PhotoProfileInnerBackground,
  PhotoProfileInnerBorder,
  ProfileInnerGradient,
  ProfileText,
} from './styles';

export interface AccountPhotoProfileType {
  variant: 'normal' | 'gradient' | 'upload-exist' | 'upload-empty';
  photoProfile?: string | undefined;
  uploadPhotoClick?: (() => void) | undefined;
}

const AccountPhotoProfile: FC<AccountPhotoProfileType> = ({
  photoProfile,
  uploadPhotoClick,
  variant,
}) => {
  return (
    <PhotoProfileBackground>
      <PhotoProfileInnerBackground>
        <PhotoProfileInnerBorder
          isEmpty={
            (variant === 'upload-empty' || !photoProfile) && variant !== 'gradient' ? true : false
          }
        >
          {variant === 'upload-exist' && photoProfile && (
            <IcProfileBackground onClick={uploadPhotoClick}>
              <ImageProfile
                width="27px"
                height="27px"
                alt="Image Profile"
                src={IconSource('ic-add-profile')}
              />
            </IcProfileBackground>
          )}
          {(variant === 'upload-empty' || !photoProfile) && variant !== 'gradient' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={uploadPhotoClick}
            >
              <ImageProfile
                width="24.45px"
                height="16.3px"
                alt="Image Profile"
                src={IconSource('upload-profile')}
              />
              <ProfileText
                margin="10px 0px 0px 0px"
                color={Colors.gray03}
                fontSize="12px"
                fontWeight="400px"
                fontStyle="normal"
              >
                Upload
              </ProfileText>
            </div>
          )}
        </PhotoProfileInnerBorder>

        {photoProfile && (variant === 'normal' || variant === 'upload-exist') && (
          <ImageProfile
            fit="cover"
            borderRadius="50%"
            width="100%"
            height="100%"
            src={photoProfile}
          />
        )}

        {variant === 'gradient' && <ProfileInnerGradient />}
      </PhotoProfileInnerBackground>
    </PhotoProfileBackground>
  );
};

export default AccountPhotoProfile;
