import React from 'react';
import { IconSource } from 'modules/assetpath';
import { useTitle } from 'react-use';
import { NormalText } from 'components/styled/text.styled';
import { Stack } from 'components/styled/layout.styled';
import { useTranslation } from 'react-i18next';

const TournamentListPage = () => {
  useTitle(`Team > My Team | Mabar`);
  const {t} = useTranslation();
  return (
    <Stack direction="column" align="center" margin="130px 0px 0px 0px" disableSelectText>
      <img alt="" src={IconSource('mabar-typeface-fullwhite')} />
      <NormalText
        fontSize="14px"
        fontWeight="400"
        color="#969393"
        lineHeight="20.44px"
        textAlign="center"
        margin="24px 0px 0px 0px"
      >
        {t('player_explore_tournament_coming_soon')}
        <br />
        {t('player_explore_tournament_empty_state')}
      </NormalText>
    </Stack>
  );
};

export default TournamentListPage;
