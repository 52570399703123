import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC, ReactNode } from 'react';
import {  UseFormRegisterReturn } from 'react-hook-form';
import { CSSProperties } from 'styled-components';
import { Input, BorderInput, NewLabel } from './styles';
export interface InputSearchProps {
  id?: string;
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  placeholder?: string;
  label: ReactNode;
  labelBottom?: string;
  minLength?: number;
  maxLength?: number;
  isRequired?: boolean;
  value?: string | number | readonly string[] | undefined;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  type?: string;
  margin?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onInvalid?: React.FormEventHandler<HTMLInputElement> | undefined;
  validationText?: string;
  validationType?: 'ERROR' | 'WARNING' | 'INFO' | string | undefined;
  registerForm?: UseFormRegisterReturn;
  disabled?: boolean | undefined;
  defaultValue?: any;
  style?: CSSProperties;
}
export const InputField: FC<InputSearchProps> = ({
  id,
  ref,
  label,
  labelBottom,
  placeholder,
  minLength,
  maxLength,
  isRequired,
  value,
  inputMode,
  type,
  margin,
  onChange,
  onInvalid,
  validationText,
  validationType,
  registerForm,
  disabled,
  style,
  defaultValue
}) => {
  let validationRef = React.useRef<HTMLParagraphElement | null>();
  return  (
    <Stack margin={margin} direction='column'>
      <BorderInput style={validationType ? {border:'1px solid #d51f40'} : style} padding="0px 25px 0px 0px">
        <NewLabel>{label}</NewLabel>
        <Input
          id={id}
          ref={ref}
          autoComplete='off'
          autoCorrect='off'
          aria-autocomplete='none'
          placeholder={placeholder}
          onChange={(e)=>{
            if(onChange){
              onChange(e)
            }
          }}
          inputMode={inputMode}
          type={type}
          minLength={minLength}
          maxLength={maxLength}
          required={isRequired}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          onInvalid={onInvalid}
          autoFocus={validationType ? true : false}
          {...registerForm}
        />
      </BorderInput>
      { !validationType && (
        <Stack
        align="start"
        direction="row"
        margin="-8px 0px 0px 5px"
        disableSelectText
        >
        <NormalText
          color={'#FFFFFF'}
          opacity="30%"
          fontSize="12px"
          lineHeight="146%"
          fontStyle="normal"
          fontWeight="400"
        >
          {labelBottom}
        </NormalText>
        </Stack>
      )}
      { validationType && (
        <Stack
        ref={e => (validationRef.current = e)}
        align="start"
        direction="row"
        margin="-8px 0px 0px 5px"
        disableSelectText
        >
        <NormalText
          color={
            validationType === 'ERROR'
              ? '#FF4D6D'
              : validationType === 'WARNING'
              ? '#E7B450'
              : validationType === 'INFO'
              ? '#DDD8D8'
              : 'white'
          }
          fontSize="12px"
          lineHeight="146%"
          fontStyle="normal"
          fontWeight="400"
        >
          {validationText}
        </NormalText>
        </Stack>
      )}
    </Stack>
  );
};

export default InputField;
