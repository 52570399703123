import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { Action, Dispatch } from '@reduxjs/toolkit';
import { Middleware, MiddlewareAPI } from 'redux';
import {
  ICheckInMessage,
  INotificationMessage,
  subscribeNotificationHub,
  subscribeToMatchHub,
  setNotificationEvent,
} from 'redux/notification/NotificationReducer';
import { userManager } from 'services/AuthService';
import events from 'events';
import { loginAction } from 'redux/auth/AuthReducer';
import { LogDev } from 'modules/helpers';

export const emitter: events.EventEmitter = new events.EventEmitter();

const signalRMiddleware: Middleware<Dispatch> = ({ dispatch }: MiddlewareAPI) => {
  return (next: Dispatch) =>
    <A extends Action>(action: A | Action) => {
      switch (action.type) {
        case subscribeNotificationHub.type:
          emitter.removeAllListeners();
          dispatch(setNotificationEvent(null));
          setupNotificationHubConnection().then((connection: HubConnection) => {
            LogDev('INFO',`Notification Connection state: ${connection.state}`);
            if (connection.state === HubConnectionState.Connected) {
                LogDev('INFO','Notification Hub');
                LogDev('INFO',`Connection ID: ${connection.connectionId}`);
              emitter.emit('CONNECTION', connection);
            }
          });
          break;
        case subscribeToMatchHub.type:
          emitter.removeAllListeners();
          dispatch(setNotificationEvent(null));
          setupMatchHubConnection(
            (action as unknown as { payload: { matchId: string } }).payload.matchId,
          ).then((connection: HubConnection) => {              
                LogDev('INFO',`Match Connection state: ${connection.state}`);
            if (connection.state === HubConnectionState.Connected) {
              {
                LogDev('INFO','Match Hub');
                LogDev('INFO',`Connection ID: ${connection.connectionId}`);
              }
              emitter.emit('CONNECTION', connection);
            }
          });
          break;
        default:
          break;
      }

      return next(action);
    };
};

const setupNotificationHubConnection = async () => {
  const oidcStorage = await userManager.getUser()
  let jwtToken = oidcStorage?.access_token ?? '';
  
  if (oidcStorage) {
    const isNotExpired = !oidcStorage.expired;
    if (oidcStorage.access_token && isNotExpired) {
      jwtToken = `${oidcStorage.access_token}`;
    }else{
      loginAction()
    }
  }
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.SIGNALR_BASE_URL}hub/notification`, {
      accessTokenFactory: () => jwtToken,
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Warning)
    .build();

  // scrim
  connection.on('scrim.challengermember.ondirectinvitation', (message: INotificationMessage) => {
    // spec ID 1
    setNotificationEvent(message);    
    LogDev('INFO','scrim.challengermember.ondirectinvitation', message);
  });

  connection.on('scrim.opponentcaptain.ondirectinvitation', (message: INotificationMessage) => {
    // spec ID 2
    setNotificationEvent(message);
    LogDev('INFO','scrim.opponentcaptain.ondirectinvitation', message);
  });

  connection.on('scrim.opponent.ondirectinvitation', (message: INotificationMessage) => {
    // spec ID 2a
    setNotificationEvent(message);    
    LogDev('INFO','scrim.opponent.ondirectinvitation', message);
  });

  connection.on('scrim.opponentmember.ondirectaccepted', (message: INotificationMessage) => {
    // spec ID 3
    setNotificationEvent(message);    
    LogDev('INFO','scrim.opponentmember.ondirectaccepted', message);
  });

  connection.on('scrim.challenger.ondirectaccepted', (message: INotificationMessage) => {
    // spec ID 3a
    setNotificationEvent(message);    
    LogDev('INFO','scrim.challenger.ondirectaccepted', message);
  });

  connection.on('scrim.challengercaptain.onrejected', (message: INotificationMessage) => {
    // spec ID 4
    setNotificationEvent(message);
    LogDev('INFO','scrim.challengercaptain.onrejected', message);
  });

  connection.on('scrim.opponent.onrejected', (message: INotificationMessage) => {
    // spec ID 4a
    setNotificationEvent(message);    
    LogDev('INFO','scrim.opponent.onrejected', message);
  });

  connection.on('scrim.challenger.onrejected', (message: INotificationMessage) => {
    // spec ID 4b
    setNotificationEvent(message);    
    LogDev('INFO','scrim.challenger.onrejected', message);
  });

  connection.on('scrim.opponentmember.onopenaccepted', (message: INotificationMessage) => {
    // spec ID 6a
    setNotificationEvent(message);    
    LogDev('INFO','scrim.opponentmember.onopenaccepted', message);
  });

  connection.on('scrim.challenger.onopenaccepted', (message: INotificationMessage) => {
    // spec ID 6b
    setNotificationEvent(message);    
    LogDev('INFO','scrim.challenger.onopenaccepted', message);
  });

  connection.on('scrim.challengermember.onopeninvitation', (message: INotificationMessage) => {
    // spec ID 48
    setNotificationEvent(message);    
    LogDev('INFO','scrim.challengermember.onopeninvitation', message);
  });

  // team
  connection.on('team.existingmember.oninvite', (message: INotificationMessage) => {
    // spec ID 12
    setNotificationEvent(message);    
    LogDev('INFO','team.existingmember.oninvite', message);
  });

  connection.on('team.newmember.oninvite', (message: INotificationMessage) => {
    // spec ID 12a
    setNotificationEvent(message);    
    LogDev('INFO','team.newmember.oninvite', message);
  });

  connection.on('team.general.oninviteaccepted', (message: INotificationMessage) => {
    // spec ID 13
    setNotificationEvent(message);    
    LogDev('INFO','team.general.oninviteaccepted', message);
  });

  connection.on('team.captain.onrequest', (message: INotificationMessage) => {
    // spec ID 14
    setNotificationEvent(message);    
    LogDev('INFO','team.captain.onrequest', message);
  });

  connection.on('team.newmember.onrequestrejected', (message: INotificationMessage) => {
    // spec ID 15
    setNotificationEvent(message);    
    LogDev('INFO','team.newmember.onrequestrejected', message);
  });

  connection.on('team.existingmember.onrequestaccepted', (message: INotificationMessage) => {
    // spec ID 16
    setNotificationEvent(message);    
    LogDev('INFO','team.existingmember.onrequestaccepted', message);
  });

  connection.on('team.newmember.onrequestaccepted', (message: INotificationMessage) => {
    // spec ID 16a
    setNotificationEvent(message);    
    LogDev('INFO','team.newmember.onrequestaccepted', message);
  });

  // tournament
  connection.on('tournament.member.oninvite', (message: INotificationMessage) => {
    // spec ID 18
    setNotificationEvent(message);    
    LogDev('INFO','tournament.member.oninvite', message);
  });

  connection.on('tournament.captain.onaccepted', (message: INotificationMessage) => {
    // spec ID 19
    setNotificationEvent(message);    
    LogDev('INFO','tournament.captain.onaccepted', message);
  });

  connection.on('tournament.general.oncompleted', (message: INotificationMessage) => {
    // spec ID 21
    setNotificationEvent(message);    
    LogDev('INFO','tournament.general.oncompleted', message);
  });

  connection.on('tournament.general.onreschedule', (message: INotificationMessage) => {
    // spec ID 36
    setNotificationEvent(message);    
    LogDev('INFO','tournament.general.onreschedule', message);
  });

  connection.serverTimeoutInMilliseconds = 100000; // 100 second
  await connection.start().catch(err => {
    
      console.error(err);
  });

  return connection;
};

const setupMatchHubConnection = async (matchId: string) => {
  const oidcStorage = await userManager.getUser()
  let jwtToken = oidcStorage?.access_token ?? '';
  
  if (oidcStorage) {
    const isNotExpired = !oidcStorage.expired;
    if (oidcStorage.access_token && isNotExpired) {
      jwtToken = `${oidcStorage.access_token}`;
    }
  }
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.SIGNALR_BASE_URL}hub/match?matchId=${matchId}`, {
      accessTokenFactory: () => jwtToken
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Warning)
    .build();

  if (connection.state === HubConnectionState.Connected) {
    await connection.stop();
  }
  connection.onreconnected((connectionId: string | undefined) => {    
    LogDev('INFO',`Match Hub Connection Reconnected ${connectionId}`);
  });

  // register or subscribe realtime match hub event
  connection.on('match.lobby.oncheckin', (message: ICheckInMessage) => {
    // dispatch(setNotificationEvent(message));
    emitter.emit('match.lobby.oncheckin', message);    
      LogDev('INFO','match.lobby.oncheckin', message, 'connection', connection.connectionId);
  });

  connection.serverTimeoutInMilliseconds = 100000; // 100 second

  await connection.start().catch(err => {    
      LogDev('INFO','signalRERROR________', err);
  });

  connection.onclose((err)=> {    
    LogDev('ERROR', 'SIGNALR_ONCLOSE _', err)
  })

  return connection;
};

export default signalRMiddleware;
