import { Box, Avatar, Stack } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React from 'react';

export interface TeamProfileTournamentProps {
  bgImage?: string;
  teamName?: string;
  teamLogo?: string;
  teamInfo?: string;
  margin?: string;
  size?: {
    width?: string;
    height?: string;
  };
}

export const TeamProfileTournament = ({
  bgImage,
  teamName,
  teamLogo,
  teamInfo,
  margin,
  size,
}: TeamProfileTournamentProps) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-end"
      m={margin ?? '16px 0px'}
      sx={{
        bgcolor: 'neutral700',
        border: '1px solid #242628',
        borderRadius: '8px',
        position: 'relative',
        height: '166px',
        width: '100%',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          background:
            'radial-gradient(107.85% 87.04% at 1.31% 100%, #000000 0%, rgba(16, 16, 18, 0.6) 65.77%, rgba(29, 29, 32, 0) 100%)',
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 1,
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          display: 'flex',
          p: '16px',
          borderRadius: '8px',
          position: 'relative',
          zIndex: 2,
          width: '100%',
        }}
      >
        <Avatar
          alt={teamName}
          sx={theme => ({
            width: size?.width ?? 50,
            height: size?.height ?? 50,
            flex: '0 0 auto',
            borderRadius: '12px',
            border: '2px solid #F1EBEB',
            boxSizing: 'border-box',
            background: theme.palette.neutral700,
            img: { zIndex: 0 },
            '&:before': {
              content: "''",
              background: `url(${IconSource('union-placeholder')}) center no-repeat`,
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 0,
            },
            svg: {
              display: 'none',
            },
          })}
          src={teamLogo}
          variant="square"
        />
        <Box sx={{ flex: '1 1 auto', textAlign: 'left', ml: '16px' }}>
          <Text
            sx={theme => ({
              fontFamily: theme.fontFamily.lexendDeca,
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '25px',
              color: '#DBD2CF',
            })}
          >
            {teamName}
          </Text>
          <Text variant="subtitle" sx={{ color: '#A8A29F', mt: '4px' }}>
            {teamInfo}
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
};
