import React, { HTMLAttributes, ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import SVG from 'react-inlinesvg';
import { CustomButton } from './styles';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  theme?: Theme;
  sx?: SxProps<Theme>;
  variant?: 'small' | 'big';
  wide?: boolean;
  boxShadow?: boolean;
  disabled?: boolean;
  icon?: string;
  children?: ReactNode;
}

export const PrimaryButton = (props: ButtonProps) => {
  return (
    <CustomButton {...props}>
      {props.icon && <SVG src={props.icon} />}
      {props.children}
    </CustomButton>
  );
};
