import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginAction } from 'redux/auth/AuthReducer';
import { failedTeamSchoolListAction, getTeamSchoolListAction, successTeamSchoolListAction, TeamSchoolResponse } from './TeamListReducer';
import { getTeamSchoolListService } from './TeamService';

interface Res<T> {
  data: T;
  status: number;
}

function* fetchTeamSchoolListSaga(action: AnyAction) {
  try {
    const res: Res<TeamSchoolResponse> = yield call(getTeamSchoolListService, action.payload);
    if (res.status !== 200) {
      throw res;
    } else {
      yield put(successTeamSchoolListAction({data: res.data, code: res.status}));
    }
  } catch (e: any) {
    if (
      e?.response?.status === 400 ||
      e?.response?.status === 404 ||
      e?.response?.status === 422 ||
      e?.response?.status === 503
    ) {
      yield put(failedTeamSchoolListAction(e?.response?.data));
    } else if (e?.response?.status === 401) {
      yield call(() => loginAction());
    } else {
      yield put(failedTeamSchoolListAction({ errorCode: e?.response?.status }));
    }
  }
}

export default function* teamListSaga() {
  yield takeLatest(getTeamSchoolListAction.type, fetchTeamSchoolListSaga);
}
