import { styled } from '@mui/system';
import { ButtonProps } from './index';

const handlePadding = ({ variant }: ButtonProps) => {
  switch (variant) {
    case 'small':
      return '4px 16px';
    case 'big':
      return '12px 16px';
    default:
      return '8px 16px';
  }
};

const handleheight = ({ variant }: ButtonProps) => {
  switch (variant) {
    case 'small':
      return '32px';
    case 'big':
      return '48px';
    default:
      return '40px';
  }
};

export const CustomSecondaryButton: any = styled('button')<ButtonProps>`
  ${({ theme }) => theme.typography.button}
  line-height: 24px;
  cursor: pointer;
  text-transform: unset;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding: ${({ variant }) => handlePadding({ variant })};
  background: ${({ theme }) => theme.palette.neutral600};
  border: 1px solid ${({ theme }) => theme.palette.neutral500};
  border-radius: ${({ variant, theme }) =>
    variant === 'small' ? theme.borderRadius.sm : theme.borderRadius.md};
  width: ${({ wide }) => (wide ? '100%' : 'fit-content')};
  height: ${({ variant }) => handleheight({ variant })};

  &:active {
    border: 1px solid ${({ theme }) => theme.palette.neutral550};
  }

  svg {
    margin-right: 6px;
  }

  &:disabled {
    pointer-events: none;
    box-shadow: none;
    color: ${({ theme }) => theme.palette.neutral500};
    border: 1px solid ${({ theme }) => theme.palette.neutral550};
  }
`;
