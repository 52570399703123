/**
 * Configuration
 * @module config
 */

const config = {
  name: 'MABAR',
  description: 'MABAR adalah platform teknologi game untuk kompetisi esport pertama di Indonesia di mana Student-Athlete seperti kamu bisa bersaing antar-SMA. Lewat MABAR, kami ingin membantu student-athlete tetap GAME ON. Yaitu, terus mengejar mimpi dengan mengembangkan bakat dan tumbuh sebagai individu.',
};

export default config;
