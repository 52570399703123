import styled from 'styled-components';

export const NotificationContainer = styled.div<{
  isRead: boolean;
}>`
  border-radius: 16px;
  margin: 8px 16px;
  padding: 16px;
  opacity: 1;
  cursor: ${p => (p.isRead ? 'default' : 'pointer')};
  background: ${p =>
    p.isRead
      ? 'rgba(55, 57, 66, 0.94)'
      : '#F3AA36'};
  &:active {
    opacity: ${p => (p.isRead ? 1.0 : 0.7)};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  margin-top: -300px;
  flex-direction: column;
`;

export const NotifButton = styled.button`
  border-radius: 10px;
  margin: 6px 12px;
  padding: 5px 10px;
  font-size: 12px;
  color: #141518;
  font-weight: bold;
  font-family: 'Roboto';
  opacity: 1;
  cursor: pointer;
  background: #F3AA36;
  &:active {
    opacity: 0.7;
  }
`;

export const TextDate = styled.p<{ isRead: boolean }>`
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 16.06px;
  padding: 0px;
  margin: 0px;
  font-size: 11px;
  color: ${p => (p.isRead ? 'rgba(150, 147, 147, 1)' : 'neutral700')};
`;

export const RowContainer = styled.div<{
  grow?: number;
  width?: string;
  padding?: string;
  margin?: string;
}>`
  display: flex;
  width: ${p => p.width ?? 'auto'};
  padding: ${p => p.padding ?? '0px'};
  margin: ${p => p.margin ?? '0px'};
  flex-grow: ${p => p.grow ?? 1};
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const TextContent = styled.p<{ isRead: boolean }>`
  font-family: 'Roboto';
  padding: 0px;
  margin: 0px;
  color: ${p => (p.isRead ? '#F3AA36' : 'neutral700')};
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  line-height: 140%;
`;

export const NotifTitle = styled.p`
  font-family: 'Roboto';
  padding: 0px;
  margin: 0px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
`;

export const TextSeeDetail = styled.p<{ isRead: boolean }>`
  font-family: 'Roboto';
  padding: 0px;
  margin: 0px;
  margin-top: 8px;
  text-decoration: underline;
  color: ${p => (p.isRead ? '#F3AA36' : 'neutral700')};
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
`;
