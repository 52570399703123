import { HeaderStyled } from './styles';
import { Box, Toolbar, Menu, MenuItem, Stack, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useState, ReactNode } from 'react';

export interface HeaderProps {
  logo?: boolean;
  title?: string | ReactNode;
  share?: (shareOpen: boolean, handleShare: () => void) => void;
  menu?: Array<any>;
  setting?: {
    show?: boolean;
    onClick?: () => void;
  };
  notification?: {
    show?: boolean;
    haveNotifUnread?: boolean;
    onClick?: () => void;
  };
  back?: {
    to?: string | 'goback';
    hash?: string;
    state?: {};
    search?: string;
  };
  background?: string;
  cta?: ReactNode;
  separator?: boolean;
  sponsor?: ReactNode;
}

export const Header = ({
  logo,
  title,
  share,
  menu,
  setting,
  notification,
  back,
  cta,
  background,
  separator,
  sponsor,
}: HeaderProps) => {
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const [shareOpen, setShareOpen] = useState<boolean>(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(event.currentTarget);
    setShareOpen(false);
  };

  const handleCloseMenu = () => {
    setMenuOpen(null);
    setShareOpen(false);
  };

  const handleShare = () => {
    setShareOpen(!shareOpen);
  };

  const handleBack = () => {
    if (back?.to) {
      if (back?.to === 'goback') {
        return history.goBack();
      }
      return history.push({
        pathname: back?.to,
        hash: back?.hash,
        search: back?.search,
        state: back?.state,
      });
    }
  };

  const handleClick = (dest: string, onClick: () => void) => {
    if (dest) return history.push(dest);
    return onClick();
  };

  return (
    <HeaderStyled sx={{ background }} separator={separator}>
      <Toolbar sx={{ justifyContent: 'space-between' }} disableGutters>
        <Stack direction="row" alignItems="center" sx={{ position: 'relative', zIndex: 1 }}>
          {back && (
            <IconButton onClick={handleBack} size="small">
              <img src={IconSource('ic-arrow-back-light')} />
            </IconButton>
          )}

          {logo && (
            <Link to="/" >
              <Box sx={{ display: 'block', width: '80px' }}>
                <Stack sx={{ ml: '2px' }}>
                  <img src={IconSource('new-rrq-mabar-logo')} alt="mabar" />
                </Stack>
              </Box>
            </Link>
          )}
        </Stack>

        {title && (
          <Text
            variant="h3"
            sx={{ fontWeight: 700, position: 'absolute', width: '100%', textAlign: 'center' }}
          >
            {title}
          </Text>
        )}

        <Stack direction="row" alignItems="center" spacing="4px">
          {sponsor && <Box sx={{ mr: '4px' }}>{sponsor}</Box>}

          {share && (
            <>
              <IconButton onClick={handleShare} size="small">
                <img src={IconSource('ic-share-social-white')} />
              </IconButton>
              {share && share(shareOpen!, handleShare!)}
            </>
          )}

          {setting?.show && (
            <IconButton onClick={setting?.onClick} size="small" sx={{ mx: '4px' }}>
              <img src={IconSource('ic-setting')} />
            </IconButton>
          )}

          {notification?.show && (
            <IconButton onClick={notification?.onClick} size="small" sx={{ mx: '4px' }}>
              <img
                src={IconSource(
                  notification?.haveNotifUnread
                    ? 'ic-notifications-filled'
                    : 'ic-notifications-white',
                )}
              />
            </IconButton>
          )}

          {menu && (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenMenu} size="small">
                <img src={IconSource('ic-menu-options-white')} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={menuOpen}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(menuOpen)}
                onClose={handleCloseMenu}
                sx={{
                  mt: '40px',
                  '.MuiPaper-root': { borderRadius: '8px' },
                  '.MuiList-root': { py: 0 },
                  a: {
                    textDecoration: 'none',
                  },
                }}
              >
                {menu.map((menu, idx) => (
                  <Box key={idx} onClick={() => handleClick(menu?.dest, menu?.onClick)}>
                    <MenuItem
                      key={menu.title}
                      onClick={handleCloseMenu}
                      sx={{ minWidth: '188px', bgcolor: 'neutral600', p: '8px 12px' }}
                    >
                      {menu.icon && <img src={IconSource(menu.icon)} width={18} />}
                      <Text
                        variant="body"
                        sx={{ color: 'neutral200', fontWeight: 400, m: '0 0 0 8px' }}
                      >
                        {menu.title}
                      </Text>
                    </MenuItem>
                  </Box>
                ))}
              </Menu>
            </Box>
          )}

          {cta && cta}
        </Stack>
      </Toolbar>
    </HeaderStyled>
  );
};
