import { LayoutProps } from 'components/Layout';
import navigationMenu from 'data/navigationMenu.json';
import React, { ReactNode, createContext, useState, useMemo } from 'react';

interface LayoutProviderProps {
  children?: ReactNode;
}

export const defaultLayoutValue: LayoutProps = {
  appBar: {
    show: true,
    logo: undefined,
    title: '',
    share: undefined,
    menu: undefined,
    setting: undefined,
    notification: undefined,
    back: undefined,
    cta: undefined,
    background: '',
    separator: undefined,
    sponsor: undefined,
  },
  bottomBar: {
    show: true,
    photo: '',
    data: navigationMenu,
  },
  background: {
    image: '',
    mask: '',
    opacity: '0.05',
    color: '',
    container: '',
  },
  padding: '',
  pageTitle: '',
  children: undefined,
  show: true,
  additional: undefined,
  'data-testid': undefined,
};

export const LayoutContext = createContext<any>({});

const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [value, setValue] = useState<LayoutProps>();

  const layoutValue = useMemo(
    () => ({
      value: {
        appBar: { ...defaultLayoutValue.appBar, ...value?.appBar },
        bottomBar: { ...defaultLayoutValue.bottomBar, ...value?.bottomBar },
        background: { ...defaultLayoutValue.background, ...value?.background },
        padding: value?.padding ?? defaultLayoutValue.padding,
        pageTitle: value?.pageTitle ?? '',
        children: value?.children ?? defaultLayoutValue.children,
        show: value?.show ?? defaultLayoutValue.show,
        additional: value?.additional ?? defaultLayoutValue.additional,
        // 'data-testid': value?['data-testid'] ?? defaultLayoutValue['data-testid']
      },

      setValue,
    }),
    [value],
  );

  return <LayoutContext.Provider value={layoutValue}>{children}</LayoutContext.Provider>;
};

export default LayoutProvider;
