import { Box, Stack, Divider, Modal as MUIModal, Fade, Backdrop } from '@mui/material';
import AppLayout from 'components/layouts/AppLayout';
import { IconButton } from 'design/Buttons/IconButton';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardImportantInfo } from 'design/Cards/CardImportantInfo';
import { InfoModal } from 'design/Cards/InfoModal';
import { Match } from 'design/Cards/Match';
import { MatchRadioButton } from 'design/Forms/MatchRadioButton/index';
import { UploadImage } from 'design/Forms/UploadImage/index';
import { Modal } from 'design/Modal/index';
import { MatchStatus } from 'design/Ribbon/MatchStatus';
import { Text } from 'design/Text';
import { IconSource, ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { loginAction } from 'redux/auth/AuthReducer';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { fetchMatchDetail } from 'redux/match/matchServices';
import { useAppThunkDispatch } from 'redux/store';
import { postTournamentResult } from 'redux/tournament/TournamentServices';
import { userManager } from 'services/AuthService';

interface Match {
  round: number;
  isWin: any;
  attachmentFile?: Blob;
  showDel: boolean;
}

interface MatchResult {
  result: string;
}

interface MatchResultScore {
  homeScore: string;
  awayScore: string;
  homeScoreResult: string;
  awayScoreResult: string;
}

const TournamentMatchDetailUpload = () => {
  const { game_slug, tournament_slug, match_id } = useParams() as {
    game_slug: string;
    tournament_slug: string;
    match_id: string;
  };
  const sessionPlayerInfo = getPlayerProfileFromSession();
  const dispatch = useAppThunkDispatch();
  const [openContohScreenshot, setOpenContohScreenshot] = React.useState(false);
  const [sentButtonActive, setSentButtonActive] = React.useState(false);
  const [sentConfirmButtonActive, setSentConfirmButtonActive] = React.useState(false);
  const [showConfirmationPage, setShowConfirmationPage] = React.useState(false);
  const [noticeContent, setNoticeContent] = React.useState('Terjadi Kesalahan');
  const [openNoticeSubmit, setOpenNoticeSubmit] = React.useState(false);
  const [openNoticeReLogin, setOpenNoticeReLogin] = React.useState(false);
  const [matchesResult, setMatchesResult] = React.useState<Array<Match>>([]);
  const [homeMatchResult, setHomeMatchResult] = React.useState<Array<MatchResult>>([]);
  const [awayMatchResult, setAwayMatchResult] = React.useState<Array<MatchResult>>([]);
  const [matchResultScore, setMatchResultScore] = React.useState<MatchResultScore>();
  const [matchesCount, setMatchesCount] = React.useState(3);
  const [openLoader, setOpenLoader] = React.useState(false);
  const handleClickBack = () => {
    if (showConfirmationPage) {
      setShowConfirmationPage(false);
    } else {
      history.goBack();
    }
  };

  const appDispatch = useAppDispatch();
  const handleClickLogin = () => {
    appDispatch(loginAction());
  };

  var { tournamentMatchDetail, account } = useAppSelector(({ match, account }) => ({
    tournamentMatchDetail: match.matchDetail,
    account: account.data,
  }));

  

  useEffect(() => {
    if (match_id) {
      dispatch(fetchMatchDetail({ matchId: match_id })).unwrap();
    }
  }, [dispatch]);

  function generateDefaultMatchArray(matches: number) {
    const temp: Match[] = [];
    for (let i = 1; i <= Math.ceil(matches / 2); i++) {
      temp.push({ round: i, isWin: '', showDel: false });
    }
    setMatchesResult(temp);
  }

  function generateDefaultMatchResultArray(matches: number) {
    const Hometemp: MatchResult[] = [];
    for (let i = 1; i <= matches; i++) {
      Hometemp.push({ result: '' });
    }
    const awayTemp: MatchResult[] = [];
    for (let i = 1; i <= matches; i++) {
      awayTemp.push({ result: '' });
    }
    setHomeMatchResult(Hometemp);
    setAwayMatchResult(awayTemp);
  }

  useEffect(() => {
    if (match_id) {
      dispatch(fetchMatchDetail({ matchId: match_id })).unwrap();
    }

    if (tournamentMatchDetail) {
      setMatchesCount(tournamentMatchDetail.type)
      generateDefaultMatchArray(tournamentMatchDetail.type);
      generateDefaultMatchResultArray(tournamentMatchDetail.type);
    } else {
      history.push(`/tournament/${game_slug}/${tournament_slug}`);
    }
  }, []);

  function addMatch() {
    setMatchesResult(matchesResult => [
      ...matchesResult,
      { round: matchesResult.length + 1, isWin: '', showDel: true },
    ]);
  }

  function deletematch() {
    var array = [...matchesResult];
    array.pop();
    setMatchesResult(array);

    var homeArray = [...homeMatchResult];
    homeArray[array.length].result = '';
    setHomeMatchResult(homeArray);

    var awayArray = [...awayMatchResult];
    awayArray[array.length].result = '';
    setAwayMatchResult(awayArray);
  }

  function onChangeStatus(index: number, isWin: boolean) {
    var array = [...matchesResult];
    array[index].isWin = isWin;
    setMatchesResult(array);

    var homeArray = [...homeMatchResult];
    homeArray[index].result = isWin ? 'win' : 'lose';
    setHomeMatchResult(homeArray);

    var awayArray = [...awayMatchResult];
    awayArray[index].result = !isWin ? 'win' : 'lose';
    setAwayMatchResult(awayArray);
  }

  function onChangeImageUpload(index: number, imageBlob: Blob) {
    var array = [...matchesResult];
    array[index].attachmentFile = imageBlob;
    setMatchesResult(array);
  }
  function clickShowConfirmationPage() {
    if (sessionPlayerInfo.id == undefined) {
      history.push(`/account`);
    } else {
      setShowConfirmationPage(true);
    }
  }

  function clickSubmitMatchResult() {
    if (sessionPlayerInfo.id == undefined) {
      history.push(`/account`);
    } else if (tournamentMatchDetail != undefined) {
      setOpenLoader(true);
      interface FormMatchResult {
        matchId: string;
        result: Match[];
      }
      let formData: FormMatchResult = {
        matchId: tournamentMatchDetail?.id,
        result: matchesResult,
      };

      userManager
        .getUser()
        .then(user => {
          if (user && user.access_token && user.expired === false) {
            const editAccountProfileThunk = postTournamentResult(formData);
            dispatch(editAccountProfileThunk)
              .unwrap()
              .then(res => {
                setOpenLoader(false);
                if (res && res.isAxiosError && res.data?.status >= 400) {
                  if (res.statusCode == 401) {
                    setOpenNoticeReLogin(true);
                  } else if (res.data?.status == 1406) {
                    setNoticeContent('Ups, waktu kirim hasil match habis');
                    setOpenNoticeSubmit(true);
                  } else {
                    setNoticeContent('Terjadi Kesalahan');
                    setOpenNoticeSubmit(true);
                  }
                } else {
                  history.push(`/tournament/${game_slug}/${tournament_slug}/match/${match_id}`);
                }
              })
              .catch(() => {
                setOpenLoader(false);
                setNoticeContent('Terjadi Kesalahan');
                setOpenNoticeSubmit(true);
              });
          } else {
            setOpenLoader(false);
            setOpenNoticeReLogin(true);
          }
        })
        .catch(() => {
          setOpenLoader(false);
          setNoticeContent('Terjadi Kesalahan');
          setOpenNoticeSubmit(true);
        });
    }
  }

  function WinLoseChecker() {
    var win = 0;
    var lose = 0;
    var countImageUpload = 0;
    var matchReq = Math.ceil(matchesCount / 2);
    matchesResult.forEach(function (item) {
      if (item.isWin !== '') {
        if (item.isWin == 1) {
          win++;
        } else {
          lose++;
        }
      }
      if (item.attachmentFile != undefined) {
        countImageUpload++;
      }
    });
    if ((win == matchReq || lose == matchReq) && countImageUpload == matchesResult.length) {
      setMatchResultScore({
        homeScore: win.toString(),
        awayScore: lose.toString(),
        homeScoreResult: win > lose ? 'win' : 'lose',
        awayScoreResult: win > lose ? 'lose' : 'win',
      });

      setSentButtonActive(true);
    } else {
      setSentButtonActive(false);
    }
  }

  useEffect(() => {
    WinLoseChecker();
  }, [matchesResult]);

  return (
    <>
      <AppLayout
        noMargin={false}
        appBar={{
          bg: { background: '#1A1817' },
          navleft: {
            bg: 'ic-arrow-back-light',
            width: '24px',
            height: '24px',
            onClick: handleClickBack,
          },
          navright: {
            variant: 'empty',
          },
          isDisable: false,
          title: showConfirmationPage ? 'Konfirmasi Hasil Match' : 'Update Hasil Match',
        }}
      >
        {!showConfirmationPage && (
          <>
            {matchesResult.map((matchData, index: number) => {
              return (
                <>
                  <Box
                    mt="16px"
                    sx={{
                      bgcolor: '#1A1817',
                      border: '1px solid #242628',
                      borderRadius: '8px',
                      padding: '12px 12px 24px 12px',
                      justifyContent: 'flex-start',
                      width: '100%',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      direction="row"
                      mb="16px"
                    >
                      <Text variant="label" sx={{ width: '100%', textAlign: 'left' }}>
                        Game{matchData.round}
                      </Text>
                      {matchData.showDel && (
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          width="fit-content"
                          direction="row"
                          onClick={deletematch}
                        >
                          <img src={IconSource('delete-square', 'png')} alt="" />
                          <Text variant="button" ml="4px">
                            Hapus
                          </Text>
                        </Stack>
                      )}
                    </Stack>
                    <UploadImage
                      getImageBlob={v => onChangeImageUpload(index, v)}
                      onImageLoad={function noRefCheck() {}}
                      title={
                        <Text>
                          Upload screenshot hasil pertandingan. Lihat contohnya{' '}
                          <Text
                            component="span"
                            sx={{ color: '#E8532E', textDecoration: 'underline' }}
                            onClick={() => setOpenContohScreenshot(true)}
                          >
                            di sini
                          </Text>
                          .
                        </Text>
                      }
                    />
                    <Text
                      variant="subtitle"
                      sx={{
                        width: '100%',
                        fontWeight: '600',
                        textAlign: 'left',
                        wordWrap: 'break-word',
                        overflow: 'auto',
                        mt: '24px',
                      }}
                    >
                      {'Status tim kamu di game ini'}
                    </Text>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      direction="row"
                      spacing={1}
                      mt="8px"
                    >
                      <MatchRadioButton
                        iswin={matchData.isWin === true ? '1' : ''}
                        checked={matchData.isWin === true}
                        onChange={() => onChangeStatus(index, true)}
                      >
                        Menang
                      </MatchRadioButton>
                      <MatchRadioButton
                        iswin={matchData.isWin === false ? '0' : ''}
                        checked={matchData.isWin === false}
                        onChange={() => onChangeStatus(index, false)}
                      >
                        Kalah
                      </MatchRadioButton>
                    </Stack>
                  </Box>
                </>
              );
            })}
            {matchesResult.length < matchesCount && (
              <Stack
                justifyContent="center"
                alignItems="center"
                width="100%"
                direction="row"
                mt="24px"
                onClick={addMatch}
              >
                <img src={IconSource('ic-add-orange')} alt="" />
                <Text variant="button" ml="4px" gradient>
                  Game {matchesResult.length + 1}
                </Text>
              </Stack>
            )}
            <PrimaryButton
              disabled={!sentButtonActive}
              onClick={() => clickShowConfirmationPage()}
              wide
              sx={{ mt: '24px', mb: '24px' }}
            >
              Kirim Hasil Match
            </PrimaryButton>
          </>
        )}
        {/* CONFIRMATION PAGE */}
        {showConfirmationPage && (
          <>
            <CardImportantInfo
              title="Penting!"
              content="Pastikan hasil match yang kamu input sudah benar"
            />
            <Match
              status={
                <MatchStatus
                  variant="white"
                  label={moment(tournamentMatchDetail?.startAt)
                    .tz('Asia/Jakarta')
                    .format('DD MMM YYYY - HH:mm z')}
                />
              }
              home={{
                logo: tournamentMatchDetail?.firstTeamAvatar,
                name: tournamentMatchDetail?.firstTeam,
                location: tournamentMatchDetail?.firstTeamSchool,
                indicator:
                  account?.id == tournamentMatchDetail?.firstCaptainId
                    ? homeMatchResult
                    : awayMatchResult,
                score:
                  account?.id == tournamentMatchDetail?.firstCaptainId
                    ? matchResultScore?.homeScore
                    : matchResultScore?.awayScore,
                result:
                  account?.id == tournamentMatchDetail?.firstCaptainId
                    ? matchResultScore?.homeScoreResult
                    : matchResultScore?.awayScoreResult,
              }}
              away={{
                logo: tournamentMatchDetail?.secondTeamAvatar,
                name: tournamentMatchDetail?.secondTeam,
                location: tournamentMatchDetail?.secondTeamSchool,
                indicator:
                  account?.id == tournamentMatchDetail?.firstCaptainId
                    ? awayMatchResult
                    : homeMatchResult,
                score:
                  account?.id == tournamentMatchDetail?.firstCaptainId
                    ? matchResultScore?.awayScore
                    : matchResultScore?.homeScore,
                result:
                  account?.id == tournamentMatchDetail?.firstCaptainId
                    ? matchResultScore?.awayScoreResult
                    : matchResultScore?.homeScoreResult,
              }}
              result={{
                status: 9,
                type: 1,
              }}
              roundCategory={tournamentMatchDetail?.roundName}
            />
            <Box
              sx={theme => ({
                background: theme.palette.neutral700,
                borderRadius: theme.borderRadius.md,
                padding: '12px',
                width: '100%',
                boxSizing: 'border-box',
                position: 'relative',
                mt: '16px',
                mb: '23px',
              })}
            >
              <Text
                variant="label"
                sx={{
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  overflow: 'auto',
                }}
              >
                {'Konfirmasi kebenaran informasi'}
              </Text>
              <Text
                variant="subtitle"
                sx={{
                  fontWeight: '700',
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  overflow: 'auto',
                  mt: '24px',
                }}
              >
                {'1. Integritas dan kejujuran'}
              </Text>
              <Text
                variant="subtitle"
                sx={{
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  overflow: 'auto',
                  mt: '4px',
                }}
              >
                {
                  'MABAR dibangun berlandaskan atas integritas dan kejujuran. Kecurangan dan pelanggaran adalah masalah serius yang dapat ditindaklanjuti.'
                }
              </Text>
              <Text
                variant="subtitle"
                sx={{
                  fontWeight: '700',
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  overflow: 'auto',
                  mt: '16px',
                }}
              >
                {'2. Nickname Game'}
              </Text>
              <Text
                variant="subtitle"
                sx={{
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  overflow: 'auto',
                  mt: '4px',
                }}
              >
                {'Hanya nickname game yang terdaftar di MABAR yang akan mendapatkan poin.'}
              </Text>
              <Text
                variant="subtitle"
                sx={{
                  fontWeight: '700',
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  overflow: 'auto',
                  mt: '16px',
                }}
              >
                {'3. Skor Match'}
              </Text>
              <Text
                variant="subtitle"
                sx={{
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  overflow: 'auto',
                  mt: '4px',
                }}
              >
                {'Skor yang di input harus sesuai dengan hasil match yang sebenarnya'}
              </Text>
              <Divider sx={{ borderColor: '#FFFFFF', opacity: '0.1', mt: '24px' }} />
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '24px',
                }}
              >
                <IconButton
                  sx={{ p: '0px', border: 'none' }}
                  onClick={() => setSentConfirmButtonActive(!sentConfirmButtonActive)}
                  icon={
                    sentConfirmButtonActive
                      ? IconSource('checklist.orange.active')
                      : IconSource('checklist.inactive')
                  }
                />
                <Text
                  variant="subtitle"
                  sx={{
                    textAlign: 'left',
                    wordWrap: 'break-word',
                    overflow: 'auto',
                    color: 'neutral200',
                    ml: '12px',
                  }}
                >
                  {'Saya mengerti dan akan memberikan informasi dengan benar'}
                </Text>
              </Stack>
              <PrimaryButton
                disabled={!sentConfirmButtonActive}
                wide
                sx={{ mt: '24px' }}
                onClick={() => clickSubmitMatchResult()}
              >
                Kirim
              </PrimaryButton>
            </Box>
          </>
        )}

        {/* modal form input image */}
        <Modal show={openContohScreenshot}>
          <InfoModal
            cta={
              <>
                <SecondaryButton onClick={() => setOpenContohScreenshot(false)} wide>
                  Kembali
                </SecondaryButton>
              </>
            }
          >
            <Text variant="h3" textAlign="center" mb="24px">
              Contoh screenshot hasil pertandingan
            </Text>
            <Box
              sx={{
                border: '1px solid #4E5156',
                borderRadius: '4px',
                mb: '8px',
              }}
            >
              <img src={ImageSource('game-result', 'png')} alt="" />
            </Box>
          </InfoModal>
        </Modal>
        {/* modal submit melebihi batas waktu */}
        <Modal show={openNoticeSubmit}>
          <InfoModal
            cta={
              <>
                <SecondaryButton
                  onClick={() =>
                    history.push(`/tournament/${game_slug}/${tournament_slug}/match/${match_id}`)
                  }
                  wide
                >
                  Kembali ke match detail
                </SecondaryButton>
              </>
            }
          >
            <Text variant="h2" textAlign="center" mb="16px">
              {noticeContent}
            </Text>
          </InfoModal>
        </Modal>
        {/* modal need re-login */}
        <Modal show={openNoticeReLogin}>
          <InfoModal
            cta={
              <>
                <PrimaryButton
                  onClick={() => handleClickLogin()}
                  sx={{
                    ml: 'auto',
                    mr: '0',
                  }}
                >
                  Login
                </PrimaryButton>
              </>
            }
          >
            <Text variant="h2" mb="8px" sx={{ width: '100%', textAlign: 'left' }}>
              Silakan login
            </Text>
            <Text variant="body" textAlign="left">
              Sesi sebelumnya berakhir karena tidak ada kegiatan di dalam platform. Silakan login
              kembali untuk melanjutkan.
            </Text>
          </InfoModal>
        </Modal>

        <MUIModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openLoader}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'transparent',
          }}
        >
          <Fade in={openLoader}>
            <img src={IconSource('ic-loading')} style={{ position: 'relative', outline: 0 }} />
          </Fade>
        </MUIModal>
      </AppLayout>
    </>
  );
};

export default TournamentMatchDetailUpload;
