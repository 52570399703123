import styled from 'styled-components';

interface ButtonProps {
  background?: string | undefined;
  backgroundHover?: string | undefined;
  borderRadius?: string | undefined;
  margin?: string | undefined;
  padding?: string | undefined;
  shadow?: string | undefined;
  textColor?: string | undefined;
  hoverTextColor?: string | undefined;
}

export const Button = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  box-shadow: ${({ shadow }) => shadow};
  background: ${({ background }) => background};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  color: ${({ textColor }) => textColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  opacity: 1;
  &:active {
    opacity: 0.7;
  }
  &:hover {
    background: ${({ backgroundHover }) => backgroundHover};
    color: ${({ hoverTextColor }) => hoverTextColor};
  }
`;
