// import GradientButton from 'components/Buttons/GradientButton';
import HeaderMatchDetails from 'components/cards/HeaderMatchDetails';
import { CardScrimWinLose} from 'components/CardScrimWinLose/index';
import { TabButtonType } from 'components/layouts/TabButton';
import TabMenu from 'components/layouts/TabMenu';
import { Box, Stack } from 'components/styled/layout.styled';
import { ImageSource } from 'modules/assetpath';
import { Status } from 'components/Status/index';
import React, { useState } from 'react';
import { matchResut, teamLeft, teamRight, winResult } from './WoData';
import AppLayout from 'components/layouts/AppLayout';
import { LogDev } from 'modules/helpers';

const MatchWO = () => {
  const [dataRound, setDataRound] = useState(winResult);
    LogDev('INFO',dataRound)
  const DataTab: TabButtonType[] = [
    {
      id: 1,
      title: 'Game',
      isSelected: true,
    },
  ];
  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
        },
        navright: {
          bg: 'white',
          variant: 'menu-options',
        },
        isDisable: false,
        title: `Detail Scrim #232323`,
      }}
      noMargin={true}
      backgroundImage={ImageSource('app.background')}
    >
      <Status label="Tim lawan (Astralis G2) tidak hadir" onClick={() => {}} />
      <br />
      <br />
      <HeaderMatchDetails
        headerBackground={ImageSource('card.background')}
        teamLeft={{
          ...teamLeft,
          onWaButtonClick: 'none',
          teamPhotos: [
            '../../../media/images/dummy/player1.svg',
            '../../../media/images/dummy/player2.svg',
            '../../../media/images/dummy/player3.svg',
            '../../../media/images/dummy/player4.svg',
            '../../../media/images/dummy/player5.svg',
          ],
        }}
        teamRight={{
          ...teamRight,
          onWaButtonClick: 'none',
          teamPhotos: [
            '../../../media/images/dummy/player1.svg',
            '../../../media/images/dummy/player2.svg',
            '../../../media/images/dummy/player3.svg',
            '../../../media/images/dummy/player4.svg',
            '../../../media/images/dummy/player5.svg',
          ],
        }}
        matchResult={matchResut}
        styled={{
          marginTop: '0px',
          padding: '0px',
          alignSelf: 'center',
          width: '100%',
        }}
      />

      <TabMenu
        data={DataTab}
        variant='left'
        onSelected={() => {
          setDataRound(winResult);
        }}
      />
      <Box width="100%">
        <Stack direction="column" margin="0px 16px 0px 16px">
        <CardScrimWinLose
          bg="linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))"
          border="0"
          header="Tim Kamu Menang di Game ini   🏆"
          // minHeight='176px'
          // onClick={null}
          picture1='./../../media/teamProfile/LogoTeam.svg'
          picture2='./../../media/teamProfile/LogoTeam.svg'
          score={<p style={{fontWeight: 'bold'}}>20{' '}<span style={{color: '#DDD8D8', fontSize: '16px', fontWeight: 'normal'}}>Kills</span></p>}
          score1={<p style={{fontWeight: 'bold'}}>9{' '}<span style={{color: '#DDD8D8', fontSize: '16px', fontWeight: 'normal'}}>Kills</span></p>}
          src="../../../media/images/dummy/game-result.png"
          team1="Fire Dragon"
          team2="Astralis G2"
          text="14 Okt 2021 - 14:30 WIB"
        />
        </Stack>
      </Box>
    </AppLayout>
  );
};

export default MatchWO;
