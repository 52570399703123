import React, { FC } from 'react';
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon } from './styles';

export interface CheckboxProps {
  /** Description of onClick function which returns void * */
  onClick: () => void;
  /** Text you want to display in your button, or an icon * */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean | undefined;
}
export const Checkbox: FC<CheckboxProps> = ({ checked, onClick, onChange }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} />
    <StyledCheckbox onClick={onClick} onChange={onChange} checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
