import { Stack, Avatar } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React from 'react';
import { PHONE_NUMBER_PATTERN } from 'validation/account/EditProfileValidation';

export interface CardSocmedTeamProps {
  linkDiscord?: string;
  linkWhatsapp?: string;
}

export const CardSocmedTeam = ({ linkDiscord, linkWhatsapp }: CardSocmedTeamProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: '#161618',
        width: '100%',
        borderBottom: `1px solid rgba(39, 40, 43, 0.6)`,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="4px"
        sx={theme => ({
          width: '50%',
          m: '12px 0',
          p: '4px 0',
          borderRight: `1px solid ${theme.palette.neutral530}`,
        })}
        onClick={() => {
          linkDiscord && window.open(linkDiscord, '_blank');
        }}
      >
        <Avatar
          src={linkDiscord ? IconSource('discord_purple') : IconSource('discord_gray')}
          sx={{ width: '19px', height: '16px', borderRadius: 0 }}
          variant="square"
        />
        <Text
          variant="button"
          sx={{
            color: !linkDiscord ? 'neutral500' : 'neutral200',
            cursor: !linkDiscord ? 'default' : 'pointer',
          }}
        >
          Team Discord
        </Text>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="4px"
        sx={{ width: '50%', m: '16px 0' }}
        onClick={() => {
          linkWhatsapp &&
            window.open(
              linkWhatsapp.match(PHONE_NUMBER_PATTERN)
                ? `https://wa.me/${
                    linkWhatsapp?.split('+')[1] ? linkWhatsapp?.split('+')[1] : linkWhatsapp
                  }`
                : linkWhatsapp,
              '_blank',
            );
        }}
      >
        <Avatar
          src={linkWhatsapp ? IconSource('wa_active') : IconSource('wa_inactive')}
          sx={{ width: '18px', height: '18px', borderRadius: 0 }}
          variant="square"
        />
        <Text
          variant="button"
          sx={{
            color: !linkWhatsapp ? 'neutral500' : 'neutral200',
            cursor: !linkWhatsapp ? 'default' : 'pointer',
          }}
        >
          WhatsApp Captain
        </Text>
      </Stack>
    </Stack>
  );
};
