import { Box, Stack, Divider } from '@mui/material';
import { TextButton } from 'design/Buttons/TextButton';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React from 'react';

export interface CardCopyProps {
  title: string;
  content: string;
  handleCopy: () => void;
}

export const CardCopy = ({ title, content, handleCopy }: CardCopyProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing="4px"
      sx={theme => ({
        width: '100%',
        bgcolor: 'neutral600',
        p: '4px 0px 4px 8px',
        borderRadius: theme.borderRadius.sm,
      })}
    >
      <Stack>
        <Text variant="labelSmall" sx={{ color: 'neutral400', textTransform: 'uppercase' }}>
          {title}
        </Text>
        <Text variant="body">{content}</Text>
      </Stack>
      <TextButton
        onClick={handleCopy}
        icon={IconSource('ic-copy')}
        sx={{ p: '8px', svg: { m: 0 } }}
      />
    </Stack>
  );
};

export interface CardProfileGameProps {
  gameLogo: string;
  gameName: string;
  dataCopy: CardCopyProps[];
}

export const CardProfileGame = ({ gameLogo, gameName, dataCopy }: CardProfileGameProps) => {
  return (
    <Box>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <img src={gameLogo} alt={gameName} width={32} />
        <Text variant="button" sx={{ color: 'neutral300' }}>
          {gameName}
        </Text>
      </Stack>

      <Divider
        sx={theme => ({
          m: '16px 0',
          borderColor: 'transparent',
          backgroundImage: `repeating-linear-gradient(to right, ${theme.palette.neutral530} 0%, ${theme.palette.neutral530} 50%, ${theme.palette.neutral700} 50%, ${theme.palette.neutral700} 100%), repeating-linear-gradient(to right, ${theme.palette.neutral530} 0%, ${theme.palette.neutral530} 50%, ${theme.palette.neutral700} 50%, ${theme.palette.neutral700} 100%), repeating-linear-gradient(to bottom, ${theme.palette.neutral530} 0%, ${theme.palette.neutral530} 50%, ${theme.palette.neutral700} 50%, ${theme.palette.neutral700} 100%), repeating-linear-gradient(to bottom, ${theme.palette.neutral530} 0%, ${theme.palette.neutral530} 50%, ${theme.palette.neutral700} 50%, ${theme.palette.neutral700} 100%)`,
          backgroundSize: '10px 2px',
        })}
      />

      <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>
        {dataCopy.length > 0 &&
          dataCopy.map(dataCopy => (
            <CardCopy
              title={dataCopy.title}
              content={dataCopy.content}
              handleCopy={dataCopy.handleCopy}
            />
          ))}
      </Stack>
    </Box>
  );
};
