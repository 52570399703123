import { Box, Stack } from '@mui/material';
import GradientButton from 'components/Buttons/GradientButton';
import { InputPassword } from 'components/Inputs/InputPassword';
import SupportLayout, { SupportLayoutProps } from 'components/Layout/SupportLayout';
import MessageModal from 'components/UniPopup/Modals/MessageModal';
import Modal from 'components/layouts/Modal';
import { NormalText } from 'components/styled/text.styled';
import { ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountValidationProblemDetails } from 'redux/account/AccountResponse';
import { changePassword, getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { useAppThunkDispatch } from 'redux/store';

const ChangePassword = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const submitRef = React.createRef<HTMLInputElement>();
  const { register, handleSubmit, control } = useForm();
  const dispatch = useAppThunkDispatch();
  const playerInfo = getPlayerProfileFromSession();
  const [getValidationErrors, setValidationErrors] = useState<AccountValidationProblemDetails>();
  const watchFields = useWatch({ control: control });
  let [newPassword, setNewPassword] = useState('');
  let [confirmPassword, setConfirmPassword] = useState('');
  const isFormComplete =
    watchFields['userId'] && watchFields['currentPassword'] && watchFields['newPassword'];

  async function saveChangePassword(data: any) {
    const thunk = changePassword({
      userId: playerInfo?.id ?? '',
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    });
    dispatch(thunk)
      .unwrap()
      .then(res => {
        if (res && res.isAxiosError) {
          setValidationErrors(res.data as AccountValidationProblemDetails);
          setShowModal(true);
        } else {
          setValidationErrors({});
          setShowModal(true);
        }
      });
  }

  const changePasswordProps: SupportLayoutProps = {
    headers: {
      headerBackground: ImageSource('card.background', 'jpg'),
      title: t('change_password_header_title'),
    },
  };

  return (
    <SupportLayout {...changePasswordProps}>
      <Box padding="16px">
        <Stack direction="column">
          <form onSubmit={handleSubmit(saveChangePassword)} noValidate>
            <InputPassword
              label={t('change_password_old_password')}
              onChange={() => {}}
              placeholder="**************"
              validationType={getValidationErrors?.errors?.currentPassword ? 'ERROR' : 'ERROR'}
              validationText={
                getValidationErrors?.errors?.currentPassword
                  ? getValidationErrors?.errors?.currentPassword[0].message
                  : undefined
              }
              isRequired
              registerForm={register('currentPassword')}
            />
            <InputPassword
              id="new_password"
              label={t('change_password_new_password')}
              margin="12px 0px"
              onChange={() => {}}
              placeholder="**************"
              validationType={getValidationErrors?.errors?.newPassword ? 'ERROR' : undefined}
              validationText={
                getValidationErrors?.errors?.newPassword
                  ? getValidationErrors?.errors?.newPassword[0].message
                  : undefined
              }
              isRequired
              registerForm={register('newPassword')}
            />
            <InputPassword
              label={t('change_password_new_password_confirmation')}
              margin="12px 0px"
              onChange={e => {
                setNewPassword((document.getElementById('new_password') as HTMLInputElement).value);
                setConfirmPassword(e.currentTarget.value);
              }}
              placeholder="**************"
              validationType={newPassword !== confirmPassword ? 'ERROR' : undefined}
              validationText="The passwords do not match"
            />
            <Box padding="8px" width="100%">
              <Stack direction="column" alignItems="center">
                <GradientButton
                  variant="primary"
                  backgroundSize="100%"
                  padding="12px 25px"
                  onClick={() => {
                    isFormComplete && submitRef.current?.click();
                  }}
                >
                  {t('change_password_title')}
                </GradientButton>
              </Stack>
            </Box>
            <div style={{ marginBottom: '70px' }}></div>
            <input ref={submitRef} type="submit" hidden />
          </form>
        </Stack>
      </Box>
      <Modal show={showModal}>
        <MessageModal
          btnConfirmText={
            <NormalText
              color="white"
              fontWeight="700"
              fontSize="14px"
              textAlign="center"
              padding="0px 80px 0px 80px"
            >
              OK
            </NormalText>
          }
          onConfirmClick={() => {
            setShowModal(!showModal);
            history.push(getValidationErrors?.errors ? '/change-password' : '/account');
          }}
          title={t(
            getValidationErrors?.errors
              ? 'change_password_popup_message_failed'
              : 'change_password_popup_message',
          )}
        ></MessageModal>
      </Modal>
    </SupportLayout>
  );
};

export default ChangePassword;
