// import GradientButton from 'components/Buttons/GradientButton';
import { NormalText } from 'components/styled/text.styled';
import HeaderAccount from 'components/cards/Account/HeaderAccount';
import { CardMatchResult } from 'components/CardMatchResult/index';
import { CardInfoGame } from 'components/CardInfoGame/index';
import { IconSource, ImageSource } from 'modules/assetpath';
import {
  FrameIdScrim,
  FrameScrimHistoryPertandingan,
  FrameWaktuPertandingan,
} from 'styles/TeamProfile/PagePertandingan/Frame';
import {
  TextIdScrim,
  TextTanggalScrim,
  TextTim1,
  TextTim2,
  TextJamPertandingan,
  TextWaktuPertandingan,
} from 'styles/TeamProfile/PagePertandingan/Text';

import React from 'react';
import AppLayout from 'components/layouts/AppLayout';

const PublicProfileFilled = () => {
  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'mabar-typeface-white',
          height: '32px',
          width: '107px',
        },
        navright: {
          bg: 'white',
          variant: 'menu-options',
        },
        isDisable: false,
        //   title: `Public Profile`
      }}
      backgroundImage={ImageSource('app.background')}
    >
    <HeaderAccount
      avatarUrl={ImageSource('profile','png')}
      cityName="Bandung"
      generation="2019"
      // onClick={() => {}}
      personName="John Due Simatupang"
      schoolName="SMA 2" 
     />
    <NormalText fontSize="14px" fontWeight="bold" lineHeight="140%" color="white">
      Informasi Game
    </NormalText>
    <br />
    <div>
    <CardInfoGame
        copyText="Salin"
        gameId={<p>Game ID.<b>819281986</b></p>}
        onClick={() => {}}
        src={IconSource('ml-logo')}
        label= "Ucup Gaming"
    />
    </div>
    <br />
    <br />
    <NormalText fontSize="14px" fontWeight="bold" lineHeight="140%" color="white">
      Riwayat Pertandingan
    </NormalText>
    <br />
    <FrameScrimHistoryPertandingan>
        <FrameIdScrim>
          <TextIdScrim>Scrim #12910</TextIdScrim>
        </FrameIdScrim>
        <TextTanggalScrim>13 Jan 2021, 14:30 WIB</TextTanggalScrim>
        <img
          src={`${process.env.PUBLIC_URL}/media/teamProfile/LogoTeam.svg`}
          style={{
            position: 'absolute',
            left: '0%',
            right: '93.57%',
            top: '0%',
            bottom: '0%',
            border: '2px solid #F1EBEB',
            boxSizing: 'border-box',
            borderRadius: '12px',
            margin: '60px 25px',
          }}
          alt=""
        />{' '}
        <TextTim1>Fire Dragon</TextTim1>
        <img
          src={`${process.env.PUBLIC_URL}/media/teamProfile/LogoTeam.svg`}
          style={{
            position: 'absolute',
            left: '0%',
            right: '93.57%',
            top: '0%',
            bottom: '0%',
            border: '2px solid #F1EBEB',
            boxSizing: 'border-box',
            borderRadius: '12px',
            margin: '90px 25px',
          }}
          alt=""
        />{' '}
        <TextTim2>Astralis G2</TextTim2>
        <FrameWaktuPertandingan>
          <TextJamPertandingan>14.30 WIB</TextJamPertandingan>
          <TextWaktuPertandingan>2 jam 35 menit</TextWaktuPertandingan>
        </FrameWaktuPertandingan>
      </FrameScrimHistoryPertandingan>
      <br />
      <CardMatchResult
        bg="rgba(55, 57, 66, 0.6)"
        header="Hasil Pertandingan Tidak Sesuai"
        // onClick={() => {}}
        score="-"
        score1="-"
        team1="Fire Dragon"
        team2="Astralis G2"
        text="14 Okt 2021 - 14:30 WIB"
      />
      <br />
      <CardMatchResult
        bg="linear-gradient(180deg, rgba(213, 31, 64, 0.3) 0%, rgba(213, 31, 64, 0.06) 100%)"
        border="0"
        disable
        header="Tim Kamu Kalah di Scrim #12910"
        // onClick={() => {}}
        score="1"
        score1="2"
        team1="Fire Dragon"
        team2="Astralis G2"
        text="14 Okt 2021 - Best of 3"
      />
      <br />
      <CardMatchResult
        bg="linear-gradient(180deg, rgba(213, 31, 64, 0.3) 0%, rgba(213, 31, 64, 0.06) 100%)"
        border="0"
        disable
        header="Tim Kamu Kalah di Scrim #12910"
        // onClick={() => {}}
        score="1"
        score1="2"
        team1="Fire Dragon"
        team2="Astralis G2"
        text="14 Okt 2021 - Best of 3"
      />
    </AppLayout>
  );
};

export default PublicProfileFilled;
