import { ButtonProps } from './index';
import { styled } from '@mui/system';
import { IconSource } from 'modules/assetpath';

const handlePadding = ({ variant }: ButtonProps) => {
  switch (variant) {
    case 'small':
      return '4px 16px';
    case 'big':
      return '12px 16px';
    default:
      return '8px 16px';
  }
};

export const CustomButton: any = styled('button', {
  name: 'primaryButton',
})<ButtonProps>`
  ${({ theme }) => theme.typography.button}
  color: ${({ theme }) => theme.palette.neutral700};
  line-height: 24px;
  padding: ${({ variant }) => handlePadding({ variant })};
  border: none;
  cursor: pointer;
  text-transform: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  background: ${({ theme }) => theme.palette.primaryOrange700};
  border-radius: ${({ variant, theme }) =>
    variant === 'small' ? theme.borderRadius.sm : theme.borderRadius.md};
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.32);
  width: ${({ wide }) => (wide ? '100%' : 'fit-content')};
  transition: 0.3s ease-in-out;
  transition-property: background, color;
  background-size: 100%;
  background-position: 0% 0%;
  position: relative;
  ${({ theme, variant, boxShadow }) =>
    boxShadow &&
    `margin-bottom: 4px !important;
    transform-style: preserve-3d;
    transition: transform 0.03s cubic-bezier(0, 0, 0.58, 1), background 0.03s cubic-bezier(0, 0, 0.58, 1);

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        inset: 0;
        margin: 0 auto;
        background: ${theme.palette.primaryOrange700};
        border-radius: ${variant === 'small' ? '6px;' : '10px'};
        box-shadow: 0 0 0 2px transparent, 0 0.625em 0 0 transparent;
        transform: translate3d(0, 4px, -10px);
        transition: transform 0.03s cubic-bezier(0, 0, 0.58, 1),
          box-shadow 0.03s cubic-bezier(0, 0, 0.58, 1);
        z-index: 1;
      }
    `}

  &:active {
    color: #dbd2cf;
    background: ${({ theme }) => theme.palette.primaryOrange700};
    background-blend-mode: overlay, normal, normal;

    ${({ boxShadow }) =>
      boxShadow &&
      `box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.32);
    transform: translate(0, 4px);
    &::before {
      box-shadow: 0 0 0 2px transparent, 0 0 transparent;
      transform: translate3d(0, 0, -10px);
    }`};
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${IconSource('grunge', 'png')});
    background-repeat: repeat;
    z-index: 2;
    opacity: 0.4;
  }

  svg {
    margin-right: 6px;
  }

  &:disabled {
    pointer-events: none;
    background: ${({ theme }) => theme.palette.neutral500};
    color: ${({ theme }) => theme.palette.neutral400};
    box-shadow: none;
    &:before {
      background: ${({ theme, boxShadow }) => boxShadow && theme.palette.grayGradient};
    }
    &::after {
      display: none;
    }
  }
`;
