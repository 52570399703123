import { Box, Stack, Avatar, useTheme, Divider } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardAward } from 'design/Cards/CardAward';
import { CardSection } from 'design/Cards/CardSection';
import { CardTournamentParticipation } from 'design/Cards/CardTournamentParticipation';
import { CompetitionStatus } from 'design/Tag/CompetitionStatus';
import { Text } from 'design/Text';
import { IconSource, ImageSource } from 'modules/assetpath';
import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { AwardsType } from 'redux/award/AwardProps';
import { useAppSelector } from 'redux/hooks';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface AwardsProps {
  handleButtonRoster?: any;
  urlParams?: {
    gameSlug?: string;
    schoolSlug?: string;
    teamSlug?: string;
  };
}

const Awards = ({ handleButtonRoster, urlParams }: AwardsProps) => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const { awardsHighlighted, awards } = useAppSelector(({ award }) => ({
    awardsHighlighted: award?.tournamentParticipantAwardsTeamHighlighted?.awards,
    awards: award?.tournamentParticipantAwardsTeam?.awards,
  }));

  const handleStepChange = (step: React.SetStateAction<number>) => {
    setActiveStep(step);
  };

  const restructureDataDrawer = (data: AwardsType) => {
    let returnData = {
      title: data.title,
      eventName: data.eventName,
      teamName: data.teamName,
      schoolName: data.schoolName,
      teamAvatarUrl: data.teamAvatarUrl,
      teamProvinceName: data.teamProvinceName,
      rosters: data.teamRoster,
    };
    return returnData;
  };

  const isAwardsHighlighted = (awardsHighlighted ?? []).length > 0;
  const isAwards = (awards ?? []).length > 0;

  return (
    <Box sx={{ mt: isAwardsHighlighted || isAwards ? '16px' : 0 }}>
      {isAwardsHighlighted && (
        <CardSection
          title={
            <Text
              variant="label"
              component="h3"
              sx={{
                position: 'relative',
                px: '16px',
                textTransform: 'capitalize',
                textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                letterSpacing: '0',
                zIndex: 1,
              }}
            >
              Awards
            </Text>
          }
          sx={{
            position: 'relative',
            bgcolor: 'transparent',
            p: '24px 0 16px 0',
          }}
        >
          <Box
            sx={{
              '&:before': {
                content: "''",
                position: 'absolute',
                inset: 0,
                width: '100%',
                height: '670px',
                maxHeight: 'calc(100vh - 210px)',
                background: 'radial-gradient(163.33% 97.31% at 0% 0%, #C79955 0%, #101216 100%)',
                zIndex: 0,
              },
              '&:after': {
                content: "''",
                position: 'absolute',
                inset: 0,
                width: '100%',
                height: '244px',
                background: `url(${IconSource('ic-awards-ornament-team')}) no-repeat`,
                zIndex: 0,
              },
            }}
          />

          <Box
            sx={{
              position: 'relative',
              p: '0',
            }}
          >
            <Avatar
              variant="square"
              src={ImageSource('bg_awards_detail', 'png')}
              sx={{
                height: '283px',
                width: '100%',
                zIndex: '-1',
                position: 'absolute',
                top: '0',
                left: '0',
                '.MuiAvatar-img': {
                  objectFit: 'fill',
                },
              }}
            />

            {(awardsHighlighted ?? []).length > 0 ? (
              <>
                <AutoPlaySwipeableViews
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {awardsHighlighted?.map((carousel: any) => (
                    <CardAward
                      image={carousel.logoUrl}
                      label={<CompetitionStatus variant="pink" label={carousel.title} />}
                      title={carousel.eventName}
                      isChampion={true}
                      imageSx={{
                        width: '140px',
                        height: '140px',
                        top: '10px',
                      }}
                      sx={{ height: 'auto', m: '0 16px' }}
                    />
                  ))}
                </AutoPlaySwipeableViews>

                <MobileStepper
                  steps={(awardsHighlighted ?? []).length}
                  position="static"
                  activeStep={activeStep}
                  nextButton={''}
                  backButton={''}
                  sx={{
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                    paddingTop: '12px',
                    ' .MuiMobileStepper-dots': {
                      alignItems: 'center',
                      ' .MuiMobileStepper-dot': {
                        bgcolor: 'rgba(212, 214, 216, 0.5)',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        mx: '3px',
                      },
                      ' .MuiMobileStepper-dotActive': {
                        bgcolor: 'white',
                      },
                    },
                  }}
                />
              </>
            ) : null}
          </Box>
        </CardSection>
      )}

      {isAwards && (
        <>
          <Stack direction="column" spacing={2} sx={{ mx: '16px', mb: '24px' }}>
            {awards?.map((award: AwardsType, idx: number) =>
              idx < 3 ? (
                <CardTournamentParticipation
                  title={award?.title}
                  description={`${award?.eventName} • ${award?.awardsDate}`}
                  cta={
                    <Stack direction="row" alignItems="center">
                      <Avatar
                        src={IconSource('ic-tim-outline')}
                        sx={{
                          width: '14px',
                          height: '14px',
                          mr: '4.67px',
                          borderRadius: '0',
                        }}
                      />
                      <Text
                        variant="button"
                        onClick={() => handleButtonRoster(restructureDataDrawer(award))}
                        style={{ cursor: 'pointer' }}
                      >
                        Lihat Roster
                      </Text>
                    </Stack>
                  }
                  sx={{ height: 'auto' }}
                />
              ) : null,
            )}

            {(awards ?? []).length > 3 ? (
              <Link
                to={`/team/${urlParams?.gameSlug}/${urlParams?.schoolSlug}/${urlParams?.teamSlug}/awards`}
              >
                <SecondaryButton wide variant="small">
                  Lihat Semua
                </SecondaryButton>
              </Link>
            ) : null}
          </Stack>
        </>
      )}

      {(isAwardsHighlighted || isAwards) && (
        <Divider
          sx={{
            borderBottom: `1px solid ${theme.palette.neutral600}`,
            position: 'relative',
          }}
        />
      )}
    </Box>
  );
};

export default memo(Awards);
