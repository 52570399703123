import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import React, { memo, useState } from 'react';
import { logoutAction } from 'redux/auth/AuthReducer';
import { useAppThunkDispatch } from 'redux/store';

interface ProfileGameProps {
  openLogoutModal: boolean;
  handleCloseLogoutModal: () => void;
}

const LogoutModal = ({ openLogoutModal, handleCloseLogoutModal }: ProfileGameProps) => {
  const dispatch = useAppThunkDispatch();

  const [disabled, setDisabled] = useState(false);

  const handleLogoutAction = () => {
    setDisabled(true);
    dispatch(logoutAction());
  };

  return (
    <Modal show={openLogoutModal}>
      <InfoModal
        cta={
          <>
            <SecondaryButton sx={{ mr: '16px' }} onClick={handleCloseLogoutModal} wide>
              Batal
            </SecondaryButton>
            <PrimaryButton onClick={handleLogoutAction} disabled={disabled} wide>
              Keluar
            </PrimaryButton>
          </>
        }
      >
        <Text variant="h2" textAlign="center" mb="8px">
          Keluar dari akun MABAR?
        </Text>
        <Text textAlign="center" mb="8px">
          Login kembali untuk bisa MABAR dengan pelajar se-Indonesia
        </Text>
      </InfoModal>
    </Modal>
  );
};

export default memo(LogoutModal);
