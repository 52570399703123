import MatchResultItem from 'components/Lists/MatchResultItem';
import ScrollView from 'components/ScrollView';
import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerScrimHistory } from 'redux/account/AccountResponse';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';
//import Slider from "react-slick";

const ScrimHistoryAccount = (p: { scrimHistoryList?: PlayerScrimHistory[] }) => {
  const { t } = useTranslation();
  const currentTeam = getMyCurrentTeamFromSession();
  const getStatusMatch = (
    key: string,
    isWin: boolean,
  ): 'NotValid' | 'Invitation' | 'WIN' | 'LOSE' | 'COMPETE' => {
    switch (key) {
      case '1' || '8':
        return 'NotValid';
      case '7' || '10':
        return 'COMPETE';
      case '3' || '4':
        return 'Invitation';
      case '12' || '11':
        return isWin ? 'WIN' : 'LOSE';
      default:
        return 'NotValid';
    }
  };
  return p.scrimHistoryList && p.scrimHistoryList.length >= 0 ? (
    <ScrollView
      direction="horizontal"
      margin={p.scrimHistoryList.length === 1 ? '0px 16px' : '0px'}
      display="flex"
      align="start"
      pageIndicator={{
        show: p.scrimHistoryList.length === 1 ? false : true,
        position: 'bottom',
        align: 'center',
      }}
    >
      {p.scrimHistoryList.map((item, index) => {
        const myteam = currentTeam.some(v => v.id === item.firstTeamId)
          ? currentTeam.find(v => v.id === item.firstTeamId)
          : currentTeam.find(v => v.id === item.secondTeamId);
        const firstTeamWinOrLose = currentTeam.find(v => v.id === item.winnerTeamId)?.id
          ? true
          : false;
        const secondTeamWinOrLose = currentTeam.find(v => v.id === item.winnerTeamId)?.id
          ? true
          : false;
        const isDraw = firstTeamWinOrLose === false && secondTeamWinOrLose === false;
        return (
          <MatchResultItem
            key={item.id}
            horizontal
            variant={getStatusMatch(item.status.key, firstTeamWinOrLose)}
            headerTitle={
              <>
                <b>Pertandingan Scrim #{item?.id ?? 0}</b>{' '}
                {isDraw ? '' : firstTeamWinOrLose ? ' 🏆' : ''}
              </>
            }
            margin={
              index === p.scrimHistoryList!.length - 1 ? '0px 8px 0px 8px' : '0px 0px 0px 8px'
            }
            data={{
              firstTeam: item.firstTeam,
              firstTeamAvatar: item.firstTeamAvatar,
              firstTeamScore: item.firstTeamScore,
              firstTeamWin: isDraw ? true : firstTeamWinOrLose,
              matchDate: item.startAt,
              secondTeam: item.secondTeam,
              secondTeamAvatar: item.secondTeamAvatar,
              secondTeamScore: item.secondTeamScore,
              secondTeamWin: isDraw ? true : secondTeamWinOrLose,
              matchType:
                ' - ' + item.type.value.replace('BO3', 'Best of 3').replace('BO5', 'Best of 5'),
            }}
            onClick={() => history.push(`/scrims/${myteam?.game?.urlSlug}/${item.id}`)}
          />
        );
      })}
    </ScrollView>
  ) : (
    <Stack
      direction="column"
      align="center"
      margin="10px 0px 0px 0px"
      disableSelectText
      disableDrag
    >
      <img alt="" src={IconSource('mabar-typeface-fullwhite')} />
      <NormalText
        fontSize="14px"
        fontWeight="400"
        color="#969393"
        lineHeight="20.44px"
        textAlign="center"
        margin="24px 0px 30px 0px"
      >
        {t('player_account_match_tournament_empty')}
      </NormalText>
    </Stack>
  );
};

export default ScrimHistoryAccount;
