import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeyValue } from 'types';
import { getScrimDetailById } from './ScrimService';

export interface ScrimDetailResponse {
    id:                           string;
    startAt:                      Date;
    firstTeamId:                  string;
    firstTeam:                    string;
    firstTeamUrlSlug:             string;
    firstTeamAvatar:              string;
    firstTeamSchool:              string;
    firstTeamScore:              string;
    firstTeamCaptainPhoneNumber: string;
    firstTeamCaptain:             string;
    firstTeamPlayers:             FirstTeamPlayer[];
    secondTeamId:                  string;
    secondTeam:                    string;
    secondTeamUrlSlug:             string;
    secondTeamAvatar:              string;
    secondTeamSchool:              string;
    secondTeamScore:              string;
    secondTeamCaptainPhoneNumber: string;
    secondTeamCaptain:            string;
    secondTeamPlayers:             FirstTeamPlayer[];
    status:                       KeyValue;
}

export interface FirstTeamPlayer {
  id: string;
  playerId: string;
  name: string;
  nickName: string;
  fullName: string;
  userName: string;
  gameProviderId: string;
  picture: string;
  hasCheckedIn: boolean;
  isCaptain: boolean;
}

export interface ScrimDetailState {
  data: ScrimDetailResponse;
  tabTitle: string;
  firstTeamPlayers: FirstTeamPlayer[];
  secondTeamPlayers: FirstTeamPlayer[];
  loading: boolean;
  errorMessage: string;
}

const initialState: ScrimDetailState = {
  data: {
    firstTeam: '',
    firstTeamAvatar: '',
    firstTeamId: '',
    firstTeamPlayers: [],
    firstTeamSchool: '',
    firstTeamScore:'',
    firstTeamUrlSlug: '',
    firstTeamCaptainPhoneNumber: '',
    firstTeamCaptain: '',
    secondTeam: '',
    secondTeamAvatar: '',
    secondTeamId: '',
    secondTeamPlayers: [],
    secondTeamSchool: '',
    secondTeamScore:'',
    secondTeamUrlSlug: '',
    secondTeamCaptainPhoneNumber: '',
    secondTeamCaptain: '',
    id: '',
    startAt: new Date(),
    status: {
      key: '', value: ''
    },
  },
  tabTitle: '',
  firstTeamPlayers: [],
  secondTeamPlayers:[],
  loading: false,
  errorMessage: '',
};

const scrimDetailSlice = createSlice({
  name: 'scrimDetail',
  initialState: initialState,
  reducers: {
    getScrimPlayers: (state, action: PayloadAction<{ matchId: string; teamSlug: string }>) => {
      state.loading = true;
      state.firstTeamPlayers = state.firstTeamPlayers;
      state.secondTeamPlayers = state.secondTeamPlayers;
      state.data.id = action.payload.matchId;
      state.data.firstTeamUrlSlug = action.payload.teamSlug;
    },
    successGetScrimPlayers: (state, action) => {
      state.firstTeamPlayers = action.payload;
      state.secondTeamPlayers = action.payload;
      state.loading = false;
    },
    failedGetScrimPlayers: (state, action) => {
      state.errorMessage = action.payload.error;
    },
    getScrimDetail: (state, action: PayloadAction<{ id: string }>) => {
      state.loading = true;
      state.data = state.data;
      state.data.id = action.payload.id;
      state.tabTitle = state.data.firstTeam;
    },
    resetScrimDetail: (_state) => {
      _state = {...initialState}
    },
    successGetScrimDetail: (state, action) => {
      state.data = action.payload;
      state.tabTitle = action.payload.firstTeam;
      state.loading = false;
    },
    failedGetScrimDetail: (state, action) => {
      state.errorMessage = action.payload.error;
    },
  },
  extraReducers: (Builder) => {
    Builder.addCase(getScrimDetailById.pending, (state, _action) => {
      state.loading = true;
      state.errorMessage = '';
      state.data = {...initialState.data}
    })
    .addCase(getScrimDetailById.rejected, (state, _action) => {
      state.loading = false;
      state.errorMessage = _action.error.message ?? '';
    }) 
    .addCase(getScrimDetailById.fulfilled, (state, action)=> {
      if(action.payload.data){
        state.data = action.payload.data
      }else{
        state.errorMessage = action.payload.statusText
      }
      state.loading = false;
    })
  }
});

export const {
  resetScrimDetail,
  getScrimDetail,
  successGetScrimDetail,
  failedGetScrimDetail,
  getScrimPlayers,
  successGetScrimPlayers,
  failedGetScrimPlayers,
} = scrimDetailSlice.actions;

export default scrimDetailSlice.reducer;
