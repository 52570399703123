import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RoutePrivate from 'containers/RoutePrivate';
import { RouteProps } from 'types';
import { withTranslation } from 'react-i18next';

import { ComponentPages } from './route';
import routes from 'data/routes.json';
import PageError from 'routes/PageError';

interface RouteItem extends RouteProps {
  isPrivate: boolean;
}

const RouteItem: FC<RouteItem> = ({
  component,
  isAuthenticated,
  path,
  redirectToSSO,
  isPrivate,
  ...rest
}) => {
  return isPrivate ? (
    <RoutePrivate component={component} path={path} isAuthenticated={isAuthenticated} {...rest} />
  ) : (
    <Route component={component} path={path} {...rest} />
  );
};

const RouteList: FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => {
  return (
    <Switch>
      {routes.map((item: any, idx: number) => {
        const comp = item.withTranslation
          ? withTranslation()(ComponentPages[item.component])
          : ComponentPages[item.component];

        return (
          <RouteItem
            component={comp}
            path={item.path}
            isAuthenticated={isAuthenticated}
            redirectToSSO={item.redirectToSSO}
            exact={item.exact}
            isPrivate={item.private}
            key={idx}
          />
        );
      })}

      <Route
        component={() => (
          <Redirect to="/competition" />
        )}
        path={["/competition/mabar-high-school-tournament", "/awards/high-school-tournament"]}
        exact
      />
      <Route
        component={() => (
          <Redirect to="/competition/rrq-mabar-esport-tournament" />
        )}
        path="/competition/mabar-national-championship"
        exact
      />
      <Route
        component={() => (
          <PageError errorCode="500" errorMessage="Internal Server Error (error 500)" />
        )}
        exact
        path="/500"
        strict
        sensitive
      />
      <Route
        component={() => (
          <PageError errorCode="503" errorMessage="Service Unavailable (error 503)" />
        )}
        exact
        path="/503"
        strict
        sensitive
      />
      <Route
        render={() => (
          <PageError errorCode="500" errorMessage="Internal Server Error (error 500)" />
        )}
        path="/500"
      />
      <Route
        render={() => <PageError errorCode="503" errorMessage="Service Unavailable (error 503)" />}
        path="/503"
      />   
      <Route
        component={withTranslation()(ComponentPages["NotFound"])}
      />   
    </Switch>
  );
};

export default RouteList;
