import {
  BorderRadiusTypes,
  ButtonBorderRadiusTypes,
  FontFamilyTypes,
  LineHeightTypes,
  ColorTypes,
  TypographTypes,
} from './themeInterface';

const color = {
  primaryMain: '#D51F40',
  secondaryMain: '#F37024',
  gradientPrimary:
    'linear-gradient(131.42deg, #D51F40 46.48%, #E24134 62.51%, #E8542E 74.36%, #F37024 83.78%)',
  black01: '#423E3E',
  black02: '#6A6363',
  black03: '#969393',
  gray01: '#DDD8D8',
  gray02: '#F1EBEB',
  gray03: '#FBF7F7',
  gray04: '#FBF8F8',
  gray05: '#191817',
  gray06: '#2D2F37',
  grayGradient:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.28)), linear-gradient(0deg, #4E5156, #4E5156)',
  grayGradient2: 'linear-gradient(0deg, rgba(1, 1, 1, 0.8), rgba(1, 1, 1, 0.8))',
  grayGradient3: 'linear-gradient(180deg, #373942 0%, #1E1F25 100%)',
  grayGradientRadial: 'radial-gradient(100% 671.28% at 0% 50%, #D5D5D5 0%, #888894 100%)',
  red01: '#E43C5A',
  redGradientRadial: 'radial-gradient(100% 671.28% at 0% 50%, #F9A5AB 0%, #F45A64 100%)',
  yellow01: '#E7B450',
  yellow02: '#BC7D1E',
  yellowGradientRadial: 'radial-gradient(100% 671.28% at 0% 50%, #FFD88B 0%, #FF8A00 100%)',
  orange01: '#BE4D1D',
  orangeGradientRadial: 'radial-gradient(100% 671.28% at 0% 50%, #FF9F67 0%, #F35624 100%)',
  green01: '#26BD87',
  greenGradient:
    'linear-gradient(270deg, rgba(19, 96, 69, 0.2) 0%, rgba(0, 0, 0, 0) 48.12%), linear-gradient(0deg, #1A1817, #1A1817)',
  greenGradientRadial: 'radial-gradient(100% 671.28% at 0% 50%, #50DFAC 0%, #1E976C 100%)',
  brown100: '#211703',
  neutral100: '#F4F4F5',
  neutral150: '#EAEAEC',
  neutral200: '#D4D6D8',
  neutral250: '#DBD2CF',
  neutral300: '#B4B7BB',
  neutral350: '#A8A29F',
  neutral400: '#6B6E72',
  neutral500: '#4E5156',
  neutral530: '#323437',
  neutral550: '#2E3033',
  neutral600: '#27282B',
  neutral700: '#17181A',
  neutral800: '#101216',
  neutral900: '#0A0A0B',
  primary100: '#FDEBE0',
  primary200: '#FCD6C1',
  primary500: '#F68F54',
  primary600: '#F37024',
  primary700: '#D1540B',
  primary800: '#4E1F04',
  primary900: '#271002',
  primaryRed100: '#FADDE3',
  primaryRed150: '#F5BCC6',
  primaryRed200: '#ED899C',
  primaryRed250: '#E65771',
  primaryRed300: '#E13555',
  primaryRed400: '#D31F40',
  primaryRed500: '#B31B37',
  primaryRed600: '#95162D',
  primaryRed700: '#681020',
  primaryRed800: '#3C0912',
  primaryRed900: '#1E0409',
  primaryOrange100: '#FEF9F1',
  primaryOrange200: '#FCEDD4',
  primaryOrange300: '#FADBA8',
  primaryOrange400: '#F7C87D',
  primaryOrange500: '#F6BC60',
  primaryOrange600: '#F4B043',
  primaryOrange700: '#F3AA36', // OG color RRQ Mabar
  primaryOrange800: '#F2A221',
  primaryOrange900: '#DC8C0D',
  primaryOrange1000: '#A06609',
  primaryOrange1100: '#644006',
  primaryOrange1200: '#3C2603',
  primaryOrange1300: '#140D01',
  primaryGradient: 'linear-gradient(90deg, #D51F40 0%, #F37024 100%)',
  primaryGradient2:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #D51F40 0%, #F37024 100%)',
  primaryGradient3: 'linear-gradient(90deg, #DD3339 0%, #F06628 100%)',
  primaryGradient4:
    'linear-gradient(0deg, rgba(244, 90, 100, 0.08), rgba(244, 90, 100, 0.08)),linear-gradient(0deg, #27282B, #27282B)',
  success100: '#EEFCF7',
  success200: '#9BEBCF',
  success500: '#26BD87',
  success600: '#1E976C',
  success700: '#136045',
  success900: '#09271D',
  success950: '#0D1814',
  danger100: '#FFE2E4',
  danger200: '#F9A5AB',
  danger500: '#F45A64',
  danger600: '#D02B20',
  danger700: '#BF0D19',
  warning100: '#FDC04A',
  warning200: '#ECB901',
};

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
    },
  } as object,
  headerHeight: 56,
  bottomBarHeight: 54,
  appColor: '#292A2F',
  easing: 'cubic-bezier(0.35, 0.01, 0.77, 0.34);',
  palette: {
    mode: 'dark',
    ...color,
    primary: { main: color.primaryMain },
    secondary: { main: color.secondaryMain },
    text: {
      main: '#FFFFFF',
    },
    error: {
      main: color.danger600,
    },
  } as object,
  typography: {
    fontFamily: "'Lexend Deca', sans-serif",
    htmlFontSize: 18.27,
    fontSize: 14,
    h1: {
      fontFamily: "'Lexend Deca', sans-serif",
      color: color.neutral200,
      fontSize: '32px',
      lineHeight: '42px',
      fontWeight: 700,
    },
    h2: {
      fontFamily: "'Lexend Deca', sans-serif",
      color: color.neutral200,
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 600,
    },
    h3: {
      fontFamily: "'Lexend Deca', sans-serif",
      color: color.neutral200,
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 600,
    },
    label: {
      fontFamily: "'Lexend Deca', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      color: color.neutral200,
    },
    labelSmall: {
      fontFamily: "'Lexend Deca', sans-serif",
      fontSize: '8px',
      lineHeight: '12px',
      fontWeight: 400,
      letterSpacing: '1px',
      color: color.neutral200,
    },
    body: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: color.neutral200,
    },
    bodySmall: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
      color: color.neutral200,
    },
    button: {
      fontFamily: "'Lexend Deca', sans-serif",
      fontSize: '12px',
      lineHeight: '24px',
      fontWeight: 600,
      color: color.neutral200,
    },
    subtitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      color: color.neutral200,
    },
    ribbon: {
      fontFamily: "'Lexend Deca', sans-serif",
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 600,
      color: color.neutral200,
    },
  },
  button: {
    borderRadius: {
      xs: '4px',
      lg: '28px',
      xl: '32px',
    },
    padding: {
      lg: ['12px', '28px'],
      xl: ['14px', '32px'],
    },
  },
  borderRadius: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
  },
  fontFamily: {
    roboto: '"Roboto"',
    lexendDeca: '"Lexend Deca", sans-serif',
    inter: '"Inter", sans-serif',
    neusaNextStd: 'Neusa Next Std',
  },
  lineHeight: {
    xs: '14px',
    sm: '16px',
    md: '20px',
    lg: '22px',
    xl: '32px',
  },
};

export default theme;

declare module '@mui/material/styles' {
  //Palette
  interface Palette extends ColorTypes {}
  interface PaletteOptions extends ColorTypes {}

  //Typography
  interface TypographyVariants extends TypographTypes {}
  interface TypographyVariantsOptions extends TypographTypes {}

  //Theme
  interface Theme {
    headerHeight: number;
    bottomBarHeight: number;
    appColor: string;
    easing: string;
    button: ButtonBorderRadiusTypes;
    borderRadius: BorderRadiusTypes;
    fontFamily: FontFamilyTypes;
    lineHeight: LineHeightTypes;
  }
  interface ThemeOptions {
    headerHeight: number;
    bottomBarHeight: number;
    appColor: string;
    easing: string;
    button: ButtonBorderRadiusTypes;
    borderRadius: BorderRadiusTypes;
    fontFamily: FontFamilyTypes;
    lineHeight: LineHeightTypes;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    label: true;
    labelSmall: true;
    body: true;
    bodySmall: true;
    button: true;
    subtitle: true;
    ribbon: true;
    h4: false;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
  }
}
