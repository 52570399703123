import { FC, useState } from 'react';
import { RouteType } from '../../routes/router';
import React from 'react';
import { BottomNavContainer, BottomNavIcon } from 'components/styled/nav.styled';

export interface BottomNavBarType {
  data?: RouteType[] | undefined;
  onSelected?: (nav: RouteType) => void | undefined;
}

const BottomNavBar: FC<BottomNavBarType> = props => {
  const [dataNav, setDataNav] = useState(props.data ?? []);
  const clickHandler = (clickedNav: RouteType) => {
    if (props.onSelected) {
      props.onSelected(clickedNav);
    }
    const newNav = dataNav.map((nav: RouteType) => {
      return nav.id === clickedNav.id
        ? {
            ...nav,
            isSelected: true,
          }
        : {
            ...nav,
            isSelected: false,
          };
    });
    setDataNav(newNav);
  };
  return (
    <BottomNavContainer>
      {dataNav.map((nav: RouteType) => {
        return (
          <BottomNavIcon
            isSelected={nav.isSelected}
            src={nav.icon}
            onClick={() => clickHandler(nav)}
          />
        );
      })}
    </BottomNavContainer>
  );
};

export default BottomNavBar;
