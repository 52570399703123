import React from 'react';
import { Circular, Inner, BarLeft, BarRight, ProgressRight, ProgressLeft } from './styles';
import { Text } from 'design/Text';

export interface CounterCircleProps {
  maxNumber: number;
  currentNumber: number;
  isColor?: boolean;
}

const CounterCircle = ({ maxNumber, currentNumber, isColor }: CounterCircleProps) => {
  var leftDegree = 0;
  var rightDegree = 180;

  if (maxNumber / 2 < currentNumber)
    leftDegree = Math.round(((currentNumber - maxNumber / 2) / (maxNumber / 2)) * 180);
  else rightDegree = Math.round((currentNumber / (maxNumber / 2)) * 180);

  return (
    <Circular>
      <Inner>
        <Text variant="ribbon" sx={{ color: isColor ? 'success500' : 'neutral400' }}>
          {currentNumber}
        </Text>
      </Inner>
      <div>
        <BarLeft>
          <ProgressLeft percentageFill={leftDegree} isColor={isColor} />
        </BarLeft>
        <BarRight>
          <ProgressRight percentageFill={rightDegree} isColor={isColor} />
        </BarRight>
      </div>
    </Circular>
  );
};

export default CounterCircle;
