import { TabsProps } from './';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/system';
import { IconSource } from 'modules/assetpath';

export const tabRedLineStyled = {
  mt: '-50px',
  background: 'linear-gradient(360deg, #161618 0%, rgba(26, 24, 23, 0) 100%)',
  overflow: 'unset',
  '&::before': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '1px',
    bgcolor: 'neutral530',
  },
  '.MuiTabs-indicator': {
    bottom: 0,
    height: '2px',
    background: 'transparent',
    bgcolor: 'primaryOrange700',
    borderRadius: 0,
    backfaceVisibility: 'hidden',
    transformStyle: 'preserve-3d',
    transform: 'translate3d(0,0,0)',
  },
  '.MuiTabs-flexContainer': {
    a: { display: 'flex' },
  },
  '.MuiTab-root': {
    p: '16px 0',
    '&.Mui-selected': {
      background: 'unset',
      backgroundClip: 'unset',
      WebkitTextFillColor: 'unset',
      '.MuiTypography-root': {
        color: 'primaryOrange700',
        '& + span': {
          color: 'neutral700',
        },
      },
    },
  },
};

export const TabStyled: any = styled(Tab)<TabsProps>`
  min-width: 0;
  max-width: none;
  min-height: 0;
  padding: ${({ variant }) => (variant == 'button' ? '7px 0' : '14px 0')};
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  text-transform: inherit;
  font-size: 12px;
  line-height: ${({ variant }) => (variant == 'button' ? '24px' : '15px')};
  color: ${({ variant, theme }) =>
    variant == 'button' ? theme.palette.neutral300 : theme.palette.neutral400};
  -webkit-box-decoration-break: clone;
  display: inline-block;
  flex: ${({ variant }) => (variant == 'button' ? 'auto' : 'none')};
  ${({ variant, theme }) =>
    variant == 'button'
      ? `
        &:before {
          content: '';
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 7px;
          background-image: url(${IconSource('grunge', 'png')});
          background-repeat: repeat;
          background-size: cover;
          z-index: 2;
          opacity:0;
        }
        &:not(:last-child):after {
          content: '';
          border-right: 1px solid #323437;
          position: absolute;
          right: 0px;
          height: 24px;
        }
        &.Mui-selected {
          color: ${theme.palette.neutral700};
          border: none;
          &:before {
            transition: ease 1s;
            opacity: 1;
          }
        }
      `
      : `
        &.Mui-selected {
          color: ${theme.palette.primaryOrange700};
        }
        &:not(:last-child) {
          margin-right: 16px;
        }
      `}
` as typeof Tab;

export const TabsStyled: any = styled(Tabs)<TabsProps>`
  position: relative;
  min-height: 0;
  padding: ${({ variant }) => (variant == 'button' ? '3px' : '0px')};
  border: ${({ variant }) => (variant == 'button' ? '1px solid #27282B !important' : 'none')};
  border-radius: ${({ variant }) => (variant == 'button' ? '8px' : '0')};
  background: ${({ variant, theme }) => (variant == 'button' ? theme.palette.neutral700 : '')};
  & .MuiTabs-flexContainer {
    a {
      transition: ease 0.5s;
    }
    .label-item {
      position: ${({ variant }) => (variant == 'button' ? 'relative' : 'unset')};
      z-index: 2;
    }
  }
  & .MuiTabs-indicator {
    z-index: 1;
    height: ${({ variant }) => (variant == 'button' ? '100%' : '4px')};
    border-radius: ${({ variant }) => (variant == 'button' ? '7px' : '16px 16px 0px 0px')};
    background: #f3aa36;
    box-shadow: ${({ variant }) =>
      variant == 'button'
        ? '0px 2px 12px rgba(0, 0, 0, 0.25), inset 0px 2px 3px rgba(255, 255, 255, 0.32)'
        : 'none'};
  }
`;

export const TabPanelStyled = styled(Box)(() => ({
  padding: 0,
  margin: 0,
  fontFamily: "'Lexend Deca', sans-serif",
}));
