import { Box } from '@mui/material';
import { Text } from 'design/Text';
import React, { FC } from 'react';



export interface CardRefereeProps {
  title?: string;
  content?: string;
}

export const CardReferee: FC<CardRefereeProps> = ({ title, content }) => {
  
  return (
    <Box
      sx={{
        padding: '12px',
        bgcolor: 'neutral700',
        border: '1px solid #242628',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Text variant="label" sx={{ width: 'fit-content', mr: '12px' }}>
        {title}
      </Text>
      <Text
        variant="subtitle"
        sx={{ width: '100%', textAlign: 'right', wordWrap: 'break-word', overflow: 'auto', color: 'neutral400' }}
      >
        {content}
      </Text>
    </Box>
  );
};
