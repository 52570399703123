import { Box, Stack } from 'components/styled/layout.styled';
import React, { FC, useState } from 'react';
import TabButton, { TabButtonType } from './TabButton';
import { Avatar } from 'components/styled/avatar.styled';

export interface TabMenuType {
  variant?: 'center' | 'left' | 'right' | undefined;
  data?: TabButtonType[];
  onSelected?: ((tab: TabButtonType) => void) | undefined;
  shadowColor?: string;
  backgroundColor?: string;
  iconRight?: string;
  onIconRightClick?: () => void;
}

const TabMenu: FC<TabMenuType> = ({ data, onSelected, variant, shadowColor, backgroundColor, iconRight, onIconRightClick }) => {
  const [dataTab, setDataTab] = useState(data);

  const clickHandled = (selectedTab: TabButtonType) => {
    if (onSelected) {
      onSelected(selectedTab);
    }
    const newDataTab = dataTab?.map((tab: TabButtonType) => {
      if (tab.id === selectedTab.id) {
        return {
          ...tab,
          isSelected: true,
        };
      }
      return {
        ...tab,
        isSelected: false,
      };
    });
    setDataTab(newDataTab);
  };

  return (
    <Box width="100%">
      <Stack direction="column">
        <Box padding="16px" background={backgroundColor ?? "#00000022"} shadow={shadowColor ?? "0px 0px 20px 20px #00000022"}>
          <Stack
            direction="row"
            align={`${variant === 'left' ? 'start' : variant === 'right' ? 'end' : 'center'}`}
          >
            {dataTab?.map((tab: TabButtonType) => {
              return (
                <TabButton key={tab.id} {...{ ...tab, onClickButton: () => clickHandled(tab) }} />
              );
            })}
            {iconRight && (
              <Avatar
                src={iconRight}
                margin='0px 0px 0px auto'
                onClick={onIconRightClick}
                isPressable={onIconRightClick? true : false}
                draggable={false}
              />
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default TabMenu;
