export enum MatchStatusKey {
  Unknown = 0,
  Expired = 1,
  Reject = 2,
  Open = 3,
  Invitation = 4,
  Scheduled = 5,
  CheckIn = 6,
  Compete = 7,
  NotValid = 8,
  ResultUpload = 9,
  Dispute = 10,
  ResultCalculated = 11,
  DataCompleted = 12,
  BYE = 13,
}

export type MatchStatusValue = keyof typeof MatchStatusKey;

export enum MatchKindKey {
  Unknown = 0,
  Scrim = 1,
  Tournament = 2,
}

export type MatchKindValue = keyof typeof MatchKindKey;

export enum MatchResultKey {
  NormalWin = 1,
  Walkover = 2,
  NoMatch = 3,
}

export type MatchResultValue = keyof typeof MatchResultKey;

export enum MatchType {
  BO3 = 3,
}

export type MatchTypeValue = keyof typeof MatchType;
