import AccountDrawer from './Drawer/AccountDrawer';
import LogoutModal from './Modal/LogoutModal';
import TournamentAwards from './state/Awards';
import HeaderAccount from './state/HeaderAccount';
import ProfileGame from './state/ProfileGame';
import ScrimStatistic from './state/ScrimStatistic';
import TeamId from './state/TeamId';
import Tournaments from './state/Tournaments';
import { Stack, Box } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { LayoutContext } from 'context/LayoutContext';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource, ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import { fetchPlayerProfile } from 'redux/account/AccountServices';
import { resetAward } from 'redux/award/AwardReducer';
import {
  fetchTournamentParticipantAwardsPlayer,
  fetchTournamentParticipantAwardsFrame,
} from 'redux/award/AwardService';
import { useAppSelector } from 'redux/hooks';
import { getCountUnreadNotification } from 'redux/notification/NotificationReducer';
import { resetScrimList } from 'redux/scrim/ScrimReducer';
import { fetchScrimTeamStatistic } from 'redux/scrim/ScrimService';
import { useAppThunkDispatch } from 'redux/store';
import { resetTournament } from 'redux/tournament/TournamentReducer';
import { fetchTournamentParticipantPlayer } from 'redux/tournament/TournamentServices';
import AuthService from 'services/AuthService';

const AccountPage = () => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const location = useLocation<any>();
  const isAuthenticated = auth.isAuthenticated();
  const dispatch = useAppThunkDispatch();

  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [haveNotifUnread, setHaveNotifUnread] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [parallaxDisable, setParallaxDisable] = useState(true);
  const playerProfileContainerRef = useRef<any>();

  const {
    playerInfo,
    scrimStatistic,
    tournamentList,
    tournamentParticipantPlayer,
    tournamentParticipantAwardsFrame,
    playerInfoStatus,
    scrimStatisticStatus,
    awardsFrameStatus,
    tournamentParticipantStatus,
    tournamentParticipantAwardsStatus,
  } = useAppSelector(({ account, scrimList, tournament, award }) => ({
    playerInfo: account?.data,
    scrimStatistic: scrimList?.scrimTeamStatistic,
    tournamentList: tournament?.tournamentList,
    tournamentParticipantPlayer: tournament?.tournamentParticipantPlayer,
    tournamentParticipantAwardsPlayer: award?.tournamentParticipantAwardsPlayer,
    tournamentParticipantAwardsFrame: award?.tournamentParticipantAwardsFrame,
    playerInfoStatus: account?.status,
    scrimStatisticStatus: scrimList?.status?.scrimTeamStatistic,
    awardsFrameStatus: award?.status?.tournamentParticipantAwardsFrame,
    tournamentParticipantStatus: tournament?.status?.tournamentParticipantPlayer,
    tournamentParticipantAwardsStatus: award?.status?.tournamentParticipantAwardsPlayer,
  }));

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleFetchPlayerProfile = (username: string) => {
    dispatch(
      fetchPlayerProfile({
        username: username,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleCountNotification = () => {
    if (playerInfo) {
      dispatch(
        getCountUnreadNotification({
          id: playerInfo.id,
        }),
      )
        .unwrap()
        .then(res => {
          if (res && res.status == 200 && res.data.count > 0) {
            setHaveNotifUnread(true);
          } else {
            setHaveNotifUnread(false);
          }
        })
        .catch(error => setError(error));
    }
  };

  const handleFetchScrimTeamStatistic = (teamId: string) => {
    dispatch(
      fetchScrimTeamStatistic({
        teamId: teamId,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipant = (playerId: string) => {
    dispatch(
      fetchTournamentParticipantPlayer({
        playerId: playerId,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwards = (playerId: string) => {
    dispatch(
      fetchTournamentParticipantAwardsPlayer({
        playerId: playerId,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwardsFrame = () => {
    dispatch(fetchTournamentParticipantAwardsFrame())
      .unwrap()
      .catch(error => setError(error));
  };

  const handleResetScrimList = () => {
    if (!scrimStatistic) {
      dispatch(resetScrimList());
    }
  };

  const handleResetTournament = () => {
    if (!tournamentList && !tournamentParticipantAwardsFrame) {
      dispatch(resetTournament());
    }
  };

  const handleResetAward = () => {
    if (!tournamentParticipantAwardsFrame) {
      dispatch(resetAward());
    }
  };

  useEffect(() => {
    Promise.all([
      handleResetAward(),
      handleResetScrimList(),
      handleResetTournament(),
      handleFetchTournamentParticipantAwardsFrame(),
    ]);

    if (isAuthenticated) {
      handleCountNotification();
      if (playerInfo) {
        handleFetchTournamentParticipant(playerInfo?.id);
        handleFetchTournamentParticipantAwards(playerInfo?.id);
        if (playerInfoStatus === 'succeeded') {
          handleFetchPlayerProfile(playerInfo?.userName);
        }
        if (playerInfo?.games && playerInfo?.games[0]?.team) {
          handleFetchScrimTeamStatistic(playerInfo?.games[0]?.team?.id);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (playerInfo && !playerInfo?.school) {
        history.push('/complete-data');
      }
    }
  }, [playerInfo]);

  useEffect(() => {
    if (isAuthenticated) {
      if (playerInfo && !playerInfo?.school) {
        history.push('/complete-data');
      }
    }
  }, [playerInfo?.games]);

  const handleOpenLogoutModal = () => {
    setOpenLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    setOpenLogoutModal(false);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleSetting = () => {
    return handleDrawerOpen();
  };

  const handleLoading = (status: boolean) => {
    if (status)
      return !Boolean(
        scrimStatistic ||
          (tournamentParticipantAwardsFrame?.awards ?? []).length > 0 ||
          (tournamentParticipantPlayer?.events ?? []).length > 0,
      );
    return false;
  };

  useLayoutEffect(() => {
    if (playerProfileContainerRef?.current?.offsetHeight > 869) {
      setParallaxDisable(false);
    } else setParallaxDisable(true);
  }, [
    scrimStatisticStatus,
    awardsFrameStatus,
    tournamentParticipantStatus,
    playerProfileContainerRef?.current?.offsetHeight,
  ]);

  const { setValue } = useContext(LayoutContext);
  const updateInfo = location?.state?.updateInfo ?? '';

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Profile Saya' }),
      background: {
        color: 'neutral800',
      },
      appBar: {
        logo: true,
        setting: {
          show: isAuthenticated,
          onClick: () => handleSetting(),
        },
        notification: {
          show: isAuthenticated,
          haveNotifUnread: haveNotifUnread,
          onClick: () => history.push('/notification'),
        },
      },
      padding: '0',
      bottomBar: {
        show: true,
      },
      additional: (
        <Toast
          show={openToast || Boolean(updateInfo)}
          onClose={handleCloseToast}
          key={toastMessage}
        >
          <Ribbon
            icon={updateInfo ? IconSource('check-gameon') : ''}
            label={
              <Text variant="body" sx={{ color: updateInfo ? 'success900' : 'neutral100' }}>
                {toastMessage || updateInfo}
              </Text>
            }
            sx={updateInfo ? GameOnToastStyle : { bgcolor: 'neutral500', p: '12px 10px' }}
          />
        </Toast>
      ),
    });
  }, [playerInfo, openToast, haveNotifUnread, updateInfo]);

  return (
    <Box ref={playerProfileContainerRef}>
      <Box
        sx={{
          position: 'relative',
          backfaceVisibility: 'hidden',
          transformStyle: 'preserve-3d',
          transform: 'translate3d(0,0,0)',
        }}
      >
        <Parallax
          disabled={parallaxDisable}
          translateY={[0, 25, 'easeInOut']}
          opacity={[1, 0]}
          shouldAlwaysCompleteAnimation={true}
        >
          <Box
            sx={{
              position: 'relative',
              '&:before': {
                content: "''",
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '50px',
                background: 'linear-gradient(180deg, transparent 0%, #101216 100%)',
                zIndex: 1,
              },
            }}
          >
            <Box
              sx={{
                background: `url(${ImageSource(
                  'bg-playerteam',
                  'png',
                )}) no-repeat top center / cover`,
                width: '100%',
                height: ['210px', '250px'],
              }}
            />
          </Box>
        </Parallax>
      </Box>

      <Stack
        direction="column"
        spacing="12px"
        sx={{
          position: 'relative',
          mt: ['-190px', '-220px'],
          px: '8px',
          pb: '12px',
        }}
      >
        <HeaderAccount />
        <TeamId />
        <ScrimStatistic />
        <TournamentAwards playerSlug={playerInfo?.userName} />
        <Tournaments playerSlug={playerInfo?.userName} />
        <ProfileGame setToastMessage={setToastMessage} setOpenToast={setOpenToast} />
      </Stack>

      <AccountDrawer
        isDrawerOpen={isDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleOpenLogoutModal={handleOpenLogoutModal}
      />

      <LogoutModal
        openLogoutModal={openLogoutModal}
        handleCloseLogoutModal={handleCloseLogoutModal}
      />

      <LoadingModal
        show={
          error
            ? true
            : handleLoading(
                scrimStatisticStatus === 'loading' ||
                  awardsFrameStatus === 'loading' ||
                  tournamentParticipantStatus === 'loading' ||
                  tournamentParticipantAwardsStatus === 'loading',
              )
        }
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </Box>
  );
};

export default AccountPage;
