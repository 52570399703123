import { Box, CardMedia, Stack, Divider, Avatar } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import awardHstJson from 'data/awards/hst.json';
import awardNcsJson from 'data/awards/ncs.json';
import s0_2022HstJson from 'data/awards/s0-2022/hst.json';
import s0_2022NcsJson from 'data/awards/s0-2022/ncs.json';
import s1_2022HstJson from 'data/awards/s1-2022/hst.json';
import s1_2022NcsJson from 'data/awards/s1-2022/ncs.json';
import s1_2023HstJson from 'data/awards/s1-2023/hst.json';
import s1_2023NcsJson from 'data/awards/s1-2023/ncs.json';
import navigationMenu from 'data/navigationMenu.json';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardAward } from 'design/Cards/CardAward';
import { CardAwardList } from 'design/Cards/CardAwardList';
import { CardRankingList } from 'design/Cards/CardRankingList';
import { SelectInput, OptionType } from 'design/Forms/Select';
import { News } from 'design/Section/News';
import { CustomTabs, TabItem } from 'design/Tabs';
import { CompetitionStatus } from 'design/Tag/CompetitionStatus';
import { Text } from 'design/Text';
import { IconSource, ImageSource } from 'modules/assetpath';
import { onClickShare } from 'modules/helpers';
import React, { useState, useEffect, useContext } from 'react';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import { setTimeout } from 'timers';

const selectSeasonDataHST: OptionType[] = [
  { name: 'S0 2022', value: 's0-2022' },
  { name: 'S1 2022', value: 's1-2022' },
  { name: 'S1 2023', value: 's1-2023' },
];

// const selectSeasonDataNCS: OptionType[] = [
//   { name: 'S0 2022', value: 's0-2022' },
//   { name: 'S1 2022', value: 's1-2022' },
//   { name: 'S1 2023', value: 's1-2023' },
// ];

export interface AwardsDetailProps {
  status: 'high-school-tournament' | 'national-championship';
}

const AwardsDetail = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const { t } = useTranslation();
  const height = use100vh();
  // const { awardSlug } = useParams<{ awardSlug: string }>();
  //hard code karena rule ada yang dimatikan
  const awardSlug = "national-championship";
  const [contentData, setContentData] = useState<any>();
  const [seasonSelected, setSeasonSelected] = useState<any>();
  const [seasonData, setSeasonData] = useState<any>();
  const [parallaxDisabled, setParallaxDisabled] = useState<boolean>(true);

  const handleContentData = (status: AwardsDetailProps['status']) => {
    switch (status) {
      case 'high-school-tournament':
        return awardHstJson;
      case 'national-championship':
        return awardNcsJson;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    setContentData(handleContentData(awardSlug as AwardsDetailProps['status']));
  }, []);

  const handleSeasonSelect = (value: any) => {
    if (location.search?.replace('?', '') !== value) return history.push({ search: value });
    return null;
  };

  useEffect(() => {
    setSeasonSelected(location.search?.replace('?', '') || 's1-2023');
  }, [location.search]);

  const seasonFilter = (seasonSelected: string, awardSlug: string) => {
    switch (seasonSelected) {
      case 's0-2022':
        return awardSlug === 'high-school-tournament' ? s0_2022HstJson : s0_2022NcsJson;
      case 's1-2022':
        return awardSlug === 'high-school-tournament' ? s1_2022HstJson : s1_2022NcsJson;
      case 's1-2023':
        return awardSlug === 'high-school-tournament' ? s1_2023HstJson : s1_2023NcsJson;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    setSeasonData(seasonFilter(seasonSelected, awardSlug));
  }, [seasonSelected]);

  const isSeasonTabs = (seasonData?.tabs ?? []).length > 0;

  const handleClickShare = (handleShare?: () => void) => {
    onClickShare({
      title: t('tournament_page_title', { title: 'Tournament Awards' }),
      handleIconShare: () => handleShare && handleShare(),
    });
  };

  const handleRanking = (rank: number) => {
    if (/^\d$/.test(rank.toString())) {
      return `0${rank + 1}`;
    }
    return rank.toString();
  };

  const handleRankingVariant = (rank: number) => {
    if (rank == 0) return 'orange';
    if (rank <= 2) return 'light-gray';
    return 'dark-gray';
  };

  useEffect(() => {
    if (height && seasonData) setTimeout(() => setParallaxDisabled(false), 500);
  }, [height, seasonData]);

  const { setValue } = useContext(LayoutContext);

  const handleBack = () => {
    if (history.action === 'POP') {
      return '/competition';
    }
    return 'goback';
  };

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Tournament Awards' }),
      appBar: {
        logo: false,
        back: {
          to: handleBack(),
        },
        title: (
          <Stack direction="column" sx={{ textAlign: 'center' }}>
            <Text variant="h3" sx={{ fontWeight: 700, letterSpacing: '0.12px' }}>
              Tournament Awards
            </Text>
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '2px' }}>
              {contentData?.title}
            </Text>
          </Stack>
        ),
        menu: navigationMenu,
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            handleClickShare(handleShare);
          }
        },
        separator: true,
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
      background: {
        color: '#0A0A0B',
      },
    });
  }, [contentData]);

  return (
    <>
      <Box
        sx={{
          backfaceVisibility: 'hidden',
          transformStyle: 'preserve-3d',
          transform: 'translate3d(0,0,0)',
        }}
      >
        <Parallax
          disabled={parallaxDisabled}
          translateY={[0, 25, 'easeInOut']}
          opacity={[1, 0]}
          shouldAlwaysCompleteAnimation={true}
        >
          <Stack justifyContent="center" alignItems="center">
            <CardMedia
              component="img"
              src={`${process.env.PUBLIC_URL}/media/images/banner/${seasonData?.background}`}
            />
          </Stack>
        </Parallax>
      </Box>

      <Stack
        sx={{
          position: 'relative',
          bgcolor: 'neutral900',
          zIndex: 3,
          backfaceVisibility: 'hidden',
          transformStyle: 'preserve-3d',
          transform: 'translate3d(0,0,0)',
          '&:before': {
            content: "''",
            position: 'absolute',
            top: -72,
            left: 0,
            width: '100%',
            height: '72px',
            background: 'linear-gradient(0deg, #0A0A0B 0%, rgba(10, 10, 11, 0) 100%)',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            m: '-16px 16px 20px 16px',
            '&::before': {
              content: "''",
              width: 'calc(100% - 5px)',
              height: '15px',
              position: 'absolute',
              bottom: '-3px',
              left: '2px',
              zIndex: 0,
              borderRadius: '50px',
              background: '#2f3033',
            },
          }}
        >
          <SelectInput
            label="Season"
            // options={
            //   awardSlug === 'high-school-tournament' ? selectSeasonDataHST : selectSeasonDataNCS
            // }
            options = {selectSeasonDataHST}
            defaultValue={location.search.replace('?', '') || 's1-2023'}
            onItemChange={e => handleSeasonSelect(e)}
          />
        </Box>

        <News
          title={contentData?.news.title}
          content={
            <Box component="span" sx={{ fontWeight: 500 }}>
              {contentData?.news.content.text}
            </Box>
          }
          cta={
            <SecondaryButton variant="small" wide onClick={() => handleClickShare()}>
              <Stack sx={{ mr: '6px' }}>
                <Avatar src={IconSource('ic-share-social-white')} sx={{ width: 18, height: 18 }} />
              </Stack>
              {contentData?.news.textButton}
            </SecondaryButton>
          }
          sx={{
            m: '0 0 40px 0',
            p: '0 16px',
            background: `#0A0A0B url(${ImageSource('bg-cup-illustration', 'png')}) no-repeat right`,
            backgroundPositionY: '30px',
          }}
        />

        <Divider sx={theme => ({ borderColor: theme.palette.neutral530 })} />

        {seasonData && seasonData?.mvp && (
          <Box
            sx={{
              mb: '16px',
              p: '17px 16px 8px 16px',
              background: `url(${ImageSource('bg_awards-detail_behind-slider', 'png')}) top/cover`,
              backfaceVisibility: 'hidden',
              transformStyle: 'preserve-3d',
              transform: 'translate3d(0,0,0)',
            }}
          >
            <CardAward
              image={ImageSource(seasonData?.mvp.photo, 'png')}
              imageSx={{ width: '106px', height: '106px' }}
              label={<CompetitionStatus variant="red" label={seasonData?.mvp.label} />}
              title={seasonData?.mvp.name}
              description={seasonData?.mvp.schoolName}
              isChampion={true}
            />
          </Box>
        )}

        {seasonData && isSeasonTabs && (
          <Box
            sx={{
              mt: '24px',
              px: '16px',
              backfaceVisibility: 'hidden',
              transformStyle: 'preserve-3d',
              transform: 'translate3d(0,0,0)',
            }}
          >
            <CustomTabs variant="button">
              {seasonData?.tabs.map((tabs: any) => (
                <TabItem title={tabs.label}>
                  <Box sx={{ mb: '24px' }}>
                    {tabs.content.length == 0 ? (
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={theme => ({
                          mt: '24px',
                          height: '107px',
                          width: '100%',
                          bgcolor: theme.palette.neutral700,
                          borderRadius: '8px',
                        })}
                      >
                        <Text sx={{ lineHeight: '21px', color: 'neutral400', fontWeight: 500 }}>
                          Belum ada data nih, silahkan cek lagi nanti
                        </Text>
                      </Stack>
                    ) : (
                      <>
                        {tabs.content
                          .slice()
                          .sort((a: any, b: any) => {
                            if (a.location < b.location) {
                              return -1;
                            }
                            if (a.location > b.location) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((content: any) => {
                            const isWinnerList = tabs.label === 'Daftar Pemenang';
                            return (
                              <>
                                {isWinnerList ? (
                                  <>
                                    <Text variant="h3" sx={{ mt: '32px' }}>
                                      {content.location}
                                    </Text>
                                    {content.list.map((list: any, idx: number) => (
                                      <CardRankingList
                                        index={idx}
                                        variant={handleRankingVariant(idx)}
                                        rank={handleRanking(idx)}
                                        schoolLogo={`${process.env.PUBLIC_URL}/media/images/sekolah/${list.schoolLogo}.png`}
                                        schoolName={list.schoolName}
                                        teamName={list.teamName}
                                        sx={{
                                          mt: '12px',
                                          cursor: content?.matchSlug ? 'pointer' : 'default',
                                        }}
                                        onClick={() => {
                                          content?.matchSlug &&
                                            history.push({
                                              pathname: `/tournament/mobile-legends/${content?.matchSlug}`,
                                              state: {
                                                pathname: location.pathname,
                                                search: seasonSelected,
                                              },
                                            });
                                        }}
                                      />
                                    ))}
                                  </>
                                ) : (
                                  <CardAwardList
                                    avatarIsCircle={true}
                                    awardText={content.location}
                                    contentText={content.name}
                                    contentSubtext={content.schoolName}
                                    sx={{ mt: '12px' }}
                                  />
                                )}
                              </>
                            );
                          })}
                      </>
                    )}
                  </Box>
                </TabItem>
              ))}
            </CustomTabs>
          </Box>
        )}

        {seasonData && !isSeasonTabs && (
          <Box
            sx={{
              px: '16px',
              pb: '24px',
              backfaceVisibility: 'hidden',
              transformStyle: 'preserve-3d',
              transform: 'translate3d(0,0,0)',
            }}
          >
            <Text variant="h3">{seasonData.label}</Text>
            {seasonData.list.map((list: any, idx: number) => (
              <CardRankingList
                index={idx}
                variant={handleRankingVariant(idx)}
                rank={handleRanking(idx)}
                schoolLogo={`${process.env.PUBLIC_URL}/media/images/sekolah/${list.schoolLogo}.png`}
                schoolName={list.schoolName}
                schoolLocation={list.location}
                teamName={list.teamName}
                sx={{ mt: '12px' }}
              />
            ))}
          </Box>
        )}
      </Stack>
    </>
  );
};

export default AwardsDetail;
