// import GradientButton from 'components/Buttons/GradientButton';
import HeaderMatchDetails from 'components/cards/HeaderMatchDetails';
import { CardScrimWinLose, ScrimWinLoseProps } from 'components/CardScrimWinLose/index';
import { TabButtonType } from 'components/layouts/TabButton';
import TabMenu from 'components/layouts/TabMenu';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { ImageSource } from 'modules/assetpath';
import PlayerCard from 'components/cards/PlayerCard';
import React, { useState, useEffect, useCallback } from 'react';
import AppLayout from 'components/layouts/AppLayout';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getScrimDetail } from 'redux/scrim/ScrimDetailReducer';
import { getScrimDetailGame } from 'redux/scrim/ScrimDetailGameReducer';
import { useParams } from 'react-router-dom';
import {
  MenuContainer,
  MenuItems,
  MenuLists,
  MenuSeparator,
  PopupMenu,
} from 'components/UniPopup/Popup/Menu';
import { ClickAwayListener, Grow } from '@mui/material';
import { VirtualElement } from '@popperjs/core';

const RoundFill = () => {
    //@ts-ignore
  let { scrim_id } = useParams();

  const dispatch = useAppDispatch();
  const {data:data} = useAppSelector((state) => state.scrimDetail);
  const {data:datas} = useAppSelector((state) => state.scrimDetailGame);
  let[teamNo, setTeamNo] = useState(false);

  
  const winResult : ScrimWinLoseProps[] = [
    {
      text: '14 Okt 2021 - 14:30 WIB',
      header: 'Tim Kamu Menang di Game Ini',
      team1: datas.firstTeam,
      team2: datas.secondTeam,
      picture1: datas.firstTeamAvatar,
      picture2: datas.secondTeamAvatar,
      score: <p style={{fontWeight: 'bold'}}>{datas.firstTeamTotalKill?? 0}{' '}<span style={{color: '#DDD8D8', fontSize: '16px', fontWeight: 'normal'}}>Kills</span></p>,
      score1: <p style={{fontWeight: 'bold'}}>{datas.secondTeamTotalKill?? 0}{' '}{' '}<span style={{color: '#DDD8D8', fontSize: '16px', fontWeight: 'normal'}}>Kills</span></p>,
      bg: datas.firstTeam === datas.winnerTeam ? 'linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))' : 'linear-gradient(0deg, rgba(228, 60, 90, 0.3), rgba(228, 60, 90, 0.3))',
      border: '0',
      minHeight:'176px',
      src: datas.firstTeamScreenshot?? ImageSource('game-result', 'png')
    },
  ];

  const loseResults : ScrimWinLoseProps[] = [
    {
      text: '14 Okt 2021 - 14:30 WIB',
      header: 'Tim Kamu Menang di Game Ini',
      team1: datas.firstTeam,
      team2: datas.secondTeam,
      picture1: datas.firstTeamAvatar,
      picture2: datas.secondTeamAvatar,
      score: <p style={{fontWeight: 'bold'}}>{datas.firstTeamTotalKill?? 0}{' '}<span style={{color: '#DDD8D8', fontSize: '16px', fontWeight: 'normal'}}>Kills</span></p>,
      score1: <p style={{fontWeight: 'bold'}}>{datas.secondTeamTotalKill?? 0}{' '}{' '}<span style={{color: '#DDD8D8', fontSize: '16px', fontWeight: 'normal'}}>Kills</span></p>,
      bg: datas.firstTeam === datas.winnerTeam ? 'linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))' : 'linear-gradient(0deg, rgba(228, 60, 90, 0.3), rgba(228, 60, 90, 0.3))',
      border: '0',
      minHeight:'176px',
      src: datas.firstTeamScreenshot ?? ImageSource('game-result', 'png')
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [dataRound, setDataRound] = useState(winResult);
  const [dataPlayers, setDataPlayer] = useState(data.firstTeamPlayers);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<VirtualElement>();

  const handleCloseMenu = (event: any) => {
    if (anchorRef.current && anchorRef.current.contextElement?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // const handleEditProfile = (event: any) => {
  //   handleCloseMenu(event);
  //   window.location.replace('/account/edit');
  // };

  const handleReport = (event: any) => {
    handleCloseMenu(event)
    window.location.replace('/contact-us/report-cheat')
  }
 
  const GetRoundMatch = useCallback(
    (id: string, roundMatch : number) => {
      dispatch(getScrimDetailGame({matchId:id, round: roundMatch}))
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(getScrimDetail({id: scrim_id}))
    GetRoundMatch(scrim_id, 1)
  },[dispatch])

  const DataTab: TabButtonType[] = [
    {
      id: 1,
      title: 'Game 1',
      isSelected: true,
    },
    {
      id: 2,
      title: 'Game 2',
      isSelected: false,
    },
    {
      id: 3,
      title: 'Game 3',
      isSelected: false,
    },
  ];
  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
          onClick: () => window.history.back(),
        },
        navright: {
          ref: anchorRef,
          variant: 'menu-options',
          bg: 'white',
          isDisable: false,
          onClick: () => {
            setOpen(prevOpen => !prevOpen);
          },
          isExpanded: open,
        },
        isDisable: false,
        title:'Detail Scrim #' + scrim_id,
      }}
      noMargin={true}
      backgroundImage={ImageSource('app.background')}
      showModal={showModal}
      modalContent={
        <Box padding="16px" width="90%">
          <Stack direction="column">
            <NormalText
              margin="10px"
              fontWeight="bold"
              fontSize="16px"
              color="white"
              textAlign="center"
            >
              {teamNo ? data.firstTeam : data.secondTeam}
            </NormalText>
            {dataPlayers.map((team, index: number) => {
            return <PlayerCard key={index} {...{
              playerName: `${team.name?? ''}`,
              playerId: `@${team.nickName?? ''}, Game ID. ${team.gameProviderId}`,
              playerPhoto: team.picture?? '',
              isCaptain: data.firstTeamCaptain === team.name || team.name === data.secondTeamCaptain? true : false,
            }} />;
          })}
            <Stack direction="column">
              <NormalText
                margin="20px"
                color="#FF4D6D"
                textAlign="center"
                onClick={() => setShowModal(false)}
              >
                Tutup
              </NormalText>
            </Stack>
          </Stack>
        </Box>
      }
      childrens={
        <PopupMenu
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="top-end"
          style={{ top: 60, left: 'none' }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right top',
              }}
            >
              <MenuContainer>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuLists
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItems>Bagikan Scrim</MenuItems>
                    <MenuSeparator />
                    <MenuItems onClick={handleReport}>Laporkan Pertandingan</MenuItems>
                  </MenuLists>
                </ClickAwayListener>
              </MenuContainer>
            </Grow>
          )}
        </PopupMenu>
      }
    >
      {/* {data?(  */}
      <HeaderMatchDetails
        headerBackground={ImageSource('card.background')}
        teamLeft={{
          teamPosition: 'left',
          teamLogo: data.firstTeamAvatar,
          teamName: data.firstTeam,
          teamSchool: data.firstTeamSchool,
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
          onWaButtonClick: () => {
            window?.open(`https://wa.me/`+data.firstTeamCaptainPhoneNumber, '_blank')?.focus();
          },
          onClick: ()=> {
            setTeamNo(true)
            setShowModal(true)
            setDataPlayer(data.firstTeamPlayers)
          },

          teamPhotos:
            data.firstTeamPlayers.map((team)=> {
              return team.picture
            })
        }}
        teamRight={{
          teamPosition: 'right',
          teamLogo: data.secondTeamAvatar,
          teamName: data.secondTeam,
          teamSchool: data.secondTeamSchool,
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
          onWaButtonClick: () => {
            window?.open(`https://wa.me/`+data.secondTeamCaptainPhoneNumber, '_blank')?.focus();
          },
          onClick: ()=> {
            setTeamNo(false)
            setShowModal(true)
            setDataPlayer(data.secondTeamPlayers)
          },
          teamPhotos:
          data.secondTeamPlayers && 
          data.secondTeamPlayers.map((team)=> {
            return team.picture
          })
        }}
        matchResult={{
          matchType: 'Best Of 3',
          score: `${data.firstTeamScore?? 0} : ${data.secondTeamScore?? 0}` ,
          status: 'WIN'
        }}
        styled={{
          marginTop: '0px',
          padding: '0px',
          alignSelf: 'center',
          width: '100%',
        }}
      />
      {/* )} */}
      <TabMenu
        data={DataTab}
        onSelected={tab => {
          GetRoundMatch(scrim_id, tab.id!!)
          tab.id === 1 ? setDataRound(winResult) : setDataRound(loseResults);

        }}
      />
      <Box width="100%">
        <Stack direction="column" margin="0px 16px 0px 16px">
          {dataRound.map((match: ScrimWinLoseProps, index: number) => {
            return <CardScrimWinLose key={index} {...match} />;
          })}
        </Stack>
      </Box>
    </AppLayout>
  );
};

export default RoundFill;
