import { MatchesResponse, MatchDetail } from './matchProps';
import { fetchMatchList, fetchMatchDetail } from './matchServices';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface MatchState {
  matchList: MatchesResponse | undefined;
  matchDetail?: MatchDetail;
  popupMatchDetailStatus: {
    isShown: boolean;
    matchId: string;
  };
  status: {
    matchList: 'idle' | 'loading' | 'succeeded' | 'failed';
    matchDetail: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
  error: {
    matchList: string | undefined | null;
    matchDetail: string | undefined | null;
  };
}

const initialState: MatchState = {
  matchList: undefined,
  popupMatchDetailStatus: {
    isShown: false,
    matchId: '',
  },
  status: {
    matchList: 'idle',
    matchDetail: 'idle',
  },
  error: {
    matchList: '',
    matchDetail: '',
  },
};

const matchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    resetMatchList: state => {
      state.matchList = initialState.matchList;
    },
    setPopupMatchDetailStatus: (state, action) => {
      const { isShown, match_id } = action.payload;
      return {
        ...state,
        popupMatchDetailStatus: {
          isShown: isShown,
          matchId: match_id,
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMatchList.pending, (state, _action) => {
        state.status.matchList = 'loading';
      })
      .addCase(fetchMatchList.fulfilled, (state, action) => {
        state.status.matchList = 'succeeded';
        state.matchList = action.payload;
      })
      .addCase(fetchMatchList.rejected, (state, action) => {
        state.status.matchList = 'failed';
        state.error.matchList = action.error.message;
      })

      .addCase(fetchMatchDetail.pending, (state, _action) => {
        state.error.matchDetail = undefined;
        state.status.matchDetail = 'loading';
      })
      .addCase(fetchMatchDetail.rejected, (state, action) => {
        state.error.matchDetail = action.error.message;
        state.status.matchDetail = 'failed';
      })
      .addCase(fetchMatchDetail.fulfilled, (state, action) => {
        state.matchDetail = action.payload;
        state.status.matchDetail = 'succeeded';
      });
  },
});

export const { resetMatchList, setPopupMatchDetailStatus } = matchSlice.actions;
export default matchSlice.reducer;
