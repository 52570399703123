import { Box } from '@mui/material';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import React, { FC } from 'react';

export interface CardNoticeRedProps {
  title?: string;
  content?: string;
}

export const CardNoticeRed: FC<CardNoticeRedProps> = ({ title, content }) => {
  return (
    <Box
      sx={{
        padding: '12px 16px',
        backgroundImage: `url(${ImageSource('promary-glow-bg', 'png')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    >
      <Text variant="label">
        {title}
      </Text>
      <Text variant="subtitle" mt='4px'>
        {content}
      </Text>
    </Box>
  );
};
