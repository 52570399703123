import history from 'modules/history';
import React, { FC, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BottomNavMenuLayout, NavMenuButton, NavMenuLink } from './styles';
import navigations from 'data/navigation.json';

export default NavMenuLink;
export interface IBottomNavMenuContainer {
  bg?: any;
  pos?: 'fixed' | 'absolute' | 'sticky';
  height?: any;
  bRadius?: any;
  boxShadow?: any;
  children?: ReactNode | HTMLAllCollection;
}

export interface INavMenuButton {
  bg?: any;
  isSelected?: any;
  children?: ReactNode;
  onClick?: () => void;
}

export interface INavMenuItem {
  title?: string;
  icon?: 'home' | 'rocket' | 'people-group' | 'person' | string;
  dest?: string;
  isSelected?: boolean;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined;
}

export interface BottomNavMenuProps {
  container?: IBottomNavMenuContainer;
  buttons?: INavMenuButton;
  //items: INavMenuItem[];
  children?: ReactNode;
}

export const BottomNavMenuContainer: FC<IBottomNavMenuContainer> = ({
  bg,
  pos,
  height,
  bRadius,
  boxShadow,
  children,
}) => (
  <BottomNavMenuLayout bg={bg} pos={pos} height={height} bRadius={bRadius} boxShadow={boxShadow}>
    {children}
  </BottomNavMenuLayout>
);

export const NavMenuItem: FC<INavMenuItem> = ({
  dest = '',
  title,
  icon,
  isSelected,
  component,
}) => (
  <NavMenuLink to={dest} isSelected={isSelected} title={title} icon={icon} component={component} />
);

export const BottomNavMenu: FC<BottomNavMenuProps> = ({ container, buttons, children }) => {
  const refLink = React.createRef<HTMLAnchorElement>();
  const urlLocation = history.location.pathname;
  const clickMenuHandle = (url: string) => {
    if (history.location.pathname !== url) {
      if (refLink.current) {
        refLink.current.click();
      }
      history.push(url);
    }
  };

  return (
    <BottomNavMenuContainer bg={container?.bg} pos={container?.pos} height={container?.height}>
      {children}
      {navigations.map((data: any) => {
        const isSelected =
          (urlLocation || '') === data.dest ||
          (data.paths.length > 0 && data.paths.includes(urlLocation))
            ? true
            : false;
        return (
          <NavMenuButton
            key={data.dest}
            bg={buttons?.bg}
            isSelected={isSelected}
            onClick={() => clickMenuHandle(data?.dest?.toString() ?? '/#')}
          >
            <NavMenuLink
              ref={refLink}
              key={data.dest}
              to={data?.dest ?? '/#'}
              isSelected={isSelected}
              title={data?.title}
              icon={data?.icon}
            />
          </NavMenuButton>
        );
      })}
    </BottomNavMenuContainer>
  );
};
