import TabMenu from './navigation/TournamentNav';
import { Skeleton } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import navigationMenu from 'data/navigationMenu.json';
import { Text } from 'design/Text';
import { onClickShare } from 'modules/helpers';
import history from 'modules/history';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { fetchPlayerProfile } from 'redux/account/AccountServices';
import { useAppSelector } from 'redux/hooks';
import { resetMatchList } from 'redux/match/matchReducer';
import { useAppThunkDispatch } from 'redux/store';
import {
  fetchTournamentDetail,
  fetchMyTeamFromTournament,
} from 'redux/tournament/TournamentServices';
import AuthService from 'services/AuthService';

const TournamentDetailPage = () => {
  const location = useLocation<any>();
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const dispatch = useAppThunkDispatch();

  const { gameSlug, tournamentSlug } = useParams<{ gameSlug: string; tournamentSlug: string }>();
  const { playerInfo, myTeam, tournamentDetail, myTeamFromTournament } = useAppSelector(
    ({ account, myTeam, tournament }) => ({
      playerInfo: account?.data,
      myTeam: myTeam?.data?.items,
      tournamentDetail: tournament?.tournamentDetail,
      myTeamFromTournament: tournament?.myTeamFromTournament,
    }),
  );

  const handleFetchPlayerProfile = (username: string) => {
    dispatch(fetchPlayerProfile({ username: username }));
  };
  const handleFetchTournamentDetail = (slug: string) => {
    dispatch(fetchTournamentDetail({ tournamentSlug: slug }));
  };

  const handleFetchMyTeamFromTournament = (tournamentId: string, teamId: string) => {
    dispatch(
      fetchMyTeamFromTournament({
        tournamentId: tournamentId,
        teamId: teamId,
      }),
    );
  };

  const myRole = isAuthenticated
    ? playerInfo?.games?.find(game => game?.game?.urlSlug === gameSlug && game?.team)
    : undefined;

  useEffect(() => {
    if (isAuthenticated && playerInfo) {
      handleFetchPlayerProfile(playerInfo.userName);
    }
  }, []);

  useEffect(() => {
    dispatch(resetMatchList());
    handleFetchTournamentDetail(tournamentSlug);
  }, []);

  useEffect(() => {
    if (isAuthenticated && tournamentDetail && myRole) {
      handleFetchMyTeamFromTournament(tournamentDetail.id, myRole?.team?.id);
    }
  }, [tournamentDetail]);

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('tournament_page_title', { title: tournamentDetail?.title }),
      background: { color: 'neutral700' },
      appBar: {
        logo: false,
        back: {
          to: location.state?.pathname ?? '/explore/tournament',
          hash: location.state?.hash,
          search: location.state?.search,
          state: {
            scrollTarget: location.state?.scrollTarget,
          },
        },
        menu: navigationMenu,
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: t('tournament_page_title', { title: tournamentDetail?.title }),
              handleIconShare: () => handleShare(),
            });
          }
        },
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, [tournamentDetail, location]);

  return (
    <>
      {tournamentDetail?.title ? (
        <Text
          variant="h2"
          sx={{
            lineHeight: '140%',
            letterSpacing: '0.12px',
            p: '14px 16px',
            bgcolor: 'neutral700',
          }}
        >
          {tournamentDetail && tournamentDetail.title}
        </Text>
      ) : (
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ mx: '16px', py: '14px', height: 33, width: '60%' }}
        />
      )}

      <TabMenu
        hash={history.location.hash || '#tentang'}
        isAuthenticated={isAuthenticated}
        tournamentDetail={tournamentDetail}
        myTeamFromTournament={myTeamFromTournament}
        playerInfo={playerInfo}
        myRole={myRole}
        myTeam={myTeam}
        gameSlug={gameSlug}
        tournamentSlug={tournamentSlug}
      />
    </>
  );
};

export default TournamentDetailPage;
