import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export interface GameOptions {
  id: string;
  name: string;
  urlSlug: string;
  release: boolean;
  iconUrl: string;
  avatarUrl: string;
  minScrimRoster: number;
  minMember: number;
  minTeamMember: number;
  maxScrimRoster: number;
  maxMember: number;
  maxTeamMember: number;
  teamCount?: number;
  tournamentCount?: number;
  scrimCount?: number;
}

export interface GamePagedResponse<T> {
  pageIndex: number;
  pageSize: number;
  search?: string;
  count: number;
  totalCount: number;
  totalPages: number;
  items: T;
}

export interface GameListState {
  data: GamePagedResponse<Array<GameOptions>>;
  game: GameOptions | null;
  gameOption: GamePagedResponse<GameOptions[]> | undefined | null;
  gameTeamList: GamePagedResponse<Array<GameOptions>> | null;
  loading: boolean;
  errors: {
    data: string | undefined | null;
    game: string | undefined | null;
    gameTeamList: { isAxiosError: boolean; data: any } | any;
    gameOption: string | undefined | null;
  };
  status: {
    data: 'idle' | 'loading' | 'succeeded' | 'failed';
    game: 'idle' | 'loading' | 'succeeded' | 'failed';
    gameTeamList: 'idle' | 'loading' | 'succeeded' | 'failed' | 'error';
    gameOption: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
}

const initialState: GameListState = {
  data: {
    pageIndex: 0,
    pageSize: 0,
    search: '',
    count: 0,
    totalCount: 0,
    totalPages: 0,
    items: [
      {
        id: '',
        name: '',
        avatarUrl: '',
        iconUrl: '',
        minMember: 0,
        minScrimRoster: 0,
        maxScrimRoster: 0,
        maxMember: 0,
        release: false,
        urlSlug: '',
        teamCount: 0,
        tournamentCount: 0,
        scrimCount: 0,
        maxTeamMember: 0,
        minTeamMember: 0,
      },
    ],
  },
  game: null,
  gameTeamList: null,
  gameOption: null,
  loading: false,
  errors: {
    data: null,
    game: null,
    gameTeamList: null,
    gameOption: null,
  },
  status: {
    data: 'idle',
    game: 'idle',
    gameTeamList: 'idle',
    gameOption: 'idle',
  },
};

export const fetchGameDetail = createAsyncThunk(
  'game/game-detail',
  async ({ gameSlug, version }: { gameSlug: string; version?: string }) => {
    const url = `/${version ? `v${version}/` : ''}game/${gameSlug}`;
    const res = await ApiService.get<GameOptions>(url);
    return res.data;
  },
);

export const fetchGameOptions = createAsyncThunk(
  'game/game-option-list',
  async ({
    pageIndex,
    pageSize,
    search,
    version,
  }: {
    pageIndex: string | number;
    pageSize: string | number;
    search?: string;
    version?: string;
  }) => {
    const url = `/${version ? `v${version}/` : ''}game/options/${pageIndex}/${pageSize}${
      search ? '?search=' + search : ''
    }`;
    const res = await ApiService.get<GamePagedResponse<GameOptions[]>>(url);
    return res.data;
  },
);

export const getGameTeamListService = createAsyncThunk(
  'game/game-team-list',
  async (p: { playerSlug: string; pageIndex: number; pageSize: number; search?: string }) => {
    try {
      const url = `team/player/game/${p.playerSlug}/${p.pageIndex}/${p.pageSize}/${
        p.search ? `?search=${encodeURIComponent(p.search || '')}` : ''
      }`;
      const res = await ApiService.get<GamePagedResponse<Array<GameOptions>>>(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, errors: error.response.data };
      } else {
        return { isAxiosError: true };
      }
    }
  },
);

const gameListSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    getGameOptions: (
      state,
      action: PayloadAction<{
        pageIndex: number;
        pageSize: number;
        search?: string;
      }>,
    ) => {
      state.loading = true;
      state.data = {
        ...state.data,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        search: action.payload.search,
      };
    },
    successGetGameOptions: (state, action) => {
      state.loading = false;
      state.status.data = 'succeeded';
      state.data = action.payload;
    },
    failedGetGameOptions: (state, action) => {
      state.status.data = 'failed';
      state.errors.data = action.payload.error;
    },
    resetGameOptions: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGameDetail.pending, (state, _action) => {
        state.status.game = 'loading';
      })
      .addCase(fetchGameDetail.fulfilled, (state, action) => {
        state.status.game = 'succeeded';
        state.game = action.payload;
      })
      .addCase(fetchGameDetail.rejected, (state, action) => {
        state.status.game = 'failed';
        state.errors.game = action.error.message;
      })

      .addCase(fetchGameOptions.pending, (state, _action) => {
        state.status.gameOption = 'loading';
      })
      .addCase(fetchGameOptions.fulfilled, (state, action) => {
        state.status.gameOption = 'succeeded';
        state.gameOption = action.payload;
      })
      .addCase(fetchGameOptions.rejected, (state, action) => {
        state.status.gameOption = 'failed';
        state.errors.gameOption = action.error.message;
      })

      .addCase(getGameTeamListService.pending, (state, _action) => {
        state.status.gameTeamList = 'loading';
      })
      .addCase(getGameTeamListService.fulfilled, (state, action) => {
        if (
          (action.payload as { isAxiosError: boolean; errors: any })?.isAxiosError &&
          (action.payload as { isAxiosError: boolean; errors: any })?.errors
        ) {
          state.status.gameTeamList = 'error';
          state.errors.gameTeamList = action.payload as { isAxiosError: boolean; data: any };
        } else {
          state.status.gameTeamList = 'succeeded';
          state.gameTeamList = action.payload as GamePagedResponse<Array<GameOptions>>;
        }
      })
      .addCase(getGameTeamListService.rejected, (state, action) => {
        state.status.gameTeamList = 'failed';
        state.errors.gameTeamList = action.error.message;
      });
  },
});

export const { getGameOptions, successGetGameOptions, failedGetGameOptions, resetGameOptions } =
  gameListSlice.actions;

export default gameListSlice.reducer;
