import {
  TournamentParticipantAwardsPlayerResponse,
  TournamentParticipantAwardsTeamResponse,
  TournamentParticipantAwardsFrameResponse,
  TournamentParticipantAwardsPlayerHighlightedResponse,
  TournamentParticipantAwardsTeamHighlightedResponse,
} from './AwardProps';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export const fetchTournamentParticipantAwardsPlayer = createAsyncThunk(
  'tournament-participant/awards/player',
  async ({ playerId }: { playerId: string }) => {
    const url = `tournament-participant/awards/player/${playerId}`;
    const res = await ApiService.get<TournamentParticipantAwardsPlayerResponse>(url);
    return res.data;
  },
);

export const fetchTournamentParticipantAwardsTeam = createAsyncThunk(
  'tournament-participant/awards/team',
  async ({ teamId }: { teamId: string }) => {
    const url = `tournament-participant/awards/team/${teamId}`;
    const res = await ApiService.get<TournamentParticipantAwardsTeamResponse>(url);
    return res.data;
  },
);

export const fetchTournamentParticipantAwardsFrame = createAsyncThunk(
  'tournament-participant/awards/frame',
  async () => {
    const url = `/tournament-participant/awards/frame/`;
    const res = await ApiService.get<TournamentParticipantAwardsFrameResponse>(url);

    return res.data;
  },
);

export const fetchTournamentParticipantAwardsPlayerHighlighted = createAsyncThunk(
  'tournament-participant/awards/player/highlighted',
  async ({ playerId }: { playerId?: string }) => {
    const url = `/tournament-participant/awards/player/${playerId}/highlighted`;
    const res = await ApiService.get<TournamentParticipantAwardsPlayerHighlightedResponse>(url);

    return res.data;
  },
);

export const fetchTournamentParticipantAwardsTeamHighlighted = createAsyncThunk(
  'tournament-participant/awards/team/highlighted',
  async ({ teamId }: { teamId?: string }) => {
    const url = `/tournament-participant/awards/team/${teamId}/highlighted`;
    const res = await ApiService.get<TournamentParticipantAwardsTeamHighlightedResponse>(url);

    return res.data;
  },
);
