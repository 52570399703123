import { Stack } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import React, { memo, useCallback } from 'react';

interface JoinModalProps {
  openJoinOtherModal: boolean;
  handleCloseJoinOtherModal: () => void;
  handleJoinOther: () => void;
  latestTeamJoinName?: string;
  nowTeamJoinName?: string;
  isTeamInviting: boolean;
}

const JoinOtherModal = ({
  openJoinOtherModal,
  handleCloseJoinOtherModal,
  handleJoinOther,
  latestTeamJoinName,
  nowTeamJoinName,
  isTeamInviting,
}: JoinModalProps) => {
  const JoinCondition = useCallback(() => {
    if (isTeamInviting) {
      return (
        <Text sx={{ color: 'neutral300', textAlign: 'left', mb: '8px', width: '100%' }}>
          Dengan mengirimkan request join ke tim{' '}
          <b style={{ color: 'neutral200' }}>{nowTeamJoinName}</b>, kamu telah setuju untuk{' '}
          <b style={{ color: '#D02B20' }}>menolak</b> undangan join dari tim{' '}
          <b style={{ color: 'neutral200' }}>{latestTeamJoinName}</b>.
        </Text>
      );
    }
    return (
      <Text sx={{ color: 'neutral300', textAlign: 'left', mb: '8px', width: '100%' }}>
        Dengan mengirimkan request join ke tim{' '}
        <b style={{ color: 'neutral200' }}>{nowTeamJoinName}</b>, kamu telah setuju untuk{' '}
        <b style={{ color: '#D02B20' }}>membatalkan</b> request join dengan tim{' '}
        <b style={{ color: 'neutral200' }}>{latestTeamJoinName}</b>.
      </Text>
    );
  }, [isTeamInviting]);

  return (
    <Modal show={openJoinOtherModal}>
      <InfoModal
        cta={
          <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ width: '100%' }}>
            <SecondaryButton onClick={handleCloseJoinOtherModal}>Batal</SecondaryButton>
            <PrimaryButton onClick={handleJoinOther}>Konfirmasi Join</PrimaryButton>
          </Stack>
        }
      >
        <Text variant="h2" sx={{ width: '100%', textAlign: 'left', mb: '8px' }}>
          Join Team
        </Text>
        <JoinCondition />
      </InfoModal>
    </Modal>
  );
};

export default memo(JoinOtherModal);
