import PlayerCard from 'components/cards/PlayerCard';
import AppLayout from 'components/layouts/AppLayout';
import { Stack } from 'components/styled/layout.styled';
import { STATUS } from 'literals';
import { ImageSource } from 'modules/assetpath';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { fetchPlayerProfile, getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addPlayersAction } from 'redux/scrim/ScrimDraftReducer';
import { useAppThunkDispatch } from 'redux/store';
import {
  resetTeamMemberApprovedAction,
  getTeamMemberApprovedAction,
  Player,
} from 'redux/team/TeamMemberReducer';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';

const ChoosePlayer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  var myCurrentTeam = getMyCurrentTeamFromSession();

  const thunkDispatch = useAppThunkDispatch();
  const playerProfileThunk = fetchPlayerProfile({
    username: getPlayerProfileFromSession().userName,
  });
  const fetchAccountProfile = () => {
    thunkDispatch(playerProfileThunk)
      .unwrap()
      .then(res => {
        res && res.id;
      });
  };
  React.useEffect(() => {
    fetchAccountProfile();
  }, [thunkDispatch]);
  var { playerInvited, maxChoosePlayer, teamMembers, myTeam, status, isLoading } = useAppSelector(
    ({ scrimDraft, teamMember, myTeam }) => ({
      playerInvited: scrimDraft?.playerList,
      maxChoosePlayer: scrimDraft?.gameSelected?.maxScrimRoster - 1,
      status: scrimDraft?.status,
      teamMembers: teamMember?.data?.items,
      myTeam: myTeam?.data?.items?.find(
        v =>
          v.urlSlug ===
          myCurrentTeam?.find(
            v => v?.gameId === scrimDraft?.gameSelected?.id && v?.level?.code === 1,
          )?.urlSlug,
      ),
      isLoading: teamMember?.status?.data === 'loading',
    }),
  );

  useEffect(() => {
    Promise.all([
      dispatch(resetTeamMemberApprovedAction()),
      dispatch(
        getTeamMemberApprovedAction({ teamSlug: myTeam?.urlSlug!!, pageIndex: 1, pageSize: 50 }),
      ),
    ]);
  }, [dispatch]);

  const savePlayer = React.useCallback(
    (player: Player[]) => dispatch(addPlayersAction({ playerList: player })),
    [dispatch],
  );

  const [selectedPlayers, setDataPlayers] = useState(playerInvited as Player[]);
  const handlePlayerClick = (player: Player) => {
    const isChecked =
      !selectedPlayers?.find(e => {
        return e.id === player.id;
      }) ?? false;
    let newData: Player[] = [];
    if (selectedPlayers?.length < maxChoosePlayer) {
      if (isChecked) {
        newData = [...selectedPlayers];
        if (
          !newData.find(e => {
            return e.id === player.id;
          })
        ) {
          newData.push(player);
        } else {
          newData = selectedPlayers.filter(el => el.id !== player.id);
        }
      } else {
        newData = selectedPlayers.filter(el => el.id !== player.id);
      }
      setDataPlayers(newData);
    } else {
      if (!isChecked) {
        newData = [...selectedPlayers];
        if (
          newData.find(e => {
            return e.id === player.id;
          })
        ) {
          newData = selectedPlayers.filter(el => el.id !== player.id);
          setDataPlayers(newData);
        }
      }
    }
  };
  const handleClickBack = () => {
    history.push('/create-scrim');
  };
  const handleClickSave = () => {
    savePlayer(selectedPlayers);
    if (status === STATUS.SUCCESS) {
      setTimeout(() => {
        history.push('/create-scrim');
      }, 100);
    }
  };
  const checkPlayerState = (playerSelected: any) => {
    if (selectedPlayers?.length >= maxChoosePlayer) {
      return selectedPlayers?.find(e => {
        return e.id === playerSelected.id;
      })
        ? false
        : true;
    } else {
      return false;
    }
  };
  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
          onClick: handleClickBack,
        },
        navright: {
          bg:
            selectedPlayers?.length ?? 0 > 0
              ? 'linear-gradient(131.42deg, #d51f40 46.48%, #e24134 62.51%, #e8542e 74.36%, #f37024 83.78%)'
              : '#969393',
          children: 'Simpan',
          variant: 'button',
          onClick: selectedPlayers?.length ?? 0 > 0 ? handleClickSave : () => null,
        },
        isDisable: false,
        title: `Pilih Player (${selectedPlayers.length ?? 0}/${maxChoosePlayer ?? 0})`,
      }}
      backgroundImage={ImageSource('app.background')}
      // searchBar={{
      //   id: 'scrim-search-player',
      //   placeholder: 'Cari player...',
      // }}
    >
      <Stack direction="column" align="center" margin="8px 0px 0px 0px">
        {isLoading === false &&
          teamMembers &&
          teamMembers.map((data: any) => {
            if (data.level.value !== 'Captain')
              return (
                <PlayerCard
                  onIconRightClick={() => {
                    handlePlayerClick(data.player);
                  }}
                  disable={checkPlayerState(data) ?? false}
                  key={data.player.id}
                  urlSlug={data.player.urlSlug}
                  playerId={`@${data.player.nickName}, GAME ID: ${data.player.gameProviderId}`}
                  playerName={data.player.name}
                  playerPhoto={data.player.picture}
                  margin="8px 0px 0px 0px"
                  iconRight={
                    selectedPlayers?.find(e => {
                      return e.id === data.player.id;
                    }) ?? false
                      ? '/media/icons/checklist.active.svg'
                      : '/media/icons/checklist.inactive.svg'
                  }
                />
              );
            else return <></>;
          })}
      </Stack>
    </AppLayout>
  );
};

export default ChoosePlayer;
