import { Theme, Drawer as MUIDrawer, DrawerProps, Box, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { IconButton } from 'design/Buttons/IconButton';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

export interface CustomDrawerProps extends DrawerProps {
  theme?: Theme;
  children?: ReactNode;
  position?: DrawerProps['anchor'];
  open?: boolean;
  onClose?: () => void;
  height?: string;
  padding?: string;
  margin?: string;
  paperSx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
}

export const DrawerPrimary = ({
  title,
  open,
  onClose,
  height,
  padding,
  margin,
  position,
  children,
  paperSx,
  titleSx,
}: CustomDrawerProps) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const boxRef = useRef<any>();
  const boxChildRef = useRef<any>();

  useEffect(() => {
    setIsScrollable(boxChildRef.current?.clientHeight > boxRef.current?.clientHeight);
  }, [open]);

  return (
    <MUIDrawer
      anchor={position}
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          p: padding ?? '12px 16px',
          m: margin ?? '0 auto',
          backgroundImage: 'none',
          bgcolor: 'neutral700',
          maxHeight:
            height || (position === 'top' || 'bottom' ? 'calc(100% - (56px + 24px))' : 'auto'),
          borderRadius: position === 'bottom' ? '12px 12px 0 0' : '',
          justifyContent: 'space-between',
          maxWidth: '500px',
          borderTop: '1px solid #2E3033',
          ...paperSx,
        },
      }}
      transitionDuration={{ enter: 350, exit: 250 }}
    >
      <Stack direction="row" alignItems="center" sx={{ mb: '16px', ...titleSx }}>
        <IconButton
          icon={IconSource('ic-close-white2')}
          onClick={onClose}
          sx={{ p: 0, background: 'transparent', border: 0, mr: '12px' }}
        />
        <Text variant="h3">{title}</Text>
      </Stack>

      <Box
        ref={boxRef}
        sx={theme => ({
          height: 'inherit',
          mb: '0px',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: `${theme.palette.neutral500} #26211E`,
          boxShadow: isScrollable ? `inset 0px -38px 20px -20px ${theme.palette.neutral700}` : '',
          '&::placeholder': {
            color: theme.palette.neutral500,
          },
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#26211E',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.neutral500,
            borderRadius: '10px',
          },
        })}
      >
        <Box ref={boxChildRef} sx={{ pr: isScrollable ? '6px' : '' }}>
          {children}
        </Box>
      </Box>
    </MUIDrawer>
  );
};
