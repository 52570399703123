import { TeamSchoolPlayers } from './TeamResponse';
import { fetchPlayerSchool } from './TeamService';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export interface TeamMemberResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  totalCount: number;
  totalPages: number;
  items: TeamMember[];
}

export interface TeamMember {
  teamId: string;
  level: Level;
  status: Level;
  type: Level;
  joinAt: Date;
  player: Player;
}

export interface Level {
  key: string;
  value: string;
}

export interface Player {
  id: string;
  urlSlug: string;
  name: string;
  nickName?: string;
  gameProviderId: string;
  picture?: string;
}
export interface ErrorResponse {
  errors: Errors;
  traceId: string;
  type: string;
  title: string;
  status: number;
}

export interface Errors {
  playerId: ErrorItem[];
}

export interface ErrorItem {
  code: string;
  message: string;
  formatedValue: FormatedValue;
}
export interface FormatedValue {
  playerId: string;
  gameId: string;
}

export interface TeamMemberState {
  data: TeamMemberResponse | any;
  playerSchool: TeamSchoolPlayers | undefined;
  error: {
    data: string | undefined | null | {};
    playerSchool: string | undefined | null;
    delegateCaptain: string | undefined | null;
  };
  status: {
    data: 'idle' | 'loading' | 'succeeded' | 'failed';
    playerSchool: 'idle' | 'loading' | 'succeeded' | 'failed';
    delegateCaptain: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
}

const initialState: TeamMemberState = {
  data: {
    pageIndex: 0,
    pageSize: 0,
    count: 0,
    totalCount: 0,
    totalPages: 0,
    items: [],
  },
  playerSchool: undefined,
  error: {
    data: null,
    playerSchool: null,
    delegateCaptain: null,
  },
  status: {
    data: 'idle',
    playerSchool: 'idle',
    delegateCaptain: 'idle',
  },
};

export const exitFromTeam = createAsyncThunk(
  'team-member/quit',
  async ({ teamId, playerId }: { teamId?: string; playerId?: string }) => {
    try {
      const url = `/team-member/quit`;
      const res = await ApiService.delete(url, {
        data: {
          teamId,
          playerId,
        },
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const requestJoinTeam = createAsyncThunk(
  'team-member/join',
  async ({ teamId, playerId, version }: { teamId: string; playerId: string; version?: string }) => {
    try {
      const url = `/${version ? `v${version}/` : ''}team-member/join`;
      const res = await ApiService.post(url, {
        teamId: teamId,
        playerId: playerId,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, errors: error.response.data };
      } else {
        return { isAxiosError: true, errors: error };
      }
    }
  },
);

export const editDelegateCaptain = createAsyncThunk(
  'team-member/delegate-captain',
  async ({ teamId, playerId }: { teamId?: string; playerId?: string }) => {
    try {
      const url = `/team-member/delegate-captain`;
      const res = await ApiService.put(url, {
        teamId: teamId,
        playerId: playerId,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

const TeamMemberSlice = createSlice({
  name: 'TeamMember',
  initialState: initialState,
  reducers: {
    getTeamMemberApprovedAction: (
      state,
      action: PayloadAction<{
        teamSlug: string;
        pageIndex: number;
        pageSize: number;
        version?: string;
      }>,
    ) => {
      state.status.data = 'loading';
      state.data = {
        ...state.data,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
      };
    },
    resetTeamMemberApprovedAction: () => initialState,
    resetTeamMemberError: state => {
      state.error = {
        ...initialState.error,
      };
    },
    successGetTeamMemberApprovedAction: (state, action) => {
      state.status.data = 'succeeded';
      state.data = action.payload;
    },
    failedGetTeamMemberApprovedAction: (state, action) => {
      state.error.data = action.payload.error;
      // state.errorCode = action.payload.error.status;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(exitFromTeam.pending, state => {
        state.status.data = 'loading';
      })
      .addCase(exitFromTeam.fulfilled, state => {
        state.status.data = 'succeeded';
      })

      .addCase(exitFromTeam.rejected, (state, action) => {
        state.status.data = 'failed';
        state.error.data = action.error.message;
      })

      .addCase(fetchPlayerSchool.pending, state => {
        state.status.playerSchool = 'loading';
      })
      .addCase(fetchPlayerSchool.rejected, (state, action) => {
        state.status.playerSchool = 'failed';
        state.error.playerSchool = action.error.message;
      })
      .addCase(fetchPlayerSchool.fulfilled, (state, action) => {
        state.status.playerSchool = 'succeeded';
        state.playerSchool = action.payload;
      })

      .addCase(requestJoinTeam.pending, state => {
        state.status.data = 'loading';
      })
      .addCase(requestJoinTeam.fulfilled, (state, action) => {
        if (action.payload?.isAxiosError && action.payload?.errors) {
          state.status.data = 'failed';
          state.error.data = action.payload.errors as ErrorResponse;
        } else {
          if (action.payload) {
            state.status.data = 'succeeded';
            state.data = action.payload as any;
          }
        }
      })
      .addCase(requestJoinTeam.rejected, (state, action) => {
        state.status.data = 'failed';
        state.error.data = action.error.message;
      })

      .addCase(editDelegateCaptain.pending, state => {
        state.status.delegateCaptain = 'loading';
      })
      .addCase(editDelegateCaptain.fulfilled, state => {
        state.status.delegateCaptain = 'succeeded';
      })

      .addCase(editDelegateCaptain.rejected, (state, action) => {
        state.status.delegateCaptain = 'failed';
        state.error.delegateCaptain = action.error.message;
      });
  },
});

export const {
  getTeamMemberApprovedAction,
  resetTeamMemberError,
  resetTeamMemberApprovedAction,
  successGetTeamMemberApprovedAction,
  failedGetTeamMemberApprovedAction,
} = TeamMemberSlice.actions;

export default TeamMemberSlice.reducer;
