import RosterDrawer from '../Drawer/RosterDrawer';
import FeedTournament from '../Section/FeedTournament';
import { Stack, Box } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { LayoutContext } from 'context/LayoutContext';
import navigationMenu from 'data/navigationMenu.json';
import { Text } from 'design/Text';
import _ from 'lodash';
import { onClickShare } from 'modules/helpers';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { fetchPublicProfile, resetPublicProfile } from 'redux/publicProfile/slice';
import { useAppThunkDispatch } from 'redux/store';
import { fetchTournamentParticipantPlayer } from 'redux/tournament/TournamentServices';

const PublicProfileStatisticTournament = () => {
  const { t } = useTranslation();
  const { playerSlug } = useParams<{ playerSlug: string }>();
  const { setValue } = useContext(LayoutContext);
  const dispatch = useAppThunkDispatch();
  const history = useHistory();

  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [openDrawerRoster, setIsOpenDrawerRoster] = useState(false);
  const [dataDrawerRoster, setDataDrawerRoster] = useState<any>([]);

  const {
    playerInfo,
    publicProfile,
    tournamentParticipant,
    publicProfileStatus,
    tournamentParticipantStatus,
  } = useAppSelector(({ account, publicProfile, tournament }) => ({
    playerInfo: account?.data,
    publicProfile: publicProfile?.publicProfile,
    tournamentParticipant: tournament?.tournamentParticipantPlayer?.events,
    publicProfileStatus: publicProfile?.status?.publicProfile,
    tournamentParticipantStatus: tournament?.status?.tournamentParticipantPlayer,
  }));

  const handleFetchTournamentParticipant = (playerId: string) => {
    dispatch(fetchTournamentParticipantPlayer({ playerId: playerId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchPublicProfile = (playerSlug: string) => {
    dispatch(fetchPublicProfile({ playerSlug: playerSlug }))
      .unwrap()
      .then(res => {
        if (res) {
          handleFetchTournamentParticipant(res.id);
        }
      })
      .catch(error => setError(error));
  };

  const handleResetPublicProfile = () => {
    if (playerSlug !== playerInfo?.userName) {
      dispatch(resetPublicProfile());
    }
  };

  const handleOpenDrawerRoster = () => {
    setIsOpenDrawerRoster(!openDrawerRoster);
  };

  const handleTournamentParticipantRank = (rank: String) => {
    switch (rank) {
      case '1':
        return 'Champion';
      case '2':
        return '2nd Place';
      case '3':
        return '3rd Place';
      default:
        return 'Playoff';
    }
  };

  const dataTournamentFeed = _.chain(tournamentParticipant)
    .orderBy(['split'], ['desc'])
    .groupBy('split')
    .map((value: any, key: any) => ({
      title: key,
      data: value,
      splitStart: value?.find((data: any) => data).splitStart,
      splitEnd: value?.find((data: any) => data).splitEnd,
    }))
    .value();

  const handleBack = () => {
    if (history.action === 'POP') {
      return '/';
    }
    return 'goback';
  };

  const handleButtonRoster = (tournament: any) => {
    setDataDrawerRoster(tournament);
    setIsOpenDrawerRoster(true);
  };

  useEffect(() => {
    Promise.all([handleResetPublicProfile(), handleFetchPublicProfile(playerSlug)]);
  }, []);

  const handleLoading = (status: boolean) => {
    if (status) return !Boolean(publicProfile && tournamentParticipant);
    return false;
  };

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Profile Player Statistic Tournament' }),
      background: {
        color: 'neutral800',
      },
      appBar: {
        logo: false,
        back: {
          to: handleBack(),
        },
        menu: navigationMenu,
        title: (
          <Stack direction="column" sx={{ textAlign: 'center' }}>
            <Text variant="h3" sx={{ fontWeight: 700, letterSpacing: '0.12px' }}>
              {t('participation_tournament_title')}
            </Text>
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '2px' }}>
              {[publicProfile?.firstName ?? '', publicProfile?.lastName ?? ''].join(' ')}
            </Text>
          </Stack>
        ),
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: t('mabar_page_title', { title: 'Profile Player Statistic Tournament' }),
              url: `${window.location.origin}/player/${playerSlug}/tournaments`,
              handleIconShare: () => handleShare(),
            });
          }
        },
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, [playerInfo, publicProfile]);

  return (
    <>
      <Box>
        <Stack>
          <FeedTournament
            dataFeed={dataTournamentFeed}
            handleButtonRoster={handleButtonRoster}
            handleTournamentParticipantRank={handleTournamentParticipantRank}
          />
        </Stack>
        <Box sx={{ textAlign: 'center', p: '16px' }}>
          <Text sx={{ color: 'neutral500' }}>{t('participant_tournament_end_of_list')}</Text>
        </Box>
      </Box>

      <RosterDrawer
        open={openDrawerRoster}
        onClose={handleOpenDrawerRoster}
        onOpen={handleOpenDrawerRoster}
        dataEvent={dataDrawerRoster}
        playerId={playerInfo?.id ?? ''}
        title="TURNAMEN"
        eventStatus={
          dataDrawerRoster?.rank === '' || dataDrawerRoster?.rank === undefined
            ? 'Partisipan'
            : handleTournamentParticipantRank(dataDrawerRoster?.rank)
        }
      />

      <LoadingModal
        show={
          error
            ? true
            : handleLoading(
                publicProfileStatus === 'loading' || tournamentParticipantStatus === 'loading',
              )
        }
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default PublicProfileStatisticTournament;
