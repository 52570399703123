import { MatchCardType } from 'components/cards/MatchCard';
import { TeamCardType } from 'components/cards/TeamCard';
import { ScrimWinLoseProps } from 'components/CardScrimWinLose';
import { ImageSource } from 'modules/assetpath';

export const teamLeft: TeamCardType = {
  teamPosition: 'left',
  teamLogo: ImageSource('team1'),
  teamName: 'Fire Dragon',
  teamSchool: 'SMA 2 Jakarta',
  background: 'linear-gradient(180deg, rgba(49, 193, 81, 0.3) 0%, rgba(49, 193, 81, 0.03) 100%)',
};

export const teamLeft1: TeamCardType = {
  teamPosition: 'left',
  teamLogo: ImageSource('team1'),
  teamName: 'Fire Dragon',
  teamSchool: 'SMA 2 Jakarta',
  variant: 'wrap',
  background: 'linear-gradient(180deg, rgba(49, 193, 81, 0.3) 0%, rgba(49, 193, 81, 0.03) 100%)',
};
export const teamRight: TeamCardType = {
  teamPosition: 'right',
  teamLogo: ImageSource('team2'),
  teamName: 'Astralis G2',
  teamSchool: 'SMA 4 Jakarta',
  variant: 'wrap',
  background: 'linear-gradient(180deg, rgba(213, 31, 64, 0.3) 0%, rgba(213, 31, 64, 0.03) 100%)',
};

export const matchResut: MatchCardType = {
  score: 'W.O',
  status: 'WIN',
  matchType: '21 Mei 2021',
};

export const winResult: ScrimWinLoseProps[] = [
  {
    text: '14 Okt 2021 - 14:30 WIB',
    header: 'Tim Kamu Menang di Game ini   🏆',
    team1: 'Fire Dragon',
    team2: 'Astralis G2',
    score: '0 Kills',
    score1: '0 Kills',
    picture1: '../../../media/teamProfile/LogoTeam.svg',
    picture2: '../../../media/teamProfile/LogoTeam.svg',
    bg: 'linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))',
    border: '0',
    src: '../../../media/images/dummy/game-result.png',
  },
];
