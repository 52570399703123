import {
  MatchHistoryResponse,
  TeamMemberResponse,
  TeamDetailResponse,
} from './TeamProfileResponse';
import { fetchTeamDetail, fetchTeamMember, fetchTeamHistory } from './TeamProfileService';
import { createSlice } from '@reduxjs/toolkit';

export interface TeamProfileState {
  teamDetail: TeamDetailResponse | undefined;
  teamMember: TeamMemberResponse | undefined;
  teamHistory: MatchHistoryResponse | undefined;
  status: {
    teamDetail: 'idle' | 'loading' | 'succeeded' | 'failed' | 'error';
    teamMember: 'idle' | 'loading' | 'succeeded' | 'failed' | 'error';
    teamHistory: 'idle' | 'loading' | 'succeeded' | 'failed' | 'error';
  };
  error: {
    teamDetail: string | object | undefined;
    teamMember: string | object | undefined;
    teamHistory: string | object | undefined;
  };
}

const initialState: TeamProfileState = {
  teamDetail: undefined,
  teamMember: undefined,
  teamHistory: undefined,
  status: {
    teamDetail: 'idle',
    teamMember: 'idle',
    teamHistory: 'idle',
  },
  error: {
    teamDetail: undefined,
    teamMember: undefined,
    teamHistory: undefined,
  },
};

const TeamProfileSlice = createSlice({
  name: 'teamProfile',
  initialState,
  reducers: {
    resetTeamProfile: () => initialState,
    resetTeamDetail: state => {
      state.teamDetail = initialState.teamDetail;
    },
    resetTeamMember: state => {
      state.teamMember = initialState.teamMember;
    },
    resetTeamHistory: state => {
      state.teamHistory = initialState.teamHistory;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamDetail.pending, (state, _action) => {
        state.status.teamDetail = 'loading';
      })
      .addCase(fetchTeamDetail.fulfilled, (state, action) => {
        state.status.teamDetail = 'succeeded';
        state.error.teamDetail = '';
        state.teamDetail = action.payload;
      })
      .addCase(fetchTeamDetail.rejected, (state, action) => {
        state.status.teamDetail = 'failed';
        state.error.teamDetail = action.error;
      })

      .addCase(fetchTeamHistory.pending, (state, _action) => {
        state.status.teamHistory = 'loading';
      })
      .addCase(fetchTeamHistory.fulfilled, (state, action) => {
        state.status.teamHistory = 'succeeded';
        state.error.teamHistory = '';
        state.teamHistory = action.payload;
      })
      .addCase(fetchTeamHistory.rejected, (state, action) => {
        state.status.teamHistory = 'failed';
        state.error.teamHistory = action.error;
      })

      .addCase(fetchTeamMember.pending, (state, _action) => {
        state.status.teamMember = 'loading';
      })
      .addCase(fetchTeamMember.fulfilled, (state, action) => {
        state.status.teamMember = 'succeeded';
        state.error.teamMember = '';
        state.teamMember = action.payload;
      })
      .addCase(fetchTeamMember.rejected, (state, action) => {
        state.status.teamMember = 'failed';
        state.error.teamMember = action.error.message;
      });

    // .addCase(directApproval.pending, (state, _action) => {
    //   state.status = 'loading';
    // })
    // .addCase(directApproval.fulfilled, (state, action) => {
    //   state.status = 'succeeded';
    //   if (action.payload.isAxiosError || _.get(action.payload, 'data.errors') != null) {
    //     state.status = 'error';
    //     state.error =
    //       typeof action.payload.data === 'string'
    //         ? action.payload.data
    //         : parseError(action.payload.data.errors);
    //   }
    // })
    // .addCase(directApproval.rejected, (state, action) => {
    //   state.status = 'failed';
    //   state.error = action.error.message;
    // })

    // .addCase(openApproval.pending, (state, _action) => {
    //   state.status = 'loading';
    // })
    // .addCase(openApproval.fulfilled, (state, action) => {
    //   if (action.payload.isAxiosError || _.get(action.payload, 'data.errors') != null) {
    //     state.status = 'error';
    //     state.error =
    //       typeof action.payload.data === 'string'
    //         ? action.payload.data
    //         : parseError(action.payload.data.errors);
    //   } else {
    //     state.status = 'succeeded';
    //   }
    // })
    // .addCase(openApproval.rejected, (state, action) => {
    //   state.status = 'failed';
    //   state.error = action.error.message;
    // })

    // .addCase(inviteMember.pending, (state, _action) => {
    //   state.status = 'loading';
    // })
    // .addCase(inviteMember.fulfilled, (state, action) => {
    //   if (action.payload.isAxiosError || _.get(action.payload, 'data.errors') != null) {
    //     state.status = 'error';
    //     state.error =
    //       typeof action.payload.data === 'string'
    //         ? action.payload.data
    //         : parseError(action.payload.data.errors);
    //   } else {
    //     state.status = 'succeeded';
    //   }
    // })
    // .addCase(inviteMember.rejected, (state, action) => {
    //   state.status = 'failed';
    //   state.error = action.error.message;
    // })

    // .addCase(rejectInvitation.pending, (state, _action) => {
    //   state.status = 'loading';
    //   state.error = null;
    // })
    // .addCase(rejectInvitation.fulfilled, (state, action) => {
    //   if (action.payload.isAxiosError || _.get(action.payload, 'data.errors') != null) {
    //     state.status = 'error';
    //     state.error =
    //       typeof action.payload.data === 'string'
    //         ? action.payload.data
    //         : parseError(action.payload.data.errors);
    //   } else {
    //     state.status = 'succeeded';
    //   }
    // })
    // .addCase(rejectInvitation.rejected, (state, action) => {
    //   state.status = 'failed';
    //   state.error = action.error.message;
    // });
  },
});

export const { resetTeamProfile, resetTeamDetail, resetTeamMember, resetTeamHistory } =
  TeamProfileSlice.actions;

export default TeamProfileSlice.reducer;
