import styled from 'styled-components';

interface Props {
  size?: string;
  padding?: string;
  margin?: string;
}
export const TextTitle = styled.p<Props>`
  position: relative;
  font-size: ${p => p.size ?? '0px'};
  margin: ${p => p.margin ?? '0px'};
  padding: ${p => p.padding ?? '0px'};
`;

export const TextTitleTurnamen = styled.p`
  position: static;
  width: 103px;
  left: calc(50% - 103px / 2);
  top: 16%;
  bottom: 16%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  /* White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
`;

export const TextTitleScrim = styled.p`
  position: static;
  width: 79px;
  left: calc(50% - 79px / 2);
  top: 16%;
  bottom: 16%;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  /* Gray 01 */

  color: #ddd8d8;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
`;

export const TextPertandinganCsss = styled.p`
  position: static;
  width: 85px;
  height: 20px;
  left: 10px;
  top: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
`;

export const TextTurnamentCss = styled.p`
  position: static;
  width: 64px;
  height: 20px;
  left: 10px;
  top: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #969393;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
`;

export const TextTanggal = styled.a`
  position: static;
  width: 204px;
  height: 11px;
  left: 0px;
  top: 56px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 140%;
  color: #969393;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 46px 95px;
`;

export const TextFrameCss = styled.p`
  position: relative;
  left: calc(40% - 103px / 2);
  top: 16%;
  bottom: 16%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
`;

export const ColumnHeader = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 10px;
  text-align: start;
  flex-direction: column;
  align-self: center flex-start;
`;

export const TextSkorTanding = styled.a`
  position: static;
  width: 56px;
  left: calc(50% - 56px / 2);
  top: 11.54%;
  bottom: 46.15%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const TextTittleTanding = styled.a`
  position: static;
  width: 56px;
  left: calc(50% - 56px / 2);
  top: 53.85%;
  bottom: 11.54%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 146%;
  color: #ddd8d8;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;
export const TextSkorMenang = styled.a`
  position: static;
  width: 56px;
  left: calc(50% - 56px / 2);
  top: 11.54%;
  bottom: 46.15%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const TextTitleMenang = styled.a`
  position: static;
  width: 56px;
  left: calc(50% - 56px / 2);
  top: 53.85%;
  bottom: 11.54%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 146%;
  color: #5fb194;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;
export const TextSkorRasio = styled.a`
  position: static;
  width: 56px;
  left: calc(50% - 56px / 2);
  top: 11.54%;
  bottom: 46.15%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const TextTitleRasio = styled.a`
  position: static;
  width: 56px;
  left: calc(50% - 56px / 2);
  top: 53.85%;
  bottom: 11.54%;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 146%;

  color: #ddd8d8;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const TextSkorRank = styled.a`
  position: static;
  width: 56px;
  left: calc(50% - 56px / 2);
  top: 11.54%;
  bottom: 46.15%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const TextTitleRank = styled.a`
  position: static;
  width: 56px;
  left: calc(50% - 56px / 2);
  top: 53.85%;
  bottom: 11.54%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 146%;
  color: #c6b086;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;
