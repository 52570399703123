import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC } from 'react';

export interface CardInfoType {
  textTitle?: string | undefined;
}

const dataContent: string[] = [
  '1. Pro player yang join MPL tidak diperbolehkan join scrim',
  '2. Semua member yang sudah dipilih ikut scrim harus check in.',
  '3. Check in dimulai 10 menit sebelum jadwal pertandingan dan selambat-lambatnya 10 menit setelah jadwal pertandingan.',
  '4. Setelah semua member yang dipilih sudah check in, akan ada instruksi untuk masuk ke dalam game mobile legends.',
  '5. Tim akan dianggap WO jika tidak menyelesaikan proses check in.',
  '6. Tipe game adalah Custom Mode Draft Pick',
  '7. Selama pertandingan tidak diperbolehkan chat dalam game ataupun pause.',
  '8. Nickname game yang digunakan harus sama dengan nickname game yang didaftarkan ke MABAR.',
  '9. Kapten tim harus menscreenshot hasil pertandingan (Scoreboard: Kill Death Assist) untuk setiap game dan upload ke MABAR selambat-lambatnya 2 jam dari jadwal pertandingan',
  '10. Jika terjadi perselisihan data, hasil pertandingan akan direview oleh MABAR'
];

const CardInfo: FC<CardInfoType> = ({ textTitle }) => {
  return (
    <Box
      background="#373942 60%"
      flexGrow={1}
      padding="12px"
      borderRadius="16px"
      margin="10px 0px 10px 0px"
    >
      <Stack direction="row" style={{ alignItems: 'flex-start' }}>
        <Stack direction="column" align="start">
          <NormalText
            color="#969393"
            fontSize="12px"
            fontWeight="normal"
            margin="0px 0px 0px 0px"
            lineHeight="140%"
          >
            {/* {Peraturan Pertandingan} */}
            {textTitle}
          </NormalText>
          <Box margin="10px 0px 0px 0px">
            <Stack direction="row" align="start">
              <NormalText
                textAlign="left"
                color="#FFFFFF"
                fontSize="12px"
                fontWeight="400"
                margin="5px 0px 0px 0px"
              >
                {dataContent.map((data: string) => {
                  return <p>{data}</p>;
                })}
              </NormalText>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CardInfo;
