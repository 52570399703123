import ButtonSelect, { ButtonSelectProps } from 'components/Buttons/ButtonSelect';
import PlayerCard, { PlayerCardType } from 'components/cards/PlayerCard';
import { Avatar } from 'components/styled/avatar.styled';
import { IconSource } from 'modules/assetpath';
import { motion, AnimatePresence } from 'framer-motion';
import React, { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { StoreState } from 'types';
import { NormalText } from 'components/styled/text.styled';
import { Player } from 'redux/team/TeamMemberReducer';
import { SelectContainer } from './styles';

export interface ChoosePlayerTeamProps {
  btnOptions?: ButtonSelectProps;
  showArrow?: boolean;
  captain: Player;
  playerList?: Player[];
  maxPlayer?: number | undefined;
  children?: ReactNode;
  onRemoveAction?: any;
  validationType?: 'ERROR' | 'WARNING' | 'INFO';
  validationText?: string;
  onRemoveClick?: (player?: PlayerCardType) => void;
}

export function ChoosePlayerTeam({
  btnOptions,
  showArrow,
  captain,
  playerList,
  maxPlayer = 5,
  children,
  onRemoveAction,
  validationType,
  validationText,
  onRemoveClick,
}: ChoosePlayerTeamProps) {
  const [collapse, setCollapse] = useState(true);
  const [playerData, setPlayerData] = useState(playerList);
  const [arrow, setArrow] = useState(showArrow);
  const [memberIsMax, setMemberIsMax] = useState(playerData?.length! > maxPlayer - 1);
  // const status = useSelector<StoreState>(({ scrimChoosePlayer }) => scrimChoosePlayer.status);
  const dispatch = useDispatch();
  const removePlayer = React.useCallback(
    (player: Player) => dispatch(onRemoveAction(player)),
    [dispatch],
  );
  return (
    <SelectContainer>
      <ButtonSelect
        isDisable={memberIsMax}
        onClick={memberIsMax ? () => null : btnOptions?.onClick}
        btnBackground={btnOptions?.btnBackground}
        btnHeight={btnOptions?.btnHeight}
        btnPadding={btnOptions?.btnPadding}
        btnText={btnOptions?.btnText}
        btnPosition={btnOptions?.btnPosition}
        btnRadius={btnOptions?.btnRadius}
        btnWidth={btnOptions?.btnWidth}
      />
      {arrow && (
        <Avatar
          alt="Arrow Icon"
          onClick={() => setCollapse(!collapse)}
          background="transparent"
          margin="4px"
          width="24px"
          height="24px"
          borderRadius="8px"
          padding="0px"
          src={collapse ? IconSource('ic-caret-up-dark') : IconSource('ic-caret-down-gray')}
          css={{ float: 'right' }}
        />
      )}
      {children}
      <PlayerCard
        margin={
          btnOptions?.btnBackground === 'transparent' ? '12px 0px 0px 0px' : '16px 0px 0px 0px'
        }
        onClick={() => {}}
        playerId={captain.id}
        playerName={captain.name}
        playerPhoto={captain.picture}
        hasCheckIn={false}
        isCaptain
        urlSlug={captain.urlSlug}
      />
      <AnimatePresence initial={false}>
        {collapse && (
          <motion.section
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {playerData !== undefined &&
              playerData.map((data, idx) => {
                return (
                  <PlayerCard
                    key={idx}
                    onClick={() => {}}
                    playerId={`@${data.nickName ?? ''}, GAME ID: ${data.gameProviderId ?? '-'}`}
                    playerName={data.name}
                    playerPhoto={data?.picture}
                    isCaptain={false}
                    iconRight={IconSource('ic-close')}
                    margin="8px 0px 0px 0px"
                    hasCheckIn={false}
                    onIconRightClick={p => {
                      const temp = [...playerData];
                      temp.splice(idx, 1);
                      removePlayer(data);
                      setPlayerData(temp);
                      if (temp.length < 1) {
                        setArrow(false);
                        setCollapse(!collapse);
                      }
                      if (playerData.length > maxPlayer - 1) {
                        setMemberIsMax(!memberIsMax);
                      } else {
                        setMemberIsMax(false);
                      }
                      if (onRemoveClick) {
                        onRemoveClick(p);
                      }
                    }}
                  />
                );
              })}
          </motion.section>
        )}
      </AnimatePresence>
      {validationType ? (
        <NormalText
          color={
            validationType === 'ERROR'
              ? '#FF4D6D'
              : validationType === 'WARNING'
              ? '#E7B450'
              : validationType === 'INFO'
              ? '#DDD8D8'
              : 'white'
          }
          fontSize="12px"
          lineHeight="146%"
          fontStyle="normal"
          fontWeight="400"
          margin="6px 0px 0px 5px"
        >
          {validationText}
        </NormalText>
      ) : undefined}
    </SelectContainer>
  );
}

export default ChoosePlayerTeam;
