import Member from '../private/Member';
import Pertandingan from '../private/Pertandingan';
import ProfileTournamentList from '../private/TournamentList';
import { Box } from '@mui/material';
import { CustomTabs, TabItem } from 'design/Tabs';
import { tabRedLineStyled } from 'design/Tabs/styles';
import { Text } from 'design/Text';
import React, { memo, useCallback, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { fetchTournamentParticipantInvited } from 'redux/tournament/TournamentServices';

interface PrivateNavigationProps {
  paramGameSlug: string;
  paramTeamSlug: string;
  setPageTitle: any;
  hash: string;
  setOpenToast: (value: boolean) => void;
  setToastMessages: (value: string) => void;
  setOpenErrorToast: (value: boolean) => void;
  setToastErrorMessages: (value: string) => void;
  setOpenLoader: (value: boolean) => void;
}

const PrivateNavigation = ({
  paramGameSlug,
  paramTeamSlug,
  setPageTitle,
  hash,
  setOpenToast,
  setToastMessages,
  setOpenErrorToast,
  setToastErrorMessages,
  setOpenLoader,
}: PrivateNavigationProps) => {
  const dispatch = useAppThunkDispatch();
  const height = use100vh();
  const location = useLocation<any>();

  const { tournamentParticipantInvited, tournamentParticipantInvitedStatus } = useAppSelector(
    ({ tournament }) => ({
      tournamentParticipantInvited: tournament?.tournamentParticipantInvited,
      tournamentParticipantInvitedStatus: tournament?.status?.tournamentParticipantInvited,
    }),
  );

  const handleFetchTournamentInvited = () => {
    dispatch(fetchTournamentParticipantInvited());
  };

  // only fetch when first load
  useEffect(() => {
    if (location?.hash !== '#tournament') {
      handleFetchTournamentInvited();
    }
  }, []);

  // fetch when click turnamen tab
  const handleActiveTab = useCallback((value: string) => {
    if (value === '#tournament' && tournamentParticipantInvitedStatus === 'succeeded') {
      handleFetchTournamentInvited();
    }
  }, []);

  const tabItemStyled = {
    minHeight: height! - (56 + 102 + 65),
    height: !height ? 'calc(100vh - (56px + 102px + 65px))' : 'auto',
    bgcolor: 'neutral800',
  };

  const handleTabItemtext = useCallback((title: string, countPartisipant?: number) => {
    return (
      <>
        <Text
          variant="button"
          sx={{
            color: 'neutral400',
            fontWeight: '400',
            lineHeight: '15px',
            letterSpacing: '0.08em',
            textTransform: 'uppercase',
            mr: '4px',
          }}
        >
          {title}
        </Text>

        {countPartisipant ? (
          <Text
            variant="button"
            sx={{
              color: 'neutral700',
              fontWeight: '400',
              lineHeight: '15px',
              letterSpacing: '0.08em',
              textTransform: 'uppercase',
              p: '0.5px 2.5px',
              bgcolor: 'primaryOrange700',
              borderRadius: '2px',
            }}
          >
            {countPartisipant}
          </Text>
        ) : null}
      </>
    );
  }, []);

  return (
    <CustomTabs
      tabIndex={value => handleActiveTab(value)}
      sx={tabRedLineStyled}
      activeTab={hash || '#match'}
      tabSx={{ flexGrow: 1 }}
    >
      <TabItem hash="#match" title={handleTabItemtext('MATCH')} sx={tabItemStyled}>
        <Box>
          <Pertandingan
            gameSlug={paramGameSlug}
            teamSlug={paramTeamSlug}
            setPageTitle={setPageTitle}
            setOpenLoader={setOpenLoader}
          />
        </Box>
      </TabItem>

      <TabItem
        hash="#tournament"
        title={handleTabItemtext('TURNAMEN', tournamentParticipantInvited?.total)}
        sx={tabItemStyled}
      >
        <Box sx={{ p: '16px' }}>
          <ProfileTournamentList
            teamSlug={paramTeamSlug}
            setPageTitle={setPageTitle}
            handleFetchTournamentInvited={handleFetchTournamentInvited}
            setOpenLoader={setOpenLoader}
          />
        </Box>
      </TabItem>

      <TabItem hash="#tentang" title={handleTabItemtext('TENTANG')} sx={tabItemStyled}>
        <Box sx={{ p: '0' }}>
          <Member
            gameSlug={paramGameSlug}
            teamSlug={paramTeamSlug}
            setPageTitle={setPageTitle}
            setOpenToast={setOpenToast}
            setToastMessages={setToastMessages}
            setOpenErrorToast={setOpenErrorToast}
            setToastErrorMessages={setToastErrorMessages}
          />
        </Box>
      </TabItem>
    </CustomTabs>
  );
};

export default memo(PrivateNavigation);
