import { IconSource } from 'modules/assetpath';

export const GameOnToastStyle = () => ({
  overflow: 'hidden',
  background:
    'linear-gradient(270deg, rgba(19, 96, 69, 0.32) 0%, rgba(0, 0, 0, 0) 48.12%), #59E8B5 no-repeat',
  backgroundRepeat: 'no-repeat',
  backgroundBlendMode: 'overlay',
  padding: '10px 12px',
  borderRadius: '8px',
  minHeight: '20px',
  '&:before': {
    content: '""',
    backgroundImage: `url(${IconSource('game-on-toast-bg', 'png')})`,
    position: 'absolute',
    left: '-12px',
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${IconSource('grunge', 'png')})`,
    zIndex: 1,
    opacity: 1,
    left: 0,
    borderRadius: '8px',
  },
});

export const WarningStyle = () => ({
  bgcolor: 'primary200',
  borderRadius: '8px',
  padding: '10px 12px',
});
