import React from 'react'
import { NormalText } from "components/styled/text.styled"
import ConfirmModal from "components/UniPopup/Modals/ConfirmModal"
import { useTranslation } from 'react-i18next'

const LogoutModal = (p: { onConfirmClick: () => void, onCancelClick: () => void }) => {
    const { t } = useTranslation()
    return (
        <ConfirmModal
            title={t('player_account_logout_confirmation_title')}
            btnConfirmText={
                <NormalText
                    color="white"
                    fontWeight="700"
                    fontSize="14px"
                    textAlign="center"
                    padding="0px 12px 0px 12px"
                >
                    {t('player_account_logout_confirmation_button')}
                </NormalText>
            }
            onConfirmClick={p.onConfirmClick}
            btnCancelText="Batal"
            onCancelClick={p.onCancelClick}
        >
            <NormalText
                color="#969393"
                fontSize="14px"
                fontWeight="400"
                lineHeight="20.44px"
                textAlign="center"
            >
                {t('player_account_logout_confirmation_message')}
            </NormalText>
        </ConfirmModal>
    )
}

export default LogoutModal;