import { Divider, Stack } from '@mui/material';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { TextButton } from 'design/Buttons/TextButton';
import { CardListParticipationAward } from 'design/Cards/CardListParticipationAward';
import { CardSection } from 'design/Cards/CardSection';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

interface TournamentsProps {
  playerSlug?: string;
}

const Tournaments = ({ playerSlug }: TournamentsProps) => {
  const { tournamentParticipantPlayer } = useAppSelector(({ tournament }) => ({
    tournamentParticipantPlayer: tournament.tournamentParticipantPlayer,
  }));

  const handleTournamentParticipantRank = (rank: String) => {
    switch (rank) {
      case '1':
        return 'Champion';
      case '2':
        return '2nd Place';
      case '3':
        return '3rd Place';
      default:
        return 'Playoff';
    }
  };

  return (
    <CardSection
      title="Partisipasi Turnamen"
      background={ImageSource('bg-tournament-ornament', 'png')}
    >
      {Object.keys(tournamentParticipantPlayer ?? {}).length ? (
        <>
          {tournamentParticipantPlayer?.events?.map((tournament: any, idx: number) =>
            idx < 3 ? (
              <CardListParticipationAward
                photo={tournament.logoUrl}
                title={tournament.eventName}
                description={handleTournamentParticipantRank(tournament.rank)}
              />
            ) : null,
          )}
          {(tournamentParticipantPlayer?.events ?? []).length > 3 ? (
            <>
              <Divider sx={{ mb: '16px', borderColor: 'neutral600' }} />
              <Stack alignItems="center">
                <Link to={`/player/${playerSlug}/tournaments`}>
                  <TextButton>
                    Lihat Semua ({(tournamentParticipantPlayer?.events ?? []).length})
                  </TextButton>
                </Link>
              </Stack>
            </>
          ) : null}
        </>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing="12px"
          sx={{ m: '8px 0 20px 0' }}
        >
          <Text variant="body" sx={{ color: 'neutral400' }}>
            Kamu belum ikut turnamen nih
          </Text>
          <Link to="/explore/tournament">
            <SecondaryButton variant="small" sx={{ whiteSpace: 'nowrap' }}>
              Cari Turnamen
            </SecondaryButton>
          </Link>
        </Stack>
      )}
    </CardSection>
  );
};

export default memo(Tournaments);
