import { Stack } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { CardActivityScrim } from 'design/Cards/CardActivityScrim';
import { CardSection } from 'design/Cards/CardSection';
import { Text } from 'design/Text';
import React, { memo, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { fetchScrimTeamStatistic } from 'redux/scrim/ScrimService';
import { useAppThunkDispatch } from 'redux/store';

const ScrimStatistic = () => {
  const dispatch = useAppThunkDispatch();

  const { teamDetail, scrimStatistic, scrimStatisticStatus } = useAppSelector(
    ({ teamProfile, scrimList }) => ({
      teamDetail: teamProfile?.teamDetail,
      scrimStatistic: scrimList?.scrimTeamStatistic,
      scrimStatisticStatus: scrimList?.status?.scrimTeamStatistic,
    }),
  );

  const [error, setError] = useState<{ name: string; message: string; stack: string }>();

  const handleFetchScrimTeamStatistic = (teamId: string) => {
    dispatch(
      fetchScrimTeamStatistic({
        teamId: teamId,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  useEffect(() => {
    if (teamDetail) {
      handleFetchScrimTeamStatistic(teamDetail?.id);
    }
  }, [teamDetail]);

  const dataAktifitasScrim = [
    {
      title: 'Total Scrim',
      value: scrimStatistic?.totalScrim ?? 0,
      description: 'Match',
    },
    {
      title: 'Total Lawan',
      value: scrimStatistic?.totalSchool ?? 0,
      description: (
        <>
          Sekolah dari <strong>{scrimStatistic?.totalProvince ?? 0}</strong> Provinsi
        </>
      ),
    },
  ];

  const handleLoading = (status: boolean) => {
    if (status) {
      return !Boolean(scrimStatistic);
    }
    return false;
  };

  return (
    <>
      <CardSection
        title={
          <Text variant="label" component="h5" sx={{ textTransform: 'capitalize' }}>
            Aktivitas Scrim
          </Text>
        }
        cta={
          <Text variant="subtitle" sx={{ color: 'neutral400' }}>
            3 Bulan Terakhir
          </Text>
        }
        sx={{ bgcolor: 'transparent', pt: '24px' }}
      >
        <Stack direction="row" spacing="12px">
          {dataAktifitasScrim.map(dataAktifitasScrim => (
            <CardActivityScrim
              key={dataAktifitasScrim.title}
              title={dataAktifitasScrim.title}
              value={dataAktifitasScrim.value}
              description={dataAktifitasScrim.description}
              sx={{
                boxShadow: 'none',
              }}
            />
          ))}
        </Stack>
      </CardSection>

      <LoadingModal
        show={error ? true : handleLoading(scrimStatisticStatus === 'loading')}
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default memo(ScrimStatistic);
