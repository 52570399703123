import AwardsLanding from './state/AwardsLanding';
import CompetitionList from './state/CompetitionList';
import { LayoutContext } from 'context/LayoutContext';
import { CustomTabs, TabItem } from 'design/Tabs';
import history from 'modules/history';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { getCountUnreadNotification } from 'redux/notification/NotificationReducer';
import { useAppThunkDispatch } from 'redux/store';
import AuthService from 'services/AuthService';

const CompetitionPage = () => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();

  const dispatch = useAppThunkDispatch();
  const [haveNotifUnread, setHaveNotifUnread] = useState(false);
  const [pageTitleTab, setPageTitleTab] = useState(t('mabar_page_title', { title: 'Competition' }));

  const { playerInfo } = useAppSelector(({ account }) => ({
    playerInfo: account?.data,
  }));

  const location = useLocation();

  const handleCountNotification = () => {
    if (playerInfo) {
      dispatch(
        getCountUnreadNotification({
          id: playerInfo.id,
        }),
      )
        .unwrap()
        .then(res => {
          if (res && res.status == 200 && res.data.count > 0) {
            setHaveNotifUnread(true);
          } else {
            setHaveNotifUnread(false);
          }
        });
    }
  };

  useEffect(() => {
    if (isAuthenticated) handleCountNotification();
  }, [playerInfo]);

  const onChangeHandler = (value: any): void => {
    if (value === 0) {
      history.push('/competition');
      setPageTitleTab(t('mabar_page_title', { title: 'Competition' }));
    } else {
      history.push('/awards');
      setPageTitleTab(t('mabar_page_title', { title: 'Awards' }));
    }
  };

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: pageTitleTab,
      background: { color: '#0a0a0b' },
      appBar: {
        logo: true,
        notification: {
          show: isAuthenticated,
          haveNotifUnread: haveNotifUnread,
          onClick: () => history.push('/notification'),
        },
      },
      padding: '0px',
      bottomBar: {
        show: true,
      },
    });
  }, [haveNotifUnread, pageTitleTab]);

  return (
    <CustomTabs
      sx={theme => ({
        height: '40px',
        bgcolor: 'neutral700',
        padding: '0 16px',
        position: 'sticky',
        top: '56px',
        zIndex: 5,
        borderBottom: `1px solid ${theme.palette.neutral530}`,
      })}
      handleChange={onChangeHandler}
      activeTab={location.pathname === '/competition' ? 0 : location.pathname === '/awards' ? 1 : 0}
    >
      <TabItem title="Competition">
        <CompetitionList />
      </TabItem>
      <TabItem title="Awards">
        <AwardsLanding />
      </TabItem>
    </CustomTabs>
  );
};

export default CompetitionPage;
