import GradientButton from 'components/Buttons/GradientButton';
import PlayerCard from 'components/cards/PlayerCard';
import AppLayout from 'components/layouts/AppLayout';
import Modal from 'components/layouts/Modal';
import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import _ from 'lodash';
import { ImageSource } from 'modules/assetpath';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { fetchGameDetail } from 'redux/game/GameReducer';
import { useAppThunkDispatch } from 'redux/store';
import { TeamMember } from 'redux/teamProfile/TeamProfileResponse';
import {
  directApproval,
  fetchTeamMember,
  openApproval,
} from 'redux/teamProfile/TeamProfileService';

const MatchChooseTeamPlayer = () => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const { matchId, teamSlug } = useParams() as { matchId: string; teamSlug: string };
  const [maxChoosePlayer, setMaxChoosePlayer] = useState<number>();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<TeamMember[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [gameType, setGameType] = useState('direct');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalMessage, setShowErrorModalMessage] = useState<any>('');
  const [getGameSlug, setGameSlug] = useState<string>();
  const isLoading = false;
  const [showModalLoading, setShowModalLoading] = useState(false);

  useEffect(() => {
    const gameState = new URLSearchParams(history.location.search).get('state');
    const gameSlug = new URLSearchParams(history.location.search).get('game') || '';
    if (gameState !== null && gameState !== '') {
      setGameType(gameState);
    }

    setGameSlug(gameSlug);

    dispatch(fetchGameDetail({ gameSlug }))
      .unwrap()
      .then(game => {
        if (game) {
          setMaxChoosePlayer(game.maxTeamMember || 0);
          const thunk = fetchTeamMember({
            teamSlug,
            pageIndex: 1,
            pageSize: 120,
            version: '',
            approved: true,
          });
          dispatch(thunk)
            .unwrap()
            .then(data => setTeamMembers(data?.items));
        }
      });
  }, [dispatch]);

  const checkPlayerState = (playerSelected: TeamMember) => {
    if (selectedPlayers.length >= (maxChoosePlayer || 0)) {
      return !selectedPlayers.find(e => {
        return e.player.id === playerSelected.player.id;
      });
    }
    return false;
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const handleClickSave = () => {
    approve();
  };

  const approve = async () => {
    const players = [...selectedPlayers];
    const captain = teamMembers.find(c => c.level.value === 'Captain');
    if (captain) {
      players.push(captain);
    }

    const thunk =
      gameType === 'direct'
        ? directApproval({ matchId, players: players.map(x => x.player.id) })
        : openApproval({ matchId, players: players.map(x => x.player.id) });

    setShowModalLoading(true);
    dispatch(thunk)
      .unwrap()
      .then((result: any) => {
        const listErrors: any = [];
        if (result && result?.isAxiosError && result?.data?.status != 204) {
          if (result.data.errors.id) {
            listErrors.push(result.data.errors.id);
          }
          if (result.data.errors.players) {
            listErrors.push(result.data.errors.players);
          }
          setTimeout(() => {
            setShowModalLoading(false);
            setShowErrorModalMessage(parseError(listErrors));
            setShowErrorModal(true);
          }, 3000);
        } else {
          setTimeout(() => {
            setShowModalLoading(false);
            history.push(`/scrims/${getGameSlug}/${matchId}`);
          }, 3000);
        }
      })
      .catch(() => {
        setShowErrorModal(true);
      });
  };

  const parseError = (errors: any) => {
    if (errors) {
      let errorsList: string[] = [];
      Object.values(errors).forEach((value: any) => {
        if (value[0].code == 'RX1098') {
          errorsList = errorsList.concat(t('scrim_conflict_schedule'));
        } else if (value[0].code == 'RX1203') {
          errorsList = errorsList.concat(value[0].message);
        } else {
          errorsList = errorsList.concat(t('something_wrong_message'));
        }
      });
      return errorsList.join('\n');
    }
    return null;
  };

  const ModalError = () => {
    return (
      <Box padding="16px 0" width="100%">
        <Stack direction="column" margin="16px">
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight="bold"
            margin="8px 0px"
          >
            {t('something_wrong')}
          </NormalText>
          <NormalText
            textAlign="center"
            color="red"
            fontSize="14px"
            fontWeight="normal"
            margin="8px 0px"
          >
            {showErrorModalMessage}
          </NormalText>
          <br />
          <Box padding="8px" width="100%">
            <Stack direction="column" align="center">
              <GradientButton
                onClick={() => setShowErrorModal(false)}
                styled={{
                  backgroundSize: '100%',
                  width: '180px',
                }}
                variant="primary"
              >
                {t('player_scrim_match_schedule_not_available_ok')}
              </GradientButton>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  };

  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
          onClick: handleClickBack,
        },
        navright: {
          bg:
            selectedPlayers.length > 0
              ? 'linear-gradient(131.42deg, #d51f40 46.48%, #e24134 62.51%, #e8542e 74.36%, #f37024 83.78%)'
              : '#969393',
          children: 'Simpan',
          variant: 'button',
          onClick: selectedPlayers.length > 0 ? handleClickSave : () => null,
        },
        isDisable: false,
        title: `Pilih Player (${selectedPlayers.length}/${maxChoosePlayer})`,
      }}
      backgroundImage={ImageSource('app.background')}
      searchBar={{
        id: 'scrim-search-player',
        placeholder: t('player_explore_scrim_add_pick_member_search'),
        onChange: e => {
          if (e.currentTarget.value.length >= 2) {
            setSearchTerm(e.currentTarget.value);
          } else {
            setSearchTerm('');
          }
        },
      }}
    >
      <Stack direction="column" align="center" margin="8px 0px 0px 0px">
        <Modal show={showModalLoading} styled={{ background: 'transparent' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar src={IconSource('ic-loader')} />
          </div>
        </Modal>
        <Modal show={showErrorModal}>
          <ModalError />
        </Modal>
        {isLoading === false &&
          teamMembers &&
          (searchTerm !== ''
            ? teamMembers.filter(
                x =>
                  x.player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  x.player.nickName.toLowerCase().includes(searchTerm.toLowerCase()),
              )
            : teamMembers
          ).map(data => {
            if (data.level.value !== 'Captain')
              return (
                <PlayerCard
                  onIconRightClick={() => {
                    const players = [...selectedPlayers];
                    const index = players.find(x => x.player.id === data.player.id);
                    if (index) {
                      _.remove(players, x => x.player.id === data.player.id);
                    } else if (selectedPlayers.length < (maxChoosePlayer || 0)) {
                      players.push(data);
                    }
                    setSelectedPlayers(players);
                  }}
                  disable={checkPlayerState(data)}
                  key={data.player.id}
                  playerId={`@${data.player.urlSlug}, GAME ID: ${data.player.gameProviderId}`}
                  playerName={data.player.name}
                  playerPhoto={data.player.picture ?? ''}
                  margin="8px 0px 0px 0px"
                  iconRight={
                    selectedPlayers.find(e => {
                      return e.player.id === data.player.id;
                    })
                      ? '/media/icons/checklist.active.svg'
                      : '/media/icons/checklist.inactive.svg'
                  }
                />
              );

            return <></>;
          })}
      </Stack>
    </AppLayout>
  );
};

export default MatchChooseTeamPlayer;
