import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export interface TeamInvitationResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  totalCount: number;
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  items: TeamInvitation[];
}

export interface TeamInvitation {
  avatarUrl: string | undefined;
  game: string | undefined;
  gameSlug: string;
  id: string;
  name: string;
  urlSlug: string;
  joinAt: Date;
  invitedBy: InvitedBy;
}

export interface InvitedBy {
  id: string;
  urlSlug: string;
  name: string;
  picture: string;
  gameProviderId: string;
}

export interface FluentValidationProblemDetails {
  errors?: Errors;
  traceId?: string;
  type?: string;
  title?: string;
  status?: number;
}

export interface Errors {
  players: ErrorItem[];
  gameId: ErrorItem[];
  startAt: ErrorItem[];
  secondTeamId: ErrorItem[];
  playerId: ErrorItem[];
  teamId: ErrorItem[];
}

export interface ErrorItem {
  code: string;
  message: string;
  formatedValue: FormatedValue;
}

export interface FormatedValue {
  minRoasters: number;
  playerId: string;
  teamId: string;
  gameId: string;
}

export interface TeamInvitationState {
  data: TeamInvitationResponse;
  showModal: boolean;
  teamInviteSelected: TeamInvitation;
  loading: boolean;
  errors: FluentValidationProblemDetails | any;
  errorCode: number;
}

export async function getTeamInvitationService(p: {
  playerSlug: string;
  pageIndex: number;
  pageSize: number;
  version?: string;
}) {
  const apiUrl = encodeURI(
    p.version
      ? `/v${p.version}/team/invitation/${p.playerSlug}/${p.pageIndex}/${p.pageSize}`
      : `/team/invitation/${p.playerSlug}/${p.pageIndex}/${p.pageSize}`,
  );
  return await ApiService.get(apiUrl);
}

export const getTeamInvitationList = createAsyncThunk(
  'team-invitation/invitation-list',
  async (p: { playerSlug: string; pageIndex: number; pageSize: number; version?: string }) => {
    try {
      const apiUrl = encodeURI(
        p.version
          ? `/v${p.version}/team/invitation/${p.playerSlug}/${p.pageIndex}/${p.pageSize}`
          : `/team/invitation/${p.playerSlug}/${p.pageIndex}/${p.pageSize}`,
      );
      return (await ApiService.get(apiUrl)).data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, errors: error.response.data };
      } else {
        return { isAxiosError: true, errors: error };
      }
    }
  },
);

export const approveInvitation = createAsyncThunk(
  'team-invitation/approve-invite',
  async ({ teamId, playerId, version }: { teamId: string; playerId: string; version?: string }) => {
    try {
      const url = `/${version ? `v${version}/` : ''}team-approval/approve-invite`;
      const res = await ApiService.post(url, {
        teamId: teamId,
        playerId: playerId,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, errors: error.response.data };
      } else {
        return { isAxiosError: true, errors: error };
      }
    }
  },
);

export const rejectInvitation = createAsyncThunk(
  'team-invitation/approve-invite',
  async ({ teamId, playerId, version }: { teamId: string; playerId: string; version?: string }) => {
    try {
      const url = `/${version ? `v${version}/` : ''}team-approval/reject-invite`;
      const res = await ApiService.post(url, {
        teamId: teamId,
        playerId: playerId,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, errors: error.response.data };
      } else {
        return { isAxiosError: true, errors: error };
      }
    }
  },
);

const initialState: TeamInvitationState = {
  data: {
    pageIndex: 0,
    pageSize: 0,
    count: 0,
    totalCount: 0,
    totalPages: 0,
    items: [],
    hasNextPage: false,
    hasPreviousPage: false,
  },
  teamInviteSelected: {
    id: '',
    avatarUrl: '',
    game: '',
    gameSlug: '',
    invitedBy: {
      gameProviderId: '',
      id: '',
      name: '',
      picture: '',
      urlSlug: '',
    },
    joinAt: new Date(0),
    name: '',
    urlSlug: '',
  },
  showModal: false,
  loading: false,
  errors: {},
  errorCode: 0,
};

const TeamInvitationSlice = createSlice({
  name: 'TeamInvitation',
  initialState: initialState,
  reducers: {
    getTeamInvitationAction: (
      state,
      action: PayloadAction<{
        playerSlug: string;
        pageIndex: number;
        pageSize: number;
        version?: string;
      }>,
    ) => {
      state.loading = true;
      state.data = {
        ...state.data,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
      };
    },
    resetTeamInvitationAction: state => {
      state.data = initialState.data;
      state.teamInviteSelected = initialState.teamInviteSelected;
    },
    successGetTeamInvitationAction: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    failedGetTeamInvitationAction: (state, action) => {
      state.loading = false;
      if (action.payload.errors !== undefined) {
        state.errors = action.payload.errors;
        state.errorCode = state.errors.status!!;
      }
    },
    setTeamInvitationModalAction: (state, action: PayloadAction<{ showModal: boolean }>) => {
      state.loading = true;
      state.showModal = action.payload.showModal;
    },
    successTeamInvitationModalAction: state => {
      state.loading = false;
    },
    failedTeamInvitationModalAction: state => {
      state.loading = false;
      state.showModal = false;
    },
    setTeamInvitationSelectedAction: (
      state,
      action: PayloadAction<{ teamInviteSelected: TeamInvitation }>,
    ) => {
      const { teamInviteSelected } = action.payload;
      state.loading = true;
      state.teamInviteSelected = teamInviteSelected;
    },
    successTeamInvitationSelectedAction: state => {
      state.loading = false;
      state.showModal = true;
    },
    failedTeamInvitationSelectedAction: state => {
      state.loading = false;
      state.showModal = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTeamInvitationList.pending, state => {
        state.loading = true;
      })
      .addCase(getTeamInvitationList.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.error;
      })
      .addCase(getTeamInvitationList.fulfilled, (state, action) => {
        if (action.payload?.isAxiosError && action.payload?.errors) {
          state.errors = action.payload.errors;
          state.errorCode = action.payload?.errors?.status ?? 0;
          state.loading = false;
        } else {
          state.data = action.payload;
          state.loading = false;
        }
      });
  },
});

export const {
  resetTeamInvitationAction,
  getTeamInvitationAction,
  successGetTeamInvitationAction,
  failedGetTeamInvitationAction,
  setTeamInvitationSelectedAction,
  failedTeamInvitationSelectedAction,
  successTeamInvitationSelectedAction,
  setTeamInvitationModalAction,
  successTeamInvitationModalAction,
  failedTeamInvitationModalAction,
} = TeamInvitationSlice.actions;

export default TeamInvitationSlice.reducer;
