import { Theme, Stack, Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface CardBannerProps {
  title?: string | ReactNode;
  description?: string;
  cta?: ReactNode;
  sx?: SxProps<Theme>;
  theme?: Theme;
}

const CardBannerStyled: any = ({ theme, sx }: CardBannerProps) => {
  return {
    position: 'relative',
    height: '146px',
    px: '12px',
    borderRadius: theme?.borderRadius.lg,
    background: `url(${ImageSource(
      'bg-cardbanner-provincialwinner',
      'png',
    )}) no-repeat center / cover`,
    ...sx,
  };
};

export const shadowOrangeStyled = {
  '&:before': {
    content: "''",
    position: 'absolute',
    height: '76px',
    margin: '0 auto',
    bottom: '11px',
    left: '15px',
    right: '15px',
    background: '#1A1817',
    boxShadow: '0px 4px 36px rgba(242, 108, 38, 0.5)',
    borderRadius: '12px',
    zIndex: -1,
  },
};

export const CardBanner = ({ title, description, cta, sx }: CardBannerProps) => {
  return (
    <Stack justifyContent="center" sx={theme => CardBannerStyled({ theme, sx })}>
      <Text
        className="cardbanner-title"
        variant="h3"
        sx={{ fontSize: '18px', lineHeight: '26px', textShadow: '0px 4px 2px rgba(0, 0, 0, 0.12)' }}
      >
        {title}
      </Text>
      <Text
        className="cardbanner-description"
        variant="subtitle"
        sx={{ color: 'neutral300', mt: '8px' }}
      >
        {description}
      </Text>
      <Box sx={{ mt: '12px' }}>{cta}</Box>
    </Stack>
  );
};
