import { Avatar } from '../../styled/avatar.styled';
import { Theme, Stack, Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { ImageSource, IconSource } from 'modules/assetpath';
import React, { FC } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export interface CardSchoolType {
  schoolLogo?: string;
  schoolName?: string;
  teamLogo?: string;
  teamName?: string;
  sx?: SxProps<Theme>;
}

export const CardSchool: FC<CardSchoolType> = ({
  schoolLogo,
  schoolName,
  teamLogo,
  teamName,
  sx,
}) => {
  return (
    <>
      <Box
        bgcolor="neutral700"
        borderRadius="12px"
        width="144px"
        height="170px"
        position="relative"
        sx={sx}
      >
        <Stack direction="column" alignItems="start">
          <Avatar src={ImageSource('bg-card-school', 'png')} width="144px" height="56px" />
          <Box sx={{ m: '42px 8px 12px 8px', whiteSpace: 'normal' }}>
            <Stack direction="column" justifyContent="center" alignItems="center" width={128}>
              <Text variant="label" align="center">
                <ResponsiveEllipsis text={schoolName} maxLine={2} ellipsis="..." />
              </Text>
              <Stack direction="row" mt="8px">
                {teamLogo && (
                  <Avatar
                    src={teamLogo ?? IconSource('ic-person-white')}
                    sx={{
                      boxShadow: '0px 1.2px 2.4px rgba(0, 0, 0, 0.16)',
                      width: '10px',
                      height: '10px',
                      borderRadius: '1.7561px',
                      border: '0.439024px solid #FFFFFF',
                    }}
                  />
                )}
                <Text
                  variant="subtitle"
                  sx={{
                    color: '#FFFFFF',
                    opacity: '60%',
                    fontSize: '10px',
                    lineHeight: '12px',
                    letterSpacing: '0.16px',
                    ml: '3px',
                    textAlign: teamLogo ? 'left' : 'center',
                  }}
                >
                  {teamName}
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Avatar
          src={schoolLogo ?? IconSource('ic-person-white')}
          sx={{
            width: '60px',
            height: '60px',
            borderRadius: '8px',
            border: '0.891304px solid #FFFFFF',
            position: 'absolute',
            top: '26px',
            left: '42px',
            zIndex: '1',
            boxShadow: '0px 0.911111px 3.64444px rgba(0, 0, 0, 0.25)',
          }}
        />
      </Box>
    </>
  );
};
