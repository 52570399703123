import PlayerCard from 'components/cards/PlayerCard';
import { Box, Stack } from 'components/styled/layout.styled';
import React from 'react';
import { useTitle } from 'react-use';
import { FirstTeamPlayer } from 'redux/scrim/ScrimDetailReducer';

export interface DetailPlayerProps {
  teamPlayers: FirstTeamPlayer[];
  captainName: string;
}

const DetailPlayer = ({ teamPlayers, captainName }: DetailPlayerProps) => {
  useTitle(`Match > Detail Info | Player `);
  return (
    <Box width="100%">
      <Stack direction="column">
        {teamPlayers &&
          teamPlayers.map(player => {
            return (
              <PlayerCard
                key={player.playerId}
                margin="12px 0px 0px 0px"
                playerName={player.name ?? ''}
                playerId={`@${player?.nickName ?? ' '}, GAME ID : ${player.gameProviderId ?? 0}`}
                playerPhoto={player.picture}
                isCaptain={player.name === captainName}
              />
            );
          })}
      </Stack>
    </Box>
  );
};

export default DetailPlayer;
