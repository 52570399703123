import styled, { css } from 'styled-components';

interface AvatarProps {
  width?: string | undefined;
  height?: string | undefined;
  padding?: string | undefined;
  margin?: string | undefined;
  borderRadius?: string | undefined;
  src?: string | undefined;
  fit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  objectPos?: string | undefined;
  border?: string | undefined;
  zIndex?: number | undefined;
  background?: string | undefined;
  isPressable?: boolean;
}

export const Avatar = styled.img<AvatarProps>`
  alt: 'Avatar';
  object-fit: ${({ fit }) => fit};
  object-position: ${({ objectPos }) => objectPos};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  background: ${({ background }) => background};
  z-index: ${({ zIndex }) => zIndex};
  position: 50% 50%;
  src: ${({ src }) => src};
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  ${p =>
    p.isPressable &&
    css`
      &:active {
        opacity: 0.7;
      }
    `}
   &:valid {
      background-color: transparent;
   }
`;
