import { Box, Stack } from '@mui/material';
import MatchItem from 'components/Lists/MatchItem';
import MatchLayout from 'components/MatchLayout';
import ScrollView from 'components/ScrollView';
import { Avatar } from 'components/styled/avatar.styled';
import { NormalText, SpanText } from 'components/styled/text.styled';
import { ImageSource } from 'modules/assetpath';
import { dateFormat, parseTimeLeft } from 'modules/helpers';
import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { PlayerUpcomingScrim } from 'redux/account/AccountResponse';
import { useAppThunkDispatch } from 'redux/store';
import { TeamDetailResponse } from 'redux/team/TeamDetailReducer';
import { fetchTeamDetail } from 'redux/teamProfile/TeamProfileService';

const ScrimUpcomingAccount = (p: { playerScrimUpcoming?: PlayerUpcomingScrim[] | [] }) => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const [teamDetail, setTeamDetail] = React.useState<TeamDetailResponse>();

  React.useEffect(() => {
    if (p.playerScrimUpcoming && p.playerScrimUpcoming.length > 0) {
      dispatch(fetchTeamDetail({ teamSlug: p.playerScrimUpcoming[0]?.firstTeamUrlSlug }))
        .unwrap()
        .then(res => {
          if (res) {
            setTeamDetail(res);
          }
        });
    }
  }, []);

  return p.playerScrimUpcoming && p.playerScrimUpcoming.length >= 0 ? (
    <Box sx={{ width: '100%' }}>
      <ScrollView
        direction="horizontal"
        margin={p.playerScrimUpcoming.length === 1 ? '0px 16px' : '0px'}
        display="flex"
        align="start"
        pageIndicator={{
          show: p.playerScrimUpcoming.length === 1 ? false : true,
          position: 'bottom',
          align: 'center',
        }}
      >
        {p.playerScrimUpcoming.map(data => {
          return (
            <MatchLayout key={data.id} style={{ width: '100%' }}>
              <MatchItem
                key={data.id}
                matchId={t('player_team_profile_scrim_no', { scrimId: data.id })}
                data={data}
                matchDate={dateFormat(data.startAt).format('DD MMM yyyy, HH:mm z')}
                onClick={() => {
                  window.location.href = `/scrims/${teamDetail?.game.urlSlug}/${data.id}`;
                  // history.pushState([], `/scrims/${p.game_slug}/${data.id}`)
                }}
                noEnemyText={'Menunggu Lawan'}
                boxRight={
                  <Stack alignItems="center">
                    <SpanText
                      color="white"
                      fontSize="16px"
                      fontWeight="900"
                      lineHeight="22.4px"
                      fontStyle="normal"
                      textAlign="center"
                      wordWrap="break-word"
                    >
                      {dateFormat(data.startAt).format('HH:mm')} WIB
                    </SpanText>
                    <Countdown
                      date={data.startAt}
                      autoStart
                      intervalDelay={0}
                      renderer={({ formatted, hours, days }) => (
                        <SpanText
                          color="#DDD8D8"
                          fontSize="12px"
                          fontWeight="400"
                          textAlign="center"
                        >
                          {days >= 1
                            ? parseTimeLeft({ dateTime: data.startAt, subtract: 10, locale: 'id' })
                            : `${hours >= 10 ? formatted.hours : hours} jam ${
                                formatted.minutes
                              } menit`}
                        </SpanText>
                      )}
                    />
                  </Stack>
                }
              />
            </MatchLayout>
          );
        })}
      </ScrollView>
    </Box>
  ) : (
    <Stack alignItems="center">
      <Avatar src={ImageSource('bg-up-coming-empty', 'png')} />
      <NormalText
        position="absolute"
        fontSize="16px"
        lineHeight="140%"
        textAlign="center"
        fontWeight="bold"
        color="white"
      >
        Tidak Ada Jadwal Scrim
      </NormalText>
    </Stack>
  );
};

export default ScrimUpcomingAccount;
