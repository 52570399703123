import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addPlayersAction,
  chooseTeamOpponentAction,
  failedAddPlayersAction,
  failedChooseTeamOpponentAction,
  failedGetGameSelectedAction,
  failedRemovePlayerAction,
  failedResetScrimDraftAction,
  failedSetMatchStartDateAction,
  failedSetMatchStartTimeAction,
  failedSetMatchTypeAction,
  getGameSelectedAction,
  removePlayerAction,
  resetScrimDraftAction,
  setMatchStartDateAction,
  setMatchStartTimeAction,
  setMatchTypeAction,
  successAddPlayersAction,
  successChooseTeamOpponentAction,
  successGetGameSelectedAction,
  successRemovePlayerAction,
  successResetScrimDraftAction,
  successSetMatchStartDateAction,
  successSetMatchStartTimeAction,
  successSetMatchTypeAction,
} from './ScrimDraftReducer';

function* getGameSelectedSaga(action: AnyAction) {
  try {
    yield call(getGameSelectedAction, action.payload);
    yield put(successGetGameSelectedAction());
  } catch (e) {
    yield put(failedGetGameSelectedAction());
  }
}

function* addPlayersSaga(action: AnyAction) {
  try {
    yield call(addPlayersAction, action.payload);
    yield put(successAddPlayersAction());
  } catch (e) {
    yield put(failedAddPlayersAction());
  }
}

function* removePlayerSaga(action: AnyAction) {
  try {
    yield call(removePlayerAction, action.payload);
    yield put(successRemovePlayerAction());
  } catch (e) {
    yield put(failedRemovePlayerAction());
  }
}

function* setMatchTypeSaga(action: AnyAction) {
  try {
    yield call(setMatchTypeAction, action.payload);
    yield put(successSetMatchTypeAction());
  } catch (e) {
    yield put(failedSetMatchTypeAction());
  }
}

function* setMatchStartDateSaga(action: AnyAction) {
  try {
    yield call(setMatchStartDateAction, action.payload);
    yield put(successSetMatchStartDateAction());
  } catch (e) {
    yield put(failedSetMatchStartDateAction());
  }
}

function* setMatchStartTimeSaga(action: AnyAction) {
  try {
    yield call(setMatchStartTimeAction, action.payload);
    yield put(successSetMatchStartTimeAction());
  } catch (e) {
    yield put(failedSetMatchStartTimeAction());
  }
}

function* chooseTeamOpponentSaga(action: AnyAction) {
  try {
    yield call(chooseTeamOpponentAction, action.payload);
    yield put(successChooseTeamOpponentAction());
  } catch (e) {
    yield put(failedChooseTeamOpponentAction());
  }
}

function* resetScrimDraftSaga() {
  try {
    yield call(() => resetScrimDraftAction());
    yield put(successResetScrimDraftAction());
  } catch (e:any) {
    yield put(failedResetScrimDraftAction());
  }
}

function* scrimDraftSaga() {
  yield takeLatest(getGameSelectedAction.type, getGameSelectedSaga);
  yield takeLatest(addPlayersAction.type, addPlayersSaga);
  yield takeLatest(removePlayerAction.type, removePlayerSaga);
  yield takeLatest(setMatchTypeAction.type, setMatchTypeSaga);
  yield takeLatest(setMatchStartDateAction.type, setMatchStartDateSaga);
  yield takeLatest(setMatchStartTimeAction.type, setMatchStartTimeSaga);
  yield takeLatest(chooseTeamOpponentAction.type, chooseTeamOpponentSaga);
  yield takeLatest(resetScrimDraftAction.type, resetScrimDraftSaga);
}

export default scrimDraftSaga;


