import React from 'react';
import AppLayout from 'components/layouts/AppLayout';
import { ImageSource } from 'modules/assetpath';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import GradientButton from 'components/Buttons/GradientButton';
import history from 'modules/history';

function PageError(p:{errorCode: string; errorMessage: string}) {
  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'mabar-typeface-white',
          height: '32px',
          width: '107px',
        },
        navright: {
          bg: '',
          variant: '',
        },
        isDisable: false,
      }}
      backgroundImage={ImageSource('app.background')}>

      <Box width='100%' height='100%'>
        <Stack direction="column" align='center' disableSelectText disableDrag>
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="90px"
            fontWeight="500"
            lineHeight="140%"
            margin="8px 0px"
          >
            {p.errorCode}
          </NormalText>
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="18px"
            fontWeight="700"
            lineHeight="140%"
            margin="8px 0px"
          >
            {p.errorMessage}
          </NormalText>
          <Stack direction="row" align="center" margin='24px 0px 0px'>
            <GradientButton
              styled={{ backgroundSize: '100%' }}
              variant="primary"
              onClick={() => history.replace('/')}
            >
              Kembali Ke Homepage
            </GradientButton>
          </Stack>
        </Stack>
      </Box>
    </AppLayout>
  );
}

export default PageError;
