import { Theme, Box, BoxProps } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface TagProps extends BoxProps {
  theme?: Theme;
  variant?: 'green' | 'orange' | 'pink' | 'red' | 'champion';
  label?: string | ReactNode;
  sx?: SxProps<Theme>;
}

const ribbonWing = {
  content: "''",
  position: 'absolute',
  top: 0,
  width: '10px',
  height: '18px',
  backgroundImage: `url(${IconSource('ribbon-wing', 'png')})`,
  backgroundSize: 'cover',
};

const variantTextColor = (variant: any, theme: any) => {
  switch (variant) {
    case 'green':
      return theme.palette.success700;
    case 'orange':
      return theme.palette.primary700;
    case 'pink':
      return theme.palette.danger700;
    case 'red':
      return theme.palette.danger700;
    case 'champion':
      return 'linear-gradient(98.52deg, #D51F40 -24.48%, #FF7E33 55.77%)';
    default:
      return theme.palette.neutral550;
  }
};

const variantBackground = (variant: any, theme: any, stand?: boolean) => {
  switch (variant) {
    case 'green':
      return theme.palette.success500;
    case 'orange':
      return theme.palette.primary500;
    case 'pink':
      return theme.palette.danger500;
    case 'red':
      return '#8B030C';
    case 'champion':
      return stand ? '#C94E1E' : 'linear-gradient(98.52deg, #D51F40 -24.48%, #FF7E33 55.77%)';
    default:
      return theme.palette.neutral400;
  }
};

const CustomTag: any = styled(Box, { name: 'competition-title' })<TagProps>(
  ({ theme, variant }) => ({
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    zIndex: 5,
    '.bgText': {
      position: 'relative',
      display: 'flex',
      minWidth: '275px',
      minHeight: '19px',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.neutral150,
      zIndex: 5,
      '&:before': {
        ...ribbonWing,
        left: '-8px',
      },
      '&:after': {
        ...ribbonWing,
        right: '-8px',
        transform: 'scaleX(-1)',
      },
    },
    span: {
      color: variantTextColor(variant, theme),
      backgroundImage: variant === 'champion' ? variantTextColor(variant, theme) : 'unset',
      backgroundClip: variant === 'champion' ? 'text' : 'unset',
      textFillColor: variant === 'champion' ? 'transparent' : 'unset',
    },
    '.shadow': {
      width: 'calc(100% - 2px)',
      height: '10px',
      position: 'absolute',
      top: '12px',
      left: '1px',
      zIndex: 0,
      borderRadius: '40px',
      background: variantBackground(variant, theme),
    },
    '.stand': {
      width: 'calc(100% + 15px)',
      height: 0,
      position: 'absolute',
      top: 0,
      zIndex: -1,
      borderBottom: `10px solid ${variantBackground(variant, theme, true)}`,
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: '2.5px',
        left: '-5px',
        width: 'calc(100% + 10px)',
        height: '7px',
        boxShadow: 'inset 0px -1px 2px rgba(0, 0, 0, 0.3)',
        borderRadius: '15px 15px 0 0',
      },
    },
  }),
);

export const CompetitionStatus = ({ label, sx, ...props }: TagProps) => {
  return (
    <CustomTag sx={{ ...sx }} {...props}>
      <Box
        className="tag-content"
        sx={{ position: 'relative', display: 'inline-flex', justifyContent: 'center' }}
      >
        <Box className="bgText">
          <Text variant="ribbon" component="span" sx={{ textTransform: 'uppercase' }}>
            {label}
          </Text>
        </Box>
        <Box className="stand" />
        <Box className="shadow" />
      </Box>
    </CustomTag>
  );
};
