import React, { useState } from 'react';
import { CardRadio } from 'components/RadioButton/CardRadio';
import { ImageSource } from 'modules/assetpath';
import AppLayout from 'components/layouts/AppLayout';
import { Team } from 'types/team';
import { STATUS } from 'literals';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { chooseTeamOpponentAction } from 'redux/scrim/ScrimDraftReducer';
import { useAppThunkDispatch } from 'redux/store';
import history from 'modules/history';
import { fetchTeamChallangeList } from 'redux/team/TeamListReducer';
import { fetchPlayerProfile, getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';
import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { dateFormat } from 'modules/helpers';
import _ from 'lodash';
import moment from 'moment';

export const SearchDiv = styled.div<any>`
  position: fixed;
  z-index: 199;
  background: #373942f0;
  width: 100%;
  align-self: center;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 500px) {
    max-width: 500px;
  }
`;

const ChooseTeam = () => {
  const [getTeamList, setTeamList] = useState<Team[]>([]);
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const currentTeam = getMyCurrentTeamFromSession()
  const [getErrorList, setErrorList] = React.useState<any>({})
  const playerProfileThunk = fetchPlayerProfile({ username: getPlayerProfileFromSession().userName })
  const fetchAccountProfile = () => {
    thunkDispatch(playerProfileThunk).unwrap().then((res) => {
      res && res.id
    })
  }
  React.useEffect(() => {
    fetchAccountProfile()
  }, [thunkDispatch])
  const status = useAppSelector(({ scrimDraft }) => scrimDraft.status);
  const gameId = useAppSelector(({ scrimDraft }) => scrimDraft.gameSelected.id);
  const matchTime = useAppSelector(({ scrimDraft }) => scrimDraft.startTime);
  const teamPrevSelected = useAppSelector(({ scrimDraft }) => scrimDraft.teamOpponent) as Team;
  const saveTeamOpponent = React.useCallback(
    (team: Team) => dispatch(chooseTeamOpponentAction({ teamOpponent: team })),
    [dispatch],
  );
  const handleClickBack = () => {
    history.push('/create-scrim');
  };

  const parseError = (errors: any) => {
    if (errors) {
      let errorsList: string[] = [];
      if (errors.errors) {
        Object.values(errors.errors).forEach((prop: any) => {
          errorsList = errorsList.concat(_.map(prop, _.property('matchTime')));
        });
      } else {
        Object.values(errors).forEach((prop: any) => {
          errorsList = errorsList.concat(_.map(prop, _.property('matchTime')));
        });
      }
      return errorsList.join('\n');
    }
    return null;
  };

  const fetchScrimChallanged = React.useCallback(
    (mT: string, gameId: string, pI: number, pS: number, search?: string) => {
      thunkDispatch(fetchTeamChallangeList({ matchTime: mT, gameId: gameId, pageIndex: pI, pageSize: pS, search: search })).unwrap()
        .then((res) => {
          if (res && res?.isAxiosError && res?.errors?.status >= 400 || res?.status >= 400) {
            setErrorList(res.errors)
          } else if (res) {
            setTeamList(res.items)
          }
        }).catch(() => {

        })
    },
    [thunkDispatch],
  );

  React.useEffect(() => {
    fetchScrimChallanged(moment(new Date(matchTime)).toDate().toISOString(), gameId, 1, 100)
  }, [matchTime, gameId]);

  const chooseTeamByID = (id: string) => {
    const teamSelected = getTeamList.find(e => {
      return e.id === id;
    }) as Team;
    saveTeamOpponent(teamSelected);
    if (status === STATUS.SUCCESS) {
      history.push('/create-scrim');
    }
  };
  return (
    <AppLayout
      appBar={{
        bg: {
          bRadius: '0px',
          background: 'rgba(36, 39, 49, 0.94)',
          boxShadow: 'none',
          height: '56px',
          pos: 'fixed',
        },
        navleft: {
          bg: 'ic-arrow-back-light',
          height: '24px',
          width: '24px',
          onClick: handleClickBack,
        },
        navright: {
          variant: '',
        },
        onClick: () => null,
        title: 'Pilih Tim Yang Akan Ditantang',
      }}
      backgroundImage={ImageSource('app.background')}
      searchBar={{
        id: 'scrim-search-team',
        placeholder: 'Cari Tim...',
        onChange: (e) => {
          fetchScrimChallanged(moment(new Date(matchTime)).toDate().toISOString(), gameId, 1, 100, e.currentTarget.value ?? undefined)
        },
        onKeyDown: (e) => {
          if (e.key === 'Enter')
            fetchScrimChallanged(moment(new Date(matchTime)).toDate().toISOString(), gameId, 1, 100, e.currentTarget.value)
        }
      }}
    >
      <div style={{ marginTop: '16px' }}>
        {getTeamList && getTeamList.length > 0
          ? getTeamList.map(data => {
            if (currentTeam.find((v) => v.id !== data.id)) {
              return (
                <div key={data.id} style={{ marginBottom: '10px' }}>
                  <CardRadio
                    gameText={data.game?.name}
                    matchText={`${data?.memberCount ?? 0} Anggota, ${data?.matchCount ?? 0} Pertandingan`}
                    bg={data.avatarUrl}
                    onChange={e => {
                      chooseTeamByID(e as string);
                    }}
                    value={data.id}
                    checked={teamPrevSelected.id === data.id}
                  >
                    {data.name}
                  </CardRadio>
                </div>
              );
            } else {
              return (<></>
              )
            }
          })
          : (
            <Stack direction='row' align='center'>
              <NormalText fontSize='13px' color={getErrorList && getErrorList?.errors ? '#FF4D6D' : '#969393'} fontWeight='700' textAlign='center'>
                {getErrorList && getErrorList ? parseError(getErrorList) : `Saat ini tidak ada tim di Jadwal ${dateFormat(new Date(matchTime)).format('DD MMM YYYY - HH:mm')} WIB`}
              </NormalText>
            </Stack>
          )}
      </div>
    </AppLayout>
  );
};

export default ChooseTeam;
