import moment from 'moment';
import React, { FC } from 'react';
import 'moment/locale/id';
import { NotificationContainer, TextContent, TextDate, TextSeeDetail } from './notification.style';
import { INotificationData } from 'redux/notification/NotificationReducer';
import { useTranslation } from 'react-i18next';

export interface NotificationItemProp {
  id: string;
  code: string;
  title: string;
  isRead: boolean;
  data: INotificationData;
  onClickItem?: () => void;
  onClickDetail?: () => void;
  seeDetails?: boolean;
  createAt: string;
}

const NotificationItem: FC<NotificationItemProp> = ({
  createAt,
  title,
  isRead,
  seeDetails,
  onClickDetail,
  onClickItem,
}) => {
  const {t} = useTranslation()
  return (
    <NotificationContainer isRead={isRead} onClick={onClickItem}>
      <TextDate isRead={isRead}>
        {moment(createAt).locale('id').format('dddd, DD MMMM YYYY')}
      </TextDate>
      <TextContent isRead={isRead} dangerouslySetInnerHTML={{ __html: title }} />
      {seeDetails && (
        <TextSeeDetail isRead={isRead} onClick={onClickDetail}>
          {t('notification_see_detail')}
        </TextSeeDetail>
      )}
    </NotificationContainer>
  );
};

export default NotificationItem;
