import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export interface FormError {
    message: string;
    type: string;
    ref: Ref;
  }
  
export interface Ref {
    name: string;

}
const EditProfileValidationSchema = Yup.object().shape({
nickName:Yup.string()
    .required('Diperlukan Nickname')
    .min(1, 'Nickname minimal 1 karakter')
    .max(50, 'Nickname maksimal 50 karakter'),
gameProviderId: Yup.string()
    .required('Wajib Diisi')
    .matches(/^[0-9]+$/, 'Game Id harus angka')
    .max(12, 'Game Id maksimal 12 karakter')
});

export const EditProfileValidationOptions = { resolver: yupResolver(EditProfileValidationSchema) };