import { Button } from 'components/styled/button.sytled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC } from 'react';

export interface MatchCardType {
  score?: string | undefined;
  status?: string | undefined;
  time?: string | undefined;
  matchType?: string | undefined;
}

const MatchCard: FC<MatchCardType> = ({ score, status, time, matchType }) => {
  return (
    <Box padding="8px" flexShrink={0}>
      <Stack direction="column" align="center">
        {score && (
          <NormalText fontSize="24px" fontWeight="900" margin="4px" color="#FFFFFF">
            {score}
          </NormalText>
        )}
        {status && (
          <NormalText
            fontSize="14px"
            fontWeight="700"
            margin="4px"
            padding="0px 0px 0px 4px"
            color="rgba(150, 147, 147, 1)"
            letterSpacing="1px"
          >
            {status}
          </NormalText>
        )}
        {time && (
          <NormalText
            fontSize="14px"
            fontWeight="700"
            margin="4px"
            padding="0px 0px 0px 4px"
            color="rgba(150, 147, 147, 1)"
            letterSpacing="1px"
          >
            {time}
          </NormalText>
        )}
        {matchType && (
          <Button background="rgba(255, 255, 255, 0.1)" borderRadius="5px" padding="2px 4px">
            <NormalText fontSize="12px" fontWeight="400" color="rgba(150, 147, 147, 1)">
              {matchType}
            </NormalText>
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default MatchCard;
