import { UserManager } from "oidc-client";
import React from "react";
import { getProfileByUsername } from "redux/account/AccountServices";
import { userManager } from "services/AuthService";
import { Constants, IDENTITY_CONFIG, METADATA_OIDC } from "services/Constants";
import { MyCurrentTeam } from "types/team";

const signSilent = new UserManager({
    ...IDENTITY_CONFIG,
    metadata: {
      ...METADATA_OIDC,
    }})
    .signinSilentCallback()
    .then(async (user) => {
        if (user && user.profile) {
            userManager.storeUser(user)
            var myCurrentTeam: Array<MyCurrentTeam> = user.profile['team']
            Constants.encryptStorage.setItem('current_team', JSON.stringify(myCurrentTeam))
            await getProfileByUsername(user.profile.user_name, undefined, {
                Authorization: `${user.token_type ?? 'Bearer'} ${user.access_token}`,
            })
                .then(res => {
                    if (res.status === 200) {
                        Constants.encryptStorage.setItem('player_profile_info', JSON.stringify(res.data));
                    }
                })
                .catch(() => { });
        }
    })
    .catch(() => { });

const SignSilentCallback = () => {
    const Callback = React.createContext(signSilent);
    return (
        <Callback.Provider value={signSilent} />
    )
}    

export default SignSilentCallback