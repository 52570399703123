import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import React from 'react';

export interface ScrimItemProps {
  id: string;
  startAt: string;
  teamChallengerLogo: string;
  teamChallengerName: string;
  schemaMatch?: string;
  data?: any;
  onScrimClick: (data: any) => void;
}

function ScrimItem({
  id,
  startAt,
  teamChallengerLogo,
  teamChallengerName,
  schemaMatch,
  data,
  onScrimClick,
}: ScrimItemProps) {
  return (
    <Box
      disableDrag
      isPressble
      background="rgba(55, 57, 66, 0.6)"
      width="100%"
      height="131px"
      borderRadius="16px"
      margin="12px 0px"
      padding="0px 0px 16px 0px"
      onClick={() => {
        if (onScrimClick) onScrimClick(data);
      }}
    >
      <Stack direction="column" align="start" disableSelectText margin="16px">
        <Stack direction="row" align="between">
          <Box background="rgba(255, 255, 255, 0.16)" borderRadius="6px" padding="6px">
            <NormalText fontSize="12px" color="#DDD8D8" fontWeight="normal" lineHeight="140%">
              Scrim #{id}
            </NormalText>
          </Box>
          <Box borderRadius="6px" padding="3px">
            <NormalText fontSize="12px" color="#DDD8D8" fontWeight="normal" lineHeight="140%">
              {schemaMatch}
            </NormalText>
          </Box>
        </Stack>
        <NormalText
          fontSize="16px"
          color="white"
          fontWeight="bold"
          lineHeight="140%"
          margin="20px 0px"
        >
          {startAt}
        </NormalText>

        <Stack direction="row" align="start">
          <Avatar
            src={teamChallengerLogo}
            width="24px"
            height="24px"
            border="2px solid #F1EBEB"
            borderRadius="50%"
            fit="contain"
            alt=""
            onError={(e) => {
              e.currentTarget.style.backgroundColor = "#B1B1B1"
              e.currentTarget.src = IconSource('union-placeholder')
              e.currentTarget.style.objectPosition = "bottom"
            }}
          />
          <NormalText
            margin="5px 0px 0px 10px"
            fontSize="14px"
            color="white"
            fontWeight="bold"
            lineHeight="16.8px"
          >
            {teamChallengerName}&nbsp;&nbsp;-&nbsp;&nbsp;
            <a
              style={{
                fontSize: '14px',
                color: 'rgba(255,255,255,0.7)',
                fontWeight: 500,
                lineHeight: '16.8px',
              }}
            >
              Penantang
            </a>
          </NormalText>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ScrimItem;
