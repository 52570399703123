import { createSlice } from '@reduxjs/toolkit';
import {
  AccountPagedResponse,
  AccountProfile,
  AccountValidationProblemDetails,
  NewGameItem,
  PlayerScrimHistory,
  UserClaim,
} from './AccountResponse';
import {
  addNewGameInformation,
  changeEmail,
  changePhoneNumber,
  changePassword,
  editAccountProfile,
  fetchGameAvailableList,
  fetchPlayerProfile,
  fetchPlayerScrimHistory,
  fetchUserClaim,
  verifyEmail,
  verifyPhoneNumber,
} from './AccountServices';

export interface AccountState {
  data?: AccountProfile;
  gameAvailable?: AccountPagedResponse<NewGameItem[]>;
  playerScrimHisory?: AccountPagedResponse<PlayerScrimHistory[]>;
  userClaim?: UserClaim[];
  errors?: string | AccountValidationProblemDetails;
  status?: 'idle' | 'loading' | 'succeeded' | 'failed' | 'error';
}

const initialState: AccountState = {
  errors: '',
  status: 'idle',
};

const AccountSlice = createSlice({
  name: 'account',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerProfile.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(fetchPlayerProfile.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchPlayerProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchGameAvailableList.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchGameAvailableList.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchGameAvailableList.fulfilled, (state, action) => {
        state.gameAvailable = action.payload;
        state.status = 'succeeded';
      })
      .addCase(addNewGameInformation.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(addNewGameInformation.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(addNewGameInformation.fulfilled, (state, action) => {
        if (action.payload.isAxiosError && action.payload.data.errors) {
          state.errors = action.payload.data as AccountValidationProblemDetails;
          state.status = 'error';
        } else {
          state.errors = undefined;
          state.status = 'succeeded';
        }
      })
      .addCase(fetchPlayerScrimHistory.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchPlayerScrimHistory.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchPlayerScrimHistory.fulfilled, (state, action) => {
        state.playerScrimHisory = action.payload;
        state.status = 'succeeded';
      })      
      .addCase(editAccountProfile.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(editAccountProfile.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(editAccountProfile.fulfilled, (state, action) => {
        if (action.payload.isAxiosError && action.payload.data) {
          state.errors = action.payload.data as AccountValidationProblemDetails;
          state.status = 'error';
        } else {
          state.errors = undefined;
          state.status = 'succeeded';
        }
      })     
      .addCase(changeEmail.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(changeEmail.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(changeEmail.fulfilled, (state, action) => {
        if (action.payload.isAxiosError && action.payload.data) {
          state.errors = action.payload.data as AccountValidationProblemDetails;
          state.status = 'error';
        } else {
          state.errors = undefined;
          state.status = 'succeeded';
        }
      }) 
      .addCase(verifyEmail.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        if (action.payload.isAxiosError && action.payload.data) {
          state.errors = action.payload.data as AccountValidationProblemDetails;
          state.status = 'error';
        } else {
          state.errors = undefined;
          state.status = 'succeeded';
        }
      })
      .addCase(changePhoneNumber.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(changePhoneNumber.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(changePhoneNumber.fulfilled, (state, action) => {
        if (action.payload.isAxiosError && action.payload.data) {
          state.errors = action.payload.data as AccountValidationProblemDetails;
          state.status = 'error';
        } else {
          state.errors = undefined;
          state.status = 'succeeded';
        }
      })
      .addCase(verifyPhoneNumber.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(verifyPhoneNumber.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(verifyPhoneNumber.fulfilled, (state, action) => {
        if (action.payload.isAxiosError && action.payload.data) {
          state.errors = action.payload.data as AccountValidationProblemDetails;
          state.status = 'error';
        } else {
          state.errors = undefined;
          state.status = 'succeeded';
        }
      })
      .addCase(fetchUserClaim.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(fetchUserClaim.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchUserClaim.fulfilled, (state, action) => {
        if (action.payload.status >= 400) {
          state.errors = action.payload.statusText;
          state.status = 'error';
        } else {
          state.errors = undefined as any;
          state.userClaim = action.payload.data;
          state.status = 'succeeded';
        }
      })    
      .addCase(changePassword.pending, (state, _action) => {
        state.errors = undefined;
        state.status = 'loading';
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        if (action.payload.isAxiosError && action.payload.data) {
          state.errors = action.payload.data as AccountValidationProblemDetails;
          state.status = 'error';
        } else {
          state.errors = undefined;
          state.status = 'succeeded';
        }
      })
  },
});

export default AccountSlice.reducer;
