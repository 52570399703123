import React, { ReactNode, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { TabsContainer, TabsItems, TabsLink } from './styles';

export interface TabsContainerProps {
  position?: string;
  background?: string;
  width?: string;
  height?: string;
  bShadow?: string;
  margin?: string;
  padding?: string;
  children?: ReactNode;
  css?: React.CSSProperties;
}

export interface TabsItemProps {
  position?: string;
  background?: string;
  isselected?: 'true' | 'false';
  height?: string;
  bRadius?: string;
  margin?: string;
  padding?: string;
  children?: ReactNode;
}

export interface ITabsItemProps {
  title?: string;
  isselected?: 'true' | 'false';
  dest?: string;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined;
  children?: ReactNode;
}

export interface TabsLayoutProps {
  container?: TabsContainerProps;
  tabs?: TabsItemProps;
  link: ITabsItemProps[];
  children?: ReactNode;
}

export const initTabsData: ITabsItemProps[] = [
  {
    title: 'Home',
    dest: '/#1',
    isselected: 'true',
  },
  {
    title: 'Trophy',
    dest: '/#23',
    isselected: 'false',
  },
  {
    title: 'Event',
    dest: '/#2',
    isselected: 'false',
  },

  {
    title: 'Teams',
    dest: '/#3',
    isselected: 'false',
  },

  {
    title: 'My Profile',
    dest: '/#4',
    isselected: 'false',
  },
];

export const clickTabsHandle = (url: string) => {
  window.location.href = url;
};

export function TabsLayout({ container, tabs, link, children }: TabsLayoutProps) {
  const [dataTabMenu, setdataTabMenu] = useState<ITabsItemProps[]>(link);
  const clickHandle = (nav: ITabsItemProps) => {
    setdataTabMenu(
      dataTabMenu.map((data: ITabsItemProps) => {
        if (data.dest === nav.dest) {
          return { key: data.dest, ...data, isselected: 'true' };
        }
        return { key: data.dest, ...data, isselected: 'false' };
      }),
    );
  };
  return (
    <TabsContainer
      position={container?.position}
      background={container?.background}
      width={container?.width}
      height={container?.height}
      bShadow={container?.bShadow}
      margin={container?.margin}
      padding={container?.padding}
      style={container?.css}
    >
      {children}
      {dataTabMenu.map((data: ITabsItemProps) => (
        <TabsItems
          onClick={() => {
            document.getElementById(data.title ?? '')?.click();
          }}
          isselected={data.isselected}
          position={tabs?.position}
          margin={tabs?.margin}
          padding={tabs?.padding}
          key={data.dest}
        >
          <TabsLink
            id={data.title}
            to={data?.dest!!}
            isselected={data?.isselected}
            onClick={() => clickHandle(data)}
          >
            {data?.title}
          </TabsLink>
        </TabsItems>
      ))}
    </TabsContainer>
  );
}

export default TabsLayout;
