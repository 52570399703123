import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from 'literals';
import { GameOptions } from 'redux/game/GameReducer';
import { Player } from 'redux/team/TeamMemberReducer';
import { ValueOf } from 'type-fest';
import { Status } from 'types';
import { Team } from 'types/team';
import dayjs, { Dayjs } from 'dayjs';

export interface FluentValidationProblemDetails {
  errors?: Errors;
  traceId?: string;
  type?: string;
  title?: string;
  status?: number;
}

export interface Errors {
  players: ErrorItem[];
  gameId: ErrorItem[];
  startAt: ErrorItem[];
  secondTeamId: ErrorItem[];
}

export interface ErrorItem {
  code: string;
  message: string;
  formatedValue: FormatedValue;
}

export interface FormatedValue {
  minRoasters: number;
}

export interface ScrimDraftState {
  id: string;
  matchType: string;
  startDate: Dayjs;
  startTime: string;
  startAt: Dayjs;
  playerList: Player[];
  gameSelected: GameOptions;
  teamOpponent: Team;
  loading: boolean;
  status: ValueOf<Status>;
  code: number;
  errors: FluentValidationProblemDetails;
}

export const initialState: ScrimDraftState = {
  id: '',
  matchType: '',
  startDate: dayjs(),
  startTime: '',
  startAt: dayjs(),
  playerList: [],
  gameSelected: {
    id: '',
    avatarUrl: '',
    iconUrl: '',
    maxMember: 0,
    minMember: 0,
    name: '',
    release: false,
    urlSlug: '',
    scrimCount: 0,
    teamCount: 0,
    tournamentCount: 0,
    maxScrimRoster: 0,
    minScrimRoster: 0,
    maxTeamMember: 0,
    minTeamMember: 0,
  },
  teamOpponent: {
    id: '',
    name: '',
  },
  loading: false,
  status: STATUS.IDLE,
  code: 0,
  errors: {},
};

const scrimDraftSlice = createSlice({
  name: 'scrimDraft',
  initialState,
  reducers: {    
    getGameSelectedAction: (state, action: PayloadAction<{ game: GameOptions }>) => {
      state.gameSelected = action.payload.game;
      state.loading = true;
      state.status = STATUS.RUNNING;
    },
    successGetGameSelectedAction: state => {
      state.loading = false;
      state.status = STATUS.SUCCESS;
    },
    failedGetGameSelectedAction: state => {
      state.loading = false;
      state.status = STATUS.ERROR;
    },
    addPlayersAction: (state, action: PayloadAction<{ playerList: Player[] }>) => {
      state.playerList = action.payload.playerList;
      state.loading = true;
      state.status = STATUS.RUNNING;
    },
    successAddPlayersAction: state => {
      state.loading = false;
      state.status = STATUS.SUCCESS;
    },
    failedAddPlayersAction: state => {
      state.loading = false;
      state.status = STATUS.ERROR;
    },
    removePlayerAction: (state, action: PayloadAction<{ player: Player }>) => {
      state.playerList.map((data, i) => {
        if (data.id === action.payload.player.id) state.playerList.splice(i, 1);
      });
      state.loading = true;
      state.status = STATUS.RUNNING;
    },
    successRemovePlayerAction: state => {
      state.loading = false;
      state.status = STATUS.SUCCESS;
    },
    failedRemovePlayerAction: state => {
      state.loading = false;
      state.status = STATUS.ERROR;
    },
    chooseTeamOpponentAction: (state, action: PayloadAction<{ teamOpponent: Team }>) => {
      state.teamOpponent = action.payload.teamOpponent;
      state.loading = true;
      state.status = STATUS.RUNNING;
    },
    successChooseTeamOpponentAction: state => {
      state.loading = false;
      state.status = STATUS.SUCCESS;
    },
    failedChooseTeamOpponentAction: state => {
      state.loading = false;
      state.status = STATUS.ERROR;
    },
    setMatchTypeAction: (state, action: PayloadAction<{ matchType: string }>) => {
      state.matchType = action.payload.matchType;
      state.loading = true;
      state.status = STATUS.RUNNING;
    },
    successSetMatchTypeAction: state => {
      state.loading = false;
      state.status = STATUS.SUCCESS;
    },
    failedSetMatchTypeAction: state => {
      state.loading = false;
      state.status = STATUS.ERROR;
    },
    setMatchStartDateAction: (state, action: PayloadAction<{ startDate: Dayjs }>) => {
      const temp = action.payload.startDate;
      //temp.setDate(temp.getDate() + 1)
      state.startDate = temp;
      state.loading = true;
      state.status = STATUS.RUNNING;
    },
    successSetMatchStartDateAction: state => {
      state.loading = false;
      state.status = STATUS.SUCCESS;
    },
    failedSetMatchStartDateAction: state => {
      state.loading = false;
      state.status = STATUS.ERROR;
    },
    setMatchStartTimeAction: (state, action: PayloadAction<{ startTime: string }>) => {
      state.startTime = action.payload.startTime;
      state.loading = true;
      state.status = STATUS.RUNNING;
    },
    successSetMatchStartTimeAction: state => {
      state.loading = false;
      state.status = STATUS.SUCCESS;
    },
    failedSetMatchStartTimeAction: state => {
      state.loading = false;
      state.status = STATUS.ERROR;
    },
    resetScrimDraftAction: state => {
      state.loading = true;
      state.status = STATUS.RUNNING;
    },
    successResetScrimDraftAction: (state) => {
      state.loading = false;
      state.matchType = initialState.matchType;
      state.startDate = initialState.startDate;
      state.startAt = initialState.startAt;
      state.startTime = initialState.startTime;
      state.teamOpponent = initialState.teamOpponent;
      state.playerList = initialState.playerList;
      state.status = STATUS.SUCCESS;
    },
    failedResetScrimDraftAction: (state) => {
      state.loading = false;
      state.status = STATUS.ERROR;
    }
  },
});

export const {
  getGameSelectedAction,
  successGetGameSelectedAction,
  failedGetGameSelectedAction,
  addPlayersAction,
  successAddPlayersAction,
  failedAddPlayersAction,
  removePlayerAction,
  successRemovePlayerAction,
  failedRemovePlayerAction,
  chooseTeamOpponentAction,
  failedChooseTeamOpponentAction,
  successChooseTeamOpponentAction,
  setMatchTypeAction,
  successSetMatchTypeAction,
  failedSetMatchTypeAction,
  setMatchStartDateAction,
  successSetMatchStartDateAction,
  failedSetMatchStartDateAction,
  setMatchStartTimeAction,
  failedSetMatchStartTimeAction,
  successSetMatchStartTimeAction,
  resetScrimDraftAction,
  successResetScrimDraftAction,
  failedResetScrimDraftAction
} = scrimDraftSlice.actions;

export default scrimDraftSlice.reducer;
