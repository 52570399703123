import GradientButton from 'components/Buttons/GradientButton';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { ReactNode } from 'react';

interface MessageModalProps {
  title?: ReactNode;
  btnConfirmText?: ReactNode;
  onConfirmClick?: () => void;
  children?: ReactNode;
}

function MessageModal({ title, children, btnConfirmText, onConfirmClick }: MessageModalProps) {
  return (
    <Box width="100%" height="auto" padding="24px 0px 24px 0px">
      <Stack direction="column" align="center" margin=" 0px 16px 0px 16px">
        <NormalText
          color="white"
          fontSize="16px"
          fontWeight="700"
          textAlign="center"
          lineHeight="22.4px"
        >
          {title}
        </NormalText>
        {children}
        <Stack direction="column" align="center" margin="24px 0px 0px 0px">
          <GradientButton
            variant="primary"
            padding="12px"
            onClick={onConfirmClick}
            styled={{ backgroundSize: '100%' }}
          >
            {btnConfirmText}
          </GradientButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default MessageModal;
