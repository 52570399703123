import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";
import { SchoolListProps, SchoolProps } from "./SchoolProps";
import { School, SchoolBaseResponse } from "./SchoolResponse";

export const fetchSchoolList = createAsyncThunk(
    'school/school-list',
    async ({ cityCode, pageIndex, pageSize, search, version }: SchoolListProps) => {
      const url = `/${
        version ? `v${version}/` : ''
      }school/${cityCode}/${pageIndex}/${pageSize}${search ? `?search=${search}` : ''}`;
      const res = await ApiService.get<SchoolBaseResponse<School[]>>(url);
      return res.data;
    },
  );
  
  export const fetchSchoolDetail = createAsyncThunk(
    'school/school-detail',
    async ({ urlSlug, version }: SchoolProps) => {
      const url = `/${version ? `v${version}/` : ''}school/${urlSlug}`;
      const res = await ApiService.get<School>(url);
      return res.data;
    },
  );