import MatchList from './MatchList';
import NotFound from './NotFound';
import { Stack, Box } from '@mui/material';
import ScrollLoadMore from 'components/ScrollView/ScrollLoadMore';
import { FindMyMatch } from 'design/Cards/FindMyMatch';
import { TextInputSearch } from 'design/Forms/TextInputSearch';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { MatchesResponse } from 'redux/match/matchProps';
import { fetchMatchList } from 'redux/match/matchServices';
import { useAppThunkDispatch } from 'redux/store';
import { TournamentDetailResponse } from 'redux/tournament/TournamentProps';

const SearchLoader = () => (
  <Box
    sx={theme => ({
      display: 'inherit',
      bgcolor: 'neutral700',
      p: '10px',
      mt: '64px !important',
      border: `1px solid ${theme.palette.neutral800}`,
      borderRadius: theme.borderRadius.md,
    })}
  >
    <img src={IconSource('loader-orange', 'gif')} alt="loader" />
  </Box>
);

export interface MatchProps {
  isAuthenticated: boolean;
  gameSlug?: string;
  tournamentSlug?: string;
  tournamentDetail?: TournamentDetailResponse;
}

const MatchPage = ({ isAuthenticated, gameSlug, tournamentSlug, tournamentDetail }: MatchProps) => {
  const location = useLocation<any>();

  const [matchListSearch, setMatchListSearch] = useState<MatchesResponse['matches']>();
  const [searchMatchList, setSearchMatchList] = useState('');
  const [delaySearch, setDelaySearch] = useState(false);
  const [isNotFound, setNotFound] = useState(false);

  const dispatch = useAppThunkDispatch();

  const { t } = useTranslation();

  const { matchListStatus, matchList, schoolSlug, myTeam } = useAppSelector(
    ({ match, account, myTeam }) => ({
      matchListStatus: match?.status?.matchList,
      matchList: match?.matchList,
      schoolSlug: account?.data?.school?.urlSlug,
      myTeam: myTeam?.data?.items,
    }),
  );

  const handleFetchMatchList = (tournamentId: string) => {
    dispatch(fetchMatchList({ tournamentId: tournamentId }));
  };

  useEffect(() => {
    if (tournamentDetail) {
      handleFetchMatchList(tournamentDetail.id);
    }
  }, [tournamentDetail]);

  useEffect(() => {
    if (matchList && !matchList.matches) setNotFound(true);
  }, [matchList]);

  const [loadMatchList, setLoadMatchList] = useState(10);

  const loadMore = () => setLoadMatchList(loadMatchList + 10);

  useEffect(() => {
    if (location && location.state && location.state.search) {
      return setSearchMatchList(location.state.search);
    }
  }, [location]);

  useEffect(() => {
    const matchesLength = (handleMatches ?? [])?.length;
    const scrollMatchTarget = location && location.state && location.state.scrollMatchTarget;
    const scrollMatchTargetNumber = scrollMatchTarget + 1;

    if (scrollMatchTarget) {
      if (scrollMatchTargetNumber > matchesLength) {
        return setLoadMatchList(scrollMatchTargetNumber + 10);
      }
    }
  }, [location]);

  const handleSearch = (value: string) => {
    history.push({ ...location, state: {} });
    setDelaySearch(true);
    setSearchMatchList(value);
  };

  const searchResult = (search: string) =>
    (matchList?.matches ?? []).filter(match => {
      const searchFirstTeam = match.firstTeam ?? '';
      const searchFirstTeamSchool = match.firstTeamSchool ?? '';
      const searchSecondTeam = match.secondTeam ?? '';
      const searchSecondTeamSchool = match.secondTeamSchool ?? '';

      const target = `${searchFirstTeam} ${searchFirstTeamSchool} ${searchSecondTeam} ${searchSecondTeamSchool}`;

      return target.toLowerCase().includes(search.toLowerCase());
    });

  useEffect(() => {
    if (searchMatchList) setMatchListSearch(searchResult(searchMatchList));
    setTimeout(() => {
      setDelaySearch(false);
    }, 1000);
  }, [searchMatchList, matchList]);

  const matchListWithLimit =
    matchList?.matches && matchList?.matches.slice(0, loadMatchList).map(match => match);

  const matchListSearchWithLimit = matchListSearch?.slice(0, loadMatchList).map(match => match);

  const matches = searchMatchList ? matchListSearchWithLimit : matchListWithLimit;

  const handleMatches = (matches ?? []).length > 0 ? matches : null;

  const teamSlug = myTeam && myTeam[0]?.urlSlug;

  const MatchListResult = () => {
    return delaySearch ? (
      <SearchLoader />
    ) : (
      <MatchList
        handleMatches={handleMatches}
        gameSlug={gameSlug}
        tournamentSlug={tournamentSlug}
        searchMatchList={searchMatchList}
        location={location}
      />
    );
  };

  const handleLoader = () => {
    if (matchListStatus !== 'succeeded') {
      if ((handleMatches ?? []).length > 0) return 'none';
      return '';
    }
    return 'none';
  };

  const handleContent = () => {
    if (matchListStatus !== 'succeeded') {
      if ((handleMatches ?? []).length > 0) return '';
      return 'none';
    }
    return '';
  };

  return (
    <>
      <Stack alignItems="center" sx={{ display: handleLoader() }}>
        <SearchLoader />
      </Stack>

      <Stack
        spacing={2}
        alignItems="center"
        sx={{
          display: handleContent(),
          bgcolor: 'neutral900',
          padding: '19px 16px 24px 16px',
        }}
      >
        <>
          {matchList && matchList.count > 0 && (
            <>
              {isAuthenticated && (
                <Box sx={{ width: '100%' }}>
                  <FindMyMatch
                    to={myTeam ? `/team/${gameSlug}/${schoolSlug}/${teamSlug}` : `/my-team`}
                    label={
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: t('match_temukan_match'),
                        }}
                      />
                    }
                  />
                </Box>
              )}

              <TextInputSearch
                defaultValue={location.state.search ?? ''}
                placeholder={t('match_search_placeholder')}
                onChange={value => handleSearch(value)}
              />

              <MatchListResult />

              {matchList && (matches ?? []).length != matchList.totalCount && !searchMatchList && (
                <ScrollLoadMore
                  loadMore={async () => {
                    if ((matches ?? []).length != matchList.totalCount) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                  onLoadMore={async () => {
                    if ((matches ?? []).length != matchList.totalCount) {
                      return loadMore();
                    }
                  }}
                  reloadDelayMs={5000}
                  style={{ marginTop: 50 }}
                />
              )}
            </>
          )}

          {isNotFound && <NotFound />}
        </>
      </Stack>
    </>
  );
};

export default MatchPage;
