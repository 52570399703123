import styled, { css } from 'styled-components';

export const FrameImage = styled.div<any>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.width};
  height: ${p => p.height};
  border-radius: ${p => p.bRadius};
  margin: ${p => p.margin};
  ${p =>
    p.isEffect === true &&
    css`
      background: linear-gradient(
        131.42deg,
        #d51f40 16.85%,
        rgba(234, 88, 44, 0.297578) 48.91%,
        rgba(240, 105, 38, 0.0853196) 69.28%,
        rgba(242, 109, 37, 0.0418083) 71.77%,
        rgba(243, 112, 36, 0) 82.91%
      );
      filter: drop-shadow(-10px 10px 40px rgba(213, 31, 64, 0.26));
      transform: rotate(-90deg);
    `}
`;
