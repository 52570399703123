import { TextInput } from '../TextInput';
import { Stack } from '@mui/material';
import { TextButton } from 'design/Buttons/TextButton';
import { IconSource } from 'modules/assetpath';
import React, { useRef, useEffect } from 'react';
import SVG from 'react-inlinesvg';

export interface TextInputSearchProps {
  id?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  defaultValue?: string;
  resetValue?: boolean;
  bgcolor?: string;
}

export const TextInputSearch = ({
  id,
  onChange,
  placeholder,
  defaultValue,
  resetValue,
  bgcolor,
}: TextInputSearchProps) => {
  const TextInputSearchRef = useRef({ value: defaultValue ?? '' });

  const handleRemove = () => {
    TextInputSearchRef.current.value = '';
  };

  useEffect(() => {
    if (resetValue) handleRemove();
  }, [resetValue]);

  return (
    <TextInput
      id={id}
      inputRef={TextInputSearchRef}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={e => {
        onChange && onChange(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <Stack justifyContent="center" width="20px">
            <SVG src={IconSource('ic-textinput-search')} />
          </Stack>
        ),
        endAdornment: (
          <Stack justifyContent="center" width="20px">
            {TextInputSearchRef.current.value && (
              <TextButton
                onClick={() => {
                  onChange && onChange('');
                  handleRemove();
                }}
              >
                <SVG src={IconSource('ic-textinput-remove')} />
              </TextButton>
            )}
          </Stack>
        ),
      }}
      sx={theme => ({
        '.MuiInputBase-root.Mui-focused': {
          bgcolor: 'neutral600',
          border: `1px solid ${theme.palette.neutral500}`,
        },
        '.MuiInputBase-root': {
          bgcolor: 'neutral700',
          borderRadius: theme.borderRadius.md,
          border: '1px solid #242628',
          fontSize: '12px',
          p: '8px',
          height: '36px',
          '&:hover': {
            bgcolor: 'neutral600',
          },
          input: {
            fontWeight: 600,
            lineHeight: '15px',
            p: '8px',
            '&::placeholder': {
              fontWeight: 400,
              color: 'neutral250',
              opacity: '0.5',
            },
          },
          ...(bgcolor && { bgcolor: bgcolor }),
        },
      })}
    />
  );
};
