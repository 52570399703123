import React, { HTMLAttributes } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import SVG from 'react-inlinesvg';
import { CustomIconButton } from './styles';

export interface IconButtonProps extends HTMLAttributes<HTMLButtonElement> {
  theme?: Theme;
  sx?: SxProps<Theme>;
  gradient?: string | boolean;
  icon?: string;
  disabled?: boolean;
}

export const IconButton = (props: IconButtonProps) => {
  return <CustomIconButton {...props}>{props.icon && <SVG src={props.icon} />}</CustomIconButton>;
};
