import { TabStyled, TabsStyled, TabPanelStyled } from './styles';
import { Theme, Box } from '@mui/material';
import { SxProps } from '@mui/system';
import history from 'modules/history';
import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export interface TabsProps {
  children?: ReactNode | any;
  sx?: SxProps<Theme>;
  tabSx?: SxProps<Theme>;
  tabIndex?: (value: any) => void;
  handleChange?: any;
  activeTab?: number | string;
  variant?: 'button';
}

export interface TabchildProps {
  props: {
    title?: string;
    icon?: string;
    hash?: string;
  };
}

export const TabItem: any = TabPanelStyled;

export const CustomTabs = ({
  children,
  tabSx,
  tabIndex,
  activeTab,
  variant,
  ...props
}: TabsProps) => {
  const location = useLocation<any>();
  const [value, setValue] = useState(activeTab ?? 0);

  const onChangeHandler = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (props.handleChange) {
      props.handleChange(newValue);
    }
  };

  useEffect(() => {
    if (tabIndex) tabIndex(value);
  }, [value]);

  useEffect(() => {
    if (activeTab) {
      setValue(activeTab);
    }
  }, []);

  const handleHash = (value: string) => {
    return history.push({
      hash: value,
      state: { ...location.state, scrollMatchTarget: undefined },
    });
  };

  const tabLink = useCallback(
    children =>
      React.Children.map(children, (child: TabchildProps, index) => {
        if (React.isValidElement(child)) {
          return (
            <TabStyled
              key={index}
              component="a"
              label={
                <Box className="label-item" component="span">
                  {child?.props?.title}
                </Box>
              }
              icon={child?.props?.icon}
              sx={tabSx}
              onClick={() => (child?.props?.hash ? handleHash(child?.props?.hash) : null)}
              value={child?.props?.hash ?? index}
              variant={variant}
              disableRipple={true}
            />
          );
        }
        return false;
      }),
    [],
  );

  const tabContent = useCallback(
    (children, value) =>
      React.Children.map(children, (child: TabchildProps, index) => {
        if (React.isValidElement(child)) {
          const customChild = {
            ...child,
            props: {
              ...child.props,
              title: '',
            },
          };
          return (
            (customChild?.props?.hash ?? index) === value && (
              <Box
                key={index}
                sx={{
                  width: '100%',
                  maxWidth: '500px',
                  m: '0 auto',
                  transformStyle: 'inherit',
                  transform: 'rotateX(0deg)',
                }}
              >
                {customChild}
              </Box>
            )
          );
        }
        return false;
      }),
    [value],
  );

  return (
    <>
      <TabsStyled
        value={value}
        onChange={onChangeHandler}
        aria-label="tabs-component"
        variant={variant}
        {...props}
      >
        {tabLink(children)}
      </TabsStyled>

      {tabContent(children, value)}
    </>
  );
};
