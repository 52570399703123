import MainLayout from '../components/layouts/MainLayout';
import { IconSource } from 'modules/assetpath';
import React from 'react';

const MainPage = () => {
  return (
    <MainLayout
      appBar={{
        logoLeft: IconSource('logo'),
        iconRight: IconSource('elipsis.vertical'),
      }}
    ></MainLayout>
  );
};

export default MainPage;
