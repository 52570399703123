import PlayerPage from './Player';
import { Modal as MUIModal, Fade, Backdrop } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import { SubHeader } from 'design/Cards/SubHeader';
import { emitter } from 'middleware/signalRMiddleware';
import { IconSource } from 'modules/assetpath';
import { onClickShare } from 'modules/helpers';
import { LogDev } from 'modules/helpers';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { fetchGameOptions } from 'redux/game/GameReducer';
import { useAppSelector } from 'redux/hooks';
import { fetchMatchDetail } from 'redux/match/matchServices';
import { ICheckInMessage, subscribeToMatchHub } from 'redux/notification/NotificationReducer';
import { useAppThunkDispatch } from 'redux/store';
import { fetchTournamentDetail } from 'redux/tournament/TournamentServices';
import { STATUS } from 'store/constants/match';

const TournamentMatchDetail = () => {
  const location = useLocation<any>();
  const { setValue } = useContext(LayoutContext);
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const [playerList, setPlayerList] = useState<any>([]);
  const [openLoader, setOpenLoader] = React.useState(false);
  const { tournament_slug, match_id } = useParams() as {
    tournament_slug: string;
    match_id: string;
  };
  const { matchDetail, tournamentDetail, gameOption } = useAppSelector(
    ({ tournament, match, game }) => ({
      tournamentDetail: tournament?.tournamentDetail,
      matchDetail: match?.matchDetail,
      gameOption: game?.gameOption?.items,
    }),
  );

  const [gameSlug, setGameSlug] = useState<any>();

  const handleFetchGameOptions = () => {
    dispatch(fetchGameOptions({ pageIndex: 1, pageSize: 10 }));
  };

  const handleFetchTournamentDetail = (slug: string) => {
    dispatch(fetchTournamentDetail({ tournamentSlug: slug }));
  };

  useEffect(() => {
    return handleFetchTournamentDetail(tournament_slug);
  }, []);

  useEffect(() => {
    handleFetchGameOptions();
    setOpenLoader(true);
  }, []);

  useEffect(() => {
    if (match_id) {
      dispatch(fetchMatchDetail({ matchId: match_id }))
        .unwrap()
        .then(_res => {
          setOpenLoader(false);
        });
    }
  }, [dispatch]);

  useEffect(() => {
    setPlayerList([matchDetail?.firstTeamPlayers?.concat(matchDetail?.secondTeamPlayers)]);
  }, [matchDetail]);

  useEffect(() => {
    if (matchDetail?.status == STATUS.CHECKIN) {
      dispatch(subscribeToMatchHub(match_id));
      emitter.on('CONNECTION', args => {
        LogDev('INFO', 'EMITTER CONNECTION....', args);
      });
      emitter.on('match.lobby.oncheckin', async (_e: ICheckInMessage) => {
        setOpenLoader(true);
        dispatch(fetchMatchDetail({ matchId: match_id }))
          .unwrap()
          .then(_res => {
            setOpenLoader(false);
          });
      });
    }
  }, [dispatch, matchDetail]);

  useEffect(() => {
    if (matchDetail && gameOption && gameOption.length > 0) {
      const gameIndex = gameOption.findIndex(game => game.id === matchDetail.gameId);
      setGameSlug(gameOption[gameIndex].urlSlug);
    }
  }, [matchDetail, gameOption]);

  useEffect(() => {
    setValue({
      pageTitle: `MABAR | ${tournamentDetail?.title} | ${tournamentDetail?.gameName}`,
      appBar: {
        logo: false,
        title: t('match_tournament_id', { id: match_id }),
        back: {
          to: location.state?.pathname ?? `/tournament/${gameSlug}/${tournamentDetail?.urlSlug}`,
          hash: '#match',
          state: {
            scrollTarget: location.state?.scrollTarget,
            scrollMatchTarget: location.state?.scrollMatchTarget,
            search: location.state?.search,
          },
        },
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: t('tournament_page_title', {
                title: `MABAR | ${tournamentDetail?.title} | ${tournamentDetail?.gameName}`,
              }),
              handleIconShare: () => handleShare(),
            });
          }
        },
      },
      background: {
        color: '#131318',
      },
      padding: '0px',
      bottomBar: {
        show: false,
      },
    });
  }, [dispatch, tournamentDetail, location, gameSlug]);
  return (
    <>
      <SubHeader
        topText={tournamentDetail?.title}
        bottomText={tournamentDetail?.gameName}
        youtubeLink={matchDetail?.matchVideoLink}
      />
      <PlayerPage
        tournamentDetail={tournamentDetail}
        playerListed={playerList[0]}
        setOpenLoader={setOpenLoader}
      />

      <MUIModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLoader}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'transparent',
        }}
      >
        <Fade in={openLoader}>
          <img src={IconSource('ic-loading')} style={{ position: 'relative', outline: 0 }} />
        </Fade>
      </MUIModal>
    </>
  );
};

export default TournamentMatchDetail;
