import Awards from '../public/Awards';
import ScrimStatistic from '../public/ScrimStatistic';
import Tentang from '../public/Tentang';
import { CustomTabs, TabItem } from 'design/Tabs';
import { tabRedLineStyled } from 'design/Tabs/styles';
import { Text } from 'design/Text';
import React, { memo, useCallback } from 'react';
import { use100vh } from 'react-div-100vh';

interface PublicNavigationProps {
  paramTeamSlug: string;
  hash: string;
  setOpenToast: (value: boolean) => void;
}

const PublicNavigation = ({ paramTeamSlug, hash, setOpenToast }: PublicNavigationProps) => {
  const height = use100vh();

  const tabItemStyled = {
    minHeight: height! - (56 + 102 + 65),
    height: !height ? 'calc(100vh - (56px + 102px + 65px))' : 'auto',
    bgcolor: 'neutral800',
  };

  const handleTabItemtext = useCallback((title: string) => {
    return (
      <>
        <Text
          variant="button"
          sx={{
            color: 'neutral400',
            fontWeight: '400',
            lineHeight: '15px',
            letterSpacing: '0.08em',
            textTransform: 'uppercase',
            mr: '4px',
          }}
        >
          {title}
        </Text>
      </>
    );
  }, []);

  return (
    <CustomTabs sx={tabRedLineStyled} activeTab={hash || '#awards'} tabSx={{ flexGrow: 1 }}>
      <TabItem hash="#awards" title={handleTabItemtext('AWARDS')} sx={tabItemStyled}>
        <Awards paramTeamSlug={paramTeamSlug} />
      </TabItem>

      <TabItem hash="#scrim" title={handleTabItemtext('SCRIM')} sx={tabItemStyled}>
        <ScrimStatistic />
      </TabItem>

      <TabItem hash="#tentang" title={handleTabItemtext('TENTANG')} sx={tabItemStyled}>
        <Tentang paramTeamSlug={paramTeamSlug} setOpenToast={setOpenToast} />
      </TabItem>
    </CustomTabs>
  );
};

export default memo(PublicNavigation);
