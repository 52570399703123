import AccountReducer from './account/AccountReducer';
import AppReducer from './app/AppReducer';
import AuthReducer from './auth/AuthReducer';
import Awardreducer from './award/AwardReducer';
import GameReducer from './game/GameReducer';
import matchReducer from './match/matchReducer';
import NotificationReducer from './notification/NotificationReducer';
import PublicProfileReducer from './publicProfile/slice';
import RegionReducer from './region/RegionReducer';
import SchoolReducer from './school/SchoolReducer';
import ScrimDetailGameReducer from './scrim/ScrimDetailGameReducer';
import ScrimDetailReducer from './scrim/ScrimDetailReducer';
import ScrimDraftReducer from './scrim/ScrimDraftReducer';
import ScrimPlayerReducer from './scrim/ScrimPlayerReducer';
import ScrimReducer from './scrim/ScrimReducer';
import ScrimMatchDetailReducer from './scrim/match.details/ScrimMatchDetailReducer';
import SeasonReducer from './season/slice';
import { StoreState } from './state';
import MyTeamReducer from './team/MyTeamReducer';
import TeamApprovalReducer from './team/TeamApproval';
import TeamDetailReducer from './team/TeamDetailReducer';
import TeamDraftReducer from './team/TeamDraftReducer';
import TeamInvitationReducer from './team/TeamInvitationReducer';
import TeamListReducer from './team/TeamListReducer';
import TeamMemberReducer from './team/TeamMemberReducer';
import TeamProfileReducer from './teamProfile/TeamProfileReducer';
import TournamentReducer from './tournament/TournamentReducer';
import { persistCombineReducers } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { Constants } from 'services/Constants';

export default persistCombineReducers<StoreState>(
  {
    keyPrefix: '@',
    key: process.env.REDUX_KEY_STORE_NAME,
    stateReconciler: autoMergeLevel2,
    storage,
    timeout: 0,
    serialize: true,
    transforms: [
      encryptTransform({
        secretKey: Constants.clientSecret,
        onError: err => {
          if (process.env.NODE_ENV === 'development') console.error(err);
        },
      }),
    ],
    version: 1,
  },
  {
    app: AppReducer,
    auth: AuthReducer,
    scrimList: ScrimReducer,
    scrimDetail: ScrimDetailReducer,
    scrimDraft: ScrimDraftReducer,
    scrimDetailGame: ScrimDetailGameReducer,
    game: GameReducer,
    notification: NotificationReducer,
    myTeam: MyTeamReducer,
    teamMember: TeamMemberReducer,
    teamInvitation: TeamInvitationReducer,
    teamDetail: TeamDetailReducer,
    teamDraft: TeamDraftReducer,
    teamList: TeamListReducer,
    account: AccountReducer,
    scrimMatchDetail: ScrimMatchDetailReducer,
    teamProfile: TeamProfileReducer,
    publicProfile: PublicProfileReducer,
    region: RegionReducer,
    school: SchoolReducer,
    teamApproval: TeamApprovalReducer,
    scrimPlayer: ScrimPlayerReducer,
    season: SeasonReducer,
    tournament: TournamentReducer,
    match: matchReducer,
    award: Awardreducer,
  },
);
