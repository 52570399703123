import { ClickAwayListener, Grow } from '@mui/material';
import { VirtualElement } from '@popperjs/core';
import GradientButton from 'components/Buttons/GradientButton';
import { CardScrimWinLose, ScrimWinLoseProps } from 'components/CardScrimWinLose/index';
import { Checkbox } from 'components/Inputs/checkbox';
import { Status } from 'components/Status/index';
import ShareModal from 'components/UniPopup/Modals/ShareModal';
import {
  MenuContainer,
  MenuItems,
  MenuLists,
  MenuSeparator,
  PopupMenu,
} from 'components/UniPopup/Popup/Menu';
import HeaderMatchDetails from 'components/cards/HeaderMatchDetails';
import PlayerCard, { PlayerCardType } from 'components/cards/PlayerCard';
import AppLayout from 'components/layouts/AppLayout';
import Modal from 'components/layouts/Modal';
import { TabButtonType } from 'components/layouts/TabButton';
import TabMenu from 'components/layouts/TabMenu';
import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText, SpanText } from 'components/styled/text.styled';
import { ContainerToast } from 'components/styled/toast.styled';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import { emitter } from 'middleware/signalRMiddleware';
import { IconSource, ImageSource } from 'modules/assetpath';
import useWindowDimensions from 'modules/dimention';
import { LogDev } from 'modules/helpers';
import history from 'modules/history';
import React, { useEffect, useState, useCallback } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { ICheckInMessage, subscribeToMatchHub } from 'redux/notification/NotificationReducer';
import { ScrimDetailGameResponse } from 'redux/scrim/ScrimDetailGameReducer';
import { getScrimPlayer, TeamPlayer } from 'redux/scrim/ScrimPlayerReducer';
import { getScrimMatchRound } from 'redux/scrim/ScrimService';
import {
  getScrimDetailHeader,
  ScrimHeaderDetailResponse,
} from 'redux/scrim/match.details/ScrimMatchDetailReducer';
import { useAppThunkDispatch } from 'redux/store';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';
import { checkIn } from 'redux/teamProfile/TeamProfileService';

const CopyText = (playerId: string, titleToast: string) => {
  if (copy(playerId))
    toast.info(titleToast, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
};

const MatchPage = () => {
  const { t } = useTranslation();
  const { game_slug, scrim_id } = useParams() as { game_slug: string; scrim_id: string };
  const [modalType, setModalType] = useState<
    'check-in' | 'upload-ss' | 'share-scrim' | 'team' | 'loading' | 'ss-fullscreen'
  >('check-in');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppThunkDispatch();
  const [tabId, setTabId] = useState<TabButtonType>({
    id: 1,
    title: '',
    isSelected: true,
  });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [getErrorList, setErrorList] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<VirtualElement>();
  const [state, setState] = useState(false);
  const [dataTeam, setDataTeam] = useState<ScrimHeaderDetailResponse>();
  const playerInfo = getPlayerProfileFromSession();
  let [playersOne, setPlayersOne] = useState<TeamPlayer[]>([]);
  let [playersTwo, setPlayersTwo] = useState<TeamPlayer[]>([]);
  const teamInfo = getMyCurrentTeamFromSession();
  const [teamNo, setTeamNo] = useState(false);
  const [getRoundData, setRoundData] = useState<ScrimDetailGameResponse>();
  const [getImageZoom, setImageZoom] = useState('');

  const convert = (Text: any) => {
    return (
      Text?.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '') ?? Text
    );
  };

  const currentTeam = teamInfo.some(v => v.id === dataTeam?.firstTeamId)
    ? teamInfo.find(v => v.id === dataTeam?.firstTeamId)
    : teamInfo.find(v => v.id === dataTeam?.secondTeamId) ?? undefined;

  let [shoudCheckIn, setShoudCheckIn] = useState(false);

  const winResult: ScrimWinLoseProps[] = [
    {
      text: '14 Okt 2021 - 14:30 WIB',
      header: currentTeam
        ? currentTeam?.urlSlug === convert(dataTeam?.winnerTeam)
          ? 'Tim Kamu Menang di Game Ini 🏆'
          : 'Tim Kamu Kalah di Game Ini'
        : getRoundData?.winnerTeam === dataTeam?.firstTeam
        ? `Tim ${dataTeam?.firstTeam} Menang di Game Ini 🏆`
        : `Tim ${dataTeam?.secondTeam} Menang di Game Ini`,
      team1: dataTeam?.firstTeam,
      team2: dataTeam?.secondTeam,
      picture1: dataTeam?.firstTeamAvatar,
      picture2: dataTeam?.secondTeamAvatar,
      score: (
        <>
          {getRoundData?.firstTeamTotalKill ?? 0}{' '}
          <span
            style={{ color: '#B1B1B1', fontSize: '12px', fontWeight: 500, lineHeight: '16.8px' }}
          >
            Kills
          </span>
        </>
      ),
      score1: (
        <>
          {getRoundData?.secondTeamTotalKill ?? 0}{' '}
          <span
            style={{ color: '#B1B1B1', fontSize: '12px', fontWeight: 500, lineHeight: '16.8px' }}
          >
            Kills
          </span>
        </>
      ),
      bg: currentTeam
        ? currentTeam?.urlSlug === convert(dataTeam?.winnerTeam)
          ? 'linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))'
          : 'linear-gradient(0deg, rgba(228, 60, 90, 0.3), rgba(228, 60, 90, 0.3))'
        : 'linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))',
      border: '0',
      minHeight:
        getRoundData?.firstTeamScreenshot || getRoundData?.secondTeamScreenshot ? '176px' : '',
      src: currentTeam
        ? currentTeam?.urlSlug === convert(getRoundData?.firstTeam)
          ? getRoundData?.firstTeamScreenshot
            ? getRoundData?.firstTeamScreenshot
            : getRoundData?.secondTeamScreenshot
          : getRoundData?.secondTeamScreenshot
          ? getRoundData?.secondTeamScreenshot
          : getRoundData?.firstTeamScreenshot
        : getRoundData?.firstTeamScreenshot
        ? getRoundData?.firstTeamScreenshot
        : getRoundData?.secondTeamScreenshot,
      onClick: e => {
        setImageZoom(e.src ?? '');
        setModalType('ss-fullscreen');
        setShowModal(true);
      },
    },
  ];

  const [dataRound, setDataRound] = useState<ScrimWinLoseProps[]>([]);

  const fetchDataRound = React.useCallback(
    async (mchId: string, r: number) => {
      setDataRound([]);
      dispatch(getScrimMatchRound({ matchId: mchId, round: r }))
        .unwrap()
        .then(res => {
          if (res.status >= 400) {
          } else {
            setRoundData(res.data);
            if (currentTeam) {
              if (
                (res.data?.winnerTeamId && currentTeam.id === res.data?.winnerTeamId) ||
                (res.data?.winnerTeam && currentTeam.name === res.data.winnerTeam)
              ) {
                setDataRound([
                  {
                    text: '14 Okt 2021 - 14:30 WIB',
                    header: 'Tim Kamu Menang di Game Ini 🏆',
                    team1: res.data?.firstTeam,
                    team2: res.data?.secondTeam,
                    picture1: res.data?.firstTeamAvatar,
                    picture2: res.data?.secondTeamAvatar,
                    score: (
                      <>
                        {res.data?.firstTeamTotalKill ?? 0}{' '}
                        <span
                          style={{
                            color: '#B1B1B1',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16.8px',
                          }}
                        >
                          Kills
                        </span>
                      </>
                    ),
                    score1: (
                      <>
                        {res.data?.secondTeamTotalKill ?? 0}{' '}
                        <span
                          style={{
                            color: '#B1B1B1',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16.8px',
                          }}
                        >
                          Kills
                        </span>
                      </>
                    ),
                    bg: 'linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))',
                    border: '0',
                    minHeight:
                      res.data?.firstTeamScreenshot || res.data?.secondTeamScreenshot
                        ? '176px'
                        : '',
                    src:
                      currentTeam.urlSlug === convert(res.data?.firstTeam)
                        ? res.data?.firstTeamScreenshot
                          ? res.data?.firstTeamScreenshot
                          : res.data?.secondTeamScreenshot
                        : res.data?.secondTeamScreenshot
                        ? res.data?.secondTeamScreenshot
                        : res.data?.firstTeamScreenshot,
                    onClick: e => {
                      setImageZoom(e.src ?? '');
                      setModalType('ss-fullscreen');
                      setShowModal(true);
                    },
                  },
                ]);
              } else {
                setDataRound([
                  {
                    text: '14 Okt 2021 - 14:30 WIB',
                    header:
                      res.data?.loserTeam || res.data?.loserTeamId
                        ? 'Tim Kamu Kalah di Game Ini'
                        : 'Hasil Pertandingan Tidak Sesuai',
                    team1: res.data?.firstTeam,
                    team2: res.data?.secondTeam,
                    picture1: res.data?.firstTeamAvatar,
                    picture2: res.data?.secondTeamAvatar,
                    score: (
                      <>
                        {res.data?.firstTeamTotalKill ?? 0}{' '}
                        <span
                          style={{
                            color: '#B1B1B1',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16.8px',
                          }}
                        >
                          Kills
                        </span>
                      </>
                    ),
                    score1: (
                      <>
                        {res.data?.secondTeamTotalKill ?? 0}{' '}
                        <span
                          style={{
                            color: '#B1B1B1',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16.8px',
                          }}
                        >
                          Kills
                        </span>
                      </>
                    ),
                    bg: 'linear-gradient(0deg, rgba(228, 60, 90, 0.3), rgba(228, 60, 90, 0.3))',
                    border: '0',
                    minHeight:
                      res.data?.firstTeamScreenshot || res.data?.secondTeamScreenshot
                        ? '176px'
                        : '',
                    src:
                      currentTeam.urlSlug === convert(res.data?.firstTeam)
                        ? res.data?.firstTeamScreenshot
                          ? res.data?.firstTeamScreenshot
                          : res.data?.secondTeamScreenshot
                        : res.data?.secondTeamScreenshot
                        ? res.data?.secondTeamScreenshot
                        : res.data?.firstTeamScreenshot,
                    onClick: e => {
                      setImageZoom(e.src ?? '');
                      setModalType('ss-fullscreen');
                      setShowModal(true);
                    },
                  },
                ]);
              }
            } else {
              if (
                dataTeam?.firstTeamId === res.data?.winnerTeamId ||
                dataTeam?.firstTeam === res.data.winnerTeam
              ) {
                setDataRound([
                  {
                    text: '14 Okt 2021 - 14:30 WIB',
                    header: `Tim ${res.data?.winnerTeam} Menang di Game Ini 🏆`,
                    team1: res.data?.firstTeam,
                    team2: res.data?.secondTeam,
                    picture1: res.data?.firstTeamAvatar,
                    picture2: res.data?.secondTeamAvatar,
                    score: (
                      <>
                        {res.data?.firstTeamTotalKill ?? 0}{' '}
                        <span
                          style={{
                            color: '#B1B1B1',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16.8px',
                          }}
                        >
                          Kills
                        </span>
                      </>
                    ),
                    score1: (
                      <>
                        {res.data?.secondTeamTotalKill ?? 0}{' '}
                        <span
                          style={{
                            color: '#B1B1B1',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16.8px',
                          }}
                        >
                          Kills
                        </span>
                      </>
                    ),
                    bg: 'linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))',
                    border: '0',
                    minHeight:
                      res.data?.firstTeamScreenshot || res.data?.secondTeamScreenshot
                        ? '176px'
                        : '',
                    src:
                      res.data?.urlSlug === convert(res.data?.firstTeam)
                        ? res.data?.firstTeamScreenshot
                          ? res.data?.firstTeamScreenshot
                          : res.data?.secondTeamScreenshot
                        : res.data?.secondTeamScreenshot
                        ? res.data?.secondTeamScreenshot
                        : res.data?.firstTeamScreenshot,
                    onClick: e => {
                      setImageZoom(e.src ?? '');
                      setModalType('ss-fullscreen');
                      setShowModal(true);
                    },
                  },
                ]);
              } else {
                setDataRound([
                  {
                    text: '14 Okt 2021 - 14:30 WIB',
                    header:
                      res.data?.loserTeam || res.data?.loserTeamId
                        ? `Tim ${res.data?.loserTeam} Kalah di Game Ini`
                        : 'Hasil Pertandingan Tidak Sesuai',
                    team1: res.data?.firstTeam,
                    team2: res.data?.secondTeam,
                    picture1: res.data?.firstTeamAvatar,
                    picture2: res.data?.secondTeamAvatar,
                    score: (
                      <>
                        {res.data?.firstTeamTotalKill ?? 0}{' '}
                        <span
                          style={{
                            color: '#B1B1B1',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16.8px',
                          }}
                        >
                          Kills
                        </span>
                      </>
                    ),
                    score1: (
                      <>
                        {res.data?.secondTeamTotalKill ?? 0}{' '}
                        <span
                          style={{
                            color: '#B1B1B1',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16.8px',
                          }}
                        >
                          Kills
                        </span>
                      </>
                    ),
                    bg: 'linear-gradient(0deg, rgba(228, 60, 90, 0.3), rgba(228, 60, 90, 0.3))',
                    border: '0',
                    minHeight:
                      res.data?.firstTeamScreenshot || res.data?.secondTeamScreenshot
                        ? '176px'
                        : '',
                    src:
                      res.data?.urlSlug === convert(res.data?.firstTeam)
                        ? res.data?.firstTeamScreenshot
                          ? res.data?.firstTeamScreenshot
                          : res.data?.secondTeamScreenshot
                        : res.data?.secondTeamScreenshot
                        ? res.data?.secondTeamScreenshot
                        : res.data?.firstTeamScreenshot,
                    onClick: e => {
                      setImageZoom(e.src ?? '');
                      setModalType('ss-fullscreen');
                      setShowModal(true);
                    },
                  },
                ]);
              }
            }
            setShowModal(false);
          }
        })
        .catch(err => {
          setShowModal(false);
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    },
    [dispatch, dataRound, currentTeam],
  );

  const handleCloseMenu = (event: any) => {
    if (anchorRef.current && anchorRef.current.contextElement?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const GetTabPlayer = useCallback(
    (id: string, teamSlug: string, isFirstTeam: boolean) => {
      dispatch(getScrimPlayer({ matchId: id, teamSlug }))
        .unwrap()
        .then(result => {
          if (result && result.length > 0) {
            const players = [...result];
            players
              .sort((a: TeamPlayer, _) =>
                a.hasCheckedIn !== undefined && a.hasCheckedIn === true ? -1 : 1,
              )
              .sort((a: TeamPlayer, _) =>
                a.isCaptain !== undefined && a.isCaptain === true ? -1 : 1,
              );
            if (isFirstTeam) {
              setPlayersOne(players);
            } else {
              setPlayersTwo(players);
            }

            players.forEach((member: TeamPlayer) => {
              if (member.playerId === playerInfo.id) {
                if (member.hasCheckedIn) {
                  setShoudCheckIn(!member.hasCheckedIn);
                } else {
                  setShoudCheckIn(true);
                }
              }
            });
          }
          setShowModal(false);
        });
    },
    [dispatch],
  );

  const GetMatchDetail = () => {
    setModalType('loading');
    setShowModal(true);
    dispatch(getScrimDetailHeader({ id: scrim_id }))
      .unwrap()
      .then(async team => {
        if (team && team.data) {
          setDataTeam(team.data);
          if (
            (team.data && team.data.status.value === 'DataCompleted') ||
            team.data.status.value === 'ResultCalculated'
          )
            fetchDataRound(scrim_id, 1);

          GetTabPlayer(scrim_id, team.data.firstTeamUrlSlug, true);
          GetTabPlayer(scrim_id, team.data.secondTeamUrlSlug, false);
        }
      })
      .catch(err => {
        if (err.stack?.includes('404')) {
          history.push(`/404?scrims/${scrim_id}`);
        } else {
          setShowModal(false);
          setErrorList(err?.stack);
          setShowErrorModal(true);
        }
      });
  };

  useEffect(() => {
    dispatch(subscribeToMatchHub(scrim_id));
    emitter.on('CONNECTION', args => {
      LogDev('INFO', 'EMITTER CONNECTION....', args);
    });
    emitter.on('match.lobby.oncheckin', async (e: ICheckInMessage) => {
      if (e.status.key === '6' || e.status.value === 'CheckIn') {
        if (playersOne.find(tp => tp.playerId === e.playerId)) {
          LogDev('INFO', e.playerId, '=> isFirstTeam checkin');
          let team1 = [...playersOne];
          team1.find((tp, i) => {
            if (tp.playerId === e.playerId) {
              LogDev('INFO', e.playerId, `=> ${tp.fullName} checkin`, tp.playerId);
              let updatePlayer = team1.fill({ ...tp, hasCheckedIn: true }, i, i + 1);
              setPlayersOne([...updatePlayer]);
            }
          });
        } else if (playersTwo.find(tp => tp.playerId === e.playerId)) {
          LogDev('INFO', e.playerId, '=> isSecondTeam checkin');
          let team2 = [...playersTwo];
          team2.find((tp, i) => {
            if (tp.playerId === e.playerId) {
              LogDev('INFO', e.playerId, `=> ${tp.fullName} checkin`, tp.playerId);
              let updatePlayer = team2.fill({ ...tp, hasCheckedIn: true }, i, i + 1);
              setPlayersTwo([...updatePlayer]);
            }
          });
        } else {
          GetMatchDetail();
        }
      } else {
        GetMatchDetail();
      }
    });
  }, [dispatch]);

  useEffect(() => {
    GetMatchDetail();
  }, [dispatch, dataTeam?.status?.value]);

  const checkInMatch = async (matchId: string) => {
    dispatch(checkIn({ matchId }))
      .unwrap()
      .then((result: any) => {
        if (result.isAxiosError && result.data) {
          setErrorList(result.data);
          setShowErrorModal(true);
          GetMatchDetail();
        } else {
          if (playersOne.find(tp => tp.playerId === playerInfo.id)) {
            LogDev('INFO', playerInfo.id, '=> isFirstTeam checkin');
            let team1 = [...playersOne];
            team1.find((tp, i) => {
              if (tp.playerId === playerInfo.id) {
                LogDev('INFO', playerInfo.id, `=> ${tp.fullName} checkin`, tp.playerId);
                let updatePlayer = team1.fill({ ...tp, hasCheckedIn: true }, i, i + 1);
                setPlayersOne([...updatePlayer]);
                setShowModal(false);
                setShoudCheckIn(false);
              }
            });
          } else if (playersTwo.find(tp => tp.playerId === playerInfo.id)) {
            LogDev('INFO', playerInfo.id, '=> isSecondTeam checkin');
            let team2 = [...playersTwo];
            team2.find((tp, i) => {
              if (tp.playerId === playerInfo.id) {
                LogDev('INFO', playerInfo.id, `=> ${tp.fullName} checkin`, tp.playerId);
                let updatePlayer = team2.fill({ ...tp, hasCheckedIn: true }, i, i + 1);
                setPlayersTwo([...updatePlayer]);
                setShowModal(false);
                setShoudCheckIn(false);
              }
            });
          } else {
            GetMatchDetail();
            setShowModal(false);
            setShoudCheckIn(false);
          }
        }
      })
      .catch(rejectedValueOrSerializedError => {
        LogDev('ERROR', 'rejectedValueOrSerializedError', rejectedValueOrSerializedError);
      });
  };
  const modalError = () => {
    const parseError = (errors: any) => {
      if (errors) {
        let errorsList: string[] = [];
        Object.values(errors).forEach((prop: any) => {
          errorsList = errorsList.concat(_.map(prop, _.property('message')));
        });
        return errorsList.join('\n');
      }
      return null;
    };
    return (
      <Box padding="16px" width="100%">
        <Stack direction="column" margin="16px">
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight="bold"
            margin="8px 0px"
          >
            {t('errors:HTTP400')}
          </NormalText>
          <NormalText
            textAlign="center"
            color="#FF4D6D"
            fontSize="12px"
            fontWeight="normal"
            lineHeight="17.52px"
            margin="8px 0px"
          >
            {getErrorList
              ? getErrorList?.errors
                ? parseError(getErrorList?.errors)?.includes('Failed CheckIn')
                  ? 'Kamu Sudah CheckIn'
                  : parseError(getErrorList?.errors)
                : getErrorList?.detail
                ? getErrorList.detail
                : getErrorList
              : ''}
          </NormalText>
          <br />
          <Box padding="8px" width="100%">
            <Stack direction="column" align="center">
              <GradientButton
                onClick={() => {
                  setShowErrorModal(false);
                  GetMatchDetail();
                }}
                backgroundSize="100%"
                width="180px"
                variant="primary"
              >
                OK
              </GradientButton>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  };

  const ZoomImage = ({ src }: { src: string; title?: string }) => {
    return (
      <Stack direction="row" align="evenly" style={{ transform: 'rotate(90deg)' }}>
        <Box
          background="#373942"
          padding="6px 0px"
          borderRadius="16px"
          height={`${useWindowDimensions().width - 80}px`}
          width={`${useWindowDimensions().height - 150}px`}
          maxHeight="500px"
          disableDrag
        >
          <Stack direction="column" disableSelectText disableDrag padding="0px 6px">
            <Box
              background={`url(${src}) no-repeat center / 100% 100%`}
              height={`${useWindowDimensions().width - 80}px`}
              width={`${useWindowDimensions().height - 170}px`}
              borderRadius="16px"
            />
          </Stack>
        </Box>
        <Box
          background="#D51F40"
          height="32px"
          width="32px"
          borderRadius="8px"
          margin="4px 0px 0px 16px"
          onClick={() => setShowModal(false)}
          isPressble
        >
          <Avatar
            src={IconSource('ic-close-white')}
            alt=""
            isPressable
            fit="contain"
            objectPos="center"
            padding="0px 24px 0px 0px"
          />
        </Box>
      </Stack>
    );
  };

  const scrimTime = new Date(dataTeam?.startAt ?? new Date()).toLocaleTimeString('id-ID', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  const DataTab: TabButtonType[] = [
    {
      id: 1,
      title: 'Game 1',
      isSelected: true,
    },
    {
      id: 2,
      title: 'Game 2',
      isSelected: false,
    },
    {
      id: 3,
      title: 'Game 3',
      isSelected: false,
    },
  ];

  const TabWO: TabButtonType[] = [
    {
      id: 1,
      title: 'Game',
      isSelected: true,
    },
  ];

  return (
    <AppLayout
      appBar={{
        bg: {},
        title: `Detail Scrim ${dataTeam?.id ? `#${dataTeam.id}` : ''}`,
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
          onClick: () => {
            history.push(
              `/team/${game_slug}/${playerInfo?.school?.urlSlug}/${
                currentTeam?.urlSlug ?? teamInfo[0].urlSlug
              }`,
            );
          },
        },
        navright: {
          ref: anchorRef,
          variant: dataTeam?.id ? 'menu-options' : 'empty',
          bg: open ? 'gradient' : 'white',
          isDisable: false,
          onClick: () => {
            setOpen(prevOpen => !prevOpen);
          },
          isExpanded: open,
        },
        isDisable: false,
      }}
      noMargin
      backgroundImage={ImageSource('app.background')}
      showModal={showModal}
      modalStyled={{
        background:
          modalType === 'loading' || modalType === 'ss-fullscreen' ? 'transparent' : '#373942',
      }}
      modalContent={
        <>
          {modalType === 'check-in' ? (
            <Box padding="8px" width="100%">
              <Countdown
                intervalDelay={0}
                date={new Date(dataTeam?.checkInEndAt!)}
                renderer={({ formatted }) => {
                  return (
                    <>
                      <Stack direction="column" align="center">
                        <NormalText
                          textAlign="center"
                          color="#FFFFFF"
                          fontSize="16px"
                          fontWeight="700"
                          margin="8px 0px"
                        >
                          Sudah Waktunya Check In
                        </NormalText>
                        <NormalText
                          textAlign="center"
                          color="rgba(221, 216, 216, 1)"
                          fontSize="14px"
                          fontWeight="400"
                          margin="8px 0px"
                          lineHeight="140%"
                        >
                          Waktu check in tersisa{' '}
                          <span style={{ fontSize: '14px', fontWeight: 700, color: '#FFFFFF' }}>
                            {formatted.hours}:{formatted.minutes}
                            {':'}
                            {formatted.seconds}
                          </span>
                          <br /> Segera check in sekarang agar pertandingan dapat segera dimulai.
                        </NormalText>
                        <Box width="100%" margin="16px 0px 0px 0px">
                          <Stack direction="column" align="center">
                            <GradientButton
                              styled={{ backgroundSize: '100%' }}
                              variant="primary"
                              onClick={() => checkInMatch(dataTeam?.id || '')}
                            >
                              Check In Sekarang
                            </GradientButton>

                            <GradientButton variant="cta" onClick={() => setShowModal(false)}>
                              Batal
                            </GradientButton>
                          </Stack>
                        </Box>
                      </Stack>
                    </>
                  );
                }}
              />
            </Box>
          ) : (
            <></>
          )}
          {modalType === 'upload-ss' ? (
            <Box padding="0px" width="100%">
              <Stack direction="column" margin="16px">
                <NormalText
                  textAlign="center"
                  color="#FFFFFF"
                  fontSize="16px"
                  fontWeight="bold"
                  margin="8px 0px"
                >
                  Konfirmasi Kebenaran Informasi
                </NormalText>
                <NormalText
                  textAlign="left"
                  color="#FFFFFF"
                  fontSize="12px"
                  fontWeight="bold"
                  margin="8px 0px"
                >
                  1. Integritas dan Kejujuran
                </NormalText>
                <NormalText
                  textAlign="left"
                  color="#FFFFFF"
                  fontSize="12px"
                  fontWeight="normal"
                  margin="8px 0px"
                >
                  MABAR dibangun berlandaskan atas integritas dan kejujuran, kecurangan dan
                  pelanggaran adalah masalah serius dan dapat ditindaklanjuti.
                </NormalText>
                <NormalText
                  textAlign="left"
                  color="#FFFFFF"
                  fontSize="12px"
                  fontWeight="bold"
                  margin="8px 0px"
                >
                  2. Nickname Game
                </NormalText>
                <NormalText
                  textAlign="left"
                  color="#FFFFFF"
                  fontSize="12px"
                  fontWeight="normal"
                  margin="8px 0px"
                >
                  Hanya nickname game yang terdaftar di MABAR yang akan mendapatkan poin
                </NormalText>
                <NormalText
                  textAlign="left"
                  color="#FFFFFF"
                  fontSize="12px"
                  fontWeight="bold"
                  margin="8px 0px"
                >
                  3. Contoh Screenshot Hasil Pertandingan
                </NormalText>
                <img src={ImageSource('screenshot-game', 'png')} alt="" />
                <Box margin="16px 0px 0px 0px">
                  <Stack direction="row" align="center">
                    <Checkbox
                      onChange={() => null}
                      onClick={() => setState(!state)}
                      checked={state}
                    />
                    <label style={{ position: 'relative', width: 'fit-content', marginLeft: 8 }}>
                      <SpanText
                        color="white"
                        fontSize="12px"
                        fontWeight="400"
                        onClick={() => setState(!state)}
                      >
                        Saya mengerti harus memberikan informasi yang benar
                      </SpanText>
                    </label>
                    <GradientButton
                      variant="cta"
                      margin="0px"
                      onClick={() => {
                        if (state) {
                          history.push(`/match/${game_slug}/${dataTeam?.id}/upload-result`);
                        }
                      }}
                    >
                      Lanjut
                    </GradientButton>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          ) : modalType === 'team' ? (
            <Box padding="16px" width="90%">
              <Stack direction="column">
                <NormalText
                  margin="10px"
                  fontWeight="bold"
                  fontSize="16px"
                  color="white"
                  textAlign="center"
                >
                  {teamNo ? dataTeam?.firstTeam : dataTeam?.secondTeam}
                </NormalText>
                {(teamNo ? playersOne : playersTwo).map((team, index: number) => {
                  return team.hasCheckedIn ? (
                    <PlayerCard
                      key={index}
                      {...{
                        playerName: `${team.fullName ?? ''}`,
                        playerId: `@${
                          teamNo
                            ? dataTeam?.firstTeamPlayers.find(v => v.id === team.playerId)?.nickName
                            : dataTeam?.secondTeamPlayers.find(v => v.id === team.playerId)
                                ?.nickName ?? ''
                        }, Game ID. ${team.gameProviderId}`,
                        playerPhoto: team.picture ?? '',
                        isCaptain: team.isCaptain,
                        urlSlug: team.userName,
                      }}
                    />
                  ) : (
                    <PlayerCard
                      key={index}
                      {...{
                        playerName: `${team.fullName ?? ''}`,
                        disable: true,
                        playerId: `@${
                          teamNo
                            ? dataTeam?.firstTeamPlayers.find(v => v.id === team.playerId)?.nickName
                            : dataTeam?.secondTeamPlayers.find(v => v.id === team.playerId)
                                ?.nickName ?? ''
                        }, Game ID. ${team.gameProviderId}`,
                        playerPhoto: team.picture ?? '',
                        isCaptain: team.isCaptain,
                        urlSlug: team.userName,
                      }}
                    />
                  );
                })}
                <Stack direction="column">
                  <GradientButton margin="16px" variant="cta" onClick={() => setShowModal(false)}>
                    Tutup
                  </GradientButton>
                </Stack>
              </Stack>
            </Box>
          ) : modalType === 'share-scrim' ? (
            <ShareModal link={window.location.href} setShowModal={setShowModal} />
          ) : modalType === 'loading' ? (
            <Avatar src={IconSource('ic-loader')} />
          ) : (
            modalType === 'ss-fullscreen' && <ZoomImage src={getImageZoom}></ZoomImage>
          )}
        </>
      }
      childrens={
        <PopupMenu
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="top-end"
          style={{ top: 60, left: 'none', zIndex: 200 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right top',
              }}
            >
              <MenuContainer>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuLists
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={event => handleListKeyDown(event)}
                  >
                    <MenuItems
                      autoFocus={false}
                      onClick={() => {
                        setModalType('share-scrim');
                        setShowModal(true);
                      }}
                    >
                      Bagikan Scrim
                    </MenuItems>
                    {currentTeam &&
                      currentTeam?.level?.description === 'Captain' &&
                      (dataTeam?.status?.value === 'DataCompleted' ||
                        dataTeam?.status?.value === 'ResultCalculated') && (
                        <>
                          <MenuSeparator />
                          <MenuItems
                            key="report-cheat"
                            autoFocus={true}
                            onClick={() => {
                              window.open(
                                `mailto:hi@mabar.com?subject=[Laporkan Pertandingan] Scrim ID : ${
                                  dataTeam?.id ?? ''
                                }`,
                              );
                            }}
                          >
                            Laporkan Pertandingan
                          </MenuItems>
                        </>
                      )}
                  </MenuLists>
                </ClickAwayListener>
              </MenuContainer>
            </Grow>
          )}
        </PopupMenu>
      }
    >
      <Modal show={showErrorModal}>{modalError()}</Modal>
      {dataTeam?.status.value === 'Compete' &&
      currentTeam &&
      currentTeam?.urlSlug !== dataTeam.firstTeamUrlSlug &&
      currentTeam?.level.description === 'Captain' ? (
        <Status
          label={
            <SpanText
              fontSize="12px"
              fontWeight="500"
              lineHeight="17.52px"
              textAlign="center"
              color="white"
            >
              Silakan masuk ke Game Mobile Legends dan tunggu undangan
              <SpanText color="#E7B450">
                {' Nick. '}
                {dataTeam?.firstTeamPlayers.find(v => v.name === dataTeam?.firstTeamCaptain)
                  ?.nickName ?? dataTeam?.firstTeamCaptain}
                &nbsp; (ID.{' '}
                {
                  dataTeam?.firstTeamPlayers.find(v => v.name === dataTeam?.firstTeamCaptain)
                    ?.gameProviderId
                }
                )
              </SpanText>{' '}
              kemudian undang member timmu
            </SpanText>
          }
          position="relative"
          onClick={() => null}
        />
      ) : dataTeam?.status.value === 'Compete' &&
        currentTeam &&
        currentTeam?.urlSlug === dataTeam.firstTeamUrlSlug &&
        currentTeam?.level.description === 'Captain' ? (
        <Status
          label="Silakan masuk ke Game Mobile Legends lalu Open Lobby dan undang Kapten tim lawan dan member timmu."
          position="relative"
          onClick={() => null}
        />
      ) : dataTeam?.status.value === 'ResultUpload' ||
        (dataTeam?.status.value === 'Dispute' &&
          currentTeam &&
          currentTeam?.level.description === 'Captain') ? (
        <Status
          label="Menunggu sinkronisasi hasil pertandingan"
          position="relative"
          onClick={() => null}
        />
      ) : dataTeam?.status.value === 'ResultUpload' ||
        (dataTeam?.status.value === 'Dispute' &&
          currentTeam &&
          currentTeam?.level.description !== 'Captain') ? (
        <Status
          label="Hasil pertandingan sudah dikirim oleh kapten, menunggu perhitungan poin"
          position="relative"
          onClick={() => null}
        />
      ) : dataTeam?.status.value === 'Compete' &&
        currentTeam &&
        currentTeam?.level.description !== 'Captain' ? (
        <Status
          label="Silahkan masuk ke Game Mobile Legends dan tunggu undangan dari kaptenmu."
          position="relative"
          onClick={() => null}
        />
      ) : dataTeam?.status.value === 'CheckIn' && currentTeam && !shoudCheckIn ? (
        <Status label="Menunggu Seluruh Tim Check In" position="relative" onClick={() => null} />
      ) : (dataTeam?.status.value === 'DataCompleted' ||
          dataTeam?.status.value === 'ResultCalculated') &&
        dataTeam?.resultType &&
        dataTeam.resultType.value === 'Walkover' &&
        currentTeam ? (
        <Status
          label={`Tim ${convert(dataTeam.winnerTeam) === currentTeam?.urlSlug ? 'lawan' : 'kamu'} 
          (${
            dataTeam.winnerTeam === dataTeam?.firstTeam ? dataTeam?.secondTeam : dataTeam?.firstTeam
          }) tidak hadir`}
          position="relative"
          onClick={() => null}
        />
      ) : (
        <></>
      )}
      {dataTeam && (
        <HeaderMatchDetails
          headerBackground={ImageSource('card.background')}
          teamLeft={{
            teamPosition: 'left',
            teamLogo: dataTeam.firstTeamAvatar,
            teamName: dataTeam.firstTeam,
            teamSchool: dataTeam.firstTeamSchool,
            background: dataTeam.winnerTeam
              ? dataTeam.winnerTeam === dataTeam.firstTeam
                ? 'linear-gradient(180deg, rgba(49, 193, 81, 0.3) 0%, rgba(49, 193, 81, 0.03) 100%)'
                : 'linear-gradient(180deg, rgba(213, 31, 64, 0.3) 0%, rgba(213, 31, 64, 0.03) 100%)'
              : 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
            onClick: () => {
              setTeamNo(true);
              setShowModal(true);
              setModalType('team');
            },
            onWaButtonClick: dataTeam?.firstTeamWhatsAppPhoneNumber
              ? () => {
                  let firstWa = dataTeam.firstTeamWhatsAppPhoneNumber;
                  if (firstWa[0] === '+' && firstWa[1] === '0') {
                    firstWa = firstWa.replace(firstWa[1], '62');
                  } else if (firstWa[0] === '0') {
                    firstWa = firstWa.replace(firstWa[0], '62');
                  }
                  window.open(`https://wa.me/${firstWa}`, '_blank');
                }
              : 'none',
            teamPhotos:
              dataTeam?.status.value === 'DataCompleted' ||
              dataTeam?.status.value === 'ResultCalculated'
                ? playersOne &&
                  playersOne
                    .sort((a, _) => (a.isCaptain ? -1 : 1))
                    .filter(v => v.hasCheckedIn === true)
                    .map(team => {
                      return team.picture;
                    })
                : [],
          }}
          teamRight={{
            teamPosition: 'right',
            teamLogo: dataTeam.secondTeamAvatar,
            teamName: dataTeam.secondTeam,
            teamSchool: dataTeam.secondTeamSchool,
            background: dataTeam.winnerTeam
              ? dataTeam.winnerTeam === dataTeam.secondTeam
                ? 'linear-gradient(180deg, rgba(49, 193, 81, 0.3) 0%, rgba(49, 193, 81, 0.03) 100%)'
                : 'linear-gradient(180deg, rgba(213, 31, 64, 0.3) 0%, rgba(213, 31, 64, 0.03) 100%)'
              : 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
            onClick: () => {
              setTeamNo(false);
              setShowModal(true);
              setModalType('team');
            },
            onWaButtonClick: dataTeam?.secondTeamWhatsAppPhoneNumber
              ? () => {
                  let secondWa = dataTeam.secondTeamWhatsAppPhoneNumber;
                  if (secondWa[0] === '+' && secondWa[1] === '0') {
                    secondWa = secondWa.replace(secondWa[1], '62');
                  } else if (secondWa[0] === '0') {
                    secondWa = secondWa.replace(secondWa[0], '62');
                  }
                  window.open(`https://wa.me/${secondWa}`, '_blank');
                }
              : 'none',
            teamPhotos:
              dataTeam?.status.value === 'DataCompleted' ||
              dataTeam?.status.value === 'ResultCalculated'
                ? playersTwo &&
                  playersTwo
                    .sort((a, _) => (a.isCaptain ? -1 : 1))
                    .filter(v => v.hasCheckedIn === true)
                    .map(team => {
                      return team.picture;
                    })
                : [],
          }}
          matchResult={{
            status:
              dataTeam?.status.value === 'Scheduled' ||
              dataTeam?.status.value === 'CheckIn' ||
              dataTeam?.status.value === 'Invitation' ||
              dataTeam?.status.value === 'Compete' ||
              dataTeam?.status.value === 'ResultUpload' ||
              dataTeam?.status.value === 'Reject' ||
              dataTeam?.status?.value === 'NotValid'
                ? scrimTime.replace('.', ':')
                : currentTeam
                ? (dataTeam?.status.value === 'DataCompleted' ||
                    dataTeam?.status.value === 'ResultCalculated') &&
                  currentTeam?.urlSlug === convert(dataTeam?.winnerTeam)
                  ? 'WIN'
                  : (dataTeam?.status.value === 'DataCompleted' ||
                      dataTeam?.status.value === 'ResultCalculated') &&
                    currentTeam?.urlSlug !== convert(dataTeam?.winnerTeam)
                  ? 'LOSE'
                  : dataTeam?.resultType &&
                    dataTeam?.resultType.value === 'Walkover' &&
                    currentTeam?.urlSlug === convert(dataTeam?.winnerTeam)
                  ? 'WIN'
                  : dataTeam?.resultType &&
                    dataTeam?.resultType.value === 'Walkover' &&
                    currentTeam?.urlSlug !== convert(dataTeam?.winnerTeam)
                  ? 'LOSE'
                  : ''
                : '',
            score:
              dataTeam?.status &&
              (dataTeam?.status.value === 'Dispute' ||
                dataTeam?.status.value === 'DataCompleted' ||
                dataTeam?.status.value === 'ResultCalculated') &&
              (dataTeam?.resultType === undefined ||
                (dataTeam?.resultType && dataTeam.resultType.value === 'NormalWin'))
                ? `${dataTeam?.firstTeamScore ?? 0} : ${dataTeam?.secondTeamScore ?? 0}`
                : dataTeam?.status.value === 'DataCompleted' &&
                  dataTeam?.resultType &&
                  dataTeam?.resultType.value === 'Walkover'
                ? 'W.O'
                : '',
            matchType: dataTeam?.type
              ? dataTeam?.type.value.replace('BO3', 'Best of 3').replace('BO5', 'Best of 5')
              : 'Unknown',
          }}
        />
      )}
      {(dataTeam?.status.value === 'DataCompleted' ||
        dataTeam?.status.value === 'ResultCalculated') &&
      (dataTeam?.resultType === undefined ||
        (dataTeam?.resultType && dataTeam.resultType.value === 'NormalWin')) ? (
        <TabMenu
          data={DataTab}
          onSelected={tab => {
            fetchDataRound(scrim_id, tab.id!);
          }}
        />
      ) : dataTeam?.status.value === 'DataCompleted' &&
        dataTeam?.resultType &&
        dataTeam?.resultType.value === 'Walkover' ? (
        <TabMenu
          data={TabWO}
          variant="left"
          onSelected={() => {
            setDataRound(winResult);
          }}
        />
      ) : (
        <></>
      )}
      {dataTeam?.status.value === 'Scheduled' ||
      dataTeam?.status.value === 'CheckIn' ||
      dataTeam?.status.value === 'Invitation' ||
      dataTeam?.status.value === 'Compete' ||
      dataTeam?.status.value === 'ResultUpload' ||
      dataTeam?.status.value === 'Dispute' ||
      dataTeam?.status.value === 'Reject' ||
      dataTeam?.status?.value === 'NotValid' ? (
        <TabMenu
          data={[
            {
              id: 1,
              title: dataTeam?.firstTeam,
              isSelected: true,
            },
            {
              id: 2,
              title: dataTeam?.secondTeam,
              isSelected: false,
            },
          ]}
          variant="left"
          onSelected={tab => {
            setTabId(tab);
            if (tab.id === 1) {
              GetTabPlayer(scrim_id, dataTeam?.firstTeamUrlSlug, true);
            } else {
              GetTabPlayer(scrim_id, dataTeam?.secondTeamUrlSlug, false);
            }
          }}
        />
      ) : (
        <></>
      )}

      <ContainerToast
        theme="dark"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {dataTeam?.status.value !== 'DataCompleted' &&
        dataTeam?.status.value !== 'ResultCalculated' &&
        tabId?.id === 1 &&
        playersOne && (
          <Box padding="5px 8px" flexGrow={1}>
            <Stack direction="column">
              {playersOne.map((e: TeamPlayer) => {
                const matchType = dataTeam?.status.value;
                const player: PlayerCardType = {
                  playerName: e.fullName,
                  playerId: `@${
                    dataTeam?.firstTeamPlayers?.find(v => v.gameProviderId === e.gameProviderId)
                      ?.nickName ?? e.userName
                  }, Game ID. ${e.gameProviderId}`,
                  playerPhoto:
                    dataTeam?.firstTeamPlayers?.find(v => v.gameProviderId === e.gameProviderId)
                      ?.picture ?? e.picture,
                  isCaptain: e.isCaptain,
                  urlSlug: e.userName,
                  iconRight:
                    matchType === 'CheckIn'
                      ? e.hasCheckedIn === true
                        ? IconSource('like')
                        : ''
                      : '',
                  disable:
                    matchType === 'Scheduled'
                      ? false
                      : e.hasCheckedIn === undefined || e.hasCheckedIn === false,
                  checkInCount: e.hasCheckedIn === true ? 1 : 0,
                  onCopyText: () => {
                    CopyText(e.gameProviderId, 'Game ID berhasil di salin');
                  },
                  textRight:
                    matchType !== 'Scheduled'
                      ? e.hasCheckedIn === true && e.isCaptain
                        ? 'Salin'
                        : ''
                      : '',
                  hasCheckIn: e.hasCheckedIn,
                };
                return <PlayerCard key={player.playerId} {...player} />;
              })}
            </Stack>
          </Box>
        )}

      {dataTeam?.status.value !== 'DataCompleted' &&
        dataTeam?.status.value !== 'ResultCalculated' &&
        tabId?.id === 2 &&
        playersTwo && (
          <Box padding="5px 8px" flexGrow={1}>
            <Stack direction="column">
              {playersTwo.map((e: TeamPlayer) => {
                const matchType = dataTeam?.status.value;
                const player: PlayerCardType = {
                  playerName: e.fullName,
                  playerId: `@${
                    dataTeam?.secondTeamPlayers?.find(v => v.gameProviderId === e.gameProviderId)
                      ?.nickName ?? e.userName
                  }, Game ID. ${e.gameProviderId}`,
                  playerPhoto:
                    dataTeam?.secondTeamPlayers?.find(v => v.gameProviderId === e.gameProviderId)
                      ?.picture ?? e.picture,
                  isCaptain: e.isCaptain,
                  urlSlug: e.userName,
                  iconRight:
                    matchType === 'CheckIn'
                      ? e.hasCheckedIn === true
                        ? IconSource('like')
                        : ''
                      : '',
                  disable:
                    matchType === 'Scheduled'
                      ? false
                      : e.hasCheckedIn === undefined || e.hasCheckedIn === false,
                  checkInCount: e.hasCheckedIn === true ? 1 : 0,
                  onCopyText: () => {
                    CopyText(e.gameProviderId, 'Game ID berhasil di salin');
                  },
                  textRight:
                    matchType !== 'Scheduled'
                      ? e.hasCheckedIn === true && e.isCaptain
                        ? 'Salin'
                        : ''
                      : '',
                  hasCheckIn: e.hasCheckedIn,
                };
                return <PlayerCard key={player.playerId} {...player} />;
              })}
            </Stack>
          </Box>
        )}
      {dataTeam?.status.value === 'CheckIn' && shoudCheckIn && (
        <Box
          padding="24px 16px"
          position="fixed"
          style={{ zIndex: 2, bottom: '0px', left: '0px', right: '0px' }}
        >
          <Countdown
            intervalDelay={0}
            date={new Date(dataTeam?.checkInEndAt!)}
            renderer={({ formatted, completed }) => {
              return (
                !completed && (
                  <Stack direction="row" align="center" display={completed ? 'none' : 'flex'}>
                    <GradientButton
                      backgroundSize="100%"
                      variant="primary"
                      onClick={() => {
                        setModalType('check-in');
                        setShowModal(true);
                      }}
                    >
                      {formatted.hours}:{formatted.minutes}
                      {':'}
                      {formatted.seconds}
                      &nbsp; Check In Sekarang
                    </GradientButton>
                  </Stack>
                )
              );
            }}
          />
        </Box>
      )}
      {(dataTeam?.status.value === 'Compete' || dataTeam?.status.value === 'ResultUpload') &&
      currentTeam?.level.description === 'Captain' &&
      (currentTeam?.urlSlug === dataTeam?.firstTeamUrlSlug ||
        currentTeam?.urlSlug === dataTeam?.secondTeamUrlSlug) ? (
        <Box
          padding="24px 16px"
          position="fixed"
          style={{ zIndex: 2, bottom: '0px', left: '0px', right: '0px' }}
        >
          <Countdown
            intervalDelay={0}
            date={dataTeam?.uploadDeadlineAt}
            renderer={({ formatted, completed }) => {
              return !completed ? (
                <>
                  <Stack direction="row" align="center">
                    {!dataTeam?.logs?.find(v => v !== currentTeam?.id) &&
                    dataTeam?.status.value !== 'Compete' ? (
                      <></>
                    ) : (
                      <GradientButton
                        backgroundSize="100%"
                        variant="primary"
                        onClick={() => {
                          setModalType('upload-ss');
                          setShowModal(true);
                        }}
                      >
                        {formatted.hours}:{formatted.minutes}
                        {':'}
                        {formatted.seconds} - Kirim Hasil Pertandingan
                      </GradientButton>
                    )}
                  </Stack>
                </>
              ) : (
                <></>
              );
            }}
          />
        </Box>
      ) : (
        <></>
      )}
      {(dataTeam?.status.value === 'DataCompleted' ||
        dataTeam?.status.value === 'ResultCalculated') &&
      (dataTeam?.resultType === undefined ||
        (dataTeam?.resultType && dataTeam.resultType.value === 'NormalWin')) ? (
        <Box width="100%">
          <Stack direction="column" margin="0px 16px 0px 16px">
            {dataRound.map((match: ScrimWinLoseProps, index: number) => {
              return <CardScrimWinLose key={index} {...match} />;
            })}
          </Stack>
        </Box>
      ) : (
        <></>
      )}

      {dataTeam?.status.value === 'DataCompleted' &&
      dataTeam?.resultType &&
      dataTeam?.resultType.value === 'Walkover' ? (
        <Box width="100%">
          <Stack direction="column" margin="0px 16px 0px 16px">
            {winResult.map((match: ScrimWinLoseProps, index: number) => {
              return <CardScrimWinLose key={index} {...match} />;
            })}
          </Stack>
        </Box>
      ) : (
        <></>
      )}
    </AppLayout>
  );
};

export default MatchPage;
