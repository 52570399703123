import { Box, Stack } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import { IconButton } from 'design/Buttons/IconButton';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardImportantInfo } from 'design/Cards/CardImportantInfo';
import { CardInfoWithInput } from 'design/Cards/CardInfoWithInput';
import { InfoModal } from 'design/Cards/InfoModal';
import { Player as PlayerCard } from 'design/Cards/Player';
import { SubHeader } from 'design/Cards/SubHeader';
import { TeamProfileTournament } from 'design/Cards/TeamProfileTournament';
import CounterCircle from 'design/Counter/Circle/index';
import { Modal } from 'design/Modal';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource, ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchPlayerProfile } from 'redux/account/AccountServices';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { getMyTeamAction } from 'redux/team/MyTeamReducer';
import {
  resetTeamMemberApprovedAction,
  getTeamMemberApprovedAction,
  Player,
} from 'redux/team/TeamMemberReducer';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';
import {
  fetchTournamentDetail,
  fetchMyTeamFromTournament,
  fetchTournamentListMyTeam,
  submitTournamentRegistration,
  submitTournamentPlayer,
} from 'redux/tournament/TournamentServices';

const TournamentRegisterPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const params = useParams() as { game_slug: string; tournament_slug: string };

  const { playerInfo, teamMembers, isLoading, tournamentDetail, myTeam } = useAppSelector(
    ({ account, teamMember, myTeam, tournament }) => ({
      playerInfo: account?.data,
      teamMembers: teamMember?.data?.items,
      tournamentDetail: tournament?.tournamentDetail,
      myTeam: myTeam?.data?.items,
      isLoading: teamMember?.status?.data === 'loading',
    }),
  );

  var myCurrentTeam = getMyCurrentTeamFromSession();

  const [openToast, setOpenToast] = useState(false);
  const [openModalNotice, setModalNotice] = useState(false);
  const [openModalSuccess, setModalSuccess] = useState(false);
  const [openModalFull, setModalFull] = useState(false);
  const [modalFullContent, setModalFullContent] = useState('Maaf slot turnamen ini sudah penuh.');

  const handleFetchTournamentDetail = (slug: string) => {
    thunkDispatch(fetchTournamentDetail({ tournamentSlug: slug }))
      .unwrap()
      .catch(() => {
        history.push('/explore/tournament/');
      });
  };

  const handleFetchMyTeamFromTournament = (tournamentId: string, teamId: string) => {
    thunkDispatch(
      fetchMyTeamFromTournament({
        tournamentId: tournamentId,
        teamId: teamId,
      }),
    );
  };

  const handleFetchTournamentListMyTeam = (teamId: string) => {
    thunkDispatch(fetchTournamentListMyTeam({ teamId: teamId, pageIndex: 1, pageSize: 100 }));
  };

  const myTeamDetail = myTeam.find(
    v =>
      v.urlSlug ===
      myCurrentTeam.find(v => v.gameId === tournamentDetail?.gameId && v.level.code === 1)?.urlSlug,
  );

  useEffect(() => {
    handleFetchTournamentDetail(params.tournament_slug);
  }, []);

  // after success registered
  useEffect(() => {
    if (openModalSuccess && myTeamDetail) {
      handleFetchTournamentDetail(params.tournament_slug);
      handleFetchTournamentListMyTeam(myTeamDetail.id);
    }
  }, [openModalSuccess]);

  useEffect(() => {
    if (tournamentDetail && myTeamDetail) {
      return handleFetchMyTeamFromTournament(tournamentDetail.id, myTeamDetail.id);
    }
  }, [tournamentDetail]);

  const handleFetchAccountProfile = (username: string) => {
    thunkDispatch(
      fetchPlayerProfile({
        username: username,
      }),
    );
  };

  useEffect(() => {
    if (playerInfo) {
      handleFetchAccountProfile(playerInfo.userName);
    }
  }, []);

  useEffect(() => {
    let foundSchool = true;
    let foundRestrictedTeam = false;
    let foundProvince = true;
    let alreadyRegistered = false;
    let tournamentIsOpen = true;
    if (playerInfo && tournamentDetail && myTeamDetail) {
      if (tournamentDetail.invitedSchoolIds) {
        foundSchool = tournamentDetail.invitedSchoolIds?.find(
          (school: any) => school.id === playerInfo.school.id,
        )
          ? true
          : false;
      }
      if (tournamentDetail.restrictedTeamIds) {
        foundRestrictedTeam = tournamentDetail.restrictedTeamIds?.find(
          (team: any) => team.id === myTeamDetail?.id,
        )
          ? true
          : false;
      }
      if (playerInfo && tournamentDetail.invitedProvinceIds) {
        foundProvince = tournamentDetail.invitedProvinceIds?.find(
          (province: any) => province.id === playerInfo.province.code,
        )
          ? true
          : false;
      }
      alreadyRegistered = tournamentDetail?.teams?.find(
        team => team.id == myTeamDetail?.id && team.status === '2',
      )
        ? true
        : false;

      if (tournamentDetail.state != 5) tournamentIsOpen = false;

      if (
        !foundProvince ||
        foundRestrictedTeam ||
        !foundSchool ||
        alreadyRegistered ||
        !tournamentIsOpen
      ) {
        history.push('/tournament/' + params.game_slug + '/' + params.tournament_slug);
      }
    }
  }, [playerInfo, tournamentDetail]);

  useEffect(() => {
    if (myCurrentTeam.find(team => team.level.code !== 1)) {
      history.push('/tournament/' + params.game_slug + '/' + params.tournament_slug);
    }
  }, [thunkDispatch]);

  useEffect(() => {
    if (playerInfo && myTeam === undefined) {
      dispatch(getMyTeamAction({ pageIndex: 1, pageSize: 50, playerSlug: playerInfo.userName }));
    }
  }, [tournamentDetail]);

  useEffect(() => {
    Promise.all([
      dispatch(resetTeamMemberApprovedAction()),
      dispatch(
        getTeamMemberApprovedAction({
          teamSlug: myTeamDetail?.urlSlug!!,
          pageIndex: 1,
          pageSize: 50,
        }),
      ),
    ]);

    if (
      !!tournamentDetail?.teams?.find(
        v => v.id === myTeamDetail?.id && v.status === '0' && v.players.length > 0,
      ) &&
      myTeamDetail !== undefined
    ) {
      history.push('/tournament/' + params.game_slug + '/' + params.tournament_slug);
    }
  }, [myTeamDetail]);

  const [activeButton, setActiveButton] = useState(false);
  interface Validation {
    isError: boolean;
    message: string;
  }
  const [nickPlayersError, setNickPlayersError] = useState<Validation>();
  const [idPlayerError, setIdPlayerError] = useState<Validation>();

  const submitRegistration = () => {
    const playerInvited: string[] = [];
    selectedPlayers?.forEach(player => {
      playerInvited.push(player?.id ?? '');
    });

    let nick_input = (document.getElementById('nick') as HTMLInputElement).value;
    let id_input = (document.getElementById('id_game') as HTMLInputElement).value;

    if (nick_input == '') {
      setNickPlayersError({ isError: true, message: `Nickname Game wajib diisi` });
    } else {
      setNickPlayersError({ isError: false, message: `` });
    }
    if (id_input == '') {
      setIdPlayerError({ isError: true, message: `ID Game wajib diisi` });
    } else if (/^\d+$/.test(id_input) === false) {
      setIdPlayerError({ isError: true, message: `ID Game hanya boleh angka` });
    } else {
      setIdPlayerError({ isError: false, message: `` });
    }

    if (
      nick_input !== '' &&
      id_input !== '' &&
      /^\d+$/.test(id_input) &&
      tournamentDetail !== undefined &&
      myTeamDetail !== undefined
    ) {
      const errorApiMesssage = (errors?: string) => {
        if (errors !== undefined) {
          setModalFullContent(errors);
        } else {
          setModalFullContent('Maaf Telah Terjadi Kesalahan.');
        }
        setModalFull(true);
      };
      const tournamentRegistThunk = submitTournamentRegistration({
        tournamentId: tournamentDetail.id,
        teamId: myTeamDetail.id,
      });

      const tournamentRegistPlayerThunk = submitTournamentPlayer({
        tournamentId: tournamentDetail.id,
        teamId: myTeamDetail.id,
        captainId: playerInfo?.id!,
        nickname: nick_input,
        gameProviderId: id_input,
        players: playerInvited,
      });

      const registdispatch = () =>
        thunkDispatch(tournamentRegistThunk)
          .unwrap()
          .then(res => {
            if (res && res.isAxiosError) {
              errorApiMesssage(res.errors);
            } else {
              registPlayerDispatch();
            }
          })
          .catch(() => {
            errorApiMesssage();
          });

      const registPlayerDispatch = () =>
        thunkDispatch(tournamentRegistPlayerThunk)
          .unwrap()
          .then(res => {
            if (res && res.isAxiosError) {
              errorApiMesssage(res.errors);
            } else {
              setModalSuccess(true);
            }
          })
          .catch(() => {
            errorApiMesssage();
          });

      if (!!tournamentDetail.teams?.find(v => v.id === myTeamDetail?.id)) {
        registPlayerDispatch();
      } else {
        registdispatch();
      }
    } else {
      setModalNotice(false);
    }
  };

  const handleBackToDetail = () => {
    if (params.tournament_slug) {
      history.push('/tournament/' + params.game_slug + '/' + params.tournament_slug);
    } else {
      history.push('/');
    }
  };

  var maxChoosePlayer =
    typeof tournamentDetail?.maxRosterPerTeam === 'undefined'
      ? 0
      : tournamentDetail?.maxRosterPerTeam;
  var minChoosePlayer =
    typeof tournamentDetail?.minRosterPerTeam === 'undefined'
      ? 0
      : tournamentDetail?.minRosterPerTeam;

  const [selectedPlayers, setDataPlayers] = useState<Player[]>([]);
  const handlePlayerClick = (player: Player) => {
    const isChecked =
      !selectedPlayers?.find(e => {
        return e.id === player.id;
      }) ?? false;
    let newData: Player[] = [];
    if (selectedPlayers?.length < maxChoosePlayer - 1) {
      if (isChecked) {
        newData = [...selectedPlayers];
        if (
          !newData.find(e => {
            return e.id === player.id;
          })
        ) {
          newData.push(player);
        } else {
          newData = selectedPlayers.filter(el => el.id !== player.id);
        }
      } else {
        newData = selectedPlayers.filter(el => el.id !== player.id);
      }
      setDataPlayers(newData);
    } else {
      if (!isChecked) {
        newData = [...selectedPlayers];
        if (
          newData.find(e => {
            return e.id === player.id;
          })
        ) {
          newData = selectedPlayers.filter(el => el.id !== player.id);
          setDataPlayers(newData);
        }
      } else {
        setOpenToast(true);
      }
    }
  };

  useEffect(() => {
    if (selectedPlayers?.length >= minChoosePlayer - 1 && minChoosePlayer !== 0) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  }, [selectedPlayers]);

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('tournament_page_title', { title: 'Registrasi Tim' }),
      background: { color: '#0A0A0B' },
      appBar: {
        logo: false,
        back: {
          to: '/tournament/' + params.game_slug + '/' + params.tournament_slug,
        },
        title: 'Registrasi Tim',
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
      additional: (
        <Toast show={openToast} onClose={() => setOpenToast(false)}>
          <Box sx={{ mt: '42px' }}>
            <Ribbon
              icon={IconSource('check-gameon')}
              label={
                <Text variant="body" sx={{ color: 'success900' }}>
                  Jumlah player maksimal sudah terpenuhi
                </Text>
              }
              sx={GameOnToastStyle}
            />
          </Box>
        </Toast>
      ),
    });
  }, [openToast]);

  return (
    <>
      <SubHeader
        topText={tournamentDetail?.title}
        bottomText={tournamentDetail?.gameName}
        isSticky={true}
      />

      <Box p="0px 16px 24px 16px">
        <Text variant="h3" sx={{ mt: '16px' }}>
          Tim Saya
        </Text>

        <TeamProfileTournament
          bgImage={ImageSource('Dark_Bg_ML', 'png')}
          teamLogo={myTeamDetail?.avatarUrl}
          teamName={myTeamDetail?.name}
          teamInfo={playerInfo?.school?.name}
        />
        <CardInfoWithInput
          header="Data saya"
          content="Pastikan kamu mendaftarkan Nickname Game yang sebenarnya"
          inputLabel="Nickname Game Saya"
          inputId="nick"
          subInputLabel="ID Game Saya"
          subInputId="id_game"
          inputIsError={nickPlayersError?.isError}
          inputErrorText={nickPlayersError?.message}
          subInputIsError={idPlayerError?.isError}
          subInputErrorText={idPlayerError?.message}
        />
        <CardImportantInfo
          title="Penting!"
          content={t('tournament_registration_info_content', {
            minRoster: tournamentDetail?.minRosterPerTeam,
            maxRoster: tournamentDetail?.maxRosterPerTeam,
          })}
        />
        <Box
          sx={{
            border: '1px solid #242628',
            borderRadius: '8px',
            bgcolor: 'neutral700',
            mb: '24px',
          }}
        >
          <Stack direction="row" sx={{ m: '12px 12px 4px 12px' }}>
            <CounterCircle
              maxNumber={maxChoosePlayer}
              currentNumber={selectedPlayers.length + 1}
              isColor={true}
            />
            <Text variant="label" sx={{ ml: '8px' }}>
              Undang player
            </Text>
          </Stack>
          <Stack sx={{ borderBottom: '1px solid #323437' }}>
            <Text variant="subtitle" sx={{ color: 'neutral400', p: '0px 12px 12px 12px' }}>
              Player yang dipilih untuk bergabung menjadi tim kamu
            </Text>
          </Stack>
          {isLoading === false &&
            teamMembers &&
            teamMembers.map((data: any) => {
              if (data.level.value == 'Captain')
                return (
                  <PlayerCard
                    playerName={data.player.name}
                    playerInfo={`@${data.player.nickName}, GAME ID: ${data.player.gameProviderId}`}
                    playerPhoto={data.player.picture}
                    isCaptain={true}
                    isYou={true}
                    icon={IconSource('checklist.orange.captain')}
                    sx={{ mb: '0px', padding: '12px' }}
                  />
                );
              else return <></>;
            })}
          {isLoading === false &&
            teamMembers &&
            teamMembers.map((data: any) => {
              if (data.level.value !== 'Captain')
                return (
                  <PlayerCard
                    playerName={data.player.name}
                    playerInfo={`@${data.player.nickName}, GAME ID: ${data.player.gameProviderId}`}
                    playerPhoto={data.player.picture}
                    isCaptain={false}
                    sx={{ mb: '0px', padding: '12px' }}
                    cta={
                      <IconButton
                        sx={{ p: '0px', border: 'none' }}
                        onClick={() => {
                          handlePlayerClick(data.player);
                        }}
                        icon={
                          selectedPlayers?.find(e => {
                            return e.id === data.player.id;
                          }) ?? false
                            ? IconSource('checklist.orange.active')
                            : IconSource('checklist.inactive')
                        }
                      />
                    }
                  />
                );
              else return <></>;
            })}
        </Box>
        <Text variant="bodySmall" sx={{ lineHeight: '18px', color: 'neutral400', mb: '12px' }}>
          Dengan klik Kirim saya mendaftarkan tim saya sesuai dengan nama, Nickname Game dan ID Game
          masing-masing player yang tertera
        </Text>
        <PrimaryButton wide disabled={!activeButton} onClick={() => setModalNotice(true)}>
          {' '}
          Kirim{' '}
        </PrimaryButton>
      </Box>

      <Modal show={openModalNotice}>
        <InfoModal
          icon={ImageSource('Paperplane', 'png')}
          cta={
            <>
              <SecondaryButton sx={{ mr: '16px' }} onClick={() => setModalNotice(false)} wide>
                Periksa lagi
              </SecondaryButton>
              <PrimaryButton onClick={submitRegistration} wide>
                Ya, kirim
              </PrimaryButton>
            </>
          }
        >
          <Text variant="h2" textAlign="center" mb="8px">
            Yakin player yang kamu pilih sudah benar?
          </Text>
          <Text textAlign="center">
            Player yang sudah diundang tidak bisa diubah lagi. Pastikan player yang dipilih bisa
            mengikuti turnamen sesuai dengan jadwal.
          </Text>
        </InfoModal>
      </Modal>

      <Modal show={openModalSuccess}>
        <InfoModal
          icon={ImageSource('Warning', 'png')}
          cta={
            <PrimaryButton onClick={handleBackToDetail} wide>
              Oke, kembali ke turnamen
            </PrimaryButton>
          }
        >
          <Text variant="h2" textAlign="center" mb="8px">
            Undangan terkirim
          </Text>
          <Text textAlign="center">
            Ingatkan semua player yang diundang untuk menerima undangan kamu agar tim kamu segera
            terdaftar dan bisa mengikuti turnamen
          </Text>
        </InfoModal>
      </Modal>

      <Modal show={openModalFull}>
        <InfoModal
          cta={<PrimaryButton onClick={handleBackToDetail}>Kembali ke Turnamen</PrimaryButton>}
        >
          <Text textAlign="center">{modalFullContent}</Text>
        </InfoModal>
      </Modal>
    </>
  );
};

export default TournamentRegisterPage;
