import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import React from 'react';

export interface TagCardProps {
  title?: string;
  color?: string;
  bgcolor?: string;
  sx?: SxProps<Theme>;
}

export const TagCard = ({ title, color, bgcolor }: TagCardProps) => {
  return (
    <Text
      variant="ribbon"
      sx={theme => ({
        display: 'inline-block',
        color: color ?? 'neutral400',
        bgcolor: bgcolor ?? 'neutral800',
        p: '1px 5px',
        lineHeight: '16px',
        borderRadius: theme.borderRadius.xs,
        whiteSpace: 'nowrap',
      })}
    >
      {title}
    </Text>
  );
};
