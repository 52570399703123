import { Avatar } from 'components/styled/avatar.styled';
import { Stack } from 'components/styled/layout.styled';
import { LayoutContext } from 'context/LayoutContext';
import { IconSource } from 'modules/assetpath';
import { UserManager } from 'oidc-client';
import React, { useContext, useEffect } from 'react';
import { fetchPlayerProfile } from 'redux/account/AccountServices';
import { useAppThunkDispatch } from 'redux/store';
import { fetchMyTeamList, getMyCurrentTeamFromSession } from 'redux/team/TeamService';
import NotFound from 'routes/NotFound';
import { Constants } from 'services/Constants';
import { MyCurrentTeam } from 'types/team';

export const GetSignRedirect = async () => {
  const dispatch = useAppThunkDispatch();
  try {
    await new UserManager({
      response_mode: 'query',
      stateStore: Constants.oidcStorage,
      userStore: Constants.oidcStorage,
      automaticSilentRenew: true,
      silentRequestTimeout: 10,
    })
      .signinRedirectCallback()
      .then(user => {
        if (user && user.profile) {
          const myCurrentTeam: Array<MyCurrentTeam> = user.profile.team;
          Constants.encryptStorage.setItem('current_team', JSON.stringify(myCurrentTeam));
          const thunk = fetchPlayerProfile({
            username: user.profile.user_name,
            accessToken: user.access_token,
          });
          const getMyTeamList = fetchMyTeamList({
            playerSlug: user.profile.user_name,
            pageIndex: 1,
            pageSize: 10,
            token: user.access_token,
          });
          dispatch(thunk)
            .unwrap()
            .then(res => {
              Constants.encryptStorage.setItem('player_profile_info', JSON.stringify(res));
              dispatch(getMyTeamList)
                .unwrap()
                .then(tres => {
                  const tmp: Array<MyCurrentTeam> = [];
                  if (tres?.isAxiosError || tres?.error) {
                    window.location.replace(
                      Constants.encryptStorage.getItem('sign_redirect_url') ?? '/account',
                    );
                  } else {
                    const myTeam = getMyCurrentTeamFromSession();
                    tres.items.forEach((v: any, i: any) => {
                      if (v.id === myTeam[i].id) {
                        tmp.push({
                          id: v.id,
                          name: v?.name,
                          level: myTeam[i].level,
                          urlSlug: v.urlSlug,
                          gameId: myTeam[i].gameId,
                          game: v?.game,
                        });
                      }
                      if (tres.items.length >= i) {
                        Constants.encryptStorage.setItem(
                          'current_team',
                          JSON.stringify(tmp.length >= 1 ? tmp : myCurrentTeam),
                        );
                        window.location.replace(
                          Constants.encryptStorage.getItem('sign_redirect_url') ?? '/account',
                        );
                      }
                    });
                  }
                })
                .catch(() =>
                  window.location.replace(
                    Constants.encryptStorage.getItem('sign_redirect_url') ?? '/account',
                  ),
                );
            })
            .catch(() =>
              window.location.replace(
                Constants.encryptStorage.getItem('sign_redirect_url') ?? '/account',
              ),
            );
        } else {
          throw 'User Not Found';
        }
      });
  } catch (err) {}
};

const SignRedirectCallback = () => {
  const query = window.location.search;
  const params = new URLSearchParams(query);
  const code = params.get('code');
  const scope = params.get('scope');
  const state = params.get('state');
  const sessionState = params.get('session_state');
  const isResponseExists = code && scope && state && sessionState;
  const Callback = React.createContext(GetSignRedirect());

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setTimeout(() => {
      window.location.replace('/account'); //give 10secs if still here, redirect to account
    }, 10000);
  }, []);

  useEffect(() => {
    setValue({
      show: false,
    });
  }, []);

  return isResponseExists ? (
    <Callback.Provider value={GetSignRedirect()}>
      <Stack direction="column" align="center" disableDrag disableSelectText>
        <Avatar
          alt=""
          src={IconSource('ic-loader')}
          fit="contain"
          objectPos="center"
          style={{ position: 'fixed', zIndex: 1999, top: '50%', backgroundSize: '100%' }}
        />
      </Stack>
    </Callback.Provider>
  ) : (
    <NotFound />
  );
};

export default SignRedirectCallback;
