import { Theme, Stack, Avatar } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export interface CardListParticipationAwardProps {
  title?: string | ReactNode;
  description?: string;
  photo?: string;
  sx?: SxProps<Theme>;
  theme?: Theme;
  placeholderType?: 'award';
}

const CardTournamentParticipationStyled: any = ({ sx }: CardListParticipationAwardProps) => {
  return {
    position: 'relative',
    height: '88px',
    '&:last-child': {
      '.cardlistparticipationaward-contentarea': { border: 'none' },
    },
    ...sx,
  };
};

export const CardListParticipationAward = ({
  title,
  description,
  photo,
  sx,
  placeholderType,
}: CardListParticipationAwardProps) => {
  return (
    <Stack direction="row" sx={() => CardTournamentParticipationStyled({ sx })}>
      <Avatar
        src={photo}
        sx={{
          width: '64px',
          height: '64px',
          bgcolor: 'transparent',
          borderRadius:
            photo && placeholderType == undefined
              ? '0'
              : placeholderType == 'award'
              ? '8px'
              : '32px',
          m: '12px 12px 12px 0px',
          '& .MuiAvatar-img ': {
            zIndex: 1,
          },
          '&:before': {
            content: "''",
            background: `url(${
              photo
                ? ''
                : placeholderType == 'award'
                ? IconSource('ph_list_award', 'png')
                : IconSource('ph_list_tournament', 'png')
            }) center no-repeat`,
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundSize: 'cover',
            zIndex: 0,
          },
          svg: {
            display: 'none',
          },
        }}
      />
      <Stack
        justifyContent="center"
        className="cardlistparticipationaward-contentarea"
        sx={{
          width: '100%',
          height: '64px',
          padding: '12px 0px',
          borderBottom: '1px solid #27282B',
        }}
      >
        <Text
          className="cardlistparticipationaward-title"
          variant="label"
          sx={{
            fontWeight: '400',
          }}
        >
          <ResponsiveEllipsis text={title} maxLine={2} ellipsis="..." />
        </Text>
        <Text
          className="cardlistparticipationaward-description"
          variant="body"
          sx={{ color: 'neutral400', mt: placeholderType == 'award' ? '2px' : '4px' }}
        >
          <ResponsiveEllipsis text={description} maxLine={1} ellipsis="..." />
        </Text>
      </Stack>
    </Stack>
  );
};
