import { EncryptStorage } from 'encrypt-storage';
import { WebStorageStateStore } from 'oidc-client';

export class Constants {
  public static stsAuthority = process.env.AUTH_CLIENT_ISSUER;
  public static clientId = process.env.AUTH_CLIENT_ID;
  public static clientSecret = process.env.AUTH_CLIENT_SECRET ?? 'm4b4r-s3cr3t-k3y-st0r4ge';
  public static clientRoot = window.location.origin;
  public static clientScope = process.env.AUTH_CLIENT_SCOPES;
  public static apiRoot = process.env.API_BASE_URL;
  public static appVersion = process.env.APP_VERSION;
  public static oidcPrefix = process.env.OIDC_STORAGE_PREFIX;
  public static encryptStorage = new EncryptStorage(this.clientSecret, {
    storageType: 'localStorage',
    encAlgorithm: 'Rabbit',
    stateManagementUse: true,
  });
  public static oidcStorage = new WebStorageStateStore({
    prefix: Constants.oidcPrefix,
    store: Constants.encryptStorage,
  });
}
export const IDENTITY_CONFIG = {
  authority: Constants.stsAuthority,
  client_id: Constants.clientId,
  client_secret: Constants.clientSecret,
  redirect_uri: `${Constants.clientRoot}/callback`,
  silent_redirect_uri: `${Constants.clientRoot}/silent`,
  post_logout_redirect_uri:
    window.location.protocol +
    '//' +
    window.location.host +
    Constants.encryptStorage.getItem('sign_redirect_url'),
  audience: Constants.apiRoot,
  response_type: 'code',
  response_mode: 'query',
  automaticSilentRenew: false,
  loadUserInfo: false,
  scope: Constants.clientScope,
  revokeAccessTokenOnSignout: true,
  stateStore: Constants.oidcStorage,
  userStore: Constants.oidcStorage,
};

export const METADATA_OIDC = {
  issuer: Constants.stsAuthority,
  jwks_uri: Constants.stsAuthority + '.well-known/openid-configuration/jwks',
  authorization_endpoint: Constants.stsAuthority + 'connect/authorize',
  token_endpoint: Constants.stsAuthority + 'connect/token',
  userinfo_endpoint: Constants.stsAuthority + 'connect/userinfo',
  end_session_endpoint: Constants.stsAuthority + 'connect/endsession',
  check_session_iframe: Constants.stsAuthority + 'connect/checksession',
  revocation_endpoint: Constants.stsAuthority + 'connect/revocation',
  introspection_endpoint: Constants.stsAuthority + 'connect/introspect',
  device_authorization_endpoint: Constants.stsAuthority + 'connect/deviceauthorization',
};
