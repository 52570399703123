import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'design/Text';
import { TextInput } from 'design/Forms/TextInput';

export interface CardInfoWithInputProps {
  header?: string;
  content?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  inputId: string;
  inputIsError?: boolean;
  inputErrorText?: string;
  subInputLabel?: string;
  subInputPlaceholder?: string;
  subInputId: string;
  subInputIsError?: boolean;
  subInputErrorText?: string;
  margin?: string;
}

export const CardInfoWithInput: FC<CardInfoWithInputProps> = ({
  header,
  content,
  inputLabel,
  inputPlaceholder,
  inputId,
  subInputLabel,
  subInputPlaceholder,
  subInputId,
  margin,
  inputIsError,
  inputErrorText,
  subInputIsError,
  subInputErrorText,
}) => {
  return (
    <Box
      m={margin ?? '16px 0px'}
      sx={{
        bgcolor: 'neutral700',
        border: '1px solid #242628',
        borderRadius: '8px',
        p: '12px 12px 24px 12px',
      }}
    >
      <Text variant="label" sx={{ mb: '4px' }}>
        {header}
      </Text>
      <Text variant="subtitle" sx={{ color: 'neutral400', mb: '16px' }}>
        {content}
      </Text>
      <TextInput id={inputId} label={inputLabel} placeholder={inputPlaceholder} sx={{ mb: '24px' }} error={inputIsError} helperText={inputErrorText} />
      <TextInput type='number' id={subInputId} label={subInputLabel} placeholder={subInputPlaceholder} error={subInputIsError} helperText={subInputErrorText}/>
    </Box>
  );
};
