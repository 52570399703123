import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';

export interface CardImportantInfoProps {
  title?: string;
  content?: string;
  margin?: string;  
}

export const CardImportantInfo: FC<CardImportantInfoProps> = ({ title, content, margin }) => {
  return (
    <Stack
      m={margin ?? '16px 0px'}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ background: '#2B2400', borderRadius: '8px' }}
    >
      <Box component="img" alt="" src={IconSource('important-bubble')} />
      <Box sx={{ padding: '2px 12px 8px 0px' }}>
        <Text variant="label" sx={{ fontSize: '12px', lineHeight: '24px', color: '#F0BB01' }}>
          {title}
        </Text>
        <Text variant="subtitle" sx={{ color: '#BFBCAC' }}>
          {content}
        </Text>
      </Box>
    </Stack>
  );
};
