import { Avatar, Theme, Stack, Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { ImageSource, IconSource } from 'modules/assetpath';
import React, { FC, ReactNode } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export interface CardAwardType {
  image?: string;
  imageSx?: SxProps<Theme>;
  label?: string | ReactNode;
  title?: string | ReactNode;
  description?: string;
  isChampion?: boolean;
  isCrown?: boolean;
  sx?: SxProps<Theme>;
}

export const CardAward: FC<CardAwardType> = ({
  image,
  imageSx,
  label,
  title,
  description,
  isChampion = false,
  isCrown = false,
  sx,
}) => {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'neutral600',
          borderRadius: '12px',
          border: '1px solid #27282B',
          boxSizing: 'border-box',
          height: '226px',
          position: 'relative',
          overflow: 'hidden',
          ...sx,
        }}
      >
        <Stack direction="column" alignItems="center" height="100%">
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '162px',
              width: '100%',
            }}
          >
            <Avatar
              src={ImageSource('bg_natchampmvp', 'png')}
              sx={{
                position: 'absolute',
                height: 'inherit',
                width: 'inherit',
                objectFit: 'cover',
                borderRadius: '12px 12px 0px 0px',
              }}
            />
            {isChampion && (
              <Avatar
                src={ImageSource('bg_Championspark', 'png')}
                sx={{
                  height: 'inherit',
                  width: 'inherit',
                  zIndex: '1',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  borderRadius: '12px 12px 0px 0px',
                }}
              />
            )}

            <Avatar
              src={image ?? IconSource('ic-person-white')}
              sx={{
                position: 'absolute',
                zIndex: isChampion ? '5' : '2',
                borderRadius: 0,
                ...imageSx,
              }}
            />
          </Stack>

          <Box
            zIndex="4"
            sx={{
              height: '50px',
              width: '100%',
              background:
                'linear-gradient(180deg, rgba(134, 5, 8, 0.3) 0%, rgba(10, 10, 11, 0) 100%)',
              position: 'absolute',
              left: '0',
              bottom: '12px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '152px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '5',
            }}
          >
            {label}
          </Box>
          <Box
            zIndex="3"
            sx={{
              background:
                'radial-gradient(39.65% 102.96% at 50.15% 50%, rgba(69, 72, 84, 0.4) 0%, rgba(6, 7, 9, 0.4) 100%), #1A1817',
              borderTop: '1px solid #27282B',
              width: '100%',
              height: '62px',
              padding: '18px 8px 8px 8px',
              boxSizing: 'border-box',
              borderRadius: '0px 0px 12px 12px',
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              zIndex="5"
              height="100%"
            >
              {title ? (
                <Text
                  variant="label"
                  align="center"
                  component={typeof title === 'string' ? 'h3' : 'div'}
                >
                  {typeof title === 'string' ? (
                    <ResponsiveEllipsis text={title} maxLine={2} ellipsis="..." />
                  ) : (
                    title
                  )}
                </Text>
              ) : null}

              {description ? (
                <Text
                  variant="subtitle"
                  sx={{
                    color: '#6B6E72',
                    mt: '2px',
                  }}
                >
                  {description}
                </Text>
              ) : null}
            </Stack>
          </Box>
        </Stack>
        {isCrown && (
          <Avatar
            src={ImageSource('ic_crown_with shadow')}
            sx={{
              position: 'absolute',
              top: '14px',
              marginLeft: 'auto',
              marginRight: 'auto',
              left: '0',
              right: '0',
              zIndex: '6',
            }}
          />
        )}
      </Box>
    </>
  );
};
