import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import _ from 'lodash';

export const inviteMember = createAsyncThunk(
  'team-api/member/invite/email',
  async ({ email }: { email: string }) => {
    const url = `team-api/member/invite/email`;
    const response = await ApiService.post(url, { email });
    return response;
  },
);
