import {
  TournamentParticipantAwardsPlayerResponse,
  TournamentParticipantAwardsFrameResponse,
  TournamentParticipantAwardsPlayerHighlightedResponse,
  TournamentParticipantAwardsTeamResponse,
  TournamentParticipantAwardsTeamHighlightedResponse,
} from './AwardProps';
import {
  fetchTournamentParticipantAwardsPlayer,
  fetchTournamentParticipantAwardsFrame,
  fetchTournamentParticipantAwardsPlayerHighlighted,
  fetchTournamentParticipantAwardsTeam,
  fetchTournamentParticipantAwardsTeamHighlighted,
} from './AwardService';
import { createSlice } from '@reduxjs/toolkit';

export interface AwardState {
  tournamentParticipantAwardsPlayer: TournamentParticipantAwardsPlayerResponse | undefined;
  tournamentParticipantAwardsTeam: TournamentParticipantAwardsTeamResponse | undefined;
  tournamentParticipantAwardsFrame: TournamentParticipantAwardsFrameResponse | undefined;
  tournamentParticipantAwardsPlayerHighlighted:
    | TournamentParticipantAwardsPlayerHighlightedResponse
    | undefined;
  tournamentParticipantAwardsTeamHighlighted:
    | TournamentParticipantAwardsTeamHighlightedResponse
    | undefined;
  errors: {
    tournamentParticipantAwardsPlayer: string | undefined;
    tournamentParticipantAwardsTeam: string | undefined;
    tournamentParticipantAwardsFrame: string | undefined;
    tournamentParticipantAwardsPlayerHighlighted: string | undefined;
    tournamentParticipantAwardsTeamHighlighted: string | undefined;
  };
  status: {
    tournamentParticipantAwardsPlayer: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentParticipantAwardsTeam: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentParticipantAwardsFrame: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentParticipantAwardsPlayerHighlighted: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentParticipantAwardsTeamHighlighted: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
}

const initialState: AwardState = {
  tournamentParticipantAwardsPlayer: undefined,
  tournamentParticipantAwardsTeam: undefined,
  tournamentParticipantAwardsFrame: undefined,
  tournamentParticipantAwardsPlayerHighlighted: undefined,
  tournamentParticipantAwardsTeamHighlighted: undefined,
  errors: {
    tournamentParticipantAwardsPlayer: '',
    tournamentParticipantAwardsTeam: '',
    tournamentParticipantAwardsFrame: '',
    tournamentParticipantAwardsPlayerHighlighted: '',
    tournamentParticipantAwardsTeamHighlighted: '',
  },
  status: {
    tournamentParticipantAwardsPlayer: 'idle',
    tournamentParticipantAwardsTeam: 'idle',
    tournamentParticipantAwardsFrame: 'idle',
    tournamentParticipantAwardsPlayerHighlighted: 'idle',
    tournamentParticipantAwardsTeamHighlighted: 'idle',
  },
};

const AwardSlice = createSlice({
  name: 'award',
  initialState: initialState,
  reducers: { resetAward: () => initialState },
  extraReducers(builder) {
    builder
      .addCase(fetchTournamentParticipantAwardsPlayer.pending, state => {
        state.status.tournamentParticipantAwardsPlayer = 'loading';
      })
      .addCase(fetchTournamentParticipantAwardsPlayer.rejected, (state, action) => {
        state.status.tournamentParticipantAwardsPlayer = 'failed';
        state.errors.tournamentParticipantAwardsPlayer = action.error.message;
      })
      .addCase(fetchTournamentParticipantAwardsPlayer.fulfilled, (state, action) => {
        state.status.tournamentParticipantAwardsPlayer = 'succeeded';
        state.tournamentParticipantAwardsPlayer = action.payload;
      })

      .addCase(fetchTournamentParticipantAwardsTeam.pending, state => {
        state.status.tournamentParticipantAwardsTeam = 'loading';
      })
      .addCase(fetchTournamentParticipantAwardsTeam.rejected, (state, action) => {
        state.status.tournamentParticipantAwardsTeam = 'failed';
        state.errors.tournamentParticipantAwardsTeam = action.error.message;
      })
      .addCase(fetchTournamentParticipantAwardsTeam.fulfilled, (state, action) => {
        state.status.tournamentParticipantAwardsTeam = 'succeeded';
        state.tournamentParticipantAwardsTeam = action.payload;
      })

      .addCase(fetchTournamentParticipantAwardsFrame.pending, state => {
        state.status.tournamentParticipantAwardsFrame = 'loading';
      })
      .addCase(fetchTournamentParticipantAwardsFrame.rejected, (state, action) => {
        state.status.tournamentParticipantAwardsFrame = 'failed';
        state.errors.tournamentParticipantAwardsFrame = action.error.message;
      })
      .addCase(fetchTournamentParticipantAwardsFrame.fulfilled, (state, action) => {
        state.status.tournamentParticipantAwardsFrame = 'succeeded';
        state.tournamentParticipantAwardsFrame = action.payload;
      })

      .addCase(fetchTournamentParticipantAwardsPlayerHighlighted.pending, state => {
        state.status.tournamentParticipantAwardsPlayerHighlighted = 'loading';
      })
      .addCase(fetchTournamentParticipantAwardsPlayerHighlighted.rejected, (state, action) => {
        state.status.tournamentParticipantAwardsPlayerHighlighted = 'failed';
        state.errors.tournamentParticipantAwardsPlayerHighlighted = action.error.message;
      })
      .addCase(fetchTournamentParticipantAwardsPlayerHighlighted.fulfilled, (state, action) => {
        state.status.tournamentParticipantAwardsPlayerHighlighted = 'succeeded';
        state.tournamentParticipantAwardsPlayerHighlighted = action.payload;
      })

      .addCase(fetchTournamentParticipantAwardsTeamHighlighted.pending, state => {
        state.status.tournamentParticipantAwardsTeamHighlighted = 'loading';
      })
      .addCase(fetchTournamentParticipantAwardsTeamHighlighted.rejected, (state, action) => {
        state.status.tournamentParticipantAwardsTeamHighlighted = 'failed';
        state.errors.tournamentParticipantAwardsTeamHighlighted = action.error.message;
      })
      .addCase(fetchTournamentParticipantAwardsTeamHighlighted.fulfilled, (state, action) => {
        state.status.tournamentParticipantAwardsTeamHighlighted = 'succeeded';
        state.tournamentParticipantAwardsTeamHighlighted = action.payload;
      });
  },
});

export const { resetAward } = AwardSlice.actions;
export default AwardSlice.reducer;
