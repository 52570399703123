import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import styled from 'styled-components';

export const ButtonFBShare = styled(FacebookShareButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  position: static;
  width: 42px;
  height: 42px;

  background-color: #242731;
  border-radius: 50%;

  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  &:active {
    opacity: 0.7;
    background: #d51f40;
  }
`;

export const ButtonEmailShare = styled(EmailShareButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  position: static;
  width: 42px;
  height: 42px;

  background-color: #242731;
  border-radius: 50%;

  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  &:active {
    opacity: 0.7;
    background: #d51f40;
  }
`;

export const ButtonTwitterShare = styled(TwitterShareButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  position: static;
  width: 42px;
  height: 42px;

  background-color: #242731;
  border-radius: 50%;

  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  &:active {
    opacity: 0.7;
    background: #d51f40;
  }
`;


export const BorderIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  position: static;
  width: 22px;
  height: 22px;

  background: #242731;
  border-radius: 50%;

  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  &:active {
    opacity: 0.7;
    background: #d51f40;
  }
`;