import { ScrollContainer, Wrapper } from './styles';
import { Theme, Drawer as MUIDrawer, DrawerProps, Stack } from '@mui/material';
import { IconButton } from 'design/Buttons/IconButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CustomDrawerProps extends DrawerProps {
  theme?: Theme;
  children?: ReactNode;
  position?: DrawerProps['anchor'];
  open?: boolean;
  onClose?: () => void;
  height?: string;
  buttonBottom?: boolean | undefined;
}

export const DrawerStatusAnggotaTim = ({
  title,
  open,
  onClose,
  height,
  buttonBottom,
  position,
  children,
}: CustomDrawerProps) => {
  const { t } = useTranslation();
  const [isScrollable, setIsScrollable] = useState(false);
  const boxRef = useRef<any>();
  const boxChildRef = useRef<any>();

  useEffect(() => {
    setIsScrollable(boxChildRef.current?.clientHeight > boxRef.current?.clientHeight);
  }, [open]);

  return (
    <MUIDrawer
      anchor={position}
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          p: '12px 16px',
          m: '0 auto',
          backgroundImage: 'none',
          bgcolor: 'neutral700',
          maxHeight:
            height || (position === 'top' || 'bottom' ? 'calc(100% - (56px + 24px))' : 'auto'),
          borderRadius: position === 'bottom' ? '12px 12px 0 0' : '',
          justifyContent: 'space-between',
          maxWidth: '468px',
        },
      }}
      transitionDuration={{ enter: 350, exit: 250 }}
    >
      <Stack direction="row" alignItems="center" sx={{ mb: '12px' }}>
        <IconButton
          icon={IconSource('ic-close-white2')}
          onClick={onClose}
          sx={{ p: 0, background: 'transparent', border: 0, mr: '12px' }}
        />
        <Text variant="h3">{title}</Text>
      </Stack>
      <ScrollContainer ref={boxRef} isScrollable={isScrollable}>
        <Wrapper ref={boxChildRef} sx={{ pr: isScrollable ? '6px' : '' }}>
          {children}
        </Wrapper>
      </ScrollContainer>
      {buttonBottom ||
        (buttonBottom == undefined && (
          <SecondaryButton wide onClick={onClose}>
            {t('tournament_tutup')}
          </SecondaryButton>
        ))}
    </MUIDrawer>
  );
};
