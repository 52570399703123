import {
  MatchHistoryResponse,
  TeamMemberResponse,
  TeamDetailResponse,
} from './TeamProfileResponse';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export const fetchTeamDetail = createAsyncThunk(
  'team-profile/team-detail',
  async ({ teamSlug, version }: { teamSlug: string; version?: string }) => {
    const url = `/${version ? `v${version}/` : ''}team/${teamSlug}`;
    const res = await ApiService.get<TeamDetailResponse>(url);
    return res.data;
  },
);

export const fetchTeamMember = createAsyncThunk(
  'team-profile/team-member',
  async ({
    teamSlug,
    pageIndex,
    pageSize,
    version,
    approved,
  }: {
    teamSlug: string;
    pageIndex: number;
    pageSize: number;
    version: string;
    approved: boolean;
  }) => {
    const url = `/${version ? `v${version}/` : ''}team-member${
      approved ? '/approved' : ''
    }/${teamSlug}/${pageIndex}/${pageSize}`;
    const res = await ApiService.get<TeamMemberResponse>(url);
    return res.data;
  },
);

export const fetchTeamHistory = createAsyncThunk(
  'team-profile/history',
  async ({
    teamSlug,
    pageIndex,
    pageSize,
    version,
  }: {
    teamSlug: string;
    pageIndex: number;
    pageSize: number;
    version: string;
  }) => {
    const url = `/match-api/${
      version ? `v${version}/` : ''
    }history/team/${teamSlug}/${pageIndex}/${pageSize}`;
    const res = await ApiService.get<MatchHistoryResponse>(url);
    return res.data;
  },
);

export const directApproval = createAsyncThunk(
  'team-profile/direct-approval',
  async ({ matchId, players }: { matchId: string; players: string[] }) => {
    try {
      const url = `/scrim/direct-approval/${matchId}`;
      const res = await ApiService.put(url, {
        id: matchId,
        players,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const openApproval = createAsyncThunk(
  'team-profile/open-approval',
  async ({ matchId, players }: { matchId: string; players: string[] }) => {
    try {
      const url = `/scrim/open-approval/${matchId}`;
      const res = await ApiService.put(url, {
        id: matchId,
        players,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const inviteMember = createAsyncThunk(
  'team-member/invite',
  async ({ teamId, members }: { teamId: string; members: string[] }) => {
    try {
      const url = `/team-member/invite`;
      const res = await ApiService.post(url, {
        teamId,
        members,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const rejectInvitation = createAsyncThunk(
  'team-profile/reject-invitation',
  async ({ matchId }: { matchId: string }) => {
    try {
      const url = `/scrim/reject/${matchId}`;
      const res = await ApiService.put(url, {
        id: matchId,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const checkIn = createAsyncThunk(
  'team-profile/match/check-in',
  async ({ matchId }: { matchId: string }) => {
    try {
      const url = `/scrim-player/check-in/${matchId}`;
      const res = await ApiService.put(url, {
        matchId,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const deleteMyTeam = createAsyncThunk(
  'myteam/remove',
  async ({ teamId }: { teamId: string }) => {
    try {
      const url = `/team/disband/${teamId}`;
      const res = await ApiService.delete(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);
