import RosterDrawer from '../Drawer/RosterDrawer';
import FeedTournament from '../Section/FeedTournament';
import { Stack, Box } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { LayoutContext } from 'context/LayoutContext';
import navigationMenu from 'data/navigationMenu.json';
import { Text } from 'design/Text';
import _ from 'lodash';
import { onClickShare } from 'modules/helpers';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { resetTeamProfile } from 'redux/teamProfile/TeamProfileReducer';
import { fetchTeamDetail } from 'redux/teamProfile/TeamProfileService';
import { fetchTournamentParticipantTeam } from 'redux/tournament/TournamentServices';

const TournamentList = () => {
  const { t } = useTranslation();
  const params = useParams() as { gameSlug: string; schoolSlug: string; teamSlug: string };
  const { setValue } = useContext(LayoutContext);
  const dispatch = useAppThunkDispatch();
  const history = useHistory();

  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [openDrawerRoster, setIsOpenDrawerRoster] = useState(false);
  const [dataDrawerRoster, setDataDrawerRoster] = useState<any>([]);

  const { playerInfo, teamDetail, tournamentParticipant, tournamentParticipantStatus } =
    useAppSelector(({ account, tournament, teamProfile }) => ({
      playerInfo: account?.data,
      teamDetail: teamProfile?.teamDetail,
      tournamentParticipant: tournament?.tournamentParticipantTeam?.events,
      tournamentParticipantStatus: tournament?.status?.tournamentParticipantTeam,
    }));

  const handleFetchTournamentParticipantTeam = (teamId: string) => {
    dispatch(fetchTournamentParticipantTeam({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTeamDetail = (teamSlug: string) => {
    dispatch(fetchTeamDetail({ teamSlug: teamSlug, version: '' }))
      .unwrap()
      .catch(error => setError(error));
  };

  // only fetch different latest parameter from teamDetail
  const handleFetchCallback = useCallback(
    (teamId?: string) => {
      if (teamId) {
        handleFetchTournamentParticipantTeam(teamId);
      }
    },
    [teamDetail?.id],
  );

  const handleResetTeamProfile = () => {
    // only for the first time load
    if (params.teamSlug !== teamDetail?.urlSlug) {
      dispatch(resetTeamProfile());
    }
  };

  const handleOpenDrawerRoster = () => {
    setIsOpenDrawerRoster(!openDrawerRoster);
  };

  const handleTournamentParticipantRank = (rank: String) => {
    switch (rank) {
      case '1':
        return 'Champion';
      case '2':
        return '2nd Place';
      case '3':
        return '3rd Place';
      default:
        return 'Playoff';
    }
  };

  const handleBack = () => {
    if (history.action === 'POP') {
      return '/';
    }
    return 'goback';
  };

  const handleButtonRoster = (tournament: any) => {
    setDataDrawerRoster({
      ...tournament,
      ...{ teamAvatarUrl: tournament?.teamAvatarUrl ?? teamDetail?.avatarUrl },
    });
    setIsOpenDrawerRoster(true);
  };

  const handleLoading = (status: boolean) => {
    if (status) return !Boolean(teamDetail && tournamentParticipant);
    return false;
  };

  const dataTournamentFeed = _.chain(tournamentParticipant)
    .orderBy(['split'], ['desc'])
    .groupBy('split')
    .map((value: any, key: any) => ({
      title: key,
      data: value,
      splitStart: value?.find((data: any) => data).splitStart,
      splitEnd: value?.find((data: any) => data).splitEnd,
    }))
    .value();

  useEffect(() => {
    // get lastest data from teamDetail loop from handleFetchCallback()
    if (params.teamSlug === teamDetail?.urlSlug) {
      handleFetchCallback(teamDetail?.id);
    }
  }, [handleFetchCallback]);

  useEffect(() => {
    Promise.all([handleResetTeamProfile(), handleFetchTeamDetail(params.teamSlug)]);
  }, []);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Team Profile Tournament' }),
      background: {
        color: 'neutral800',
      },
      appBar: {
        logo: false,
        back: {
          to: handleBack(),
        },
        menu: navigationMenu,
        title: (
          <Stack direction="column" sx={{ textAlign: 'center' }}>
            <Text variant="h3" sx={{ fontWeight: 700, letterSpacing: '0.12px' }}>
              {t('participation_tournament_title')}
            </Text>
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '2px' }}>
              {teamDetail?.name}
            </Text>
          </Stack>
        ),
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: t('mabar_page_title', { title: 'Team Profile Tournament' }),
              url: `${window.location.origin}/team/${params.gameSlug}/${params.schoolSlug}/${params.teamSlug}/tournaments`,
              handleIconShare: () => handleShare(),
            });
          }
        },
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, [playerInfo, teamDetail]);

  return (
    <>
      <Box>
        <Stack>
          <FeedTournament
            dataFeed={dataTournamentFeed}
            handleButtonRoster={handleButtonRoster}
            handleTournamentParticipantRank={handleTournamentParticipantRank}
          />
        </Stack>
        <Box sx={{ textAlign: 'center', p: '16px' }}>
          <Text sx={{ color: 'neutral500' }}>{t('participant_tournament_end_of_list')}</Text>
        </Box>
      </Box>

      <RosterDrawer
        open={openDrawerRoster}
        onClose={handleOpenDrawerRoster}
        onOpen={handleOpenDrawerRoster}
        dataEvent={dataDrawerRoster}
        playerId={playerInfo?.id ?? ''}
        title="TURNAMEN"
        eventStatus={
          dataDrawerRoster?.rank === '' || dataDrawerRoster?.rank === undefined
            ? 'Partisipan'
            : handleTournamentParticipantRank(dataDrawerRoster?.rank)
        }
      />

      <LoadingModal
        show={error ? true : handleLoading(tournamentParticipantStatus === 'loading')}
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default TournamentList;
