import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IBottomNavMenuContainer, INavMenuItem, INavMenuButton } from './index';

export const BottomNavMenuLayout = styled.nav<IBottomNavMenuContainer>`
  position: ${props => props.pos ?? 'fixed'};
  z-index: 199;
  bottom: 0px;
  transition: 0.4s;
  border: 1px solid #373942;
  box-sizing: border-box;
  display: flex;
  overflow-y: hidden;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  margin: 0px auto;
  border-radius: ${props => props.bRadius ?? '22px 22px 0px 0px'};
  width: 100%;
  height: ${props => props.height ?? '60px'};
  background: ${props => props.bg ?? '#242731'};
  box-shadow: ${props => props.boxShadow ?? '0px 8px 20px rgba(0, 0, 0, 0.05)'};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media only screen and (min-width: 500px) {
    max-width: 500px;
  }
`;

export const NavMenuButton = styled.ul<INavMenuButton>`
  width: auto;
  height: auto;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background: ${props =>
    props.isSelected
      ? 'linear-gradient(131.42deg, #E75570 32.19%, #ED676A 53.45%, #F17267 66.7%, #FD965B 83.78%)'
      : 'transparent'};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background: ${props =>
      props.isSelected
        ? 'linear-gradient(131.42deg, #E75570 32.19%, #ED676A 53.45%, #F17267 66.7%, #FD965B 83.78%)'
        : '#373942'};
  }
  &:active {
    background: linear-gradient(
      131.42deg,
      #e75570 32.19%,
      #ed676a 53.45%,
      #f17267 66.7%,
      #fd965b 83.78%
    );
  }
  &:focus {
    background: linear-gradient(
      131.42deg,
      #e75570 32.19%,
      #ed676a 53.45%,
      #f17267 66.7%,
      #fd965b 83.78%
    );
  }
`;

export const NavMenuLink = styled(Link)<INavMenuItem>`
  display: flex;
  width: 24px;
  height: 24px;
  transition: 0.4s;
  -webkit-appearance: none;
  background: ${props => `url('${process.env.PUBLIC_URL}/media/icons/ic-${props.icon}-white.svg')`};
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: ${props => (props?.isSelected ? 700 : 500)};
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  flex-grow: 1;
  text-decoration: solid;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
`;
