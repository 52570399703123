export const SINGLE_ID = '1';
export const DOUBLE_ID = '2';
export const SINGLE_ROUND_ROBIN = '3';

export const UPCOMING = 1;
export const BRACKET_GENERATED = 2;
export const FINISHED = 3;
export const REGISTRATION_OPENED = 5;
export const REGISTRATION_CLOSED = 6;
export const COMPETITION_STATED = 7;

export const STATE = {
  DRAFT: 0,
  UPCOMING: 1,
  BRACKET_GENERATED: 2,
  FINISHED: 3,
  CANCELLED: 4,
  REGISTRATION_OPENED: 5,
  REGISTRATION_CLOSED: 6,
  COMPETITION_STATED: 7,
};

export const TEAM_STATE = {
  MEMBERS_INVITATION_STATED: '0' || '',
  QUEUED: '1',
  JOINED: '2',
  DISQUALIFIED: '3',
  REJECTED: '4',
};

export const TEAM_STATUS = {
  PENDING: '0',
  ACCEPTED: '2',
  DISQUALIFIED: '3',
  REJECTED: '4',
};

export const PLAYER_STATUS = {
  PENDING: '0',
  ACCEPTED: '1',
};

export const PLAYER_STATE = {
  PENDING: 0,
  ACCEPTED: 1,
};
