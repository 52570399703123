import styled from 'styled-components';
import { MatchResultProps } from './index';

export const Card = styled.div<MatchResultProps>`
  position: relative;
  width: ${props=> props.containerWidth ?? '100%'};
  height: 180px;
  background: ${props => props.bg ?? 'rgba(55, 57, 66, 0.6)'};
  border: ${props => props.border ?? '4px solid rgba(213, 31, 64, 0.3)'};
  box-sizing: border-box;
  border-radius: 16px;
`;

export const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 10px 16px 12px; */

  position: relative;
  width: 63%;
  /* height: 28px; */

  background: #1e1f25;
  border-radius: 0px 0px 16px 16px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

export const BorderDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;

  position: relative;
  bottom: 70px;
  width: 51%;
  height: 25px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
`;
export const HeaderTitle = styled.p`
  position: relative;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #ddd8d8;
`;

export const TextTeam = styled.p`
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  text-align: left;
  margin-left: 13px;
  width: 118px;
  height: 20px;
`;

export const TextTeam1 = styled.p`
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  text-align: right;
  margin-left: auto;
  right : 12px;
  width: 118px;
  height: 20px;
`;
export const Score1 = styled.p`
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
  text-align: left;
  margin-left: 13px;
`;
export const Score2 = styled.p`
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
  text-align: right;
  margin-right: 13px;
`;

export const BorderVs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  position: relative;
  width: 31px;
  height: 24px;
  bottom: 85px;
  background: rgba(213, 31, 64, 0.12);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
`;
