import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TabsContainerProps, TabsItemProps, ITabsItemProps } from './index';

export const TabsContainer = styled.div<TabsContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.background ?? 'transparent'};
  box-shadow: ${props => props.bShadow ?? 'none'};
  padding: ${props => props.padding ?? '24px 0px'};
  position: ${props => props.position ?? 'static'};
  width: ${props => props.width ?? 'auto'};
  height: ${props => props.height ?? '80px'};
  flex: none;
  order: 0;
  flex-grow: 0;
  box-sizing: content-box;
  margin: ${props => props.margin ?? '20px 0px'};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;

  @media only screen and (min-width: 480px) {
    max-width: 500px;
  }
`;

export const TabsItems = styled.nav<TabsItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: ${props => props.position ?? 'static'};
  padding: ${props => props.padding ?? '6px 10px'};
  background: ${props =>
    props.isselected?.match('true') ? props.background ?? 'rgba(55, 57, 66, 0.4)' : 'transparent'};
  border-radius: ${props => props.bRadius ?? '8px'};
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  margin: ${props => props.margin ?? '0px 16px'};
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
`;

export const TabsLink = styled(Link)<ITabsItemProps>`
  position: static;
  width: auto;
  height: auto;
  background: ${props =>
    props.isselected?.match('true')
      ? 'linear-gradient(131.42deg, #E75570 32.19%, #ED676A 53.45%, #F17267 66.7%, #FD965B 83.78%)'
      : 'rgba(150, 147, 147, 1)'};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  flex: none;
  cursor: pointer;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
`;
