import { fetchMyTeamList } from './TeamService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameOptions } from 'redux/game/GameReducer';
import { KeyValue } from 'types';

export interface MyTeamResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  totalCount: number;
  totalPages: number;
  items: MyTeamItem[];
}

export interface MyTeamState {
  data: MyTeamResponse;
  loading: boolean;
  errorMessage: any;
  errorCode: number;
  status: {
    myTeamList: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
  error: {
    myTeamList: string | undefined;
  };
}

export interface MyTeamItem {
  [x: string]: any;
  id: string;
  name: string;
  urlSlug: string;
  avatarUrl: string;
  status: KeyValue;
  invitationType: KeyValue;
  game: GameOptions;
  memberCount: number;
  matchCount: number;
}

const initialState: MyTeamState = {
  data: {
    pageIndex: 0,
    pageSize: 0,
    count: 0,
    totalCount: 0,
    totalPages: 0,
    items: [],
  },
  loading: false,
  errorMessage: '',
  errorCode: 0,
  status: {
    myTeamList: 'idle',
  },
  error: {
    myTeamList: '',
  },
};

const MyTeamSlice = createSlice({
  name: 'MyTeam',
  initialState: initialState,
  reducers: {
    getMyTeamAction: (
      state,
      action: PayloadAction<{
        playerSlug: string;
        pageIndex: number;
        pageSize: number;
        search?: string;
        version?: string;
      }>,
    ) => {
      state.loading = true;
      state.data = {
        ...state.data,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
      };
    },
    resetMyTeamAction: _state => {
      _state = undefined as unknown as any;
    },
    successGetMyTeamAction: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    failedGetMyTeamAction: (state, action) => {
      state.errorMessage = action.payload.error;
      state.errorCode = action.payload.error.status;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMyTeamList.pending, state => {
        state.loading = true;
        state.status.myTeamList = 'loading';
      })
      .addCase(fetchMyTeamList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message ?? '';
        state.status.myTeamList = 'failed';
      })
      .addCase(fetchMyTeamList.fulfilled, (state, action) => {
        if (action.payload?.isAxiosError || action.payload?.error) {
          state.errorMessage = action.payload?.error;
          state.loading = false;
          state.status.myTeamList = 'failed';
        } else {
          state.data = action.payload;
          state.loading = false;
          state.status.myTeamList = 'succeeded';
        }
      });
  },
});

export const { resetMyTeamAction, getMyTeamAction, successGetMyTeamAction, failedGetMyTeamAction } =
  MyTeamSlice.actions;

export default MyTeamSlice.reducer;
