import { Stack } from '@mui/material';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardHeaderAccount } from 'design/Cards/CardHeaderAccount';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import { onClickShare } from 'modules/helpers';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppSelector } from 'redux/hooks';

interface HeaderAccountProps {
  handleOpenDrawer: () => void;
}

const HeaderAccount = ({ handleOpenDrawer }: HeaderAccountProps) => {
  const { t } = useTranslation();
  const { playerSlug } = useParams<{ playerSlug: string }>();

  const { publicProfile, tournamentParticipantAwardsFrame } = useAppSelector(
    ({ publicProfile, award }) => ({
      publicProfile: publicProfile?.publicProfile,
      tournamentParticipantAwardsFrame: award?.tournamentParticipantAwardsFrame,
    }),
  );

  const handlePlayerFrame = useMemo(() => {
    const dataPlayerMvp = (tournamentParticipantAwardsFrame?.awards ?? []).find(
      data => data?.teamId == undefined,
    );
    const dataPlayerAwards = (tournamentParticipantAwardsFrame?.awards ?? []).find(
      data => data?.teamId === publicProfile?.teams[0].teamId,
    );

    const isPlayerGetMvp = publicProfile?.id
      ? dataPlayerMvp?.playerIds?.includes(publicProfile?.id)
      : false;
    const isPlayerGetAward = publicProfile?.id
      ? dataPlayerAwards?.playerIds?.includes(publicProfile?.id)
      : false;

    if (isPlayerGetMvp || isPlayerGetAward) {
      return {
        image: isPlayerGetMvp ? dataPlayerMvp?.playerFrameUrl : dataPlayerAwards?.playerFrameUrl,
        text: isPlayerGetMvp
          ? dataPlayerMvp?.frameTitle.toLocaleUpperCase()
          : dataPlayerAwards?.frameTitle.toLocaleUpperCase(),
      };
    }

    return undefined;
  }, [publicProfile?.id, publicProfile?.teams[0].teamId, tournamentParticipantAwardsFrame]);

  const handleShare = () => {
    return onClickShare({
      title: t('tournament_page_title', { title: 'Profile Saya' }),
      url: `${window.location.origin}/player/${playerSlug}`,
    });
  };

  return (
    <CardHeaderAccount
      photo={publicProfile?.picture ?? ''}
      fullname={[publicProfile?.firstName ?? '', publicProfile?.lastName ?? ''].join(' ')}
      description={[publicProfile?.school.name ?? '', publicProfile?.school?.province ?? '']
        .join(', ')
        .replace(/^,|,\s*$/, '')}
      frame={handlePlayerFrame}
      cta={
        <Stack direction="row" spacing="7px" sx={{ width: '100%' }}>
          <SecondaryButton
            variant="small"
            wide
            icon={IconSource('ic-game-profile')}
            sx={{ flex: 1, whiteSpace: 'nowrap', svg: { width: '16px', mr: '4px' } }}
            onClick={handleOpenDrawer}
          >
            <Text variant="button">Profil Game</Text>
          </SecondaryButton>
          <SecondaryButton
            variant="small"
            wide
            icon={IconSource('ic-share-social-white')}
            sx={{ flex: 1, whiteSpace: 'nowrap', svg: { width: '16px', mr: '4px' } }}
            onClick={handleShare}
          >
            <Text variant="button">Bagikan Profil</Text>
          </SecondaryButton>
        </Stack>
      }
    />
  );
};

export default memo(HeaderAccount);
