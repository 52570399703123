import styled from 'styled-components';

interface NavProps {
  height?: string | undefined;
  width?: string | undefined;
  isSelected?: boolean | undefined;
}

export const NavBar = styled.nav<NavProps>`
  background: rgba(36, 39, 49, 0.94);
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: fixed;
  z-index: 999;
  top: 0;
  height: ${p => p.height};
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

export const BottomNavContainer = styled.nav`
  padding: 10px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  bottom: 0;
  margin: 0px;
  z-index: 99;
  width: 100%;
  background: #242731;
  border: 1px solid #373942;
  box-sizing: border-box;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
  border-radius: 22px 22px 0px 0px;
`;

export const BottomNavIcon = styled.img<NavProps>`
  width: 40px;
  height: 40px;
  padding: 8px;
  opacity: 1;
  background: ${p =>
    p.isSelected
      ? 'linear-gradient(131.42deg,#e75570 32.19%, #ed676a 53.45%,#f17267 66.7%,#fd965b 83.78% )'
      : 'none'};
  border-radius: 10px;
  &:hover {
    background: ${p =>
      p.isSelected
        ? 'linear-gradient(131.42deg,#e75570 32.19%, #ed676a 53.45%,#f17267 66.7%,#fd965b 83.78% )'
        : '#FFFFFF22'};
  }
  &:active {
    opacity: 0.8;
    background: linear-gradient(
      131.42deg,
      #e75570 32.19%,
      #ed676a 53.45%,
      #f17267 66.7%,
      #fd965b 83.78%
    );
  }
`;
