import {
  CardContainer,
  CardContainerContent,
  CardThumbnailWrapper,
  ImgThumbnail,
  IconTournamentInfo,
  CardContainerChildren,
} from './styles';
import Stack from '@mui/material/Stack';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface CardTournamentListProps {
  title?: string;
  banner?: string;
  desc?: string;
  schedule?: string;
  price?: string;
  type?: string;
  tag?: ReactNode;
  status?: ReactNode;
  children?: ReactNode;
  to?: object;
}

export const CardTournamentChild = CardContainerChildren;

export const CardTournamentList = ({
  title,
  banner,
  desc,
  schedule,
  price,
  type,
  tag,
  status,
  children,
  to,
}: CardTournamentListProps) => {
  const content = (
    <CardContainer>
      <CardThumbnailWrapper>
        <ImgThumbnail src={banner} />
        {tag}
      </CardThumbnailWrapper>
      <CardContainerContent>
        <Text variant="h3" sx={{ mb: '4px' }}>
          {title}
        </Text>
        <Text sx={{ mb: '8px', color: 'neutral300' }}>{desc}</Text>
        <Stack spacing={1}>
          {schedule && (
            <Stack direction="row" alignItems="center">
              <IconTournamentInfo src={IconSource('ic-calendar')} />
              <Text variant="body">{schedule}</Text>
            </Stack>
          )}
          {price && (
            <Stack direction="row" alignItems="center">
              <IconTournamentInfo src={IconSource('ic-trophy2')} />
              <Text variant="body">{price}</Text>
            </Stack>
          )}
          {type && (
            <Stack direction="row" alignItems="center">
              <IconTournamentInfo src={IconSource('ic-gamepad')} />
              <Text variant="body">{type}</Text>
            </Stack>
          )}
        </Stack>
      </CardContainerContent>
      {children}
      {status}
    </CardContainer>
  );

  const tournamentCardContent = to ? <Link to={to}>{content}</Link> : content;

  return tournamentCardContent;
};
