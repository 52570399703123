import React, { FC, ReactNode } from 'react';
import { IndicatorContainer, IndicatorItem } from './indicator.styles';

export interface PaginationIndicatorType {
  pageCount: number;
  selectedIndex: number;
  itemPosition: 'left' | 'center' | 'right';
  direction: 'vertical' | 'horizontal' | 'both';
  onSelected?: React.MouseEventHandler<HTMLDivElement>;
}

const PaginationIndicator: FC<PaginationIndicatorType> = ({
  pageCount,
  selectedIndex,
  itemPosition,
  direction,
  onSelected,
}) => {
  let items: ReactNode[] = [];
  let [selectedItem, setSelectedItem] = React.useState(selectedIndex);
  React.useEffect(()=> {
    setSelectedItem(selectedIndex)
  }, [selectedIndex])


  for (let i = 0; i < pageCount; i++) {
    if (i === selectedItem || selectedItem < 0 || selectedItem > pageCount) {
      items.push(
      <IndicatorItem key={'IndicatorItemSelected'} id={'IndicatorItemSelected'} 
        isSelected={true} color="#FFFFFF"/>);
    } else {
      items.push(
      <IndicatorItem key={'IndicatorItem'+i} id={'IndicatorItem'+i} 
        borderColor="#C4C4C4" onClick={(e) => {
          if(onSelected) {
            onSelected(e)
            setSelectedItem(i)
          } 
        }}/>
      );
    }
  }

  return <IndicatorContainer position={itemPosition} direction={direction}>{items}</IndicatorContainer>;
};

export default PaginationIndicator;
