import { Box, Stack, Avatar } from '@mui/material';
import { FrameLogo } from 'design/Avatars/FrameLogo';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

export interface TeamProfileHeaderProps {
  logo?: string;
  teamName?: string;
  teamSchool?: string;
  teamLocation?: string;
  cta?: ReactNode;
  frame?: {
    image?: string;
    text?: string;
  };
}

export const TeamProfileHeader = ({
  logo,
  teamName,
  teamSchool,
  teamLocation,
  cta,
  frame,
}: TeamProfileHeaderProps) => {
  return (
    <Stack alignItems="center" direction="row" spacing="12px">
      <Stack
        sx={theme => ({
          position: 'relative',
          zIndex: 0,
          borderRadius: theme.borderRadius.md,
        })}
      >
        <FrameLogo image={logo} frameImage={frame?.image} frameText={frame?.text} />
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Text variant="h3" sx={{ color: 'neutral150' }}>
          {teamName ? <LinesEllipsis text={teamName} maxLine={2} ellipsis="..." /> : null}
        </Text>
        <Text variant="subtitle" sx={{ color: 'neutral300', mt: '4px' }}>
          {teamSchool ? <LinesEllipsis text={teamSchool} maxLine={2} ellipsis="..." /> : null}
        </Text>
        <Stack alignItems="center" direction="row" sx={{ mt: '4px' }}>
          {teamLocation ? (
            <>
              <Avatar
                src={IconSource('ic-location')}
                sx={{
                  width: '10px',
                  height: '10px',
                  flexShrink: 0,
                  mr: '2px',
                }}
              />
              <Text variant="subtitle" sx={{ color: 'neutral300' }}>
                {teamLocation}
              </Text>
            </>
          ) : null}
        </Stack>
        {teamName && cta && <Box sx={{ mt: '6px' }}>{cta}</Box>}
      </Box>
    </Stack>
  );
};
