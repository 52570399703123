import { Stack } from '@mui/material';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardHeaderAccount } from 'design/Cards/CardHeaderAccount';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import { onClickShare } from 'modules/helpers';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const HeaderAccount = () => {
  const { t } = useTranslation();

  const { playerInfo, tournamentParticipantAwardsFrame } = useAppSelector(({ account, award }) => ({
    playerInfo: account?.data,
    tournamentParticipantAwardsFrame: award?.tournamentParticipantAwardsFrame,
  }));

  const handlePlayerFrame = useMemo(() => {
    const dataPlayerMvp = (tournamentParticipantAwardsFrame?.awards ?? []).find(
      data => data?.teamId == undefined,
    );
    const dataPlayerAwards = (tournamentParticipantAwardsFrame?.awards ?? []).find(
      data => data?.teamId === (playerInfo?.games && playerInfo?.games[0]?.team?.id),
    );

    const isPlayerGetMvp = playerInfo?.id
      ? dataPlayerMvp?.playerIds?.includes(playerInfo?.id)
      : false;
    const isPlayerGetAward = playerInfo?.id
      ? dataPlayerAwards?.playerIds?.includes(playerInfo?.id)
      : false;

    if (isPlayerGetMvp || isPlayerGetAward) {
      return {
        image: isPlayerGetMvp ? dataPlayerMvp?.playerFrameUrl : dataPlayerAwards?.playerFrameUrl,
        text: isPlayerGetMvp
          ? dataPlayerMvp?.frameTitle.toLocaleUpperCase()
          : dataPlayerAwards?.frameTitle.toLocaleUpperCase(),
      };
    }

    return undefined;
  }, [playerInfo?.id, playerInfo?.games, tournamentParticipantAwardsFrame]);

  const handleShare = () => {
    return onClickShare({
      title: t('tournament_page_title', { title: 'Profile Saya' }),
      url: `${window.location.origin}/player/${playerInfo?.userName}`,
    });
  };

  return (
    <CardHeaderAccount
      photo={playerInfo?.picture ?? ''}
      fullname={[playerInfo?.firstName ?? '', playerInfo?.lastName ?? ''].join(' ')}
      description={[playerInfo?.school?.name ?? '', playerInfo?.school?.province ?? '']
        .join(', ')
        .replace(/^,|,\s*$/, '')}
      frame={handlePlayerFrame}
      cta={
        <Stack direction="row" spacing="7px" sx={{ width: '100%' }}>
          <Link to="/account/edit" style={{ flex: '50%' }}>
            <SecondaryButton variant="small" wide sx={{ whiteSpace: 'nowrap' }}>
              <img src={IconSource('ic-edit')} alt="edit button" width={16} />
              <Text variant="button" sx={{ ml: '4px' }}>
                Edit Profil
              </Text>
            </SecondaryButton>
          </Link>

          <SecondaryButton
            variant="small"
            wide
            sx={{ flex: '50%', whiteSpace: 'nowrap' }}
            onClick={handleShare}
          >
            <img src={IconSource('ic-share-social-white')} alt="edit button" width={16} />
            <Text variant="button" sx={{ ml: '4px' }}>
              Bagikan Profil
            </Text>
          </SecondaryButton>
        </Stack>
      }
    />
  );
};

export default memo(HeaderAccount);
