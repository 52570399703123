import LogoutModal from './modal/LogoutModal';
import ScrimHistoryAccount from './navigation/scrimHistory';
import TournamentHistoryAccount from './navigation/tournamentHistory';
import { Avatar, Stack as MUIStack } from '@mui/material';
import CardInfoGame from 'components/CardInfoGame';
import HeaderAccount from 'components/cards/Account/HeaderAccount';
import InfoAccountItemCard from 'components/cards/InfoAccountItemCard/InfoAccountItemCard';
import { TabButtonType } from 'components/layouts/TabButton';
import TabMenu from 'components/layouts/TabMenu';
import { Box, Stack } from 'components/styled/layout.styled';
import { GradientText, NormalText } from 'components/styled/text.styled';
import { ContainerToast } from 'components/styled/toast.styled';
import { LayoutContext } from 'context/LayoutContext';
import copy from 'copy-to-clipboard';
import navigationMenuAccount from 'data/navigationMenuAccount.json';
import { Modal } from 'design/Modal';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource, ImageSource } from 'modules/assetpath';
import { onClickShare } from 'modules/helpers';
import history from 'modules/history';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchPlayerScrimHistory, fetchPlayerProfile } from 'redux/account/AccountServices';
import { logoutAction } from 'redux/auth/AuthReducer';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import AuthService from 'services/AuthService';

const AccountPage = () => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const dispatch = useAppThunkDispatch();

  const [getTabAccount, setTabAccount] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const { playerInfo, scrimHistoryList } = useAppSelector(({ account }) => ({
    playerInfo: account.data,
    scrimHistoryList: account.playerScrimHisory,
  }));

  var forwardIcon = IconSource('ic-caret-forward-small-white');

  const handleCopyGameId = (playerId: string) => {
    Promise.all([copy(playerId)]).then(() => {
      return setOpenToast(true);
    });
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const DataTab: TabButtonType[] = [
    {
      id: 1,
      title: t('player_explore_tournament_tab'),
      isSelected: true,
    },
    {
      id: 2,
      title: t('player_explore_scrim_tab'),
      isSelected: false,
    },
  ];

  const handleFetchPlayerProfile = (username: string) => {
    dispatch(
      fetchPlayerProfile({
        username: username,
      }),
    );
  };

  const handleFetchPlayerScrimHistory = () => {
    if (playerInfo) {
      dispatch(
        fetchPlayerScrimHistory({
          playerSlug: playerInfo?.userName,
          pageIndex: 1,
          pageSize: 20,
        }),
      );
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (playerInfo) {
        handleFetchPlayerProfile(playerInfo?.userName);
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      handleFetchPlayerScrimHistory();
      if (playerInfo && !playerInfo?.school) {
        history.push('/complete-data');
      }
    }
  }, [playerInfo]);

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Profile Saya' }),
      background: {
        image: ImageSource('app.background', 'png'),
        opacity: '0.02',
        mask: 'linear-gradient(359.1deg, #131318 0.29%, rgba(19, 19, 24, 0.97299) 32.28%, rgba(19, 19, 24, 0.934402) 43.9%, rgba(19, 19, 24, 0.89284) 56.38%, rgba(19, 19, 24, 0.02) 80.4%)',
      },
      appBar: {
        logo: true,
        menu: navigationMenuAccount,
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: t('tournament_page_title', { title: 'Profile Saya' }),
              url: `${window.location.origin}/player/${playerInfo?.userName}`,
              handleIconShare: () => handleShare(),
            });
          }
        },
      },
      padding: '0',
      bottomBar: {
        show: true,
      },
      additional: (
        <Toast show={openToast} onClose={handleCloseToast}>
          <Ribbon
            icon={IconSource('check-gameon')}
            label={
              <Text variant="body" sx={{ color: 'success900' }}>
                {t('player_player_profile_information_game_id')}
              </Text>
            }
            sx={GameOnToastStyle}
          />
        </Toast>
      ),
    });
  }, [playerInfo, openToast]);

  return (
    <>
      <Box width="100%">
        <Stack direction="column" align="center" disableDrag disableSelectText>
          <HeaderAccount
            personName={playerInfo?.firstName?.concat(' ' + playerInfo?.lastName ?? '') ?? ''}
            schoolName={playerInfo?.school?.name}
            avatarUrl={playerInfo?.picture}
            cityName={playerInfo?.city?.name}
            generation={playerInfo?.generation?.toString()}
          />
          <Box width="100%">
            <Stack direction="column" align="start">
              <NormalText
                padding="8px 16px 0px"
                fontStyle="normal"
                fontSize="14px"
                fontWeight="700"
                lineHeight="19.6px"
                color="white"
              >
                {t('player_player_profile_match_history')}
              </NormalText>
              <TabMenu
                data={DataTab}
                variant="left"
                shadowColor="none"
                backgroundColor="transparent"
                iconRight={IconSource('ic-caret-forward-small-white')}
                onSelected={tab => {
                  switch (tab.id) {
                    case 1:
                      return setTabAccount(false);
                    case 2:
                      return setTabAccount(true);
                    default:
                      return undefined;
                  }
                }}
              />
              <Stack direction="column" position="relative" margin="0px 0px 24px">
                {!getTabAccount ? (
                  <TournamentHistoryAccount />
                ) : (
                  <ScrimHistoryAccount scrimHistoryList={scrimHistoryList?.items} />
                )}
              </Stack>
              <NormalText
                padding="8px 16px 12px"
                fontStyle="normal"
                fontSize="14px"
                fontWeight="700"
                color="white"
                lineHeight="19.6px"
              >
                {t('player_account_account_info_title')}
              </NormalText>
              <InfoAccountItemCard
                keyName={t('player_account_account_info_email')}
                value={playerInfo?.email ?? '-'}
                iconRight={playerInfo?.emailConfirmed ? IconSource('check-account') : undefined}
              />
              <InfoAccountItemCard
                keyName={t('player_account_account_info_phone number')}
                value={playerInfo?.phoneNumber ?? '-'}
                iconRight={
                  playerInfo?.phoneNumberConfirmed ? IconSource('check-account') : undefined
                }
              />
              <InfoAccountItemCard
                keyName={t('player_account_account_info_student_id')}
                value={playerInfo?.studentIdNumber ?? '-'}
              />
              <InfoAccountItemCard
                keyName={t('player_account_account_info_student_card')}
                imageContent={playerInfo?.studentCardFile ?? undefined}
                value={playerInfo?.studentCardFile ? undefined : '-'}
              />
              <div
                style={{
                  marginTop: '26px',
                  width: '100%',
                }}
              >
                <MUIStack direction="row" spacing={2} justifyContent="space-between">
                  <NormalText
                    padding="8px 16px 12px"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="700"
                    color="white"
                    lineHeight="19.6px"
                  >
                    {t('player_player_profile_information_game')}
                  </NormalText>
                  <MUIStack direction="row" justifyContent="space-between" sx={{ p: '5px' }}>
                    <a href="/account/edit-game">
                      <GradientText
                        padding="8px 16px 12px"
                        fontStyle="normal"
                        fontSize="12px"
                        fontWeight="700"
                        lineHeight="19.6px"
                        textAlign="right"
                        margin="9px"
                        color="#F3AA36"
                      >
                        Edit
                      </GradientText>
                      <Avatar
                        src={IconSource('btn_right_orange')}
                        sx={{
                          width: '6px',
                          height: '9.75px',
                          float: 'right',
                          mr: '21px',
                          mt: '5px',
                        }}
                      />
                    </a>
                  </MUIStack>
                </MUIStack>
                {playerInfo?.games ? (
                  <>
                    <ContainerToast
                      theme="dark"
                      autoClose={3000}
                      hideProgressBar
                      newestOnTop
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable={false}
                      pauseOnHover
                    />
                    {playerInfo?.games?.map(it => {
                      return (
                        <CardInfoGame
                          key={it.game.id}
                          {...{
                            src: IconSource('ml-logo'),
                            gameId: t('player_account_game_info_game_id_label', {
                              gameId: it.gameProviderId,
                            }),
                            label: it.nickName,
                            copyText: t('player_player_profile_information_game_copy'),
                            onClick: () => handleCopyGameId(it.gameProviderId ?? ''),
                          }}
                        />
                      );
                    })}{' '}
                  </>
                ) : (
                  <div
                    style={{
                      padding: '10px',
                      height: '80px',
                      top: '60px',
                      background: 'rgba(55, 57, 66, 0.6)',
                      borderRadius: '16px',
                      textAlign: 'center',
                      margin: '5px 16px',
                    }}
                  >
                    <NormalText
                      margin="6px 8px 16px"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="700px"
                      color="#969393"
                    >
                      {t('player_account_game_info_empty')}
                    </NormalText>
                    <GradientText
                      fontSize="14px"
                      lineHeight="140%"
                      fontWeight="bold"
                      fontStyle="normal"
                      position="relative"
                      textAlign="center"
                      isPressable
                      color="#F3AA36"
                      onClick={() => {
                        history.push('/account/add-game');
                      }}
                    >
                      {t('player_account_game_info_add')}
                    </GradientText>
                  </div>
                )}
              </div>
              <Stack direction="column" margin="26px auto 42px auto" width="92%">
                <NormalText
                  padding="8px 0px 16px 0px"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="700"
                  color="white"
                  lineHeight="19.6px"
                >
                  {t('player_account_other_link_title')}
                </NormalText>
                <Box
                  background="rgba(55, 57, 66, 0.6)"
                  padding="16px 0px 10px 0px"
                  borderRadius="16px"
                  display="inline-block"
                >
                  <InfoAccountItemCard
                    backgroundColor="#242731"
                    margin={`5px 16px 10px 16px`}
                    padding="14px 16px 14px 16px"
                    {...{
                      value: t('player_account_other_link_change_pass'),
                      onClick: () => history.push('/change-password'),
                      iconRight: forwardIcon,
                    }}
                  />
                  {/* <InfoAccountItemCard
                    backgroundColor="#242731"
                    margin={`5px 16px 10px 16px`}
                    padding="14px 16px 14px 16px"
                    {...{
                      value: t('player_account_other_link_contact_us'),
                      onClick: () => {
                        window.open("mailto:hi@mabar.com", "_blank")
                        //history.push('/contact-us/guest');
                      },
                      iconRight: forwardIcon,
                    }}
                  /> */}
                  <InfoAccountItemCard
                    backgroundColor="#242731"
                    margin={`5px 16px 10px 16px`}
                    padding="14px 16px 14px 16px"
                    {...{
                      value: t('player_account_other_link_faq'),
                      onClick: () => {
                        history.push('/faq');
                      },
                      iconRight: forwardIcon,
                    }}
                  />
                  <InfoAccountItemCard
                    backgroundColor="#242731"
                    margin={`5px 16px 10px 16px`}
                    padding="14px 16px 14px 16px"
                    {...{
                      value: t('player_account_other_link_tos'),
                      onClick: () => {
                        history.push('/term');
                      },
                      iconRight: forwardIcon,
                    }}
                  />
                  <InfoAccountItemCard
                    backgroundColor="#242731"
                    margin={`5px 16px 10px 16px`}
                    padding="14px 16px 14px 16px"
                    {...{
                      value: t('player_account_other_link_privacy'),
                      onClick: () => {
                        history.push('/privacy');
                      },
                      iconRight: forwardIcon,
                    }}
                  />
                  <InfoAccountItemCard
                    backgroundColor="#242731"
                    margin={`5px 16px 10px 16px`}
                    padding="14px 16px 14px 16px"
                    {...{
                      value: t('player_account_other_link_logout'),
                      onClick: () => {
                        setOpenLogoutModal(!openLogoutModal);
                      },
                      iconRight: forwardIcon,
                    }}
                  />
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Modal show={openLogoutModal}>
        <LogoutModal
          onCancelClick={() => setOpenLogoutModal(!openLogoutModal)}
          onConfirmClick={() => {
            dispatch(logoutAction());
          }}
        />
      </Modal>
    </>
  );
};

export default AccountPage;
