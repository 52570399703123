import { CardThumbnailWrapper, ImgThumbnail } from './styles';
import { Box, Stack, Avatar } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface GameCardProps {
  gameLogo?: string;
  gameBg: string;
  gameName: string;
  teamLogo?: string;
  teamName?: string;
  isWaiting?: boolean;
  onClick?: (data?: any) => void;
  bgOverlay?: boolean;
  avatarBorder?: boolean;
}

export const GameCard = ({
  gameLogo,
  gameBg,
  gameName,
  teamLogo,
  teamName,
  isWaiting = false,
  onClick,
  bgOverlay = false,
  avatarBorder = false,
}: GameCardProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={theme => ({
        width: '100%',
        height: '166px',
        borderRadius: theme.borderRadius.md,
        border: `1px solid ${theme.palette.neutral550}`,
        mb: '16px',
        position: 'relative',
      })}
      onClick={onClick}
    >
      <CardThumbnailWrapper bgOverlay={bgOverlay}>
        <ImgThumbnail src={gameBg} />
      </CardThumbnailWrapper>
      <Stack direction="row" alignItems="center" sx={{ padding: '64px 12px 12px 12px' }}>
        <Avatar
          variant="rounded"
          sx={{
            width: '90px',
            height: '90px',
            borderRadius: '8px',
            bgcolor: teamLogo ? 'white' : 'neutral900',
            img: {
              objectFit: teamLogo ? 'cover' : 'contain',
            },
            mr: '12px',
            border: avatarBorder ? '2px solid #F1EBEB' : '0px',
          }}
          aria-label="logo-team"
          src={teamLogo ?? gameLogo}
        />
        <Box sx={{ width: '100%' }}>
          {teamName ? (
            <>
              <Text variant="label" sx={{ color: 'neutral300' }}>
                {gameName}
              </Text>
              <Text variant="h3" sx={{ color: 'neutral200', mt: '4px' }}>
                {teamName}
              </Text>
            </>
          ) : (
            <>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Text variant="label" sx={{ color: 'neutral300', flex: 1 }}>
                  {gameName}
                </Text>
                <PrimaryButton variant="small">{t('tim_saya_cari_team')}</PrimaryButton>
              </Stack>
            </>
          )}
          {isWaiting && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                borderTop: '1px dashed #323437',
                mt: '16px',
                pt: '12px',
              }}
            >
              <img src={IconSource('ic-ribbon-timer')} width="14px" height="14px" />
              <Text variant="button" sx={{ color: 'primary500', lineHeight: '16px', ml: '4.5px' }}>
                {t('tim_saya_waiting_confirmation')}
              </Text>
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
