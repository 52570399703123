import AnggotaDrawer from '../Drawer/AnggotaDrawer';
import { Box } from '@mui/material';
import { TextButton } from 'design/Buttons/TextButton';
import { Player } from 'design/Cards/Player';
import { IconSource } from 'modules/assetpath';
import React, { memo, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { TeamMember } from 'redux/teamProfile/TeamProfileResponse';

export interface playerDetailDrawer {
  playerName: string;
  playerPhoto: string;
  gameId: string;
  nickname: string;
  urlslug: string;
}

interface AnggotaProps {
  setOpenToast: (value: boolean) => void;
}

const Anggota = ({ setOpenToast }: AnggotaProps) => {
  const { teamMember } = useAppSelector(({ teamProfile }) => ({
    teamMember: teamProfile?.teamMember,
  }));

  const [playerDetail, setPlayerDetail] = useState<playerDetailDrawer>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const teamMemberMemo = useMemo(
    () => ((teamMember?.items ?? []).length > 1 ? teamMember?.items : null),
    [(teamMember?.items ?? []).length],
  );

  const handleOpenPlayerDetail = (member: TeamMember) => {
    setIsDrawerOpen(true);
    setPlayerDetail({
      playerName: member?.player?.name,
      playerPhoto: member?.player?.picture ?? '',
      gameId: member?.player?.gameProviderId,
      nickname: member?.player?.nickName,
      urlslug: member?.player?.urlSlug,
    });
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <Box
        sx={theme => ({
          bgcolor: teamMemberMemo ? 'neutral700' : 'transparent',
          border: teamMemberMemo ? `1px solid ${theme.palette.neutral600}` : 'unset',
          borderRadius: theme.borderRadius.md,
        })}
      >
        {teamMemberMemo
          ? teamMemberMemo
              .slice()
              .sort((a, b) => (a.joinAt > b.joinAt ? 1 : -1))
              .sort((a, _) => (a.level.value === 'Captain' ? -1 : 1))
              .map(player =>
                player.status.value === 'Approved' ? (
                  <Box
                    key={player?.player?.id}
                    onClick={() => handleOpenPlayerDetail(player)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Player
                      playerName={player?.player?.name}
                      playerInfo={`${player?.player?.nickName} | ${player?.player?.gameProviderId}`}
                      playerPhoto={player?.player?.picture ?? ''}
                      isCaptain={player?.level?.value === 'Captain'}
                      cta={<TextButton icon={IconSource('ic-chevron-right')} />}
                      sx={{ mb: '0' }}
                    />
                  </Box>
                ) : null,
              )
          : null}
      </Box>

      <AnggotaDrawer
        isDrawerOpen={isDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        setOpenToast={setOpenToast}
        playerDetail={playerDetail}
      />
    </>
  );
};

export default memo(Anggota);
