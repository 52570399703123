import SEO from './SEO';
import { Box, CardMedia } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import { ScrollContext } from 'context/ScrollContext';
import { BottomBarProps } from 'design/BottomBar';
import { BottomBar } from 'design/BottomBar';
import { Header, HeaderProps } from 'design/Headers';
import React, { ReactNode, useContext, useMemo, useEffect, useState, useRef } from 'react';
import { use100vh } from 'react-div-100vh';
import { ParallaxProvider } from 'react-scroll-parallax';
import { setCurrentVersion } from 'redux/app/AppReducer';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { Constants } from 'services/Constants';
import theme from 'theme';

export interface LayoutProps {
  appBar?: HeaderProps & {
    show?: boolean;
  };
  bottomBar?: BottomBarProps & {
    show?: boolean;
    photo?: string;
  };
  background?: {
    color?: string;
    image?: string;
    mask?: string;
    opacity?: string;
    container?: string;
  };
  padding?: string;
  pageTitle?: string;
  children?: ReactNode;
  show?: boolean;
  additional?: any;
  'data-testid'?: string;
}

const Layout = (props: LayoutProps) => {
  const dispatch = useAppThunkDispatch();

  const height = use100vh();
  const { value } = useContext(LayoutContext);
  const { setValue } = useContext(ScrollContext);
  const [backgroundLayout, setBackgroundLayout] = useState('');
  const listInnerRef = useRef<any>(null);

  const { currentVersion } = useAppSelector(({ app }) => ({
    currentVersion: app.currentVersion,
  }));

  const handleSetCurrentVersion = (currentVersion: string) => {
    dispatch(setCurrentVersion({ currentVersion: currentVersion }));
  };

  const latestVersion = Constants.appVersion;

  useEffect(() => {
    if (currentVersion !== latestVersion) {
      handleSetCurrentVersion(latestVersion);
    }
  }, [currentVersion]);

  const handleScrollEnd = useMemo(
    () =>
      setValue({
        scrollRef: listInnerRef,
      }),
    [],
  );

  useEffect(() => {
    handleScrollEnd;
  }, []);

  useEffect(() => {
    if (value.background.image) {
      Promise.all([setBackgroundLayout('')]).then(() =>
        setBackgroundLayout(value.background.image),
      );
    }
  }, [value.background.image]);

  const heightContentForAppBar = value.appBar.show ? theme.headerHeight : 0;
  const heightContentForBottomBar = value.bottomBar.show ? theme.bottomBarHeight : 0;

  const scrollEl = listInnerRef.current;

  return value.show ? (
    <Box
      id="scrollContainer"
      data-testid={props['data-testid']}
      sx={{ overflow: 'auto', height: height }}
      ref={listInnerRef}
    >
      <ParallaxProvider scrollContainer={scrollEl}>
        <Box sx={{ maxWidth: '500px', margin: '0 auto', background: value.background.container }}>
          <SEO title={value.pageTitle} />

          {value.appBar.show && (
            <Header
              logo={value.appBar.logo}
              back={value.appBar.back}
              title={value.appBar.title}
              notification={value.appBar.notification}
              share={value.appBar.share}
              menu={value.appBar.menu}
              setting={value.appBar.setting}
              separator={value.appBar.separator}
              background={value.appBar.background}
              sponsor={value.appBar.sponsor}
            />
          )}

          {backgroundLayout && (
            <CardMedia
              image={backgroundLayout}
              component="img"
              sx={theme => ({
                position: 'fixed',
                top: theme.headerHeight,
                maxWidth: '500px',
                width: '100%',
                opacity: value.background.opacity,
                objectFit: 'cover',
                mask: value.background.mask,
                height: height! - (heightContentForAppBar + heightContentForBottomBar + 2),
                display: value.background.image ? '' : 'none',
              })}
            />
          )}

          <Box
            sx={{
              position: 'relative',
              p: value.padding ?? '16px',
              boxShadow: '0px 0px 0px -2px rgb(0 0 0 / 75%)',
              bgcolor: value.background.color,
              backfaceVisibility: 'hidden',
              transformStyle: 'preserve-3d',
              transform: 'translate3d(0,0,0)',
              minHeight: height! - (heightContentForAppBar + heightContentForBottomBar + 2),
            }}
          >
            {props.children && <Box id="appcontent">{props.children}</Box>}
          </Box>

          {value.bottomBar.show && (
            <BottomBar photo={value.bottomBar.photo} data={value.bottomBar.data} />
          )}

          {value.additional}
        </Box>
      </ParallaxProvider>
    </Box>
  ) : (
    <div>{props.children}</div>
  );
};

export default Layout;
