const FAQContent = () => {
  return `
## Umum

### 1. Anggota tim boleh dari sekolah yang berbeda gak?


> Tidak bisa. Peserta MABAR adalah mewakili sekolah. Tim harus berisikan
pemain dari murid satu sekolah

### 2. Saya bukan pelajar SMA, SMK, atau sederajat boleh ikut MABAR gak?


> Saat ini, tidak bisa.

### 3. Program MABAR gratis gak sih semuanya?


> Saat ini, platform MABAR adalah gratis. Apabila terdapat pihak yang menarik
biaya dari platform ini, mohon informasikan ke email hi@mabar.com.

### 4. Apa itu Season?


> Season adalah sistem pembagian musim turnamen di MABAR yang mengikuti
kalender akademis sekolah, yaitu dimulai di Agustus dan berakhir di Juli.

> Dalam satu season, turnamen utama MABAR diadakan sebanyak dua kali (split).
Yaitu: Fall split (Agustus-Januari) dan Spring split (Februari-Juli). Artinya, setiap
season, tiap orang berkesempatan mengikuti turnamen dua kali.


## Game & Match

### 1. Bagaimana jika tim sudah check in untuk bertanding, namun lawan tidak hadir di in-game?

> Pertandingan akan dianggap tidak valid jika tidak ada hasil pertandingan yang
disubmit. MABAR menjunjung tinggi komitmen dalam setiap pertandingan dan
berharap seluruh tim peserta dapat memberi respek kepada lawannya dengan
menjalankan pertandingan sesuai jadwal.

> Dalam situasi tim lawan tidak hadir, tim yang hadir dapat melaporkan lawannya
dan akan menang WO. Tim yang kalah WO akan ditindaklanjuti oleh MABAR
sesuai peraturan yang berlaku pada masa tersebut.

> Peserta dapat mengemail kami di <hi@mabar.com>.


### 2. Bagaimana cara mengubah game nickname?


> Nickname tidak dapat diubah. Perhatikan pilihan nickname sejak awal membuat
>akun. Apabila terpaksa harus mengganti nickname, silakan email kami di
><hi@mabar.com>.

### 3. Kenapa peserta harus melakukan screenshot atas pertandingan dan mengupload ke

### platform?


> Screenshot pertandingan dan menguploadnya ke platform adalah wajib.
>Screenshot menjadi bukti pertandingan sudah berlangsung dan menjadi dasar
>catatan kemenangan/kekalahan peserta. MABAR juga mengambil catatan
>statistik dari screenshot ini, untuk dapat menjadi data penting pengguna.

### 4. Seberapa lama batas waktu yang diberikan untuk mengupload screenshot? Dan apa

### yang terjadi kalau peserta tidak mengupload screenshot?


> Peserta harus mengupload screenshot selambat-lambatnya 2 jam setelah jadwal
>tanding selesai. Tanpa adanya screenshot, pertandingan belum dianggap
>selesai.

### 5. Apa yang harus kami lakukan apabila kami melihat ada peserta yang curang atau

### melanggar aturan MABAR?


> MABAR menjunjung tinggi sportivitas dan kejujuran. Tim atau pemain yang
curang akan mendapatkan sanksi mulai dari teguran hingga dikeluarkan dari
platform sampai jangka waktu tidak terbatas. Peserta dapat melaporkan dugaan
kecurangan kepada MABAR melalui hi@mabar.com dengan menyertakan bukti-
bukti yang kuat. Kami akan merespons selambat-lambatnya dalam 2x24 jam.

> MABAR memiliki tim khusus yang bertugas meneliti dugaan kecurangan dan
memberikan sanksi yang sesuai. MABAR memiliki kekuasaan penuh dalam
menilai dugaan kecurangan dengan seadil-adilnya. Keputusan MABAR tidak
dapat diganggu gugat.

    `;
};

export default FAQContent;
