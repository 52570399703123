import {
  HeaderBackgroundProps,
  HeaderNavigationIconProps,
  HeaderNavigationRightProps,
} from './Header';
import { AppBar } from '@mui/material';
import { styled } from '@mui/system';

export const HeaderStyled = styled(AppBar)(({ theme, separator }: any) => ({
  position: 'sticky',
  top: 0,
  boxShadow: 'none',
  backgroundImage: 'none',
  background: theme.palette.neutral700,
  justifyContent: 'center',
  height: theme.headerHeight,
  padding: '0 8px 0 14px !important',
  zIndex: 10,
  borderBottom: separator ? `1px solid ${theme.palette.neutral530}` : '0',
  '.MuiToolbar-regular': {
    minHeight: 'auto',
  },
  '.MuiIconButton-root': {
    '&:hover': {
      background: 'transparent',
    },
  },
}));

export const HeaderLayout = styled('nav', { name: 'headerLayout' })<HeaderBackgroundProps>(
  ({ theme, pos, bRadius, height, background, boxShadow, isDisable }: any) => ({
    position: pos ?? 'fixed',
    zIndex: 99,
    top: '0%',
    bottom: '0%',
    transition: '0.4s',
    display: 'flex',
    overflowY: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    borderRadius: bRadius ?? '0px',
    width: '100%',
    height: height ?? '56px',
    background: theme.palette[background] || background,
    boxShadow: boxShadow ?? 'none',
    [isDisable]: {
      opacity: 0.7,
      cursor: 'not-allowed',
    },
    // @media only screen and (min-width: 500px)
    [theme.breakpoints.up('sm')]: {
      maxWidth: '500px',
    },
  }),
);

const isDisableNavLeft: any = () => ({
  true: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  false: {
    'ic-arrow-back-light': {
      '&:focus': {
        opacity: 0.7,
        backgroundImage: `url('${process.env.PUBLIC_URL}/media/icons/ic-arrow-back-gradient.svg')`,
      },
      ' &:active': {
        opacity: 0.7,
        backgroundImage: `url('${process.env.PUBLIC_URL}/media/icons/ic-arrow-back-gradient.svg')`,
      },
    },
  },
});
export const HeaderNavLeft = styled('a', { name: 'navLeft' })<HeaderNavigationIconProps>(
  ({ width, height, bg, isDisable }: any) => ({
    float: 'left',
    margin: '16px',
    width: width ?? '24px',
    height: height ?? '24px',
    cursor: 'pointer',
    backgroundImage:
      `url('${process.env.PUBLIC_URL}/media/icons/${bg}.svg')` ??
      `url('${process.env.PUBLIC_URL}/media/icons/ic-arrow-back-light.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    touchCallout: 'none',
    userSelect: 'none',
    // isDisable === false && bg === 'ic-arrow-back-light'
    ...isDisableNavLeft()[isDisable][bg],
  }),
);

export const HeaderTitleCenter = styled('a', { name: 'navCenter' })<any>(({ theme }: any) => ({
  ...theme.typography.h3,
  fontWeight: 700,
  letterSpacing: '0.12px',
  display: 'block',
  color: 'white',
  flexGrow: 1,
  overflow: 'hidden',
  textAlign: 'center',
  textOverflow: 'ellipsis',
  lineHeight: '140%',
  whiteSpace: 'nowrap',
  touchCallout: 'none',
  userSelect: 'none',
}));

const variantNavRight: any = ({ isDisable, theme, bg }: any) => ({
  button: {
    width: 'auto',
    height: 'auto',
    marginRight: '16px',
    fontStyle: 'normal',
    textAlign: 'right',
    fontSize: '13px',
    fontWeight: 'bold',
    cursor: isDisable ? 'not-allowed' : 'pointer',
    background: isDisable
      ? theme.palette.black03
      : bg
      ? theme.palette[bg] || bg
      : theme.palette.black03,
    backgroundClip: 'text',
    textFillColor: 'transparent',
    [`${!isDisable}`]: {
      '&:focus': {
        opacity: 0.7,
      },
      '&:active': {
        opacity: 0.7,
      },
      '&:focus-within': {
        opacity: 0.7,
      },
    },
  },
});
const isDisableNavRight: any = ({ variant }: any) => ({
  true: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  false: {
    '&:focus-within': {
      opacity: 0.7,
      backgroundImage:
        variant !== 'button' &&
        `url('${process.env.PUBLIC_URL}/media/icons/ic-${variant}-gradient.svg')`,
    },
    '&:focus': {
      opacity: 0.7,
      backgroundImage:
        variant !== 'button' &&
        `url('${process.env.PUBLIC_URL}/media/icons/ic-${variant}-gradient.svg')`,
    },
    '&:active': {
      opacity: 0.7,
      backgroundImage:
        variant !== 'button' &&
        `url('${process.env.PUBLIC_URL}/media/icons/ic-${variant}-gradient.svg')`,
    },
  },
});
export const HeaderNavRight = styled('a', {
  name: 'navRight',
})<HeaderNavigationRightProps>(({ theme, width, height, variant, isDisable, bg }: any) => ({
  width: width ?? '24px',
  height: height ?? '24px',
  margin: '16px',
  cursor: 'pointer',

  // variant !== 'button'
  backgroundImage:
    `url('${process.env.PUBLIC_URL}/media/icons/ic-${`${variant}-${bg}`}.svg')` ??
    `url('${process.env.PUBLIC_URL}/media/icons/ic-${variant}-white.svg')`,
  backgroundRepeat: 'no-repeat',
  touchCallout: 'none',
  userSelect: 'none',

  ...variantNavRight({ theme, isDisable, bg })[variant],
  ...isDisableNavRight({ variant })[isDisable],
}));
