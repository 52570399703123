import { Theme, Box, Stack, Avatar } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { LOGO_VARIANT } from 'dictionaries/frame';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface FrameLogoProps {
  image?: string;
  frameImage?: string;
  frameText?: string | ReactNode;
  variant?: '1st' | '2nd' | '3rd' | 'favorite' | 'crown';
  sx?: SxProps<Theme>;
}

export interface avatarStyledProps {
  isSrc: boolean;
  frameImage?: string;
  theme: Theme;
}

const AvatarStyled: any = styled(Avatar, {
  name: 'logo',
})(({ isSrc, frameImage, theme }: avatarStyledProps) => ({
  overflow: 'inherit',
  width: frameImage ? '90px' : '86.8px',
  height: frameImage ? '102px' : '86.8px',
  background: frameImage ? `url(${frameImage}) no-repeat top/contain` : 'none',
  backgroundColor: isSrc && !frameImage ? theme.palette.gray02 : 'transparent',
  borderRadius: theme.borderRadius.md,
  border: isSrc && !frameImage ? `2px solid ${theme.palette.gray02}` : 0,
  boxShadow: isSrc ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)',
  img: {
    width: frameImage ? '76px' : '100%',
    height: frameImage ? '76px' : '100%',
    backgroundColor: 'white',
    marginTop: frameImage ? '5px' : 0,
    borderRadius: frameImage ? '2px' : '8px',
    zIndex: frameImage ? -1 : 0,
  },
  svg: {
    display: 'none',
  },
}));

export const FrameLogo = ({ image, frameImage, frameText, variant, sx }: FrameLogoProps) => {
  return (
    <Stack display="inline-flex" justifyContent="center" position="relative">
      {variant === LOGO_VARIANT.CROWN && (
        <Avatar
          src={IconSource('ic-crown-champion')}
          sx={{ m: '-7px auto -12px auto', zIndex: 1 }}
        />
      )}
      <AvatarStyled
        src={image ? image : IconSource('ic-default-team')}
        isSrc={Boolean(image)}
        alt="logo"
        variant="rounded"
        frameImage={frameImage}
        sx={sx}
      />
      {frameImage && variant !== LOGO_VARIANT.CROWN && (
        <Stack
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
          sx={theme => ({
            position: 'absolute',
            padding: '0 5px',
            bottom: '1px',
            minWidth: '24px',
            height: '11px',
            ':before': {
              content: "''",
              position: 'absolute',
              inset: 0,
              top: '-2px',
              background: 'white',
              borderRadius: '3px',
              transform: 'perspective(30px) rotateX(12deg)',
              borderTop: `1px solid ${theme.palette.primaryRed300}`,
              transformOrigin: 'top',
            },
          })}
        >
          <Text
            variant="labelSmall"
            sx={theme => ({
              position: 'relative',
              fontFamily: theme.fontFamily.neusaNextStd,
              fontWeight: 700,
              lineHeight: '8px',
              color: 'primaryRed700',
              letterSpacing: '0.4px',
              mt: '1px',
              zIndex: 1,
            })}
          >
            {frameText}
          </Text>
          <Box
            sx={{
              position: 'absolute',
              bottom: '-1px',
              left: '-1.5px',
              width: 'calc(100% + 2.5px)',
              height: '6px',
              bgcolor: '#CBCBCB',
              borderRadius: '200% 200% 10px 10px',
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
