import { Theme, Box, Avatar, Badge, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import { limitedChar } from 'modules/helpers';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

export interface PlayerProps {
  playerId?: string;
  playerName: string | ReactNode;
  playerInfo?: string | ReactNode;
  playerPhoto?: string;
  isCaptain?: boolean;
  isYou?: boolean;
  icon?: string;
  cta?: ReactNode;
  sx?: SxProps<Theme>;
  reverse?: boolean;
  onClick?: () => void;
  avatarBorder?: boolean;
  isActive?: boolean;
  smallText?: boolean;
  iconInfo?: boolean;
  avatarSize?: avatarSizeProps;
  inactive?: boolean;
  isDeclined?: boolean;
  textElipsis?: boolean;
  onClickContent?: () => void;
}

interface avatarSizeProps {
  width?: string;
  height?: string;
}

export const PlayerStyled: any = styled('div')<PlayerProps>({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px',
  marginBottom: '8px',
});

export const YouStyled: any = styled('div')<PlayerProps>({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2px 4px',
  height: '16px',
  background: '#242628',
  borderRadius: '4px',
  marginLeft: '6px',
});

export const Player = ({
  playerName,
  playerInfo,
  playerPhoto,
  isCaptain,
  isYou,
  icon,
  cta,
  sx,
  reverse = false,
  onClick,
  avatarBorder,
  isActive,
  iconInfo,
  smallText,
  avatarSize,
  inactive,
  isDeclined,
  textElipsis = true,
  onClickContent,
}: PlayerProps) => {
  const { t } = useTranslation();

  const widthMax480 = useMediaQuery('(max-width: 480px)');

  const handleBorderColorState = (theme: any) => {
    if (isActive) {
      return theme.palette.success500;
    } else if (inactive) {
      return theme.palette.neutral300;
    } else {
      return theme.palette.neutral500;
    }
  };
  const handleColorState = (theme: any) => {
    if (isActive) {
      return theme.palette.success500;
    } else if (inactive) {
      return theme.palette.neutral300;
    } else if (isDeclined) {
      return theme.palette.neutral400;
    } else {
      return theme.palette.neutral200;
    }
  };
  const handleBorderWidth = useMemo(() => {
    if (playerPhoto) {
      if (avatarBorder && (isActive || inactive)) {
        return '2px';
      }
      return '1px';
    }
    return 0;
  }, [playerPhoto, avatarBorder, isActive, inactive]);

  return (
    <PlayerStyled sx={sx} onClick={onClick}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: reverse ? 'row-reverse' : 'row',
        }}
      >
        <Stack
          direction={reverse ? 'row-reverse' : 'row'}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', minWidth: '0' }}
        >
          <Stack
            direction={reverse ? 'row-reverse' : 'row'}
            sx={{ width: '100%' }}
            onClick={onClickContent}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                isCaptain && (
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: '16px',
                      height: '16px',
                      mt: '-5px',
                      ml: '-4px',
                      borderRadius: '2.4px',
                    }}
                    alt="Captain"
                    src={IconSource('ic-captain')}
                  />
                )
              }
              sx={{ minWidth: '0', flexShrink: 0 }}
            >
              <Box
                sx={theme => ({
                  borderRadius: '100%',
                  borderWidth: handleBorderWidth,
                  borderColor: handleBorderColorState(theme),
                  borderStyle: 'solid',
                  boxSizing: 'border-box',
                  width: avatarSize?.width ?? '42px',
                  height: avatarSize?.height ?? '42px',
                  ...(isDeclined && {
                    border: 'none',
                  }),
                })}
              >
                <Avatar
                  sx={{
                    width: '100%',
                    height: '100%',
                    opacity: inactive || isDeclined ? '0.3' : '1',
                  }}
                  aria-label="player"
                  src={playerPhoto ? playerPhoto : IconSource('ic-default-photo')}
                />
              </Box>
            </Badge>
            <Stack
              direction="column"
              justifyContent="center"
              sx={{
                textAlign: reverse ? 'right' : 'left',
                minWidth: '0',
                overflow: 'hidden',
                p: '0 8px',
                width: '100%',
              }}
            >
              <Stack
                direction={reverse ? 'row-reverse' : 'row'}
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Text
                  variant="label"
                  component="span"
                  sx={theme => ({
                    fontWeight: '700',
                    color: handleColorState(theme),
                    ...(textElipsis && {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }),
                    fontSize: smallText ? '10px' : '14px',
                    lineHeight: smallText ? '16px' : '20px',
                  })}
                >
                  {widthMax480 ? limitedChar(playerName, 26) : limitedChar(playerName, 43)}
                </Text>
                {isYou && (
                  <YouStyled>
                    <Text
                      variant="ribbon"
                      sx={theme => ({ lineHeight: '16px', color: theme.palette.neutral400 })}
                    >
                      {t('player_card_kamu')}
                    </Text>
                  </YouStyled>
                )}
                {iconInfo && (
                  <Avatar
                    variant="square"
                    sx={{
                      width: '11px',
                      height: '11px',
                      m: '0 4px',
                    }}
                    src={IconSource('ic-info')}
                  />
                )}
              </Stack>
              <Text
                variant="bodySmall"
                component="span"
                sx={theme => ({
                  color: isDeclined ? theme.palette.neutral500 : theme.palette.neutral400,
                  mb: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  letterSpacing: '0.16px',
                  fontSize: smallText ? '10px' : '12px',
                  lineHeight: smallText ? '14px' : '20px',
                  display: 'block',
                  mt: playerInfo ? '4px' : '0',
                })}
              >
                {playerInfo}
              </Text>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {icon && <SVG src={icon} />}
            {cta}
          </Stack>
        </Stack>
      </Box>
    </PlayerStyled>
  );
};
