import { ScrimListResponse, ScrimTeamStatisticResponse } from './ScrimProps';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import ApiService from 'services/ApiService';
import { fetchApi } from 'services/setupService';

export const fetchScrimTeamStatistic = createAsyncThunk(
  'scrim/team/statistic',
  async ({ teamId }: { teamId: string }) => {
    const url = `scrim/team/${teamId}/statistic`;
    const res = await ApiService.get<ScrimTeamStatisticResponse>(url);
    return res.data;
  },
);

export const getScrimDetailService = async (p: { id: string }) => {
  const res = await fetchApi({
    method: 'GET',
    path: `scrim/${p.id}`,
  });
  return res;
};

export const getScrimPlayersService = async (p: { matchId: string; teamSlug: string }) => {
  const res = await fetchApi({
    method: 'GET',
    path: `scrim-player/${p.matchId}/${p.teamSlug}`,
  });
  return res;
};

export const getScrimListService = async (p: { pageIndex: number; pageSize: number }) => {
  const res = await fetchApi({
    method: 'GET',
    path: `scrim/explore/${p.pageIndex}/${p.pageSize}`,
  });
  return res;
};

export const createScrimService = async (p: {
  gameId: string;
  startAt: Dayjs;
  players: string[];
  secondTeamId?: string;
  scrimType: 'public' | 'challenge';
}) => {
  var url = '';
  var data = {};
  if (p.scrimType === 'public') {
    data = {
      gameId: p.gameId,
      startAt: p.startAt.toISOString(),
      players: p.players,
    };
    url = `scrim/open-invitation`;
  } else {
    data = {
      gameId: p.gameId,
      startAt: p.startAt.toISOString(),
      players: p.players,
      secondTeamId: p.secondTeamId,
    };
    url = `scrim/direct-invitation`;
  }
  data = JSON.stringify(data);
  const res = await ApiService.post(url, data, { headers: { 'Content-Type': 'application/json' } });
  return res;
};
export const getGameListService = async (p: { pageIndex: number; pageSize: number }) => {
  const res = await fetchApi({
    method: 'GET',
    path: `game/options/${p.pageIndex}/${p.pageSize}`,
  });
  return res;
};

export const getScrimDetailGameService = async (p: { matchId: string; round: number }) => {
  const res = await fetchApi({
    method: 'GET',
    path: `scrim-detail/mobile-legend/${p.matchId}/${p.round}`,
  });
  return res;
};

export const directApproval = async (p: { matchId: string; players: string[] }) => {
  const res = await fetchApi({
    method: 'PUT',
    path: `scrim/direct-approval/${p.matchId}`,
    data: {
      id: p.matchId,
      players: p.players,
    },
  });
  return res;
};

export const fetchScrimList = createAsyncThunk(
  'scrim/explore',
  async (p: { pageIndex: number; pageSize: number; gameSlug?: string; search?: string }) => {
    const url = `scrim/explore/${p.pageIndex}/${p.pageSize}${
      p.gameSlug ? '?gameUrlSlug=' + p.gameSlug : ''
    }${
      p.gameSlug && p.search
        ? '&search=' + encodeURI(p.search)
        : !p.gameSlug && p.search
        ? '?search=' + encodeURI(p.search)
        : ''
    }`;
    const res = await ApiService.get<ScrimListResponse>(url);
    return res;
  },
);

export const getScrimDetailById = createAsyncThunk(
  'scrimDetail/match-detail-by-id',
  async (p: { id: string }) => {
    const res = await ApiService.get(`scrim/${p.id}`);
    return res;
  },
);

export const getScrimMatchRound = createAsyncThunk(
  'scrimDetail/match-round-detail',
  async (p: { matchId: string; round: number }) => {
    const res = await ApiService.get(`scrim-detail/mobile-legend/${p.matchId}/${p.round}`);
    return res;
  },
);
