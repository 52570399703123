import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode, useRef, useState } from 'react';
import { FrameImage } from './styles';
import imageCompression from 'browser-image-compression';
import { RefCallBack } from 'react-hook-form';
import { FILE_SIZE_LIMIT, FILE_TYPE_ALLOWED } from 'store/constants/uploadImage';

interface IconOptionProps {
  background?: string;
  backgroundWidth?: string;
  backgroundHeight?: string;
  backgroundRadius?: string;
  iconSrc?: 'add-photo' | 'upload' | string;
  iconWidth?: string;
  iconHeight?: string;
  iconMargin?: string;
  iconPosition?: 'center' | 'end' | 'start' | 'between' | 'evenly' | undefined;
}

interface PlaceholderStyle {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  lineHeight?: string;
}
interface AddImageProps {
  variant: 'Box' | 'Circle';
  margin?: string;
  iconOptions?: IconOptionProps;
  width?: number;
  height?: number;
  widthMatchParent?: boolean;
  bRadius?: string;
  placeholder?: string;
  placeholderStyle?: PlaceholderStyle;
  bgColor?: string; // set background color
  defaultImage?: string; // set default Image
  imageAccept?: string; // image/*, image/png
  fileSizeLimit?: number; // defaut size MB
  isEffect?: boolean; // Add Frame Image
  iconVisible?: boolean;
  isRequired?: boolean;
  onImageLoad?: ((this: FileReader, ev: ProgressEvent<FileReader>) => any) | null;
  getImageBlob?: (value: Blob) => void;
  getImageSource?: (value: string) => void;
  onImageChange?: (value: Blob) => void;
  inputRef?: RefCallBack;
  descriptionText?: ReactNode;
  align?: 'start' | 'end' | 'center' | 'between' | 'evenly' | undefined;
  setSaveDisabled?: any;
}

function AddImage({
  variant,
  width = 166,
  height = 166,
  placeholder,
  placeholderStyle,
  bgColor = 'rgba(55, 57, 66, 0.94)',
  defaultImage,
  imageAccept = FILE_TYPE_ALLOWED,
  fileSizeLimit = FILE_SIZE_LIMIT,
  isEffect,
  margin,
  iconOptions = {
    background: 'rgba(55, 57, 66, 0.5)',
    backgroundRadius: variant === 'Box' ? '16px' : '50.25%',
    backgroundWidth: '100%',
    backgroundHeight: '100%',
    iconMargin: '0px',
    iconSrc: 'add-photo',
    iconPosition: 'center',
    iconHeight: '18px',
    iconWidth: '22.5px',
  },
  iconVisible = true,
  isRequired,
  onImageLoad,
  onImageChange,
  getImageBlob,
  getImageSource,
  widthMatchParent,
  inputRef,
  descriptionText,
  align,
  setSaveDisabled,
}: AddImageProps) {
  const inputFile = useRef<HTMLInputElement>(null);
  let [isCompressing, setIsCompressing] = useState(false);
  if (inputRef) inputRef(inputFile);
  const imgPreview = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);
  const fileTypeList = imageAccept?.replace(' ', '').toLowerCase().split(',');
  const [validationText, setValidationText] = useState(placeholder);
  const [validationTextColor, setValidationTextColor] = useState('#FBF7F7');
  const [getDefaultImage, setDefaultImage] = useState(defaultImage);
  const handleFileUpload = async (e: any) => {
    try {
      const { files } = e.target;
      if (files && files.length) {
        var fileSize = files[0].size / 1024 / 1024; // MB
        var fileType = files[0].name.substring(
          files[0].name.lastIndexOf('.') + 1,
          files[0].name.length,
        );

        if (fileTypeList?.includes(fileType.toLowerCase())) {
          if (fileSize <= fileSizeLimit) {
            imageCompression(files[0], {
              maxSizeMB: fileSizeLimit - 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
              fileType: 'image/png',
              initialQuality: 0.98,
              onProgress: () => {
                setIsCompressing(true);
              },
            })
              .then(compressedFile => {
                setIsCompressing(false);

                setValidationTextColor('#FBF7F7');
                if (onImageChange) {
                  onImageChange(compressedFile);
                }
                var imageReader = new FileReader();
                imageReader.readAsBinaryString(compressedFile);
                if (getImageBlob) {
                  getImageBlob(compressedFile);
                }
                if (onImageLoad) {
                  imageReader.onloadstart = onImageLoad;
                }

                imageReader.onloadend = function () {
                  imgPreview.current!!.style.display = 'flex';
                  bgRef.current!!.style.display = 'none';
                  setDefaultImage(URL.createObjectURL(compressedFile));
                  if (getImageSource) {
                    var iReader = new FileReader();
                    iReader.readAsDataURL(compressedFile);
                    iReader.onload = function (ev) {
                      getImageSource(ev.target?.result?.toString()!!);
                    };
                  }
                  setIsCompressing(false);
                };
                imageReader.onerror = function (ev) {
                  imgPreview.current!!.style.display = 'none';
                  bgRef.current!!.style.display = 'flex';
                  setValidationTextColor('#FF4D6D');
                  setValidationText(ev.target?.error?.message);
                };
                setSaveDisabled(false);
              })
              .catch((err: Error) => {
                setIsCompressing(false);
                if (err.message) {
                  imgPreview.current!!.style.display = 'none';
                  bgRef.current!!.style.display = 'flex';
                  setValidationTextColor('#FF4D6D');
                  setValidationText(err.message);
                }
              });
          } else {
            imgPreview.current!!.style.display = 'none';
            bgRef.current!!.style.display = 'flex';
            setValidationTextColor('#FF4D6D');
            setValidationText(`Ukuran Gambar tidak boleh melebihi ${fileSizeLimit}mb!`);
            setSaveDisabled(true);
          }
        } else {
          imgPreview.current!!.style.display = 'none';
          bgRef.current!!.style.display = 'flex';
          setValidationTextColor('#FF4D6D');
          setValidationText(`Hanya Ekstensi (${imageAccept}) yang diizinkan!`);
          setSaveDisabled(true);
        }
      }
      setIsCompressing(false);
    } catch (err: any) {
      setIsCompressing(false);
      if (err.message) {
        imgPreview.current!!.style.display = 'none';
        bgRef.current!!.style.display = 'flex';
        setValidationTextColor('#FF4D6D');
        setValidationText(err.message);
      }
    }
  };

  const onButtonClick = () => {
    inputFile?.current!!.click();
    if (inputRef) inputRef(inputFile);
  };

  React.useEffect(() => {
    if (getDefaultImage) {
      srcToFile(getDefaultImage, 'mabar-default-image.png', 'image/png')
        .then(v => {
          if (getImageBlob) getImageBlob(v);
        })
        .catch(() => {});
    }
  }, [getDefaultImage]);
  return (
    <Stack direction="column" align={align ?? 'center'}>
      <FrameImage
        margin={margin}
        isEffect={isEffect}
        width={widthMatchParent ? '100%' : width + 'px'}
        height={height + 'px'}
        bRadius={variant === 'Box' ? '16px' : '50%'}
      >
        <Box
          borderRadius={variant === 'Box' ? '16px' : '50%'}
          background={bgColor}
          width={widthMatchParent ? '100%' : `${isEffect ? width - 20 : width}px`}
          height={`${isEffect ? height - 20 : height}px`}
          style={{
            transform: isEffect ? 'rotate(90deg)' : 'none',
          }}
          disableDrag
        >
          <input
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            ref={inputFile}
            onChange={async e => {
              setIsCompressing(true);
              await handleFileUpload(e);
            }}
            hidden
            required={isRequired}
          />
          <Stack
            direction="column"
            align="center"
            margin={variant === 'Box' ? (bgColor === 'transparent' ? '0px' : '13px') : '9px'}
          >
            <Box
              borderRadius={variant === 'Box' ? '12px' : '48%'}
              ref={bgRef}
              isPressble
              background="transparent"
              border="1px dashed #969393"
              width="100%"
              height="100%"
              onClick={onButtonClick}
              style={{ cursor: 'pointer', display: getDefaultImage ? 'none' : 'flex' }}
            >
              <Stack direction="column" align="center" margin="0px">
                <Avatar
                  alt="Upload a image"
                  margin={iconOptions.iconMargin}
                  width={iconOptions.iconWidth ?? '24px'}
                  height={iconOptions.iconHeight ?? '24px'}
                  src={
                    iconOptions.iconSrc === 'upload' || iconOptions.iconSrc === 'add-photo'
                      ? IconSource(iconOptions.iconSrc!!)
                      : iconOptions.iconSrc
                  }
                />
                <NormalText
                  fontSize={placeholderStyle?.fontSize ?? '12px'}
                  fontWeight={placeholderStyle?.fontWeight ?? 'normal'}
                  textAlign={placeholderStyle?.textAlign ?? 'center'}
                  color={placeholderStyle?.color ?? validationTextColor}
                  lineHeight={placeholderStyle?.lineHeight ?? '146%'}
                  margin="12px 6px 6px 6px"
                >
                  {validationText}
                </NormalText>
              </Stack>
            </Box>
          </Stack>
          <Box
            ref={imgPreview}
            borderRadius={variant === 'Box' ? '16px' : '50.25%'}
            width={widthMatchParent ? '100%' : `${isEffect ? width - 20 : width}px`}
            height={`${isEffect ? height - 20 : height}px`}
            position="absolute"
            background={
              getDefaultImage
                ? `url(${getDefaultImage}) no-repeat center / ${
                    widthMatchParent ? '100%' : width + 'px' + ' ' + height + 'px'
                  }`
                : 'transparent'
            }
            display={getDefaultImage ? 'flex' : 'none'}
            style={{ backgroundSize: '100%' }}
          >
            {iconVisible && (
              <Stack direction="column" align="center">
                <Box
                  width={iconOptions.backgroundWidth ?? '36px'}
                  height={iconOptions.backgroundHeight ?? '36px'}
                  background={iconOptions.background ?? 'rgba(55, 57, 66, 0.6)'}
                  borderRadius={iconOptions.backgroundRadius ?? '6px'}
                  disableDrag
                  isPressble
                  onClick={isCompressing ? undefined : onButtonClick}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Stack direction="column" align={iconOptions.iconPosition ?? 'center'}>
                    <Avatar
                      alt="add-photo"
                      margin={iconOptions.iconMargin}
                      width={isCompressing ? '40px' : iconOptions.iconWidth ?? '22.5px'}
                      height={isCompressing ? '40px' : iconOptions.iconHeight ?? '18px'}
                      src={
                        isCompressing
                          ? IconSource('loader', 'gif')
                          : iconOptions.iconSrc === 'upload' || iconOptions.iconSrc === 'add-photo'
                          ? IconSource(iconOptions.iconSrc!!)
                          : iconOptions.iconSrc
                      }
                    />
                  </Stack>
                </Box>
              </Stack>
            )}
          </Box>
        </Box>
      </FrameImage>
      {descriptionText && (
        <NormalText
          fontSize="12px"
          fontWeight="400"
          lineHeight="17.52px"
          margin="24px 0px 0px 0px"
          color="white"
          textAlign="center"
        >
          {descriptionText}
        </NormalText>
      )}
    </Stack>
  );
}

function srcToFile(src: string, fileName: string, mimeType: string) {
  return fetch(src, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'no-cors',
    headers: {
      'Content-Type': '*/*',
    },
  })
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}

export default AddImage;
