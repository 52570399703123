import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from 'literals';
import { GameOptions } from 'redux/game/GameReducer';
import { Player } from 'redux/team/TeamMemberReducer';
import { Team, TeamSchool } from 'types/team';

export interface CreateTeamDraftState {
  teamLogoUrl: string;
  teamName: string;
  members: Player[];
  game: GameOptions;
  teamSelected: TeamSchool;
  myCurrentTeam?: Team;
  WhatsAppGroupUrl: string;
  DiscordUrl: string;
  WhatsAppPhoneNumber: string;
  status: string;
  infoMessage: {
    show?: boolean;
    message?: string;
  };
}

export const initialState: CreateTeamDraftState = {
  teamLogoUrl: '',
  teamName: '',
  members: [],
  game: {
    id: '',
    name: '',
    urlSlug: '',
    avatarUrl: '',
    iconUrl: '',
    release: false,
    scrimCount: 0,
    teamCount: 0,
    tournamentCount: 0,
    maxMember: 0,
    minMember: 0,
    maxScrimRoster: 0,
    minScrimRoster: 0,
    maxTeamMember: 0,
    minTeamMember: 0,
  },
  teamSelected: {
    id: '',
    name: '',
    game: {
      id: '',
      name: '',
      urlSlug: '',
      iconUrl: '',
    },
    matchCount: 0,
    memberCount: 0,
    urlSlug: '',
    avatarUrl: '',
    captain: '',
  },
  WhatsAppGroupUrl: '',
  DiscordUrl: '',
  WhatsAppPhoneNumber: '',
  status: STATUS.IDLE,
  infoMessage: {
    show: false,
    message: '',
  },
};

const TeamDraftSlice = createSlice({
  name: 'TeamDraft',
  initialState,
  reducers: {
    setGameSelectedAction: (state, action: PayloadAction<{ game: GameOptions }>) => {
      state.status = STATUS.RUNNING;
      state.game = action.payload.game;
    },
    successSetGameSelectedAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetGameSelectedAction: state => {
      state.status = STATUS.ERROR;
    },
    setPlayerListAction: (state, action: PayloadAction<{ players: Player[] }>) => {
      state.status = STATUS.RUNNING;
      state.members = action.payload.players;
    },
    successSetPlayerListAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetPlayerListAction: state => {
      state.status = STATUS.ERROR;
    },
    removePlayerAction: (state, action: PayloadAction<{ player: Player }>) => {
      state.status = STATUS.RUNNING;
      state.members.map((data, i) => {
        if (data.id === action.payload.player.id) state.members.splice(i, 1);
      });
    },
    successRemovePlayerAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedRemovePlayerAction: state => {
      state.status = STATUS.ERROR;
    },
    setTeamSelectedAction: (state, action: PayloadAction<{ teamSelected: TeamSchool }>) => {
      state.status = STATUS.RUNNING;
      state.teamSelected = action.payload.teamSelected;
    },
    successSetTeamSelectedAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetTeamSelectedAction: state => {
      state.status = STATUS.ERROR;
    },
    setTeamLogoAction: (state, action: PayloadAction<{ teamLogoUrl: string }>) => {
      state.status = STATUS.RUNNING;
      state.teamLogoUrl = action.payload.teamLogoUrl;
    },
    successSetTeamLogoAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetTeamLogoAction: state => {
      state.status = STATUS.ERROR;
    },
    setTeamNameAction: (state, action: PayloadAction<{ teamName: string }>) => {
      state.status = STATUS.RUNNING;
      state.teamName = action.payload.teamName;
    },
    successSetTeamNameAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetTeamNameAction: state => {
      state.status = STATUS.ERROR;
    },
    setTeamWhatsAppGroupUrlAction: (state, action: PayloadAction<{ whatsAppGroupUrl: string }>) => {
      state.status = STATUS.RUNNING;
      state.WhatsAppGroupUrl = action.payload.whatsAppGroupUrl;
    },
    successSetTeamWhatsAppGroupUrlAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetTeamWhatsAppGroupUrlAction: state => {
      state.status = STATUS.ERROR;
    },
    setTeamDiscordUrlAction: (state, action: PayloadAction<{ discordUrl: string }>) => {
      state.status = STATUS.RUNNING;
      state.DiscordUrl = action.payload.discordUrl;
    },
    successSetTeamDiscordUrlAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetTeamDiscordUrlAction: state => {
      state.status = STATUS.ERROR;
    },
    setTeamWhatsAppPhoneNumberAction: (
      state,
      action: PayloadAction<{ whatsAppPhoneNumber: string }>,
    ) => {
      state.status = STATUS.RUNNING;
      state.WhatsAppPhoneNumber = action.payload.whatsAppPhoneNumber;
    },
    successSetTeamWhatsAppPhoneNumberAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetTeamWhatsAppPhoneNumberAction: state => {
      state.status = STATUS.ERROR;
    },
    resetTeamDraftAction: state => {
      state.members = initialState.members;
      state.DiscordUrl = initialState.DiscordUrl;
      state.teamLogoUrl = initialState.teamLogoUrl;
      state.game = initialState.game;
      state.teamName = initialState.teamName;
      state.teamSelected = initialState.teamSelected;
      state.WhatsAppGroupUrl = initialState.WhatsAppGroupUrl;
      state.WhatsAppPhoneNumber = initialState.WhatsAppPhoneNumber;
      state.status = STATUS.SUCCESS;
    },
    setMyCurrentTeamAction: (state, action: PayloadAction<{ currentTeam: Team }>) => {
      state.status = STATUS.RUNNING;
      state.myCurrentTeam = action.payload.currentTeam;
    },
    successSetMyCurrentTeamAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetMyCurrentTeamAction: state => {
      state.status = STATUS.ERROR;
    },
    setInfoMessageAction: (
      state,
      action: PayloadAction<{
        infoMessage: {
          show?: boolean;
          message?: string;
        };
      }>,
    ) => {
      state.status = STATUS.RUNNING;
      state.infoMessage = action.payload.infoMessage;
    },
    successSetInfoMessageAction: state => {
      state.status = STATUS.SUCCESS;
    },
    failedSetInfoMessageAction: state => {
      state.status = STATUS.ERROR;
    },
    resetInfoMessageAction: state => {
      state.infoMessage = { ...initialState.infoMessage };
    },
  },
});

export const {
  setGameSelectedAction,
  successSetGameSelectedAction,
  failedSetGameSelectedAction,
  setPlayerListAction,
  successSetPlayerListAction,
  failedSetPlayerListAction,
  removePlayerAction,
  successRemovePlayerAction,
  failedRemovePlayerAction,
  setTeamSelectedAction,
  successSetTeamSelectedAction,
  failedSetTeamSelectedAction,
  setTeamDiscordUrlAction,
  successSetTeamDiscordUrlAction,
  failedSetTeamDiscordUrlAction,
  setTeamLogoAction,
  successSetTeamLogoAction,
  failedSetTeamLogoAction,
  setTeamNameAction,
  successSetTeamNameAction,
  failedSetTeamNameAction,
  setTeamWhatsAppGroupUrlAction,
  successSetTeamWhatsAppGroupUrlAction,
  failedSetTeamWhatsAppGroupUrlAction,
  setTeamWhatsAppPhoneNumberAction,
  successSetTeamWhatsAppPhoneNumberAction,
  failedSetTeamWhatsAppPhoneNumberAction,
  resetTeamDraftAction,
  setMyCurrentTeamAction,
  successSetMyCurrentTeamAction,
  failedSetMyCurrentTeamAction,
  setInfoMessageAction,
  successSetInfoMessageAction,
  failedSetInfoMessageAction,
  resetInfoMessageAction,
} = TeamDraftSlice.actions;

export default TeamDraftSlice.reducer;
