import { ClickAwayListener, Grow } from '@mui/material';
import { VirtualElement } from '@popperjs/core';
import {
  MenuContainer,
  MenuItems,
  MenuLists,
  MenuSeparator,
  PopupMenu,
} from 'components/UniPopup/Popup/Menu';
import { Avatar } from 'components/styled/avatar.styled';
import { Button } from 'components/styled/button.sytled';
import { Box, Stack } from 'components/styled/layout.styled';
import { GradientText, NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { FC } from 'react';

export interface PlayerCardType {
  playerPhoto?: string | undefined;
  playerName?: string | undefined;
  playerType?: string | undefined;
  playerId?: string | undefined;
  playerStatus?: string | undefined;
  isCaptain?: boolean | undefined;
  iconRight?: string | undefined;
  iconMore?: string | undefined;
  textRight?: string | undefined;
  disable?: boolean | undefined;
  margin?: string;
  onIconRightClick?: ((player: PlayerCardType) => void) | undefined;
  onClick?: ((player: PlayerCardType) => void) | undefined;
  onCopyText?: ((player: PlayerCardType) => void) | undefined;
  openMore?: boolean | undefined;
  handleOpenMore?: ((id: string) => void) | undefined;
  handleToggleMore?: ((status: boolean, key: string) => void) | undefined;
  handleListKeyDown?: (() => void) | undefined;
  playerKey?: string | undefined;
  playerSlug?: string | undefined;
  teamId?: string | undefined;
  checkInCount?: number;
  urlSlug?: string;
  hasCheckIn?: boolean | false | undefined;

  // For Accept & Rejection
  acceptMember?: (({ playerId, teamId }: { playerId: string; teamId: string }) => void) | undefined;
  rejectMember?: (({ playerId, teamId }: { playerId: string; teamId: string }) => void) | undefined;
  kickMember?: (({ playerId, teamId }: { playerId: string; teamId: string }) => void) | undefined;
  acceptInvitation?:
    | (({ playerId, teamId }: { playerId: string; teamId: string }) => void)
    | undefined;
  rejectInvitation?:
    | (({ playerId, teamId }: { playerId: string; teamId: string }) => void)
    | undefined;
}

const PlayerCard: FC<PlayerCardType> = ({
  playerPhoto,
  playerName,
  playerType,
  playerId,
  isCaptain,
  playerStatus,
  iconRight,
  textRight,
  disable,
  margin,
  onIconRightClick,
  onClick,
  onCopyText,
  iconMore,
  openMore,
  handleToggleMore,
  playerKey,
  playerSlug,
  teamId,
  hasCheckIn,
  acceptMember,
  rejectMember,
  kickMember,
  rejectInvitation,
  urlSlug,
}) => {
  const anchorRef = React.useRef<VirtualElement>();

  const RenderMenuLists = () => {
    if (playerStatus === '1' && playerType === 'Request') {
      return (
        <>
          <MenuItems
            color="#FF4D6D"
            onClick={() => {
              if (acceptMember && playerSlug && teamId) {
                acceptMember({
                  playerId: playerSlug,
                  teamId,
                });
              }
            }}
          >
            Terima Jadi Anggota
          </MenuItems>
          <MenuSeparator />
          <MenuItems
            onClick={() => {
              if (rejectMember && playerSlug && teamId) {
                rejectMember({
                  playerId: playerSlug,
                  teamId,
                });
              }
            }}
          >
            Tolak
          </MenuItems>
        </>
      );
    } else if (playerStatus === '1' && playerType === 'Invite') {
      return (
        <>
          <MenuItems
            color="#FF4D6D"
            onClick={() => {
              if (rejectInvitation && playerSlug && teamId) {
                rejectInvitation({
                  playerId: playerSlug,
                  teamId,
                });
              }
            }}
          >
            Batalkan Undangan
          </MenuItems>
        </>
      );
    } else {
      return (
        <MenuItems
          color="#FF4D6D"
          onClick={() => {
            if (kickMember && playerSlug && teamId) {
              kickMember({
                playerId: playerSlug,
                teamId,
              });
            }
          }}
        >
          Keluarkan dari Tim
        </MenuItems>
      );
    }
  };

  return (
    <Box width="100%">
      <Box
        background="#2D2F37"
        flexGrow={1}
        margin={margin ?? '5px 8px'}
        padding="12px"
        borderRadius="16px"
        opacity={disable ? 0.3 : 1.0}
      >
        <Stack direction="row" align="center" disableDrag>
          <Box width="50px" isPressble>
            <Stack direction="column" align="center" position="relative" disableSelectText>
              <Avatar
                src={playerPhoto ?? IconSource('ic-person-white')}
                width="42px"
                height="42px"
                borderRadius="50%"
                background={playerPhoto ? 'transparent' : '#B1B1B1'}
                fit="contain"
                objectPos={playerPhoto ? 'center' : 'bottom'}
                zIndex={0}
                style={{ position: 'absolute' }}
                isPressable={disable ? false : true}
                onClick={() => {
                  if (urlSlug) {
                    history.push('/player/' + urlSlug);
                  }
                }}
                onError={e => {
                  e.currentTarget.style.backgroundColor = '#B1B1B1';
                  e.currentTarget.src = IconSource('ic-person-white');
                  e.currentTarget.style.objectPosition = 'bottom';
                }}
              />
              {isCaptain && (
                <div style={{ width: '42px', height: '42px', position: 'absolute', zIndex: 2 }}>
                  <div style={{ height: '25px' }} />
                  <Stack
                    direction="row"
                    align="end"
                    disableSelectText
                    disableDrag
                    onClick={() => {
                      if (urlSlug) {
                        history.push('/player/' + urlSlug);
                      }
                    }}
                  >
                    <div style={{ width: '20px' }} />
                    <Avatar src={IconSource('crown.solid')} height="24px" borderRadius="50%" />
                  </Stack>
                </div>
              )}
            </Stack>
          </Box>
          <Box
            width="100%"
            padding="0px 10px"
            style={{ overflow: 'hidden' }}
            onClick={() => {
              if (onClick) {
                onClick({
                  playerPhoto,
                  playerName,
                  playerId,
                  isCaptain,
                  iconRight,
                  textRight,
                  disable,
                  hasCheckIn,
                  onClick,
                });
              }
            }}
            isPressble={onClick && disable ? false : true}
          >
            <Stack
              direction="column"
              align="start"
              disableSelectText
              disableDrag
              onClick={() => {
                if (urlSlug) {
                  history.push('/player/' + urlSlug);
                }
              }}
            >
              <NormalText
                textAlign="left"
                color="#FFFFFF"
                fontSize="14px"
                fontWeight="700"
                margin="0px 0px 4px 0px"
              >
                {playerName}
              </NormalText>
              <NormalText
                color={
                  playerId === 'Menunggu Persetujuan'
                    ? '#E7B450'
                    : playerId === 'Perlu Persetujuan'
                    ? '#F37024'
                    : 'rgba(150, 147, 147, 1)'
                }
                fontSize="12px"
                fontWeight="400"
                margin="4px 0px 0px 0px"
              >
                {playerId}
              </NormalText>
            </Stack>
          </Box>
          <Box
            padding={textRight ? '0px 3px 0px 0px' : '5px 3px 0px 0px'}
            height="100%"
            onClick={() => {
              if (onIconRightClick) {
                onIconRightClick({
                  playerPhoto,
                  playerName,
                  playerId,
                  isCaptain,
                  iconRight,
                  textRight,
                  disable,
                  hasCheckIn,
                  onIconRightClick: onClick,
                  onClick,
                });
              }
            }}
            isPressble={disable ? true : false}
          >
            <Stack direction="column" align="center" style={{ alignItems: 'flex-end' }}>
              <Box width="20px" height="24px">
                {iconRight && <Avatar src={iconRight} width="20px" height="20px" />}
              </Box>
              {textRight && (
                <GradientText
                  fontWeight="700"
                  fontSize="14px"
                  onClick={() => {
                    if (onCopyText) {
                      onCopyText({
                        playerPhoto,
                        playerName,
                        playerId,
                        isCaptain,
                        iconRight,
                        textRight,
                        disable,
                        hasCheckIn,
                        onCopyText,
                      });
                    }
                  }}
                >
                  {textRight}
                </GradientText>
              )}
            </Stack>
          </Box>
          <Box
            padding={textRight ? '0px 3px 0px 0px' : '5px 3px 0px 0px'}
            height="100%"
            isPressble={true}
          >
            <Button
              // @ts-ignore
              ref={anchorRef}
              onClick={() => {
                if (handleToggleMore && playerKey) {
                  handleToggleMore(true, playerKey);
                }
              }}
            >
              {iconMore && <Avatar src={iconMore} width="20px" height="20px" />}
            </Button>
            {openMore !== undefined && (
              <PopupMenu
                open={openMore}
                role={undefined}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                placement="bottom-end"
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: 'right top',
                    }}
                  >
                    <MenuContainer>
                      <ClickAwayListener
                        onClickAway={() => {
                          if (handleToggleMore && playerKey) {
                            handleToggleMore(false, '');
                          }
                        }}
                      >
                        <MenuLists
                          autoFocusItem={openMore}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                        >
                          <RenderMenuLists />
                        </MenuLists>
                      </ClickAwayListener>
                    </MenuContainer>
                  </Grow>
                )}
              </PopupMenu>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default PlayerCard;
