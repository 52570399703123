import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export const TEAM_NAME_PATTERN = /^[a-zA-Z0-9]+$/g;
export const PHONE_NUMBER_PATTERN = /^[0-9]+$/g;
export const SPESIFIC_PHONE_NUMBER_PATTERN = /^[08]{2,2}.*$/g;
export const DISCORD_URL_PATTERN =
  /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-zA-Z]/g;
export const WHATSAPP_GROUP_OR_PHONE_PATTERN =
  /^[08]{2,2}.*|(https?:\/\/)?chat\.whatsapp\.com\/(?:invite\/)?([a-zA-Z0-9_-]{22})$/g;
export const URL_PATTERN =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
export const URL_OR_PHONE_PATTERN =
  /^[08]{2,2}.*$|(http|https):\/\/(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))/g;

// ex: +62345678901223 or 0828123682356 or https://chat.whatsapp.com/LJhdjkiJldoIkdjMJdd
export const WHATSAPP_GROUP_OR_PHONE_PATTERN2 =
  /^[\+62]\d{11,14}$|^[08]\d{10,12}$|^(https):\/\/chat\.whatsapp\.com\/(?:invite\/)?([a-zA-Z0-9_-]*)$/g;

export interface FormError {
  message: string;
  type: string;
  ref: Ref;
}

export interface Ref {
  name: string;
}

export type CreateTeamFormValues = {
  teamName?: FormError;
  WhatsAppGroupUrl?: FormError;
  GameId?: FormError;
  DiscordUrl?: FormError;
  WhatsAppPhoneNumber?: FormError;
  AvatarFile?: FormError;
  Members?: FormError;
};

const CreateTeamValidationSchema = Yup.object().shape({
  teamName: Yup.string()
    .required('Diperlukan Nama Tim')
    .min(1, 'Nama Tim minimal 1 karakter')
    .max(50, 'Nama Tim maksimal 50 karakter'),

  WhatsAppGroupUrl: Yup.string().matches(URL_OR_PHONE_PATTERN, {
    excludeEmptyString: true,
    message: 'Link Group atau No Whatsapp tidak sesuai',
  }),
  // DiscordUrl: Yup.string().matches(URL_PATTERN, {
  //   excludeEmptyString: true,
  //   message: 'Link Discord yang kamu berikan tidak valid',
  // }),
  WhatsAppPhoneNumber: Yup.string()
    .required('Wajib Diisi')
    .matches(/^[0-9]+$/, 'Kontak Whatsapp harus angka')
    .min(11, 'Kontak Whatsapp minimal 11 karakter')
    .max(13, 'Kontak Whatsapp maksimal 13 karakter'),
  // AvatarFile: Yup.mixed().required('Diperlukan Logo Tim'),
});

const EditTeamValidationSchema = Yup.object().shape({
  WhatsAppGroupUrl: Yup.string().matches(WHATSAPP_GROUP_OR_PHONE_PATTERN2, {
    excludeEmptyString: true,
    message: 'Link Group atau No Whatsapp tidak sesuai',
  }),
  WhatsAppPhoneNumber: Yup.string()
    .required('Wajib Diisi')
    .matches(/^[0-9]+$/, 'Kontak Whatsapp harus angka')
    .min(11, 'Kontak Whatsapp minimal 11 karakter')
    .max(13, 'Kontak Whatsapp maksimal 13 karakter'),
});

export const CreateTeamFormOptions = { resolver: yupResolver(CreateTeamValidationSchema) };
export const EditTeamFormOptions = { resolver: yupResolver(EditTeamValidationSchema) };
