import styled from 'styled-components';

export const TextIdScrim = styled.p`
  position: static;
  left: 6.9%;
  right: 6.9%;
  top: 16%;
  bottom: 16%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  /* Gray 01 */

  color: #ddd8d8;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const TextTanggalScrim = styled.p`
  position: absolute;
  right: 0%;
  top: 0%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #ddd8d8;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 20px;
  margin-right: 20px;
`;

export const TextDescripScrim = styled.p`
  position: absolute;
  width: auto;
  height: auto;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  /* White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 55px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const TextPenantang = styled.p`
  position: absolute;
  width: auto;
  //left: calc(50% - 300px/2 + 15px);
  top: 0%;
  bottom: 0%;
  margin: 120px 5px;
  margin-left: 55px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  /* White */

  color: #ffffff;
`;

export const TextIdPertandingan = styled.p`
  position: absolute;
  left: 6.9%;
  right: 6.9%;
  top: 10%;
  bottom: 16%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  /* Gray 01 */

  color: #ddd8d8;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
`;

export const TextTim1 = styled.span`
  position: absolute;
  width: 281px;
  top: 0%;
  bottom: 0%;
  margin: 60px 60px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  word-wrap: break-word;

  color: #ffffff;
`;

export const TextTim2 = styled.span`
  position: absolute;
  width: 281px;
  top: 0%;
  bottom: 0%;
  margin: 90px 60px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  word-wrap: break-word;

  color: #ffffff;
`;

export const TextRejected = styled.span `
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
padding: 10px;

position: static;
width: 71px;
height: 42px;
left: 240px;
top: 14px;
word-wrap: break-word;

background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
border-radius: 10px;

/* Inside Auto Layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 16px;
`
export const TextJamPertandingan = styled.span`
  position: static;
  width: 75px;
  height: 22px;
  left: calc(50% - 75px / 2 + 2.5px);
  top: calc(50% - 22px / 2 - 8.5px);

  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: right;

  /* White */

  color: #ffffff;

  /* Inside Auto Layout */
  word-wrap: break-word;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const TextWaktuPertandingan = styled.p`
  position: static;
  left: 10%;
  right: 10%;
  top: 54.24%;
  bottom: 16.95%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  text-align: right;

  /* Gray 01 */

  color: #ddd8d8;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const TextWCheckinPertandingan = styled.p`
  position: relative;
  width: 84px;
  height: 22px;
  left: calc(50% - 84px / 2 + 1px);
  top: calc(50% - 22px / 2 - 8.5px);
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: right;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`;
