import { createSlice } from '@reduxjs/toolkit';
import { School, SchoolBaseResponse } from './SchoolResponse';
import { fetchSchoolList } from './SchoolServices';

export interface SchoolState {
  schoolList?: SchoolBaseResponse<School[]>;
  errors?: string;
  status?: 'idle' | 'loading' | 'succeeded' | 'failed' | 'error';
}

const initialState: SchoolState = {
  errors: '',
  status: 'idle',
};

const SchoolSlice = createSlice({
  name: 'school',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSchoolList.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchSchoolList.rejected, (state, action) => {
        state.errors = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchSchoolList.fulfilled, (state, action) => {
        state.schoolList = action.payload;
        state.status = 'succeeded';
      })
  },
});

export default SchoolSlice.reducer;
