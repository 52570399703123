import {
  StyledButton,
  StyledListbox,
  StyledPopper,
  CustomInputLabel,
  StyledOption,
  CustomInputSearch,
} from './styles';
import SelectUnstyled from '@mui/base/SelectUnstyled';
import { SelectProps } from '@mui/material';
import Box from '@mui/material/Box';
import { Text } from 'design/Text';
import React, { useState, useEffect, useRef, ChangeEvent, ReactNode } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

interface SelectInputProps extends SelectProps {
  label?: string;
  placeholder?: string;
  options?: Array<OptionType>;
  defaultValue?: string;
  searchable?: boolean;
  cta?: ReactNode;
  onItemChange?: ((arg: unknown) => void) | undefined;
  validationText?: string;
}

export type OptionType = {
  name: string;
  value: string;
  disabled?: boolean;
};

function CustomSelect(props: any) {
  const components: any = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

export const SelectInput = ({
  label,
  placeholder,
  options,
  searchable = false,
  cta = false,
  defaultValue,
  onItemChange,
  validationText,
  disabled,
  ...props
}: SelectInputProps) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<string>('');
  const [filterData, setFilterData] = useState(options);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue);
    }
  }, [defaultValue, options]);

  const secondOptions = options?.filter(item =>
    defaultValue ? item.value === defaultValue : item.value === values,
  );
  const secondOptionsWithDisabled = {
    value: secondOptions && secondOptions[0]?.value,
    label: secondOptions && secondOptions[0]?.name,
    disabled: (secondOptions && secondOptions[0]?.disabled) || false,
  };

  const handleListBoxOpen = () => {
    if (disabled) return setOpen(false);
    setFilterValue('');
    open ? setOpen(false) : setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChange = (event: string) => {
    setValues(event);
    if (onItemChange) {
      onItemChange(event);
    }
  };

  const renderValue = (option = secondOptionsWithDisabled) => {
    if (option == null || values === '' || values !== option.value) {
      return (
        <Text sx={theme => ({ color: theme.palette.neutral400 })}>
          {placeholder ? placeholder : 'Select'}
        </Text>
      );
    }

    return <Text>{option.label}</Text>;
  };

  const handleFilterValue = (e: ChangeEvent<{ value: string }>) => {
    return setFilterValue(e.target.value);
  };

  // change FilterData if options updating
  useEffect(() => {
    setFilterData(options);
    if (secondOptions?.length == 0) {
      setValues('');
    }
  }, [options]);

  useEffect(() => {
    if (filterValue) {
      const result = options?.filter(item =>
        item.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
      setFilterData(result);
    } else {
      setFilterData(options);
    }
  }, [filterValue]);

  const indexValue = options?.map(item => item.value).indexOf(values);
  const optionRef = useRef<Array<HTMLDivElement>>([]);

  useEffect(() => {
    if (optionRef.current[0] && indexValue) {
      document
        .querySelector('.box-option')!
        .scrollTo(0, optionRef.current[indexValue]?.offsetTop - 5 ?? 0);
    }
  }, [open, indexValue]);

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <Box sx={{ position: 'relative' }}>
        <CustomInputLabel>{label}</CustomInputLabel>
        <CustomSelect
          renderValue={renderValue}
          listboxOpen={open}
          defaultValue={defaultValue}
          onClick={handleListBoxOpen}
          onChange={handleChange}
          value={values}
          disabled={disabled}
          {...props}
        >
          {searchable && (
            <Box>
              <CustomInputSearch
                onChange={handleFilterValue}
                defaultValue={filterValue}
                placeholder="Search"
                onKeyDown={(e: ChangeEvent<any>) => e.stopPropagation()}
                onKeyUp={(e: ChangeEvent<any>) => e.stopPropagation()}
              />
            </Box>
          )}
          {filterData && (
            <Box
              className="box-option"
              onClick={handleClose}
              sx={{ maxHeight: searchable ? '110px' : '180px', overflow: 'auto', padding: '0 4px' }}
            >
              {filterData.length > 0 &&
                filterData.map((item: OptionType, index: number) => {
                  return (
                    <StyledOption
                      ref={(e: any) => {
                        optionRef.current[index] = e;
                      }}
                      value={item.value}
                      key={index}
                    >
                      {item.name}
                    </StyledOption>
                  );
                })}
            </Box>
          )}
          {cta && <Box sx={{ padding: '12px 16px', borderTop: '1px solid #323437' }}>{cta}</Box>}
        </CustomSelect>
      </Box>
      {validationText && (
        <Text variant="subtitle" sx={theme => ({ color: theme.palette.danger600, mt: '4px' })}>
          {validationText}
        </Text>
      )}
    </OutsideClickHandler>
  );
};
