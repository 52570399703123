import React, { useState } from 'react';
import GradientButton from 'components/Buttons/GradientButton';
import { Box, Stack } from 'components/styled/layout.styled';
import { GradientText, NormalText, SpanText } from 'components/styled/text.styled';
import { MatchUpload, MatchUploadProps } from 'components/MatchUpload/index';
import AppLayout from 'components/layouts/AppLayout';
import { IconSource, ImageSource } from 'modules/assetpath';
import styled from 'styled-components';
import { addScrimDetail, AddScrimDetailProps, getScrimDetailHeader, ScrimHeaderDetailResponse } from 'redux/scrim/match.details/ScrimMatchDetailReducer';
import { useAppThunkDispatch } from 'redux/store';
import history from 'modules/history';
import { useForm, Controller/*, useWatch */} from 'react-hook-form';
import { useParams } from 'react-router-dom';
//import { getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';
import MessageModal from 'components/UniPopup/Modals/MessageModal';
import { Avatar } from 'components/styled/avatar.styled';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export const FrameGameResult = styled.div`
  bottom: 0%;
  background-image: url(${`${process.env.PUBLIC_URL}/media/images/dummy/game-result.png`});
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 207px;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 14px;
`;

const MatchUploads = () => {
  const { gameSlug,  scrim_id } = useParams() as { gameSlug: string;scrim_id: string };
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<'error' | 'loading' | 'information'>('information');
  const submitRef = React.createRef<HTMLInputElement>();
  const { register, handleSubmit, control } = useForm();
  const dispatch = useAppThunkDispatch();
 // const watchFields = useWatch({ control: control })
  //const isFormComplete = watchFields['Results']
  const [state, setState] = useState(false);
  const [getUploadResult, setUploadResult] = useState<AddScrimDetailProps>({matchId: scrim_id, result:[]});
  const [getScrimDetail, setScrimDetail] = useState<ScrimHeaderDetailResponse>()
  const teamInfo = getMyCurrentTeamFromSession().find((v)=> v.urlSlug === getScrimDetail?.firstTeamUrlSlug) ?? getMyCurrentTeamFromSession().find((v)=> v.urlSlug === getScrimDetail?.secondTeamUrlSlug);
  const [getErrorList, setErrorList] = useState<any>()
  function saveUploadSs(data: any) {
    setUploadResult({matchId: scrim_id, result: data.Results})
  }

  async function uploadScreenShoot(){
    setModalType('loading')
    setShowModal(true)
    const thunk = addScrimDetail(getUploadResult)
    dispatch(thunk).unwrap().then((res) => {
        if(res && res?.isAxiosError || res?.data || res?.data?.status >= 400){
          setErrorList(res.data)
          setModalType('error')
          setShowModal(true)        
        }else{          
          setShowModal(false)
          history.push('/scrims/mobile-legend/'+scrim_id)
        }        
    }).catch((err) => {
      if(process.env.NODE_ENV !== 'production')
        console.error(err)
    })
  }

  const dataMatchUpload : MatchUploadProps = 
    {
      id: '1',
      name: '1',
      value1: String(true),
      value2: String(false),
      gameText: 'Game 1',
      status:"Status Tim Kamu di Game Ini",
      onImgSelected:() => setState(!state),      
      onClick:() => state,
    }
  
  const [uploadElements, setUploadElements] = useState<MatchUploadProps[]>([])

  const ModalError = () => {
    const parseError = (errors: any) => {
      if (errors) {
        let errorsList: string[] = [];
        Object.values(errors).forEach((prop: any) => {
          errorsList = errorsList.concat(_.map(prop, _.property('message')));
        });
        return errorsList.join('\r\n');
      }
      return null;
    };
    return (
      <MessageModal btnConfirmText={<NormalText padding='0px 60px 0px 60px'>OK</NormalText>} onConfirmClick={()=> setShowModal(false)} 
        title={getErrorList ? getErrorList?.status !== 400 ? getErrorList?.title : t('errors:HTTP400') : 'Kirim Hasil Pertandingan Gagal'}>
          <SpanText fontSize='13px' fontWeight='500' color='#FF4D6D' textAlign='center'>
            {getErrorList && getErrorList.errors ? parseError(getErrorList?.errors) : getErrorList?.detail ? getErrorList?.detail : "Data yang dikirim salah!"}
          </SpanText>
      </MessageModal>
    )
  }
  

  const fetchScrimDetail = React.useCallback(()=> {
    dispatch(getScrimDetailHeader({ id: scrim_id }))
    .unwrap()
    .then(async (team) => {
      if (team && team.data) {
        setScrimDetail(team.data);
        if(teamInfo){
          if(teamInfo.level.description !== 'Captain'){
            setErrorList({detail: 'Hanya Kapten Tim yang diperbolehkan Kirim Hasil Pertandingan'})
            setModalType('error')
            setShowModal(true)
          }
        }
      }
    }).catch((err) => {
      if (err.stack?.includes('404')) {
        history.push(`/404?scrims/${scrim_id}`)
      }
    })
  }, [dispatch, teamInfo])
  

  React.useEffect(()=> {
    fetchScrimDetail()
    if(uploadElements.length <= 0) 
      uploadElements.push(dataMatchUpload)   
  }, [dispatch, scrim_id])

  return (
    <AppLayout
      appBar={{
        bg: {
          bRadius: '0px',
          background: 'rgba(36, 39, 49, 0.94)',
          boxShadow: 'none',
          height: '56px',
          pos: 'fixed',
        },
        navleft: {
          bg: 'ic-arrow-back-light',
          height: '24px',
          width: '24px',
          onClick: () => history.push(`/scrims/${gameSlug}/${scrim_id}`),
        },
        navright: {
          idNav: 'btn-send-match-result',
          bg: state
            ? 'linear-gradient(131.42deg, #d51f40 46.48%, #e24134 62.51%, #e8542e 74.36%, #f37024 83.78%)'
            : '#969393',
          children: 'Kirim',
          variant: 'button',
          onClick: ()=> {
            setModalType('information')
            setShowModal(true)
          }
        },
        onClick: () => null,
        title: 'Kirim Hasil Pertandingan',
      }}
      backgroundImage={ImageSource('app.background')}
      showModal={showModal}      
      modalStyled={{background: modalType === 'loading' ? 'transparent' : '#373942'}}
      modalContent={ modalType === 'information' ? (
      <Box padding="14px" width="100%">
        <Stack direction="column" align="center">
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight="700"
            margin="13px"
          >
            Apakah kamu yakin telah mengisi data “Hasil Pertandingan” dengan benar?
          </NormalText>
          <Box width="100%" margin="16px 0px 0px 0px">
            <Stack direction="column" align="center">
              <GradientButton
                styled={{ backgroundSize: '100%' }}
                variant="primary"
                onClick={async() => await uploadScreenShoot()}
              >
               Ya, Kirim Sekarang
              </GradientButton>
  
              <GradientButton variant="cta" onClick={() => setShowModal(false)}>
                Batal
              </GradientButton>
            </Stack>
          </Box>
        </Stack>
      </Box>) : modalType === 'error' ? (<ModalError/>) : modalType === 'loading' && (
        <Avatar src={IconSource('ic-loader')} />
      )
      }
    >
    <form onSubmit={handleSubmit(saveUploadSs)} noValidate>
      <div style={{ marginTop: '20px' }}>
        {uploadElements?.map((uploadProps, index)=> {
          return (
            <Controller
            key={index}
            control={control}
            name={`Results.${index}.AttachmentFile`}
            render={({ field: { onChange }}) => (
              <MatchUpload {...uploadProps}
              getImgBlob={(blob)=> onChange(blob)} 
              radioControl={control} radioControlName={`Results.${index}.isWin`} 
              {...register(`Results.${index}.Round`, {value: index+1})}
               />
            )}
            />
          )
        })}
        <p style={{ textAlign: 'center' }} hidden={uploadElements.length >= 3}>
          <GradientText
            onClick={() => {
              if(uploadElements.length <= 2){
                submitRef.current?.click()
                const temp = [...uploadElements]
                temp.push({
                  gameText: 'Game '+(uploadElements.length+1),
                  status: 'Status Tim Kamu di Game Ini',
                  id : (uploadElements.length+1).toString(),
                  name: (uploadElements.length+2).toString(),
                  value1 : String(true),
                  value2: String(true)
                })
                setUploadElements(temp)
              }
            }}
            fontSize="14px"
            fontStyle="normal"
            fontWeight="700"
            isPressable
            itemType='submit'
          >
            + Tambah Game
          </GradientText>
        </p>
      </div>
        <input ref={submitRef} type='submit' hidden />
      </form>
    </AppLayout>
  );
};

export default MatchUploads;
