import { STATUS } from 'store/constants/match';

interface MatchStatusProps {
  [key: number]: string;
}

export const MATCH_STATUS: MatchStatusProps = {
  [STATUS.UNKNOWN]: 'Match Tidak Valid',
  [STATUS.EXPIRED]: 'Match Tidak Valid',
  [STATUS.REJECT]: 'Match Tidak Valid',
  [STATUS.OPEN]: 'Match Akan Datang',
  [STATUS.INVITATION]: 'Match Akan Datang',
  [STATUS.SCHEDULED]: 'Match Akan Datang',
  [STATUS.CHECKIN]: 'Match Sedang Berlangsung',
  [STATUS.COMPETE]: 'Match Sedang Berlangsung',
  [STATUS.NOTVALID]: 'Match Tidak Valid',
  [STATUS.RESULTUPLOAD]: 'Match Sedang Berlangsung',
  [STATUS.DISPUTE]: 'Match Dalam Validasi',
  [STATUS.RESULTCALCULATED]: 'Match Selesai',
  [STATUS.DATACOMPLETED]: 'Match Selesai',
  [STATUS.BYE]: 'Match Selesai',
};
