import { Stack, Avatar } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface CardTeamIdProps {
  gameName: string;
  teamLogo: string;
  teamName: string;
  additional?: ReactNode;
  link: string | object;
}
export const CardTeamId = ({ gameName, teamLogo, teamName, additional, link }: CardTeamIdProps) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={theme => ({
          p: '12px 8px 12px 12px',
          bgcolor: 'neutral700',
          borderRadius: theme.borderRadius.md,
        })}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar
            src={teamLogo}
            variant="rounded"
            sx={theme => ({
              width: '30.4px',
              height: '30.4px',
              bgcolor: 'white',
              border: `1.41502px solid ${theme.palette.gray02}`,
              boxShadow: '0px 2.83003px 2.83003px rgba(0, 0, 0, 0.25)',
              borderRadius: theme.borderRadius.sm,
            })}
          />
          <Stack justifyContent="center" sx={{ ml: '10px' }}>
            <Text
              className="cardbanner-title"
              variant="labelSmall"
              sx={{ color: 'neutral300', textTransform: 'uppercase' }}
            >
              {gameName}
            </Text>
            <Text
              className="cardbanner-description"
              variant="subtitle"
              sx={theme => ({
                fontFamily: theme.fontFamily.lexendDeca,
                fontWeight: 600,
                lineHeight: '18px',
              })}
            >
              {teamName}
            </Text>
          </Stack>
        </Stack>
        {additional}
        <Stack sx={{ ml: '5px' }}>
          <img src={IconSource('ic-chevron-right')} width={24} />
        </Stack>
      </Stack>
    </Link>
  );
};
