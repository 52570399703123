import React, { FC, ReactNode } from 'react';
import { CardUpload, Title, Row, Column, TitleStatus } from './styles';
import { RadioButton } from '../RadioButton/index';
import AddImage from 'components/Forms/AddImage';
import { Box, Stack } from 'components/styled/layout.styled';
import { Control, Controller, RefCallBack } from 'react-hook-form';

export interface MatchUploadBgProps {
  bg?: string;
  w?: string;
  h?: string;
  frameMt?: string;
  children?: ReactNode;
}

export interface MatchUploadProps {
  bg?: 'upload' | 'upload-img-icon';
  bw?: string;
  bh?: string;
  iconWidth?: '21px' | '25px';
  mtIcon?: string;
  mb?: string;
  mt?: string;
  gameText?: ReactNode;
  status?: ReactNode;
  onClick?: () => void;
  onImgSelected?: () => void;
  borderRadius?: '12px' | '100px';
  transform?: 'rotate(90deg)' | 'rotate(0deg)';
  value1?: string | number | readonly string[] | undefined;
  value2?: string | number | readonly string[] | undefined;
  value?: string | number | readonly string[] | undefined;
  id?: string;
  name?: string;
  getImgBlob?: (blob: Blob) => void;
  ref?: RefCallBack;
  radioControl?: Control;
  radioControlName?: string;
}
export interface MatchUploadIcon {
  bg?: 'upload' | 'upload-img-icon';
  children?: ReactNode | HTMLAllCollection;
  onClick?: () => void;
}

export const RadioButtonChangeHandle = (data: number | string) => {
  const temp = data;

  return temp;
};

export const MatchUploadContainer: FC<MatchUploadBgProps> = ({
  frameMt = '0px',
  bg,
  h,
  w,
  children,
}) => (
  <CardUpload frameMt={frameMt} bg={bg} h={h} w={w}>
    {children}
  </CardUpload>
);

export const MatchUpload: FC<MatchUploadProps> = ({
  status,
  gameText,
  radioControl,
  onImgSelected,
  value1,
  value2,
  id,
  name,
  getImgBlob,
  radioControlName,
}) => (
  <MatchUploadContainer>
    <Title>{gameText}</Title>
    <Box width="100%">
      <Stack direction="column" align="center" margin="0px 32px">
        <AddImage
          bgColor="transparent"
          height={175}
          iconOptions={{
            background: 'rgba(55, 57, 66, 0.5)',
            backgroundHeight: '100%',
            backgroundRadius: '16px',
            backgroundWidth: '100%',
            iconHeight: '18px',
            iconMargin: '0px',
            iconPosition: 'center',
            iconSrc: 'add-photo',
            iconWidth: '22.5px',
          }}
          // onClick={onClick}
          onImageLoad={onImgSelected}
          placeholder="Screenshot Hasil Pertandingan"
          variant="Box"
          getImageBlob={getImgBlob}
          widthMatchParent
        />
      </Stack>
    </Box>
    <TitleStatus>{status}</TitleStatus>
    <Controller
      name={radioControlName ?? 'RadioResult'}
      control={radioControl}
      render={({ field: { onChange } }) => (
        <Row>
          <Column>
            <RadioButton
              id={id}
              name={name}
              label="Menang"
              value={value1}
              onChange={() => {
                onChange(RadioButtonChangeHandle(1) === 1 ? true : false);
              }}
            />
          </Column>
          <Column>
            <RadioButton
              id={id}
              name={name}
              label="Kalah"
              value={value2}
              onChange={() => {
                onChange(RadioButtonChangeHandle(0) === 0 ? false : true);
              }}
            />
          </Column>
        </Row>
      )}
    />
  </MatchUploadContainer>
);

export default MatchUpload;
