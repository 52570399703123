import styled from 'styled-components';

export const IndicatorContainer = styled.div<{
  position: 'left' | 'center' | 'right';
  direction: 'vertical' | 'horizontal' | 'both';
}>`
  display: flex;
  flex-direction: ${p => {
    switch (p.direction) {
      case 'vertical':
        return 'column';
      case 'horizontal':
        return 'row';
      default:
        return 'row';
    }
  }};;
  justify-content: ${p => {
    switch (p.position) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
    }
  }};
  padding: 10px;
  flex-wrap: wrap;
`;

export const IndicatorItem = styled.div<{
  color?: string;
  borderColor?: string;
  isSelected?: boolean;
}>`
  display: flex;
  width: 8px;
  height: 8px;
  margin: 10px 4px;
  border-radius: 50%;
  border: 1px solid ${p => (p.isSelected ? p.color : p.borderColor)};
  background: ${p => (p.isSelected ? p.color : 'none')};
`;
