import BackgroundImage from 'components/layouts/BackgroundImage';
import { NormalText } from 'components/styled/text.styled';
import { IconSource} from 'modules/assetpath';
import React, { ReactNode } from 'react';
import AccountPhotoProfile from './AccountPhotoProfile';
import { HeaderAccountContainer } from './styles';

export interface HeaderAccountProps {
  personName?: ReactNode;
  schoolName?: ReactNode;
  cityName?: ReactNode;
  generation?: ReactNode;
  avatarUrl?: string;
}

const HeaderAccount = ({
  personName,
  schoolName,
  cityName,
  generation,
  avatarUrl,
}: HeaderAccountProps) => {
  return (
    <HeaderAccountContainer>
      <BackgroundImage
        backgroundColor='rgba(19, 19, 24, 0.94)'
        minHeight="294px"
        src={IconSource('mabar-logo-only')}
        mask="linear-gradient(to bottom, #13131888, #13131811,#13131802, #13131800)"
        imgStyle={{objectFit: 'contain', top: -58}}
      >
        <AccountPhotoProfile
          photoProfile={avatarUrl}
          variant={avatarUrl ? "normal" : "gradient"}
          uploadPhotoClick={() => {}}
        />
        <NormalText 
        color="white" 
        fontSize="22px" 
        fontWeight="700" 
        wordWrap={'break-word'}
        maxWidth='320px'
        textAlign='center'
        fontStyle="normal">
          {personName}
        </NormalText>
        <NormalText
          margin="4px 0px 48px 0px"
          color="white"
          fontSize="12px"
          fontWeight="500"
          fontStyle="normal"
          textAlign='center'
          wordWrap={'break-word'}
          maxWidth='220px'
        >
          {`${schoolName ? `${schoolName}` : ''} ${cityName ? cityName : ''} ${generation ? `(${generation})` : ''}`}
        </NormalText>
      </BackgroundImage>
    </HeaderAccountContainer>
  );
};
export default HeaderAccount;
