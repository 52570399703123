import {
  failedAuthAction,
  loginAction,
  logoutAction,
  registerAction,
  successAuthAction,
} from './AuthReducer';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import history from 'modules/history';
import AuthService from 'services/AuthService';

const auth = new AuthService();

function* loginSaga() {
  try {
    window.localStorage.removeItem('sign_redirect_url');
    auth.setSignRedirectURL(history.location.pathname);
    yield call(() => auth.login());
    yield put(successAuthAction());
  } catch (e) {
    yield put(failedAuthAction());
  }
}

function* registerSaga() {
  try {
    window.localStorage.removeItem('sign_redirect_url');
    auth.setSignRedirectURL(history.location.pathname);
    yield call(() => auth.register());
    yield put(successAuthAction());
  } catch (e) {
    yield put(failedAuthAction());
  }
}

function* logoutSaga() {
  try {
    window.localStorage.removeItem('sign_redirect_url');
    auth.setSignRedirectURL(history.location.pathname);
    yield call(() => auth.logout());
    yield put(successAuthAction());
  } catch (e) {
    yield put(failedAuthAction());
  }
}

function* authSaga() {
  yield takeLatest(loginAction.type, loginSaga);
  yield takeLatest(registerAction.type, registerSaga);
  yield takeLatest(logoutAction.type, logoutSaga);
}

export default authSaga;
