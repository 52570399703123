import { call, put, takeLatest } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import {
  failedGetScrimDetail,
  failedGetScrimPlayers,
  FirstTeamPlayer,
  getScrimPlayers,
  ScrimDetailResponse,
  successGetScrimDetail,
  successGetScrimPlayers,
} from './ScrimDetailReducer';
import { getScrimDetail } from './ScrimDetailReducer';
import { getScrimDetailService, getScrimPlayersService } from './ScrimService';

interface Res<T> {
  data: T;
  status: number;
}

function* fetchScrimPlayersSaga(action: AnyAction) {
  try {
    const res: Res<Array<FirstTeamPlayer>> = yield call(getScrimPlayersService, action.payload);
    if (res.status !== 200) {
      throw res;
    }
    yield put(successGetScrimPlayers(res.data));
  } catch (e) {
    yield put(failedGetScrimPlayers({ error: 'Error Pak eko' }));
  }
}

function* fetchScrimDetailSaga(action: AnyAction) {
  try {
    const res: Res<ScrimDetailResponse> = yield call(getScrimDetailService, action.payload);
    if (res.status !== 200) {
      throw res;
    }
    yield put(successGetScrimDetail(res.data));
  } catch (e) {
    yield put(failedGetScrimDetail({ error: 'Error Pak eko' }));
  }
}

function* scrimDetailSaga() {
  yield takeLatest(getScrimPlayers.type, fetchScrimPlayersSaga);
  yield takeLatest(getScrimDetail.type, fetchScrimDetailSaga);
}

export default scrimDetailSaga;
