import styled, { css, keyframes } from 'styled-components';
import { GButtonProps } from 'components/Buttons/GradientButton';

const gradientRotate = keyframes`
  0% {
    background-position: 0% 0%;
 }
  100% {
    background-position: 100% 100%;
 }
 400% {
    background-position: 100% 100%;
 }
`;

export const GButton = styled.button<GButtonProps>`
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 140%;
  font-weight: 700;
  text-transform: normal;
  text-align: center;
  border: none;
  border-radius: 10px;
  align-items: center;
  outline: none;
  box-shadow: -6px 6px 25px rgba(213, 31, 64, 0.22);
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transition-property: background, color;
  margin-right: 6px;
  margin-top: 6px;
  ${props =>
    props.variant === 'primary' &&
    css`
      position: relative;
      background-color: #F3AA36;
      background-size: 400%;
      background-position: 0% 0%;
      box-shadow: -6px 6px 25px rgba(213, 31, 64, 0.22);
      border: none;
      color: #17181A;
      &:hover {
        background-size: 100%;
        background-position: 0% 0%;
        animation-name: ${gradientRotate};
        animation-duration: 2s;
        animation-iteration-count: 2;
      }
      &:hover::before {
        background-size: 500%;
        background-position: 0% 0%;
        opacity: 1;
        animation-name: ${gradientRotate};
        animation-duration: 2s;
        animation-iteration-count: 2;
      }
      &:active {
        color: white;
        background-color: #F3AA36;
      }
      &:focus::before {
        opacity: 1;
      }
      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: -2px;
        right: -2px;
        bottom: -2px;
        border-radius: 10px;
        background-color: #F3AA36;
        background-size: 500%;
        background-position: 0% -0%;
        box-shadow: -6px 6px 25px rgba(213, 31, 64, 0.22);
        filter: blur(10px);
        opacity: 0;
        z-index: -1;
        transition: opacity 0.2s;
      }
    `};
  ${props =>
    props.variant === 'secondary' &&
    css`
      background: rgba(255, 255, 255, 0.1);
      color: white;
      margin-left: 6px;
      margin-right: 12px;
      &:active {
        opacity: 0.7;
      }
    `};
  ${props =>
    props.variant === 'cta' &&
    css`
      color: #F4B043;
      display: inline;
      box-shadow: none;
      background: transparent;
      margin-left: 6px;
      margin-right: 12px;
      &:active {
        opacity: 0.7;
      }
    `};
  ${props =>
    props.variant === 'flying' &&
    css`
      display: inline;
      color: white;
      font-size: x-large;
      font-weight: 700;
      border-radius: ${props.radius ?? '60%'};
      background: linear-gradient(
        131.42deg,
        #d51f40 46.48%,
        #e24134 62.51%,
        #e8542e 74.36%,
        #f37024 83.78%
      );
      box-shadow: -6px 6px 25px rgba(213, 31, 64, 0.22);
      &:active {
        opacity: 0.7;
      }
    `};
  ${props =>
    props.variant === 'whatsapp' &&
    css`
      background: #25d366;
      align-items: center;
      justify-content: center;
      align-content: center;
      color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      &:active {
        opacity: 0.7;
      }
    `};
  ${props =>
    props.variant === 'discord' &&
    css`
      background: #7289da;
      align-items: center;
      justify-content: center;
      align-content: center;
      color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      &:active {
        opacity: 0.7;
      }
    `};
  ${props =>
    props.variant === 'disabled' &&
    css`
      box-shadow: none;
      color: white;
      background: #6A6363;
      cursor: not-allowed;
      margin-left: 6px;
      margin-right: 12px;
    `};
  margin: ${props => props.margin ?? '0px 6px 0px 6px'};
  padding: ${props => props.padding ?? '12px 12px'};
  height: ${props => props.height ?? 'auto'};
  width: ${props => props.width ?? 'auto'};
  ${props =>
    props.backgroundSize !== undefined &&
    css`
      background-size: ${props.backgroundSize};
    `}
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
