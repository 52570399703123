import { NavBarHeight } from '../../modules/config';
import styled from 'styled-components';

interface ImageProps {
  opacity?: string | undefined;
  padding?: string | undefined;
  margin?: string | undefined;
  mask?: string | undefined;
  objectFit?: string;
  minHeight?: number;
  boxShadow?: string;
  top?: string | undefined;
}

export const ImageBackground = styled.img<ImageProps>`
  -webkit-touch-callout: none !important;
  alt: 'Image Masking';
  opacity: ${p => p.opacity ?? '0.33'};
  position: absolute;
  padding: ${p => p.padding};
  margin: ${p => p.margin};
  top: ${p => p.top ?? NavBarHeight + 'px'};
  z-index: -999;
  background: linear-gradient(
    rgba(19, 19, 24, 1),
    rgba(19, 19, 24, 0.973),
    rgba(19, 19, 24, 0.9344),
    rgba(19, 19, 24, 0.8928),
    rgba(19, 19, 24, 0.5)
  );
  object-fit: ${p => p.objectFit ?? 'cover'};
  width: 100%;
  height: 100%;
  min-height: ${p => p.minHeight + 'px'};
  box-shadow: ${p => p.boxShadow};
  mask: ${p => p.mask};
  -webkit-mask: ${p => p.mask};
  -webkit-mask-image: ${p => p.mask};
  mask-image: ${p => p.mask};
  @media only screen and (min-width: 480px) {
    max-width: 500px;
  }
  @media only screen and (min-height: 480px) and (max-height: 599px) {
    min-height: ${p => p.minHeight!! - 124 + 'px'};
  }
  @media only screen and (min-height: 600px) and (max-height: 799px) {
    min-height: ${p => p.minHeight!! - 74 + 'px'};
  }
  @media only screen and (min-height: 800px) and (max-height: 960px) {
    min-height: ${p => p.minHeight!! - 18 + 'px'};
  }
  @media only screen and (min-height: 961px) and (max-height: 1024px) {
    min-height: ${p => p.minHeight!! + 25 + 'px'};
  }
  -webkit-user-drag: none;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
`;

export const ImageMasking = styled.img<ImageProps>`
  alt: 'Image Masking';
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${p => p.padding};
  margin: ${p => p.margin};
  z-index: -1;
  -webkit-mask-image: ${p =>
    p.mask
      ? p.mask
      : 'linear-gradient(to bottom, transparent 1%, #00000088 100%, transparent 10%)'};
  mask-image: ${p =>
    p.mask
      ? p.mask
      : 'linear-gradient(to bottom, transparent 1%, #00000088 100%, transparent 10%)'};
`;

export const ImageContainer = styled.div<ImageProps>`
  display: flex;
  position: relative;
  width: 100%;
  padding: ${p => p.padding};
  margin: ${p => p.margin};
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: #00000055;
  -webkit-touch-callout: none !important;
`;

export const ImageContent = styled.div<ImageProps>`
  position: relative;
  height: 100%;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  padding: ${p => p.padding};
  margin: ${p => p.margin};
  flex-direction: column;
`;

export const ImageBGCard = styled.img`
  -webkit-touch-callout: none !important;
  alt: 'Image Background';
  border: none;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  z-index: 0;
  -webkit-mask-image: linear-gradient(to bottom, #000000 100%, transparent 100%);
  mask-image: linear-gradient(to bottom, #000000 20%, transparent 100%, transparent 100%);
`;
