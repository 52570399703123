import CardFeedTournament from './CardFeedTournament';
import { Box } from '@mui/material';
import { Text } from 'design/Text';
import moment from 'moment';
import React, { memo } from 'react';

interface FeedTournamentProps {
  dataFeed?: any;
  handleButtonRoster?: any;
  handleTournamentParticipantRank?: any;
}

const FeedTournament = ({
  dataFeed,
  handleButtonRoster,
  handleTournamentParticipantRank,
}: FeedTournamentProps) => {
  return (
    <>
      {dataFeed?.map((season: any) => {
        return (
          <Box sx={{ pl: '16px', mb: '13px', ':last-child': { mb: '0' } }}>
            <Box sx={{ p: '24px 16px 15px 0' }}>
              <Text variant="h3" sx={{ mb: '4px' }}>
                {season.title}
              </Text>
              <Text
                variant="bodySmall"
                sx={{
                  fontSize: '10px',
                  lineHeight: '14px',
                  letterSpacing: '0.16px',
                  color: 'neutral400',
                }}
              >
                {`${moment(season?.splitStart).format('D MMMM YYYY')} - ${moment(
                  season?.splitEnd,
                ).format('D MMMM YYYY')}`}
              </Text>
            </Box>
            <Box
              sx={theme => ({
                p: '12px 0 4px 0',
                borderTop: `1px solid ${theme.palette.neutral600}`,
                borderBottom: `1px solid ${theme.palette.neutral600}`,
              })}
            >
              <CardFeedTournament
                tournamentList={season.data ?? []}
                handleButtonRoster={handleButtonRoster}
                handleTournamentParticipantRank={handleTournamentParticipantRank}
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default memo(FeedTournament);
