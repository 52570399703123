import { NavBarHeight } from '../../modules/config';
import Modal from './Modal';
import { BottomNavMenu, INavMenuItem } from 'components/BottomNavMenu';
import { HeaderNavigation, HeaderOnlyLogoNavigation, HeaderNavProps } from 'components/Headers';
import SearchBox, { InputSearchProps } from 'components/Inputs/SearchBox';
import { SearchContainer } from 'components/Inputs/styles';
import SEO from 'components/Layout/SEO';
import TabsLayout, { ITabsItemProps } from 'components/TabsLayout';
import { ImageBackground } from 'components/styled/image.styled';
import { Layout, Stack, Box, AppContainer } from 'components/styled/layout.styled';
import { LayoutContext } from 'context/LayoutContext';
import useWindowDimensions from 'modules/dimention';
import history from 'modules/history';
import React, { FC, ReactNode, useLayoutEffect, useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container } from 'styled-minimal/lib';

export interface AppLayoutType {
  appBar?: HeaderNavProps | undefined;
  searchBar?: InputSearchProps | undefined;
  tabMenu?: ITabsItemProps[];
  backgroundImage?: string | undefined;
  backgroundMask?: string;
  bottomMenuItems?: INavMenuItem[];
  showBottomMenu?: boolean | undefined;
  isAuth?: boolean | undefined;
  opacityBg?: string;
  pageTitle?: string;
  showModal?: boolean;
  noMargin?: boolean | undefined;
  modalContent?: ReactNode;
  modalStyled?: React.CSSProperties;
  childrens?: ReactNode;
  'data-testid'?: string;
  background?: string;
}

const AppLayout: FC<AppLayoutType> = props => {
  const { height: deviceHeight } = useWindowDimensions();
  const getCurrentUrl = history.location.pathname;
  const appContentRef = React.createRef<HTMLDivElement>();
  const [dataNavMenu] = useState<INavMenuItem[]>(props.bottomMenuItems!!);
  const [dataTabMenu] = useState<INavMenuItem[]>(props.tabMenu!!);
  const [contentHeight, setContentHeight] = useState(0);
  const isHaveTab = props.tabMenu !== undefined;
  const isHaveSearchBar = props.searchBar !== undefined;
  useLayoutEffect(() => {
    if (appContentRef.current) {
      setContentHeight(appContentRef.current.clientHeight);
    }
  }, [appContentRef.current, contentHeight]);

  const { value, setValue } = useContext(LayoutContext);

  useEffect(() => {
    if (value) {
      setValue({
        show: false,
      });
    }
  }, []);

  return (
    <Layout data-testid={props['data-testid']}>
      <SEO title={`${props.pageTitle ?? ''} ${process.env.APP_NAME}`} />
      <Router>
        <Stack direction="column" align="center">
          <AppContainer
            direction="column"
            style={{
              maxWidth: '500px',
              minHeight: `${deviceHeight}px`,
              background: props.background,
            }}
          >
            <Box
              position="relative"
              width="100%"
              alignSelf="center"
              shadow="0px 0px 0px -2px rgb(0 0 0 / 75%)"
              maxWidth={'500px'}
            >
              {props.backgroundImage && (
                <ImageBackground
                  src={props.backgroundImage}
                  opacity={props.opacityBg ?? '0.05'}
                  width="100%"
                  objectFit="cover"
                  mask={props.backgroundMask}
                  minHeight={
                    contentHeight === 0
                      ? deviceHeight - NavBarHeight
                      : contentHeight +
                        (isHaveTab || isHaveSearchBar
                          ? 0
                          : NavBarHeight + (contentHeight > 780 ? 38 : 150))
                  }
                  top={
                    isHaveTab || isHaveSearchBar
                      ? `${NavBarHeight + (isHaveSearchBar ? 76 : 52)}px`
                      : `${NavBarHeight}px`
                  }
                />
              )}
              {props.children ? (
                <Box
                  ref={appContentRef}
                  width="100%"
                  margin={
                    props.noMargin
                      ? `${NavBarHeight}px 0px 0px 0px`
                      : `${
                          isHaveTab || isHaveSearchBar
                            ? NavBarHeight + (isHaveSearchBar ? 78 : 54)
                            : NavBarHeight
                        }px 16px ${
                          isHaveTab || isHaveSearchBar ? (deviceHeight < 812 ? -50 : 14) : 0
                        }px 16px`
                  }
                  minHeight={`${deviceHeight > 1023 ? deviceHeight : deviceHeight * 0.7}px`}
                >
                  <Stack id="appcontent" direction="column">
                    {props.children}
                  </Stack>
                </Box>
              ) : undefined}
            </Box>
            {isHaveTab && (
              <TabsLayout
                container={{
                  background: 'rgba(55, 57, 66, 0.94)',
                  width: '100%',
                  height: '5px',
                  position: 'fixed',
                  css: {
                    top: `${NavBarHeight - 20.2}px`,
                  },
                }}
                link={dataTabMenu}
                tabs={{
                  bRadius: '8px',
                  background: 'rgba(55, 57, 66, 0.4)',
                }}
              />
            )}
            {props.isAuth || props.isAuth === undefined ? (
              <HeaderNavigation
                bg={
                  {
                    bRadius: props.appBar?.bg.bRadius ?? '0px',
                    background: props.appBar?.bg.background ?? 'rgba(36, 39, 49, 0.94)',
                    boxShadow: props.appBar?.bg.boxShadow ?? 'none',
                    height: props.appBar?.bg.height ?? '56px',
                  } ?? props.appBar?.bg
                }
                navleft={
                  {
                    idNav: props.appBar?.navleft?.idNav,
                    bg: props.appBar?.navleft.bg ?? 'mabar-typeface-white',
                    width: props.appBar?.navleft.width ?? '100px',
                    height: props.appBar?.navleft.height ?? '24px',
                    onClick: props.appBar?.navleft.onClick,
                  } ?? props.appBar?.navleft
                }
                navright={
                  {
                    idNav: props.appBar?.navright.idNav,
                    variant: props.appBar?.navright.variant ?? 'notifications',
                    bg: props.appBar?.navright.bg ?? 'filled',
                    children: props.appBar?.navright.children,
                    onClick: props.appBar?.navright.onClick,
                    width: props.appBar?.navright.width ?? '24px',
                    height: props.appBar?.navright.height ?? '24px',
                  } ?? props.appBar?.navright
                }
                title={props.appBar?.title}
                isDisable={props.appBar?.isDisable}
              />
            ) : (
              <HeaderOnlyLogoNavigation
                bg={
                  {
                    bRadius: props.appBar?.bg.bRadius ?? '0px',
                    background: props.appBar?.bg.background ?? 'rgba(36, 39, 49, 0.94)',
                    boxShadow: props.appBar?.bg.boxShadow ?? 'none',
                    height: props.appBar?.bg.height ?? '56px',
                  } ?? props.appBar?.bg
                }
                navleft={
                  {
                    idNav: props.appBar?.navleft?.idNav,
                    bg: props.appBar?.navleft.bg ?? 'mabar-typeface-white',
                    width: props.appBar?.navleft.width ?? '100px',
                    height: props.appBar?.navleft.height ?? '24px',
                    onClick: props.appBar?.navleft.onClick,
                  } ?? props.appBar?.navleft
                }
                navright={
                  {
                    idNav: props.appBar?.navright.idNav,
                    variant: 'share-social',
                    bg: props.appBar?.navright.bg ?? 'filled',
                    children: props.appBar?.navright.children,
                    onClick: props.appBar?.navright.onClick,
                  } ?? props.appBar?.navright
                }
                title={props.appBar?.title}
                isDisable={props.appBar?.isDisable}
              />
            )}

            {props.searchBar !== undefined && (
              <SearchContainer margin="56px 0px 0px 0px">
                <SearchBox
                  id={props.searchBar.id}
                  placeholder={props.searchBar.placeholder}
                  onChange={props.searchBar.onChange}
                  onKeyDown={props.searchBar.onKeyDown}
                />
              </SearchContainer>
            )}
            {props.showBottomMenu && <BottomNavMenu />}
            <Modal show={props.showModal} styled={props.modalStyled}>
              {props.modalContent}
            </Modal>
            {props.childrens ? props.childrens : undefined}
            <Container>
              <Switch>
                {dataNavMenu !== undefined &&
                  dataNavMenu.map((data: INavMenuItem) => {
                    const isMatch = getCurrentUrl.split('/')[1] === data.dest?.split('/')[1]!!;

                    if (
                      data.dest === getCurrentUrl ||
                      isMatch ||
                      data.dest?.includes(history.location.pathname)
                    ) {
                      data.isSelected = true;
                      return (
                        <Route exact path={data.dest} component={data.component} key={data.dest} />
                      );
                    }
                    return <Route path={data.dest} component={data.component} key={data.dest} />;
                  })}
              </Switch>
            </Container>
          </AppContainer>
        </Stack>
      </Router>
    </Layout>
  );
};

export default AppLayout;
