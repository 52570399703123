import { Theme, Typography, TypographyProps } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import React from 'react';

export interface CustomTextProps extends TypographyProps {
  theme?: Theme | any;
  component?: string;
  gradient?: boolean | string;
  sx?: SxProps<Theme>;
}

const handleGradient = ({ theme, gradient }: CustomTextProps) => ({
  ...(gradient && typeof gradient === 'string' ? {
    backgroundImage: theme.palette[gradient!],
    backgroundClip: 'text',
    textFillColor: 'transparent',
  } : {
    color: theme.palette.primaryOrange700 // OG color RRQ Mabar
  })
});

const CustomText: any = styled(Typography, {
  name: 'text',
})(({ theme, variant, gradient }: CustomTextProps) => ({
  ...theme.typography[variant! || 'body'],
  textTransform: 'unset',
  cursor: 'inherit',
  ...(gradient && handleGradient({ theme, gradient })),
}));

export const Text = (props: CustomTextProps) => {
  const handleComponentFromVariant = () => {
    switch (props.variant) {
      case 'labelSmall':
        return 'label';
      case 'body':
        return 'p';
      case 'bodySmall':
        return 'p';
      case 'button':
        return 'span';
      case 'subtitle':
        return 'p';
      case 'ribbon':
        return 'span';
      default:
        return props.variant || 'p';
    }
  };
  return <CustomText component={props.component ?? handleComponentFromVariant()} {...props} />;
};
