import { Stack } from '@mui/material';
import { TextButton } from 'design/Buttons/TextButton';
import { CardParticipationFeed } from 'design/Cards/CardParticipationFeed';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { eventsType } from 'redux/tournament/TournamentProps';

interface CardFeedTournamentProps {
  tournamentList?: eventsType[];
  handleButtonRoster?: any;
  handleTournamentParticipantRank?: any;
}

const CardFeedTournament = ({
  tournamentList,
  handleButtonRoster,
  handleTournamentParticipantRank,
}: CardFeedTournamentProps) => {
  const { t } = useTranslation();

  return (
    <>
      {tournamentList?.map(tournament => (
        <CardParticipationFeed
          photo={tournament?.logoUrl}
          title={tournament.eventName}
          description={
            <Stack direction="row" alignItems="center">
              <Text
                variant="bodySmall"
                sx={{
                  color: handleTournamentParticipantRank(tournament?.rank)
                    ? '#CB7D3E'
                    : 'neutral400',
                  mr: '6px',
                }}
              >
                {tournament.rank == '' || tournament.rank == undefined
                  ? 'Partisipan'
                  : handleTournamentParticipantRank(tournament?.rank)}
              </Text>
              <Text variant="bodySmall" sx={{ fontWeight: '600', color: 'neutral400', mr: '6px' }}>
                •
              </Text>
              <Text variant="bodySmall" sx={{ fontWeight: '600', color: 'neutral400' }}>
                {tournament.teamName}
              </Text>
            </Stack>
          }
          cta={
            <TextButton
              icon={IconSource('ic-tim-outline')}
              sx={{
                svg: { mr: '4px' },
              }}
              onClick={() => handleButtonRoster(tournament)}
            >
              {t('participant_tournament_see_roster')}
            </TextButton>
          }
        />
      ))}
    </>
  );
};

export default memo(CardFeedTournament);
