import React, { useState } from 'react';
import Input from 'components/Inputs/Input';
import SelectOption, { IOptions } from 'components/Inputs/SelectOption';
import AppLayout from 'components/layouts/AppLayout';
import { ImageSource } from 'modules/assetpath';
import AddImage from 'components/Forms/AddImage';
import { Stack } from 'components/styled/layout.styled';
import { useForm, Controller, useWatch } from 'react-hook-form';
import {
  addNewGameInformation,
  fetchGameAvailableList,
  getPlayerProfileFromSession,
} from 'redux/account/AccountServices';
import { AccountValidationProblemDetails } from 'redux/account/AccountResponse';
import { useAppThunkDispatch } from 'redux/store';
import { useAppSelector } from 'redux/hooks';
import history from 'modules/history';
import { useTranslation } from 'react-i18next';
import { FILE_TYPE_ALLOWED } from 'store/constants/uploadImage';

const handleClickBack = () => {
  history.push('/account');
};

const GameRegistration = () => {
  const { t } = useTranslation();
  const submitRef = React.createRef<HTMLInputElement>();
  const { register, handleSubmit, control } = useForm();
  const dispatch = useAppThunkDispatch();
  const states = useAppSelector(state => state.account);
  const playerInfo = getPlayerProfileFromSession();
  const [getGameList, setGameList] = useState<IOptions[]>([]);
  const [getValidationErrors, setValidationErrors] = useState<AccountValidationProblemDetails>();
  const watchFields = useWatch({ control: control });
  const isFormComplete =
    watchFields['gameId'] &&
    watchFields['gameProviderId'] &&
    watchFields['nickName'] &&
    watchFields['nickNameFile'];
  React.useEffect(() => {
    const thunk = fetchGameAvailableList({
      playerSlug: playerInfo?.userName,
      pageIndex: 1,
      pageSize: 20,
    });
    dispatch(thunk)
      .unwrap()
      .then(data => {
        data.items &&
          setGameList(
            data.items.map(g => {
              return { label: g.name, value: g.id } as IOptions;
            }),
          );
      });
  }, [dispatch]);

  async function saveDataGame(data: any) {
    const thunk = addNewGameInformation({
      userId: playerInfo?.id ?? '',
      gameId: data.gameId,
      gameProviderId: data.gameProviderId,
      nickName: data.nickName,
      nickNameFile: data.nickNameFile,
    });
    dispatch(thunk)
      .unwrap()
      .then(res => {
        if (res && res.data?.status >= 400) {
          setValidationErrors((states?.errors as AccountValidationProblemDetails) ?? {});
        } else {
          setValidationErrors({});
          setTimeout(() => {
            history.push('/account');
          }, 150);
        }
      });
  }
  return (
    <AppLayout
      showBottomMenu
      appBar={{
        bg: {},
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
          onClick: handleClickBack,
        },
        navright: {
          bg: isFormComplete
            ? 'linear-gradient(131.42deg, #d51f40 46.48%, #e24134 62.51%, #e8542e 74.36%, #f37024 83.78%)'
            : '#969393',
          children: t('player_account_add_game_button_next'),
          variant: 'button',
          onClick: () => submitRef.current?.click(),
        },
        isDisable: false,
        title: t('player_account_add_game_header_title'),
      }}
      backgroundImage={ImageSource('app.background')}
      pageTitle={t('player_account_add_game_header_title')}
    >
      <Stack direction="column" margin="16px 0px 0px 0px">
        <form onSubmit={handleSubmit(saveDataGame)} noValidate>
          <Controller
            control={control}
            name="gameId"
            defaultValue={getGameList.map(c => c.value)}
            render={({ field: { onChange, ref } }) => (
              <SelectOption
                onItemChange={(val: any) => onChange(val.value)}
                option={getGameList}
                label={t('player_account_add_game_select_game')}
                placeholder={t('player_account_add_game_select_game_placeholder')}
                noOptionsMsg="Tidak ada Game"
                inputRef={ref}
                isError={getValidationErrors?.errors?.game_GameId ? true : false}
                errorMessage="Silahkan pilih Game!"
              />
            )}
          />
          <Input
            placeholder="000292771"
            label="Game ID"
            margin="16px 0px 0px 0px"
            type="number"
            inputMode="numeric"
            validationType={getValidationErrors?.errors?.game_GameProviderId ? 'ERROR' : undefined}
            validationText={
              getValidationErrors?.errors?.game_GameProviderId
                ? getValidationErrors?.errors?.game_GameProviderId[0].message
                : undefined
            }
            isRequired
            registerForm={register('gameProviderId')}
          />
          <Input
            placeholder={t('player_account_add_game_nickname_game_placeholder')}
            label={t('player_account_add_game_nickname_game')}
            margin="16px 0px 16px 0px"
            type="text"
            inputMode="text"
            validationType={getValidationErrors?.errors?.game_NickName ? 'ERROR' : undefined}
            validationText={
              getValidationErrors?.errors?.game_NickName
                ? getValidationErrors?.errors?.game_NickName[0].message
                : undefined
            }
            isRequired
            registerForm={register('nickName')}
          />
          <Controller
            control={control}
            name="nickNameFile"
            render={({ field: { onChange, ref } }) => (
              <AddImage
                {...{
                  variant: 'Box',
                  bgColor: 'transparent',
                  iconOptions: {
                    background: 'rgba(55, 57, 66, 0.5)',
                    backgroundRadius: '16px',
                    backgroundWidth: '100%',
                    backgroundHeight: '100%',
                    iconSrc: 'add-photo',
                    iconPosition: 'center',
                    iconMargin: '0px 0px 8px 0px',
                    iconHeight: '18px',
                    iconWidth: '22.5px',
                  },
                  placeholder: t('player_create_team_add_game_screenshoot_profil_id'),
                  placeholderStyle: {
                    color: getValidationErrors?.errors?.game_NickNameFile ? '#FF4D6D' : '#969393',
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '17.52px',
                    textAlign: 'center',
                  },
                  widthMatchParent: true,
                  height: 175,
                  isEffect: false,
                  isRequired: true,
                  imageAccept: FILE_TYPE_ALLOWED,
                  getImageBlob: val => onChange(val),
                  inputRef: ref,
                }}
              />
            )}
          />
          <input ref={submitRef} type="submit" hidden />
        </form>
      </Stack>
    </AppLayout>
  );
};

export default GameRegistration;
