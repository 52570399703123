import React from 'react';
import { TagProps } from './';
import { CustomTagTournamentCard } from './styles';
import { Text } from 'design/Text';

export const TagTournamentCard = ({ title, ...props }: TagProps) => {
  return (
    <CustomTagTournamentCard {...props}>
      <Text variant="ribbon">{title}</Text>
    </CustomTagTournamentCard>
  );
};
