import { Stack } from '@mui/material';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { TextButton } from 'design/Buttons/TextButton';
import { CardTournamentParticipation } from 'design/Cards/CardTournamentParticipation';
import _ from 'lodash';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { eventsType } from 'redux/tournament/TournamentProps';

interface TournamentSectionProps {
  setIsOpenDrawerRoster: (value: boolean) => void;
  setDataDrawerRoster: (value: eventsType) => void;
}

const TournamentSection = ({
  setIsOpenDrawerRoster,
  setDataDrawerRoster,
}: TournamentSectionProps) => {
  const { tournamentParticipantTeam, teamDetail } = useAppSelector(
    ({ tournament, teamProfile }) => ({
      tournamentParticipantTeam: tournament?.tournamentParticipantTeam?.events,
      teamDetail: teamProfile?.teamDetail,
    }),
  );

  const handleButtonRoster = (tournament: eventsType) => {
    setDataDrawerRoster({
      ...tournament,
      ...{ teamAvatarUrl: tournament?.teamAvatarUrl ?? teamDetail?.avatarUrl, type: 'tournament' },
    });
    setIsOpenDrawerRoster(true);
  };

  const handleTournamentParticipantRank = (rank: String) => {
    switch (rank) {
      case '1':
        return 'Champion';
      case '2':
        return '2nd Place';
      case '3':
        return '3rd Place';
      default:
        return 'Playoff';
    }
  };

  return (
    <Stack spacing="16px">
      {tournamentParticipantTeam?.map((tournament, idx) => {
        return idx < 3 ? (
          <CardTournamentParticipation
            key={idx}
            title={tournament?.eventName}
            description={`${tournament?.season} • ${
              tournament?.split
            } • ${handleTournamentParticipantRank(tournament?.rank)}`}
            cta={
              <TextButton
                icon={IconSource('ic-tim-outline')}
                sx={{
                  svg: { mr: '4px' },
                }}
                onClick={() => handleButtonRoster(tournament)}
              >
                Lihat Roster
              </TextButton>
            }
            sx={{ height: 'auto' }}
          />
        ) : null;
      })}

      {(tournamentParticipantTeam ?? []).length > 2 ? (
        <Link to={`${history.location.pathname}/tournaments`}>
          <SecondaryButton variant="small" wide>
            Lihat Semua
          </SecondaryButton>
        </Link>
      ) : null}
    </Stack>
  );
};

export default memo(TournamentSection);
