import React, { FC, ReactNode } from 'react';
import {
  Card,
  HeaderCard,
  HeaderTitle,
} from './styles';
import BackgroundImage from 'components/layouts/BackgroundImage';
import { Box, Stack } from 'components/styled/layout.styled';
import { GradientText, SpanText } from 'components/styled/text.styled';
import { Avatar } from 'components/styled/avatar.styled';
import { IconSource } from 'modules/assetpath';
export interface ScrimWinLoseProps {
  text?: string;
  header?: string;
  team1?: string;
  team2?: string;
  score?: ReactNode;
  score1: ReactNode;
  picture1?: string;
  picture2?: string;
  src?: string;
  minHeight?: string;
  onClick?: ((match: ScrimWinLoseProps) => void) | undefined;
  bg?: string;
  border?: '4px solid rgba(213, 31, 64, 0.3)' | '0' | '4px solid rgba(231, 180, 80, 0.3)';
}

// | 'linear-gradient(0deg, rgba(0, 120, 41, 0.3), rgba(0, 120, 41, 0.3))'
// | 'linear-gradient(0deg, rgba(228, 60, 90, 0.3), rgba(228, 60, 90, 0.3))';
export const CardScrimWinLose: FC<ScrimWinLoseProps> = ({
  onClick,
  score,
  header,
  team1,
  team2,
  score1,
  picture1,
  picture2,
  bg,
  src,
  minHeight,
}) => (
  <Box width="100%">
    <Stack direction="column" align="center">
      <Card>
        <HeaderCard bg={bg} score1={score1}>
          <HeaderTitle>{header}</HeaderTitle>
        </HeaderCard>
        <Stack direction='row' align='evenly' margin='24px 0px 14px' disableDrag disableSelectText>
          <Stack direction='row' align='center' disableDrag disableSelectText>
            <SpanText fontSize='14px' color='white' fontStyle='normal' fontWeight='700' textAlign='end' maxLines={4} maxWidth='85px' wordWrap='break-word'>
              {team1 ?? '-'}
            </SpanText>
            {team1 && (
              <Avatar border='2px solid #F1EBEB' width='20px' height='20px' borderRadius='12px' margin='0px 0px 0px 10px'
                background={picture1 ? 'transparent' : '#B1B1B1'} fit="contain" objectPos={picture1 ? 'center' : 'bottom'}
                src={picture1 ?? IconSource('union-placeholder')} onError={(e) => {
                  e.currentTarget.src = IconSource('union-placeholder')
                  e.currentTarget.style.objectPosition = 'bottom'
                }} />
            )}
          </Stack>
          <Box background='rgba(213, 31, 64, 0.12)' borderRadius='5px' padding='5.5px 8px' alignSelf='center'>
            <GradientText fontSize='14px' fontWeight='bold' textAlign='center' lineHeight='19.6px'
              color='linear-gradient(131.42deg, #D51F40 46.48%, #E24134 62.51%, #E8542E 74.36%, #F37024 83.78%)'>
              VS
            </GradientText>
          </Box>
          <Stack direction='row' align='center' disableDrag disableSelectText>
            {team2 && (
              <Avatar border='2px solid #F1EBEB' width='20px' height='20px' borderRadius='12px' margin='0px 10px 0px 0px'
                background={picture2 ? 'transparent' : '#B1B1B1'} fit="contain" objectPos={picture2 ? 'center' : 'bottom'}
                src={picture2 ?? IconSource('union-placeholder')} onError={(e) => {
                  e.currentTarget.src = IconSource('union-placeholder')
                  e.currentTarget.style.objectPosition = 'bottom'
                }} />
            )}
            <SpanText fontSize='14px' color='white' fontStyle='normal' fontWeight='700' textAlign='start' maxLines={4} maxWidth='85px' wordWrap='break-word'>
              {team2 ?? '-'}
            </SpanText>
          </Stack>
        </Stack>
        <Stack direction='row' align='between' margin='0px 0px 20px'>
          <Stack direction='row' align='center'>
            <SpanText fontSize='18px' fontWeight='700' lineHeight='25.2px' color='#DDD8D8'>
              {score}
            </SpanText>
          </Stack>
          <Stack direction='row' align='center'>
            <SpanText fontSize='18px' fontWeight='700' lineHeight='25.2px' color='#DDD8D8'>
              {score1}
            </SpanText>
          </Stack>
        </Stack>
        {src && (
          <Box margin='0px 16px 16px' isPressble={onClick ? true : false}
            onClick={() => {
              if (onClick) {
                onClick({
                  header: header,
                  score: score,
                  score1: score1,
                  team1: team1,
                  team2: team2,
                  picture1: picture1,
                  picture2: picture2,
                  bg: bg,
                  src: src,
                  onClick: onClick,
                });
              }
            }}
          >
            <BackgroundImage minHeight={minHeight} borderRadius="12px" src={src} />
          </Box>
        )}
      </Card>
    </Stack>
  </Box>
);

export default CardScrimWinLose;
