import { TeamSchoolPlayers } from './TeamResponse';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import { Constants } from 'services/Constants';
import { MyCurrentTeam } from 'types/team';

export async function getMyTeamService(p: {
  playerSlug: string;
  pageIndex: number;
  pageSize: number;
  search?: string;
  version?: string;
}) {
  const apiUrl = encodeURI(
    p.version
      ? `/v${p.version}/team/player/${p.playerSlug}/${p.pageIndex}/${p.pageSize}${
          p.search ? `?search=${p.search}` : ''
        }`
      : `/team/player/${p.playerSlug}/${p.pageIndex}/${p.pageSize}${
          p.search ? `?search=${p.search}` : ''
        }`,
  );
  return await ApiService.get(apiUrl);
}

export async function getTeamMemberApprovedService(p: {
  teamSlug: string;
  pageIndex: number;
  pageSize: number;
  version?: string;
}) {
  const apiUrl = encodeURI(
    p.version
      ? `/v${p.version}/team-member/approved/${p.teamSlug}/${p.pageIndex}/${p.pageSize}`
      : `/team-member/approved/${p.teamSlug}/${p.pageIndex}/${p.pageSize}`,
  );
  return await ApiService.get(apiUrl);
}

export async function getTeamInvitationService(p: {
  playerSlug: string;
  pageIndex: number;
  pageSize: number;
  version?: string;
}) {
  const apiUrl = encodeURI(
    p.version
      ? `/v${p.version}/team/invitation/${p.playerSlug}/${p.pageIndex}/${p.pageSize}`
      : `/team/invitation/${p.playerSlug}/${p.pageIndex}/${p.pageSize}`,
  );
  return await ApiService.get(apiUrl);
}

export async function getTeamDetailService(p: { teamSlug: string; version?: string }) {
  const apiUrl = encodeURI(p.version ? `/v${p.version}/team/${p.teamSlug}` : `/team/${p.teamSlug}`);
  return await ApiService.get(apiUrl);
}

export async function updateTeamAvatar(p: { Id: string; AvatarFile: Blob; version?: string }) {
  const apiUrl = encodeURI(
    p.version ? `/v${p.version}/team/avatar/${p.Id}` : `/team/avatar/${p.Id}`,
  );
  const body = new FormData();
  body.append('Id', p.Id);
  body.append('AvatarFile', p.AvatarFile);
  const res = await ApiService.put(apiUrl, body);
  return res.data;
}

export function getMyCurrentTeamFromSession(): MyCurrentTeam[] {
  let result: MyCurrentTeam[] = [];
  const sessionStorage = Constants.encryptStorage.getItem('current_team');
  if (sessionStorage !== 'undefined' && sessionStorage !== undefined) {
    var obj = JSON.parse(sessionStorage);
    var myCurrentTeam: MyCurrentTeam[] = [];
    if (Array.isArray(obj)) {
      obj.map(v => {
        myCurrentTeam.push(v);
      });
    } else {
      myCurrentTeam.push(JSON.parse(obj));
    }
    result = myCurrentTeam;
  }
  return result;
}

export async function getTeamSchoolListService(p: {
  idSchool: string;
  pageIndex: number;
  pageSize: number;
  search?: string;
  version?: string;
}) {
  const apiUrl = encodeURI(
    p.version
      ? `/v${p.version}/team/school/${p.idSchool}/${p.pageIndex}/${p.pageSize}${
          p.search ? `?search=${p.search}` : ''
        }`
      : `/team/school/${p.idSchool}/${p.pageIndex}/${p.pageSize}${
          p.search ? `?search=${p.search}` : ''
        }`,
  );
  return await ApiService.get(apiUrl);
}

export const fetchPlayerSchool = createAsyncThunk(
  'team/player-in-school',
  async ({
    schoolId,
    gameId,
    pageIndex,
    pageSize,
    search,
    version,
  }: {
    schoolId: string;
    gameId: string;
    pageIndex: number;
    pageSize: number;
    search?: string;
    version?: string;
  }) => {
    try {
      const url = `${
        version ? `v${version}/` : '/'
      }user/player/in-school/${schoolId}/${pageIndex}/${pageSize}?gameId=${gameId}${
        search ? `&search=${search}` : ''
      }`;
      const res = await ApiService.get<TeamSchoolPlayers>(url);
      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, errors: err.response.data };
      } else {
        return err;
      }
    }
  },
);

export const fetchPlayerSchools = createAsyncThunk(
  'team/player-in-schools',
  async ({
    schoolId,
    gameId,
    pageIndex,
    pageSize,
    search,
    version,
  }: {
    schoolId: string;
    gameId: string;
    pageIndex: number;
    pageSize: number;
    search?: string;
    version?: string;
  }) => {
    try {
      const url = `${
        version ? `v${version}/` : '/'
      }user/player/in-school/${schoolId}/${pageIndex}/${pageSize}?gameId=${gameId}${
        search ? `&search=${search}` : ''
      }`;
      const res = await ApiService.get<TeamSchoolPlayers>(url);
      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, errors: err.response.data };
      } else {
        return err;
      }
    }
  },
);

export const fetchMyTeamList = createAsyncThunk(
  'myTeamList',
  async (p: {
    playerSlug: string;
    pageIndex: number;
    pageSize: number;
    search?: string;
    version?: string;
    token?: string;
  }) => {
    try {
      const apiUrl = encodeURI(
        p.version
          ? `/v${p.version}/team/player/${p.playerSlug}/${p.pageIndex}/${p.pageSize}${
              p.search ? `?search=${p.search}` : ''
            }`
          : `/team/player/${p.playerSlug}/${p.pageIndex}/${p.pageSize}${
              p.search ? `?search=${p.search}` : ''
            }`,
      );
      let config = {};
      if (p.token) {
        config = {
          Authorization: `Bearer ${p.token}`,
        };
      }
      return (await ApiService.get(apiUrl, { headers: config })).data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, error: error.response.data };
      } else {
        return { isAxiosError: true, error: error };
      }
    }
  },
);

export const postCheckTeamName = createAsyncThunk(
  'team/check-team-name',
  async ({ name }: { name: string; }) => {
    try {
      const url = `team/check-team-name`;
      const res = await ApiService.post(url, {name: name});

      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);