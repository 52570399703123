import { Divider, Stack } from '@mui/material';
import { TextButton } from 'design/Buttons/TextButton';
import { CardListParticipationAward } from 'design/Cards/CardListParticipationAward';
import { CardSection } from 'design/Cards/CardSection';
import { ImageSource } from 'modules/assetpath';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

interface AwardsProps {
  playerSlug?: string;
}

const Awards = ({ playerSlug }: AwardsProps) => {
  const { tournamentParticipantAwardPlayer } = useAppSelector(({ award }) => ({
    tournamentParticipantAwardPlayer: award.tournamentParticipantAwardsPlayer,
  }));

  return Object.keys(tournamentParticipantAwardPlayer ?? {}).length ? (
    <CardSection title="Awards" background={ImageSource('bg-awards-ornament', 'png')}>
      <>
        {tournamentParticipantAwardPlayer?.awards.map((award: any, idx: number) =>
          idx < 3 ? (
            <CardListParticipationAward
              photo={award.logoUrl}
              title={award.title}
              description={`${award.season} - ${award.awardsDate} • ${award.eventName}`}
              placeholderType="award"
            />
          ) : null,
        )}
        {(tournamentParticipantAwardPlayer?.awards ?? []).length > 3 ? (
          <>
            <Divider sx={{ mb: '16px', borderColor: 'neutral600' }} />
            <Stack alignItems="center">
              <Link to={`/player/${playerSlug}/awards`}>
                <TextButton>
                  Lihat Semua ({(tournamentParticipantAwardPlayer?.awards ?? []).length})
                </TextButton>
              </Link>
            </Stack>
          </>
        ) : null}
      </>
    </CardSection>
  ) : null;
};

export default memo(Awards);
