import RosterDrawer from './Drawer/RosterDrawer';
import AwardSection from './Section/AwardSection';
import CarouselHighlightedSection from './Section/CarouselHighlightedSection';
import TournamentParticipantSection from './Section/TournamentSection';
import { Box, Divider } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { CardEmptyParticipation } from 'design/Cards/CardEmptyParticipation';
import { CardSection } from 'design/Cards/CardSection';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { useState, useEffect, useCallback, memo } from 'react';
import { AwardsType } from 'redux/award/AwardProps';
import {
  fetchTournamentParticipantAwardsTeam,
  fetchTournamentParticipantAwardsTeamHighlighted,
} from 'redux/award/AwardService';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { fetchTournamentParticipantTeam } from 'redux/tournament/TournamentServices';

export interface DataDrawerRosterTypes {
  title: AwardsType['title'];
  eventName: AwardsType['eventName'];
  teamName: AwardsType['teamName'];
  schoolName: AwardsType['schoolName'];
  teamAvatarUrl: AwardsType['teamAvatarUrl'];
  teamProvinceName: AwardsType['teamProvinceName'];
  rosters: AwardsType['teamRoster'];
}

export interface AwardsProps {
  paramTeamSlug: string;
}

const Awards = ({ paramTeamSlug }: AwardsProps) => {
  const dispatch = useAppThunkDispatch();

  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [openDrawerRoster, setIsOpenDrawerRoster] = useState(false);
  const [dataDrawerRoster, setDataDrawerRoster] = useState<any>();

  const {
    playerInfo,
    teamDetail,
    awardTeamList,
    awardTeamListStatus,
    awardsTeamHighlighted,
    awardsTeamHighlightedStatus,
    tournamentParticipantTeam,
    tournamentParticipantTeamStatus,
  } = useAppSelector(({ account, teamProfile, tournament, award }) => ({
    playerInfo: account?.data,
    teamDetail: teamProfile?.teamDetail,
    awardsTeamHighlighted: award?.tournamentParticipantAwardsTeamHighlighted?.awards,
    awardsTeamHighlightedStatus: award?.status?.tournamentParticipantAwardsTeamHighlighted,
    awardTeamList: award?.tournamentParticipantAwardsTeam?.awards,
    awardTeamListStatus: award?.status?.tournamentParticipantAwardsTeam,
    tournamentParticipantTeam: tournament?.tournamentParticipantTeam?.events,
    tournamentParticipantTeamStatus: tournament?.status?.tournamentParticipantTeam,
  }));

  const handleFetchTournamentParticipantAwardsTeamHighlighted = (teamId: string) => {
    dispatch(fetchTournamentParticipantAwardsTeamHighlighted({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwardsTeam = (teamId: string) => {
    dispatch(fetchTournamentParticipantAwardsTeam({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantTeam = (teamId: string) => {
    dispatch(fetchTournamentParticipantTeam({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  // only fetch different latest parameter from teamDetail
  const handleFetchCallback = useCallback(
    (teamId?: string) => {
      if (teamId) {
        handleFetchTournamentParticipantAwardsTeamHighlighted(teamId);
        handleFetchTournamentParticipantAwardsTeam(teamId);
        handleFetchTournamentParticipantTeam(teamId);
      }
    },
    [teamDetail?.id],
  );

  useEffect(() => {
    // get lastest data from teamDetail loop from handleFetchCallback()
    if (paramTeamSlug === teamDetail?.urlSlug) {
      handleFetchCallback(teamDetail?.id);
    }
  }, [handleFetchCallback]);

  const handleOpenDrawerRoster = () => {
    setIsOpenDrawerRoster(!openDrawerRoster);
  };

  const handleLoading = (status: boolean) => {
    if (status)
      return !Boolean(tournamentParticipantTeam || awardTeamList || awardsTeamHighlighted);
    return false;
  };

  const isHaveAwardsection =
    (awardsTeamHighlighted ?? []).length > 1 && (awardTeamList ?? []).length > 1;

  const isHaveTournamentParticipant = (tournamentParticipantTeam ?? []).length > 1;

  return (
    <>
      {isHaveAwardsection ? (
        <CardSection
          title={
            <Text
              variant="label"
              component="h3"
              sx={{
                position: 'relative',
                px: '16px',
                textTransform: 'capitalize',
                textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                letterSpacing: '0',
                zIndex: 1,
              }}
            >
              Awards
            </Text>
          }
          sx={{
            position: 'relative',
            bgcolor: 'transparent',
            p: '24px 0',
          }}
        >
          <Box
            sx={{
              '&:before': {
                content: "''",
                position: 'absolute',
                inset: 0,
                width: '100%',
                height: '670px',
                maxHeight: 'calc(100vh - 210px)',
                background: 'radial-gradient(163.33% 97.31% at 0% 0%, #C79955 0%, #101216 100%)',
                zIndex: 0,
              },
              '&:after': {
                content: "''",
                position: 'absolute',
                inset: 0,
                width: '100%',
                height: '244px',
                background: `url(${IconSource('ic-awards-ornament-team')}) no-repeat`,
                zIndex: 0,
              },
            }}
          />
          <CarouselHighlightedSection />
          <AwardSection
            setIsOpenDrawerRoster={setIsOpenDrawerRoster}
            setDataDrawerRoster={setDataDrawerRoster}
          />
        </CardSection>
      ) : null}

      {isHaveAwardsection && isHaveTournamentParticipant ? (
        <Divider
          sx={theme => ({ position: 'relative', zIndex: 1, borderColor: theme.palette.neutral600 })}
        />
      ) : null}

      <CardSection
        title={
          <Text
            variant="label"
            component="h3"
            sx={{
              position: 'relative',
              textTransform: 'capitalize',
              textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
              letterSpacing: '0',
            }}
          >
            Partisipasi Turnamen
          </Text>
        }
        sx={{
          position: 'relative',
          bgcolor: 'transparent',
          py: '24px',
        }}
      >
        {isHaveTournamentParticipant ? (
          <TournamentParticipantSection
            setIsOpenDrawerRoster={setIsOpenDrawerRoster}
            setDataDrawerRoster={setDataDrawerRoster}
          />
        ) : (
          <CardEmptyParticipation title="Tim ini belum berpartisipasi di turnamen" />
        )}
      </CardSection>

      <RosterDrawer
        open={openDrawerRoster}
        onClose={handleOpenDrawerRoster}
        onOpen={handleOpenDrawerRoster}
        dataEvent={dataDrawerRoster}
        playerId={playerInfo?.id}
      />
      <LoadingModal
        show={
          error
            ? true
            : handleLoading(
                tournamentParticipantTeamStatus === 'loading' ||
                  awardTeamListStatus === 'loading' ||
                  awardsTeamHighlightedStatus === 'loading',
              )
        }
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default memo(Awards);
