import styled, { css } from 'styled-components';
import Select from 'react-select';
import { SelectOptionProps } from './index';

export const Label = styled.div<{ isFloating: boolean }>`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 146%;
  left: 12px;
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  color: #969393;
  top: ${props => (props.isFloating ? `12px` : `19.80%`)};
`;

export const SelectElement = styled(Select)<SelectOptionProps>`
  .react-select-container {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
  }

  .react-select__control {
    background: transparent;
    border-radius: 12px;
    margin: ${props => props.margin};
    width: ${props => props.width};   
    border: ${props => props.border};
    &:hover {
      box-shadow: none;
      border: 1px solid #f1ebeb;
    }
    &:focus-within {
      box-shadow: none;
      border: 1px solid #d51f40;
    }
  }

  .react-select__input {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: white;
  }

  .react-select__input-container {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 146%;
    color: white;
    padding-left: 2px;
    margin-top: 29px;
    padding-bottom:6px;
  }
  .react-select__placeholder {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 146%;
    color: rgba(66, 62, 62, 1);
    margin-top: 29px;
    padding-bottom:6px;

  }

  .react-select__control--is-focused {
    box-shadow: none;
    border: 1px solid #d51f40;
  }
  .react-select__single-value {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 146%;
    color: white;
    margin-top: 30px;
    margin-bottom: 6px;
    margin-left: 3px;
  }
  .react-select__indicator-separator {
    background-color: transparent;
  }

  .react-select__menu {
    color: white;
    border-radius: 12px;
    background: #373942;
  }

  .react-select__option {
    font-family: 'Roboto';
    border-bottom: 1px solid #423e3e;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 146%;
    border-radius: 12px;
    background: transparent;
    &:active {
      color: white;
      background: #d51f40;
    }
  }
  .react-select__option--is-focused {
    color: #ff4d6d;
    background: rgba(240, 105, 38, 0.0853);
    &:active {
      color: white;
      background: #d51f40;
    }
  }
  .react-select__menu-list {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #1e1e1e;
    }
    ::-webkit-scrollbar-thumb {
      background: linear-gradient(
        131.42deg,
        #e7557055 32.19%,
        #ed676a55 53.45%,
        #f1726755 66.7%,
        #fd965b55 83.78%
      );
      border-radius: 12px;
      opacity: 0.2;
    }
  }
  .react-select__indicators {
    color: transparent;
    margin-right: 7px;
  }
  .react-select__indicator {
    ${({indicatorIcon}) => indicatorIcon && css`    
      filter: invert(1);
      background: ${`${`url(${indicatorIcon})`} no-repeat center`};
      color: transparent;
    `}
  }
`;
