import BoxRegister from './BoxRegister';
import Details from './Details';
import TeamStatus from './TeamStatus';
import WinnerTeams from './WinnerTeams';
import { Box, Stack, Skeleton } from '@mui/material';
import StatusAnggotaTim from 'components/StatusAnggotaTim';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { AccountProfile } from 'redux/account/AccountResponse';
import { Game } from 'redux/account/AccountResponse';
import { MyTeamResponse } from 'redux/team/MyTeamReducer';
import {
  TournamentDetailResponse,
  MyTeamFromTournamentResponse,
} from 'redux/tournament/TournamentProps';
import { STATE, TEAM_STATE, TEAM_STATUS, PLAYER_STATE } from 'store/constants/tournament';

export interface AboutProps {
  isAuthenticated: boolean;
  tournamentDetail?: TournamentDetailResponse;
  myTeamFromTournament?: MyTeamFromTournamentResponse;
  playerInfo?: AccountProfile;
  myRole?: Game;
  myTeam?: MyTeamResponse['items'];
  gameSlug?: string;
  tournamentSlug?: string;
}
const AboutPage = ({
  isAuthenticated,
  tournamentDetail,
  myTeamFromTournament,
  playerInfo,
  myRole,
  myTeam,
  gameSlug,
  tournamentSlug,
}: AboutProps) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showBoxRegister, setShowBoxRegister] = useState(false);
  const handleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const myId = isAuthenticated ? playerInfo?.id : undefined;
  const isCaptain = isAuthenticated ? myRole?.team?.level?.value === 'Captain' : undefined;
  const haveTeam =
    isAuthenticated &&
    myTeam
      ?.map(my => my.status.value === 'Approved')
      .filter(Boolean)
      .toString();
  const teamsAccepted = tournamentDetail?.teams?.filter(
    team => team.status === TEAM_STATUS.ACCEPTED,
  );

  const myTeamId = isAuthenticated && myTeam ? myTeam[0].id : '';

  const isMyTeamInTournament = tournamentDetail?.teams?.filter(team => team.id === myTeamId)[0];

  const isTournamentSlotNotFull = (teamsAccepted?.length ?? 0) < (tournamentDetail?.maxTeam ?? 0);

  const isMyPlayerInvited =
    Object.keys(myTeamFromTournament ?? {}).length !== 0 && Object.keys(myTeam ?? {}).length !== 0;

  const isMyTeamRegistered =
    tournamentDetail?.teamState === TEAM_STATE.MEMBERS_INVITATION_STATED &&
    isMyPlayerInvited &&
    isTournamentSlotNotFull;

  const isMyTeamJoined = tournamentDetail?.teamState === TEAM_STATE.JOINED;

  const isMyTeamQueue = tournamentDetail?.teamState === TEAM_STATE.QUEUED;

  const isMyTeamRejected =
    tournamentDetail?.teamState === TEAM_STATE.DISQUALIFIED ||
    tournamentDetail?.teamState === TEAM_STATE.REJECTED;

  const isMyTeamPendingButSlotFull =
    tournamentDetail?.teamState === TEAM_STATE.MEMBERS_INVITATION_STATED &&
    !isTournamentSlotNotFull;

  const handleRejectTeam = () => {
    if (tournamentDetail?.state === STATE.REGISTRATION_CLOSED) {
      return isMyTeamInTournament?.status === TEAM_STATUS.PENDING;
    }
    return isMyTeamRejected || isMyTeamPendingButSlotFull;
  };

  const isMyTeamRejectedForRibbon = Boolean(isMyTeamInTournament) && handleRejectTeam();

  const statusBoxRegister =
    isMyTeamRegistered == false &&
    isMyTeamJoined == false &&
    isMyTeamRejected == false &&
    isMyTeamQueue == false &&
    tournamentDetail?.state === STATE.REGISTRATION_OPENED;


  const playerAccepted =
    myTeamFromTournament?.players?.filter(player => player.state == PLAYER_STATE.ACCEPTED) ?? [];

  const minRosterPerTeam = tournamentDetail?.minRosterPerTeam ? tournamentDetail?.minRosterPerTeam : 0;
  const calculatePlayerJoin = minRosterPerTeam - playerAccepted.length

  const handlePlayers =
    (tournamentDetail?.teamState === TEAM_STATE.JOINED &&
    tournamentDetail?.state != STATE.REGISTRATION_OPENED
      ? playerAccepted
      : myTeamFromTournament?.players) || [];

  const isNotRestrictedTeamIds = () => {
    if (tournamentDetail?.restrictedTeamIds) {
      const result = tournamentDetail?.restrictedTeamIds
        .map(restrictedTeam => restrictedTeam.id.includes(myTeamId!))
        .filter(Boolean)
        .toString();
      return !Boolean(result);
    }
    return true;
  };

  const isInvitedProvinceIds = () => {
    if (tournamentDetail?.invitedProvinceIds) {
      const result = tournamentDetail?.invitedProvinceIds
        .map(invitedProvince => invitedProvince.id.includes(playerInfo?.province?.code!))
        .filter(Boolean)
        .toString();
      return Boolean(result);
    }
    return true;
  };

  const isInvitedSchoolIds = () => {
    if (tournamentDetail?.invitedSchoolIds) {
      const result = tournamentDetail?.invitedSchoolIds
        .map(invitedProvince => invitedProvince.id.includes(playerInfo?.school.id!))
        .filter(Boolean)
        .toString();
      return Boolean(result);
    }
    return true;
  };

  const isAcceptedCity = () => {
    if (tournamentDetail?.invitedCityIds) {
      const result = tournamentDetail?.invitedCityIds
        .map(arr => arr.id.includes(playerInfo?.city?.code!))
        .filter(Boolean)
        .toString();
      return Boolean(result);
    }
    return true;
  };

  const rulesMatch =
    isAuthenticated &&
    isNotRestrictedTeamIds() &&
    isInvitedProvinceIds() &&
    isInvitedSchoolIds() &&
    isAcceptedCity();

  useEffect(() => {
    setShowBoxRegister(Boolean(statusBoxRegister));
  }, [isMyTeamRegistered, tournamentDetail]);

  return (
    <Box sx={{ bgcolor: '#000', position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          mb: '24px',
          display: 'flex',
          boxShadow: `inset 0px -28px 20px -10px #000`,
          img: { zIndex: -1 },
        }}
      >
        {tournamentDetail?.banner ? (
          <img src={tournamentDetail?.banner} alt={tournamentDetail?.title} width="100%" />
        ) : (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
        )}
      </Box>

      <Box sx={{ px: '16px', pb: '24px' }}>
        <Stack spacing={2} sx={{ mb: '24px' }}>
          {isAuthenticated && (
            <TeamStatus
              isMyTeamInTournament={isMyTeamInTournament}
              isMyTeamRejected={isMyTeamRejectedForRibbon}
              tournamentState={tournamentDetail?.state}
              teamState={tournamentDetail?.teamState}
              requireParticipantApproval={tournamentDetail?.requireParticipantApproval}
              calculatePlayerJoin={calculatePlayerJoin}
              handleDrawerOpen={handleDrawerOpen}
            />
          )}

          {tournamentDetail?.eventPrizeIdr && (
            <>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={theme => ({
                  pb: '24px',
                  borderBottom: `1px dashed ${theme.palette.neutral530}`,
                })}
              >
                <SVG src={IconSource('ic-trophy-outlined')} />
                <Stack>
                  <Text variant="labelSmall" sx={{ color: 'neutral400' }}>
                    {t('tournament_total_hadiah')}
                  </Text>
                  <Text variant="h3" sx={{ color: 'primary600' }}>
                    {t('tournament_rp', {
                      number: tournamentDetail?.eventPrizeIdr.toLocaleString().replace(/,/g, '.'),
                    })}
                  </Text>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>

        {tournamentDetail?.winners && (
          <Stack spacing={2} sx={{ mb: '32px' }}>
            <WinnerTeams teams={tournamentDetail?.winners} />
          </Stack>
        )}

        <Details tournamentDetail={tournamentDetail} teamsAccepted={teamsAccepted} />
      </Box>

      {showBoxRegister && (
        <BoxRegister
          isAuthenticated={isAuthenticated}
          registerValidation={{
            isCaptain: isCaptain,
            haveTeam: Boolean(haveTeam),
            rulesMatch: rulesMatch,
          }}
          teamsAccepted={teamsAccepted}
          maxTeam={tournamentDetail?.maxTeam}
          slug={{ gameSlug: gameSlug, tournamentSlug: tournamentSlug }}
        />
      )}

      <StatusAnggotaTim
        importantInfo={{
          title: t('tournament_register_end', {
            date: moment(tournamentDetail?.closeRegAt).format('dddd, DD MMMM YYYY'),
          }),
          content: t('tournament_tim_belum_resmi'),
        }}
        myId={myId}
        isMyTeamInTournament={Boolean(isMyTeamInTournament)}
        tournamentState={tournamentDetail?.state}
        teamState={tournamentDetail?.teamState}
        players={handlePlayers}
        isDrawerOpen={isDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        tournamentName={tournamentDetail?.title}
      />
    </Box>
  );
};

export default AboutPage;
