import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export interface TeamApprovalResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  totalCount: number;
  totalPages: number;
  items: TeamApproval[];
}

export interface TeamApproval {
  teamId: string;
  level: Level;
  status: Level;
  type: Level;
  joinAt: Date;
  player: Player;
}

export interface Level {
  key: string;
  value: string;
}

export interface Player {
  id: string;
  urlSlug: string;
  name: string;
  gameProviderId: string;
  picture?: string;
}

export interface TeamApprovalState {
  data: TeamApprovalResponse;
  loading: boolean;
  errorMessage: string | undefined | null;
  errorCode: number;
  status?: string | null;
}

const initialState: TeamApprovalState = {
  data: {
    pageIndex: 0,
    pageSize: 0,
    count: 0,
    totalCount: 0,
    totalPages: 0,
    items: [],
  },
  loading: false,
  errorMessage: '',
  errorCode: 0,
  status: '',
};

export const approveJoin = createAsyncThunk(
  'team-approval/approve-join',
  async (data: { playerId: string; teamId: string }) => {
    try {
      const url = `/team-approval/approve-join`;
      const res = await ApiService.post(url, data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const approveInvite = createAsyncThunk(
  'team-approval/approve-invite',
  async (data: { playerId: string; teamId: string }) => {
    try {
      const url = `/team-approval/approve-invite`;
      const res = await ApiService.post(url, data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const rejectJoin = createAsyncThunk(
  'team-approval/reject-join',
  async (data: { playerId: string; teamId: string }) => {
    try {
      const url = `/team-approval/reject-join`;
      const res = await ApiService.post(url, data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const kickMember = createAsyncThunk(
  'team-approval/remove-member',
  async (data: { playerId: string; teamId: string }) => {
    try {
      const url = `/team-member/quit`;
      const res = await ApiService.delete(url, {
        data,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

export const rejectInvite = createAsyncThunk(
  'team-approval/reject-invite',
  async (data: { playerId: string; teamId: string }) => {
    try {
      const url = `/team-approval/reject-invite`;
      const res = await ApiService.post(url, data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

const TeamApprovalSlice = createSlice({
  name: 'TeamApproval',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(approveJoin.pending, state => {
      state.status = 'loading';
      state.loading = true;
    }),
      builder.addCase(approveJoin.fulfilled, state => {
        state.status = 'succeeded';
        state.loading = false;
      }),
      builder.addCase(approveJoin.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.loading = false;
        state.data.items = [];
      });

    builder.addCase(approveInvite.pending, state => {
      state.status = 'loading';
      state.loading = true;
    }),
      builder.addCase(approveInvite.fulfilled, state => {
        state.status = 'succeeded';
        state.loading = false;
      }),
      builder.addCase(approveInvite.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.loading = false;
        state.data.items = [];
      });

    builder.addCase(rejectJoin.pending, state => {
      state.status = 'loading';
      state.loading = true;
    }),
      builder.addCase(rejectJoin.fulfilled, state => {
        state.status = 'succeeded';
        state.loading = false;
      }),
      builder.addCase(rejectJoin.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.loading = false;
        state.data.items = [];
      });

    builder.addCase(rejectInvite.pending, state => {
      state.status = 'loading';
      state.loading = true;
    }),
      builder.addCase(rejectInvite.fulfilled, state => {
        state.status = 'succeeded';
        state.loading = false;
      }),
      builder.addCase(rejectInvite.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.loading = false;
        state.data.items = [];
      });

    builder.addCase(kickMember.pending, state => {
      state.status = 'loading';
      state.loading = true;
    }),
      builder.addCase(kickMember.fulfilled, state => {
        state.status = 'succeeded';
        state.loading = false;
      }),
      builder.addCase(kickMember.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.loading = false;
        state.data.items = [];
      });
  },
});

export default TeamApprovalSlice.reducer;
