import { Constants, IDENTITY_CONFIG, METADATA_OIDC } from './Constants';
import history from 'modules/history';
import { User, UserManager } from 'oidc-client';

export const getDecryptedUser = (): User => {
  const sessionStorage =
    window.localStorage.getItem(
      `${Constants.oidcPrefix}user:${Constants.stsAuthority}:${Constants.clientId}`,
    ) ?? undefined;
  return JSON.parse(
    sessionStorage ? Constants.encryptStorage.decryptString(sessionStorage) : '{}',
  ) as User;
};

const isNotExpired =
  !!getDecryptedUser() &&
  !!getDecryptedUser().access_token &&
  getDecryptedUser().expires_at >= Date.now() / 1000;

export const userManager = new UserManager({
  ...IDENTITY_CONFIG,
  metadata: {
    ...METADATA_OIDC,
  },
  checkSessionInterval: 10000000,
  // iframeNavigator: isNotExpired ? new CordovaIFrameNavigator() : undefined,
});

export default class AuthService {
  public isAuthenticated(): boolean {
    return isNotExpired;
  }

  public getUser(): Promise<User | null> {
    return userManager.getUser();
  }

  public register(): void {
    userManager
      .createSigninRequest()
      .then(v => {
        const signUpUrl = `${Constants.stsAuthority}signup`;
        const params = new URLSearchParams(v.url);
        const state = params.get('state');
        const codeChallenge = params.get('code_challenge');
        const codeChallengeMethod = params.get('code_challenge_method');
        const signUpReturn = history.createHref({
          state: v.state,
          pathname: '/connect/authorize/callback',
          search:
            '?' +
            new URLSearchParams({
              client_id: IDENTITY_CONFIG.client_id,
              redirect_uri: IDENTITY_CONFIG.redirect_uri,
              response_type: IDENTITY_CONFIG.response_type,
              scope: Constants.clientScope,
              state: state ?? '',
              code_challenge: codeChallenge ?? '',
              code_challenge_method: codeChallengeMethod ?? '',
              response_mode: IDENTITY_CONFIG.response_mode,
            }).toString(),
        });
        window.location.href = history.createHref({
          state: v.state,
          pathname: signUpUrl,
          search:
            '?' +
            new URLSearchParams({
              ReturnUrl: signUpReturn,
            }).toString(),
        });
      })
      .catch(err => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      });
  }

  public login(): Promise<void> {
    return userManager.signinRedirect();
  }

  public getSignRedirectURL(): string {
    return Constants.encryptStorage.getItem('sign_redirect_url') ?? Constants.clientRoot;
  }

  public setSignRedirectURL(pathname: string) {
    return Constants.encryptStorage.setItem('sign_redirect_url', pathname);
  }

  public renewToken(): Promise<User> {
    return userManager.signinSilent();
  }

  public logout(): Promise<void> {
    window.localStorage.removeItem('player_profile_info');
    window.localStorage.removeItem('current_team');
    return userManager.signoutRedirect();
  }
}
