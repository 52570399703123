import styled from 'styled-components';

export const Border = styled.div`
  position: relative;
  width: 92%;
  height: 116px;
  margin: auto;
  margin-bottom: 8px;

  background: rgba(55, 57, 66, 0.6);
  border-radius: 16px;
`;

export const InlineBorder = styled.div`
  position: relative;
  width: 94%;
  height: 84px;
  border: 1px solid #423e3e;
  box-sizing: border-box;
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
  top: 16px;
`;
export const BorderGame = styled.div`
  position: relative;
  width: 100px;
  height: 64px;

  background: #423e3e;
  border-radius: 8px;
  top: 8px;
  left: 8px;
  justify-content: center;
  align-content: center;
  align-items: center;
  -webkit-user-drag: none;
`;

export const GameLogo = styled.img`
  position: relative;
  width: 78px;
  padding: 13px;
  top: 7px;
  -webkit-user-drag: none;
`;
export const Column = styled.div`
  float: left;
  width: auto;
`;

export const Row = styled.div`
  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;
