import TextView from 'components/TextView/TextView';
import React, { ReactNode } from 'react';
import { BtnSelect } from './styles';

export interface ButtonSelectProps {
  btnText?: string;
  btnWidth?: string;
  btnHeight?: string;
  btnRadius?: string;
  btnPadding?: string;
  btnPosition?: string;
  btnBackground?: string;
  isDisable?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}

export function ButtonSelect({
  btnText,
  btnWidth,
  btnHeight,
  btnRadius,
  btnPadding,
  btnPosition,
  btnBackground,
  onClick,
  isDisable = true,
}: ButtonSelectProps) {
  return (
    <BtnSelect
      btnWidth={btnWidth}
      btnHeight={btnHeight}
      btnRadius={btnRadius}
      btnPadding={btnPadding}
      btnPosition={btnPosition}
      btnBackground={btnBackground}
      isDisable={isDisable}
      onClick={onClick}
    >
      {isDisable ? (
        btnText
      ) : (
        <TextView variant="Gradient" tSize="14px" tStyle="normal" tWeight="700">
          {btnText}
        </TextView>
      )}
    </BtnSelect>
  );
}

export default ButtonSelect;
