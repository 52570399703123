import { fetchApi } from 'services/setupService';

export const getGameOptionsService = async (p: {
  pageIndex: number;
  pageSize: number;
  search?: string;
}) => {
  const res = await fetchApi({
    method: 'GET',
    path: `game/options/${p.pageIndex}/${p.pageSize}/${
      p.search ? `?search=${encodeURIComponent(p.search || '')}` : ''
    }`,
  });
  return res;
};
