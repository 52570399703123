import styled, { css } from 'styled-components';
interface TextProps {
  position?: string | undefined;
  maxLines?: number | undefined;
  fontWeight?: string | undefined;
  fontSize?: string | undefined;
  margin?: string | undefined;
  padding?: string | undefined;
  letterSpacing?: string | undefined;
  lineHeight?: string | undefined;
  color?: string | undefined;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  fontStyle?: string | undefined;
  textDecoration?: 'none'| 'underline' | 'overline' | 'line-through'| 'blink' | 'spelling-error' | 'grammar-error';
  opacity?: string | undefined;
  disableSelectText?: boolean;
  disableDrag?: boolean;
  isPressable?: boolean;
  maxWidth?: string;
  wordWrap?: 'normal' | 'break-word' | 'inherit' | 'initial' | undefined | null;
  textJustify?: 'auto' | 'inter-character' | 'inter-word' | 'none';
  fontFamily?: string | 'Roboto';
}

export const NormalText = styled.p<TextProps>`
  position: ${({ position }) => position};
  font-family: ${({ fontFamily }) => fontFamily ?? 'Roboto'};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-style: ${({ fontStyle }) => fontStyle};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin ?? '0px'};
  padding: ${({ padding }) => padding ?? '0px'};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: ${({ textAlign }) => textAlign};
  text-justify: ${({ textJustify }) => textJustify};
  text-decoration-line: ${({ textDecoration }) => textDecoration ?? 'none'};
  opacity: ${({ opacity }) => opacity};
  word-wrap: ${({ wordWrap }) => wordWrap};
  ${p =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${p =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  ${p =>
    p.isPressable &&
    css`
      cursor: pointer;
      &:active {
        opacity: 0.7;
      }
    `}
`;

export const GradientText = styled.span<TextProps>`
  margin: ${p=> p.margin};
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: ${p => p.fontWeight};
  font-size: ${p => p.fontSize};
  line-height: ${p => p.lineHeight};
  letter-spacing: ${p => p.letterSpacing};
  text-align: ${({ textAlign }) => textAlign};
  z-index: 0;
  background-clip: text;
  ${p =>
    p.textDecoration === 'underline' &&
    css`
      border-bottom: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(
        131.42deg,
        #e75570 32.19%,
        #ed676a 53.45%,
        #f17267 66.7%,
        #fd965b 83.78%
      );
    `}
  background: ${p =>
    p.color
      ? p.color
      : 'linear-gradient(131.42deg, #E75570 32.19%, #ED676A 53.45%, #F17267 66.7%, #FD965B 83.78%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${p =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${p =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  ${p =>
    p.isPressable &&
    css`
      cursor: pointer;
      &:active {
        opacity: 0.85;
      }
    `}
    opacity: ${p=> p.opacity};
`;


export const SpanText = styled.span<TextProps>`
  position: ${({ position }) => position};
  font-family: 'Roboto';
  font-weight: ${({ fontWeight }) => fontWeight};
  font-style: ${({ fontStyle }) => fontStyle};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin ?? '0px'};
  padding: ${({ padding }) => padding ?? '0px'};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: ${({ textAlign }) => textAlign};
  text-decoration-line: ${({ textDecoration }) => textDecoration ?? 'none'};
  opacity: ${({ opacity }) => opacity};
  word-wrap: ${({ wordWrap }) => wordWrap};
  ${p =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${p =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  ${p =>
    p.isPressable &&
    css`
      cursor: pointer;
      &:active {
        opacity: 0.7;
      }
    `}
`;