export const TermsContent = () => {
    return (
`Syarat dan Ketentuan berikut menjelaskan peraturan dan ketentuan penggunaan Platform MABAR dengan alamat <https://mabar.com>.

Platform MABAR (selanjutnya dinamakan &quot;**MABAR**&quot;) adalah platform teknologi yang dibuat untuk memberikan pengalaman arena Esport untuk _student-athlete_. Platform ini dikelola oleh PT. MID DIGITAL INTERACTIVE (Selanjutnya disebut &quot;Pengelola&quot; atau &quot;Pengelola MABAR). Dengan menggunakan platform MABAR ini, Pengguna diwajibkan untuk tunduk pada syarat dan ketentuan di bawah ini.

### PASAL 1: PENGUMPULAN INFORMASI

Platform MABAR dapat mengumpulkan informasi pribadi Pengguna. Pengguna wajib memberikan data/informasi pribadi yang akurat untuk memastikan pengalaman penggunaan platform secara maksimal.

Dengan bergabung menjadi pengguna MABAR, Pengguna setuju dan mengizinkan MABAR untuk dapat melakukan komunikasi kepada Pengguna melalui email, SMS, dan/atau notifikasi melalui Platform yang terdaftar. MABAR dapat menggunakan sarana-sarana komunikasi tersebut untuk memberikan informasi terkait status akun pengguna, perubahan kebijakan, menawarkan promosi khusus, serta event - event yang menarik bagi Pengguna.

### PASAL 2: INTEGRITAS PENGGUNA

MABAR dibangun berlandaskan integritas dan kejujuran. Setiap kecurangan dan pelanggaran adalah masalah serius dan dapat ditindaklanjuti oleh Pengelola Mabar. Setiap penggunaan platform MABAR oleh Pengguna harus dengan itikad baik dan sesuai dengan peraturan maupun hukum yang berlaku. Pengguna tidak diperbolehkan mengatasnamakan orang lain atau menggunakan akun milik orang lain pada platform MABAR. Seluruh permainan dalam platform MABAR harus dimainkan oleh Pengguna sendiri dan tidak diwakilkan kecuali atas izin tertulis dari Pengelola MABAR. Pengguna tidak diperbolehkan memanipulasi hasil permainan dan merugikan pengguna lain. Pengguna harus menghormati setiap hak para pengguna lainnya, setiap aturan permainan, dan kebijakan dari Pengelola MABAR.

Seluruh Pengguna merupakan pelajar sekolah tingkat SMA atau sederajat yang membawa nama baik sekolah, serta mewakili dan membanggakan sekolahnya.

Pengguna tidak diperbolehkan untuk berkata-kata kasar dan melakukan tindakan negatif lainnya baik dalam platform maupun diluar platform saat menjadi pengguna MABAR, setiap penggunaan kata berunsur SARA maupun tindakan negatif lainnya pada inputan dalam platform MABAR tidak diperbolehkan dan akan ditindaklanjuti dengan tegas oleh Pengelola MABAR.

### PASAL 3: KEBIJAKAN SCRIM

Scrim adalah cara antar pengguna untuk dapat saling berinteraksi, berlatih, dan mempersiapkan diri maupun tim agar siap dalam berkompetisi dan menjadi _student-athlete_. Scrim dibuat oleh Pengelola MABAR dengan sedemikian rupa sehingga Pengguna dapat menikmati pengalaman Scrim sebagai tim dari sekolahnya. Pengalaman Scrim akan dicatat dalam platform MABAR yang kemudian akan menjadi riwayat pertandingan Pengguna.

### PASAL 4: PENANGGUHAN &amp; PENUTUPAN AKUN

Pengelola MABAR berhak untuk menangguhkan atau menghentikan akun pengguna, apabila:

- Pengguna melanggar persyaratan dan ketentuan MABAR;
- Pengguna memberikan informasi pribadi yang tidak lengkap, tidak akurat, palsu atau fiktif;
- Pengguna melakukan tindakan penipuan atau penggunaan tidak sah terhadap MABAR;
- Pengguna tidak beritikad baik dan penggunaannya tidak sesuai dengan peraturan maupun hukum yang berlaku; dan
- Pengguna merugikan pengguna lain dan/atau Pengelola MABAR.

### PASAL 5 : KEAMANAN

Pengelola MABAR menganggap serius keamanan informasi yang dikumpulkannya. Oleh karena itu, Pengelola MABAR telah menerapkan kebijakan dan prosedur teknologi dan keamanan yang dimaksudkan untuk mengurangi risiko kerusakan atau kerugian yang tidak disengaja, atau pengungkapan atau akses yang tidak sah terhadap informasi tersebut, yang sesuai dengan sifat data yang bersangkutan.

### PASAL 6: LISENSI

Kecuali dinyatakan lain, MABAR dan/atau Pengelola MABAR memiliki hak kekayaan intelektual atas setiap materi dalam MABAR, termasuk namun tidak terbatas pada nama, logo dan materi. Semua hak kekayaan intelektual dilindungi oleh undang-undang. Pengguna dapat mengaksesnya dari MABAR untuk penggunaan pribadi Pengguna sendiri dengan batasan yang diatur dalam Syarat dan Ketentuan ini.

Pengguna dilarang untuk:

- Menyalin atau menerbitkan ulang materi dari MABAR;
- Menjual, menyewakan atau mensublisensikan materi dari MABAR;
- Memproduksi ulang, menggandakan, atau menyalin materi dari MABAR;
- Mendistribusikan ulang konten dari MABAR.

### PASAL 7: LAYANAN PELANGGAN

Pengguna platform bisa menghubungi pihak Pengelola platform di bawah ini:

> PT MID Digital Interactive

> midPlaza 2 Building, Lantai 24.

> Jl. Jendral Sudirman Kav. 10-11, Jakarta Pusat, Indonesia 10220

> E-mail: <hi@mabar.com>

Selain itu, Pengguna juga dapat menghubungi Whatsapp dan Social Media yang tercantum pada website MABAR.

### PASAL 8: PERUBAHAN KETENTUAN UMUM

Platform MABAR adalah platform teknologi yang dibuat untuk memberikan pengalaman arena Esport untuk _student-athlete_. Penggunaan platform mabar bersifat terbatas dan Pengguna membebaskan Pengelola MABAR dari segala tuntutan hukum. Kami berhak untuk mengubah atau memperbarui syarat dan ketentuan umum ini tanpa pemberitahuan terlebih dahulu. Oleh karenanya kami mendorong Pengguna untuk merujuk kembali kepada syarat dan ketentuan umum ini secara berkala.

Saat merevisi syarat dan ketentuan umum ini, kami juga akan merevisi tanggal efektif di bawah ini.

Tanggal Efektif: 29 November 2021`
    )
} 

export default TermsContent;