import is from 'is-lite';
import { getTheme, px } from 'styled-minimal';

export const headerHeight = 56;

export const appColor = '#292A2F';

export const easing = 'cubic-bezier(0.35, 0.01, 0.77, 0.34);';

const theme = getTheme({
  colors: {
    primary: '#D51F40',
    secondary: '#F37024',
    gradientPrimary:
      'linear-gradient(131.42deg, #D51F40 46.48%, #E24134 62.51%, #E8542E 74.36%, #F37024 83.78%)',
    black01: '#423E3E',
    black02: '#6A6363',
    black03: '#969393',
    gray01: '#DDD8D8',
    gray02: '#F1EBEB',
    gray03: '#FBF7F7',
    gray04: '#FBF8F8',
    red01: '#E43C5A',
    yellow01: '#E7B450',
    green01: '#26BD87',
    neutral100: '#F4F4F5',
    neutral150: '#EAEAEC',
    neutral200: '#D4D6D8',
    neutral300: '#B4B7BB',
    neutral400: '#94989E',
    neutral500: '#4E5156',
    neutral550: '#2E3033',
    neutral600: '#27282B',
    neutral700: '#1A1817',
    neutral800: '#131415',
    neutral900: '#0A0A0B',
    primary100: '#FDEBE0',
    primary200: '#FCD6C1',
    primary500: '#F68F54',
    primary600: '#4945FF',
    primary700: '#271FE0',
    primary800: '#4E1F04',
    primary900: '#271002',
    primaryGradient: 'linear-gradient(90deg, #D51F40 0%, #F37024 100%)',
    success100: '#EEFCF7',
    success200: '#9BEBCF',
    success500: '#47DBA7',
    success600: '#1E976C',
    success700: '#136045',
    success900: '#09271D',
    danger100: '#FFE2E4',
    danger200: '#F9A5AB',
    danger500: '#F45A64',
    danger600: '#D02B20',
    danger700: '#BF0D19',
  },
  button: {
    borderRadius: {
      xs: 4,
      lg: 28,
      xl: 32,
    },
    padding: {
      lg: [12, 28],
      xl: [14, 32],
    },
  },
});

export const Colors = {
  primary: '#D51F40',
  secondary: '#F37024',
  gradientPrimary:
    'linear-gradient(131.42deg, #D51F40 46.48%, #E24134 62.51%, #E8542E 74.36%, #F37024 83.78%)',
  black01: '#423E3E',
  black02: '#6A6363',
  black03: '#969393',
  gray01: '#DDD8D8',
  gray02: '#F1EBEB',
  gray03: '#FBF7F7',
  gray04: '#FBF8F8',
  red01: '#E43C5A',
  yellow01: '#E7B450',
  green01: '#26BD87',
};

export const variants = theme.colors;
export const spacer = (value: number | string): string =>
  px(is.string(value) ? value : theme.space[value]);

export default theme;
