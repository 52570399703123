import Anggota from './Anggota';
import LoadingModal from 'components/LoadingModal';
import { CardSection } from 'design/Cards/CardSection';
import { Text } from 'design/Text';
import React, { memo, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { fetchTeamMember } from 'redux/teamProfile/TeamProfileService';

interface TentangPublicProps {
  paramTeamSlug: string;
  setOpenToast: (value: boolean) => void;
}

const TentangPublic = ({ paramTeamSlug, setOpenToast }: TentangPublicProps) => {
  const dispatch = useAppThunkDispatch();

  const { teamDetail, teamMember, teamMemberStatus } = useAppSelector(({ teamProfile }) => ({
    teamDetail: teamProfile?.teamDetail,
    teamMember: teamProfile?.teamMember,
    teamMemberStatus: teamProfile?.status?.teamMember,
  }));

  const [error, setError] = useState<{ name: string; message: string; stack: string }>();

  const handleFetchTeamMember = (teamSlug: string) => {
    dispatch(
      fetchTeamMember({
        approved: false,
        teamSlug: teamSlug,
        pageIndex: 1,
        pageSize: 20,
        version: '',
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  useEffect(() => {
    if (paramTeamSlug) {
      handleFetchTeamMember(paramTeamSlug);
    }
  }, []);

  const handleLoading = (status: boolean) => {
    if (status) {
      return !Boolean(teamMember?.items[0]?.teamId === teamDetail?.id);
    }
    return false;
  };

  return (
    <>
      <CardSection
        title={
          <Text variant="label" component="h5" sx={{ textTransform: 'capitalize' }}>
            Anggota
          </Text>
        }
        sx={{ bgcolor: 'transparent', pt: '24px' }}
      >
        <Anggota setOpenToast={setOpenToast} />
      </CardSection>

      <LoadingModal
        show={error ? true : handleLoading(teamMemberStatus === 'loading')}
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default memo(TentangPublic);
