import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginAction } from 'redux/auth/AuthReducer';
import { failedGetTeamDetailAction, getTeamDetailAction, successGetTeamDetailAction, TeamDetailResponse } from './TeamDetailReducer';
import { getTeamDetailService } from './TeamService';

interface Res<T> {
  data: T;
  status: number;
}

function* fetchTeamDetailSaga(action: AnyAction) {
  try {
    const res: Res<TeamDetailResponse> = yield call(getTeamDetailService, action.payload);
    if (res.status !== 200) {
      throw res;
    } else {
      yield put(successGetTeamDetailAction(res.data));
    }
  } catch (e: any) {
    if (
      e?.response?.status === 400 ||
      e?.response?.status === 404 ||
      e?.response?.status === 422 ||
      e?.response?.status === 503
    ) {
      yield put(failedGetTeamDetailAction(e?.response?.data));
    } else if (e?.response?.status === 401) {
      yield call(loginAction);
    } else {
      yield put(failedGetTeamDetailAction({ errorCode: e?.response?.status }));
    }
  }
}

export default function* teamDetailSaga() {
  yield takeLatest(getTeamDetailAction.type, fetchTeamDetailSaga);
}
