import { Stack, Box, Collapse } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardNoticeRed } from 'design/Cards/CardNoticeRed';
import { CardPlayerDetail } from 'design/Cards/CardPlayerDetail';
import { CardReferee } from 'design/Cards/CardReferee';
import { CardTeamBattle } from 'design/Cards/CardTeamBattle';
import { InfoModal } from 'design/Cards/InfoModal';
import { Match } from 'design/Cards/Match';
import { Player } from 'design/Cards/Player';
import { DrawerPrimary } from 'design/Drawer/Primary';
import { Modal } from 'design/Modal';
import { Ribbon } from 'design/Ribbon';
import { MatchStatus } from 'design/Ribbon/MatchStatus';
import { GameOnToastStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import he from 'he';
import { IconSource } from 'modules/assetpath';
import { dateFormat, delayExecute } from 'modules/helpers';
import history from 'modules/history';
import moment from 'moment-timezone';
import React, { useState, Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { setPopupMatchDetailStatus } from 'redux/match/matchReducer';
import { playerCheckinMatch, fetchMatchDetail } from 'redux/match/matchServices';
import { useAppThunkDispatch } from 'redux/store';
import { TournamentDetailResponse } from 'redux/tournament/TournamentProps';
import { STATUS, RESULT_TYPE } from 'store/constants/match';

const matchNotValid: any = [
  STATUS.UNKNOWN,
  STATUS.EXPIRED,
  STATUS.REJECT,
  STATUS.NOTVALID,
  STATUS.DISPUTE,
];
const matchScheduled: any = [STATUS.OPEN, STATUS.INVITATION, STATUS.SCHEDULED];
const matchCompete: any = [STATUS.CHECKIN, STATUS.COMPETE, STATUS.RESULTUPLOAD];
const matchFinished: any = [STATUS.DATACOMPLETED, STATUS.BYE, STATUS.NOTVALID, STATUS.DISPUTE];

interface PlayerPageProps {
  tournamentDetail?: TournamentDetailResponse;
  playerListed: Array<any>;
  setOpenLoader: Dispatch<SetStateAction<boolean>>;
}

const PlayerPage = ({ tournamentDetail, playerListed, setOpenLoader }: PlayerPageProps) => {
  const { t } = useTranslation();
  const { game_slug, tournament_slug, match_id } = useParams() as {
    game_slug: string;
    tournament_slug: string;
    match_id: string;
  };
  const dispatch = useAppThunkDispatch();
  const [isOpenRules, setIsOpenRules] = useState(false);
  const [isOpenPlayerDetail, setIsOpenPlayerDetail] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState('');
  const [openModalMatchWinWO, setOpenModalMatchWinWO] = useState(false);
  const [openModalMatchLoseWO, setOpenModalMatchLoseWO] = useState(false);
  const [openModalMatchNotValid, setOpenModalMatchNotValid] = useState(false);
  const [expandedMatch, setExpandedMatch] = useState([false, false, false]);
  const [openModalUploadEnd, setOpenModalUploadEnd] = React.useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [checkinTimeEnd, setCheckinTimeEnd] = useState(false);
  const [playerData, setPlayerData] = useState({
    playerName: '',
    playerPhoto: '',
    gameId: '',
    nickname: '',
    urlSlug: '',
  });
  const { matchDetail, myTeam, profile, popupMatchStatus } = useAppSelector(
    ({ myTeam, account, match }) => ({
      matchDetail: match.matchDetail,
      myTeam: myTeam.data.items,
      profile: account.data,
      popupMatchStatus: match.popupMatchDetailStatus,
    }),
  );

  const isPlayerActive = playerListed?.find(item => item?.playerId === profile?.id);
  const isCaptain = isPlayerActive?.level == 1;

  const isPlayerInFirstTeam = matchDetail?.firstTeamPlayers?.find(
    item => item?.playerId === profile?.id,
  );
  const isPlayerInSecondTeam = matchDetail?.secondTeamPlayers?.find(
    item => item?.playerId === profile?.id,
  );

  const firstTeamResultScreenshot =
    matchDetail?.results?.filter(item => item?.firstTeamScreenshot) ?? [];
  const secondTeamResultScreenshot =
    matchDetail?.results?.filter(item => item?.secondTeamScreenshot) ?? [];

  const currentFirstTeamPlayerCheckin =
    matchDetail?.firstTeamPlayers?.filter(item => item.checkinAt !== '').length ?? 0;
  const totalFirstTeamPlayerCheckin = matchDetail?.firstTeamPlayers?.length ?? 0;

  const currentSecondTeamPlayerCheckin =
    matchDetail?.secondTeamPlayers?.filter(item => item.checkinAt !== '').length ?? 0;
  const totalSecondTeamPlayerCheckin = matchDetail?.secondTeamPlayers?.length ?? 0;

  const fetchMatchDetailData = useCallback(
    (matchId: string, showPopupStatus: boolean | undefined) => {
      dispatch(fetchMatchDetail({ matchId: matchId }))
        .unwrap()
        .then(res => {
          setOpenLoader(false);
          if (showPopupStatus) handleModalMatchStatus(res);
        });
    },
    [dispatch],
  );

  const handleMatchStatusColor = (status: number) => {
    if (matchNotValid.includes(status)) return 'red';
    if (matchCompete.includes(status)) return 'orange';
    if (matchScheduled.includes(status)) return 'green';

    return 'white';
  };

  const handleWhatsapp = () => {
    if (myTeam) {
      if (matchDetail?.firstTeamId === myTeam[0]?.id) {
        return matchDetail?.secondTeamWhatsappNumber;
      }
      if (matchDetail?.secondTeamId === myTeam[0]?.id) {
        return matchDetail?.firstTeamWhatsappNumber;
      }
    }

    return '';
  };

  const handleWinner = (teamId: string) => {
    if (teamId !== matchDetail?.winnerTeamId) {
      if (matchDetail?.results) {
        if (matchDetail?.status === STATUS.RESULTUPLOAD) return '';
        return 'lose';
      }
      return '';
    }
    return 'win';
  };

  const handleIndicatorTeam = (teamId: string) => {
    const byeResult = Array.from(Array(matchDetail?.type).keys()).map(() => ({
      result: matchDetail?.winnerTeamId === teamId ? 'win' : '',
    }));
    const matchResult = matchDetail?.results?.map(result =>
      result?.winnerTeamId === teamId ? { result: 'win' } : { result: 'lose' },
    );
    const noResult = Array.from(Array(matchDetail?.type).keys()).map(_item => ({
      result: '',
    }));

    if (matchDetail?.status === STATUS.RESULTUPLOAD || matchDetail?.status === STATUS.DISPUTE) {
      return noResult;
    }

    return matchResult ?? byeResult;
  };

  const handleCloseToast: any = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const checkinMatch = () => {
    setOpenLoader(true);
    const checkinMatchThunk = playerCheckinMatch({
      matchId: match_id,
    });
    dispatch(checkinMatchThunk)
      .unwrap()
      .then(res => {
        if (res && res.isAxiosError && res.data?.status >= 400) {
          setOpenLoader(false);
          setOpenToast(false);
          if (res.data.status == 1701) {
            setModalErrorMsg(t('match_checkin_limit'));
          } else if (res.data.status == 1702) {
            setModalErrorMsg(t('match_checkin_invalid'));
          } else {
            setModalErrorMsg(res.data.title);
          }

          setOpenModalError(true);
        } else {
          fetchMatchDetailData(match_id, false);

          setOpenToast(true);
        }
      })
      .catch(err => {
        if (process.env.NODE_ENV !== 'production') console.error(err);
      });
  };

  function onClickExpand(index: number, state: boolean) {
    var array = [...expandedMatch];
    array[index] = state;
    setExpandedMatch(array);
  }

  const homeAwayDesc = isPlayerInFirstTeam
    ? t('match_start_compete_info_desc_home')
    : t('match_start_compete_info_desc_away');

  const handleNoticeMessage: any = () => {
    if (
      isCaptain &&
      (matchDetail?.status == STATUS.COMPETE ||
        (isPlayerInFirstTeam &&
          matchDetail?.status === STATUS.RESULTUPLOAD &&
          firstTeamResultScreenshot?.length == 0) ||
        (isPlayerInSecondTeam &&
          matchDetail?.status === STATUS.RESULTUPLOAD &&
          secondTeamResultScreenshot?.length == 0))
    ) {
      return {
        title: t('match_start_compete_info_title'),
        content: matchDetail?.referee?.refereeId
          ? t('match_start_compete_info_desc_referee')
          : homeAwayDesc,
      };
    }
    if (
      matchDetail?.status == STATUS.COMPETE ||
      (isPlayerInFirstTeam &&
        matchDetail?.status === STATUS.RESULTUPLOAD &&
        firstTeamResultScreenshot?.length == 0) ||
      (isPlayerInSecondTeam &&
        matchDetail?.status === STATUS.RESULTUPLOAD &&
        secondTeamResultScreenshot?.length == 0)
    ) {
      return {
        title: t('match_start_compete_info_title'),
        content: t('match_result_member_info_desc'),
      };
    }
    if (
      (isPlayerInFirstTeam &&
        matchDetail?.status === STATUS.RESULTUPLOAD &&
        firstTeamResultScreenshot?.length > 0) ||
      (isPlayerInSecondTeam &&
        matchDetail?.status === STATUS.RESULTUPLOAD &&
        secondTeamResultScreenshot?.length > 0)
    ) {
      return {
        title: t('match_result_info_title'),
        content: t('match_result_info_desc'),
      };
    }
    if (matchDetail?.status == STATUS.DISPUTE) {
      return {
        title: t('match_result_dispute_title'),
        content: t('match_result_dispute_desc'),
      };
    }

    return { title: '', content: '' };
  };

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleModalMatchStatus = useCallback((match: any) => {
    const firstTeamPlayer = match?.firstTeamPlayers?.find(
      (item: any) => item?.playerId === profile?.id,
    );
    const secondTeamPlayer = match?.secondTeamPlayers?.find(
      (item: any) => item?.playerId === profile?.id,
    );

    if (match) {
      if (match?.status === STATUS.NOTVALID) {
        setOpenModalMatchNotValid(true);
      }

      if (
        match?.status === STATUS.DATACOMPLETED &&
        match?.matchResultType === RESULT_TYPE.WALKOVER
      ) {
        if (match?.winnerTeamId === match?.firstTeamId) {
          if (firstTeamPlayer) {
            setOpenModalMatchWinWO(true);
          }
          if (secondTeamPlayer) {
            setOpenModalMatchLoseWO(true);
          }
        }

        if (match?.winnerTeamId === match?.secondTeamId) {
          if (firstTeamPlayer) {
            setOpenModalMatchLoseWO(true);
          }
          if (secondTeamPlayer) {
            setOpenModalMatchWinWO(true);
          }
        }
      }
    }
  }, []);

  const handleMatchUploadLink = () => {
    if (matchDetail) {
      if (moment().tz('Asia/Jakarta').format() > matchDetail.uploadDeadlineAt) {
        setOpenModalUploadEnd(true);
      } else {
        history.push(`/tournament/${game_slug}/${tournament_slug}/match/${match_id}/result`);
      }
    }
  };

  const buttonSendResultUploadCondition =
    (isCaptain && matchDetail?.status === STATUS.COMPETE) ||
    (isPlayerInFirstTeam?.level == 1 &&
      matchDetail?.status === STATUS.RESULTUPLOAD &&
      firstTeamResultScreenshot?.length == 0) ||
    (isPlayerInSecondTeam?.level == 1 &&
      matchDetail?.status === STATUS.RESULTUPLOAD &&
      secondTeamResultScreenshot?.length == 0);

  const buttonWhatsappCondition =
    !matchFinished.includes(matchDetail?.status) &&
    ((isPlayerInFirstTeam && Boolean(matchDetail?.secondTeamWhatsappNumber)) ||
      (isPlayerInSecondTeam && Boolean(matchDetail?.firstTeamWhatsappNumber)));

  const delayArray: any = [5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000];

  useEffect(() => {
    if (checkinTimeEnd) {
      if (popupMatchStatus) {
        delayArray.map((time: number) => {
          delayExecute(time).promise.then((_msg: any) => {
            fetchMatchDetailData(match_id, true);
          });
        });
      }
    }
  }, [checkinTimeEnd, popupMatchStatus]);

  useEffect(() => {
    if (matchDetail?.id) {
      if (popupMatchStatus.matchId !== matchDetail.id) {
        dispatch(setPopupMatchDetailStatus({ isShown: false, match_id: match_id }));
      }
    }
  }, [matchDetail?.id]);

  return (
    <>
      {isPlayerActive &&
        (matchDetail?.status == STATUS.COMPETE ||
          matchDetail?.status == STATUS.RESULTUPLOAD ||
          matchDetail?.status == STATUS.DISPUTE) && (
          <CardNoticeRed
            title={handleNoticeMessage().title}
            content={handleNoticeMessage().content}
          />
        )}
      <Stack
        direction="column"
        spacing={2}
        sx={{
          padding:
            (isCaptain &&
              (matchDetail?.status === STATUS.COMPETE ||
                matchDetail?.status === STATUS.RESULTUPLOAD)) ||
            (isPlayerActive && matchDetail?.status === STATUS.CHECKIN) ||
            (!isPlayerActive && matchDetail?.status === STATUS.SCHEDULED)
              ? '16px 16px 97px'
              : '16px',
        }}
      >
        <Match
          status={
            <MatchStatus
              variant={handleMatchStatusColor(matchDetail?.status ?? 0)}
              label={`${dateFormat(matchDetail?.startAt, 'Asia/Jakarta', 'id').format(
                'DD MMM yyyy, HH:mm',
              )} WIB`}
            />
          }
          home={{
            logo: matchDetail?.firstTeamAvatar,
            name: matchDetail?.firstTeam,
            location: matchDetail?.firstTeamSchool,
            indicator: handleIndicatorTeam(matchDetail?.firstTeamId ?? ''),
            score: matchDetail?.firstTeamScore === '' ? '0' : matchDetail?.firstTeamScore,
            result: handleWinner(matchDetail?.firstTeamId ?? ''),
          }}
          away={{
            logo: matchDetail?.secondTeamAvatar,
            name: matchDetail?.secondTeam,
            location: matchDetail?.secondTeamSchool,
            indicator: handleIndicatorTeam(matchDetail?.secondTeamId ?? ''),
            score: matchDetail?.secondTeamScore === '' ? '0' : matchDetail?.secondTeamScore,
            result: handleWinner(matchDetail?.secondTeamId ?? ''),
          }}
          result={{
            status: matchDetail?.status,
            type: matchDetail?.matchResultType,
          }}
          roundCategory={matchDetail?.roundName}
          whatsapp={{
            show: buttonWhatsappCondition,
            message: t('match_whatsapp_messages_opponent'),
            phone: handleWhatsapp(),
          }}
          rules={{
            onClick: () => setIsOpenRules(true),
          }}
        />
        {(matchDetail?.status == STATUS.DATACOMPLETED ||
          matchDetail?.status == STATUS.RESULTCALCULATED) &&
          matchDetail?.results && (
            <Box
              mt="16px"
              sx={theme => ({ bgcolor: 'neutral700', borderRadius: theme.borderRadius.md })}
            >
              <Stack direction="row" justifyContent="center" sx={{ mb: '6px' }}>
                <MatchStatus variant="white" label="Hasil Pertandingan" />
              </Stack>
              {matchDetail.results.map((resultData, index: number) => {
                return (
                  <>
                    <Box
                      p="14px 12px 16px 12px"
                      sx={{
                        borderBottom: '1px solid rgb(255,255,255,0.1)',
                        '&:last-child': {
                          borderBottom: '0px',
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        onClick={() => onClickExpand(index, !expandedMatch[index])}
                      >
                        <Text variant="label" sx={{ width: '100%' }}>
                          Game {index + 1}
                        </Text>
                        <SVG
                          src={IconSource('ic-caret-down-small-white')}
                          style={{
                            transition: '.3s ease',
                            transform: expandedMatch[index] ? 'rotate(180deg)' : 'rotate(0deg)',
                          }}
                        />
                      </Stack>
                      <Collapse
                        in={expandedMatch[index]}
                        timeout="auto"
                        unmountOnExit
                        sx={{ mt: '14px' }}
                      >
                        <img
                          alt=""
                          src={
                            resultData?.finalTeamScreenshot ||
                            resultData?.firstTeamScreenshot ||
                            resultData?.secondTeamScreenshot
                          }
                          style={{ width: '100%' }}
                        />
                      </Collapse>
                    </Box>
                  </>
                );
              })}
            </Box>
          )}
        {matchDetail?.referee?.refereeId && (
          <CardReferee
            title="Wasit"
            content={`${matchDetail?.referee?.nickname} | ${matchDetail?.referee?.gameProviderId}`}
          />
        )}
        <CardTeamBattle
          showCounter={isPlayerActive && matchDetail?.status === STATUS.CHECKIN}
          home={{
            name: matchDetail?.firstTeam,
            maxPlayer: totalFirstTeamPlayerCheckin,
            currentPlayer: currentFirstTeamPlayerCheckin,
            player: matchDetail?.firstTeamPlayers
              ?.slice()
              .sort((a, _) => (a.level === 1 ? -1 : 1))
              .map((player: any, index: number) => (
                <Player
                  playerName={player.playerName}
                  playerInfo={player.nickname}
                  playerPhoto={player.avatarUrl}
                  avatarBorder
                  iconInfo
                  smallText
                  isCaptain={player.level == 1 ? true : false}
                  onClick={() => {
                    setPlayerData({
                      playerName: player?.playerName,
                      playerPhoto: player.avatarUrl,
                      gameId: player?.gameProviderId,
                      nickname: player?.nickname,
                      urlSlug: player?.urlSlug,
                    });
                    setIsOpenPlayerDetail(true);
                  }}
                  isActive={
                    isPlayerActive && matchDetail?.status == STATUS.CHECKIN && player.checkinAt
                  }
                  inactive={
                    isPlayerActive && matchDetail?.status == STATUS.CHECKIN && !player.checkinAt
                  }
                  key={index}
                  sx={{ padding: '0 12px', mb: '0' }}
                />
              )),
          }}
          away={{
            name: matchDetail?.secondTeam,
            maxPlayer: totalSecondTeamPlayerCheckin,
            currentPlayer: currentSecondTeamPlayerCheckin,
            player: matchDetail?.secondTeamPlayers
              ?.slice()
              .sort((a, _) => (a.level === 1 ? -1 : 1))
              .map((player: any, index: number) => (
                <Player
                  playerName={player.playerName}
                  playerInfo={player.nickname}
                  playerPhoto={player.avatarUrl}
                  avatarBorder
                  iconInfo
                  smallText
                  reverse
                  isCaptain={player.level == 1 ? true : false}
                  onClick={() => {
                    setPlayerData({
                      playerName: player?.playerName,
                      playerPhoto: player.avatarUrl,
                      gameId: player?.gameProviderId,
                      nickname: player?.nickname,
                      urlSlug: player?.urlSlug,
                    });
                    setIsOpenPlayerDetail(true);
                  }}
                  isActive={
                    isPlayerActive && matchDetail?.status == STATUS.CHECKIN && player.checkinAt
                  }
                  inactive={
                    isPlayerActive && matchDetail?.status == STATUS.CHECKIN && !player.checkinAt
                  }
                  key={index}
                  sx={{ padding: '0 12px', mb: '0' }}
                />
              )),
          }}
        />
      </Stack>

      {matchCompete.includes(matchDetail?.status) && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            maxWidth: '500px',
            right: 0,
            margin: '0 auto',
            zIndex: 20,
          }}
        >
          {matchDetail?.status === STATUS.SCHEDULED && !isPlayerActive && (
            <Box
              sx={theme => ({
                padding: '12px 16px',
                borderTop: `1px solid ${theme.palette.neutral530}`,
                bgcolor: '#131318',
              })}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '49px' }}
              >
                <Text sx={{ display: 'inline-block' }}>{t('match_user_not_member')}</Text>
              </Stack>
            </Box>
          )}
          {matchDetail?.status === STATUS.CHECKIN && isPlayerActive && (
            <>
              {!checkinTimeEnd && (
                <Box
                  sx={theme => ({
                    padding: '12px 16px',
                    borderTop: `1px solid ${theme.palette.neutral530}`,
                    bgcolor: '#131318',
                  })}
                >
                  <Countdown
                    intervalDelay={0}
                    date={matchDetail?.checkinEndAt}
                    renderer={({ formatted, completed }) => {
                      if (completed) {
                        setCheckinTimeEnd(true);
                        setPopupMatchDetailStatus({ isShown: true, match_id: match_id });

                        return false;
                      } else {
                        return isPlayerActive?.checkinAt == '' && isCaptain ? (
                          <>
                            <PrimaryButton variant="big" wide onClick={() => checkinMatch()}>
                              {t('match_checkin_now', {
                                minutes: formatted.minutes,
                                seconds: formatted.seconds,
                              })}
                            </PrimaryButton>
                          </>
                        ) : isPlayerActive?.checkinAt && !isCaptain ? (
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: '49px' }}
                          >
                            <Text sx={{ display: 'inline-block' }}>
                            {t('match_reminder_other_player')}
                            
                            </Text>{' '}
                            -{' '}
                            <Text
                              gradient
                              sx={{ display: 'inline-block' }}
                            >{`${formatted.minutes}:${formatted.seconds}`}</Text>
                          </Stack>
                        ) : isPlayerActive?.checkinAt == '' && !isCaptain ? (
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: '49px' }}
                          >
                            <Text sx={{ display: 'inline-block' }}>
                            {t('match_reminder_kapten')}
                            </Text>{' '}
                            -{' '}
                            <Text
                              gradient
                              sx={{ display: 'inline-block' }}
                            >{`${formatted.minutes}:${formatted.seconds}`}</Text>
                          </Stack>
                        ) : (
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: '49px' }}
                          >
                            <Text sx={{ display: 'inline-block' }}>
                            {t('match_reminder_other_player')}
                            
                            </Text>{' '}
                            -{' '}
                            <Text
                              gradient
                              sx={{ display: 'inline-block' }}
                            >{`${formatted.minutes}:${formatted.seconds}`}</Text>
                          </Stack>
                        );
                      }
                    }}
                  />
                </Box>
              )}
            </>
          )}
          {buttonSendResultUploadCondition && (
            <Box
              sx={theme => ({
                padding: '12px 16px',
                borderTop: `1px solid ${theme.palette.neutral530}`,
                bgcolor: '#131318',
              })}
            >
              <Countdown
                intervalDelay={0}
                date={matchDetail?.uploadDeadlineAt}
                renderer={({ formatted }) => {
                  return (
                    <>
                      <PrimaryButton variant="big" wide onClick={handleMatchUploadLink}>
                        {t('match_send_result', {
                          hours: formatted.hours,
                          minutes: formatted.minutes,
                          seconds: formatted.seconds,
                        })}
                      </PrimaryButton>
                    </>
                  );
                }}
              />
            </Box>
          )}
        </Box>
      )}

      <DrawerPrimary
        title={t('match_rules')}
        position="bottom"
        open={isOpenRules}
        onClose={() => setIsOpenRules(false)}
      >
        <Text
          variant="subtitle"
          dangerouslySetInnerHTML={{ __html: he.decode(tournamentDetail?.matchRules ?? '') }}
          sx={{
            'ol, ul': {
              padding: '0 0 0 20px',
              margin: '5px 0 8px',
            },
          }}
        />
      </DrawerPrimary>
      <DrawerPrimary
        position="bottom"
        open={isOpenPlayerDetail}
        onClose={() => setIsOpenPlayerDetail(false)}
        paperSx={{ overflow: 'visible' }}
      >
        <CardPlayerDetail
          playerName={playerData?.playerName}
          playerPhoto={playerData?.playerPhoto}
          gameId={playerData?.gameId}
          nickname={playerData?.nickname}
          url={`/player/${playerData?.urlSlug}`}
        />
      </DrawerPrimary>

      <Modal show={openModalError}>
        <InfoModal
          cta={<PrimaryButton onClick={() => setOpenModalError(false)}>Kembali</PrimaryButton>}
        >
          <Text textAlign="center">{modalErrorMsg}</Text>
        </InfoModal>
      </Modal>

      <Modal show={openModalMatchNotValid}>
        <InfoModal
          cta={
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: '100%' }}
              onClick={reloadPage}
            >
              <SecondaryButton wide>{t('match_popup_back')}</SecondaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%', textAlign: 'left' }}>
            <Text variant="h2" sx={{ mb: '8px' }}>
              {t('match_popup_not_valid_title')}
            </Text>
            <Text sx={theme => ({ color: theme.palette.neutral400 })}>
              {t('match_popup_not_valid_desc')}
            </Text>
          </Box>
        </InfoModal>
      </Modal>
      <Modal show={openModalMatchWinWO}>
        <InfoModal
          cta={
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: '100%' }}
              onClick={reloadPage}
            >
              <PrimaryButton wide>{t('match_popup_ok').toUpperCase()}</PrimaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%', textAlign: 'left' }}>
            <Text variant="h2" sx={{ mb: '8px' }}>
              {t('match_wo_win_title')}
            </Text>
            <Text sx={theme => ({ color: theme.palette.neutral400 })}>
              {t('match_wo_win_desc')}
            </Text>
          </Box>
        </InfoModal>
      </Modal>
      <Modal show={openModalMatchLoseWO}>
        <InfoModal
          cta={
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: '100%' }}
              onClick={reloadPage}
            >
              <SecondaryButton wide>{t('match_popup_back')}</SecondaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%', textAlign: 'left' }}>
            <Text variant="h2" sx={{ mb: '8px' }}>
              {t('match_wo_lose_title')}
            </Text>
            <Text sx={theme => ({ color: theme.palette.neutral400 })}>
              {t('match_wo_lose_desc')}
            </Text>
          </Box>
        </InfoModal>
      </Modal>

      <Modal show={openModalUploadEnd}>
        <InfoModal
          cta={
            <>
              <SecondaryButton onClick={reloadPage} wide>
                {t('match_popup_back')}
              </SecondaryButton>
            </>
          }
        >
          <Text variant="h2" textAlign="center" mb="16px">
            {t('match_upload_time_end')}
          </Text>
        </InfoModal>
      </Modal>

      <Toast show={openToast} onClose={handleCloseToast} duration={3000}>
        <Ribbon
          icon={IconSource('check-gameon')}
          label={
            <Text variant="body" sx={{ color: 'success900' }}>
              {t('match-checkin-success')}
            </Text>
          }
          sx={GameOnToastStyle}
        />
      </Toast>
    </>
  );
};

export default PlayerPage;
