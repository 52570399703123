import styled, { css } from 'styled-components';
import { ButtonSelectProps } from '.';

export const BtnSelect = styled.button<ButtonSelectProps>`
  justify-content: center;
  align-items: center;
  color: #6a6363;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Roboto';
  padding: ${props => props.btnPadding ?? '6px 10px'};
  position: ${props => props.btnPosition ?? 'static'};
  width: ${props => props.btnWidth ?? 'auto'};
  height: ${props => props.btnHeight ?? '32px'};
  background: ${props => props.btnBackground ?? 'rgba(55, 57, 66, 0.4)'};
  border-radius: ${props => props.btnRadius ?? '8px'};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: not-allowed;
  ${props =>
    props.isDisable === false &&
    css`
      cursor: pointer;
      &:active {
        opacity: 0.7;
      }
    `}
`;
