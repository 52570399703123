import authSaga from './auth/AuthSaga';
import gameListSaga from './game/GameListSaga';
import scrimDetailGameSaga from './scrim/ScrimDetailGameSaga';
import scrimDetailSaga from './scrim/ScrimDetailSaga';
import scrimDraftSaga from './scrim/ScrimDraftSaga';
import scrimListSaga from './scrim/ScrimListSaga';
import myTeamSaga from './team/MyTeamSaga';
import teamDetailSaga from './team/TeamDetailSaga';
import teamDraftSaga from './team/TeamDraftSaga';
import teamInvitationSaga from './team/TeamInvitationSaga';
import teamListSaga from './team/TeamListSaga';
import teamMemberSaga from './team/TeamMemberSaga';
import { all, fork } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    fork(scrimDraftSaga),
    fork(scrimListSaga),
    fork(scrimDetailSaga),
    fork(scrimDetailGameSaga),
    fork(gameListSaga),
    fork(authSaga),
    fork(myTeamSaga),
    fork(teamMemberSaga),
    fork(teamInvitationSaga),
    fork(teamDetailSaga),
    fork(teamDraftSaga),
    fork(teamListSaga)
  ]);
}
