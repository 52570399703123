import { BottomNavigation, BottomNavigationAction, Avatar, Box, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useState, useEffect, SyntheticEvent } from 'react';
import SVG from 'react-inlinesvg';

interface BottomBarDataProps {
  title: string;
  icon: string;
  dest: string;
  paths: Array<string>;
  isselected: boolean;
}

export interface BottomBarProps {
  data: Array<BottomBarDataProps>;
  photo?: string;
}

const BottomNavigationStyled = styled(BottomNavigation)`
  height: 54px;
  max-width: 500px;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 10;
  transform: translate3d(0, 0, 0);
  background: ${({ theme }) => theme.palette.neutral700};
  border-top: 1px solid ${({ theme }) => theme.palette.neutral530};
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  transition: none;
`;

const BottomNavigationActionStyled = styled(BottomNavigationAction)`
  min-width: 60px;
  padding: 15px 5px 5px 5px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  svg {
    width: 24px;
    height: 24px;
  }
  .MuiBottomNavigationAction-label {
    font-weight: 600;
    letter-spacing: 1px;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);
    transition: none;
    &.Mui-selected {
      font-size: 8px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.neutral400};
      margin-top: 4px;
    }
  }
`;

export const BottomBar = ({ data, photo }: BottomBarProps) => {
  const [selected, setSelected] = useState('');
  const urlLocation = history.location.pathname;

  const getCurrentUrl =
    data &&
    data
      .map(data => {
        return (
          ((urlLocation || '') === data.dest ||
            (data.paths.length > 0 && data.paths.includes(urlLocation))) &&
          data.dest
        );
      })
      .filter(data => Boolean(data))
      .toLocaleString();

  useEffect(() => {
    setSelected(getCurrentUrl);
  }, [getCurrentUrl]);

  const handleChange = (e: SyntheticEvent, newValue: string) => {
    e.defaultPrevented;
    setSelected(newValue);
    if (history.location.pathname !== newValue) {
      history.push(newValue);
    }
  };

  return (
    <BottomNavigationStyled value={selected} onChange={handleChange}>
      {data &&
        data.map((data: BottomBarDataProps) => {
          const handleActiveIcon = () => {
            if (selected === data.dest) {
              if (data.dest === '/account')
                if (photo)
                  return <Avatar alt={data.title} src={photo} sx={{ width: 24, height: 24 }} />;
                else return <SVG src={IconSource(`${data.icon}-active`)} />;

              return (
                <>
                  <Stack>
                    <SVG src={IconSource(`${data.icon}-active`)} />
                    <Box sx={{ display: 'none' }}>
                      <SVG src={IconSource(data.icon)} />
                    </Box>
                  </Stack>
                </>
              );
            } else {
              if (data.dest === '/account')
                if (photo)
                  return <Avatar alt={data.title} src={photo} sx={{ width: 24, height: 24 }} />;
                else return <SVG src={IconSource(data.icon)} />;

              return (
                <>
                  <Stack>
                    <SVG src={IconSource(data.icon)} />
                    <Box sx={{ display: 'none' }}>
                      <SVG src={IconSource(`${data.icon}-active`)} />
                    </Box>
                  </Stack>
                </>
              );
            }
          };

          return (
            <BottomNavigationActionStyled
              key={data.title}
              label={data.title}
              value={data.dest}
              icon={handleActiveIcon()}
            />
          );
        })}
    </BottomNavigationStyled>
  );
};
