export type ErrorCodeType = keyof typeof ErrorCode;

export const getErrorMessageLocale = (errors: any) => {
  if (errors) {
    let objectName: string[] = Object.getOwnPropertyNames(errors);
    var errorObj: any = {};
    Object.values(errors).forEach((props: any, i) => {
      if (props[0].code){
        Object.defineProperty(errorObj, objectName[i].replace('[', '').replace(']', '').replace(/[0-9]/, ''), {
          value: getErrorMessageKey(props[0].code),
          configurable: true,
          enumerable: true,
          writable: true,          
        });
      }           
    });
    return errorObj;
  }
  return undefined;
};

/**
 * TODO: error message in file translations
 * @description Gets error message key
 * @param code 
 * {@link ErrorCodeType}
 * @returns  useTranslations() with i18next
 */
export const getErrorMessageKey = (code: ErrorCodeType) => {
  return `errors:${code}`;
};
/**
 * @description list error code, response **JSON** like this:
 *
 *                  "errors":{[
 *                  "startAt":[
 *                    {"code":"RX1003"}]
 *                  ]}
 *
 *
 * {@link [Sample API Response](https://api-sand.rex.agatedev.net/swagger/index.html)}
 *
 * {@link [API Spec List](https://docs.google.com/spreadsheets/d/1JA5zJKNe-eYtnYENkradlyoA6Pa3V3GotUjIzZmmQ8E/edit#gid=691861309&range=E7)}.
 *
 * @see {@link ErrorCode ErrorCode}.
 */
export enum ErrorCode {
  /**
   * This code represent no error
   * 
   * @see {@link RX0NoError}
   */
  RX0NoError = 0,
  /**
   * This code represent if contains any bad word(s).
   *
   *
   * @see {@link RX1001}
   */
  RX1001 = 1001,
  /**
   * This code represent if not matched with alphabet white space regex.
   *
   * @see {@link RX1002}
   */
  RX1002 = 1002,
  /**
   * This code represent if not matched with alphanumeric white space regex.
   *
   * @see {@link RX1003}
   */
  RX1003 = 1003,
  /**
   * This code represent if not matched with alphanumeric regex.
   *
   * @see {@link RX1004}
   */
  RX1004 = 1004,
  /**
   * This code represent if not valid url.
   *
   * @see {@link RX1005}
   */
  RX1005 = 1005,
  /**
   * This code represent if not valid password.
   *
   * @see {@link RX1006}
   */
  RX1006 = 1006,
  /**
   * This code represent must not be empty.
   *
   * @see {@link EM1015}
   */
   EM1015 = 1015,
  /**
   * This code repressent when data not exist.
   *
   * @see {@link RX1098}
   */
  RX1098 = 1098,
  /**
   * This code repressent when data duplicated.
   *
   * @see {@link RX1099}
   */
  RX1099 = 1099,
  /** Tournament related will be using code from 100 to 199.
   *
   * This code represent a case when a user is not an EO or PA but wants to access resources avaliable for EO or PA only.
   *
   * @see {@link RX1100UnauthorizeTournament}
   */
  RX1100UnauthorizeTournament = 1100,
  /**
   *This code is used when Team or tournament or game or eo not found.
   *
   * @see {@link RX1101TournamentNotFound}
   */
  RX1101TournamentNotFound = 1101,
  /**
   * This code is used when get teams data for a tournament fail.
   *
   * @see {@link RX1102GetTeamsDataFail}
   */
  RX1102GetTeamsDataFail = 1102,
  /**
   * This codre is used for unknown error of  tournament"s controllers.
   *
   * @see {@link RX1109UnknownError}
   */
  RX1109UnknownError = 1109,
  /**
   * @return  is for error of generating matches of already generated tournament.
   *
   * @see {@link RX1110DoubleGenerateMatch}
   */
  RX1110DoubleGenerateMatch = 1110,
  /**
   * This code is used or unknown error of create / update tournament.
   *
   * @see {@link RX1115}
   */
  RX1115 = 1115,
  /**
   * This code represent error of bad request
   * @see {@link RX1116BadRequest}
   */
  RX1116BadRequest = 1116,
  /**
   * This code represent error get tournament by Id
   * @see {@link RX1117GetByIdFail}
   */
  RX1117GetByIdFail = 1117,
  /**
   * This code represent error get team membership
   * @see {@link RX1118GetTeamMembershipFail}
   */
  RX1118GetTeamMembershipFail = 1118,
  /**
   * This code represent error get team status
   * @see {@link RX1119GetTeamStatus}
   */
  RX1119GetTeamStatus = 1119,
  /**
   * This code represent error Tournament Registration Fail
   * @see {@link RX1120TournamentRegistrationFail}
   */
  RX1120TournamentRegistrationFail = 1120,
  /**
   * This code represent error Tournament invite player to tournament
   * @see {@link RX1121InvitePlayerTournamentFail}
   */
  RX1121InvitePlayerTournamentFail = 1121,
  /**
   * @return  This code repressent when team membership lower than MinTeamMember from game entity.
   *
   * @see {@link RX1201}
   */
  RX1201 = 1201,
  /**
   * @return  This code repressent when team membership greater than MaxTeamMember from game entity.
   *
   * @see {@link RX1202}
   */
  RX1202 = 1202,
  /**
   * @return  This code repressent when scrim player lower than MinScrimRoster from game entity.
   *
   * @see {@link RX1203}
   */
  RX1203 = 1203,
  /**
   * @return  This code repressent when scrim player greater than MaxScrimRoster from game entity.
   *
   * @see {@link RX1204}
   */
  RX1204 = 1204,
  /**
   * @return  This code repressent when scrim hours outside the applicable rules.
   *
   * @see {@link RX1205}
   */
  RX1205 = 1205,
  /**
   * This code represents an error when it is not possible to edit match result.
   *
   * @see {@link RX1206}
   */
  RX1206 = 1206,
  /**
   * This code represents an error when it is not possible to create match invitation.
   *
   * @see {@link RX1207}
   */
  RX1207 = 1207,
  /**
   * This code represents an error when it is not possible to report match result.
   *
   * @see {@link RX1208}
   */
  RX1208 = 1208,
  /**
   * This code represents an error when it is not possible to check in lobby on scrim.
   *
   * @see {@link RX1209}
   */
  RX1209 = 1209,
  /**
   * This code represents an error when it is not possible to post scrim match result.
   *
   * @see {@link RX1210}
   */
  RX1210 = 1210,
  /**
   * This code represents an error when it is not possible to post scrim match result if player check-in does not meet the minimum check-in amount.
   *
   * @see {@link RX1211}
   */
  RX1211 = 1211,
  /**
   * This code represents an error when grpc return error.
   *
   * @return Grpc Error.
   *
   * @see {@link RX1300}
   */
  RX1300 = 1300,
  /**
   * This code represents an error when current user has no school.
   *
   * @return Current user has no school.
   *
   * @see {@link RX1301}
   */
  RX1301 = 1301,
  /**
   * This code represents an error when invitation player to a team.
   *
   * @return Failed invitation player.
   *
   * @see {@link RX1302}
   */
  RX1302 = 1302,
  /**
   * This code represents an error when player to try to update game profile.
   *
   * @return Game profile already exists.
   *
   * @see {@link RX1303}
   */
  RX1303 = 1303,
  /**
   * This code represents an error when the second team as the creator invitation team when to create direct invitation.
   *
   * @return The second team can not be the same as the creator invitation team.
   *
   * @see {@link RX1304}
   */
  RX1304 = 1304,
  /**
   * This code represents an error when approve or reject invitation.
   *
   * @see {@link RX1305}
   */
  RX1305 = 1305,
  /**
   * This code represent an error when user get limit on request phone number token.
   *
   * @return You got limit to request token.
   *
   * @see {@link RX1306}
   */
  RX1306 = 1306,
  /**
   * @description This code represent an error when user failed to verify reset password token.
   *
   * @return Failed to verify reset password token.
   *
   * @see {@link RX1307}
   */
  RX1307 = 1307,
  /**
   * This code represent an error for invalid email or phone number format.
   *
   * @return email ? a : b is invalid email or phone number format.
   *
   * @see {@link RX1308}
   */
  RX1308 = 1308,
  /**
   * This code represent an error for email or phone number already exists when try to claim it.
   *
   * @return Email or phone number {0} already exists.
   *
   * @see {@link RX1309}
   */
  RX1309 = 1309,
  /**
   * This code represent an error for email or phone number in extetnal login already exists when try to claim it.
   *
   * @return Email or phone number {0} already exists in external login.
   *
   * @see {@link RX1310}
   */
  RX1310 = 1310,
}