import GradientButton from 'components/Buttons/GradientButton';
import { BorderLogo } from 'components/RadioButton/styles';
import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import history from 'modules/history';
import React from 'react';
import { TeamSchool } from 'redux/team/TeamListReducer';
import { Team } from 'types/team';
import { TeamListItem } from './styles';

export interface TeamListProps {
  variant?: 'Variant2' | 'Variant3' | 'Variant4';
  teamId?: string;
  teamLogoUrl?: string;
  gameText?: string;
  matchText?: string;
  teamName?: string;
  captainName?: string;
  textTopRight?: string;
  dataTeam?: Team;
  dataTeamSchool?: TeamSchool;
  schoolSlug?: string;
  margin?: string;
  onTopRightClick?: () => void;
  onLogoClick?: ((team?: Team, teamSchool?: TeamSchool) => void) | undefined;
  onClick?: ((team?: Team, teamSchool?: TeamSchool) => void) | undefined;
}

export function TeamList({
  variant,
  gameText,
  textTopRight,
  dataTeam,
  dataTeamSchool,
  schoolSlug,
  onTopRightClick,
  onClick,
  onLogoClick,
  matchText,
  teamLogoUrl,
  teamName,
  captainName,
  margin
}: TeamListProps) {
  return (
    <Stack direction="column" align="center" margin={margin}
    disableDrag isPressble={onClick ? true: false}>
      <TeamListItem
        onClick={() => {
          if (onClick) {
            onClick(dataTeam, dataTeamSchool);
          }
        }}
        id={dataTeam?.id}
      >
        <Stack direction="row" align="start" style={{ width: '90px' }} onClick={()=> {
          if(onLogoClick){
            onLogoClick(dataTeam, dataTeamSchool);
          }
          if(dataTeam || dataTeamSchool){
            const gameSlug = dataTeamSchool?.game?.urlSlug ?? dataTeam?.game?.urlSlug;
            const schoolSlugs = dataTeam?.school?.urlSlug ?? schoolSlug;
            const teamSlug = dataTeam?.urlSlug ?? dataTeamSchool?.urlSlug;
            if(gameSlug && schoolSlugs && teamSlug)
              history.push(`/team/${gameSlug}/${schoolSlugs}/${teamSlug}`)
          }
        }}>
          <BorderLogo bg={teamLogoUrl} />
        </Stack>
        <Stack direction="row" align="start" style={{ marginTop: '12px' }}>
          {variant !== 'Variant4' ? (
            <NormalText
              color="rgba(150, 147, 147, 1)"
              fontSize="12px"
              fontWeight="500"
              fontStyle="normal"
              position="absolute"
            >
              {gameText}
            </NormalText>
          ) : (
            <NormalText
              color="white"
              fontSize="14px"
              fontWeight="700"
              fontStyle="normal"
              textAlign="start"
              position="absolute"
            >
              {teamName}
            </NormalText>
          )}
        </Stack>
        {variant === 'Variant3' && (
          <Stack align="end" style={{ marginTop: '12px' }}>
            <GradientButton
              variant="cta"
              onClick={onTopRightClick}
              height="auto"
              padding="0px"
              margin="-2px 12px"
            >
              <span>{textTopRight}</span>
            </GradientButton>
          </Stack>
        )}
        {variant !== 'Variant4' ? (
          <NormalText
            color="white"
            fontSize="14px"
            fontWeight="700"
            fontStyle="normal"
            style={{
              position: 'absolute',
              marginLeft: '102px',
              marginTop: '40px',
            }}
          >
            {teamName}
          </NormalText>
        ) : (
          <NormalText
            color="rgba(150, 147, 147, 1)"
            fontSize="12px"
            fontWeight="500"
            fontStyle="normal"
            style={{
              position: 'absolute',
              marginLeft: '102px',
              marginTop: '38px',
            }}
          >
            {captainName}
          </NormalText>
        )}
        <NormalText
          color="rgba(150, 147, 147, 1)"
          fontSize="12px"
          fontWeight="500"
          fontStyle="normal"
          style={{
            position: 'absolute',
            marginLeft: '102px',
            marginTop: '65px',
          }}
        >
          {matchText}
        </NormalText>
      </TeamListItem>
    </Stack>
  );
}

export default TeamList;
