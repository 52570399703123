import Modal from 'components/layouts/Modal';
import React, { FC, ReactNode } from 'react';

import { MatchesList } from './styles';

export interface MatchLayoutProps {
  children: ReactNode | HTMLAllCollection;
  props?: any;
  style?: any;
  showModal?: boolean;
  modalContent?: ReactNode;
}

export const MatchLayout: FC<MatchLayoutProps> = ({ children, style, ...props }) => (
  <MatchesList style={style} {...props}>
    {children}
    <Modal show={props.showModal}>{props.modalContent}</Modal>
  </MatchesList>
);

export default MatchLayout;
