import React, { ReactNode } from 'react';
import { Theme } from '@mui/material';
import {
    RadioButtonInput,
    RadioButtonCircle,
    RadioButtonWrapper,
    RadioLabel,
    Frame,
  } from './styles';

export type MatchRadioButtonProps = {
    theme?: Theme;
    children?: ReactNode;
    iswin?: string;
    id?: string;
    name?: string;
    value?: string | ReadonlyArray<string> | number | undefined;
    checked?: boolean;
    onChange?: () => void;
};

export const MatchRadioButton = (props: MatchRadioButtonProps) => {
    return (
      <>
        <Frame {...props}>
            <RadioButtonWrapper>
                <RadioButtonInput
                    id={props.id}
                    name={props.name}
                    type="radio"
                    value={props.value}
                    checked={props.checked}
                    iswin={props.iswin}
                    onChange={props.onChange}
                />
                <RadioButtonCircle />
            </RadioButtonWrapper>
            <RadioLabel>{props.children}</RadioLabel>
        </Frame>
      </>
    );
};