import React, { useState } from 'react';
import { ScrimNavInterface } from './ScrimNavInterface';
import { ScrimNav, ScrimNavItem, ScrimNavLink } from './ScrimNavStyles';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import history from 'modules/history';

export const ExploreNavigation = (p:{onTabChange: (v: string) => void; ScrimNavLinks: ScrimNavInterface[]}) => {
  const [dataNav] = React.useState(p.ScrimNavLinks)
  return (
    <Router>
      <ScrimNavigation links={dataNav} onTabChange={p.onTabChange}/>
      <Switch>
        {dataNav.map((data: ScrimNavInterface) => {
          if (data.isSelected === true || history.location.pathname?.toLowerCase().match(data.to)) {
            data.isSelected = true 
            return <Route exact path={data.to} component={data.component} key={data.to} />
          }else return <Route exact path={data.to} component={data.component} key={data.to} />
        })}
      </Switch>
    </Router>
  );
};

export const ScrimNavigation = (p:{links: ScrimNavInterface[]; onTabChange: (v: string) => void}) => {
  const [dataNav, setDataNav] = useState(p.links);
  const clickHandle = (nav: ScrimNavInterface) => {    
    if(p.onTabChange)
      p.onTabChange(nav.to)
    let temp = [...dataNav]  
    temp.find((v, i)=> {
      if(v.to === nav.to){
        let updateNav = temp.fill({...nav, isSelected: true, to: nav.to}, i, i + 1)
        setDataNav([...updateNav]);
        history.replace(nav.to)
      }else{
        let updateNav = temp.fill({...v, isSelected: false, to: v.to}, i, i + 1)
        setDataNav([...updateNav]);
      }
    })
  };

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = (e: any) => {
    if (!showScroll && e.srcElement.scrollTop > 160) setShowScroll(true);
    if (showScroll && e.srcElement.scrollTop <= 161) setShowScroll(false);
  };

  window.addEventListener('scroll', checkScrollTop, true);

  return (
    <ScrimNav id="tabscrim" isSticky={showScroll}>
      {dataNav.map((data: ScrimNavInterface, i) => (
        <ScrimNavItem
          key={data.title}
          isSticky={i === 0 ? showScroll : false}
          marginLeft={i >= 1 ? '8px' : '16px'}
          isSelected={data.isSelected}
          onClick={() => document.getElementById(data.title)?.click()}
        >
          <ScrimNavLink
            id={data.title}
            key={data.title}
            to={data?.to}
            isSelected={data.isSelected}
            onClick={() => clickHandle(data)}
          >
            {data.title}
          </ScrimNavLink>
        </ScrimNavItem>
      ))}
    </ScrimNav>
  );
};