import PaginationIndicator from 'components/Pagination/PaginationIndicator';
import React, { ReactNode, RefObject } from 'react';
import { ScrollContainer } from './styles';

interface PageIndicatorProps {
  show: boolean;
  align: 'left' | 'center' | 'right';
  position: 'top' | 'bottom' | 'right' | 'left';
}

export interface ScrollViewProps {
  id?: string;
  display?: string;
  position?: string;
  margin?: string;
  direction: 'horizontal' | 'vertical' | 'both';
  scrollVisibility?: boolean;
  align?: 'start' | 'center' | 'end';
  children: ReactNode;
  pageIndicator?: PageIndicatorProps;
  maxWidth?: string;
  maxHeight?: string;
}

function ScrollView({
  id,
  display,
  position,
  margin = '16px 16px 0px 16px',
  direction = 'horizontal',
  scrollVisibility = false,
  align,
  children,
  pageIndicator,
  maxHeight,
  maxWidth,
}: ScrollViewProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { onMouseDown } = useDraggableScroll(ref, { direction: direction });
  const [getChildClient, setChildClient] = React.useState({ width: 0, height: 0 });

  React.useLayoutEffect(() => {
    if (pageIndicator?.show)
      setChildClient({
        width: ref.current?.firstElementChild?.clientWidth ?? 0,
        height: ref.current?.firstElementChild?.clientHeight ?? 0,
      });
  }, [ref.current]);

  const childCount = React.Children.count(children);
  let [childVisible, setChildVisible] = React.useState(0);


  const scrollAtItem = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (pageIndicator?.show) {
      if (direction === 'horizontal') {
        if (
          e.currentTarget.scrollLeft >= getChildClient.width * (childVisible * 1.1) &&
          e.currentTarget.scrollLeft <= e.currentTarget.scrollWidth - e.currentTarget.clientWidth &&
          childVisible < childCount
        ) {
          setChildVisible(childVisible + 1);
        }
        if (
          e.currentTarget.scrollLeft >=
          e.currentTarget.scrollWidth - e.currentTarget.clientWidth
        ) {
          setChildVisible(childCount - 1);
        }
        if (
          childVisible >= 0 &&
          e.currentTarget.scrollLeft <= getChildClient.width * (childVisible * 0.6)
        ) {
          setChildVisible(childVisible - 1);
        }
        if (e.currentTarget.scrollLeft <= getChildClient.width) {
          setChildVisible(0);
        }
      }
      if (direction === 'vertical') {
        if (
          e.currentTarget.scrollTop >= getChildClient.height * (childVisible * 1.2) &&
          e.currentTarget.scrollTop <=
            e.currentTarget.scrollHeight - e.currentTarget.clientHeight &&
          childVisible < childCount
        ) {
          setChildVisible(childVisible + 1);
        }
        if (
          e.currentTarget.scrollTop >=
          e.currentTarget.scrollHeight - e.currentTarget.clientHeight
        ) {
          setChildVisible(childCount - 1);
        }
        if (
          childVisible >= 0 &&
          e.currentTarget.scrollTop <= getChildClient.height * (childVisible * 0.6)
        ) {
          setChildVisible(childVisible - 1);
        }
        if (e.currentTarget.scrollTop <= getChildClient.height) {
          setChildVisible(0);
        }
      }
    }
  };

  return (
    <>
      {pageIndicator?.show && pageIndicator.position === 'top' && (
        <PaginationIndicator
          pageCount={React.Children.count(children)}
          selectedIndex={0}
          itemPosition={pageIndicator.align}
          direction={direction}
        />
      )}
      <ScrollContainer
        id={id}
        ref={ref}
        display={display}
        position={position}
        margin={margin}
        direction={direction}
        scrollVisibility={scrollVisibility}
        align={align}
        onMouseDown={onMouseDown}
        onScroll={scrollAtItem}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
      >
        {children}
      </ScrollContainer>

      {pageIndicator?.show && pageIndicator.position === 'bottom' && (
        <PaginationIndicator
          pageCount={childCount}
          selectedIndex={childVisible}
          itemPosition={pageIndicator.align}
          direction={direction}
        />
      )}
    </>
  );
}

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export function getValidChildren(children: React.ReactNode) {
  return React.Children.toArray(children).filter(child =>
    React.isValidElement(child),
  ) as React.ReactElement[];
}

export function useDraggableScroll(
  ref: RefObject<HTMLElement>,
  options: {
    direction?: 'vertical' | 'horizontal' | 'both';
  } = { direction: 'both' },
) {
  if (process.env.NODE_ENV === 'development') {
    if (typeof ref !== 'object' || typeof ref.current === 'undefined') {
      console.error('`useDraggableScroll` expects a single ref argument.');
    }
  }

  const { direction } = options;
  let initialPosition = { scrollTop: 0, scrollLeft: 0, mouseX: 0, mouseY: 0 };
  let initialTouchPosition = { scrollTop: 0, scrollLeft: 0, touchX: 0, touchY: 0 };

  const touchMoveHandler = (event: { targetTouches: TouchList }) => {
    if (ref.current) {
      const dx = event.targetTouches[0].clientX - initialTouchPosition.touchX;
      const dy = event.targetTouches[0].clientY - initialTouchPosition.touchY;

      if (direction !== 'horizontal') ref.current.scrollTop = initialTouchPosition.scrollTop - dy;
      if (direction !== 'vertical') ref.current.scrollLeft = initialTouchPosition.scrollLeft - dx;
    }
  };

  const touchEndHandler = () => {
    if (ref.current) ref.current.style.cursor = 'grab';
    document.removeEventListener('touchmove', touchMoveHandler);
    document.removeEventListener('touchend', touchEndHandler);
  };

  const onTouchStart = (event: { targetTouches: React.TouchList }) => {
    if (ref.current) {
      initialTouchPosition = {
        scrollLeft: ref.current.scrollLeft,
        scrollTop: ref.current.scrollTop,
        touchX: event.targetTouches[0].clientX,
        touchY: event.targetTouches[0].clientY,
      };
      ref.current.style.cursor = 'grabbing';
      ref.current.style.userSelect = 'none';
      document.addEventListener('touchmove', touchMoveHandler);
      document.addEventListener('touchend', touchEndHandler);
    }
  };

  const mouseMoveHandler = (event: { clientX: number; clientY: number }) => {
    if (ref.current) {
      const dx = event.clientX - initialPosition.mouseX;
      const dy = event.clientY - initialPosition.mouseY;
      if (direction !== 'horizontal') ref.current.scrollTop = initialPosition.scrollTop - dy;
      if (direction !== 'vertical') ref.current.scrollLeft = initialPosition.scrollLeft - dx;
    }
  };

  const mouseUpHandler = () => {
    if (ref.current) ref.current.style.cursor = 'grab';
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const onMouseDown = (event: { clientX: number; clientY: number }) => {
    if (ref.current) {
      initialPosition = {
        scrollLeft: ref.current.scrollLeft,
        scrollTop: ref.current.scrollTop,
        mouseX: event.clientX,
        mouseY: event.clientY,
      };
      ref.current.style.cursor = 'grabbing';
      ref.current.style.userSelect = 'none';
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  };
  return { onMouseDown, onTouchStart, initialPosition, initialTouchPosition };
}

export default ScrollView;
