import { now } from '@gilbarbara/helpers';
import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import moment from 'moment-timezone';
import 'moment/locale/id';
import React from 'react';
import { Reducer } from 'redux';
import { ActionCreator, ActionsMapReducer, GenericFunction, StoreAction } from 'types';

/**
 * Create an action
 */
export function createAction<T extends GenericFunction>(
  type: string,
  payloadCreator: T,
): ActionCreator<Parameters<T>, ReturnType<T>> {
  if (!payloadCreator) {
    throw new TypeError('Expected a function');
  }

  return (...args: any[]) => ({
    type,
    payload: payloadCreator(...args),
  });
}

/**
 * Create a reducer
 */
export function createReducer<State>(
  actionsMap: ActionsMapReducer<State>,
  defaultState: State,
): Reducer<State, StoreAction> {
  return (state = defaultState, action: StoreAction) =>
    produce(state, (draft: State) => {
      const fn = actionsMap[action.type];

      if (fn) {
        return fn(draft, action);
      }

      return draft;
    });
}

/**
 * Check if cache is valid
 */
export function hasValidCache(lastUpdated: number, max = 10): boolean {
  if (!navigator.onLine) {
    return true;
  }

  return lastUpdated + max * 60 > now();
}

export const base64toBlob = (dataImage: string): Blob => {
  if (dataImage) {
    const b64 = dataImage.split(',')[1];
    const fileType = dataImage.split(',')[0].replace('data:', '').replace(';base64', '');
    const byteCharacters = atob(b64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    return blob;
  }
  return new Blob();
};

export function getDaysArray(startDate: Dayjs, endDate: Dayjs) {
  const dateArray = new Array();
  let currentDate = startDate;
  while (currentDate.isBefore(dayjs(endDate).add(1, 'day'))) {
    dateArray.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }
  return dateArray;
}

export function getTimesArray(startTime: Dayjs, stopTime: Dayjs, interval: number) {
  var timeArray = new Array();
  var currentTime = startTime;
  while (currentTime.isBefore(stopTime.add(1, 'minute'))) {
    timeArray.push(currentTime);
    currentTime = currentTime.add(interval, 'minute');
  }
  return timeArray;
}

/**
 * @convertDateToString
format return YYYY-MMMM-DD
*/
export const convertDateToString = (date: Date) => {
  return new Date(date).toLocaleDateString('id-ID', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
};

/**
 * @convertDateTimeToString
format return HH:mm:ss
*/
export const convertDateTimeToString = (time: Date) => {
  return new Date(time)
    .toLocaleTimeString('id-ID', {
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace('.', ':');
};

export const dateFormat = (time?: number | Date, timeZone?: string, locale?: 'id' | 'en') => {
  return moment(time ? time : new Date())
    .tz(timeZone ?? 'Asia/Jakarta')
    .locale(locale ?? 'id');
};

export const limitedChar = (string: any = 0, charLimit: any = 30) => {
  var totalCut = string.length - charLimit;
  return string?.length > charLimit
    ? string?.substring(0, string.length - totalCut) + '...'
    : string;
};

/**
 * Count days with minute
 */
export const parseTimeLeftDay = (p: {
  dateTime?: Date;
  subtract?: number;
  locale?: 'en' | 'id';
  variant?: moment.unitOfTime.DurationConstructor;
}) => {
  var given = moment(p.dateTime, 'YYYY-MM-DD');
  var current = moment().startOf('day');
  return moment.duration(given.diff(current)).asDays();
};

/**
 * Check if days same
 */
export const parseTimeDay = (p: {
  dateTime?: Date;
  subtract?: number;
  locale?: 'en' | 'id';
  variant?: moment.unitOfTime.DurationConstructor;
}) => {
  var given = moment(p.dateTime, 'YYYY-MM-DD');
  var current = moment().startOf('day');
  return given.isSame(current, 'day');
};

export const parseTimeLeft = (p: {
  dateTime?: Date;
  subtract?: number;
  locale?: 'en' | 'id';
  variant?: moment.unitOfTime.DurationConstructor;
}) => {
  const now = moment().add(p.subtract ?? 10, p.variant ?? 'minutes');
  const deadline = moment(p.dateTime);

  if (p.locale === 'en') {
    moment.updateLocale('en', {
      relativeTime: {
        future: '%s remaining',
        past: '%s ago',
        s: '%d seconds',
        ss: '%d seconds',
        m: '%d minutes',
        mm: '%d minutes',
        h: '%d hour',
        hh: '%d hour',
        d: '%d day',
        dd: '%d day',
        w: '%d week',
        ww: '%d week',
        M: '%d month',
        MM: '%d month',
        y: '%d year',
        yy: '%d year',
      },
    });
  } else if (p.locale === 'id') {
    moment.updateLocale('id', {
      relativeTime: {
        future: '%s tersisa',
        past: '%s yang lalu',
        s: '%d detik',
        ss: '%d detik',
        m: '%d menit',
        mm: '%d menit',
        h: '%d jam',
        hh: '%d jam',
        d: '%d hari',
        dd: '%d hari',
        w: '%d minggu',
        ww: '%d minggu',
        M: '%d bulan',
        MM: '%d bulan',
        y: '%d tahun',
        yy: '%d tahun',
      },
    });
  }
  if (now.isAfter(deadline)) {
    return '';
  }
  return deadline.from(now);
};

export const parseTimeLeftCheckIn = (p: {
  dateTime?: Date;
  subtract?: number;
  locale?: 'en' | 'id';
  variant?: moment.unitOfTime.DurationConstructor;
}) => {
  const now = moment().add(p.subtract, p.variant ?? 'minutes');
  const deadline = moment(p.dateTime);
  if (p.locale === 'en') {
    moment.updateLocale('en', {
      relativeTime: {
        future: '%s remaining',
        past: '%s ago',
        s: '%d seconds',
        ss: '%d seconds',
        m: '%d minutes',
        mm: '%d minutes',
        h: '%d hour',
        hh: '%d hour',
        d: '%d day',
        dd: '%d day',
        w: '%d week',
        ww: '%d week',
        M: '%d month',
        MM: '%d month',
        y: '%d year',
        yy: '%d year',
      },
    });
  } else if (p.locale === 'id') {
    moment.updateLocale('id', {
      relativeTime: {
        future: '%s tersisa',
        past: '%s yang lalu',
        s: '%d detik',
        ss: '%d detik',
        m: '%d menit',
        mm: '%d menit',
        h: '%d jam',
        hh: '%d jam',
        d: '%d hari',
        dd: '%d hari',
        w: '%d minggu',
        ww: '%d minggu',
        M: '%d bulan',
        MM: '%d bulan',
        y: '%d tahun',
        yy: '%d tahun',
      },
    });
  }
  if (now.isAfter(deadline)) {
    return '';
  }
  return deadline.from(now);
};

export function isFunction(obj: any) {
  return typeof obj == 'function';
}

export function isObject(obj: any) {
  var type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
}

export function disableReactDevTools() {
  // Ensure the React Developer Tools global hook exists
  //@ts-ignore
  if (!isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
    return;
  }

  // Replace all global hook properties with a no-op function or a null value
  //@ts-ignore
  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === 'renderers') {
      // prevents console error when dev tools try to iterate of renderers
      //@ts-ignore
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
      continue;
    } //@ts-ignore
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
      //@ts-ignore
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop],
    )
      ? Function.prototype
      : null;
  }
}

export function LogDev(
  LOG_TYPE: 'INFO' | 'ERROR' | 'WARNING' | 'DEFAULT' | 'TRACE' | 'TIME' | 'TABLE',
  message?: any,
  ...optionalParams: any[]
) {
  if (process.env.NODE_ENV !== 'production') {
    switch (LOG_TYPE) {
      case 'INFO': {
        return console.info(message, optionalParams.length === 0 ? '' : optionalParams);
      }
      case 'ERROR': {
        return console.error(message, optionalParams.length === 0 ? '' : optionalParams);
      }
      case 'WARNING': {
        return console.warn(message, optionalParams.length === 0 ? '' : optionalParams);
      }
      case 'TRACE': {
        return console.trace(message, optionalParams.length === 0 ? '' : optionalParams);
      }
      case 'TIME': {
        return console.timeLog(message, optionalParams.length === 0 ? '' : optionalParams);
      }
      case 'TABLE': {
        return console.table(message, optionalParams);
      }
      default: {
        return console.log(message, optionalParams.length === 0 ? '' : optionalParams);
      }
    }
  }
}

export function currencyFormat(num: number, decimal: number) {
  return 'Rp ' + num.toFixed(decimal ?? 2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

interface CountDownReturn {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

function calculateTimeLeft(endDate: string) {
  const difference = +new Date(endDate) - +new Date();
  let timeLeft: CountDownReturn = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

export function countdownTimer(endDate: string) {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(endDate));

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  if (timeLeft.days == 0 && timeLeft.hours == 0 && timeLeft.minutes == 0 && timeLeft.seconds == 0) {
    return '';
  } else {
    return (
      (timeLeft.days == 0 ? '' : (timeLeft.days < 10 ? '0' : '') + timeLeft.days + ':') +
      (timeLeft.hours < 10 ? '0' : '') +
      timeLeft.hours +
      ':' +
      (timeLeft.minutes < 10 ? '0' : '') +
      timeLeft.minutes +
      ':' +
      (timeLeft.seconds < 10 ? '0' : '') +
      timeLeft.seconds
    );
  }
}

export const delayExecute = (delay: number, value?: any) => {
  let timer: any = 0;
  let reject: any = null;
  const promise = new Promise((resolve, _reject) => {
    reject = _reject;
    timer = setTimeout(resolve, delay, value);
  });
  return {
    get promise() {
      return promise;
    },
    cancel() {
      if (timer) {
        clearTimeout(timer);
        timer = 0;
        reject();
        reject = null;
      }
    },
  };
};

interface ShareType {
  title: string;
  message?: string;
  url?: string;
  handleIconShare?: () => void;
}

export const onClickShare = ({
  title,
  message = '',
  url = window.location.href,
  handleIconShare,
}: ShareType) => {
  const shareOptions = {
    title,
    url,
    message,
  };

  if (navigator.share) {
    try {
      navigator.share(shareOptions);
    } catch (error) {
      console.log(`Oops! I couldn't share to the world because: ${error}`);
    }
  } else {
    console.log('Web share is currently not supported on this browser. Please provide a callback');
  }

  handleIconShare ? handleIconShare() : null;
};

export const handleLoader = (data: any, status: string) => {
  if (status !== 'succeeded') {
    if (data || (data ?? []).length > 0) return 'none';
    return '';
  }
  return 'none';
};

export const handleContent = (data: any, status: string) => {
  if (status !== 'succeeded') {
    if (data || (data ?? []).length > 0) return '';
    return 'none';
  }
  return '';
};
