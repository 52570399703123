import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ScrimNav = styled.div<any>`
  position: -webkit-sticky;
  position: sticky;
  z-index: 199;
  top: 56px;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  ${p =>
    p.isSticky
      ? `background: #15141af8; 
    padding: 12px 0 12px 0; 
    margin: 0px;
  `
      : `background: transparent;  
    padding: 6px 0 6px 0;
   margin: 10px 0px 0px 0px;
  `}
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
`;

interface LinkProps {
  isSelected: boolean;
}

export const ScrimNavItem1 = styled.div<LinkProps>`
  display: flex;
  width: auto;
  border-radius: 8px;
  ${p =>
    p.isSelected
      ? `
    background: linear-gradient(131.42deg, #E75570 32.19%, #ED676A 53.45%, #F17267 66.7%, #FD965B 83.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`
      : 'color: #6A6363'};
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
`;

export const ScrimNavItem = styled.button<any>`
  background: ${p => (p.isSelected ? 'rgba(55, 57, 66, 0.4)' : 'transparent')};
  border-radius: 8px;
  margin-right: 8px;
  height: auto;
  width: fit-content;
  text-align: center;
  flex-flow: row;
  &:active {
    background: rgba(55, 57, 66, 0.4);
    border-radius: 8px;
  }
  &:hover {
    background: rgba(55, 57, 66, 0.4);
    border-radius: 8px;
  }
  ${p =>
    p.isSticky
      ? `  
    margin-left: 16px;
  `
      : `
    margin-left: 16px;
  `}
  margin-left: ${p => p.marginLeft};
  -webkit-user-drag: none;
`;

export const ScrimNavLink = styled(Link)<LinkProps>`
  display: inline-block;
  border-radius: 2px;
  padding: 6px 10px;
  background: ${p =>
    p.isSelected
      ? 'linear-gradient(131.42deg, #E75570 32.19%, #ED676A 53.45%, #F17267 66.7%, #FD965B 83.78%)'
      : 'rgba(106, 99, 99, 1)'};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: ${p => (p.isSelected ? 700 : 500)};
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  flex-grow: 1;
  text-decoration: solid;
  &:active {
    background: linear-gradient(
      131.42deg,
      #e75570 32.19%,
      #ed676a 53.45%,
      #f17267 66.7%,
      #fd965b 83.78%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &:hover {
    background: linear-gradient(
      131.42deg,
      #e75570 32.19%,
      #ed676a 53.45%,
      #f17267 66.7%,
      #fd965b 83.78%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  -webkit-user-drag: none;
`;

export const AddNewScrimButton = styled.button<any>`
  border-radius: 8px;
  padding: 10px;
  background: linear-gradient(
    131.42deg,
    #e75570 32.19%,
    #ed676a 53.45%,
    #f17267 66.7%,
    #fd965b 83.78%
  );
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  margin: 10px 0 20px 18vw;
`;
