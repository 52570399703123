import React, { ReactNode } from 'react';
import { Modal as MUIModal, Grow, Backdrop, Box } from '@mui/material';

const modalWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  position: 'absolute' as 'absolute',
  inset: '0',
  margin: 'auto',
  height: 'fit-content',
  maxWidth: '468px',
  width: '91.47%',
  bgcolor: '#373942',
  borderRadius: '20px',
  outline: 0,
};

interface ModalType {
  children?: ReactNode;
  show?: boolean;
  styled?: React.CSSProperties;
}

const Modal = ({ children, show, styled }: ModalType) => {
  return (
    <MUIModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Boolean(show)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={modalWrapperStyle}
    >
      <Grow in={show}>
        <Box sx={{ ...modalContentStyle, ...styled }}>{children}</Box>
      </Grow>
    </MUIModal>
  );
};
export default Modal;
