import { Theme, Box, BoxProps } from '@mui/material';
import { styled } from '@mui/system';
import { Text } from 'design/Text';
import React, { ReactNode } from 'react';

export interface RibbonProps extends BoxProps {
  theme?: Theme;
  variant?: 'green' | 'orange' | 'red' | 'white';
  label?: string | ReactNode;
}

const handleVariantStyle = ({ variant }: RibbonProps) => {
  switch (variant) {
    case 'green':
      return { boxShadow: '0px 2px 4px rgba(38, 189, 135, 0.4), 0px 4px 32px rgba(0, 0, 0, 0.7)' };
    case 'orange':
      return { boxShadow: '0px 2px 4px rgba(243, 112, 36, 0.4), 0px 4px 32px rgba(0, 0, 0, 0.7)' };
    case 'red':
      return { boxShadow: '0px 2px 4px rgba(244, 90, 100, 0.5), 0px 4px 32px rgba(0, 0, 0, 0.7)' };
    case 'white':
      return { boxShadow: '0px 2px 4px rgba(148, 152, 158, 0.4), 0px 4px 32px rgba(0, 0, 0, 0.7)' };
    default:
      return { boxShadow: 'none' };
  }
};

const CustomRibbon: any = styled(Box, { name: 'match-status' })<RibbonProps>(({ theme }) => ({
  minHeight: '34px',
  width: '245px',
  borderRadius: '0px 0px 16px 16px',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.neutral700,
}));

export const MatchStatus = ({ label, variant, ...props }: RibbonProps) => {
  return (
    <CustomRibbon sx={{ ...(variant && handleVariantStyle({ variant })) }} {...props}>
      <Text variant="subtitle">{label}</Text>
    </CustomRibbon>
  );
};
