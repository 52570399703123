import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { failedGetTeamMemberApprovedAction, getTeamMemberApprovedAction, successGetTeamMemberApprovedAction, TeamMemberResponse } from './TeamMemberReducer';
import { getTeamMemberApprovedService } from './TeamService';

interface Res<T> {
  data: T;
  status: number;
}

function* fetchTeamMemberApprovedSaga(action: AnyAction) {
  try {
    const res: Res<TeamMemberResponse> = yield call(getTeamMemberApprovedService, action.payload);
    if (res.status !== 200) {
      throw res;
    }else{
        yield put(successGetTeamMemberApprovedAction(res.data));
    }
  } catch (e) {
    yield put(failedGetTeamMemberApprovedAction({ error: e }));
  }
}

export default function* teamMemberSaga() {
  yield takeLatest(getTeamMemberApprovedAction.type, fetchTeamMemberApprovedSaga);
}
