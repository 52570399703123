import ProfileGameDrawer from '../Drawer/ProfileGameDrawer';
import TournamentAwards from '../state/Awards';
import ScrimStatistic from '../state/ScrimStatistic';
import Tournaments from '../state/Tournaments';
import HeaderAccount from '../state/public/HeaderAccount';
import TeamId from '../state/public/TeamId';
import { Box, Stack } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { LayoutContext } from 'context/LayoutContext';
import { Ribbon } from 'design/Ribbon';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { ImageSource } from 'modules/assetpath';
import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import { AccountState } from 'redux/account/AccountReducer';
import {
  fetchTournamentParticipantAwardsPlayer,
  fetchTournamentParticipantAwardsFrame,
} from 'redux/award/AwardService';
import { useAppSelector } from 'redux/hooks';
import { fetchPublicProfile, resetPublicProfile } from 'redux/publicProfile/slice';
import { fetchScrimTeamStatistic } from 'redux/scrim/ScrimService';
import { useAppThunkDispatch } from 'redux/store';
import { fetchTournamentParticipantPlayer } from 'redux/tournament/TournamentServices';
import AuthService from 'services/AuthService';

const PublicProfile = () => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const dispatch = useAppThunkDispatch();
  const history = useHistory();

  const { playerSlug } = useParams<{ playerSlug: string }>();
  const [openToast, setOpenToast] = useState(false);
  const [openDrawerGame, setOpenDrawerGame] = useState(false);
  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [parallaxDisable, setParallaxDisable] = useState(true);

  const playerProfileContainerRef = useRef<any>();

  const {
    playerInfo,
    publicProfile,
    awardsFrame,
    awardsFrameStatus,
    scrimStatisticStatus,
    scrimStatistic,
    publicProfileStatus,
  } = useAppSelector(({ account, publicProfile, scrimList, award }) => ({
    playerInfo: account?.data,
    publicProfile: publicProfile?.publicProfile,
    scrimStatistic: scrimList?.scrimTeamStatistic,
    awardsFrame: award?.tournamentParticipantAwardsFrame?.awards ?? [],
    awardsFrameStatus: award?.status?.tournamentParticipantAwardsFrame,
    scrimStatisticStatus: scrimList?.status?.scrimTeamStatistic,
    publicProfileStatus: publicProfile?.status?.publicProfile,
  }));

  const myRole = isAuthenticated
    ? playerInfo?.games?.find(game => game?.nickName === playerSlug && game?.team)
    : undefined;
  const teamId = myRole?.team?.id;

  const handleFetchTeamScrimStatistic = (teamId: string) => {
    dispatch(fetchScrimTeamStatistic({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipant = (playerId: string) => {
    dispatch(fetchTournamentParticipantPlayer({ playerId: playerId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwards = (playerId: string) => {
    dispatch(fetchTournamentParticipantAwardsPlayer({ playerId: playerId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwardsFrame = () => {
    dispatch(fetchTournamentParticipantAwardsFrame())
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchPublicProfile = (playerSlug: string) => {
    dispatch(fetchPublicProfile({ playerSlug: playerSlug }))
      .unwrap()
      .then(res => {
        if (res) {
          Promise.all([
            handleFetchTeamScrimStatistic(res.teams[0].teamId),
            handleFetchTournamentParticipant(res.id),
            handleFetchTournamentParticipantAwards(res.id),
          ]);
        }
      })
      .catch(error => setError(error));
  };

  useEffect(() => {
    Promise.all([
      dispatch(resetPublicProfile()),
      handleFetchPublicProfile(playerSlug),
      handleFetchTournamentParticipantAwardsFrame(),
    ]);
  }, []);

  // Karena ada bug api terpanggil 2x, handle useEffect ini tidak dipakai dulu
  // useEffect(() => {
  //   Promise.all([
  //     dispatch(resetPublicProfile()),
  //     handleFetchPublicProfile(playerSlug),
  //     handleFetchTournamentParticipantAwardsFrame(),
  //   ]);
  //
  //   if (publicProfile) {
  //     handleFetchTournamentParticipant(publicProfile?.id);
  //     handleFetchTournamentParticipantAwards(publicProfile?.id);
  //     if (publicProfile?.teams) {
  //       handleFetchTeamScrimStatistic(publicProfile?.teams[0]?.teamId);
  //     }
  //   }
  // }, []);

  useLayoutEffect(() => {
    if (playerProfileContainerRef?.current?.offsetHeight > 869) {
      setParallaxDisable(false);
    } else setParallaxDisable(true);
  }, [playerProfileContainerRef?.current?.offsetHeight]);

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleBack = () => {
    if (history.action === 'POP') {
      if (teamId) {
        return 'goback';
      }
      return '/';
    }
    return 'goback';
  };

  const handleOpenDrawerGame = () => {
    setOpenDrawerGame(!openDrawerGame);
  };

  const handleLoading = (status: AccountState['status']) => {
    if (status === 'loading') return Boolean(publicProfile || scrimStatistic || awardsFrame);
    return false;
  };

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Profile Saya' }),
      background: { color: '#101216' },
      appBar: {
        logo: true,
        back: {
          to: handleBack(),
        },
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
      additional: (
        <Toast show={openToast} onClose={handleCloseToast}>
          <Ribbon
            label={
              <Text variant="body" sx={{ color: 'neutral100' }}>
                {t('player_player_profile_information_game_id')}
              </Text>
            }
            sx={{ bgcolor: 'neutral500', p: '12px 10px' }}
          />
        </Toast>
      ),
    });
  }, [playerInfo, openToast]);

  return (
    <Box ref={playerProfileContainerRef}>
      <Box
        sx={{
          position: 'relative',
          backfaceVisibility: 'hidden',
          transformStyle: 'preserve-3d',
          transform: 'translate3d(0,0,0)',
        }}
      >
        <Parallax
          disabled={parallaxDisable}
          translateY={[0, 25, 'easeInOut']}
          opacity={[1, 0]}
          shouldAlwaysCompleteAnimation={true}
        >
          <Box
            sx={{
              position: 'relative',
              '&:before': {
                content: "''",
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '50px',
                background: 'linear-gradient(180deg, transparent 0%, #101216 100%)',
                zIndex: 1,
              },
            }}
          >
            <Box
              sx={{
                background: `url(${ImageSource(
                  'bg-playerteam',
                  'png',
                )}) no-repeat top center / cover`,
                width: '100%',
                height: ['210px', '250px'],
              }}
            />
          </Box>
        </Parallax>
      </Box>

      <Stack
        direction="column"
        spacing="12px"
        sx={{
          position: 'relative',
          mt: ['-190px', '-220px'],
          px: '8px',
          pb: '12px',
        }}
      >
        <HeaderAccount handleOpenDrawer={handleOpenDrawerGame} />
        <TeamId />
        <ScrimStatistic />
        <TournamentAwards playerSlug={playerSlug} />
        <Tournaments playerSlug={playerSlug} />
      </Stack>

      <ProfileGameDrawer
        open={openDrawerGame}
        onClose={handleOpenDrawerGame}
        onOpen={handleOpenDrawerGame}
        setOpenToast={setOpenToast}
        nickname={publicProfile?.games[0].nickName ?? ''}
        gameId={publicProfile?.games[0].gameProviderId ?? ''}
      />

      <LoadingModal
        show={
          error
            ? true
            : handleLoading(publicProfileStatus || scrimStatisticStatus || awardsFrameStatus)
        }
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </Box>
  );
};

export default PublicProfile;
