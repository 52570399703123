import { Box, Stack, FormControl, FormHelperText } from '@mui/material';
import { styled } from '@mui/system';
import { LayoutContext } from 'context/LayoutContext';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { TextButton } from 'design/Buttons/TextButton';
import { TextInput } from 'design/Forms/TextInput';
import { Text } from 'design/Text';
import history from 'modules/history';
import React, { useEffect, useState, useContext } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useParams } from 'react-router-dom';
import {
  changeEmail,
  verifyEmail,
  changePhoneNumber,
  verifyPhoneNumber,
} from 'redux/account/AccountServices';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { EMAIL, PHONENUM } from 'store/constants/regex';

const OtpArea = styled(Box)(({ theme }: any) => ({
  '.otp-custom-container': {
    justifyContent: 'center',
    div: {
      marginRight: '16px',
      '&:last-child': {
        marginRight: '0',
      },
      '.otp-custom-input': {
        width: '48px!important',
        height: '48px',
        fontFamily: theme.fontFamily.inter,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '20px',
        color: theme.palette.neutral200,
        background: theme.palette.neutral600,
        borderRadius: '8px',
        border: 'none',
        boxSizing: 'border-box',
        '&::selection': {
          background: theme.palette.neutral400,
        },
        '&.otp-custom-error': {
          border: '1px solid #B31B37',
        },
      },
      '.otp-custom-focus': {
        outline: 'none',
      },
    },
  },
}));

const VerificationCode = () => {
  const { setValue } = useContext(LayoutContext);
  const { t } = useTranslation();

  //requestApi
  const dispatch = useAppThunkDispatch();

  const requestOtpAction = (successAction: () => void) => {
    const changeEmailThunk = changeEmail({
      currentEmail: profile ? profile.email : '',
      newEmail: getValidateData,
    });
    const changePhoneThunk = changePhoneNumber({
      currentPhoneNumber: profile ? profile.phoneNumber : '',
      newPhoneNumber: getValidateData,
    });

    dispatch(accountType == 'email' ? changeEmailThunk : changePhoneThunk)
      .unwrap()
      .then(res => {
        if (res && res.isAxiosError && res.data?.status >= 400) {
          // setValidationForm(res.data as AccountValidationProblemDetails)
          // if(res.data.status >= 500)
          //     setModalState({ step: 'failed' })
        } else {
          successAction();
        }
      })
      .catch(err => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      });
  };

  const clickVerifyOtp = () => {
    const verifyEmailThunk = verifyEmail({ newEmail: getValidateData, token: parseInt(otp) });
    const verifyPhoneThunk = verifyPhoneNumber({
      newPhoneNumber: getValidateData,
      token: parseInt(otp),
    });

    if (accountType == 'email') {
      dispatch(verifyEmailThunk)
        .unwrap()
        .then(res => {
          if (res && res.isAxiosError && res.data?.status >= 400) {
            setIsOtpError(true);
            if (process.env.NODE_ENV === 'development') console.log(res.data.message);
          } else {
            history.push('/account/edit#success-verify-email');
          }
        })
        .catch(err => {
          if (process.env.NODE_ENV === 'development') console.error(err);
        });
    } else {
      dispatch(verifyPhoneThunk)
        .unwrap()
        .then(res => {
          if (res && res.isAxiosError && res.data?.status >= 400) {
            setIsOtpError(true);
            if (process.env.NODE_ENV === 'development') console.log(res.data.message);
          } else {
            history.push('/account/edit#success-verify-phone');
          }
        })
        .catch(err => {
          if (process.env.NODE_ENV === 'development') console.error(err);
        });
    }
  };

  //page section
  const { accountType } = useParams<{ accountType: string }>();
  const hash = history.location.hash;
  let [showEdit, setShowEdit] = useState(false);
  const clickSubmitChangeData = () => {
    if (validate(getValidateData)) {
      requestOtpAction(() => setShowEdit(false));
    } else {
      setDataisValid(true);
    }
  };

  //data section
  const { profile } = useAppSelector(({ account }) => ({
    profile: account.data,
  }));
  const [getValidateData, setValidateData] = useState('');
  const changeValidateData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidateData(event.target.value);
    if (dataisValid) setDataisValid(false);
  };

  let [dataisValid, setDataisValid] = useState(false);

  const validate = (input: string) => {
    if (accountType == 'email') {
      const expression = EMAIL;

      return expression.test(String(input).toLowerCase());
    } else {
      const expression = PHONENUM;
      return expression.test(input);
    }
  };

  //otp section
  const [otp, setOtp] = useState<string>('');
  const handleChangeOTP = (newValue: string) => {
    setOtp(newValue);
    if (isOtpError) setIsOtpError(false);
  };
  let [isOtpError, setIsOtpError] = useState(false);

  let [verifyTime, setVerifyTime] = useState(new Date().getTime());
  const clickButtonResendOtp = () => {
    requestOtpAction(() => setVerifyTime(new Date().getTime()));
  };

  useEffect(() => {
    setValue({
      appBar: {
        separator: false,
        logo: false,
        back: {
          to: '/account/edit',
        },
        background: '#101216',
      },
      background: '#101216',
      bottomBar: {
        show: false,
      },
    });

    if (
      profile &&
      ((accountType == 'email' &&
        (profile.emailConfirmed == undefined || profile.emailConfirmed == false)) ||
        (accountType == 'phone' &&
          (profile.phoneNumberConfirmed == undefined || profile.phoneNumberConfirmed == false)))
    ) {
      if (accountType == 'email') {
        setValidateData(profile.email);
      } else {
        setValidateData(profile.phoneNumber);
      }

      if (hash == '#edit') {
        setShowEdit(true);
      }
    } else {
      history.push('/account/edit');
    }
  }, []);

  return (
    <Box p="24px 16px">
      {showEdit ? (
        <Stack>
          <Text variant="h2" mb="16px">
            {accountType == 'email' ? 'Ubah Email' : 'Ubah No. HP'}
          </Text>
          <TextInput
            label={accountType == 'email' ? 'Email Sekarang' : 'No. HP Sekarang'}
            placeholder=""
            defaultValue={accountType == 'email' ? profile?.email : profile?.phoneNumber}
            disabled
            sx={{
              mb: '16px',
              '& .Mui-disabled': {
                borderColor: '#4E5156 !important',
              },
            }}
          />
          <FormControl error={dataisValid}>
            <TextInput
              id="inputfilled4"
              label={accountType == 'email' ? 'Email Baru' : 'No. HP Baru'}
              onChange={changeValidateData}
              InputLabelProps={{ shrink: true }}
              aria-describedby="component-error-text"
              error={dataisValid}
            />
            {dataisValid && (
              <FormHelperText id="component-error-text">{`Format ${
                accountType == 'email' ? 'Email' : 'No. HP'
              } Salah`}</FormHelperText>
            )}
          </FormControl>
          <PrimaryButton wide sx={{ mt: '24px' }} onClick={clickSubmitChangeData}>
            {t('player_account_verification_flow_submit_otp')}
          </PrimaryButton>
        </Stack>
      ) : (
        <Stack alignItems="center">
          <Text variant="h2" mb="8px">
            {t('player_account_verification_flow_phone_title_otp')}
          </Text>
          <Text variant="body" textAlign="center" mb="24px" sx={{ color: 'neutral300' }}>
            {`Kode verifikasi telah dikirim melalui ${
              accountType == 'email' ? 'Email' : 'SMS'
            } ke `}
            <Text variant="body" component="span" sx={{ fontWeight: '600', color: 'neutral200' }}>
              {getValidateData}
            </Text>
          </Text>
          <OtpArea>
            <OtpInput
              value={otp}
              onChange={handleChangeOTP}
              numInputs={4}
              containerStyle="otp-custom-container"
              inputStyle="otp-custom-input"
              focusStyle="otp-custom-focus"
              isInputNum={true}
              hasErrored={isOtpError}
              errorStyle="otp-custom-error"
            />
          </OtpArea>
          {isOtpError && (
            <Text variant="body" sx={{ mt: '4px', color: 'primaryRed500' }}>
              Kode yang dimasukkan salah
            </Text>
          )}
          <PrimaryButton
            wide
            sx={{ mt: '32px' }}
            disabled={otp.length < 4}
            onClick={clickVerifyOtp}
          >
            {t('player_account_verification_flow_submit_otp')}
          </PrimaryButton>
          <Countdown
            key={verifyTime + 60000}
            date={verifyTime + 60000}
            renderer={({ completed, formatted }) => {
              return (
                <>
                  <Text
                    variant="bodySmall"
                    sx={{ lineHeight: '140%', color: 'neutral300', mt: '32px' }}
                  >
                    {completed
                      ? 'Belum menerima kode?'
                      : `Kode akan kadaluarsa dalam ${formatted.seconds} detik`}
                  </Text>
                  <TextButton
                    variant="button"
                    textsx={{
                      color: 'primaryRed400',
                      fontWeight: '700',
                      lineHeight: '140%',
                      mt: '2px',
                    }}
                    disabled={!completed}
                    onClick={clickButtonResendOtp}
                  >
                    {t('player_account_verification_flow_resend_otp')}
                  </TextButton>
                </>
              );
            }}
          />
          <Text
            variant="bodySmall"
            textAlign="center"
            sx={{
              lineHeight: '140%',
              color: 'neutral300',
              position: 'absolute',
              margin: '0 auto 56px',
              bottom: '0',
              left: '0',
              right: '0',
            }}
          >
            {`${accountType == 'email' ? 'Email' : 'No. HP'} berubah? `}
            <TextButton
              variant="button"
              textsx={{ color: 'primaryRed400', fontWeight: '700', lineHeight: '140%' }}
              onClick={() => setShowEdit(true)}
            >
              {`Ubah ${accountType == 'email' ? 'Email' : 'No. HP'}`}
            </TextButton>
          </Text>
        </Stack>
      )}
    </Box>
  );
};

export default VerificationCode;
