import { Radio, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { Text } from 'design/Text';
import React from 'react';

export const RadioRed = (props: any) => {
  return (
    <Radio
      {...props}
      sx={theme => ({
        color: 'neutral400',
        right: '-9px',
        '&:hover': { bgcolor: 'transparent' },
        '&.Mui-checked': {
          color: 'neutral200',
          'svg[data-testid=RadioButtonUncheckedIcon]': {
            fill: theme.palette.primaryRed400,
          },
        },
        'span:not(.MuiTouchRipple-root)': {
          justifyContent: 'center',
          alignItems: 'center',
          'svg[data-testid=RadioButtonUncheckedIcon]': {
            width: '24px',
            height: '24px',
          },
          'svg[data-testid=RadioButtonCheckedIcon]': {
            left: '-2.3px',
            width: '28.77px',
            height: '28.77px',
          },
        },
      })}
    />
  );
};

interface RadioListProps extends FormControlLabelProps {
  title: string;
  description: string;
}

export const RadioList: any = ({ title, description, ...props }: RadioListProps) => {
  return (
    <FormControlLabel
      value={props.value}
      label={
        <>
          <Text variant="label" component="h3" sx={{ mb: '2px' }}>
            {title}
          </Text>
          {description && (
            <Text variant="body" sx={{ color: 'neutral400' }}>
              {description}
            </Text>
          )}
        </>
      }
      control={<RadioRed />}
      labelPlacement="start"
      sx={{
        width: '100%',
        py: '16px',
        m: 0,
        justifyContent: 'space-between',
        borderBottom: '1px solid #242628',
        ...props.sx,
      }}
    />
  );
};
