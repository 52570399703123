import React, { useState } from 'react';
import { Container, Link, Box } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton/index';
import { ImageSource } from 'modules/assetpath';
import { FrameInvitation, BackImage } from '../style';
import { NormalText, GradientText } from 'components/styled/text.styled';
import { InputField } from 'components/Inputs/Input';
import Modal from 'components/layouts/Modal';
import { inviteMember } from 'redux/member/slice';
import { useAppThunkDispatch } from 'redux/store';

function ModalInvitation() {
  const thunkDispatch = useAppThunkDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<'input-mail' | 'done'>('input-mail');
  const [getMail, setMail] = useState('');
  const [buttonModal, setButtonModal] = useState(false);
  const [labelButton, setLabelButton] = useState<'Please Wait..' | 'Kirim Undangan'>(
    'Kirim Undangan',
  );

  const handleClickSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLabelButton('Please Wait..');
    setButtonModal(true);
    thunkDispatch(inviteMember({ email: getMail }))
      .unwrap()
      .then(res => {
        if (res && res.status == 204) {
          setLabelButton('Kirim Undangan');
          setModalType('done');
          setButtonModal(false);
        } else {
          setButtonModal(false);
          setLabelButton('Kirim Undangan');
        }
      });
  };

  const clickModalInvitation = () => {
    setButtonModal(false);
    setShowModal(true);
    setMail('');
    setModalType('input-mail');
    setLabelButton('Kirim Undangan');
  };

  const handleEmailChange = (event: { target: HTMLInputElement }) => {
    let checkEmail = event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ? true
      : false;

    setMail(event.target.value);
    if (checkEmail) {
      setButtonModal(true);
    } else {
      setButtonModal(false);
    }
  };

  const ModalEmail = () => {
    return modalType === 'input-mail' ? (
      <Container sx={{ padding: '24px 16px 24px 16px', width: '343px' }}>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontStyle: 'Bold',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            color: '#FFFFFF',
            fontWeight: '700',
          }}
        >
          Undang Teman Via Email
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontStyle: 'Regular',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#969393',
            padding: '20px 20px 20px 20px',
          }}
        >
          {' '}
          Jika anggota tim yang kamu cari belum terdaftar di platform ini, undang dia via email :)
        </Box>

        <form onSubmit={handleClickSubmit}>
          <Box>
            <InputField
              style={{ position: 'relative', width: '100%' }}
              label="Email"
              onChange={handleEmailChange}
              inputMode="email"
              type="email"
              value={getMail}
              placeholder="email@email.com"
            />
          </Box>
          <Box>
            <PrimaryButton variant="big" wide disabled={!buttonModal}>
              {' '}
              {labelButton}
            </PrimaryButton>
          </Box>
        </form>

        <Box
          sx={{
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'Bold',
            lineHeight: '20px',
            fontWeight: '700',
            padding: '16px 0px 0px 0px',
          }}
        >
          <Link underline="none" onClick={() => setShowModal(false)}>
            Batal
          </Link>
        </Box>
      </Container>
    ) : modalType === 'done' ? (
      <Container sx={{ padding: '24px 16px 24px 16px', width: '343px' }}>
        <Box>
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight="bold"
            margin="8px 0px"
          >
            Undangan Berhasil Dikirim
          </NormalText>
          <NormalText
            textAlign="center"
            color="#969393"
            fontSize="14px"
            fontWeight="normal"
            margin="8px 0px"
          >
            Undangan berhasil dikirim <br />
            {getMail}
          </NormalText>
        </Box>
        <Box sx={{ width: '190px', margin: 'auto', fontFamily: 'Roboto', fontSize: '14px' }}>
          <PrimaryButton variant="big" wide onClick={() => setShowModal(false)}>
            Tutup
          </PrimaryButton>
        </Box>
      </Container>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Modal show={showModal}>{ModalEmail()}</Modal>
      <FrameInvitation>
        <NormalText
          style={{ width: 'fit-content' }}
          position="absolute"
          fontStyle="normal"
          fontWeight="bold"
          fontSize="16px"
          margin="30px"
          lineHeight="140%"
          maxWidth="177px"
          color="white"
        >
          MABAR lebih seru bareng teman!
        </NormalText>
        <GradientText
          style={{ position: 'absolute', width: 'fit-content', top: '53px', zIndex: 1 }}
          fontStyle="normal"
          fontWeight="bold"
          fontSize="12px"
          margin="30px"
          lineHeight="140%"
          maxWidth="177px"
          isPressable
          color="#F3AA36"
          onClick={() => {
            clickModalInvitation();
          }}
        >
          Undang Teman MABAR!{' >'}
        </GradientText>
        <BackImage>
          <img
            style={{ position: 'relative', float: 'right', top: '7px', right: '10%' }}
            src={ImageSource('invitation-bg-2', 'png')}
            alt=""
          />
        </BackImage>
      </FrameInvitation>
    </>
  );
}

export default ModalInvitation;
