import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export interface PublicProfileResponse {
  id: string;
  firstName: string;
  lastName: string;
  picture: string;
  generation: number;
  school: Schools;
  games: Games[];
  teams: Teams[];
}

export interface Teams {
  avatarUrl: string;
  game: string;
  gameSlug: string;
  name: string;
  schoolSlug: string;
  teamId: string;
  urlSlug: string;
}

export interface Games {
  nickName: string;
  gameProviderId: string;
  game: Schools;
}

export interface Schools {
  id: string;
  name: string;
  urlSlug: string;
  iconUrl?: string;
  city?: City;
  province?: string;
}

export interface City {
  code: string;
  name: string;
  parentCode: string;
}

export interface DetailData<T> {
  pageIndex: number;
  pageSize: number;
  count: number;
  totalCount: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: T;
}

export interface PublicProfileState {
  publicProfile: PublicProfileResponse | undefined;
  profileHistory: DetailData<[]> | undefined;
  status: {
    publicProfile: 'idle' | 'loading' | 'succeeded' | 'failed';
    profileHistory: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
  error: {
    publicProfile: string | undefined;
    profileHistory: string | undefined;
  };
}

const initialState: PublicProfileState = {
  publicProfile: undefined,
  profileHistory: undefined,
  status: {
    publicProfile: 'idle',
    profileHistory: 'idle',
  },
  error: {
    publicProfile: '',
    profileHistory: '',
  },
};

export const fetchPublicProfile = createAsyncThunk(
  'public-profile',
  async ({ playerSlug, version }: { playerSlug: string; version?: string }) => {
    const url = `/${version ? `v${version}/` : ''}user/player/public-profile/${playerSlug}`;
    const res = await ApiService.get<PublicProfileResponse>(url);
    return res.data;
  },
);

export const fetchProfileHistory = createAsyncThunk(
  'public-profile/history',
  async ({
    playerSlug,
    pageIndex,
    pageSize,
    version,
  }: {
    playerSlug: string;
    pageIndex: number;
    pageSize: number;
    version: string;
  }) => {
    const url = `/match-api/${
      version ? `v${version}/` : ''
    }history/player/${playerSlug}/${pageIndex}/${pageSize}`;
    const res = await ApiService.get(url);
    return res.data;
  },
);

const PublicProfileSlice = createSlice({
  name: 'publicProfile',
  initialState,
  reducers: {
    resetPublicProfile: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPublicProfile.pending, (state, _action) => {
        state.status.publicProfile = 'loading';
      })
      .addCase(fetchPublicProfile.fulfilled, (state, action) => {
        state.status.publicProfile = 'succeeded';
        state.publicProfile = action.payload;
      })
      .addCase(fetchPublicProfile.rejected, (state, action) => {
        state.status.publicProfile = 'failed';
        state.error.publicProfile = action.error.message;
      })

      .addCase(fetchProfileHistory.pending, (state, _action) => {
        state.status.profileHistory = 'loading';
      })
      .addCase(fetchProfileHistory.fulfilled, (state, action) => {
        state.status.profileHistory = 'succeeded';
        state.profileHistory =
          action.meta.arg.pageIndex > 1
            ? {
                ...state.profileHistory,
                pageIndex: action.payload.pageIndex,
                pageSize: action.payload.pageSize,
                hasNextPage: action.payload.hasNextPage,
                items: [...state.profileHistory!.items, ...action.payload.items],
              }
            : action.payload;
      })
      .addCase(fetchProfileHistory.rejected, (state, action) => {
        state.status.profileHistory = 'failed';
        state.error.profileHistory = action.error.message;
      });
  },
});

export const { resetPublicProfile } = PublicProfileSlice.actions;
export default PublicProfileSlice.reducer;
