import { Box, Stack } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import socmedLink from 'data/socmedLink.json';
import React from 'react';

function SocialMediaRowButton() {
  return (
    <Box sx={{ mt: '20px' }}>
      <Text variant="label">Follow Us on</Text>
      <Stack direction="row" spacing="21px" justifyContent="center" sx={{ mt: '8px' }}>
        {socmedLink.map(social => (
          <>
            <Box
              sx={theme => ({
                width: '100%',
                aspectRatio: '1/1',
                padding: '24px',
                bgcolor: 'neutral600',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)',
                borderRadius: theme.borderRadius.md,
                verticalAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
                boxSizing: 'border-box',
                '& img': {
                  maxWidth: '36px',
                },
              })}
              onClick={() => {
                social.url && window.open(social.url, '_blank');
              }}
            >
              <img src={IconSource(social.icon)} alt={social.name} />
            </Box>
          </>
        ))}
      </Stack>
    </Box>
  );
}

export default SocialMediaRowButton;
