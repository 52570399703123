import RosterDrawer from '../Drawer/RosterDrawer';
import CarouselHighlighted from '../Section/CarouselHighlighted';
import FeedAward from '../Section/FeedAward';
import { Stack } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { LayoutContext } from 'context/LayoutContext';
import navigationMenu from 'data/navigationMenu.json';
import { Text } from 'design/Text';
import _ from 'lodash';
import { onClickShare } from 'modules/helpers';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { resetAward } from 'redux/award/AwardReducer';
import {
  fetchTournamentParticipantAwardsTeam,
  fetchTournamentParticipantAwardsTeamHighlighted,
} from 'redux/award/AwardService';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { resetTeamProfile } from 'redux/teamProfile/TeamProfileReducer';
import { fetchTeamDetail } from 'redux/teamProfile/TeamProfileService';
import { fetchTournamentParticipantTeam } from 'redux/tournament/TournamentServices';

const AwardList = () => {
  const { t } = useTranslation();
  const { setValue } = useContext(LayoutContext);
  const dispatch = useAppThunkDispatch();
  const history = useHistory();
  const params = useParams() as { gameSlug: string; schoolSlug: string; teamSlug: string };

  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [openDrawerRoster, setIsOpenDrawerRoster] = useState(false);
  const [dataDrawerRoster, setDataDrawerRoster] = useState<any>([]);

  const {
    playerInfo,
    teamDetail,
    awardsHighlighted,
    awardList,
    awardListStatus,
    awardsHighlightedStatus,
  } = useAppSelector(({ account, award, teamProfile }) => ({
    playerInfo: account?.data,
    teamDetail: teamProfile?.teamDetail,
    awardsHighlighted: award?.tournamentParticipantAwardsTeamHighlighted?.awards,
    awardList: award?.tournamentParticipantAwardsTeam?.awards,
    awardListStatus: award?.status?.tournamentParticipantAwardsTeam,
    awardsHighlightedStatus: award?.status?.tournamentParticipantAwardsTeamHighlighted,
  }));

  const handleFetchTournamentParticipantAwardsTeamHighlighted = (teamId: string) => {
    dispatch(fetchTournamentParticipantAwardsTeamHighlighted({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwardsTeam = (teamId: string) => {
    dispatch(fetchTournamentParticipantAwardsTeam({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantTeam = (teamId: string) => {
    dispatch(fetchTournamentParticipantTeam({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTeamDetail = (teamSlug: string) => {
    dispatch(fetchTeamDetail({ teamSlug: teamSlug, version: '' }))
      .unwrap()
      .catch(error => setError(error));
  };

  // only fetch different latest parameter from teamDetail
  const handleFetchCallback = useCallback(
    (teamId?: string) => {
      if (teamId) {
        handleFetchTournamentParticipantAwardsTeamHighlighted(teamId);
        handleFetchTournamentParticipantAwardsTeam(teamId);
        handleFetchTournamentParticipantTeam(teamId);
      }
    },
    [teamDetail?.id],
  );

  const handleOpenDrawerRoster = () => {
    setIsOpenDrawerRoster(!openDrawerRoster);
  };

  const handleResetTeamProfile = () => {
    // only for the first time load
    if (params.teamSlug !== teamDetail?.urlSlug) {
      dispatch(resetTeamProfile());
    }
  };

  const handleResetAward = () => {
    // only for the first time load
    if (params.teamSlug !== teamDetail?.urlSlug) {
      dispatch(resetAward());
    }
  };

  const handleBack = () => {
    if (history.action === 'POP') {
      return '/';
    }
    return 'goback';
  };

  const dataAwardFeed = _.chain(awardList)
    .orderBy(['split'], ['desc'])
    .groupBy('split')
    .map((value: any, key: any) => ({
      title: key,
      data: value,
      splitStart: value?.find((data: any) => data).splitStart,
      splitEnd: value?.find((data: any) => data).splitEnd,
    }))
    .value();

  const handleButtonRoster = (award: any) => {
    setDataDrawerRoster({
      ...award,
      ...{
        teamAvatarUrl: award?.teamAvatarUrl ?? teamDetail?.avatarUrl,
        rosters: award?.teamRoster,
      },
    });
    setIsOpenDrawerRoster(true);
  };

  const handleLoading = (status: boolean) => {
    if (status) return !Boolean(teamDetail && awardsHighlighted && awardList);
    return false;
  };

  useEffect(() => {
    Promise.all([
      handleResetTeamProfile(),
      handleResetAward(),
      handleFetchTeamDetail(params.teamSlug),
    ]);
  }, []);

  useEffect(() => {
    // get lastest data from teamDetail loop from handleFetchCallback()
    if (params.teamSlug === teamDetail?.urlSlug) {
      handleFetchCallback(teamDetail?.id);
    }
  }, [handleFetchCallback]);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Team Profile Awards' }),
      background: {
        color: 'neutral800',
      },
      appBar: {
        logo: false,
        back: {
          to: handleBack(),
        },
        menu: navigationMenu,
        title: (
          <Stack direction="column" sx={{ textAlign: 'center' }}>
            <Text variant="h3" sx={{ fontWeight: 700, letterSpacing: '0.12px' }}>
              Awards
            </Text>
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '2px' }}>
              {teamDetail?.name}
            </Text>
          </Stack>
        ),
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: t('mabar_page_title', { title: 'Team Profile Awards' }),
              url: `${window.location.origin}/team/${params.gameSlug}/${params.schoolSlug}/${params.teamSlug}/awards`,
              handleIconShare: () => handleShare(),
            });
          }
        },
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, [playerInfo, teamDetail]);

  return (
    <>
      {awardsHighlighted && <CarouselHighlighted dataCarousel={awardsHighlighted} />}
      {dataAwardFeed && (
        <FeedAward dataFeed={dataAwardFeed} handleButtonRoster={handleButtonRoster} />
      )}

      <RosterDrawer
        open={openDrawerRoster}
        onClose={handleOpenDrawerRoster}
        onOpen={handleOpenDrawerRoster}
        dataEvent={dataDrawerRoster}
        playerId={playerInfo?.id ?? ''}
        title="AWARD"
        eventStatus={dataDrawerRoster?.title}
      />

      <LoadingModal
        show={
          error
            ? true
            : handleLoading(awardListStatus === 'loading' || awardsHighlightedStatus === 'loading')
        }
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default AwardList;
