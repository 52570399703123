import ListOfMyTeam from './state/ListOfMyTeam';
import LoadingModal from 'components/LoadingModal';
import { LayoutContext } from 'context/LayoutContext';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle, WarningStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchPlayerProfile } from 'redux/account/AccountServices';
import { fetchGameOptions, resetGameOptions } from 'redux/game/GameReducer';
import { useAppSelector } from 'redux/hooks';
import { getCountUnreadNotification } from 'redux/notification/NotificationReducer';
import { useAppThunkDispatch } from 'redux/store';
import { resetInfoMessageAction } from 'redux/team/TeamDraftReducer';
import { fetchMyTeamList } from 'redux/team/TeamService';
import AuthService from 'services/AuthService';

const MyTeamPage = () => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const thunkDispatch = useAppThunkDispatch();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessages, setToastMessages] = useState<any>('');
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [toastErrorMessages, setToastErrorMessages] = useState<any>('');
  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [haveNotifUnread, setHaveNotifUnread] = useState(false);

  const { playerInfo, infoMessage, teamInfo, teamInfoStatus, gameListData, gameListDataStatus } =
    useAppSelector(({ account, teamDraft, myTeam, game }) => ({
      playerInfo: account.data,
      infoMessage: teamDraft?.infoMessage,
      teamInfo: myTeam?.data,
      teamInfoStatus: myTeam?.status?.myTeamList,
      gameListData: game?.gameOption,
      gameListDataStatus: game?.status?.gameOption,
    }));

  const handleFetchPlayerProfile = (username: string) => {
    thunkDispatch(fetchPlayerProfile({ username: username }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchMyTeamList = (username: string) => {
    thunkDispatch(
      fetchMyTeamList({
        playerSlug: username,
        pageIndex: 1,
        pageSize: 10,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchGameOptions = () => {
    thunkDispatch(fetchGameOptions({ pageIndex: 1, pageSize: 10 }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleCountNotification = () => {
    if (playerInfo) {
      thunkDispatch(
        getCountUnreadNotification({
          id: playerInfo.id,
        }),
      )
        .unwrap()
        .then(res => {
          if (res && res.status == 200 && res.data.count > 0) {
            setHaveNotifUnread(true);
          } else {
            setHaveNotifUnread(false);
          }
        })
        .catch(error => setError(error));
    }
  };

  const handleResetGameOptions = () => {
    // only for the first time load
    if ((gameListData?.items ?? []).length === 0) {
      thunkDispatch(resetGameOptions());
    }
  };

  const handleFetchEffect = () => {
    if (playerInfo) {
      handleFetchPlayerProfile(playerInfo?.userName);
      handleFetchMyTeamList(playerInfo?.userName);
    }
    handleFetchGameOptions();
  };

  useEffect(() => {
    if (isAuthenticated) {
      Promise.all([handleResetGameOptions(), handleCountNotification(), handleFetchEffect()]);
    }
  }, []);

  const resetTostrMessage = () => {
    thunkDispatch(resetInfoMessageAction());
  };

  const handleOpenToast = (openToast: boolean, openErrorToast: boolean) => {
    if (openToast) {
      setOpenErrorToast(false);
      return openToast;
    }
    if (openErrorToast) {
      setOpenToast(false);
      return openErrorToast;
    }
    return false;
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setOpenErrorToast(false);
    resetTostrMessage();
  };

  useEffect(() => {
    if (infoMessage && infoMessage.show) {
      setToastMessages(infoMessage.message);
      setOpenToast(infoMessage.show);
    } else {
      setToastMessages('');
      setToastErrorMessages('');
      setOpenToast(false);
      setOpenErrorToast(false);
    }
  }, [infoMessage]);

  useEffect(() => {
    if (playerInfo !== undefined && playerInfo.school === undefined) {
      history.push('/complete-data');
    }
  }, [playerInfo]);

  const handleLoading = (status: boolean) => {
    if (status) {
      return !Boolean((teamInfo?.items ?? []).length > 0 || (gameListData?.items ?? []).length > 0);
    }
    return false;
  };

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Team > My Team' }),
      background: { color: '#0a0a0b', opacity: '0.5' },
      appBar: {
        logo: true,
        notification: {
          show: isAuthenticated,
          haveNotifUnread: haveNotifUnread,
          onClick: () => history.push('/notification'),
        },
      },
      padding: '0px',
      bottomBar: {
        show: true,
      },
      additional: (
        <>
          <Toast
            show={handleOpenToast(openToast, openErrorToast)}
            onClose={handleCloseToast}
            duration={3000}
          >
            {openToast && (
              <Ribbon
                icon={IconSource('check-gameon')}
                label={
                  <Text variant="body" sx={{ color: 'success900' }}>
                    {toastMessages}
                  </Text>
                }
                sx={GameOnToastStyle}
              />
            )}
            {openErrorToast && (
              <Ribbon
                label={
                  <Text variant="body" sx={{ color: 'success900' }}>
                    {toastErrorMessages}
                  </Text>
                }
                sx={WarningStyle}
              />
            )}
          </Toast>
        </>
      ),
    });
  }, [haveNotifUnread, openToast, openErrorToast]);

  return (
    <>
      <ListOfMyTeam />
      <LoadingModal
        show={
          error
            ? true
            : handleLoading(teamInfoStatus === 'loading' || gameListDataStatus === 'loading')
        }
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default MyTeamPage;
