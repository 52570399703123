import { Stack } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import { IconSource, ImageSource } from 'modules/assetpath';
import React, { memo, useCallback, useState } from 'react';
import AuthService from 'services/AuthService';

interface LoadingModalProps {
  show: boolean;
  isError?: boolean;
  errorMessage?: string;
}

const LoadingModal = ({ show, isError, errorMessage }: LoadingModalProps) => {
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();

  const [isShow, setIsShow] = useState<boolean>();

  const handleClose = () => {
    return setIsShow(false);
  };

  const Alert = useCallback(() => {
    const regex = /\b401\b|\b500\b|\b503\b/;

    if (isError) {
      if (regex.test(errorMessage ?? '') || !isAuthenticated) {
        return (
          <Stack justifyContent="center" alignItems="center">
            <img src={IconSource('ic-loading')} style={{ position: 'relative', outline: 0 }} />
          </Stack>
        );
      }
      return (
        <InfoModal
          icon={ImageSource('Warning', 'png')}
          cta={
            <PrimaryButton onClick={handleClose} wide>
              Tutup
            </PrimaryButton>
          }
        >
          <Text variant="h2" sx={{ fontSize: '22px', mb: '12px', textAlign: 'center' }}>
            Something went wrong!
          </Text>
          <Text sx={{ textAlign: 'center' }}>Terjadi kesalahan, silahkan hubungi kami.</Text>
          <Text variant="bodySmall" sx={{ color: 'yellow01', textAlign: 'center' }}>
            {errorMessage}
          </Text>
        </InfoModal>
      );
    }
    return (
      <Stack justifyContent="center" alignItems="center">
        <img src={IconSource('ic-loading')} style={{ position: 'relative', outline: 0 }} />
      </Stack>
    );
  }, [errorMessage]);

  return (
    <Modal show={isShow ?? show} contentSx={{ background: 'transparent' }}>
      <Alert />
    </Modal>
  );
};

export default memo(LoadingModal);
