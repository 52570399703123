import GradientButton from 'components/Buttons/GradientButton';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React from 'react';
import { Container } from 'styled-minimal/lib';

type Props = {
  children?: any;
  onError?: (error: Error, componentStack: string) => void;
};

type ErrorInfo = {
  componentStack: string;
};

type State = {
  error: Error | null;
};

export default class ErrorHandler extends React.Component<Props, State> {
  public state: State = {
    error: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props;

    /* istanbul ignore else */
    if (typeof onError === 'function') {
      try {
        onError.call(this, error, info?.componentStack);
      } catch {
        // ignore
      }
    }

    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error === null) {
      return children;
    }

    const message = error.toString();
    return (
        <Container data-testid='ErrorHandler'>
        <Box width='100%' height='100%'>
          <Stack direction="column" align='center' disableSelectText disableDrag>
            <NormalText
              textAlign="center"
              color="#FFFFFF"
              fontSize="90px"
              fontWeight="500"
              lineHeight="140%"
              margin="8px 0px">
              {error.name}
            </NormalText>
            <NormalText
              textAlign="center"
              color="#FFFFFF"
              fontSize="18px"
              fontWeight="700"
              lineHeight="140%"
              margin="8px 0px">
              {message}
            </NormalText>
            <Stack direction="row" align="center" margin='24px 0px 0px'>
              <GradientButton
                backgroundSize='100%'
                variant="primary"
                onClick={() => window.location.replace('/')}>
                Kembali ke Homepage
              </GradientButton>
            </Stack>
          </Stack>
        </Box>
      </Container>
    );
  }
}
