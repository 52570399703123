import history from 'modules/history';
import React, { FC, ReactNode } from 'react';
import { HeaderLayout, HeaderNavLeft, HeaderNavRight, HeaderTitleCenter } from './styles';

export interface HeaderBackgroundProps {
  isDisable?: boolean;
  background?: string;
  pos?: 'fixed' | 'absolute' | 'sticky' | 'relative';
  height?: string;
  bRadius?: string;
  boxShadow?: string;
  children?: ReactNode | HTMLAllCollection;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface HeaderNavigationIconProps {
  idNav?: string;
  isDisable?: boolean;
  // save icons to path assets/media/icons/*
  bg?:
    | 'ic-arrow-back-dark'
    | 'ic-arrow-back-light'
    | 'ic-arrow-back-gradient'
    | 'mabar-typeface-white'
    | 'mabar-typeface-fullwhite'
    | 'mabar-typeface-black'
    | 'mabar-logo-default'
    | 'mabar-logo-white';
  width?: string;
  height?: string;
  children?: ReactNode | HTMLAllCollection;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface HeaderNavigationRightProps {
  idNav?: string;
  ref?: any;
  isDisable?: boolean;
  variant: 'notifications' | 'menu-options' | 'button' | 'share-social' | 'empty' | '';
  bg?: 'white' | 'filled' | 'gradient' | 'dark' | string | undefined;
  width?: string;
  height?: string;
  children?: ReactNode | HTMLAllCollection;
  isExpanded?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface HeaderNavProps {
  isDisable?: boolean;
  bg: HeaderBackgroundProps;
  navleft: HeaderNavigationIconProps;
  navright: HeaderNavigationRightProps;
  title?: string | ReactNode;
  onClick?: () => void;
}

export const HeaderNotificationBackground: FC<HeaderBackgroundProps> = ({
  isDisable = false,
  background = 'rgba(36, 39, 49, 0.94)',
  pos = 'fixed',
  height = '56px',
  bRadius = '0px',
  boxShadow = 'none',
  children,
  onClick,
}) => (
  <HeaderLayout
    isDisable={isDisable}
    background={background}
    pos={pos}
    height={height}
    bRadius={bRadius}
    boxShadow={boxShadow}
    onClick={onClick}
  >
    {children}
  </HeaderLayout>
);

export const HeaderNavigationIcon: FC<HeaderNavigationIconProps> = ({
  idNav,
  isDisable = false,
  bg = 'ic-arrow-back-light',
  width = '24px',
  height = '24px',
  children,
  onClick,
}) => (
  <HeaderNavLeft
    idNav={idNav}
    isDisable={isDisable}
    bg={bg}
    width={width}
    height={height}
    onClick={onClick}
  >
    {children}
  </HeaderNavLeft>
);

export const HeaderNavigationRight: FC<HeaderNavigationRightProps> = ({
  isDisable = false,
  variant = 'notifications',
  bg = 'white',
  width = '24px',
  height = '24px',
  children,
  isExpanded,
  ref,
  onClick,
}) => (
  <HeaderNavRight
    ref={ref}
    aria-haspopup={variant === 'menu-options' ? 'true' : 'false'}
    aria-controls={isExpanded ? 'composition-menu' : undefined}
    aria-expanded={isExpanded ? 'true' : undefined}
    isDisable={isDisable}
    variant={variant}
    bg={bg}
    width={width}
    height={height}
    onClick={onClick}
  >
    {children}
  </HeaderNavRight>
);

export const HeaderNotification: FC<HeaderNavProps> = ({
  isDisable = false,
  bg,
  navleft,
  title,
  navright,
  onClick,
}) => (
  <HeaderNotificationBackground
    isDisable={isDisable}
    background={bg.background}
    pos={bg.pos}
    height={bg.height}
    bRadius={bg.bRadius}
    boxShadow={bg.boxShadow}
    onClick={onClick}
  >
    <HeaderNavigationIcon
      isDisable={isDisable}
      bg={navleft.bg}
      width={navleft.width}
      height={navleft.height}
      onClick={navleft.onClick}
    />
    <HeaderTitleCenter>{title}</HeaderTitleCenter>
    <HeaderNavigationRight
      variant="notifications"
      isDisable={isDisable}
      bg={navright.bg}
      onClick={navright.onClick}
    />
    {bg.children}
  </HeaderNotificationBackground>
);

export const HeaderMenuOption: FC<HeaderNavProps> = ({
  isDisable = false,
  bg,
  navleft,
  title,
  navright,
  onClick,
}) => (
  <HeaderNotificationBackground
    isDisable={isDisable}
    background={bg.background}
    pos={bg.pos}
    height={bg.height}
    bRadius={bg.bRadius}
    boxShadow={bg.boxShadow}
    onClick={onClick}
  >
    <HeaderNavigationIcon
      isDisable={isDisable}
      bg={navleft.bg}
      width={navleft.width}
      height={navleft.height}
      onClick={navleft.onClick}
    />
    <HeaderTitleCenter>{title}</HeaderTitleCenter>
    <HeaderNavigationRight
      variant="menu-options"
      isDisable={isDisable}
      bg={navright.bg}
      onClick={navright.onClick}
    />
    {bg.children}
  </HeaderNotificationBackground>
);

export const HeaderNavButton: FC<HeaderNavProps> = ({
  isDisable = false,
  bg,
  navleft,
  title,
  navright,
  onClick,
}) => (
  <HeaderNotificationBackground
    isDisable={isDisable}
    background={bg.background}
    pos={bg.pos}
    height={bg.height}
    bRadius={bg.bRadius}
    boxShadow={bg.boxShadow}
    onClick={onClick}
  >
    <HeaderNavigationIcon
      isDisable={isDisable}
      bg={navleft.bg}
      width={navleft.width}
      height={navleft.height}
      onClick={navleft.onClick}
    />
    <HeaderTitleCenter>{title}</HeaderTitleCenter>
    <HeaderNavRight
      bg={navright.bg}
      isDisable={isDisable}
      variant="button"
      onClick={navright.onClick}
    >
      {navright.children}
    </HeaderNavRight>
    {bg.children}
  </HeaderNotificationBackground>
);

export const HeaderNavShareSocial: FC<HeaderNavProps> = ({
  isDisable = false,
  bg,
  navleft,
  title,
  navright,
  onClick,
}) => (
  <HeaderNotificationBackground
    isDisable={isDisable}
    background={bg.background}
    pos={bg.pos}
    height={bg.height}
    bRadius={bg.bRadius}
    boxShadow={bg.boxShadow}
    onClick={onClick}
  >
    <HeaderNavigationIcon
      isDisable={isDisable}
      bg={navleft.bg}
      width={navleft.width}
      height={navleft.height}
      onClick={navleft.onClick}
    />
    <HeaderTitleCenter>{title}</HeaderTitleCenter>
    <HeaderNavRight
      bg={navright.bg}
      isDisable={isDisable}
      variant="share-social"
      onClick={navright.onClick}
    >
      {navright.children}
    </HeaderNavRight>
    {bg.children}
  </HeaderNotificationBackground>
);

export const HeaderNavigation: FC<HeaderNavProps> = ({
  isDisable = false,
  bg,
  navleft,
  title,
  navright,
  onClick,
}) => (
  <HeaderNotificationBackground
    isDisable={isDisable}
    background={bg.background}
    pos={bg.pos}
    height={bg.height}
    bRadius={bg.bRadius}
    boxShadow={bg.boxShadow}
    onClick={onClick}
  >
    <HeaderNavigationIcon
      idNav={navleft.idNav}
      isDisable={isDisable}
      bg={navleft.bg}
      width={navleft.width}
      height={navleft.height}
      onClick={e => {
        if (
          navleft.bg === 'mabar-typeface-fullwhite' ||
          navleft.bg === 'mabar-logo-default' ||
          navleft.bg === 'mabar-typeface-white'
        ) {
          history.replace('/');
        }
        if (navleft.onClick) navleft.onClick(e);
      }}
    />
    <HeaderTitleCenter>{title}</HeaderTitleCenter>
    <HeaderNavRight
      id={navright.idNav}
      bg={navright.bg}
      isDisable={isDisable}
      variant={navright.variant}
      onClick={navright.onClick}
      width={navright.width}
      height={navright.height}
    >
      {navright.children}
    </HeaderNavRight>
    {bg.children}
  </HeaderNotificationBackground>
);

export const HeaderOnlyLogoNavigation: FC<HeaderNavProps> = ({
  isDisable = false,
  bg,
  navleft,
  title,
  onClick,
}) => (
  <HeaderNotificationBackground
    isDisable={isDisable}
    background={bg.background}
    pos={bg.pos}
    height={bg.height}
    bRadius={bg.bRadius}
    boxShadow={bg.boxShadow}
    onClick={onClick}
  >
    <HeaderNavigationIcon
      idNav={navleft.idNav}
      isDisable={isDisable}
      bg={navleft.bg}
      width={navleft.width}
      height={navleft.height}
      onClick={e => {
        if (
          navleft.bg === 'mabar-typeface-fullwhite' ||
          navleft.bg === 'mabar-logo-default' ||
          navleft.bg === 'mabar-typeface-white'
        ) {
          history.replace('/');
        }
        if (navleft.onClick) navleft.onClick(e);
      }}
    />
    <HeaderTitleCenter>{title}</HeaderTitleCenter>
    {bg.children}
  </HeaderNotificationBackground>
);
