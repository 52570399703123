import { styled } from '@mui/system';
import { IconButtonProps } from './index';

export const CustomIconButton: any = styled('button')<IconButtonProps>`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  outline: 0;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.palette.neutral700};
  border: 1px solid ${({ theme }) => theme.palette.neutral800};
  ${({ theme, disabled }) =>
    disabled && `pointer-events: none; border: 1px solid ${theme.palette.neutral600}`};
  &:active {
    opacity: 0.8;
  }
`;
