import { Box, Avatar } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { CardAward } from 'design/Cards/CardAward';
import { CompetitionStatus } from 'design/Tag/CompetitionStatus';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import React, { useState, memo } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useAppSelector } from 'redux/hooks';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CarouselHighlighted = () => {
  const { awardsTeamHighlighted } = useAppSelector(({ award }) => ({
    awardsTeamHighlighted: award?.tournamentParticipantAwardsTeamHighlighted?.awards,
  }));

  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (index: number) => {
    setActiveStep(index);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        p: '0',
      }}
    >
      <Avatar
        variant="square"
        src={ImageSource('bg_awards_detail', 'png')}
        sx={{
          height: '283px',
          width: '100%',
          zIndex: '-1',
          position: 'absolute',
          top: '0',
          left: '0',
          '.MuiAvatar-img': {
            objectFit: 'fill',
          },
        }}
      />

      {(awardsTeamHighlighted ?? []).length > 0 ? (
        <>
          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {awardsTeamHighlighted?.map((carousel: any, idx: number) => (
              <CardAward
                key={idx}
                image={carousel?.logoUrl}
                label={<CompetitionStatus variant="pink" label={carousel?.title} />}
                title={
                  <>
                    <Text variant="label" component="h3">
                      {carousel?.eventName}
                    </Text>
                    <Text variant="label" component="h5">
                      {carousel?.season} • {carousel?.split}
                    </Text>
                  </>
                }
                isChampion={true}
                imageSx={{
                  width: '140px',
                  height: '140px',
                  top: '10px',
                }}
                sx={{ height: 'auto', m: '0 16px' }}
              />
            ))}
          </AutoPlaySwipeableViews>

          <MobileStepper
            steps={(awardsTeamHighlighted ?? []).length}
            position="static"
            activeStep={activeStep}
            nextButton={''}
            backButton={''}
            sx={{
              backgroundColor: 'transparent',
              justifyContent: 'center',
              paddingTop: '12px',
              ' .MuiMobileStepper-dots': {
                alignItems: 'center',
                ' .MuiMobileStepper-dot': {
                  bgcolor: 'rgba(212, 214, 216, 0.5)',
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  mx: '3px',
                },
                ' .MuiMobileStepper-dotActive': {
                  bgcolor: 'white',
                },
              },
            }}
          />
        </>
      ) : null}
    </Box>
  );
};

export default memo(CarouselHighlighted);
