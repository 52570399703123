import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  failedRemovePlayerAction,
  failedSetGameSelectedAction,
  failedSetMyCurrentTeamAction,
  failedSetPlayerListAction,
  failedSetTeamDiscordUrlAction,
  failedSetTeamLogoAction,
  failedSetTeamNameAction,
  failedSetTeamSelectedAction,
  failedSetTeamWhatsAppGroupUrlAction,
  failedSetTeamWhatsAppPhoneNumberAction,
  removePlayerAction,
  setGameSelectedAction,
  setMyCurrentTeamAction,
  setPlayerListAction,
  setTeamDiscordUrlAction,
  setTeamLogoAction,
  setTeamNameAction,
  setTeamSelectedAction,
  setTeamWhatsAppGroupUrlAction,
  setTeamWhatsAppPhoneNumberAction,
  successRemovePlayerAction,
  successSetGameSelectedAction,
  successSetMyCurrentTeamAction,
  successSetPlayerListAction,
  successSetTeamDiscordUrlAction,
  successSetTeamLogoAction,
  successSetTeamNameAction,
  successSetTeamSelectedAction,
  successSetTeamWhatsAppGroupUrlAction,
  successSetTeamWhatsAppPhoneNumberAction,
} from './TeamDraftReducer';

function* setGameSelectedSaga(action: AnyAction) {
  try {
    yield call(setGameSelectedAction, action.payload);
    yield put(successSetGameSelectedAction());
  } catch (e) {
    yield put(failedSetGameSelectedAction());
  }
}

function* setPlayerListSaga(action: AnyAction) {
  try {
    yield call(setPlayerListAction, action.payload);
    yield put(successSetPlayerListAction());
  } catch (e) {
    yield put(failedSetPlayerListAction());
  }
}

function* removePlayerSaga(action: AnyAction) {
  try {
    yield call(removePlayerAction, action.payload);
    yield put(successRemovePlayerAction());
  } catch (e) {
    yield put(failedRemovePlayerAction());
  }
}

function* setTeamSelectedSaga(action: AnyAction) {
  try {
    yield call(setTeamSelectedAction, action.payload);
    yield put(successSetTeamSelectedAction());
  } catch (e) {
    yield put(failedSetTeamSelectedAction());
  }
}

function* setTeamLogoSaga(action: AnyAction) {
  try {
    yield call(setTeamLogoAction, action.payload);
    yield put(successSetTeamLogoAction());
  } catch (e) {
    yield put(failedSetTeamLogoAction());
  }
}

function* setTeamNameSaga(action: AnyAction) {
  try {
    yield call(setTeamNameAction, action.payload);
    yield put(successSetTeamNameAction());
  } catch (e) {
    yield put(failedSetTeamNameAction());
  }
}

function* setTeamWhatsAppGroupUrlSaga(action: AnyAction) {
  try {
    yield call(setTeamWhatsAppGroupUrlAction, action.payload);
    yield put(successSetTeamWhatsAppGroupUrlAction());
  } catch (e) {
    yield put(failedSetTeamWhatsAppGroupUrlAction());
  }
}

function* setTeamDiscordUrlSaga(action: AnyAction) {
  try {
    yield call(setTeamDiscordUrlAction, action.payload);
    yield put(successSetTeamDiscordUrlAction());
  } catch (e: any) {
    yield put(failedSetTeamDiscordUrlAction());
  }
}

function* setTeamWhatsAppPhoneNumberSaga(action: AnyAction) {
  try {
    yield call(setTeamWhatsAppPhoneNumberAction, action.payload);
    yield put(successSetTeamWhatsAppPhoneNumberAction());
  } catch (e: any) {
    yield put(failedSetTeamWhatsAppPhoneNumberAction());
  }
}

function* setMyCurrentTeamSaga(action: AnyAction) {
  try {
    yield call(setMyCurrentTeamAction, action.payload);
    yield put(successSetMyCurrentTeamAction());
  } catch (e) {
    yield put(failedSetMyCurrentTeamAction());
  }
}

function* teamDraftSaga() {
    yield takeLatest(setGameSelectedAction.type, setGameSelectedSaga);
    yield takeLatest(setTeamSelectedAction.type, setTeamSelectedSaga);
    yield takeLatest(setPlayerListAction.type, setPlayerListSaga);
    yield takeLatest(removePlayerAction.type, removePlayerSaga);
    yield takeLatest(setTeamLogoAction.type, setTeamLogoSaga);
    yield takeLatest(setTeamNameAction.type, setTeamNameSaga);
    yield takeLatest(setTeamWhatsAppGroupUrlAction.type, setTeamWhatsAppGroupUrlSaga);
    yield takeLatest(setTeamDiscordUrlAction.type, setTeamDiscordUrlSaga);
    yield takeLatest(setTeamWhatsAppPhoneNumberAction.type, setTeamWhatsAppPhoneNumberSaga);
    yield takeLatest(setMyCurrentTeamAction.type, setMyCurrentTeamSaga);
}

export default teamDraftSaga;
