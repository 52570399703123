import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC } from 'react';

export interface InfoAccountItemCardType {
  keyName?: string;
  value?: string;
  iconRight?: string;
  imageContent?: string;
  backgroundColor?: string;
  margin?: string;
  height?: string;
  padding?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const InfoAccountItemCard: FC<InfoAccountItemCardType> = ({
  keyName,
  value,
  iconRight,
  onClick,
  imageContent,
  backgroundColor, margin, height, padding
}) => {
  return (
    <Box width="100%">
      <Box
        onClick={onClick}
        width="100%"
        background={backgroundColor?? "rgba(55, 57, 66, 0.6)"}
        margin={margin ?? "5px 16px"}
        height={height ?? 'auto'}
        padding={padding ?? "16px"}
        borderRadius="16px"
        isPressble={onClick ? true : false}
        disableDrag
      >
        <Stack direction="row" align="center">
          <Box flexGrow={1}>
            <Stack direction="column" align="start">
              <NormalText
                color="rgba(150, 147, 147, 1)"
                fontWeight="500"
                fontSize="12px"
                margin="0px 0px 3px 0px"
              >
                {keyName}
              </NormalText>
              {value && (
                <NormalText
                  color="#FFFFFF"
                  fontWeight="700"
                  fontSize="14px"
                  margin={`${margin? 0:3}px 0px 0px 0px`}
                >
                  {value}
                </NormalText>
              )}
              {imageContent && (
                <Avatar
                  src={imageContent}
                  width="100%"
                  borderRadius="10px"
                  fit="fill"
                  margin="13px 0px 0px 0px"
                  height='194px'
                />
              )}
            </Stack>
          </Box>
          {iconRight && (
            <Box>
              <Avatar src={iconRight} width="24px" height="24px" margin="0px"/>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default InfoAccountItemCard;
