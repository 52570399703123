import { call, put, takeLatest } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import {
  failedGetGameOptions,
  GameOptions,
  GamePagedResponse,
  getGameOptions,
  successGetGameOptions,
} from './GameReducer';
import { getGameOptionsService } from './GameService';

interface Res<T> {
  data: T;
  status: number;
}

function* fetchGameOptionsSaga(action: AnyAction) {
  let res: Res<GamePagedResponse<Array<GameOptions>>>;
  try {
    res = yield call(getGameOptionsService, action.payload);
    if (res.status !== 200) {
      yield put(failedGetGameOptions({ error: res }));
      throw res;
    } else {
      yield put(successGetGameOptions(res.data));
    }
  } catch (e) {
    yield put(failedGetGameOptions({ error: e }));
  }
}

function* gameListSaga() {
  yield takeLatest(getGameOptions.type, fetchGameOptionsSaga);
}

export default gameListSaga;
