import { call, put, takeLatest } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import { successGetScrimDetailGame,getScrimDetailGame, 
        ScrimDetailGameResponse,
        failedGetScrimDetailGame 
} from './ScrimDetailGameReducer';
import { getScrimDetailGameService } from './ScrimService';

interface Res<T> {
    data: T;
    status: number;
  }

  function* fetchScrimDetailGameSaga(action: AnyAction) {
    try {
      const res: Res<ScrimDetailGameResponse> = yield call(getScrimDetailGameService, action.payload);
      if (res.status !== 200) {
        throw res;
      }
      yield put(successGetScrimDetailGame(res.data));
    } catch (e) {
      yield put(failedGetScrimDetailGame({ error: 'Error' }));
    }
  }
  
  function* scrimDetailGameSaga() {
    yield takeLatest(getScrimDetailGame.type, fetchScrimDetailGameSaga);
  }
  
  export default scrimDetailGameSaga;