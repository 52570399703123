import AboutPage from '../state/About';
import { AboutProps } from '../state/About/index';
import BracketPage from '../state/Bracket';
import MatchPage from '../state/Match';
import RulePage from '../state/Rule';
import { CustomTabs, TabItem } from 'design/Tabs';
import React from 'react';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';

interface TournamentNavProps extends AboutProps {
  hash: string;
}
const TournamentNav = ({
  hash,
  isAuthenticated,
  tournamentDetail,
  myTeamFromTournament,
  playerInfo,
  myRole,
  myTeam,
  gameSlug,
  tournamentSlug,
}: TournamentNavProps) => {
  const { t } = useTranslation();
  const height = use100vh();
  return (
    <>
      <CustomTabs
        activeTab={hash}
        sx={{
          borderBottom: '1px solid #323437',
          bgcolor: 'neutral700',
          padding: '0px 0px 0px 15px',
        }}
      >
        <TabItem
          hash="#tentang"
          title={t('tournament_tab_1')}
          sx={{
            minHeight: height! - (56 + 61 + 44),
            height: !height ? 'calc(100vh - (56px + 61px + 44px))' : 'auto',
            bgcolor: 'neutral900',
          }}
        >
          <AboutPage
            isAuthenticated={isAuthenticated}
            tournamentDetail={tournamentDetail}
            myTeamFromTournament={myTeamFromTournament}
            playerInfo={playerInfo}
            myRole={myRole}
            myTeam={myTeam}
            gameSlug={gameSlug}
            tournamentSlug={tournamentSlug}
          />
        </TabItem>
        <TabItem
          hash="#match"
          title={t('tournament_tab_2')}
          sx={{
            minHeight: height! - (56 + 61 + 44),
            height: !height ? 'calc(100vh - (56px + 61px + 44px))' : 'auto',
            bgcolor: 'neutral900',
          }}
        >
          <MatchPage
            isAuthenticated={isAuthenticated}
            gameSlug={gameSlug}
            tournamentSlug={tournamentSlug}
            tournamentDetail={tournamentDetail}
          />
        </TabItem>
        <TabItem
          hash="#bracket"
          title={t('tournament_tab_3')}
          sx={{
            minHeight: height! - (56 + 61 + 44),
            height: !height ? 'calc(100vh - (56px + 61px + 44px))' : 'auto',
            bgcolor: 'neutral900',
          }}
        >
          <BracketPage tournamentDetail={tournamentDetail} />
        </TabItem>
        <TabItem
          hash="#peraturan"
          title={t('tournament_tab_4')}
          sx={{
            minHeight: height! - (56 + 61 + 44),
            height: !height ? 'calc(100vh - (56px + 61px + 44px))' : 'auto',
            bgcolor: 'neutral900',
          }}
        >
          <RulePage tournamentDetail={tournamentDetail} />
        </TabItem>
      </CustomTabs>
    </>
  );
};

export default TournamentNav;
