import { Box, Stack } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import { IconButton } from 'design/Buttons/IconButton';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { TextButton } from 'design/Buttons/TextButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { OptionType as IOptions } from 'design/Forms/Select';
import { SelectInput as SelectOption } from 'design/Forms/Select';
import { TextInput as Input } from 'design/Forms/TextInput';
import { UploadImage } from 'design/Forms/UploadImage';
import { Modal } from 'design/Modal';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource, ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useEffect, useState, useContext } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditAccountProfileProps } from 'redux/account/AccountProps';
import { AccountValidationProblemDetails } from 'redux/account/AccountResponse';
import {
  editAccountProfile,
  fetchPlayerProfile,
  getPlayerProfileFromSession,
} from 'redux/account/AccountServices';
import { changeEmail, changePhoneNumber } from 'redux/account/AccountServices';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { EditPlayerFormOptions } from 'validation/account/EditProfileValidation';

const EditProfile = () => {
  const { handleSubmit, control } = useForm(EditPlayerFormOptions);
  const { t } = useTranslation();
  const watchFields = useWatch({ control: control });
  let [showModal, setShowModal] = useState(false);
  let [modalType, setModalType] = useState<'email' | 'phone' | 'error' | 'loading'>('loading');
  const [disableButton, setDisableButton] = useState(true);
  const [imageEdited, setImageEdited] = useState(false);
  const [pictureEdited, setPictureEdited] = useState(false);

  const dispatch = useAppThunkDispatch();
  const { profile, errors } = useAppSelector(({ account }) => ({
    profile: account.data,
    errors: account.errors,
  }));
  const [getPlayerProfile, setPlayerProfile] = useState(profile);
  const [getValidationErrors, setValidationErrors] = useState<AccountValidationProblemDetails>(
    errors as AccountValidationProblemDetails,
  );
  let isError = getValidationErrors && getValidationErrors?.errors;
  const playerProfileThunk = fetchPlayerProfile({
    username: getPlayerProfileFromSession().userName,
  });
  const fetchAccountProfile = () => {
    dispatch(playerProfileThunk)
      .unwrap()
      .then(res => {
        res && res.id && setPlayerProfile(res);
      });
  };

  useEffect(() => {
    fetchAccountProfile();
  }, [getPlayerProfile?.userName]);

  const saveAccountProfile = (data: any) => {
    let formData = data as EditAccountProfileProps;
    if (getPlayerProfile) {
      formData.Id = getPlayerProfile.id;
      formData.Email = data.Email ?? getPlayerProfile.email;
      formData.PhoneNumber = data.PhoneNumber ?? getPlayerProfile.phoneNumber;
      if (formData.Picture?.size == 0) {
        formData.Picture = undefined;
      }
      if (formData.StudentCardFile?.size == 0) {
        formData.StudentCardFile = undefined;
      }
      setModalType('loading');
      setShowModal(true);
      const editAccountProfileThunk = editAccountProfile(formData);
      dispatch(editAccountProfileThunk)
        .unwrap()
        .then(res => {
          if (res && res.isAxiosError && res.data?.status >= 400) {
            if (modalType === 'loading') {
              setShowModal(false);
            }
            setValidationErrors(res.data);
            setModalType('error');
            setShowModal(true);
          } else {
            setTimeout(() => {
              setShowModal(false);
              history.push({
                pathname: '/account',
                state: { updateInfo: t('player_team_profile_member_update_account') },
              });
            }, 3000);
          }
        })
        .catch(() => {
          setModalType('error');
          setShowModal(true);
        });
    }
  };

  const { setValue } = useContext(LayoutContext);

  const [getGenerationYearList, setGenerationYearList] = useState<IOptions[]>([]);
  const generateGenerationYearList = (): IOptions[] => {
    const minYear = 2018;
    const maxYear = new Date();
    const temp: IOptions[] = [];
    for (let year = minYear; year <= maxYear.getFullYear(); year++) {
      temp.push({ name: year.toString(), value: year.toString() });
    }
    return temp;
  };

  const [openToastSuccess, setOpenToastSuccess] = useState(false);
  const hash = history.location.hash;

  useEffect(() => {
    setValue({
      pageTitle: t('player_account_edit_profile_header_title'),
      appBar: {
        separator: true,
        logo: false,
        back: {
          to: '/account',
        },
        title: t('player_account_edit_profile_header_title'),
      },
      background: 'linear-gradient(180deg, #131318 19.34%, rgba(19, 19, 24, 0.94) 94.01%)',
      padding: '24px 16px',
      bottomBar: {
        show: false,
      },
    });
    setGenerationYearList(generateGenerationYearList());
    if (hash == '#success-verify-phone' || hash == '#success-verify-email') {
      setOpenToastSuccess(true);
    }
  }, []);

  const clickEditDataForOtp = (type: 'email' | 'phone') => {
    history.push(`/account/verify-data/${type}#edit`);
  };

  const clickVerifyButton = (e: any) => {
    e.preventDefault();
    setModalType(
      getPlayerProfile?.emailConfirmed == undefined || getPlayerProfile?.emailConfirmed == false
        ? 'email'
        : 'phone',
    );
    setShowModal(true);
  };

  const clickOpenOtpPage = () => {
    if ((modalType == 'email' || modalType == 'phone') && getPlayerProfile) {
      const changeEmailThunk = changeEmail({
        currentEmail: getPlayerProfile.email,
        newEmail: getPlayerProfile.email,
      });
      const changePhoneThunk = changePhoneNumber({
        currentPhoneNumber: getPlayerProfile.phoneNumber,
        newPhoneNumber: getPlayerProfile.phoneNumber,
      });

      dispatch(modalType == 'email' ? changeEmailThunk : changePhoneThunk)
        .unwrap()
        .then(res => {
          if (res && res.isAxiosError && res.data?.status >= 400) {
            setModalType('error');
            setShowModal(true);
          } else {
            history.push(`/account/verify-data/${modalType}`);
          }
        })
        .catch(err => {
          if (process.env.NODE_ENV === 'development') console.error(err);
        });
    }
  };

  const valueGeneration = watchFields['Generation'];
  const valueStudentIdNumber = watchFields['StudentIdNumber'];

  useEffect(() => {
    if (
      valueGeneration !== String(getPlayerProfile?.generation) ||
      valueStudentIdNumber !== String(getPlayerProfile?.studentIdNumber) ||
      imageEdited ||
      pictureEdited
    ) {
      return setDisableButton(false);
    } else {
      return setDisableButton(true);
    }
  }, [valueGeneration, valueStudentIdNumber, imageEdited, pictureEdited]);

  return (
    <>
      <Toast show={openToastSuccess} onClose={() => setOpenToastSuccess(false)}>
        <Ribbon
          icon={IconSource('check-gameon')}
          label={
            <Text variant="body" sx={{ color: 'success900' }}>
              {`Verifikasi ${hash == '#success-verify-email' ? 'Email' : 'No. HP'} Berhasil`}
            </Text>
          }
          sx={GameOnToastStyle}
        />
      </Toast>
      <Modal
        show={showModal}
        contentSx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'transparent',
        }}
      >
        {modalType == 'error' ? (
          <InfoModal
            icon={ImageSource('Warning', 'png')}
            cta={
              <PrimaryButton onClick={() => setShowModal(false)} wide>
                OK
              </PrimaryButton>
            }
          >
            <Text variant="h2" textAlign="center" mb="8px">
              Gagal Merubah Profile
            </Text>
            <Text textAlign="center">
              {getValidationErrors?.detail?.replace(
                `'Content' must not be empty`,
                'Data tidak boleh sama',
              )}
            </Text>
          </InfoModal>
        ) : modalType == 'email' || modalType == 'phone' ? (
          <InfoModal
            sx={{ width: '100%' }}
            cta={
              <>
                <SecondaryButton onClick={() => setShowModal(false)} sx={{ mr: '16px' }} wide>
                  Batal
                </SecondaryButton>
                <PrimaryButton onClick={() => clickOpenOtpPage()} wide>
                  Lanjut
                </PrimaryButton>
              </>
            }
          >
            <Text variant="h3" textAlign="center" mb="4px">
              {`Verifikasi ${modalType == 'email' ? 'Email' : 'No. HP'}`}
            </Text>
            <Text variant="body" textAlign="center" sx={{ color: 'neutral300', mb: '12px' }}>
              {`Pastikan ${modalType == 'email' ? 'Email' : 'No. HP'} kamu sudah benar`}
            </Text>
            <Box
              sx={{
                height: '28px',
                width: '100%',
                bgcolor: 'neutral500',
                borderRadius: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: '12px',
              }}
            >
              <Text variant="body" textAlign="center" sx={{ fontWeight: '600' }}>
                {modalType == 'email' ? getPlayerProfile?.email : getPlayerProfile?.phoneNumber}
              </Text>
            </Box>
            <Text
              variant="bodySmall"
              textAlign="center"
              sx={{ lineHeight: '140%', color: 'neutral300', mb: '16px' }}
            >
              {`${modalType == 'email' ? 'Email' : 'No. HP'} salah atau berubah? `}
              <TextButton
                variant="button"
                textsx={{ color: 'primaryRed400', fontWeight: '700', lineHeight: '140%' }}
                onClick={() => clickEditDataForOtp(modalType == 'email' ? 'email' : 'phone')}
              >
                {`Ubah ${modalType == 'email' ? 'Email' : 'No. HP'}`}
              </TextButton>
            </Text>
          </InfoModal>
        ) : (
          <img src={IconSource('ic-loading')} style={{ position: 'relative', outline: 0 }} />
        )}
      </Modal>

      <form
        onSubmit={handleSubmit(saveAccountProfile)}
        noValidate
        autoComplete="off"
        autoCapitalize="off"
      >
        <Stack spacing={'16px'}>
          <Stack
            sx={{
              bgcolor: 'neutral700',
              border: '1px solid #2E3033',
              borderRadius: '8px',
              padding: '12px 12px 24px 12px',
            }}
          >
            <Text variant="label" mb="16px">
              {t('player_account_edit_profile_content_header')}
            </Text>
            {(getPlayerProfile?.emailConfirmed == undefined ||
              getPlayerProfile?.emailConfirmed == false) && (
              <Box
                sx={{
                  height: '36px',
                  background: '#2B2400',
                  borderRadius: '8px',
                  padding: '10px 12px',
                  boxSizing: 'border-box',
                  mb: '16px',
                }}
              >
                <Text variant="subtitle" sx={{ color: '#D4A600' }}>
                  {t('player_account_edit_profile_verification_notice_green', { content: 'email' })}
                </Text>
              </Box>
            )}
            {(getPlayerProfile?.phoneNumberConfirmed == undefined ||
              getPlayerProfile?.phoneNumberConfirmed == false) && (
              <Box
                sx={{
                  height: '36px',
                  background: '#2B2400',
                  borderRadius: '8px',
                  padding: '10px 12px',
                  boxSizing: 'border-box',
                  mb: '16px',
                }}
              >
                <Text variant="subtitle" sx={{ color: '#D4A600' }}>
                  {t('player_account_edit_profile_verification_notice_green', {
                    content: 'no. HP',
                  })}
                </Text>
              </Box>
            )}
            <Box sx={{ mb: '18px' }}>
              <Controller
                control={control}
                name="Picture"
                defaultValue={getPlayerProfile?.picture ?? undefined}
                render={({ field: { onChange, ref } }) => (
                  <UploadImage
                    defaultImage={getPlayerProfile?.picture}
                    title={t('player_account_edit_profile_upload_image_no_limit')}
                    getImageBlob={val => onChange(val)}
                    inputRef={ref}
                    onImageLoad={function noRefCheck() {}}
                    variant="iconCircle"
                    getImageSource={_v => {
                      setPictureEdited(true);
                    }}
                  />
                )}
              />
            </Box>

            <Stack direction="row" sx={{ mb: '14px' }}>
              <Text variant="label" sx={{ color: 'neutral400', mr: '24px' }}>
                {t('player_account_edit_profile_name')}
              </Text>
              <Text variant="label" sx={{ width: '100%', textAlign: 'right', fontWeight: '400' }}>
                {getPlayerProfile?.firstName + ' ' + getPlayerProfile?.lastName}
              </Text>
            </Stack>
            <Stack direction="row" sx={{ mb: '12px' }}>
              <Text variant="label" sx={{ whiteSpace: 'nowrap', color: 'neutral400', mr: '24px' }}>
                {t('player_account_edit_profile_phone_number')}
              </Text>
              <Stack sx={{ width: '100%', textAlign: 'right' }}>
                <Text variant="label" sx={{ fontWeight: '400' }}>
                  {getPlayerProfile?.phoneNumber}
                </Text>
                {(getPlayerProfile?.phoneNumberConfirmed == undefined ||
                  getPlayerProfile?.phoneNumberConfirmed == false) && (
                  <Stack direction="row" spacing="8px" justifyContent="flex-end" sx={{ mt: '4px' }}>
                    <SecondaryButton
                      variant="small"
                      sx={{
                        height: '24px',
                        fontSize: '10px',
                        lineHeight: '16px',
                        padding: '4px 8px',
                        borderColor: 'neutral400',
                      }}
                      onClick={(e: any) => clickVerifyButton(e)}
                    >
                      Verifikasi
                    </SecondaryButton>
                    <IconButton
                      icon={IconSource('ic-Edit-White')}
                      sx={{
                        width: '24px',
                        height: '24px',
                        padding: '4px',
                        bgcolor: 'neutral600',
                        border: '1px solid #6B6E72',
                        borderRadius: '4px',
                      }}
                      onClick={() => clickEditDataForOtp('phone')}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack direction="row">
              <Text variant="label" sx={{ color: 'neutral400', mr: '24px' }}>
                {t('player_account_edit_profile_email')}
              </Text>
              <Stack sx={{ width: '100%', textAlign: 'right' }}>
                <Text variant="label" sx={{ width: '100%', textAlign: 'right', fontWeight: '400' }}>
                  {getPlayerProfile?.email}
                </Text>
                {(getPlayerProfile?.emailConfirmed == undefined ||
                  getPlayerProfile?.emailConfirmed == false) && (
                  <Stack direction="row" spacing="8px" justifyContent="flex-end" sx={{ mt: '4px' }}>
                    <SecondaryButton
                      variant="small"
                      sx={{
                        height: '24px',
                        fontSize: '10px',
                        lineHeight: '16px',
                        padding: '4px 8px',
                        borderColor: 'neutral400',
                      }}
                      onClick={(e: any) => clickVerifyButton(e)}
                    >
                      Verifikasi
                    </SecondaryButton>
                    <IconButton
                      icon={IconSource('ic-Edit-White')}
                      sx={{
                        width: '24px',
                        height: '24px',
                        padding: '4px',
                        bgcolor: 'neutral600',
                        border: '1px solid #6B6E72',
                        borderRadius: '4px',
                      }}
                      onClick={() => clickEditDataForOtp('email')}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              bgcolor: 'neutral700',
              border: '1px solid #2E3033',
              borderRadius: '8px',
              padding: '12px 12px 28px 12px',
            }}
          >
            <Text variant="label" mb="16px">
              {t('player_account_edit_profile_school_information')}
            </Text>
            <Stack direction="row" sx={{ mb: '21px' }}>
              <Text variant="label" sx={{ whiteSpace: 'nowrap', color: 'neutral400', mr: '24px' }}>
                {t('player_account_edit_profile_school_name')}
              </Text>
              <Text variant="label" sx={{ width: '100%', textAlign: 'right', fontWeight: '400' }}>
                {getPlayerProfile?.school?.name}
              </Text>
            </Stack>
            <Stack direction="row" sx={{ mb: '16px' }}>
              <Text variant="label" sx={{ color: 'neutral400', mr: '24px' }}>
                {t('player_account_edit_profile_province')}
              </Text>
              <Text variant="label" sx={{ width: '100%', textAlign: 'right', fontWeight: '400' }}>
                {getPlayerProfile?.province?.name}
              </Text>
            </Stack>
            <Stack direction="row" sx={{ mb: '18px' }}>
              <Text variant="label" sx={{ color: 'neutral400', mr: '24px' }}>
                {t('player_account_edit_profile_city')}
              </Text>
              <Text variant="label" sx={{ width: '100%', textAlign: 'right', fontWeight: '400' }}>
                {getPlayerProfile?.city?.name}
              </Text>
            </Stack>
            <Box sx={{ mb: '24px' }}>
              <Controller
                control={control}
                name="Generation"
                defaultValue={getPlayerProfile?.generation ?? undefined}
                render={({ field: { onChange, ref } }) => (
                  <SelectOption
                    label={t('player_account_edit_profile_select_join_year')}
                    placeholder={t('player_account_edit_profile_join_year')}
                    options={getGenerationYearList}
                    defaultValue={getPlayerProfile ? getPlayerProfile?.generation.toString() : ''}
                    validationText={
                      isError && getValidationErrors?.errors?.generation
                        ? getValidationErrors.errors?.generation[0].message
                        : undefined
                    }
                    inputRef={ref}
                    onItemChange={(e: any) => {
                      onChange(e);
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: '26px' }}>
              <Controller
                control={control}
                name="StudentIdNumber"
                defaultValue={getPlayerProfile?.studentIdNumber ?? ''}
                render={({ field: { onChange, ref }, formState: { errors } }) => (
                  <>
                    <Input
                      id="inputStudentId"
                      label={t('player_account_edit_profile_student_number')}
                      defaultValue={getPlayerProfile?.studentIdNumber ?? ''}
                      onChange={onChange}
                      inputRef={ref}
                      error={errors?.StudentIdNumber}
                    />
                    {errors.StudentIdNumber && (
                      <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                        {errors?.StudentIdNumber?.message}
                      </Text>
                    )}
                  </>
                )}
              />
            </Box>
            <Stack>
              <Text variant="ribbon" sx={{ lineHeight: '16px', color: 'neutral400', mb: '6px' }}>
                {t('player_account_edit_profile_upload_image_id')}
              </Text>

              <Controller
                control={control}
                name="StudentCardFile"
                defaultValue={getPlayerProfile?.studentCardFile ?? undefined}
                render={({ field: { onChange, ref } }) => (
                  <UploadImage
                    defaultImage={getPlayerProfile?.studentCardFile}
                    title={t('player_account_edit_profile_upload_image_message')}
                    getImageBlob={val => onChange(val)}
                    inputRef={ref}
                    onImageLoad={function noRefCheck() {}}
                    getImageSource={_v => {
                      setImageEdited(true);
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>

          <PrimaryButton
            wide
            onClick={handleSubmit(saveAccountProfile)}
            disabled={disableButton}
            sx={{ borderRadius: '4px' }}
          >
            {t('player_account_edit_profile_save')}
          </PrimaryButton>
        </Stack>
      </form>
    </>
  );
};

export default EditProfile;
