import styled from 'styled-components';
import { InputDateProps } from './InputDate';
import { CheckboxProps } from './checkbox';

export const SearchContainer = styled.div<any>`
  position: fixed;
  z-index: 199;
  background: #373942f0;
  width: 100%;
  align-self: center;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  margin: ${({ margin }) => margin};
  @media only screen and (min-width: 500px) {
    max-width: 500px;
  }
`;

export const InputSearch = styled.input`
  position: flex;
  width: 100%;
  height: 44px;
  font-size: 14px;
  border: 1px solid #f1ebeb;
  box-sizing: border-box;
  border-radius: 12px;
  color: white;
  font-family: Roboto;
  background: ${`url('${process.env.PUBLIC_URL}/media/icons/ic-search-white.svg')`}no-repeat scroll
    7px 7px;
  padding-left: 40px;
  &:focus {
    outline: none !important;
    border-color: #d51f40;
  }
  margin-left: 16px;
  margin-right: 16px;
  &::-moz-selection {
    color: white;
    background: #d51f40;
  }
  &::selection {
    color: white;
    background: #d51f40;
  }
`;
export const Input = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: none;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  font-size: 14px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: #eee;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: white;
  font-family: Roboto;
  margin-top: 12px;
  margin-left: 11px;
  margin-bottom: 11px;
  background: transparent;
  &-webkit-autofill, 
  &-webkit-autofill:active, 
  &-webkit-autofill:focus, 
  &-webkit-autofill:hover, select:-webkit-autofill, select:-webkit-autofill:active, select:-webkit-autofill:focus, select:-webkit-autofill:hover, textarea:-webkit-autofill, textarea:-webkit-autofill:active, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:hover {
      transition: background-color 600000s 0s,color 600000s 0s;
      background-color: transparent;
  }
  &:-webkit-autofill {
    background-color: transparent;
  }
  &:autofill {
    background-color: transparent;
  }
  &:autofill {
    background-color: transparent !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
  }
  &:-internal-autofill-selected{
    background-color: transparent;
  }
  &:focus {
    outline: none !important;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-progress-appearance: none;
    background: transparent;
  }
  &::-moz-selection {
    color: white;
    background: #E43C5A;
  }
  &::selection {
    color: white;
    background: #E43C5A;
  }
  &:invalid {
    outline: none !important;
    border-color: #d51f40;
  }
  &::-internal-autofill-selected {
    background-color: transparent;
  }

`;

export const InputDates = styled.input<InputDateProps>`
  content: ${props => props.placeholder};
  position: relative;
  z-index: 1;
  width: 100%;
  height: 40px;
  appearance: none;
  -webkit-appearance: none;
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
    display: block;
    height: 32px;
    width: 32px;
    background: transparent; // ${props =>    `url('${process.env.PUBLIC_URL}/media/icons/${props.icon}.svg')` ??   `url('media/icons/ic-calendar-dark.svg')`} no-repeat scroll;
  }
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: #eee;
  color: white;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.44px;
  padding-left: 11px;
  padding-right: 11px;
  background: transparent;
  &:focus {
    outline: none !important;
    border-color: #d51f40;
  }
  &::-moz-selection {
    color: white;
    background: #E43C5A;
  }
  &::selection {
    color: white;
    background: #E43C5A;
  }
`;
export const BorderInput = styled.div<any>`
  position: relative;
  width: 100%;
  height: ${p => p.height ?? 'auto'};
  margin-bottom: 15px;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 12px;
  margin: ${p => p.margin};
  padding: ${p => p.padding};
  
  & > input:-internal-autofill-selected{
    background-color: transparent !important;
  }
  &:focus {
    outline: none !important;
    border-color:${({ validationType, defaultValue }) => {
    switch (validationType) {
      case 'ERROR': 
      return '#d51f40'
      case 'WARNING':
        return '#E7B450';
      case 'INFO':
        return 'white';
      case 'VALID':
        return '#26BD87';
      default:
        return defaultValue ? '#26BD87' : '#d51f40' ;
    }
  }};
  }
  &:focus-within {
    outline: none !important;
    border-color:${({ validationType, defaultValue }) => {
    switch (validationType) {
      case 'ERROR': 
      return '#d51f40'
      case 'WARNING':
        return '#E7B450';
      case 'INFO':
        return 'white';
      case 'VALID':
        return '#26BD87';
      default:
        return defaultValue ? '#26BD87' : '#d51f40' ;
    }
  }};
  }
  &:invalid {
    outline: none !important;
    border-color: #e43c5a;
  }
  -webkit-user-drag: none;
`;
export const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 146%;
  user-select: none;
  color: #969393;
  margin: 9px 11px -7px 11px;
`;

export const NewLabel = styled.div`
  font-family: Inter;
  font-style: Medium;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  user-select: none;
  opacity: 50%;
  color: #FFFFFF;
  margin: 9px 11px -7px 11px;
`;

export const TextAreaField = styled.textarea`
  position: static;
  width: 98%;
  font-size: 14px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: #eee;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: white;
  font-family: Roboto;
  margin-top: 12px;
  padding-left: 11px;
  background: transparent;
  &:focus {
    outline: none !important;
    border-color: #d51f40;
  }
  &::-moz-selection {
    color: white;
    background: #d51f40;
  }
  &::selection {
    color: white;
    background: #d51f40;
  }
`;
export const IconEye = styled.i `
  position: absolute;
  top: 31%;
  right: 5%;
`

export const BorderTextArea = styled.div<any>`
  position: relative;
  width: 100%;
  height: auto;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 12px;
  margin: ${p => p.margin};
  &:focus-within {
    outline: none !important;
    border-color: #d51f40;
  }
  -webkit-user-drag: none;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<CheckboxProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props =>
    props.checked
      ? 'linear-gradient(131.42deg, #D51F40 46.48%, #E24134 62.51%, #E8542E 74.36%, #F37024 83.78%);'
      : 'transparent'};
  border-radius: 3px;
  border: 1px solid white;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px white;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
