import theme from './theme';
import { ThemeProvider, createTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Layout from 'components/Layout';
import ScrollToTop from 'components/Layout/ScrollToTop';
import LayoutProvider from 'context/LayoutContext';
import history from 'modules/history';
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { subscribeNotificationHub } from 'redux/notification/NotificationReducer';
import { useAppThunkDispatch } from 'redux/store';
import RouteList from 'routes/RouteList';
import AuthService from 'services/AuthService';

const muiTheme = createTheme(theme);
const auth = new AuthService();

function Root() {
  const dispatch = useAppThunkDispatch();
  const isAuthenticated = auth.isAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(subscribeNotificationHub());
    }
  });

  return (
    <LayoutProvider>
      <Router history={history}>
        <ThemeProvider theme={muiTheme}>
          <ScrollToTop />
          <Layout>
            <RouteList isAuthenticated={isAuthenticated} />
          </Layout>
        </ThemeProvider>
      </Router>
    </LayoutProvider>
  );
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: ['Non-Error promise rejection captured'],
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0,
  });
}

export default Root;
