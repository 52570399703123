import AddMemberDrawer from '../Drawer/AddMemberDrawer';
import { Box, Stack, Avatar } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardSection } from 'design/Cards/CardSection';
import { Player as PlayerCard } from 'design/Cards/Player';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Game } from 'redux/account/AccountResponse';
import { useAppSelector } from 'redux/hooks';
import { Player } from 'redux/team/TeamMemberReducer';
import { TeamMember } from 'redux/teamProfile/TeamProfileResponse';

interface PlayerListProps {
  myRole?: Game;
  isCaptain?: boolean;
  handleFetchPlayerList: (
    schoolId: string,
    gameId: string,
    pI: number,
    pS: number,
    search?: string,
  ) => void;
  acceptMember: (playerId: string, teamId: string) => void;
  actionPlayerOnClickList: (player: TeamMember) => void;
  actionPlayerRejectJoin: (player: TeamMember) => void;
  setRefetchTeamProfile: (value: boolean) => void;
  setOpenLoader: (value: boolean) => void;
  setToastErrorMessages: (value: string) => void;
  setOpenErrorToast: (value: boolean) => void;
  setToastMessages: (value: string) => void;
  setOpenToast: (value: boolean) => void;
  setError: (value: { name: string; message: string; stack: string }) => void;
}

const PlayerList = ({
  myRole,
  isCaptain,
  handleFetchPlayerList,
  acceptMember,
  actionPlayerOnClickList,
  actionPlayerRejectJoin,
  setRefetchTeamProfile,
  setOpenLoader,
  setToastErrorMessages,
  setOpenErrorToast,
  setToastMessages,
  setOpenToast,
  setError,
}: PlayerListProps) => {
  const { t } = useTranslation();

  const { accountInfo, teamMember } = useAppSelector(({ account, teamProfile }) => ({
    accountInfo: account?.data,
    teamMember: teamProfile?.teamMember,
  }));

  const [isOpenAddMember, setIsOpenAddMember] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [resetInputSearch, setResetInputSearch] = useState(false);

  const handleOpenAddMember = () => {
    setOpenLoader(true);
    setIsOpenAddMember(true);
    if (accountInfo && myRole) {
      handleFetchPlayerList(accountInfo.school.id, myRole.game.id, 1, 200);
    }
  };

  const handleOpenAddMemberDrawer = () => {
    setIsOpenAddMember(!isOpenAddMember);
  };

  const handleCloseAddMemberDrawer = () => {
    setIsOpenAddMember(false);
    setSelectedPlayers([]);
    setResetInputSearch(true);
  };

  const memberApproved = teamMember?.items.filter(member => member.status.value === 'Approved');
  const memberApprovedLength = (memberApproved ?? []).length;

  return (
    <>
      <CardSection
        title={
          <Text
            variant="label"
            component="h5"
            sx={{ textTransform: 'capitalize', letterSpacing: 0 }}
          >
            {t('player_team_profile_member_tab_member')}
          </Text>
        }
        sx={{ bgcolor: 'transparent', pt: '24px' }}
      >
        <Box
          sx={theme => ({
            background: theme.palette.neutral700,
            border: `1px solid ${theme.palette.neutral600}`,
            borderRadius: '8px',
          })}
        >
          {isCaptain && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={theme => ({ p: '12px', borderBottom: `1px solid ${theme.palette.neutral600}` })}
              onClick={handleOpenAddMember}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: '42px',
                  height: '42px',
                  bgcolor: 'neutral600',
                  borderRadius: '100%',
                  flexShrink: 0,
                }}
              >
                <Avatar src={IconSource('ic-add')} sx={{ width: '20px', height: '20px' }} />
              </Stack>
              <Text variant="label" sx={{ fontWeight: 400 }}>
                {t('add_new_member')}
              </Text>
            </Stack>
          )}

          <Stack direction="column">
            {teamMember?.items
              .slice()
              .sort((a, b) => (a.joinAt > b.joinAt ? 1 : -1))
              .sort((a, b) => {
                if (a.status.value === 'Approved' && b.status.value === 'Approved') {
                  if (a.level.value === 'Captain') return -1;
                  return 0;
                }
                if (a.status.value === 'Waiting') return 1;
                return -1;       
              })
              .map((player, index) => {
                return (
                  <PlayerCard
                    key={index}
                    playerId={player?.player.id}
                    playerName={player.player.name}
                    playerInfo={`${player.player.nickName} | ${player.player.gameProviderId}`}
                    playerPhoto={player.player.picture !== null ? player.player.picture : undefined}
                    isCaptain={player.level.value === 'Captain'}
                    isYou={player?.player.id === accountInfo?.id}
                    icon={
                      isCaptain &&
                      player.status.value === 'Waiting' &&
                      player.type.value == 'Invite'
                        ? IconSource('ic-waiting')
                        : undefined
                    }
                    cta={
                      isCaptain &&
                      player.status.value === 'Waiting' &&
                      player.type.value === 'Request' ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <PrimaryButton
                            wide
                            variant="small"
                            sx={{ p: '4px 8px' }}
                            onClick={() => acceptMember(player?.player.id, player?.teamId)}
                          >
                            Terima
                          </PrimaryButton>
                          <SecondaryButton
                            wide
                            variant="small"
                            sx={{ p: '4px 8px' }}
                            onClick={() => actionPlayerRejectJoin(player)}
                          >
                            Tolak
                          </SecondaryButton>
                        </Stack>
                      ) : (
                        <Avatar
                          src={IconSource('ic-chevron-right')}
                          sx={{ width: '24px', height: '24px' }}
                        />
                      )
                    }
                    sx={theme => ({
                      m: 0,
                      borderTop:
                        player.status.value === 'Waiting' && index == memberApprovedLength
                          ? `1px solid ${theme.palette.neutral600}`
                          : 'none',
                      ...(!isCaptain && player.status.value === 'Waiting' && { display: 'none' }),
                    })}
                    {...(isCaptain &&
                    player.status.value === 'Waiting' &&
                    player.type.value === 'Request'
                      ? {
                          onClickContent: () => actionPlayerOnClickList(player),
                        }
                      : {
                          onClick: () => actionPlayerOnClickList(player),
                        })}
                  />
                );
              })}
          </Stack>
        </Box>
      </CardSection>

      <AddMemberDrawer
        open={isOpenAddMember}
        onClose={handleCloseAddMemberDrawer}
        onOpen={handleOpenAddMemberDrawer}
        myRole={myRole}
        selectedPlayers={selectedPlayers}
        resetInputSearch={resetInputSearch}
        handleFetchPlayerList={handleFetchPlayerList}
        setIsOpenAddMember={setIsOpenAddMember}
        setRefetchTeamProfile={setRefetchTeamProfile}
        setOpenLoader={setOpenLoader}
        setToastMessages={setToastMessages}
        setToastErrorMessages={setToastErrorMessages}
        setOpenErrorToast={setOpenErrorToast}
        setOpenToast={setOpenToast}
        setError={setError}
      />
    </>
  );
};

export default memo(PlayerList);
