import styled from 'styled-components';
import { MatchUploadProps, MatchUploadBgProps } from './index';

export const CardUpload = styled.div<MatchUploadBgProps>`
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 12px;
  position: relative;
  margin-top: ${props => props.frameMt ?? '0px'};
  width: ${props => props.w ?? '100%'};
  height: ${props => props.h ?? '345px'};
  box-sizing: border-box;
  object-fit: contain;
  background: ${props => props.bg ?? 'rgba(55, 57, 66, 0.6)'};
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
export const BorderUpload = styled.div<MatchUploadProps>`
  width: ${props => props.bw ?? '92%'};
  height: ${props => props.bh ?? '92%'};
  margin-top: ${props => props.mt ?? 'auto'};
  margin-bottom: ${props => props.mb ?? 'auto'};
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  object-position: bottom;
  border: 2px dashed #969393;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const TextUnggah = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 146%;
  margin-top: 26px;
  /* identical to box height, or 18px */

  text-align: center;

  /* Gray 03 */

  color: #c7bebe;
`;
export const IconUpload = styled.div<MatchUploadProps>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  margin-top: ${props => props.mtIcon ?? '21px'};
  background-image: ${props =>
    `url('${process.env.PUBLIC_URL}/media/icons/${props.bg}.svg')` ??
    `url('media/icons/upload-img-icon.svg')`};
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transform: ${props => props.transform ?? 'rotate(90deg)'};
  /* :hover {
        transform: scale(1.1);
        box-shadow: 0 0 15px red;
        border-radius: 100px;
        transform: rotate(90deg);
        transition: .5s;
    } */
`;
export const UploadFile = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 9;
  cursor: pointer;
`;
export const Title = styled.h1`
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 146%;
  /* identical to box height, or 18px */

  text-align: center;

  /* Gray 03 */

  color: white;
  padding: 10px;
`;
export const Column = styled.div`
  float: left;
  width: 50%;
`;

export const Row = styled.div`
  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

export const TitleStatus = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 146%;
  margin-left: 6%;
  color: #c7bebe;
  text-align: left;
`;

export const ImagePreview = styled.img<MatchUploadProps>`
  width: 100%;
  height: 100%;
  border-radius: ${props => props.borderRadius ?? '12px'};
  object-fit: 100%;
  object-position: bottom;
  transform: ${props => props.transform ?? 'rotate(90deg)'};
  @media only screen and (min-width: 600px) {
    object-fit: cover;
    object-position: center;
  }
`;

export const CircleBorder = styled.div`
  position: relative;
  width: 140px;
  height: 140px;
  border-radius: 100px;
  background: rgba(55, 57, 66, 0.94);
  margin: auto;
  top: 9px;
`;

export const OutlineBorder = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  /* left: 0px;
    top: 160px; */

  background: linear-gradient(
    131.42deg,
    #d51f40 16.85%,
    rgba(234, 88, 44, 0.297578) 48.91%,
    rgba(240, 105, 38, 0.0853196) 69.28%,
    rgba(242, 109, 37, 0.0418083) 71.77%,
    rgba(243, 112, 36, 0) 82.91%
  );
  filter: drop-shadow(-10px 10px 40px rgba(213, 31, 64, 0.26));
  border-radius: 100px;
  transform: rotate(-90deg);
  /* :hover {
        transform: rotate(-50deg);
    } */
`;
export const InlineBorder = styled.div`
  position: relative;
  width: 122px;
  height: 122px;
  border: 1px dashed #969393;
  box-sizing: border-box;
  border-radius: 100px;
  margin: auto;
  top: 9px;
`;
