import React, { HTMLAttributes } from 'react';
import { styled, SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export interface CustomButtonProps extends HTMLAttributes<HTMLButtonElement> {
  background?: string;
  justify?: string;
  sx?: SxProps<Theme>;
}

const CustomButton: any = styled('button')<CustomButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transition-property: background, color;
  padding: 8px;
  border-radius: 10px;
  outline: 0;
  min-width: 180px;
  background-color: ${props => props.background && props.background};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  color: white;
  border: none;

  &:active {
    opacity: 0.7;
  }
`;

export const SocialMediaButton = (props: CustomButtonProps) => {
  return <CustomButton {...props} />;
};
