import i18n from './i18n';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { register } from './serviceWorkerRegistration';
import Root from 'Root';
import { showAlert } from 'actions';
import Reload from 'components/Reload';
import ErrorHandler from 'containers/ErrorHandler';
import GlobalStyles from 'containers/GlobalStyles';
import ScrollProvider from 'context/ScrollContext';
import { disableReactDevTools } from 'modules/helpers';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

window.store = store;
window.React = React;

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ErrorHandler>
          <HelmetProvider>
            <ScrollProvider>
              <Root />
            </ScrollProvider>
          </HelmetProvider>
        </ErrorHandler>
        <GlobalStyles />
      </Provider>
    </PersistGate>
  </I18nextProvider>,
  document.getElementById('root'),
);

/* istanbul ignore next */
register({
  onUpdate: () => {
    store.dispatch(showAlert(<Reload />, { id: 'sw-update', icon: 'bolt', timeout: 0 }));
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'production') {
  // const CURRENT_HOSTNAME = window.location.hostname;
  // if (CURRENT_HOSTNAME === 'mainmabar.stagingapps.net') {
  //   ReactGA.initialize(process.env.GA_STAG_MEASUREMENT_ID ?? '');
  //   ReactGA.gtag('js', new Date());
  //   ReactGA.gtag('config', process.env.GA_STAG_MEASUREMENT_ID);
  // } else {
  ReactGA.initialize(process.env.GA_MEASUREMENT_ID ?? '');
  ReactGA.gtag('js', new Date());
  ReactGA.gtag('config', process.env.GA_MEASUREMENT_ID);
  // }
} else {
  reportWebVitals(console.log);
}
