import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

// for header
export interface TeamPlayer {
  playerId: string;
  fullName: string;
  userName: string;
  nickName?: string;
  picture: string;
  gameProviderId: string;
  hasCheckedIn: boolean;
  isCaptain: boolean;
}

export interface ScrimPlayerState {
  data?: TeamPlayer[];
  isLoading: boolean;
  status: string;
  error: string;
}

export const initialState: ScrimPlayerState = {
  isLoading: false,
  status: '',
  error: '',
};

export const getScrimPlayer = createAsyncThunk(
  'scrim/getScrimPlayer',
  async ({
    matchId,
    version,
    teamSlug,
  }: {
    matchId: string;
    version?: string;
    teamSlug?: string;
  }) => {
    const url = `/${version ? `v${version}/` : ''}scrim-player/${matchId}/${teamSlug}`;
    const response = await ApiService.get(url);
    return response.data;
  },
);

const ScrimMatchDetailSlice = createSlice({
  name: 'scrim/scrim-player',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // header
    builder.addCase(getScrimPlayer.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getScrimPlayer.pending, state => {
      state.isLoading = true;
      state.data = [];
    });
    builder.addCase(getScrimPlayer.rejected, state => {
      state.isLoading = false;
      state.data = [];
    });
  },
});

export default ScrimMatchDetailSlice.reducer;
