import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC } from 'react';

export interface CardTimeType {
  textTitle?: string | undefined;
  icon?: string | undefined;
  textInfo?: string | undefined;
  iconRight?: string | undefined;
}

const CardTime: FC<CardTimeType> = ({ textTitle, icon, textInfo, iconRight }) => {
  return (
    <Box
      background="linear-gradient(295.84deg, rgba(213, 31, 64, 0.2) 22.62%, rgba(243, 112, 36, 0.2) 74.92%);"
      flexGrow={1}
      padding="12px"
      borderRadius="16px"
      margin="10px 0px 5px 0px"
    >
      <Stack direction="row" style={{ justifyContent: 'space-between' }}>
        <Stack direction="column" align="start">
          <NormalText
            color="#969393"
            fontSize="12px"
            fontWeight="normal"
            margin="0px 0px 0px 0px"
            lineHeight="140%"
          >
            {textTitle}
          </NormalText>
          <Box margin="10px 0px 0px 0px">
            <Stack direction="row" align="start">
              <Avatar src={icon} height="24px" borderRadius="50%" margin="0px" />
              <NormalText
                textAlign="left"
                color="#FFFFFF"
                fontSize="14px"
                fontWeight="700"
                margin="4.5px 0px 0px 10px"
              >
                {textInfo}
              </NormalText>
            </Stack>
          </Box>
        </Stack>
        <Box width="32px" height="45px">
          <Avatar src={iconRight}></Avatar>
        </Box>
      </Stack>
    </Box>
  );
};

export default CardTime;
