import styled from 'styled-components';

export const TeamListItem = styled.section<any>`
  width: 100%;
  height: 90px;
  display: grid;
  grid-template-columns: repeat(2, 90px);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 0 12px;
  grid-auto-flow: column;
  background: rgba(55, 57, 66, 0.6);
  border-radius: 16px;
  justify-content: first baseline;
  align-items: flex-start;
  margin-bottom: 12px;
`;
