import BackgroundImage from 'components/layouts/BackgroundImage';
import { Box, Stack } from 'components/styled/layout.styled';
import React, { FC } from 'react';
import MatchCard, { MatchCardType } from 'components/cards/MatchCard';
import TeamCard, { TeamCardType } from 'components/cards/TeamCard';

export interface HeaderMatchDetailsType {
  teamLeft?: TeamCardType | undefined;
  teamRight?: TeamCardType | undefined;
  headerBackground?: string | undefined;
  matchResult?: MatchCardType | undefined;
  styled?: React.CSSProperties;
  onLoad?: React.ReactEventHandler<HTMLDivElement>;
}

const HeaderMatchDetails: FC<HeaderMatchDetailsType> = ({
  teamLeft,
  teamRight,
  headerBackground,
  matchResult,
  styled, onLoad
}) => {
  return (
    <Box width="100%" onLoad={onLoad}>
      <BackgroundImage
        src={headerBackground}
        backgroundColor="linear-gradient(#00000090, #000000, transparent)"
        mask="linear-gradient(#000000, #00000023, transparent)"
        styled={styled}
      >
        <Box padding="0px" width="100%" height='100%'>
          <Stack direction="row" align="center">
            <TeamCard {...{ ...teamLeft, variant: 'stretch' }} />
            <MatchCard {...matchResult} />
            <TeamCard {...{ ...teamRight, variant: 'stretch' }} />
          </Stack>
        </Box>
      </BackgroundImage>
    </Box>
  );
};

export default HeaderMatchDetails;
