import { Stack } from '@mui/material';
import { TextButton } from 'design/Buttons/TextButton';
import { CardTournamentParticipation } from 'design/Cards/CardTournamentParticipation';
import { IconSource } from 'modules/assetpath';
import React, { memo } from 'react';
import { AwardsType } from 'redux/award/AwardProps';

interface CardFeedAwardProps {
  awardList?: AwardsType[];
  handleButtonRoster?: any;
}

const CardFeedAward = ({ awardList, handleButtonRoster }: CardFeedAwardProps) => {
  return (
    <Stack spacing="12px">
      {awardList?.map((award: any) => (
        <CardTournamentParticipation
          title={award?.title}
          description={`${award?.eventName} • ${award?.awardsDate}`}
          cta={
            <TextButton
              icon={IconSource('ic-tim-outline')}
              sx={{
                svg: { mr: '4px' },
              }}
              onClick={() => handleButtonRoster(award)}
            >
              Lihat Roster
            </TextButton>
          }
          sx={{ height: 'auto' }}
        />
      ))}
    </Stack>
  );
};

export default memo(CardFeedAward);
