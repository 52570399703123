import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { CardTournamentList } from 'design/Cards/CardTournament';
import { TournamentStatus } from 'design/Cards/CardTournament/TournamentStatus';
import { TagProps } from 'design/Tag';
import { TagTournamentCard } from 'design/Tag/TagTournamentCard';
import { TOURNAMENT_MODE } from 'dictionaries/tournament';
import { currencyFormat } from 'modules/helpers';
import history from 'modules/history';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TournamentItem } from 'redux/tournament/TournamentProps';
import { APP_DATE_FORMAT } from 'store/constants/date';
import * as STATE from 'store/constants/tournament';

export interface HandleTagStatusProps {
  teamState?: TournamentItem['teamState'];
  playerState?: TournamentItem['playerState'];
  state?: TournamentItem['state'];
  openRegAt?: TournamentItem['openRegAt'];
  closeRegAt?: TournamentItem['closeRegAt'];
  startAt?: TournamentItem['startAt'];
  endAt?: TournamentItem['endAt'];
  winnerTeam?: TournamentItem['winnerTeam'];
  requireParticipantApproval?: TournamentItem['requireParticipantApproval'];
  totalAcceptedPlayer?: TournamentItem['totalAcceptedPlayer'];
  totalInvitedPlayer?: TournamentItem['totalInvitedPlayer'];
}

interface CardTournamentProps extends TournamentItem {
  index: number;
}

const CardTournament = ({ index, ...props }: CardTournamentProps) => {
  const { t } = useTranslation();

  const handleTag = ({ state }: HandleTagStatusProps): TagProps => {
    switch (state) {
      case STATE.UPCOMING:
        return { title: t('tournament_state_soon'), variant: 'yellow' };
      case STATE.BRACKET_GENERATED:
        return { title: t('tournament_state_bracket_created'), variant: 'gray' };
      case STATE.FINISHED:
        return { title: t('tournament_state_finished'), variant: 'black' };
      case STATE.REGISTRATION_OPENED:
        return { title: t('tournament_state_registration_open'), variant: 'green' };
      case STATE.REGISTRATION_CLOSED:
        return { title: t('tournament_state_registration_closed'), variant: 'red' };
      case STATE.COMPETITION_STATED:
        return { title: t('tournament_state_ongoing'), variant: 'orange' };
      default:
        return { title: '', variant: 'gray' };
    }
  };

  const handleStatus = ({
    state,
    teamState,
    startAt,
    closeRegAt,
    winnerTeam,
  }: HandleTagStatusProps) => {
    const now = dayjs();
    const diffDays = dayjs(startAt).diff(now, 'day');
    const diffHours = dayjs(closeRegAt).diff(now, 'hours');

    if (diffDays <= 3 && diffDays > 0 && teamState === '2') {
      return (
        <TournamentStatus status="closed" title={t('counter_date_match', { dateLeft: diffDays })} />
      );
    }

    if (diffHours <= 24 && diffHours > 0) {
      return (
        <TournamentStatus
          status="warning"
          title={t('counter_time_match', { timeLeft: diffHours })}
        />
      );
    }

    if (winnerTeam) {
      if (state === STATE.FINISHED && winnerTeam?.id !== '' && winnerTeam?.schoolName != '') {
        return (
          <TournamentStatus
            status="complete"
            name={winnerTeam?.schoolName}
            logo={winnerTeam?.avatarFile}
          />
        );
      }
    }

    if (teamState === '2') {
      return <TournamentStatus status="success" title={t('team_registered')} />;
    }
    //NOTE : not exist in FIGMA
    /*else if (teamState === '3') {
      return <TournamentStatus status="failed" title={t('register_failed')} />;
    }*/

    return false;
  };

  const restructureData = (data: TournamentItem) => {
    let tagState = handleTag({ state: data?.state });
    let returnData = {
      title: data?.title,
      gameSlug: data?.gameSlug,
      tournamentSlug: data?.urlSlug,
      banner: data?.banner,
      desc: data?.shortDescription,
      schedule: `${dayjs(data?.startAt).format(APP_DATE_FORMAT)} - ${dayjs(data?.endAt).format(
        APP_DATE_FORMAT,
      )}`,
      price: data?.eventPrizeIdr
        ? `Total Hadiah: ${currencyFormat(data?.eventPrizeIdr, 0)}`
        : undefined,
      type: TOURNAMENT_MODE[data?.mode!],
      tag: <TagTournamentCard title={tagState?.title} variant={tagState?.variant} />,
      status: handleStatus({
        state: data?.state,
        teamState: data?.teamState,
        startAt: data?.startAt,
        closeRegAt: data?.closeRegAt,
        winnerTeam: data?.winnerTeam,
      }),
    };
    return returnData;
  };

  const tournamentList = restructureData(props);
  const cardTournamentRef = useRef(null);

  const handleClickTournamentList = () => {
    const currentURL = window.location.pathname;
    history.push({
      pathname: `/tournament/${tournamentList.gameSlug}/${tournamentList.tournamentSlug}`,
      state: {
        pathname: currentURL,
        scrollTarget: index,
      },
    });
  };

  return (
    <Box
      sx={{ cursor: 'pointer', position: 'relative', zIndex: 1 }}
      onClick={handleClickTournamentList}
      ref={cardTournamentRef}
    >
      <CardTournamentList
        title={tournamentList.title}
        banner={tournamentList.banner}
        desc={tournamentList.desc}
        schedule={tournamentList.schedule}
        price={tournamentList.price}
        type={tournamentList.type}
        tag={tournamentList.tag}
        status={tournamentList.status}
      />
    </Box>
  );
};

export default CardTournament;
