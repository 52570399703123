import { ScrimListResponse } from './ScrimProps';
import { failedGetScrimList, getScrimList, successGetScrimList } from './ScrimReducer';
import { getScrimListService } from './ScrimService';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';

interface Res<T> {
  data: T;
  status: number;
}

function* fetchScrimListSaga(action: AnyAction) {
  try {
    const res: Res<ScrimListResponse> = yield call(getScrimListService, action.payload);
    if (res.status !== 200) {
      throw res;
    }
    yield put(successGetScrimList(res.data));
  } catch (e) {
    yield put(failedGetScrimList({ error: 'Error Pak eko' }));
  }
}

function* scrimListSaga() {
  yield takeLatest(getScrimList.type, fetchScrimListSaga);
}

export default scrimListSaga;
