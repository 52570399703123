import copy from 'copy-to-clipboard';
import { TextButton } from 'design/Buttons/TextButton';
import { CardProfileGame } from 'design/Cards/CardProfileGame';
import { CardSection } from 'design/Cards/CardSection';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

interface ProfileGameProps {
  setToastMessage: (value: string) => void;
  setOpenToast: (value: boolean) => void;
}

const ProfileGame = ({ setToastMessage, setOpenToast }: ProfileGameProps) => {
  const { t } = useTranslation();

  const { playerInfo } = useAppSelector(({ account }) => ({
    playerInfo: account?.data,
  }));

  const myRole = playerInfo?.games && playerInfo?.games[0];
  const haveTeamFromMyRole = Boolean(myRole?.team);

  const handleCopy = (value: string, message: string) => {
    Promise.all([
      copy(value),
      setTimeout(() => {
        setToastMessage(message);
        setOpenToast(true);
      }, 300),
    ]);
  };

  const dataCopy = [
    {
      title: 'Game Nickname',
      content: playerInfo?.userName ?? '',
      handleCopy: () =>
        handleCopy(
          playerInfo?.userName ?? '',
          t('player_player_profile_information_game_nickname'),
        ),
    },
    {
      title: 'Game Id',
      content: playerInfo?.games ? playerInfo?.games[0]?.gameProviderId ?? '' : '',
      handleCopy: () =>
        handleCopy(
          playerInfo?.games ? playerInfo?.games[0]?.gameProviderId ?? '' : '',
          t('player_player_profile_information_game_id'),
        ),
    },
  ];

  return haveTeamFromMyRole ? (
    <CardSection
      title="Profil Game"
      cta={
        <Link to="/account/edit-game">
          <TextButton
            icon={IconSource('ic-edit')}
            sx={{ svg: { width: '16px', height: '16px', mr: '4px' } }}
          >
            <Text variant="ribbon">Edit</Text>
          </TextButton>
        </Link>
      }
    >
      <CardProfileGame
        gameName={playerInfo?.games[0]?.game?.name ?? ''}
        gameLogo={`${process.env.PUBLIC_URL}/media/images/game/mlbb/logo-square-mlbb@3x.png`}
        dataCopy={dataCopy}
      />
    </CardSection>
  ) : null;
};

export default memo(ProfileGame);
