import PrivateNavigation from './PrivateNavigation';
import PublicNavigation from './PublicNavigation';
import React, { memo, useCallback } from 'react';
import { useAppSelector } from 'redux/hooks';
import AuthService from 'services/AuthService';

interface TeamProfileNavProps {
  paramGameSlug: string;
  paramTeamSlug: string;
  setPageTitle: any;
  hash: string;
  setOpenToast: (value: boolean) => void;
  setToastMessages: (value: string) => void;
  setOpenErrorToast: (value: boolean) => void;
  setToastErrorMessages: (value: string) => void;
  setOpenLoader: (value: boolean) => void;
}

const TeamProfileNavigation = ({
  paramGameSlug,
  paramTeamSlug,
  setPageTitle,
  hash,
  setOpenToast,
  setToastMessages,
  setOpenErrorToast,
  setToastErrorMessages,
  setOpenLoader,
}: TeamProfileNavProps) => {
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const { teamInfo } = useAppSelector(({ myTeam }) => ({
    teamInfo: myTeam?.data?.items,
  }));

  const myTeam = isAuthenticated
    ? teamInfo?.find(team => team?.urlSlug === paramTeamSlug)
    : undefined;

  const isJoinApproved = myTeam?.status?.value === 'Approved';
  const isPrivateNav = paramTeamSlug === myTeam?.urlSlug && isJoinApproved;

  const TeamProfileNavigation = useCallback(
    () =>
      isPrivateNav ? (
        <PrivateNavigation
          paramGameSlug={paramGameSlug}
          paramTeamSlug={paramTeamSlug}
          setPageTitle={setPageTitle}
          hash={hash}
          setOpenToast={setOpenToast}
          setToastMessages={setToastMessages}
          setOpenErrorToast={setOpenErrorToast}
          setToastErrorMessages={setToastErrorMessages}
          setOpenLoader={setOpenLoader}
        />
      ) : (
        <PublicNavigation paramTeamSlug={paramTeamSlug} hash={hash} setOpenToast={setOpenToast} />
      ),
    [isPrivateNav],
  );

  return <TeamProfileNavigation />;
};

export default memo(TeamProfileNavigation);
