import MatchDetailNav from './MatchDetailNav';
import GradientButton from 'components/Buttons/GradientButton';
import HeaderMatchTantangan from 'components/cards/HeaderMatchTantangan';
import AppLayout from 'components/layouts/AppLayout';
import Modal from 'components/layouts/Modal';
import { TabButtonType } from 'components/layouts/TabButton';
import TabMenu from 'components/layouts/TabMenu';
import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { fetchGameDetail } from 'redux/game/GameReducer';
// import { getScrimDetail } from 'redux/scrim/ScrimDetailReducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ScrimDetailResponse } from 'redux/scrim/ScrimDetailReducer';
// import { useTranslation } from 'react-i18next';
import { getScrimDetailById } from 'redux/scrim/ScrimService';
import { useAppThunkDispatch } from 'redux/store';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';
import { rejectInvitation } from 'redux/teamProfile/TeamProfileService';

const MatchDetail = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const [data, setData] = useState<ScrimDetailResponse>();
  const playerInfo = getPlayerProfileFromSession();
  const [dataTab, setDataTab] = useState<TabButtonType[]>([
    {
      id: 1,
      title: 'Informasi',
      isSelected: true,
    },
    {
      id: 2,
      title: '-',
      isSelected: false,
    },
  ]);

  const sessionTeam = getMyCurrentTeamFromSession();
  const [showModal, setShowModal] = useState(false);
  const [getTabChange, setTabChange] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [getIsSameTeam, setIsSameTeam] = useState(false);
  const [teamSlug, setTeamSlug] = useState('');
  const states = useAppSelector(state => state.teamProfile);

  // @ts-ignore
  const { game_slug, scrim_id } = useParams();

  const handleClickBack = () => {
    history.go(-1);
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const fetchData = () => {
    thunkDispatch(getScrimDetailById({ id: scrim_id }))
      .unwrap()
      .then(scrim => {
        setData(scrim.data);
        const tabs = [...dataTab];

        tabs[1].title = scrim.data?.firstTeam || '';
        tabs[1].isSelected = dataTab[1].isSelected;

        setDataTab(tabs);

        if (sessionTeam.find(v => v.urlSlug === scrim.data.firstTeamUrlSlug)) {
          setIsSameTeam(true);
        } else {
          setIsSameTeam(false);
        }
      });

    thunkDispatch(fetchGameDetail({ gameSlug: game_slug.toString() }))
      .unwrap()
      .then(game => {
        if (game && sessionTeam.length > 0) {
          const team = sessionTeam.find(x => x.gameId === game.id);
          if (team) {
            setTeamSlug(team.urlSlug);
          }
        }
      })
      .catch(() => {});
  };

  const reject = async (matchId: string) => {
    setShowModal(false);
    const thunk = rejectInvitation({ matchId });
    thunkDispatch(thunk)
      .unwrap()
      .then((result: any) => {
        if (result) {
          if (result.data.status >= 400) {
            setShowErrorModal(true);
          }
        }
        history.push(`/team/${game_slug || ''}/${playerInfo?.school?.urlSlug}/${teamSlug || ''}`);
      })
      .catch(() => {});
  };

  const ModalError = () => {
    return (
      <Box padding="24px 0px 24px 0px">
        <Stack direction="column" margin="16px">
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight="bold"
            margin="8px 0px"
          >
            Terjadi Kesalahan!
          </NormalText>
          <NormalText
            textAlign="center"
            color="red"
            fontSize="14px"
            fontWeight="normal"
            margin="8px 0px"
          >
            {states.error}
          </NormalText>
          <br />
          <Box padding="8px" width="100%">
            <Stack direction="column" align="center">
              <GradientButton
                onClick={() => {
                  setShowErrorModal(false);
                  window.location.reload();
                }}
                styled={{
                  backgroundSize: '100%',
                  width: '180px',
                }}
                variant="primary"
              >
                OK
              </GradientButton>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  };

  const scrimDate = data
    ? new Date(data?.startAt).toLocaleDateString('id-ID', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    : '-';
  const scrimTime = data
    ? new Date(data?.startAt).toLocaleTimeString('id-ID', {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      })
    : '-';
  return (
    <AppLayout
      backgroundImage={ImageSource('app.background')}
      appBar={{
        bg: {},
        title: `Detail Scrim ${scrim_id ? '#' + scrim_id : ''}`,
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
          onClick: handleClickBack,
        },
        navright: {
          variant: 'menu-options',
          bg: 'white',
          onClick: () => null,
          idNav: 'tp-menu-options',
        },
      }}
      noMargin
      showModal={showModal}
      modalContent={
        <Box padding="24px 0px 24px 0px">
          <Stack direction="column" align="center">
            <div style={{ width: '180px' }}>
              <NormalText
                textAlign="center"
                color="#FFFFFF"
                fontSize="16px"
                fontWeight="700"
                margin="8px 0px"
              >
                {t('player_scrim_match_scrim_accept_message')}
              </NormalText>
            </div>
            <Box
              width="100%"
              margin="10px"
              padding="0px"
              background="linear-gradient(295.84deg, rgba(213, 31, 64, 0.2) 22.62%, rgba(243, 112, 36, 0.2) 74.92%)"
            >
              <Stack direction="column" align="start">
                <NormalText
                  textAlign="center"
                  color="#FFFFFF"
                  fontSize="16px"
                  fontWeight="700"
                  margin="20px"
                >
                  {`${scrimDate} - ${scrimTime.replace('.', ':')}`}
                </NormalText>
                <Box width="100%" margin="10px">
                  <Stack direction="row" align="start">
                    <Avatar
                      src={data?.firstTeamAvatar}
                      height="20px"
                      borderRadius="50%"
                      border="2px solid #F1EBEB"
                      margin=" 0px 0px 0px 10px"
                    />
                    <NormalText
                      textAlign="left"
                      color="#FFFFFF"
                      fontSize="14px"
                      fontWeight="400"
                      margin="4px 0px 0px 10px"
                    >
                      {t('player_scrim_match_scrim_accept_team_name', {
                        team_name: data?.firstTeam,
                      })}
                    </NormalText>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Box margin="16px 0px 0px 0px">
              <Stack direction="column" align="center">
                <GradientButton
                  variant="primary"
                  onClick={() => {
                    setShowModal(false);
                    history.push(
                      `/team-match/choose-player/${teamSlug}/${scrim_id || ''}/?state=${
                        data?.status?.value === 'Open' ? 'open' : 'direct'
                      }&game=${game_slug}`,
                    );
                  }}
                  styled={{ backgroundSize: '100%' }}
                >
                  {t('player_scrim_match_scrim_accept_accept_scrim')}
                </GradientButton>
                {data?.status?.value === 'Invitation' ? (
                  <GradientButton variant="cta" onClick={() => reject(scrim_id)}>
                    {t('player_scrim_match_scrim_accept_reject_scrim')}
                  </GradientButton>
                ) : undefined}
              </Stack>
            </Box>
          </Stack>
        </Box>
      }
    >
      <HeaderMatchTantangan
        {...{
          headerBackground: ImageSource('card.background'),
          teamCard: {
            teamLogo: data?.firstTeamAvatar,
            teamName: data?.firstTeam,
            teamSchool: data?.firstTeamSchool,
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
          },
        }}
      />
      <TabMenu
        data={dataTab}
        variant="left"
        onSelected={tab => {
          setTabChange(tab.id === 1);
        }}
      />
      <Modal show={showErrorModal}>
        <ModalError />
      </Modal>
      <Box padding="0px" width="100%">
        <Stack direction="column" margin="0px 16px 0px 16px">
          <MatchDetailNav
            component={getTabChange}
            detailRules={{
              startAt: `${scrimDate} - ${scrimTime.replace('.', ':')}`,
              status: data?.status.value ?? '',
            }}
            detailPlayers={{
              teamPlayers: data?.firstTeamPlayers ?? [],
              captainName: data?.firstTeamCaptain ?? '',
            }}
          />
        </Stack>
      </Box>
      {!getIsSameTeam &&
      sessionTeam.find(v => v.level.description === 'Captain') &&
      (data?.status.value === 'Invitation' || data?.status.value === 'Open') ? (
        <Box
          width="100%"
          margin="0px 0px 24px 0px"
          position="fixed"
          style={{ zIndex: 199, bottom: '0px', maxWidth: '500px' }}
        >
          <Stack direction="row" align="center">
            <GradientButton
              variant="primary"
              onClick={() => setShowModal(true)}
              styled={{ backgroundSize: '100%' }}
            >
              <NormalText
                textAlign="center"
                color="white"
                fontSize="14px"
                fontWeight="700"
                margin="3px 0px"
              >
                Terima Tantangan Ini
              </NormalText>
            </GradientButton>
          </Stack>
        </Box>
      ) : undefined}
    </AppLayout>
  );
};

export default MatchDetail;
