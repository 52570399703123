import { TextField, TextFieldProps, InputBaseProps } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const CustomTextInput = styled(TextField)(({ theme, multiline }: any) => ({
  width: '100%',
  label: {
    ...theme.typography.label,
    color: theme.palette.neutral400,
    fontWeight: 300,
    marginLeft: '4px',
    transform: 'translate(8px, 12px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(8px, 5px) scale(0.7)',
      fontWeight: 600,
      '&.Mui-focused': {
        color: theme.palette.primary600,
      },
      '& + .MuiInputBase-root': {
        backgroundColor: theme.palette.neutral600,
        input: {
          padding: '23px 12px 8px 0',
        },
      },
    },
    '&.Mui-disabled, &.Mui-disabled + .MuiInputBase-root input': {
      color: theme.palette.neutral500,
      textFillColor: 'unset',
    },
    '&.Mui-disabled + .MuiInputBase-root': {
      background: theme.palette.neutral700,
      border: `1px solid ${theme.palette.neutral600}`,
    },
    '&.Mui-error': {
      color: theme.palette.neutral400,
    },
  },
  '.MuiInputBase-root': {
    ...theme.typography.body,
    color: theme.palette.neutral200,
    lineHeight: '20px',
    borderRadius: theme.borderRadius.md,
    backgroundColor: theme.palette.neutral600,
    height: multiline ? null : '44px',
    padding: multiline ? '15px 6px 7px 12px' : '14px 12px',
    '&:hover': {
      backgroundColor: theme.palette.neutral600,
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.danger600} !important`,
    },
    input: {
      padding: '12px 16px',
      '&::placeholder': {
        color: theme.palette.neutral500,
        background: 'transparent',
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '&[type=number]': {
        appearance: 'textfield',
      },
    },
    textarea: {
      marginTop: '6px',
      paddingRight: '5px',
      scrollbarWidth: 'thin',
      scrollbarColor: `${theme.palette.neutral500} #26211E`,
      '&::placeholder': {
        color: theme.palette.neutral500,
      },
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#26211E',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.neutral500,
        borderRadius: '10px',
      },
    },
    fieldset: {
      border: 0,
    },
    '&::before': {
      display: 'none',
    },
  },
  '& + .MuiFormHelperText-root': {
    ...theme.typography.subtitle,
    marginLeft: 0,
    '&.Mui-error': {
      color: theme.palette.danger600,
    },
  },
}));

export const TextInput = (props: TextFieldProps) => {
  return (
    <CustomTextInput
      InputProps={{ disableUnderline: true, ...props.InputProps } as Partial<InputBaseProps>}
      {...props}
    />
  );
};
