import { Theme, Stack, Box, Avatar } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { FC } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { Link } from 'react-router-dom';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export interface CardAwardListType {
  avatarIsCircle?: boolean;
  placeholderType?: 'team';
  awardText?: string;
  contentPhoto?: string;
  contentText?: string;
  contentTextLink?: string;
  contentSubtext?: string;
  sx?: SxProps<Theme>;
}

export const CardAwardList: FC<CardAwardListType> = ({
  avatarIsCircle = false,
  placeholderType,
  awardText,
  contentPhoto,
  contentText,
  contentTextLink,
  contentSubtext,
  sx,
  ...props
}) => {
  return (
    <>
      <Box bgcolor="neutral700" borderRadius="8px" p="8px 12px 12px 12px" sx={{ ...sx }} {...props}>
        <Stack direction="column" alignItems="start">
          <Text
            variant="label"
            sx={{
              color: 'neutral400',
              fontWeight: 400,
              letterSpacing: '0.2px',
              textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            {awardText}
          </Text>
          <Box marginTop="7px" paddingTop="8px" borderTop="1px solid #323437" width="100%">
            <Stack direction="row">
              <Avatar
                src={contentPhoto}
                sx={{
                  width: '52px',
                  height: '52px',
                  bgcolor: 'neutral550',
                  borderRadius: avatarIsCircle ? '50%' : '8px',
                  border:
                    contentPhoto == ''
                      ? ''
                      : avatarIsCircle
                      ? '1px solid #4E5156'
                      : '2px solid #FFFFFF',
                  '& .MuiAvatar-img ': {
                    zIndex: 1,
                  },
                  '&:before': {
                    content: "''",
                    background: `url(${
                      placeholderType == 'team'
                        ? IconSource('ic-default-team')
                        : IconSource('ic-default-photo')
                    }) center no-repeat`,
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    backgroundSize: 'cover',
                    zIndex: 0,
                  },
                  svg: {
                    display: 'none',
                  },
                }}
              />
              <Stack direction="column" marginLeft="12px" maxHeight="60px" justifyContent="center">
                <Text
                  variant="body"
                  sx={{
                    color: 'neutral150',
                    fontSize: '16px',
                    fontWeight: 300,
                    letterSpacing: '0.2px',
                  }}
                >
                  {contentTextLink ? (
                    <Link to={contentTextLink} style={{ color: 'inherit' }}>
                      <Stack direction="row" alignItems="top">
                        <ResponsiveEllipsis text={contentText} maxLine={2} ellipsis="..." />
                        <img src={IconSource('ic-chevron-right')} width={20} />
                      </Stack>
                    </Link>
                  ) : (
                    <ResponsiveEllipsis text={contentText} maxLine={2} ellipsis="..." />
                  )}
                </Text>
                {contentSubtext && (
                  <Text variant="subtitle" mt="4px" sx={{ color: 'neutral400' }}>
                    {contentSubtext}
                  </Text>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
