import { Box, Stack, Divider } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource, ImageSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

export interface FindMyMatchProps {
  label?: string | ReactNode;
  to?: string;
}

export const FindMyMatch = ({ label, to }: FindMyMatchProps) => {
  return (
    <Link to={to ?? ''}>
      <Box
        sx={theme => ({
          width: '100%',
          background: theme.palette.primaryGradient,
          p: '1px',
          borderRadius: theme.borderRadius.sm,
          boxShadow: '0px 4px 8px rgba(230, 53, 2, 0.45)',
        })}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          sx={theme => ({
            background: `${theme.palette.neutral600} url(${ImageSource('bg-dot-gray', 'png')})`,
            p: '8px',
            borderRadius: theme.borderRadius.sm,
          })}
        >
          <Stack direction="row" alignItems="center" sx={{ mr: '12px' }}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={theme => ({
                width: '40px',
                height: '40px',
                background: theme.palette.grayGradient3,
                borderRadius: '50%',
              })}
            >
              <SVG src={IconSource('ic-big-team-active')} />
            </Stack>
            <Text
              variant="subtitle"
              component="div"
              sx={{ flex: 'min-content', color: 'neutral150', ml: '8px' }}
            >
              {label}
            </Text>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderStyle: 'dashed', m: 0, mr: '6px' }}
            />
            <SVG src={IconSource('ic-caret-forward-small-white')} />
          </Stack>
        </Stack>
      </Box>
    </Link>
  );
};
