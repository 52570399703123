import BackgroundImage from 'components/layouts/BackgroundImage';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC } from 'react';
import TeamCard, { TeamCardType } from './TeamCard';

export interface HeaderMatchTantanganType {
  teamCard?: TeamCardType | undefined;
  headerBackground?: string | undefined;
  matchChallengeText?: string;
}

const HeaderMatchTantangan: FC<HeaderMatchTantanganType> = ({
  teamCard,
  headerBackground,
  matchChallengeText,
}) => {
  return (
    <Box width="100%">
      <BackgroundImage
        src={headerBackground}
        backgroundColor="#0b0b0ee6"
        mask="linear-gradient(transparent, #00000042, #2e292988, #2e292911, transparent)"
      >
        <Box
          height="6px"
          width="80%"
          borderRadius="0px 0px 10px 10px"
          shadow="0px 2px 70px 6px #D51F40"
          background="linear-gradient(122.67deg, #D51F40 38.55%, #E24134 50.61%, #E8542E 59.52%, #F37024 66.6%)"
        />
        <Box width="100%" padding="16px 0px">
          <Stack direction="column" align="center">
            <NormalText
              textAlign="center"
              color="#ffffff"
              padding="8px"
              fontSize="22px"
              fontWeight="bold"
            >
              {matchChallengeText ?? 'Tantangan Scrim Dari'}
            </NormalText>
            <Box padding="8px">
              <TeamCard {...{ ...teamCard, variant: 'stretch' }} />
            </Box>
          </Stack>
        </Box>
      </BackgroundImage>
    </Box>
  );
};

export default HeaderMatchTantangan;
