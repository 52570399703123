import GradientButton from 'components/Buttons/GradientButton';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import useWindowDimensions from 'modules/dimention';
import history from 'modules/history';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { resetTeamDraftAction } from 'redux/team/TeamDraftReducer';

export interface TeamEmptyPageProps {
  noMarginTop?: boolean;
  urlTo?: string;
}

const TeamEmptyPage = ({ noMarginTop, urlTo }: TeamEmptyPageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { height: deviceHeight } = useWindowDimensions();
  return (
    <div
      style={{
        display: 'grid',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: noMarginTop ? 0 : `${deviceHeight * 0.28}px`,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <img
          style={{ backgroundRepeat: 'no-repeat' }}
          src={IconSource('mabar-typeface-fullwhite')}
          alt="mabar-typeface-fullwhite"
        />
      </div>
      <div style={{ marginTop: '8%', marginLeft: '0%', marginRight: '0%', marginBottom: '12px' }}>
        <NormalText
          color="#969393"
          textAlign="center"
          fontStyle="normal"
          fontWeight="400"
          fontSize="14px"
        >
          {t('player_explore_scrim_empty_state_team')}
        </NormalText>
      </div>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          marginLeft: '10px',
          marginTop: '10px',
        }}
      >
        <GradientButton
          onClick={() => {
            dispatch(resetTeamDraftAction());
            if (urlTo) history.push(urlTo);
          }}
          variant="primary"
          padding="12.5px 49.376px 12.5px 49.3746px"
          backgroundSize="100%"
        >
          {t('player_my_team_button_add_team')}
        </GradientButton>
      </div>
    </div>
  );
};

export default TeamEmptyPage;
