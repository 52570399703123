import { Theme } from '@mui/material';
import { styled, Box } from '@mui/system';

export interface ScrollContainerProps {
  theme?: Theme;
  isScrollable?: boolean;
}

export const ScrollContainer = styled(Box)(({ theme, isScrollable }: ScrollContainerProps) => ({
  height: 'inherit',
  mb: '16px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme?.palette.neutral500} #26211E`,
  boxShadow: isScrollable ? `inset 0px -38px 20px -20px ${theme?.palette.neutral700}` : '',
  '&::placeholder': {
    color: theme?.palette.neutral500,
  },
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#26211E',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme?.palette.neutral500,
    borderRadius: '10px',
  },
}));
export const Wrapper = styled(Box)``;
