import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: inline;
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  justify-content: space-between;
`;
