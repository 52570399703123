import { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';
import AppLayout from 'components/layouts/AppLayout';
import { Avatar } from 'components/styled/avatar.styled';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { TextButton } from 'design/Buttons/TextButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { OptionType } from 'design/Forms/Select';
import { SelectInput } from 'design/Forms/Select';
import { TextInput } from 'design/Forms/TextInput';
import { UploadImage } from 'design/Forms/UploadImage';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useState, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UpdateCompleteDataProps } from 'redux/account/AccountProps';
import { CompleteDataValidationProblemDetails } from 'redux/account/AccountResponse';
import {
  fetchPlayerProfile,
  fetchGameAvailableList,
  updateCompleteData,
} from 'redux/account/AccountServices';
import { useAppSelector } from 'redux/hooks';
import { fetchCityList, fetchProvinceList } from 'redux/region/RegionServices';
import { fetchSchoolList } from 'redux/school/SchoolServices';
import { useAppThunkDispatch } from 'redux/store';

const StyledUlList = styled('ul')(({ theme }: any) => ({
  paddingLeft: '24px',
  margin: '10px 0',
  color: theme.palette.neutral400,
}));

const AccountCompleteData = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, getValues, resetField } = useForm();
  const watchFields = useWatch({ control: control });
  const dispatch = useAppThunkDispatch();

  const isFormComplete =
    watchFields['EmailOrPhoneNumber'] &&
    watchFields['FullName'] &&
    watchFields['ProvinceCode'] &&
    watchFields['CityCode'] &&
    watchFields['SchoolId'] &&
    watchFields['Generation'] &&
    watchFields['StudentIdNumber'] &&
    watchFields['StudentCardFile'] &&
    watchFields['GameId'] &&
    watchFields['GameProviderId'] &&
    watchFields['NickName'] &&
    watchFields['NickNameFile'];

  const { playerInfo, provinceList, cityList, schoolList, errors, gameDataList } = useAppSelector(
    ({ account, region, school }) => ({
      playerInfo: account.data,
      provinceList: region.provinceList,
      cityList: region.cityList,
      schoolList: school.schoolList,
      errors: account.errors,
      gameDataList: account.gameAvailable,
    }),
  );

  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  const [getGameList, setGameList] = useState<OptionType[]>();
  const [getProvinceOptList, setProvinceOptList] = useState<OptionType[]>();
  const [getCityOptList, setCityOptList] = useState<OptionType[]>();
  const [getSchoolOptList, setSchoolOptList] = useState<OptionType[]>();
  const [getValidationErrors, setValidationErrors] = useState<CompleteDataValidationProblemDetails>(
    errors as CompleteDataValidationProblemDetails,
  );
  let isError = getValidationErrors && getValidationErrors?.errors;
  const [schoolSelected, setSchoolSelected] = useState('');
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [showModalLoading, setShowModalLoading] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState('');
  const [validatePhoneOrEmail, setValidatePhoneOrEmail] = useState('');

  const fetchAccountProfile = (username: string) => {
    dispatch(fetchPlayerProfile({ username: username }));
  };

  const fetchGameList = (username: string) => {
    dispatch(fetchGameAvailableList({ playerSlug: username, pageIndex: 1, pageSize: 20 }));
  };

  const getProvinceList = (pageIndex: number, pageSize: number, search?: string) => {
    dispatch(fetchProvinceList({ pageIndex: pageIndex, pageSize: pageSize, search: search }));
  };

  const getCityList = (
    provinceCode: string,
    pageIndex: number,
    pageSize: number,
    search?: string,
  ) => {
    dispatch(
      fetchCityList({
        provinceCode: provinceCode,
        pageIndex: pageIndex,
        pageSize: pageSize,
        search: search,
      }),
    );
  };

  const getSchoolList = (
    cityCode: string,
    pageIndex: number,
    pageSize: number,
    search?: string,
  ) => {
    dispatch(
      fetchSchoolList({
        cityCode: cityCode,
        pageIndex: pageIndex,
        pageSize: pageSize,
        search: search,
      }),
    );
  };

  const getGenerationYearList = (): { name: string; value: any }[] => {
    const minYear = 2018;
    const maxYear = new Date();
    const temp: { name: string; value: any }[] = [];
    for (let year = minYear; year <= maxYear.getFullYear(); year++) {
      temp.push({ name: year.toString(), value: year });
    }
    return temp;
  };

  const handleValidatePhoneOrEmail = (data: any) => {
    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (playerInfo?.phoneNumber) {
      if (!emailRegex.test(data)) {
        setModalErrorMsg('Format email salah');
        setValidatePhoneOrEmail('Format email salah');
        setOpenModalError(true);

        return;
      }
    }

    if (playerInfo?.email) {
      let prefixFormat = '08';
      let prefix = data.slice(0, 2);

      if (prefix != prefixFormat) {
        setModalErrorMsg('Format No HP salah');
        setValidatePhoneOrEmail('Format No HP salah');
        setOpenModalError(true);

        return;
      }
    }

    setOpenModalConfirmation(true);
  };

  useEffect(() => {
    if (playerInfo && playerInfo.school) {
      history.push('/account');
    }

    if (playerInfo) {
      fetchGameList(playerInfo?.userName);
    }

    getProvinceList(1, 100);
  }, []);

  useEffect(() => {
    if (provinceList) {
      setProvinceOptList(
        provinceList.items.map(prov => {
          return { name: prov.name, value: prov.code } as OptionType;
        }),
      );
    }
  }, [provinceList]);

  useEffect(() => {
    if (cityList) {
      setCityOptList(
        cityList.items.map(city => {
          return { name: city.name, value: city.code } as OptionType;
        }),
      );
    }
  }, [cityList]);

  useEffect(() => {
    if (schoolList) {
      setSchoolOptList(
        schoolList.items.map(sch => {
          return { name: sch.name, value: sch.id } as OptionType;
        }),
      );
    }
  }, [schoolList]);

  useEffect(() => {
    if (gameDataList) {
      setGameList(
        gameDataList.items.map(game => {
          return { name: game.name, value: game.id } as OptionType;
        }),
      );
    }
  }, [gameDataList]);

  const saveCompleteData = (data: any) => {
    let formData = data as UpdateCompleteDataProps;
    setConfirmButtonDisabled(true);
    if (playerInfo) {
      formData.id = playerInfo.id;
      formData.name = data.FullName;
      formData.emailOrPhoneNumber = data.EmailOrPhoneNumber;
      formData.provinceCode = data.ProvinceCode;
      formData.cityCode = data.CityCode;
      formData.schoolId = data.SchoolId;
      formData.generation = data.Generation;
      formData.studentIdNumber = data.StudentIdNumber;
      formData.studentCardFile = data.StudentCardFile;
      formData.gameId = data.GameId;
      formData.gameProviderId = data.GameProviderId;
      formData.nickName = data.NickName;
      formData.nickNameFile = data.NickNameFile;

      setOpenModalConfirmation(false);
      setShowModalLoading(true);
      const updateCompleteDataThunk = updateCompleteData(formData);
      dispatch(updateCompleteDataThunk)
        .unwrap()
        .then(res => {
          if (res && res.isAxiosError && res.data?.status >= 400) {
            setShowModalLoading(false);
            setValidationErrors(res.data);
            if (res.data.status == 1602) {
              setModalErrorMsg('Alamat email telah digunakan');
            } else if (res.data.status == 1603) {
              setModalErrorMsg('No handphone telah digunakan');
            } else if (res?.data?.errors['game.game_NickName']) {
              setModalErrorMsg('Jumlah karakter maksimal untuk Nickname Game adalah 32');
            } else {
              setModalErrorMsg(res.data.title);
            }

            setOpenModalError(true);
          } else {
            fetchAccountProfile(playerInfo.userName);
            setShowModalLoading(false);
            history.push('/');
          }
          setConfirmButtonDisabled(false);
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    }
  };

  const handleRequestSchool = (e: any) => {
    e.preventDefault();
    window.open('https://wa.me/628119455671?text=Halo MABAR! Aku tertarik untuk buat klub esports di sekolah. Apa boleh diberi panduannya?', '_blank');
  };

  return (
    <AppLayout
      appBar={{
        bg: {
          background: '#1A1817',
        },
        navleft: {
          width: '0',
          height: '0',
        },
        navright: {
          variant: 'empty',
          width: '0',
          height: '0',
          isDisable: true,
        },
        title: (
          <Text variant="h3" sx={{ fontWeight: 700, letterSpacing: '0.12px' }}>
            Lengkapi Data Kamu
          </Text>
        ),
      }}
      background={`url(${IconSource('ic-bg-vector')}) no-repeat 100% center / contain`}
      backgroundMask="linear-gradient(359.1deg, #131318 0.29%, rgba(19, 19, 24, 0.97299) 32.28%, rgba(19, 19, 24, 0.934402) 43.9%, rgba(19, 19, 24, 0.89284) 56.38%, rgba(19, 19, 24, 0.02) 80.4%)"
      showModal={showModalLoading}
      modalStyled={{ background: 'transparent' }}
      modalContent={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar src={IconSource('ic-loader')} />
        </div>
      }
    >
      <form noValidate autoComplete="off" autoCapitalize="off">
        <Box
          sx={{
            p: '24px 0 15px',
          }}
        >
          <Text
            sx={(theme: any) => ({
              fontFamily: theme.fontFamily.lexendDeca,
              fontWeight: 600,
              mb: '16px',
            })}
          >
            {t('player_register_personal_data_title')}
          </Text>
          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={3}>
              <TextInput
                id="inputeverified"
                label={playerInfo?.email ? 'Email' : 'No HP'}
                defaultValue={playerInfo?.email ? playerInfo?.email : playerInfo?.phoneNumber}
                disabled
              />
              <Box>
                <Controller
                  control={control}
                  name="EmailOrPhoneNumber"
                  render={({ field: { onChange, ref } }) => (
                    <TextInput
                      id="inputnphoneoremail"
                      label={playerInfo?.email ? 'No HP' : 'Email'}
                      type={playerInfo?.email ? 'number' : 'text'}
                      onChange={onChange}
                      inputRef={ref}
                    />
                  )}
                />
                {isError && getValidationErrors.errors?.emailOrPhoneNumber && (
                  <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                    {getValidationErrors.errors?.emailOrPhoneNumber[0].message}
                  </Text>
                )}
                {validatePhoneOrEmail && (
                  <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                    {validatePhoneOrEmail}
                  </Text>
                )}
                {!playerInfo?.phoneNumber && (
                  <Text variant="subtitle" sx={{ color: 'neutral400', mt: '4px' }}>
                  {t('player_register_personal_data_phone_info')}
                </Text>
                )}
              </Box>
            </Stack>
            <Box>
              <Controller
                control={control}
                name="FullName"
                defaultValue={`${playerInfo?.firstName} ${playerInfo?.lastName}`}
                render={({ field: { onChange, ref } }) => (
                  <TextInput
                    id="inputname"
                    label="Nama Lengkap"
                    defaultValue={`${playerInfo?.firstName} ${playerInfo?.lastName}`}
                    onChange={onChange}
                    inputRef={ref}
                  />
                )}
              />
              {isError && getValidationErrors.errors?.name ? (
                <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                  {getValidationErrors.errors?.name[0].message}
                </Text>
              ) : (
                ''
              )}
              <Text variant="subtitle" sx={{ color: 'neutral400', mt: '4px' }}>
                {t('player_register_personal_data_name_info')}
              </Text>
            </Box>
            <Controller
              control={control}
              name="ProvinceCode"
              render={({ field: { onChange, ref } }) => (
                <SelectInput
                  inputRef={ref}
                  label={t('player_account_edit_profile_province')}
                  options={getProvinceOptList}
                  validationText={
                    isError && getValidationErrors.errors?.provinceCode
                      ? getValidationErrors.errors?.provinceCode[0].message
                      : ''
                  }
                  onItemChange={(e: any) => {
                    onChange(e);
                    getCityList(e, 1, 500);
                    resetField('CityCode');
                    resetField('SchoolId');
                    setSchoolOptList([{ name: '', value: '' }]);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="CityCode"
              render={({ field: { onChange, ref } }) => (
                <SelectInput
                  inputRef={ref}
                  label={t('player_account_edit_profile_city')}
                  options={getCityOptList}
                  validationText={
                    isError && getValidationErrors.errors?.cityCode
                      ? getValidationErrors.errors?.cityCode[0].message
                      : ''
                  }
                  disabled={!Boolean(getValues('ProvinceCode'))}
                  onItemChange={(e: any) => {
                    onChange(e);
                    getSchoolList(e, 1, 500);
                    resetField('SchoolId');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="SchoolId"
              render={({ field: { onChange, ref } }) => (
                <SelectInput
                  inputRef={ref}
                  label={t('player_account_edit_profile_school')}
                  options={getSchoolOptList}
                  searchable
                  disabled={!Boolean(getValues('ProvinceCode')) || !Boolean(getValues('CityCode'))}
                  validationText={
                    isError && getValidationErrors.errors?.schoolId
                      ? getValidationErrors.errors?.schoolId[0].message
                      : ''
                  }
                  onItemChange={(e: any) => {
                    onChange(e);
                    setSchoolSelected(getSchoolOptList?.find(v => v.value === e)?.name ?? '');
                  }}
                  cta={
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Text
                        variant="bodySmall"
                        sx={(theme: any) => ({
                          color: 'neutral400',
                          fontWeight: 400,
                          fontFamily: theme.fontFamily.lexendDeca,
                        })}
                      >
                        Sekolah tidak ditemukan?
                      </Text>

                      <TextButton
                        variant="bodySmall"
                        gradient
                        onClick={(e: any) => handleRequestSchool(e)}
                        textsx={(theme: any) => ({
                          fontWeight: 600,
                          fontFamily: theme.fontFamily.lexendDeca,
                        })}
                      >
                        Ajukan Sekolah via WA
                      </TextButton>
                    </Stack>
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="Generation"
              render={({ field: { onChange, ref } }) => (
                <SelectInput
                  inputRef={ref}
                  label="Tahun Masuk Sekolah"
                  placeholder="Silahkan pilih"
                  options={getGenerationYearList()}
                  validationText={
                    isError && getValidationErrors.errors?.generation
                      ? getValidationErrors.errors?.generation[0].message
                      : undefined
                  }
                  onItemChange={(e: any) => {
                    onChange(e);
                  }}
                />
              )}
            />
            <Box>
              <Controller
                control={control}
                name="StudentIdNumber"
                render={({ field: { onChange, ref } }) => (
                  <TextInput
                    id="inputnis"
                    label="Nomor Induk Siswa Nasional / NISN"
                    type="number"
                    onChange={onChange}
                    inputRef={ref}
                  />
                )}
              />
              {isError && getValidationErrors.errors?.studentIdNumber ? (
                <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                  {getValidationErrors.errors?.studentIdNumber[0].message}
                </Text>
              ) : (
                ''
              )}
              <Text variant="subtitle" sx={{ color: 'neutral400', mt: '4px' }}>
                Contoh: 9876543210
              </Text>
            </Box>
            <Controller
              control={control}
              name="StudentCardFile"
              render={({ field: { onChange, ref } }) => (
                <UploadImage
                  inputRef={ref}
                  getImageBlob={v => onChange(v)}
                  onImageLoad={function noRefCheck() {}}
                />
              )}
            />
            {isError && getValidationErrors.errors?.studentCardFile ? (
              <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                {getValidationErrors.errors?.studentCardFile[0].message}
              </Text>
            ) : (
              ''
            )}
            <Box>
              <Text
                sx={(theme: any) => ({
                  fontFamily: theme.fontFamily.lexendDeca,
                  fontWeight: 600,
                  mb: '16px',
                })}
              >
                {t('player_register_personal_data_game_title')}
              </Text>
              <Controller
                control={control}
                name="GameId"
                defaultValue={getGameList ? getGameList[0]?.value : ''}
                render={({ field: { onChange, ref } }) => (
                  <SelectInput
                    label="Pilih Game"
                    options={getGameList}
                    defaultValue={getGameList ? getGameList[0]?.value : ''}
                    validationText={
                      isError && getValidationErrors.errors?.gameId
                        ? getValidationErrors.errors?.gameId[0].message
                        : undefined
                    }
                    inputRef={ref}
                    onItemChange={(e: any) => {
                      onChange(e);
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name="GameProviderId"
              render={({ field: { onChange, ref } }) => (
                <TextInput
                  id="inputgameid"
                  type="number"
                  label="Game ID"
                  onChange={onChange}
                  inputRef={ref}
                />
              )}
            />
            {isError && getValidationErrors.errors?.gameProviderId ? (
              <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                {getValidationErrors.errors?.gameProviderId[0].message}
              </Text>
            ) : (
              ''
            )}
            <Controller
              control={control}
              name="NickName"
              render={({ field: { onChange, ref } }) => (
                <TextInput
                  id="inputnicknamegame"
                  label="Nickname Game"
                  onChange={onChange}
                  inputRef={ref}
                />
              )}
            />
            {isError && getValidationErrors.errors?.nickName ? (
              <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                {getValidationErrors.errors?.nickName[0].message}
              </Text>
            ) : (
              ''
            )}
            <Controller
              control={control}
              name="NickNameFile"
              render={({ field: { onChange, ref } }) => (
                <UploadImage
                  inputRef={ref}
                  title="Upload Screenshot Profil Game ID Kamu"
                  getImageBlob={v => onChange(v)}
                  onImageLoad={function noRefCheck() {}}
                />
              )}
            />
            {isError && getValidationErrors.errors?.nickNameFile ? (
              <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                {getValidationErrors.errors?.nickNameFile[0].message}
              </Text>
            ) : (
              ''
            )}

            <PrimaryButton
              variant="big"
              wide
              boxShadow
              onClick={e => {
                e.preventDefault();
                handleValidatePhoneOrEmail(watchFields['EmailOrPhoneNumber']);
              }}
              disabled={Boolean(isFormComplete) ? false : true}
            >
              Submit
            </PrimaryButton>
          </Stack>
        </Box>
      </form>

      <Modal show={openModalConfirmation}>
        <InfoModal
          cta={
            <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
              <SecondaryButton sx={{ mr: '16px' }} onClick={() => setOpenModalConfirmation(false)}>
                Periksa lagi
              </SecondaryButton>
              <PrimaryButton
                onClick={handleSubmit(saveCompleteData)}
                disabled={confirmButtonDisabled}
              >
                Ya, Sudah Benar
              </PrimaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%', textAlign: 'left' }}>
            <Text variant="h2" sx={{ mb: '8px' }}>
              Konfirmasi Identitas
            </Text>
            <Text sx={theme => ({ color: theme.palette.neutral400 })}>
              Pastikan informasi di bawah ini sudah sesuai sebelum menyelesaikan proses registrasi.
              Data ini akan digunakan untuk pembuatan sertifikat kamu.
            </Text>
            <StyledUlList>
              <li>
                <Text sx={theme => ({ color: theme.palette.neutral400 })}>
                  Nama Lengkap : {watchFields['FullName']}
                </Text>
              </li>
              <li>
                <Text sx={theme => ({ color: theme.palette.neutral400 })}>
                  Sekolah : {schoolSelected}
                </Text>
              </li>
            </StyledUlList>
          </Box>
        </InfoModal>
      </Modal>

      <Modal show={openModalError}>
        <InfoModal
          cta={<PrimaryButton onClick={() => setOpenModalError(false)}>Kembali</PrimaryButton>}
        >
          <Text textAlign="center">{modalErrorMsg}</Text>
        </InfoModal>
      </Modal>
    </AppLayout>
  );
};

export default AccountCompleteData;
