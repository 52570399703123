import { ModalActionTypes } from '../index';
import { Box, Stack } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { TextInput } from 'design/Forms/TextInput';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import he from 'he';
import { ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { memo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { exitFromTeam } from 'redux/team/TeamMemberReducer';
import { deleteMyTeam, fetchTeamMember } from 'redux/teamProfile/TeamProfileService';
import AuthService from 'services/AuthService';

interface MenuHeaderModalProps {
  openModalConfirmation: boolean;
  setModalActionStatus: (value: ModalActionTypes['status']) => void;
  modalActionStatus: string;
  setOpenModalConfirmation: (value: boolean) => void;
  paramTeamSlug: string;
}

const MenuHeaderModal = ({
  openModalConfirmation,
  setModalActionStatus,
  modalActionStatus,
  setOpenModalConfirmation,
  paramTeamSlug,
}: MenuHeaderModalProps) => {
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const dispatch = useAppThunkDispatch();

  const { playerInfo, teamDetail, activeTournament } = useAppSelector(
    ({ account, teamProfile, tournament }) => ({
      playerInfo: account?.data,
      teamDetail: teamProfile?.teamDetail,
      activeTournament: tournament.activeTournamentList?.tournaments
        ? tournament.activeTournamentList?.tournaments.map(arr => arr.name)
        : [],
    }),
  );

  const [contentPrintListTournament, setContentPrintListTournament] = useState('');

  const myRole = isAuthenticated
    ? playerInfo?.games?.find(game => game?.team?.urlSlug === paramTeamSlug)
    : undefined;
  const isCaptain = myRole?.team?.level?.value === 'Captain';

  useEffect(() => {
    if (activeTournament.length == 1) {
      setContentPrintListTournament('<b>' + activeTournament[0] + '</b>');
    } else if (activeTournament.length == 2) {
      setContentPrintListTournament('<b>' + activeTournament.join('</b> dan <b>') + '</b>');
    } else if (activeTournament.length >= 3) {
      setContentPrintListTournament(
        '<b>' +
          activeTournament.slice(0, -1).join('</b>, <b>') +
          '</b> dan <b>' +
          activeTournament.slice(-1) +
          '</b>',
      );
    } else {
      setContentPrintListTournament('');
    }
  }, [activeTournament]);

  const handleFetchTeamMember = (teamSlug: string) => {
    dispatch(
      fetchTeamMember({
        approved: false,
        teamSlug: teamSlug,
        pageIndex: 1,
        pageSize: 20,
        version: '',
      }),
    );
  };

  const handleConfirmDeleteTeam = (inputTeamName: string) => {
    if (teamDetail && teamDetail?.id) {
      if (inputTeamName === teamDetail?.name) {
        dispatch(deleteMyTeam({ teamId: teamDetail?.id })).then((res: any) => {
          if (res) {
            if (res?.payload?.data?.errCode == 1801) {
              setOpenModalConfirmation(false);
              setModalActionStatus('delete-team-failed-match');
              setOpenModalConfirmation(true);
            } else if (res?.payload?.data?.errCode == 1802) {
              setOpenModalConfirmation(false);
              setModalActionStatus('state-internal-server-error');
              setOpenModalConfirmation(true);
            } else {
              setOpenModalConfirmation(false);
              handleFetchTeamMember(paramTeamSlug);
              history.push('/my-team');
            }
          }
        });
      } else {
        console.log('Nama tim tidak sama');
      }
    }
  };

  const handleCancelDeleteTeam = () => {
    setOpenModalConfirmation(false);
  };

  const handleCancelLeaveTeam = () => {
    setOpenModalConfirmation(false);
  };

  const handleConfirmOutTeam = () => {
    const thunk = exitFromTeam({
      teamId: teamDetail?.id,
      playerId: playerInfo?.id,
    });
    dispatch(thunk)
      .unwrap()
      .then(res => {
        setOpenModalConfirmation(false);
        var skipRefresh = false;
        if ((res && res?.isAxiosError) || res?.data || res?.data?.status != 204) {
          if (res?.data?.detail != undefined) {
            if (res?.data?.status == 1300) {
              setModalActionStatus('delete-team-failed-match');
              setOpenModalConfirmation(true);
              skipRefresh = true;
            }
          }
        }

        if (!skipRefresh) {
          return history.push('/my-team');
        }
      });
  };

  const handleApifailed = () => {
    setOpenModalConfirmation(false);
  };

  const MenuAction = useCallback(() => {
    const [inputTeamName, setInputTeamName] = useState('');
    const { t } = useTranslation();

    switch (modalActionStatus) {
      case 'confirm-leave-team':
        return (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <SecondaryButton sx={{ mr: '16px' }} onClick={handleCancelLeaveTeam}>
                  Batal
                </SecondaryButton>
                <PrimaryButton onClick={handleConfirmOutTeam}>
                  {t('player_team_profile_member_confirm_resign')}
                </PrimaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%' }}>
              <Text variant="h2" mb="8px">
                {isCaptain
                  ? t('player_team_profile_captain_disband_team')
                  : t('player_team_profile_member_resign_team')}
              </Text>
              <Text
                dangerouslySetInnerHTML={{
                  __html: he.decode(
                    t('player_team_profile_leave_popup_message', {
                      teamName: teamDetail?.name ?? '',
                    }),
                  ),
                }}
              ></Text>
            </Box>
          </InfoModal>
        );

      case 'confirm-delete-team':
        return (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <SecondaryButton sx={{ mr: '16px' }} onClick={handleCancelDeleteTeam}>
                  Batal
                </SecondaryButton>
                <PrimaryButton onClick={() => handleConfirmDeleteTeam(inputTeamName)}>
                  {t('player_team_profile_remove_popup_confirmation_button')}
                </PrimaryButton>
              </Stack>
            }
          >
            <Box>
              <Text variant="h2" textAlign="left" mb="8px">
                {t('player_team_profile_captain_disband_team')}
              </Text>
              <>
                <Text>{t('player_team_profile_delete_team')}</Text>
                <TextInput
                  id="inputteamname"
                  label="Nama Tim"
                  onChange={(e: { target: { value: React.SetStateAction<string> } }) => {
                    setInputTeamName(e.target.value);
                  }}
                  placeholder="Masukan nama tim kamu"
                  sx={{ mt: '16px' }}
                />
                {inputTeamName !== '' && teamDetail?.name !== inputTeamName && (
                  <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                    Nama tim tidak sesuai
                  </Text>
                )}
              </>
            </Box>
          </InfoModal>
        );

      case 'delete-team-failed-member':
        return (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <PrimaryButton onClick={handleCancelDeleteTeam}>Tutup</PrimaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%' }}>
              <Text variant="h2" mb="8px">
                Permintaan Gagal
              </Text>
              <Text>Pembubaran team tidak diizinkan apabila masih ada anggota di dalamnya.</Text>
            </Box>
          </InfoModal>
        );

      case 'state-internal-server-error':
        return (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <PrimaryButton onClick={handleCancelDeleteTeam}>Tutup</PrimaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%' }}>
              <Text variant="h2" mb="8px">
                {t('something_wrong')}
              </Text>
              <Text>{t('something_wrong_message')}</Text>
            </Box>
          </InfoModal>
        );

      case 'delete-team-failed-match':
        return (
          <InfoModal
            icon={ImageSource('Warning', 'png')}
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <SecondaryButton onClick={handleCancelDeleteTeam} wide>
                  Oke
                </SecondaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%' }}>
              <Text variant="h2" mb="8px" textAlign="center">
                Permintaan Gagal
              </Text>
              <Text textAlign="center">
                Pembubaran team dan keluar dari team tidak diizinkan di saat tim memiliki jadwal
                pertandingan.
              </Text>
            </Box>
          </InfoModal>
        );

      case 'confirm-leave-team-have-active-tournament':
        return (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <SecondaryButton sx={{ mr: '16px' }} onClick={handleCancelLeaveTeam}>
                  Batal
                </SecondaryButton>
                <PrimaryButton onClick={handleConfirmOutTeam}>
                  {t('player_team_profile_member_confirm_resign')}
                </PrimaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%' }}>
              <Text variant="h2" mb="8px">
                {t('player_team_profile_member_resign_team')}
              </Text>
              <Text
                sx={theme => ({
                  color: theme.palette.neutral400,
                  b: { color: theme.palette.neutral300 },
                })}
                dangerouslySetInnerHTML={{
                  __html: he.decode(
                    t('player_team_profile_leave_have_tournament_popup_message', {
                      tournamentName: contentPrintListTournament,
                    }),
                  ),
                }}
              ></Text>
            </Box>
          </InfoModal>
        );

      //api-failed
      default:
        return (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <SecondaryButton sx={{ mr: '16px' }} onClick={handleApifailed}>
                  Batal
                </SecondaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%' }}>
              <Text variant="h2" mb="8px" textAlign="center">
                Permintaan Gagal
              </Text>
              <Text textAlign="center">
                Telah terjadi kesalahan, mohon dicoba beberapa saat lagi.
              </Text>
            </Box>
          </InfoModal>
        );
    }
  }, [modalActionStatus]);

  return (
    <Modal show={openModalConfirmation}>
      <MenuAction />
    </Modal>
  );
};

export default memo(MenuHeaderModal);
