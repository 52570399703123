import { Theme, Stack, Box, Avatar } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { FC } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export interface CardRankingListType {
  index: number;
  schoolLogo?: string;
  schoolName?: string;
  schoolLocation?: string;
  teamName?: string;
  variant?: 'orange' | 'light-gray' | 'dark-gray';
  rank?: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

const variantTextColor = (variant: any, theme: any) => {
  switch (variant) {
    case 'orange':
      return '#ffffff';
    case 'light-gray':
      return theme.palette.neutral300;
    case 'dark-gray':
      return 'transparent';
    default:
      return theme.palette.neutral550;
  }
};

const textOpacity = (rank: number) => {
  if (rank > 0 && rank <= 2) return '70%';
  if (rank > 2) return '20%';
  return '100%';
};

const variantBackground = (variant: any, theme: any) => {
  switch (variant) {
    case 'orange':
      return '#F2A221';
    case 'light-gray':
      return 'linear-gradient(98.52deg, #8C8C8C -24.48%, #4B4B4B 55.77%)';
    case 'dark-gray':
      return 'linear-gradient(98.52deg, rgba(140, 140, 140, 0.2) -24.48%, rgba(75, 75, 75, 0.2) 55.77%)';
    default:
      return theme.palette.neutral400;
  }
};

export interface GradientNumberBgType {
  theme: Theme;
  variant: CardRankingListType['variant'];
  index: CardRankingListType['index'];
}

const GradientNumberBg: any = styled(Box, { name: 'number-bg' })<GradientNumberBgType>(
  ({ theme, variant, index }) => ({
    position: 'absolute',
    left: '0px',
    top: '0px',
    background: variantBackground(variant, theme),
    backgroundBlendMode: 'overlay',
    margin: '0 auto',
    zIndex: 1,
    width: '70px',
    height: '60px',
    borderRadius: '2px',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '70px',
      height: '60px',
      background: `url(${IconSource('grunge', 'png')}) center/cover`,
      opacity: index > 2 ? '20%' : '100%',
      overflow: 'hidden',
    },
    '&::after': {
      display: 'block',
      width: '65px',
      height: '85px',
      transform: 'rotate(30deg) translate(70%, -25%);',
      background: theme.palette.neutral700,
      backgroundSize: 'cover',
      content: '" "',
    },
    '.numberText': {
      position: 'absolute',
      left: '0px',
      top: '-1px',
      fontFamily: theme.fontFamily.neusaNextStd,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '52px',
      lineHeight: '54px',
      letterSpacing: '-2px',
      color: variantTextColor(variant, theme),
      textShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
      '-webkit-text-stroke': variant === 'dark-gray' ? '1px #B4B7BB' : 'unset',
      opacity: textOpacity(index),
    },
  }),
);
export const CardRankingList: FC<CardRankingListType> = ({
  index,
  schoolLogo,
  schoolName,
  schoolLocation,
  teamName,
  variant,
  rank,
  sx,
  onClick,
}) => {
  return (
    <>
      <Box bgcolor="neutral700" borderRadius="2px" position="relative" height="60px" sx={sx} onClick={onClick}>
        <Stack direction="row" justifyContent="flex-end">
          <Stack
            direction="column"
            justifyContent="right"
            sx={{
              width: '100%',
              margin: '12px 12px 11px 54px',
              textAlign: 'right',
              zIndex: '3',
            }}
          >
            <Text variant="body" mb="2px">
              <ResponsiveEllipsis text={schoolName} maxLine={1} ellipsis="..." />
            </Text>
            <Text variant="subtitle" sx={{ color: 'neutral400', lineHeight: '15px' }}>
              <ResponsiveEllipsis
                text={schoolLocation ? teamName + ' • ' + schoolLocation : teamName}
                maxLine={1}
                ellipsis="..."
              />
            </Text>
          </Stack>
          <Avatar
            src={schoolLogo ?? IconSource('ic-person-white')}
            sx={{
              width: '59px',
              height: '59px',
              borderRadius: '0px 2px 2px 0px',
              bgcolor: '#fff',
              border: '1px solid #FFFFFF',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
            }}
          />
        </Stack>
        <GradientNumberBg index={index} variant={variant}>
          <Text variant="ribbon" className="numberText" sx={{ opacity: rank === '01' ? 1 : 0.7 }}>
            {rank}
          </Text>
        </GradientNumberBg>
      </Box>
    </>
  );
};
