import {
  TournamentDetailResponse,
  TournamentListResponse,
  MyTeamFromTournamentResponse,
  TournamentListMyTeamResponse,
  ActiveTournamentListByPlayerResponse,
  TournamentParticipantInvitedResponse,
  TournamentParticipantPlayerResponse,
  TournamentParticipantTeamResponse,
} from './TournamentProps';
import {
  fetchTournamentDetail,
  fetchMyTeamFromTournament,
  fetchTournamentList,
  fetchTournamentListMyTeam,
  fetchActiveTournamentListPlayer,
  fetchTournamentParticipantInvited,
  fetchTournamentParticipantPlayer,
  fetchTournamentParticipantTeam,
} from './TournamentServices';
import { createSlice } from '@reduxjs/toolkit';

export interface TournamentState {
  tournamentDetail: TournamentDetailResponse | undefined;
  myTeamFromTournament: MyTeamFromTournamentResponse | undefined;
  tournamentList: TournamentListResponse | undefined;
  tournamentListMyTeam: TournamentListMyTeamResponse | undefined;
  activeTournamentList: ActiveTournamentListByPlayerResponse | undefined;
  tournamentParticipantInvited: TournamentParticipantInvitedResponse | undefined;
  tournamentParticipantPlayer: TournamentParticipantPlayerResponse | undefined;
  tournamentParticipantTeam: TournamentParticipantTeamResponse | undefined;
  errors: {
    tournamentDetail: string | undefined;
    myTeamFromTournament: string | undefined;
    tournamentList: string | undefined;
    tournamentListMyTeam: string | undefined;
    activeTournamentList: string | undefined;
    tournamentParticipantInvited: string | undefined;
    tournamentParticipantPlayer: string | undefined;
    tournamentParticipantTeam: string | undefined;
  };
  status: {
    tournamentDetail: 'idle' | 'loading' | 'succeeded' | 'failed';
    myTeamFromTournament: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentList: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentListMyTeam: 'idle' | 'loading' | 'succeeded' | 'failed';
    activeTournamentList: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentParticipantInvited: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentParticipantPlayer: 'idle' | 'loading' | 'succeeded' | 'failed';
    tournamentParticipantTeam: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
}

const initialState: TournamentState = {
  tournamentDetail: undefined,
  myTeamFromTournament: undefined,
  tournamentList: undefined,
  tournamentListMyTeam: undefined,
  activeTournamentList: undefined,
  tournamentParticipantInvited: undefined,
  tournamentParticipantPlayer: undefined,
  tournamentParticipantTeam: undefined,
  errors: {
    tournamentDetail: '',
    myTeamFromTournament: '',
    tournamentList: '',
    tournamentListMyTeam: '',
    activeTournamentList: '',
    tournamentParticipantInvited: '',
    tournamentParticipantPlayer: '',
    tournamentParticipantTeam: '',
  },
  status: {
    tournamentDetail: 'idle',
    myTeamFromTournament: 'idle',
    tournamentList: 'idle',
    tournamentListMyTeam: 'idle',
    activeTournamentList: 'idle',
    tournamentParticipantInvited: 'idle',
    tournamentParticipantPlayer: 'idle',
    tournamentParticipantTeam: 'idle',
  },
};

const TournamentSlice = createSlice({
  name: 'tournament',
  initialState: initialState,
  reducers: {
    resetTournament: () => initialState,
    resetTournamentDetail: state => {
      state.tournamentDetail = initialState.tournamentDetail;
    },
    resetMyTeamFromTournament: state => {
      state.myTeamFromTournament = initialState.myTeamFromTournament;
    },
    resetTournamentList: state => {
      state.tournamentList = initialState.tournamentList;
    },
    resetTournamentListMyTeam: state => {
      state.tournamentListMyTeam = initialState.tournamentListMyTeam;
    },
    resetActiveTournamentList: state => {
      state.activeTournamentList = initialState.activeTournamentList;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTournamentDetail.pending, (state, _action) => {
        state.status.tournamentDetail = 'loading';
      })
      .addCase(fetchTournamentDetail.rejected, (state, action) => {
        state.status.tournamentDetail = 'failed';
        state.errors.tournamentDetail = action.error.message;
      })
      .addCase(fetchTournamentDetail.fulfilled, (state, action) => {
        state.status.tournamentDetail = 'succeeded';
        state.tournamentDetail = action.payload;
      })

      .addCase(fetchMyTeamFromTournament.pending, (state, _action) => {
        state.status.myTeamFromTournament = 'loading';
      })
      .addCase(fetchMyTeamFromTournament.rejected, (state, action) => {
        state.status.myTeamFromTournament = 'failed';
        state.errors.myTeamFromTournament = action.error.message;
      })
      .addCase(fetchMyTeamFromTournament.fulfilled, (state, action) => {
        state.status.myTeamFromTournament = 'succeeded';
        state.myTeamFromTournament = action.payload;
      })

      .addCase(fetchTournamentList.pending, (state, _action) => {
        state.status.tournamentList = 'loading';
      })
      .addCase(fetchTournamentList.rejected, (state, action) => {
        state.errors.tournamentList = action.error.message;
        state.status.tournamentList = 'failed';
      })
      .addCase(fetchTournamentList.fulfilled, (state, action) => {
        state.tournamentList = action.payload;
        state.status.tournamentList = 'succeeded';
      })

      .addCase(fetchTournamentListMyTeam.pending, (state, _action) => {
        state.status.tournamentListMyTeam = 'loading';
      })
      .addCase(fetchTournamentListMyTeam.rejected, (state, action) => {
        state.status.tournamentListMyTeam = 'failed';
        state.errors.tournamentListMyTeam = action.error.message;
      })
      .addCase(fetchTournamentListMyTeam.fulfilled, (state, action) => {
        state.status.tournamentListMyTeam = 'succeeded';
        state.tournamentListMyTeam = action.payload;
      })

      .addCase(fetchActiveTournamentListPlayer.pending, (state, _action) => {
        state.status.activeTournamentList = 'loading';
      })
      .addCase(fetchActiveTournamentListPlayer.rejected, (state, action) => {
        state.status.activeTournamentList = 'failed';
        state.errors.activeTournamentList = action.error.message;
      })
      .addCase(fetchActiveTournamentListPlayer.fulfilled, (state, action) => {
        state.status.activeTournamentList = 'succeeded';
        state.activeTournamentList = action.payload;
      })

      .addCase(fetchTournamentParticipantInvited.pending, (state, _action) => {
        state.status.tournamentParticipantInvited = 'loading';
      })
      .addCase(fetchTournamentParticipantInvited.rejected, (state, action) => {
        state.status.tournamentParticipantInvited = 'failed';
        state.errors.tournamentParticipantInvited = action.error.message;
      })
      .addCase(fetchTournamentParticipantInvited.fulfilled, (state, action) => {
        state.status.tournamentParticipantInvited = 'succeeded';
        state.tournamentParticipantInvited = action.payload;
      })

      .addCase(fetchTournamentParticipantPlayer.pending, (state, _action) => {
        state.status.tournamentParticipantPlayer = 'loading';
      })
      .addCase(fetchTournamentParticipantPlayer.rejected, (state, action) => {
        state.status.tournamentParticipantPlayer = 'failed';
        state.errors.tournamentParticipantPlayer = action.error.message;
      })
      .addCase(fetchTournamentParticipantPlayer.fulfilled, (state, action) => {
        state.status.tournamentParticipantPlayer = 'succeeded';
        state.tournamentParticipantPlayer = action.payload;
      })

      .addCase(fetchTournamentParticipantTeam.pending, (state, _action) => {
        state.status.tournamentParticipantTeam = 'loading';
      })
      .addCase(fetchTournamentParticipantTeam.rejected, (state, action) => {
        state.status.tournamentParticipantTeam = 'failed';
        state.errors.tournamentParticipantTeam = action.error.message;
      })
      .addCase(fetchTournamentParticipantTeam.fulfilled, (state, action) => {
        state.status.tournamentParticipantTeam = 'succeeded';
        state.tournamentParticipantTeam = action.payload;
      });
  },
});

export const {
  resetTournament,
  resetTournamentDetail,
  resetMyTeamFromTournament,
  resetTournamentList,
  resetTournamentListMyTeam,
  resetActiveTournamentList,
} = TournamentSlice.actions;

export default TournamentSlice.reducer;
