import { Box, Stack } from '@mui/material';
import { GameCard } from 'design/Cards/GameCard';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { memo } from 'react';
import { GameOptions } from 'redux/game/GameReducer';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { setGameSelectedAction } from 'redux/team/TeamDraftReducer';

const ListOfMyTeam = () => {
  const thunkDispatch = useAppThunkDispatch();
  const { playerInfo, myTeamData, myTeamGameId, teamWaitingId, gameListData, isGameListLoading } =
    useAppSelector(({ myTeam, game, account }) => ({
      playerInfo: account?.data,
      myTeamData: myTeam.data?.items,
      myTeamGameId:
        myTeam.data.count > 0
          ? myTeam.data?.items
              .filter(
                arr =>
                  !(arr.invitationType.key == '3' && arr.status?.key === '1') &&
                  !(arr.invitationType.key == '2' && arr.status?.key === '1'),
              )
              .map(arr => arr.game.id)
          : [],
      teamWaitingId:
        myTeam.data.count > 0
          ? myTeam.data?.items
              .filter(
                arr =>
                  (arr.invitationType.key == '2' || arr.invitationType.key == '3') &&
                  arr.status.key == '1',
              )
              .map(arr => arr.game.id)
          : [],
      gameListData: game.gameOption,
      isGameListLoading: game.loading,
    }));

  let saveGameSelected = React.useCallback(
    (gameSelected: GameOptions) => thunkDispatch(setGameSelectedAction({ game: gameSelected })),
    [thunkDispatch],
  );

  let handleGameSelected = (e: GameOptions) => {
    saveGameSelected(e);
    history.push('/create-team/team-list');
  };

  return (
    <Box padding="24px 16px">
      {myTeamGameId.length >= 1 && (
        <>
          <Text variant="h3" mb="16px">
            Tim Saya
          </Text>
          <Stack alignItems="center" mb="8px">
            {myTeamData
              .filter(data => data?.status?.key === '2')
              .map((data, index) => {
                return (
                  <>
                    <GameCard
                      key={index}
                      gameBg={data?.game?.avatarUrl}
                      gameName={data?.game?.name}
                      teamLogo={data?.avatarUrl}
                      teamName={data?.name ?? ''}
                      onClick={() => {
                        history.push(
                          `/team/${data?.game?.urlSlug}/${playerInfo?.school?.urlSlug}/${data?.urlSlug}`,
                        );
                      }}
                      bgOverlay={false}
                      avatarBorder={true}
                    />
                  </>
                );
              })}
          </Stack>
        </>
      )}

      {gameListData && (
        <>
          {isGameListLoading &&
          gameListData?.items.length <= 1 &&
          gameListData?.items[0].id == '' ? (
            <></>
          ) : (
            <>
              {myTeamGameId.length <= 0 && (
                <Text variant="h3" mb="16px">
                  Belum Ada Team
                </Text>
              )}
              <Stack alignItems="center" mb="8px">
                {gameListData !== undefined &&
                  gameListData?.items
                    .filter(v => v.urlSlug === 'mobile-legend' || v.urlSlug === 'mobile-legends')
                    .map((data, index) => {
                      if (myTeamGameId.includes(data?.id)) {
                        return <></>;
                      } else if (teamWaitingId.includes(data?.id)) {
                        return (
                          <>
                            <GameCard
                              key={index}
                              gameLogo={data?.iconUrl ?? IconSource('ic-default-team')}
                              gameBg={data?.avatarUrl}
                              gameName={data?.name}
                              isWaiting={true}
                              onClick={() => {
                                handleGameSelected(data);
                                // history.push('/create-team/choose-game');
                              }}
                              bgOverlay={false}
                            />
                          </>
                        );
                      } else {
                        return (
                          <>
                            <GameCard
                              key={index}
                              gameLogo={data?.iconUrl ?? IconSource('ic-default-team')}
                              gameBg={data?.avatarUrl}
                              gameName={data?.name}
                              onClick={() => {
                                handleGameSelected(data);
                                // history.push('/create-team/choose-game');
                              }}
                              bgOverlay={false}
                            />
                          </>
                        );
                      }
                    })}
              </Stack>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default memo(ListOfMyTeam);
