import React from 'react';

import { Container, Box, Link } from '@mui/material';
import { loginAction, registerAction } from 'redux/auth/AuthReducer';
import { useAppDispatch } from 'redux/hooks';
import { Text } from 'design/Text';
import { TextButton } from 'design/Buttons/TextButton';
import GradientButton from 'components/Buttons/GradientButton';

function HomeRegister() {
  const dispatch = useAppDispatch();
  const handleClickLogin = () => {
    dispatch(loginAction());
  };

  const handleClickRegister = () => {
    dispatch(registerAction());
  };

  return (
    <>
      <Container
        sx={{
          backgroundImage: `url(${`${process.env.PUBLIC_URL}/media/images/JoinMabar-Revise-2.png`})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          borderRadius: '12px',
          margin: '12px 0px 10px 0px',
          height: '140px',
        }}
      >
        <Box
          sx={{
            typography: 'label',
            width: '202px',
            height: '40px',
            fontFamily: 'Roboto',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'left',
            color: '#FFFFFF',
            padding: '11px 0px 0px 16px',
          }}
        >
          Buat akun dan main bareng teman sekarang juga
        </Box>
        <Box sx={{ padding: '11px 0px 0px 16px' }}>
          <GradientButton
            width="80px"
            height="34px"
            variant="primary"
            radius="10px"
            onClick={handleClickRegister}
          >
            <Text
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '0',
                color: '#FFFFFF',
              }}
            >
              Daftar
            </Text>
          </GradientButton>
        </Box>
        <Box
          sx={{
            typography: 'label',
            fontFamily: 'Roboto',
            fontSize: '10px',
            fontWeight: '400',
            lineHeight: '14.6px',
            textAlign: 'left',
            color: '#FFFFFF',
            padding: '14px 0px 12px 16px',
          }}
        >
          Sudah memiliki akun?{' '}
          <Link
            underline="none"
            sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '700', lineHeight: '14.6px' }}
            onClick={handleClickLogin}
          >
            <TextButton gradient variant="ribbon">
              Login di sini
            </TextButton>
          </Link>
        </Box>
      </Container>
    </>
  );
}

export default HomeRegister;
