import { playerDetailDrawer } from '../Tentang/Anggota';
import { Box } from '@mui/material';
import { CardPlayerDetail } from 'design/Cards/CardPlayerDetail';
import { DrawerBottom } from 'design/Drawer/DrawerBottom';
import React, { memo } from 'react';

interface AnggotaDrawerProps {
  isDrawerOpen: boolean;
  handleDrawerOpen: () => void;
  setOpenToast: (value: boolean) => void;
  playerDetail?: playerDetailDrawer;
}

const AnggotaDrawer = ({ isDrawerOpen, handleDrawerOpen, playerDetail }: AnggotaDrawerProps) => {
  return (
    <DrawerBottom
      open={isDrawerOpen}
      iconClose={false}
      onClose={handleDrawerOpen}
      onOpen={handleDrawerOpen}
    >
      <Box>
        <CardPlayerDetail
          playerName={playerDetail?.playerName}
          playerPhoto={playerDetail?.playerPhoto}
          gameId={playerDetail?.gameId ?? ''}
          nickname={playerDetail?.nickname ?? ''}
          url={`/player/${playerDetail?.urlslug}`}
        />
      </Box>
    </DrawerBottom>
  );
};

export default memo(AnggotaDrawer);
