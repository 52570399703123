import { Box, Stack } from 'components/styled/layout.styled';
import { IconSource } from 'modules/assetpath';
import React from 'react';
import { useTitle } from 'react-use';
import CardInfo from '../Card/CardInfo';
import CardRule from '../Card/CardRule';
import CardTime from '../Card/CardTime';

export interface DetailRulesProps {
  startAt: string;
  status: string;
}

const DetailRules = ({ startAt, status }: DetailRulesProps) => {
  useTitle(`Match > Detail Info | Info `);
  return (
    <Box width="100%">
      <Stack direction="column">
        <CardTime
          textTitle="Waktu Pertandingan"
          icon={IconSource('ic-time-white')}
          textInfo={startAt}
          iconRight={status !== 'Expired' ? IconSource('fire') : IconSource('fire')}
        />
        <CardRule
          textTitle1="Skema Pertandingan"
          text1="BO3 (Best of Three)"
          textTitle2="Format Pemain"
          text2="5 vs 5"
        />
        <CardInfo textTitle="Peraturan Pertandingan" />
        <br />
        <br />
      </Stack>
    </Box>
  );
};

export default DetailRules;
