import axios, { Method } from 'axios';
import AuthService, { getDecryptedUser } from './AuthService';
import { Constants } from './Constants';

export const fetchApi = async (p: { method: Method; path: string; data?: any; params?: any }) => {
  try {
    const params = {
      method: p.method,
      url: Constants.apiRoot + p.path,
      data: p.data,
      headers: {
        accept: '*/*',
        'x-api-version': '1.0',
        'Access-Control-Allow-Origin': '*',
        Vary: 'Origin',
        'content-type': 'application/json',
        'Cross-Origin-Resource-Policy': 'cross-origin',
        Authorization: getToken(),
      },
      params: p.params,
    };
    const res = await axios(params);
    return res;
  } catch (e) {
    return e;
  }
};

const getToken = () => {
  const oidcStorage = getDecryptedUser();
  let token = '';
  if (oidcStorage && new AuthService().isAuthenticated()) {
    const isNotExpired = oidcStorage.expires_at >= Date.now() / 1000;
    if (oidcStorage.access_token && isNotExpired) {
      token = `${oidcStorage.token_type ?? 'Bearer'} ${oidcStorage.access_token}`;
    }
  }
  return token;
};
