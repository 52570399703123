import { Theme, Box, Avatar, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface FramePhotoProps {
  image?: string;
  frameImage?: string;
  frameText?: string | ReactNode;
  sx?: SxProps<Theme>;
  sxTag?: SxProps<Theme>;
}

export const FramePhoto = ({ image, frameImage, frameText, sx, sxTag }: FramePhotoProps) => {
  return (
    <Stack display="inline-flex" justifyContent="center" position="relative">
      <Avatar
        src={image ? image : IconSource('ic-default-photo')}
        alt="photo"
        sx={{
          overflow: 'inherit',
          width: frameImage ? '72px' : '66px',
          height: frameImage ? '78px' : '66px',
          background: frameImage ? `url(${frameImage}) no-repeat center/cover` : 'none',
          borderRadius: frameImage || sx ? 0 : '50%',
          img: {
            width: frameImage ? '56px' : '100%',
            height: frameImage ? '56px' : '100%',
            borderRadius: '50%',
            filter: frameImage ? 'drop-shadow(0px 2px 4px #181818)' : 'none',
          },
          svg: {
            display: 'none',
          },
          ...sx,
        }}
      />
      {frameImage && (
        <Stack
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
          sx={theme => ({
            position: 'absolute',
            padding: '0 4px',
            bottom: '4px',
            minWidth: '24px',
            height: '11px',
            ':before': {
              content: "''",
              position: 'absolute',
              inset: 0,
              top: '-2px',
              background: 'white',
              borderRadius: '3px',
              filter: 'blur(0.1px)',
              transform: 'perspective(30px) rotateX(15deg)',
              borderTop: `1px solid ${theme.palette.primaryRed300}`,
              transformOrigin: 'top',
            },
            ...sxTag,
          })}
        >
          <Text
            variant="labelSmall"
            sx={theme => ({
              position: 'relative',
              fontFamily: theme.fontFamily.neusaNextStd,
              fontWeight: 700,
              lineHeight: '8px',
              color: 'primaryRed700',
              letterSpacing: '0.4px',
              mt: '1px',
              zIndex: 1,
            })}
          >
            {frameText}
          </Text>
          <Box
            sx={{
              position: 'absolute',
              bottom: '-1px',
              left: '-1.5px',
              width: 'calc(100% + 3px)',
              height: '6px',
              bgcolor: '#CBCBCB',
              borderRadius: '200% 200% 10px 10px',
              zIndex: 0,
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
