import { Stack } from '@mui/material';
import { CardActivityScrim } from 'design/Cards/CardActivityScrim';
import { CardSection } from 'design/Cards/CardSection';
import { Text } from 'design/Text';
import React, { memo } from 'react';
import { useAppSelector } from 'redux/hooks';

const ScrimStatistic = () => {
  const { scrimStatistic } = useAppSelector(({ scrimList }) => ({
    scrimStatistic: scrimList.scrimTeamStatistic,
  }));

  const dataAktifitasScrim = [
    {
      title: 'Total Scrim',
      value: scrimStatistic?.totalScrim ?? 0,
      description: 'Match',
    },
    {
      title: 'Total Lawan',
      value: scrimStatistic?.totalSchool ?? 0,
      description: (
        <>
          Sekolah dari <strong>{scrimStatistic?.totalProvince ?? 0}</strong> Provinsi
        </>
      ),
    },
  ];

  return (
    <CardSection
      title="Aktivitas Scrim"
      cta={
        <Text variant="subtitle" sx={{ color: 'neutral400' }}>
          3 Bulan Terakhir
        </Text>
      }
    >
      <Stack direction="row" spacing="12px">
        {dataAktifitasScrim.map(dataAktifitasScrim => (
          <CardActivityScrim
            title={dataAktifitasScrim.title}
            value={dataAktifitasScrim.value}
            description={dataAktifitasScrim.description}
          />
        ))}
      </Stack>
    </CardSection>
  );
};

export default memo(ScrimStatistic);
