import { LayoutContext } from 'context/LayoutContext';
import { ScrollContext } from 'context/ScrollContext';
import history from 'modules/history';
import { useContext, useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation<any>();
  const { value } = useContext(LayoutContext);
  const { value: scrollValue } = useContext(ScrollContext);
  const [pathname, setPathname] = useState<any>('');
  const [pathnameEqual, setPathnameEqual] = useState(false);

  useEffect(() => {
    setPathname((prev: any) => {
      if (prev === location.pathname) {
        return Promise.all([setPathnameEqual(true), pathname]);
      }
      return Promise.all([setPathnameEqual(false), location.pathname]);
    });

    if (!pathnameEqual) {
      const unlisten = history.listen(() => {
        scrollValue?.scrollRef?.current?.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
    }

    return undefined;
  }, [value, pathnameEqual]);

  return null;
};

export default withRouter(ScrollToTop);
