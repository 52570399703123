import { PopperUnstyled } from '@mui/base';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { InputBase } from '@mui/material';
import { Theme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/system';
import { IconSource } from 'modules/assetpath';
import { HTMLAttributes } from 'react';

export interface StyledButtonProps extends HTMLAttributes<HTMLButtonElement> {
  theme?: Theme;
}

export const StyledButton = styled('div')(({ theme }: StyledButtonProps) => ({
  ...theme?.typography.body,
  boxSizing: 'border-box',
  background: theme?.palette.neutral600,
  color: theme?.palette.neutral200,
  border: '0',
  borderRadius: theme?.borderRadius.md,
  padding: '19px 32px 5px 12px',
  position: 'relative',
  width: '100%',
  textAlign: 'left',
  cursor: 'pointer',
  '&.Mui-expanded': {
    '&::after': {
      backgroundImage: `url(${IconSource('ic-dropdown-active')})`,
    },
  },
  '&::after': {
    content: '""',
    backgroundImage: `url(${IconSource('ic-dropdown-default')})`,
    backgroundRepeat: 'no-repeat',
    width: '16px',
    height: '16px',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '14px',
    margin: 'auto',
  },
  '&.Mui-disabled': {
    border: `1px solid ${theme?.palette.neutral600}`,
    color: theme?.palette.neutral500,
    textFillColor: 'unset',
    cursor: 'default',
  },
  '&.Mui-disabled .MuiTypography-root': {
    color: theme?.palette.neutral400,
  },
}));

export const StyledListbox: any = styled('ul')(
  ({ theme }) => `
  box-sizing: border-box;
  padding: 4px 0;
  margin: 4px 0;
  background: ${theme.palette.neutral600};
  border: 0;
  border-radius: 8px;
  overflow: auto;
  outline: 0px;
  width: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
  `,
);

export const StyledOption: any = styled(OptionUnstyled)(
  ({ theme }: StyledButtonProps) => `
  list-style: none;
  padding: 8px 12px;
  cursor: default;
  font-family: ${theme?.fontFamily.inter};
  font-size: 14px;
  line-height: 20px;
  color: ${theme?.palette.neutral200};
  cursor: pointer;
  border-radius: ${theme?.borderRadius.md};
  &:last-of-type {
    border-bottom: none;
  }
  &.${optionUnstyledClasses.selected} {
    background-color: ${theme?.palette.neutral700};
    color: ${theme?.palette.primary600};
  }
  &.${optionUnstyledClasses.disabled} {
    opacity: 0.5
  }
  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme?.palette.neutral550};
    color: ${theme?.palette.neutral200};
  }
  `,
);

export const StyledPopper = styled(PopperUnstyled)`
  z-index: 3;
  width: 100%;
`;

export const CustomInputSearch = styled(InputBase)(({ theme }: any) => ({
  width: '100%',
  padding: '4px',
  input: {
    ...theme?.typography.subtitle,
    borderRadius: theme.borderRadius.md,
    background: theme.palette.neutral700,
    border: `1px solid ${theme.palette.neutral500}`,
    padding: '7px 10px 7px 27px',
    lineHeight: '14px',
    opacity: '1',
    color: '#dbd2cf',
    backgroundImage: `url(${IconSource('ic-search')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '10px',
    '&::placeholder': {
      opacity: '0.5',
    },
  },
}));

export const CustomInputLabel = styled(InputLabel)(({ theme }: any) => ({
  ...theme.typography.ribbon,
  color: theme.palette.neutral400,
  lineHeight: '16px',
  position: 'absolute',
  left: '12px',
  top: '3px',
  zIndex: 1,
}));
