import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { GradientText, NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import { dateFormat } from 'modules/helpers';
import React, { ReactNode } from 'react';

enum MatchResultStyle {
  BG_COMMON = 'rgba(55, 57, 66, 0.6)',
  BG_WIN = 'linear-gradient(180deg, rgba(49, 193, 81, 0.3) 0%, rgba(49, 193, 81, 0.06) 100%)',
  BG_LOSE = 'linear-gradient(180deg, rgba(213, 31, 64, 0.3) 0%, rgba(213, 31, 64, 0.06) 100%)',
  BORDER_COMMON = '4px solid rgba(55, 57, 66, 0.6)',
  BORDER_NOT_VALID = '4px solid rgba(213, 31, 64, 0.3)',
  BORDER_OPEN_INVITATION = '4px solid rgba(231, 180, 80, 0.3)',
  BORDER_RADIUS_S = '2px',
  BORDER_RADIUS_M = '4px',
  BORDER_RADIUS_L = '8px',
  BORDER_RADIUS_X = '16px',
  BORDER_RADIUS_CIRCLE = '50%',
  BG_MIN_HEIGHT = '142px',
  BG_WIDTH_HORIZONTAL = '343px',
  BG_PADDING_S = '4px',
  BG_WIDTH_VERTICAL = '100%'
}

export interface MatchResultContainerProps {
  background?: string;
  width?: string;
  minHeight?: string;
  border?: string;
  borderRadius?: string;
  padding?: string;
}

const NotValidContainterHorizontal: MatchResultContainerProps = {
  background: MatchResultStyle.BG_COMMON,
  border: MatchResultStyle.BORDER_NOT_VALID,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT,
  width: MatchResultStyle.BG_WIDTH_HORIZONTAL
}

const InvitationContainterHorizontal: MatchResultContainerProps = {
  background: MatchResultStyle.BG_COMMON,
  border: MatchResultStyle.BORDER_OPEN_INVITATION,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT,
  width: MatchResultStyle.BG_WIDTH_HORIZONTAL
}

const WINContainterHorizontal: MatchResultContainerProps = {
  background: MatchResultStyle.BG_WIN,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT,
  width: MatchResultStyle.BG_WIDTH_HORIZONTAL,
  padding: MatchResultStyle.BG_PADDING_S
}

const LOSEContainterHorizontal: MatchResultContainerProps = {
  background: MatchResultStyle.BG_LOSE,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT,
  width: MatchResultStyle.BG_WIDTH_HORIZONTAL,
  padding: MatchResultStyle.BG_PADDING_S
}

const COMPETEContainterHorizontal: MatchResultContainerProps = {
  background: MatchResultStyle.BG_COMMON,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT,
  width: MatchResultStyle.BG_WIDTH_HORIZONTAL,
  padding: MatchResultStyle.BG_PADDING_S
}

const NotValidContainterVertical: MatchResultContainerProps = {
  background: MatchResultStyle.BG_COMMON,
  border: MatchResultStyle.BORDER_NOT_VALID,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT
}

const InvitationContainterVertical: MatchResultContainerProps = {
  background: MatchResultStyle.BG_COMMON,
  border: MatchResultStyle.BORDER_OPEN_INVITATION,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT
}

const WINContainterVertical: MatchResultContainerProps = {
  background: MatchResultStyle.BG_WIN,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT,
}

const LOSEContainterVertical: MatchResultContainerProps = {
  background: MatchResultStyle.BG_LOSE,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT,
}
const COMPETEContainterVertical: MatchResultContainerProps = {
  background: MatchResultStyle.BG_COMMON,
  borderRadius: MatchResultStyle.BORDER_RADIUS_X,
  minHeight: MatchResultStyle.BG_MIN_HEIGHT,
}

export interface MatchResultData {
  firstTeam?: ReactNode;
  firstTeamAvatar?: string;
  firstTeamScore?: ReactNode;
  firstTeamWin?: boolean;
  secondTeam?: ReactNode;
  secondTeamAvatar?: string;
  secondTeamScore?: ReactNode;
  secondTeamWin?: boolean;
  matchDate?: Date;
  matchType?: ReactNode;
}

interface MatchResultItemProps {
  ref?: any;
  id?: string;
  margin?: string;
  containerStyle?: MatchResultContainerProps;
  variant?: 'NotValid' | 'Invitation' | 'WIN' | 'LOSE' | 'COMPETE';
  headerTitle?: ReactNode;
  data?: MatchResultData;
  horizontal?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

function MatchResultItem({ id, ref, variant, headerTitle, horizontal, data, margin, containerStyle, onClick }: MatchResultItemProps) {
  var CONTAINER_VARIANT = NotValidContainterHorizontal;
  if (variant) {
    switch (variant) {
      case 'NotValid': {
        if (horizontal)
          CONTAINER_VARIANT = NotValidContainterHorizontal;
        else CONTAINER_VARIANT = NotValidContainterVertical;
        break;
      }
      case 'Invitation': {
        if (horizontal)
          CONTAINER_VARIANT = InvitationContainterHorizontal;
        else CONTAINER_VARIANT = InvitationContainterVertical;
        break;
      }
      case 'WIN': {
        if (horizontal)
          CONTAINER_VARIANT = WINContainterHorizontal;
        else
          CONTAINER_VARIANT = WINContainterVertical;
        break;
      }
      case 'LOSE': {
        if (horizontal)
          CONTAINER_VARIANT = LOSEContainterHorizontal;
        else
          CONTAINER_VARIANT = LOSEContainterVertical;
        break;
      }
      case 'COMPETE': {
        if (horizontal)
          CONTAINER_VARIANT = COMPETEContainterHorizontal;
        else CONTAINER_VARIANT = COMPETEContainterVertical;
        break;
      }
      default: undefined;
    }
  }
  const parsePx = CONTAINER_VARIANT.width?.split('px')[0] ?? '243';
  const headerMaxWidth = `${parseInt(parsePx!) - 150}px`
  return (
    <Box id={id} ref={ref} margin={margin} position='relative' onClick={onClick} isPressble={onClick ? true : false} disableDrag>
      <Stack direction='column' zIndex={0} disableDrag disableSelectText>
        <Box {...CONTAINER_VARIANT} {...containerStyle} flexGrow={1}>
          <Stack direction='column' align='center' disableDrag disableSelectText>
            <Stack direction='row' align='evenly' margin='32px 0px 16px' disableDrag disableSelectText>
              <Stack direction='row' align='center' disableDrag disableSelectText opacity={data?.firstTeamWin ? data.firstTeamWin ? 1 : 0.5 : data?.firstTeamWin === undefined ? 1 : 0.5}>
                <NormalText fontSize='14px' color='white' fontStyle='normal' fontWeight='700' maxLines={4} maxWidth='85px' wordWrap='break-word'>
                  {data?.firstTeam ?? '-'}
                </NormalText>
                {data?.firstTeam && (
                  <Avatar border='2px solid #F1EBEB' width='20px' height='20px' borderRadius='12px' margin='0px 0px 0px 10px'
                    background={data?.firstTeamAvatar ? 'transparent' : '#B1B1B1'} fit="contain" objectPos={data?.firstTeamAvatar ? 'center' : 'bottom'}
                    src={data?.firstTeamAvatar ?? IconSource('union-placeholder')} onError={(e) => {
                      e.currentTarget.src = IconSource('union-placeholder')
                      e.currentTarget.style.objectPosition = 'bottom'
                    }} />
                )}
              </Stack>
              <Box background='rgba(213, 31, 64, 0.12)' borderRadius='5px' padding='5.5px 8px' alignSelf='center'>
                <GradientText fontSize='14px' fontWeight='bold' textAlign='center' lineHeight='19.6px'
                  color='#F3AA36'>
                  VS
                </GradientText>
              </Box>
              <Stack direction='row' align='center' disableDrag disableSelectText opacity={data?.secondTeamWin ? data.secondTeamWin ? 1 : 0.5 : data?.secondTeamWin === undefined ? 1 : 0.5}>
                {data?.secondTeam && (
                  <Avatar border='2px solid #F1EBEB' width='20px' height='20px' borderRadius='12px' margin='0px 10px 0px 0px'
                    background={data?.secondTeamAvatar ? 'transparent' : '#B1B1B1'} fit="contain" objectPos={data?.secondTeamAvatar ? 'center' : 'bottom'}
                    src={data?.secondTeamAvatar ?? IconSource('union-placeholder')} onError={(e) => {
                      e.currentTarget.src = IconSource('union-placeholder')
                      e.currentTarget.style.objectPosition = 'bottom'
                    }} />
                )}
                <NormalText fontSize='14px' color='white' fontStyle='normal' fontWeight='700' maxLines={4} maxWidth='85px' wordWrap='break-word'>
                  {data?.secondTeam ?? '-'}
                </NormalText>
              </Stack>
            </Stack>
            <Stack direction='row' align='evenly' disableDrag disableSelectText>
              <NormalText fontSize='18px' color='#DDD8D8' lineHeight='25.2px' fontStyle='normal' fontWeight='700' textAlign='center'>
                {data?.firstTeamScore ?? '-'}
              </NormalText>
              <Box background='rgba(255, 255, 255, 0.06)' borderRadius='6px' padding='4px 6px' alignSelf='center'>
                <NormalText fontSize='12px' fontWeight='400' textAlign='center' lineHeight='16.8px'
                  color='#DDD8D8'>
                  {dateFormat(data?.matchDate, 'Asia/Jakarta', 'id').format(`${variant === 'NotValid' ? 'DD MMM YYYY - HH:mm z' : 'DD MMM YYYY'}`)}
                  {variant === 'NotValid' ? undefined : data?.matchType}
                </NormalText>
              </Box>
              <NormalText fontSize='18px' color='#DDD8D8' lineHeight='25.2px' fontStyle='normal' fontWeight='700' textAlign='center'>
                {data?.secondTeamScore ?? '-'}
              </NormalText>
            </Stack>
          </Stack>
        </Box>
        {headerTitle && (
          <Stack align='center' position='absolute' zIndex={1} disableDrag disableSelectText>
            <Box id='MatchResultTitle' borderRadius='0px 0px 16px 16px' background='#1E1F25'
              padding='10px 0px 12px 0px' margin='0px 0px 0px 8px'
              maxWidth={headerMaxWidth} minWidth='243px'>
              <Stack align='center'>
                <NormalText fontSize='12px' color='#DDD8D8' fontStyle='normal' fontWeight='500'
                  textAlign='center' lineHeight='16.8px'>
                  {headerTitle}
                </NormalText>
              </Stack>
            </Box>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default MatchResultItem;
