import { Container, Box, Stack } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { DrawerPrimary } from 'design/Drawer/Primary';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TournamentDetailResponse } from 'redux/tournament/TournamentProps';

export interface BracketProps {
  tournamentDetail?: TournamentDetailResponse;
}

const BracketPage = ({ tournamentDetail }: BracketProps) => {
  const { t } = useTranslation();

  const [getIsDrawerOpen, setIsDrawerOpen] = useState(false);
  const [getLoadIframe, setLoadIframe] = useState(true);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(false);
  };

  const hideSpinnerIframe = () => {
    setLoadIframe(false);
  };

  return (
    <Container
      disableGutters
      sx={{
        bgcolor: 'neutral900',
        padding: '16px 16px 16px 16px',
      }}
    >
      <DrawerPrimary
        title={t('tournament_tab_3')}
        position="bottom"
        height="calc(100% - (0px + 24px))"
        padding="12px 0px 2px 2px"
        open={getIsDrawerOpen}
        onClose={handleDrawerOpen}
      >
        <Box sx={{ height: '90vh' }}>
          {getLoadIframe ? (
            <Box
              sx={{
                display: 'grid',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Text variant="h3">Please Wait ..</Text>
            </Box>
          ) : null}
          <iframe
            width="100%"
            height="100%"
            src={tournamentDetail?.bracketUrl ?? 'https://challonge.com/7lfwj4ok/module'}
            onLoad={hideSpinnerIframe}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </Box>
      </DrawerPrimary>

      {tournamentDetail?.bracketUrl ? (
        <Stack
          height={'109px'}
          spacing={2}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            borderRadius: '12px',
            height: '109px',
            backgroundImage: `url(${`${process.env.PUBLIC_URL}/media/images/Lihat_Bracket.png`})`,
            backgroundPosition: 'bottom',
          }}
        >
          <Box
            sx={{
              padding: '12.5px 12px 12px 12.5px',
              width: '319px',
              height: '40px',
              top: '12.5px',
            }}
          >
            {' '}
            <Text
              sx={{
                color: 'neutral100',
                fontSize: '14px',
                lineHeight: '20px',
                fontStyle: 'Regular',
              }}
            >
              {t('tournament_tab_3_label_bracket')}
            </Text>
          </Box>
          <Box
            sx={{
              width: '120px',
              height: '32px',
              top: '65px',
              marginTop: '0px !important',
              paddingLeft: '12px',
            }}
          >
            {' '}
            <SecondaryButton variant="small" wide onClick={() => setIsDrawerOpen(true)}>
              {t('tournament_tab_3_label_button_lihat_bracket')}
            </SecondaryButton>
          </Box>
        </Stack>
      ) : (
        <Box
          sx={{
            display: 'grid',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Stack justifyContent={'center'} alignItems={'center'} marginTop={'70px'}>
            <img
              style={{ backgroundRepeat: 'no-repeat' }}
              src={IconSource('mabar-typeface-fullwhite-bracket')}
              alt="mabar-typeface-fullwhite"
            />
          </Stack>
          <Box>
            <NormalText
              color="#8D8784"
              textAlign="center"
              fontStyle="normal"
              fontSize="12px"
              lineHeight="17px"
            >
              {t('tournament_tab_3_placeholder_empty')}
            </NormalText>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default BracketPage;
