import { MenuItem, MenuList, Paper, Popper } from '@mui/material';
import styled from 'styled-components';

export const PopupMenu = styled(Popper)`    
  align-self: ${p => {
    switch (p.placement) {
      case 'top':
        return 'center';
      case 'top-start':
        return 'flex-start';
      case 'top-end':
        return 'flex-end';
      case 'auto':
        return 'auto';
      default:
        return undefined;
    }
  }};
  margin-right: 5px;
`;

export const MenuContainer = styled(Paper)`
  &.MuiPaper-root {
    border-radius: 12px;
    background: #373942f0;
  }
`;

export const MenuLists = styled(MenuList)`
&.MuiList-root {
}
`;

export const MenuItems = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: ${p => p.color ? p.color : 'white'};
    padding: 2px 80px 2px 12px;
    min-height: 35px;
    &:active{
        color: #ff4d6d;
    }
    &:focus-within{
        color: #ff4d6d;
    }
  }
  &.MuiMenuItem-root > span {
      display: none;
  }
`;

export const MenuSeparator = styled.div`
    border: 0.5px solid #6A6363;
    margin-left: 12px;
    margin-right: 12px;
`;