import BackgroundImage from 'components/layouts/BackgroundImage';
import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface GameListProps {
  variant?: 'default' | 'active' | 'soon' | 'change' | 'small-default' | 'small-soon';
  gameId?: string;
  boxColor?: string;
  gameBg?: string;
  gameName?: ReactNode;
  gameInfo?: ReactNode;
  gameOptions?: any;
  soonText?: ReactNode;
  gameChangeText?: ReactNode;
  width?: string;
  heigth?: string;
  margin?: string;
  onChangeGame?: ((game: any) => void) | undefined;
  onClick?: ((game: any) => void) | undefined;
  styled?: React.CSSProperties;
}

function GameList({
  variant,
  boxColor = 'transparent',
  gameId,
  gameBg,
  gameName,
  gameInfo,
  gameOptions,
  soonText = 'Segera Hadir',
  gameChangeText = 'Ganti Game',
  width,
  heigth,
  margin,
  onChangeGame,
  onClick,
  styled,
}: GameListProps) {
  return (
    <Box
      id={gameId}
      display={variant === 'small-default' || variant === 'small-soon' ? 'inline-block' : 'flex'}
      width={width}
      margin={margin}
      onClick={() => {
        if (onClick) {
          onClick({
            id: gameOptions?.id!!,
            name: gameOptions?.name!!,
            avatarUrl: gameOptions?.avatarUrl!!,
            teamCount: gameOptions?.teamCount!!,
            tournamentCount: gameOptions?.tournamentCount!!,
            scrimCount: gameOptions?.scrimCount!!,
            urlSlug: gameOptions?.urlSlug!!,
            release: gameOptions?.release!!,
            maxMember: gameOptions?.maxMember!!,
            minMember: gameOptions?.minMember!!
          });
        }
      }}
      isPressble={variant !== 'small-soon' && onClick ? true : false}
      disableDrag
    >
      <Box
        flexGrow={1}
        background={boxColor}
        borderRadius="20px"
        padding={boxColor === 'transparent' ? '0px' : '4px 4px 4px 4px'}
        style={styled}
      >
        <BackgroundImage
          borderRadius="20px"
          minHeight={heigth ?? '150px'}
          backgroundColor="#1D1D20"
          mask="linear-gradient(177deg, #1D1D20 20.56%, rgba(29, 29, 32, 0.22) 55.34%, rgba(29, 29, 32, 0.033) 79.09%, rgba(29, 29, 32, 0.033) 30.39%, rgba(29, 29, 32, 0.012) 69.5%)"
          src={gameBg}
        >
          <Box flexGrow={1} />
          <Stack direction="row" align="start">
            <Box padding="0px 8px" height="100%">
              <Stack
                direction="column"
                align="start"
                margin={
                  variant !== 'change' && variant !== 'default'
                    ? variant === 'small-default' || variant === 'small-soon'
                      ? '0px 8px'
                      : '0px 24px'
                    : '0px 16px'
                }
                disableSelectText
                style={{ justifyContent: 'flex-end' }}
              >
                {gameName !== undefined && (
                  <NormalText
                    margin="6px 0px"
                    color="white"
                    fontStyle="normal"
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="22.4px"
                  >
                    {gameName}
                  </NormalText>
                )}
                {gameInfo !== undefined && (
                  <NormalText
                    color="rgba(255, 255, 255, 0.7)"
                    fontStyle="normal"
                    fontSize="12px"
                    fontWeight="400"
                    margin="0px 0px 0px 0px"
                    lineHeight="17.52px"
                  >
                    {gameInfo}
                  </NormalText>
                )}
                {variant === 'change' && (
                  <NormalText
                    margin="6px 0px"
                    color="white"
                    fontStyle="normal"
                    fontSize="12px"
                    fontWeight="700"
                    textDecoration="underline"
                    lineHeight="17.52px"
                    onClick={() => {
                      if (onChangeGame) {
                        onChangeGame({
                          id: gameOptions?.id!!,
                          name: gameOptions?.name!!,
                          avatarUrl: gameOptions?.avatarUrl!!,
                          teamCount: gameOptions?.teamCount!!,
                          tournamentCount: gameOptions?.tournamentCount!!,
                          scrimCount: gameOptions?.scrimCount!!,
                          urlSlug: gameOptions?.urlSlug!!,
                          release: gameOptions?.release!!,
                          maxMember: gameOptions?.maxMember!!,
                          minMember: gameOptions?.minMember!!
                        });
                      }
                    }}
                    isPressable
                    disableDrag
                    disableSelectText
                  >
                    {gameChangeText}
                  </NormalText>
                )}
                {variant === 'default' && (
                  <NormalText
                    margin="6px 0px"
                    color="#969393"
                    fontStyle="normal"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="17.52px"
                  >
                    {gameChangeText}
                  </NormalText>
                )}
                <Box
                  height={
                    variant !== 'change' && variant !== 'default'
                      ? variant === 'small-default' || variant === 'small-soon'
                        ? '12px'
                        : '24px'
                      : '8px'
                  }
                />
              </Stack>
            </Box>
          </Stack>
          {variant === 'soon' && (
            <Box
              position='absolute'
              borderRadius="20px"
              width="100%"
              minHeight={heigth ?? '150px'}
              background="rgba(46, 50, 56, 0.85)"
            >
              <Stack direction="column" align="center" disableSelectText>
                <Avatar width="30px" height="32px" src={IconSource('ic-lock-closed-white')} />
                <NormalText
                  margin="8px 0px"
                  color="white"
                  fontStyle="normal"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="22.4px"
                >
                  {soonText}
                </NormalText>
              </Stack>
            </Box>
          )}
          {variant === 'small-soon' && (
            <Box
              position='absolute'
              borderRadius="16px"
              padding={boxColor === 'transparent' ? '0px 0px 0px 0px' : '4px 4px 4px 4px'}
              width={width ?? '250px'}
              minHeight={heigth ?? '150px'}
              background="rgba(46, 50, 56, 0.85)"
            >
              <Stack direction="column" align="center" disableSelectText>
                <Avatar width="30px" height="32px" src={IconSource('ic-lock-closed-white')} />
                <NormalText
                  margin="8px 0px"
                  color="white"
                  fontStyle="normal"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="22.4px"
                >
                  {soonText}
                </NormalText>
              </Stack>
            </Box>
          )}
          {variant === 'small-default' && (
            <Box
              position='absolute'
              borderRadius="16px"
              width={width ?? '250px'}
              minHeight={heigth ?? '150px'}
              background="transparent"
            >
              <Stack direction="column" align="center" disableSelectText>
                <Avatar width="104px" height="32px" zIndex={-1} src={IconSource('ml-logo')} />
              </Stack>
            </Box>
          )}
        </BackgroundImage>
      </Box>
    </Box>
  );
}

export default GameList;
