import { MatchesResponse, MatchDetail } from './matchProps';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export const fetchMatchList = createAsyncThunk(
  'match-list',
  async ({ tournamentId }: { tournamentId: string }) => {
    const url = `/event-tournament/${tournamentId}/matches`;
    const res = await ApiService.get<MatchesResponse>(url);
    return res.data;
  },
);

export const fetchMatchDetail = createAsyncThunk(
  '/tournament/match',
  async ({ matchId }: { matchId: string }) => {
    const url = `/event-tournament/match/${matchId}`;
    const res = await ApiService.get<MatchDetail>(url);

    return res.data;
  },
);

export const playerCheckinMatch = createAsyncThunk(
  '/tournament-participant/check-in',
  async ({ matchId }: { matchId: string }) => {
    try {
      const url = `/tournament-participant/check-in/${matchId}`;
      const res = await ApiService.put(url, {
        matchId: matchId,
      });

      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);
