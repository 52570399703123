import styled from 'styled-components';

interface IconProps {
  margin?: string | undefined;
  src?: string | undefined;
  height?: string | undefined;
  width?: string | undefined;
}

export const IconButton = styled.button<IconProps>`
  height: ${p => p.height};
  width: ${p => p.width};
  border-radius: 23px;
  margin: ${p => p.margin};
  display: flex;
  background: none;
  border: none;
  opacity: 1;
  cursor: pointer;
  justify-items: center;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-image: url(${p => p.src});
  &:active {
    opacity: 0.4;
  }
`;

export const Icon = styled.img<IconProps>`
  object-fit: contain;
  margin: ${p => (p.margin ? p.margin : '6px')};
  width: ${p => p.width};
  height: ${p => p.height};
  src: ${p => p.src};
`;

export const IconRounded = styled.img<IconProps>`
  border-radius: 8px;
  padding: 6px;
  background: rgba(255, 255, 255, 0.16);
  width: ${p => p.width};
  height: ${p => p.height};
  margin: ${p => p.margin};
  src: ${p => p.src};
`;
