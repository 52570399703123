import { Stack } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { CardTeamId } from 'design/Cards/CardTeamId';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import AuthService from 'services/AuthService';

const TeamId = () => {
  const location = useLocation<any>();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();

  const { playerInfo, teamInfo } = useAppSelector(({ account, myTeam }) => ({
    playerInfo: account?.data,
    teamInfo: myTeam?.data?.items,
  }));

  const myRole = playerInfo?.games && playerInfo?.games[0];
  const myTeam = isAuthenticated
    ? teamInfo?.find(team => team?.urlSlug === myRole?.team?.urlSlug)
    : undefined;

  const handleWaiting = useCallback(() => {
    if (myTeam?.status?.value === 'Waiting') {
      return (
        <Stack alignItems="flex-end" sx={{ flex: 'auto' }}>
          <img src={IconSource('ic-waiting')} width={24} />
        </Stack>
      );
    }
    return undefined;
  }, [myTeam?.status?.value]);

  return myTeam ? (
    <CardTeamId
      gameName={myRole?.game?.name ?? ''}
      teamLogo={myTeam?.avatarUrl ?? ''}
      teamName={myRole?.team?.name ?? ''}
      additional={handleWaiting()}
      link={{
        pathname: `/team/${myRole?.game?.urlSlug}/${playerInfo?.school.urlSlug}/${myRole?.team?.urlSlug}`,
        state: {
          pathname: location.pathname,
        },
      }}
    />
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      spacing="12px"
      sx={theme => ({
        bgcolor: 'neutral700',
        borderRadius: theme.borderRadius.md,
        p: '16px 12px',
      })}
    >
      <Text variant="body" sx={{ color: 'neutral300' }}>
        Kamu belum punya tim. Buat atau cari tim untuk bisa bertanding.
      </Text>
      <Link to="/my-team">
        <PrimaryButton variant="small" sx={{ whiteSpace: 'nowrap' }}>
          Cari Tim
        </PrimaryButton>
      </Link>
    </Stack>
  );
};

export default memo(TeamId);
