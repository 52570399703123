import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
}

const SEO = ({ title }: SEOProps) => (
  <Helmet
    defaultTitle={title}
    defer={false}
    encodeSpecialCharacters
    htmlAttributes={{
      lang: process.env.APP_DEFAULT_LANGUAGE,
    }}
    meta={[
      {
        name: 'description',
        content: process.env.APP_DESCRIPTION,
      },
    ]}
  ></Helmet>
);

export default SEO;
