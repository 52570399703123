import { BgImage, BgImageContainer } from 'components/styled/layout.styled';
import { ImageSource } from 'modules/assetpath';
import React from 'react';
import { FC, ReactNode } from 'react';

export interface BgImageType {
  children?: ReactNode | undefined;
  mask?: string | undefined;
  src?: string | undefined;
  backgroundColor?: string | undefined;
  borderRadius?: string | undefined;
  minHeight?: string | undefined;
  styled?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
}

const BackgroundImage: FC<BgImageType> = ({
  children,
  mask,
  src,
  backgroundColor,
  borderRadius,
  minHeight,
  styled = {
    position: 'relative',
    alignSelf: 'center',
    borderRadius: borderRadius,
    minHeight: minHeight,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  imgStyle
}) => {
  return (
    <BgImageContainer
      minHeight={minHeight}
      borderRadius={borderRadius}
      background={backgroundColor}
      style={styled}
    >
      <BgImage alt="Background Image" borderRadius={borderRadius} src={src} mask={mask} style={imgStyle}
      onError={(v)=> {
        v.currentTarget.src = ImageSource('team.background')
      }} />
      {children}
    </BgImageContainer>
  );
};

export default BackgroundImage;
