import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC, ReactNode, useState} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { CSSProperties } from 'styled-components';
import { Input, BorderInput, Label, IconEye } from './styles';
import { IconSource } from 'modules/assetpath';

export interface InputPasswordProps {
  id?: string;
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  placeholder?: string;
  label: ReactNode;
  minLength?: number;
  maxLength?: number;
  isRequired?: boolean;
  value?: string | number | readonly string[] | undefined;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  type?: string;
  margin?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onInvalid?: React.FormEventHandler<HTMLInputElement> | undefined;
  validationText?: string;
  validationType?: 'ERROR' | 'WARNING' | 'INFO' | string | undefined;
  registerForm?: UseFormRegisterReturn;
  disabled?: boolean | undefined;
  style?: CSSProperties;
}
export const InputPassword: FC<InputPasswordProps> = ({
  id,
  ref,
  label,
  placeholder,
  minLength,
  maxLength,
  isRequired,
  value,
  inputMode,
  margin,
  onChange,
  onInvalid,
  validationText,
  validationType,
  registerForm,
  disabled,
  style
}) => {
const [passwordShown, setPasswordShown] = useState(false);
const [checked, setChecked] = useState(false);
const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
};
  let validationRef = React.useRef<HTMLParagraphElement | null>();
  return (
    <>
      <BorderInput style={style} margin={margin} padding="0px 25px 0px 0px">
        <Label>{label}</Label>
        <Input
          id={id}
          ref={ref}
          placeholder={placeholder}
          onChange={onChange}
          inputMode={inputMode}
          type={passwordShown ? "text" : "password"}
          minLength={minLength}
          maxLength={maxLength}
          required={isRequired}
          value={value}
          disabled={disabled}
          onInvalid={e => {
            if (onInvalid) {
              onInvalid(e);
            }
            if (!e.currentTarget.value) {
              validationRef.current!!.style.display = 'flex';
            } else {
              validationRef.current!!.style.display = 'none';
            }
          }}
          {...registerForm}
        />
        <IconEye onClick={togglePasswordVisiblity}>
        <img onClick={() => setChecked(!checked)} src={checked? IconSource('ic-eye-on-white') : IconSource('ic-eye-off-white')} alt="" /> 
      </IconEye>
      </BorderInput>
      {validationType && (
        <Stack
        ref={e => (validationRef.current = e)}
        align="start"
        direction="row"
        margin="-4px 0px 0px 5px"
        disableSelectText
        display='flex'
        >
        <NormalText
          color={
            validationType === 'ERROR'
              ? '#FF4D6D'
              : validationType === 'WARNING'
              ? '#E7B450'
              : validationType === 'INFO'
              ? '#DDD8D8'
              : 'white'
          }
          fontSize="12px"
          lineHeight="146%"
          fontStyle="normal"
          fontWeight="400"
        >
          {validationText}
        </NormalText>
        </Stack>

      )}    </>
  );
};

export default InputPassword;
