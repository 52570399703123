import { IOptions } from 'components/Inputs/SelectOption';
import dayjs, { Dayjs } from 'dayjs';
import {
  getDaysArray,
  getTimesArray,
} from 'modules/helpers';
require('dayjs/locale/id');

const scrimTimeRange = {
  Weekday: { start: 14, end: 22 },
  Weekend: { start: 8, end: 22 },
};

const scrimInterval = 30;
const maxScrimIntervalFromNow = 180;

export const scrimOptions: readonly IOptions[] = [
  { value: 'public', label: 'Publik' },
  { value: 'challenge', label: 'Kirim Tantangan Ke Spesifik Tim' },
];

export const scrimSchedule = () => {
  dayjs.locale('id');
  const nextWeek = dayjs().add(6, 'day');
  const temp: IOptions[] = [];
  var dateRange = getDaysArray(dayjs(), nextWeek);
  dateRange.map(day => {
    temp.push({ value: dayjs(day), label: dayjs(day).format('D MMMM YYYY') });
  });
  return temp;
};

export const scrimScheduleTime = (currentDate: Dayjs) => {
  const temp: IOptions[] = [];
  const currentDateObj = dayjs(currentDate);
  const day = currentDateObj.day();
  let timeRange;

  if (day === 0 || day === 6) {
    timeRange = scrimTimeRange.Weekend;
  } else {
    timeRange = scrimTimeRange.Weekday;
  }
  const timeRanges = getTimesArray(
    currentDateObj.hour(timeRange.start).minute(0).second(0).millisecond(0),
    currentDateObj.hour(timeRange.end).minute(0).second(0),
    scrimInterval,
  );
  const isSameToday = currentDateObj.format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY');
  timeRanges.map(time => {
    if (isSameToday) {
      if (dayjs().add(maxScrimIntervalFromNow, 'minute').isBefore(time)) {
        temp.push({ value: time, label: dayjs(time).format('HH:mm') });
      }
    } else {
      temp.push({ value: time, label: dayjs(time).format('HH:mm') });
    }
  });
  return temp;
};
