import { Box, AvatarGroup, Avatar } from '@mui/material';
import { Text } from 'design/Text';
import React from 'react';

export interface PlayerProps {
  name?: string;
  src?: string;
}

export interface TeamPlayerProps {
  player?: Array<PlayerProps>;
  point?: string;
  borderColor?: string;
  max?: number;
}

export const TeamPlayer = ({ player, point, max, borderColor }: TeamPlayerProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AvatarGroup
        max={max || 999}
        sx={{
          '.MuiAvatar-root': { border: `2px solid ${borderColor || 'white'}` },
          '.MuiAvatar-colorDefault': {
            width: '17px',
            height: '17px',
            fontSize: '10px',
            fontWeight: 400,
            ml: '-4px',
          },
        }}
      >
        {player?.map(({ name, src }, idx) => (
          <Avatar key={idx} sx={{ width: '17px', height: '17px' }} alt={name} src={src} />
        ))}
      </AvatarGroup>
      {point && (
        <Text variant="label" sx={{ fontWeight: 500, color: 'white', ml: '12px' }}>
          {point}
        </Text>
      )}
    </Box>
  );
};
