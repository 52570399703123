import { Theme, Stack, Box, Avatar } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { Link } from 'react-router-dom';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export interface CardTournamentParticipationProps {
  title?: string | ReactNode;
  description?: string;
  cta?: ReactNode;
  sx?: SxProps<Theme>;
  theme?: Theme;
  url?: string;
}

const CardTournamentParticipationStyled: any = ({
  theme,
  sx,
}: CardTournamentParticipationProps) => {
  return {
    position: 'relative',
    height: '131px',
    p: '13px 12px 12px 12px',
    borderRadius: theme?.borderRadius.md,
    border: '1px solid #27282B',
    background: theme?.palette.neutral700,
    boxSizing: 'border-box',
    ...sx,
  };
};

const content = (title: string | ReactNode, description: string | ReactNode, url: boolean) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    borderBottom={'1px solid #27282B'}
  >
    <Stack justifyContent="center" sx={{ width: '100%' }}>
      <Text
        className="cardtournamentparticipation-title"
        variant="label"
        sx={{
          fontWeight: '400',
          textShadow: '0px 4px 2px rgba(0, 0, 0, 0.12)',
          cursor: url ? 'pointer' : 'inherit',
          width: '100%',
        }}
      >
        {title ? <ResponsiveEllipsis text={title} maxLine={1} ellipsis="..." /> : null}
      </Text>
      <Text
        className="cardtournamentparticipation-description"
        variant="subtitle"
        sx={{ color: 'neutral300', m: '4px 0 12px', lineHeight: '18px', width: '100%' }}
      >
        {description ? <ResponsiveEllipsis text={description} maxLine={2} ellipsis="..." /> : null}
      </Text>
    </Stack>
    {url ? (
      <Avatar
        src={IconSource('ic-chevron-right')}
        sx={{ width: '24px', height: '24px', ml: '4px', borderRadius: '0' }}
      />
    ) : null}
  </Stack>
);

export const CardTournamentParticipation = ({
  title,
  description,
  url,
  cta,
  sx,
}: CardTournamentParticipationProps) => {
  return (
    <Box sx={theme => CardTournamentParticipationStyled({ theme, sx })}>
      {url ? (
        <Link to={url} style={{ textDecoration: 'none' }}>
          {content(title, description, Boolean(url))}
        </Link>
      ) : (
        content(title, description, Boolean(url))
      )}
      <Box sx={{ mt: '10px' }}>{cta}</Box>
    </Box>
  );
};
