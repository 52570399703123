import { NormalText } from 'components/styled/text.styled';
import { InputField } from 'components/Inputs/Input';
import { TextArea } from 'components/Inputs/TextArea';
import BackgroundImage from 'components/layouts/BackgroundImage';
import { ImageSource } from 'modules/assetpath';
import { Box, Stack } from 'components/styled/layout.styled';
import GradientButton from 'components/Buttons/GradientButton';
import React, { useState } from 'react';
import AppLayout from 'components/layouts/AppLayout';

const ReportCheat = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <AppLayout
    noMargin={true}
    opacityBg='0.04' 
    appBar={{
      bg: {},
      navleft: {
        bg: 'ic-arrow-back-light',
        width: '24px',
        height: '24px',
      },
      navright: {
        variant: ''
      },
      isDisable: false,
      title: `Laporan Kecurangan`
    }}
      backgroundImage={ImageSource('app.background')}
      showModal={showModal}
      modalContent={
        <Box padding="16px" width="100%">
          <Stack direction="column" margin="16px">
            <NormalText
              textAlign="center"
              color="#FFFFFF"
              fontSize="16px"
              fontWeight="bold"
              margin="8px 0px"
            >
              Terima Kasih!
            </NormalText>
            <NormalText
              textAlign="center"
              color="#969393"
              fontSize="14px"
              fontWeight="normal"
              margin="8px 0px"
            >
              Kami telah menerima permintaanmu.
            </NormalText>
            <br />
            <Box padding="8px" width="100%">
              <Stack direction="column" align="center">
                <GradientButton
                  onClick={() => setShowModal(false)}
                  styled={{
                    backgroundSize: '100%',
                    width: '180px',
                  }}
                  variant="primary"
                >
                  OK
                </GradientButton>
              </Stack>
            </Box>
          </Stack>
        </Box>
      }
    >
      <BackgroundImage
        src={ImageSource('card.background')}
        backgroundColor="#131318"
        mask="linear-gradient(transparent, #00000090, #00000023, transparent)"
        //   styled={styled}
      >
        <NormalText
          style={{ marginLeft: '10px', marginRight: 'auto', marginTop: '35px' }}
          fontSize="18px"
          fontWeight="bold"
          lineHeight="140%"
          color="white"
          textAlign="left"
        >
          MABAR menjunjung tinggi sportivitas
        </NormalText>
        <NormalText
          style={{ marginBottom: '40px' }}
          fontSize="12px"
          fontWeight="normal"
          lineHeight="140%"
          color="#DDD8D8"
          textAlign="left"
          margin="10px"
        >
          Ceritakan pengalaman tidak menyenangkanmu dengan detail supaya kami dapat membantumu.
        </NormalText>
      </BackgroundImage>
      <div style={{margin: '12px'}}>
        <InputField
          label="Nama"
          onChange={() => {}}
          inputMode="text"
          // onClick={function noRefCheck() {}}
          placeholder="John Doe"
        />
        <InputField
          label="Email"
          onChange={() => {}}
          // onClick={function noRefCheck() {}}
          placeholder="johndoe@gmail.com"
          inputMode="email"
        />
        <InputField
          label="Nomor Ponsel"
          onChange={() => {}}
          // onClick={function noRefCheck() {}}
          placeholder="0812xxxxxxx"
          inputMode="tel"
        />
        <InputField
          label="Tipe Laporan"
          onChange={() => {}}
          // onClick={function noRefCheck() {}}
          placeholder="Laporan Pertandingan Scrim"
          inputMode="text"
          style={{background:'rgba(46, 50, 56, 0.6)'}}
          disabled={true}
        />
        <InputField
          label="Scrim Match ID"
          onChange={() => {}}
          inputMode="text"
          // onClick={function noRefCheck() {}}
          placeholder="Masukan Scrim Match ID"
        />
        <TextArea
          label="Pesan"
          onChange={() => {}}
          // onClick={function noRefCheck() {}}
          placeholder="Tulis pertanyaan kamu disini"
          inputMode="text"
          type="textarea"
        />
        <br />
        <Box padding="8px" width="100%">
          <Stack direction="column" align="center">
            <GradientButton
              onClick={() => setShowModal(true)}
              styled={{
                backgroundSize: '100%',
                width: '100px',
              }}
              variant="primary"
            >
              Kirim
            </GradientButton>
          </Stack>
        </Box>
      </div>
    </AppLayout>
  );
};

export default ReportCheat;
