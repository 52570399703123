import { DataDrawerRosterTypes } from '../Awards';
import { Stack } from '@mui/material';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { TextButton } from 'design/Buttons/TextButton';
import { CardTournamentParticipation } from 'design/Cards/CardTournamentParticipation';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { AwardsType } from 'redux/award/AwardProps';
import { useAppSelector } from 'redux/hooks';

interface AwardSectionProps {
  setIsOpenDrawerRoster: (value: boolean) => void;
  setDataDrawerRoster: (value: DataDrawerRosterTypes) => void;
}

const AwardSection = ({ setIsOpenDrawerRoster, setDataDrawerRoster }: AwardSectionProps) => {
  const { awardTeamList, teamDetail } = useAppSelector(({ award, teamProfile }) => ({
    awardTeamList: award?.tournamentParticipantAwardsTeam?.awards,
    teamDetail: teamProfile?.teamDetail,
  }));

  const handleButtonRoster = (awardData: AwardsType) => {
    setDataDrawerRoster({
      ...awardData,
      ...{
        teamAvatarUrl: awardData?.teamAvatarUrl ?? teamDetail?.avatarUrl,
        rosters: awardData?.teamRoster,
        type: 'award',
      },
    });
    setIsOpenDrawerRoster(true);
  };

  return (
    <Stack spacing="16px" sx={{ mt: '10px', px: '16px' }}>
      {awardTeamList?.map((award, idx) => {
        return idx < 3 ? (
          <CardTournamentParticipation
            key={idx}
            title={award?.title}
            description={`${award?.eventName} • ${award?.awardsDate}`}
            cta={
              <TextButton
                icon={IconSource('ic-tim-outline')}
                sx={{
                  svg: { mr: '4px' },
                }}
                onClick={() => handleButtonRoster(award)}
              >
                Lihat Roster
              </TextButton>
            }
            sx={{ height: 'auto' }}
          />
        ) : null;
      })}

      {(awardTeamList ?? []).length > 2 ? (
        <Link to={`${history.location.pathname}/awards`}>
          <SecondaryButton variant="small" wide>
            Lihat Semua
          </SecondaryButton>
        </Link>
      ) : null}
    </Stack>
  );
};

export default memo(AwardSection);
