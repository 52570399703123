import { Box, useTheme, Link } from '@mui/material';
import history from 'modules/history';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import styled from 'styled-components';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const images = [
  {
    label: 'RRQ MABAR ESPORTS TOURNAMENT',
    imgPath: `${process.env.PUBLIC_URL}/media/images/banner/Banner_Event_rrq_esport_2023.jpg`,
    link: 'https://wa.me/message/6EDYTERJ3KOKI1',
    newTab: true,
  },
];

export const Banner = styled.div`
  position: relative;
  z-index: 1;
`;

export const BannerImage = styled.img`
  width: 100%;
  z-index: 2;
`;

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step: React.SetStateAction<number>) => {
    setActiveStep(step);
  };

  return (
    <Banner>
      <Box>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <>
              {Math.abs(activeStep - index) <= 2 ? (
                <>
                  {step.newTab ? (
                    <Link href={step?.link} target="_blank">
                      <Box
                        key={step.label}
                        component="img"
                        sx={{
                          display: 'block',
                          overflow: 'hidden',
                          width: '100%',
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    </Link>
                  ) : (
                    <Box
                      key={step.label}
                      component="img"
                      sx={{
                        display: 'block',
                        overflow: 'hidden',
                        width: '100%',
                      }}
                      src={step.imgPath}
                      alt={step.label}
                      onClick={() => history.push(step?.link)}
                    />
                  )}
                </>
              ) : null}
            </>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
    </Banner>
  );
}

export default SwipeableTextMobileStepper;
