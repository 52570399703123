import GradientButton from 'components/Buttons/GradientButton';
import ScrimItem from 'components/Lists/ScrimItem';
import ScrollView from 'components/ScrollView';
import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import { dateFormat, LogDev } from 'modules/helpers';
import history from 'modules/history';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetScrimDraftAction } from 'redux/scrim/ScrimDraftReducer';
import { ScrimListResponse } from 'redux/scrim/ScrimProps';
import { fetchScrimList } from 'redux/scrim/ScrimService';
import { useAppThunkDispatch } from 'redux/store';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';

export default function ScrimListPage() {
  useTitle(`Explore Scrim | MABAR`);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const { data, status } = useAppSelector(state => state.scrimList);
  const { gameSelected } = useAppSelector(state => state.scrimDraft);
  const myCurrentTeam = getMyCurrentTeamFromSession();
  const [getScrimList, setScrimList] = React.useState<ScrimListResponse>(data);
  const isHaveTeam = myCurrentTeam && myCurrentTeam.length >= 1;
  const isCaptain =
    isHaveTeam && myCurrentTeam.find(v => v.gameId === gameSelected.id)?.level.code === 1;

  useEffect(() => {
    thunkDispatch(fetchScrimList({ gameSlug: gameSelected.urlSlug, pageIndex: 1, pageSize: 20 }))
      .unwrap()
      .then(res => {
        if (res.status >= 400) {
        } else {
          setScrimList(res.data);
        }
      })
      .catch(err => {
        LogDev('ERROR', err);
      });
  }, [thunkDispatch]);

  const goCreateScrim = () => {
    dispatch(resetScrimDraftAction());
    history.push('/create-scrim');
  };
  return (
    <>
      {getScrimList.count >= 1 ? (
        <>
          <ScrollView id="ScrimList" margin="0px 16px 80px 16px" direction="vertical">
            {getScrimList.items &&
              getScrimList.items.map(item => {
                return (
                  <ScrimItem
                    key={item.id.replace('#', '')}
                    id={item.id}
                    data={item}
                    startAt={dateFormat(item.startAt, 'Asia/Jakarta', 'id').format(
                      'dddd, DD MMM YYYY - HH:mm z',
                    )}
                    teamChallengerLogo={item.avatar}
                    teamChallengerName={item.firstTeam}
                    schemaMatch={
                      item.type.value.replace('BO3', 'Best of 3').replace('BO5', 'Best of 5') ??
                      'Unknown'
                    }
                    onScrimClick={d => {
                      history.push(`/scrim/${gameSelected.urlSlug}/${d.id}`);
                    }}
                  />
                );
              })}
          </ScrollView>
          {isCaptain && (
            <div
              style={{
                position: 'fixed',
                zIndex: 199,
                flexDirection: 'row',
                alignSelf: 'center',
                justifyContent: 'center',
                marginBottom: '75px',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                maxWidth: '500px',
                width: 'max-content',
              }}
            >
              <GradientButton
                variant="primary"
                onClick={goCreateScrim}
                padding="12px 20px 12px 20px"
                styled={{
                  backgroundSize: '100%',
                }}
              >
                {t('player_explore_scrim_create_scrim')}
              </GradientButton>
            </div>
          )}
        </>
      ) : (
        status.data !== 'loading' && (
          <Stack
            direction="column"
            align="center"
            margin="130px 0px 0px 0px"
            disableSelectText
            disableDrag
          >
            <img alt="" src={IconSource('mabar-typeface-fullwhite')} />
            <NormalText
              fontSize="14px"
              fontWeight="400"
              color="#969393"
              lineHeight="20.44px"
              textAlign="center"
              margin="24px 0px 30px 0px"
            >
              {isHaveTeam
                ? t('player_explore_scrim_empty_state')
                : t('player_explore_scrim_empty_state_team')}
              <br />
            </NormalText>
            {isCaptain && (
              <GradientButton
                variant="primary"
                onClick={goCreateScrim}
                padding="12px 20px 12px 20px"
                styled={{
                  backgroundSize: '100%',
                }}
              >
                {t('player_explore_scrim_create_scrim')}
              </GradientButton>
            )}
          </Stack>
        )
      )}
    </>
  );
}
