import { ExpandMoreProps } from '.';
import { Card } from '@mui/material';
import { styled } from '@mui/system';

export const ExpandMore = styled('div')<ExpandMoreProps>(({ theme, expand }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 0 9px 0',
  borderRadius: 0,
  width: '100%',
  ':hover': { background: 'none' },
  svg: {
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export interface TeamInfoStyledProps {
  theme?: any;
  gradient?: Boolean;
  expanded?: Boolean;
}

export const TeamInfoStyled = styled(Card)<TeamInfoStyledProps>(
  ({ theme, gradient, expanded }) => ({
    ...(gradient
      ? { backgroundImage: theme.palette.primaryGradient }
      : { backgroundColor: theme.palette.neutral700, backgroundImage: 'none' }),
    border: gradient ? '' : `1px solid ${theme.palette.neutral500}`,
    borderRadius: theme.borderRadius.lg,
    boxShadow: 'none',
    overflow: 'visible',
    padding: gradient ? '2px' : '',
    marginBottom: gradient ? '10px !important' : '',
    '.MuiCardHeader-root, .MuiCardActions-root': {
      position: 'relative',
    },
    '.MuiCardActions-root': {
      cursor: 'pointer',
      background: gradient ? 'rgba(39, 40, 43, 0.6)' : 'none',
      borderRadius: '0 0 16px 16px',
      padding: 0,
    },
    '.MuiCardHeader-avatar': {
      zIndex: 1,
    },
    '.MuiCollapse-root': {
      position: 'relative',
      zIndex: 1,
    },
    ...(gradient && {
      '.bg-gradient-dark': {
        position: 'relative',
        display: 'block',
        borderRadius: theme.borderRadius.lg,
        backgroundImage: theme.palette.primaryGradient4,
        '&:before': {
          content: `""`,
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          background: `url(${process.env.PUBLIC_URL}/media/images/bg-gradient-dark.svg) no-repeat`,
          backgroundSize: 'cover',
          borderRadius: theme.borderRadius.lg,
        },
        '&:after': {
          content: `""`,
          position: 'absolute',
          width: '200px',
          height: expanded ? '200px' : '165px',
          top: 0,
          left: 0,
          background: `url(${process.env.PUBLIC_URL}/media/images/star-trophy.png) no-repeat`,
          borderRadius: theme.borderRadius.lg,
          pointerEvents: 'none',
          transition: !expanded && '0.1s linear 0.2s',
        },
      },
      '.MuiCardActions-root:before': {
        content: `""`,
        position: 'absolute',
        width: '100%',
        height: '30px',
        bottom: '-12px',
        left: '-1px',
        right: 0,
        m: '0 auto',
        padding: '0 1px',
        background: theme.palette.primaryGradient2,
        backgroundBlendMode: 'darken, normal',
        borderRadius: '0 0 22px 22px',
        zIndex: '-1',
      },
    }),
  }),
);
