import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React from 'react';
import { ActionMeta, components } from 'react-select';
import { SelectElement, Label } from './styles';

export interface IOptions {
  readonly value: any;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export interface SelectOptionProps {
  ids?: string;
  onItemChange?: ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void) | undefined;
  placeholder?: string;
  defaultValue?: any;
  option?: readonly IOptions[];
  indicatorIcon?: string;
  margin?: string;
  width?: string;
  isRequired?: boolean;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  noOptionsMsg?: string;
  border?: string;
  inputRef?: any;
  singleValueRef?: React.RefObject<HTMLDivElement>;
  searchable?: boolean;
  isLoading?: boolean;
}

const CustomControl = (props: any) => {
  const { label } = props.selectProps;
  return (
    <components.Control {...props}>
      <Label isFloating={props.isFocused || props.hasValue}>{label}</Label>
      {props.children}
    </components.Control>
  );
};

const CustomInput = (props: any) => {
  const { inputRef } = props.selectProps;
  return (
    <components.Input {...props} ref={inputRef}>
      {props.children}
    </components.Input>
  );
};

const CustomSingleValue = (props: any) => {
  const { singleValueRef } = props.selectProps;
  return (
    <components.SingleValue {...props} ref={singleValueRef}>
      {props.children}
    </components.SingleValue>
  );
};


export function SelectOption({
  ids,
  onItemChange,
  option,
  indicatorIcon,
  placeholder,
  label,
  margin,
  defaultValue,
  width, searchable, singleValueRef, isLoading,
  isRequired, isError, errorMessage, noOptionsMsg, border, inputRef
}: SelectOptionProps) {
  return (
    <>
      <SelectElement
        label={label}
        components={{ Control: CustomControl, Input: CustomInput, SingleValue: CustomSingleValue}}
        classNamePrefix="react-select"
        id={ids} indicatorIcon={indicatorIcon} isRequired={isRequired}
        options={option}
        placeholder={placeholder}
        isSearchable={searchable}
        onChange={onItemChange}
        margin={margin}
        width={width}
        isLoading={isLoading}
        defaultValue={defaultValue}
        noOptionsMessage={(obj) => obj.inputValue = noOptionsMsg ?? 'No Options'}
        border={isError ? '1px solid #d51f40' : border}
        autoFocus={isError}
        inputRef={inputRef}
        singleValueRef={singleValueRef}
      />
      {isError && (
        <Stack
          align="start"
          direction="row"
          margin="4px 0px 0px 5px"
          disableSelectText
          display={isError ? "flex" : "none"}
        >
          <NormalText
            color='#FF4D6D'
            fontSize="12px"
            lineHeight="146%"
            fontStyle="normal"
            fontWeight="400"
          >
            {errorMessage}
          </NormalText>
        </Stack>
      )}
    </>
  );
}

export default SelectOption;
