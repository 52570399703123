import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  failedGetTeamInvitationAction,
  failedTeamInvitationModalAction,
  failedTeamInvitationSelectedAction,
  getTeamInvitationAction,
  setTeamInvitationModalAction,
  setTeamInvitationSelectedAction,
  successGetTeamInvitationAction,
  successTeamInvitationModalAction,
  successTeamInvitationSelectedAction,
  TeamInvitationResponse,
} from './TeamInvitationReducer';
import { getTeamInvitationService } from './TeamService';

interface Res<T> {
  data: T;
  status: number;
}


function* fetchSetTeamInvitationModal(action: AnyAction){
    try {
        yield call(setTeamInvitationModalAction, action.payload)
        yield put(successTeamInvitationModalAction())
    }catch(e){
        yield put(failedTeamInvitationModalAction())
    }
}

function* fetchSetTeamInviteSelected(action: AnyAction){
    try {
        yield call(setTeamInvitationSelectedAction, action.payload)
        yield put(successTeamInvitationSelectedAction())
    } catch (error) {
        yield put(failedTeamInvitationSelectedAction())
    }
}

function* fetchTeamInvitationSaga(action: AnyAction) {
  try {
    const res: Res<TeamInvitationResponse> = yield call(getTeamInvitationService, action.payload);
    if (res.status !== 200) {
      throw res;
    } else {
      yield put(successGetTeamInvitationAction(res.data));
    }
  } catch (e: any) {
    if (e?.response?.status >= 400) {
      yield put(failedGetTeamInvitationAction(e?.response?.data));
    } else {
      yield put(failedGetTeamInvitationAction({ errorCode: e?.response?.status }));
    }
  }
}

export default function* teamInvitationSaga() {
  yield takeLatest(getTeamInvitationAction.type, fetchTeamInvitationSaga);
  yield takeLatest(setTeamInvitationSelectedAction.type, fetchSetTeamInviteSelected);
  yield takeLatest(setTeamInvitationModalAction.type, fetchSetTeamInvitationModal);
}
