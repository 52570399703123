import {
  TournamentDetailResponse,
  MyTeamFromTournamentResponse,
  TournamentListResponse,
  TournamentListMyTeamResponse,
  ActiveTournamentListByPlayerResponse,
  TournamentParticipantInvitedResponse,
  MatchRound,
  TournamentParticipantPlayerResponse,
  TournamentParticipantTeamResponse,
} from './TournamentProps';
import { createAsyncThunk } from '@reduxjs/toolkit';
import errorDatas from 'data/errors.json';
import ApiService from 'services/ApiService';

export const fetchTournamentDetail = createAsyncThunk(
  'tournament/tournament-detail',
  async ({ tournamentSlug }: { tournamentSlug: string }) => {
    const url = `/event-tournament/${tournamentSlug}`;
    const res = await ApiService.get<TournamentDetailResponse>(url);
    return res.data;
  },
);

export const fetchTournamentParticipantInvited = createAsyncThunk(
  'tournament-participant/invited-tournament',
  async () => {
    const url = `/tournament-participant/invited-tournament`;
    const res = await ApiService.get<TournamentParticipantInvitedResponse>(url);
    return res.data;
  },
);

export const fetchTournamentParticipantPlayer = createAsyncThunk(
  'tournament-participant/player',
  async ({ playerId }: { playerId: string }) => {
    const url = `tournament-participant/player/${playerId}`;
    const res = await ApiService.get<TournamentParticipantPlayerResponse>(url);
    return res.data;
  },
);

export const fetchTournamentParticipantTeam = createAsyncThunk(
  'tournament-participant/team',
  async ({ teamId }: { teamId: string }) => {
    const url = `tournament-participant/team/${teamId}`;
    const res = await ApiService.get<TournamentParticipantTeamResponse>(url);
    return res.data;
  },
);

export const fetchMyTeamFromTournament = createAsyncThunk(
  'tournament-participant/myTeam',
  async ({ tournamentId, teamId }: { tournamentId: string; teamId: string }) => {
    const url = `tournament-participant/players/${tournamentId}/${teamId}`;
    const res = await ApiService.get<MyTeamFromTournamentResponse>(url);
    return res.data;
  },
);

type errorCodes = {
  [key: string]: string;
};

export const submitTournamentRegistration = createAsyncThunk(
  'tournament/submit-tournament-regist',
  async ({ tournamentId, teamId }: { tournamentId: string; teamId: string }) => {
    try {
      const url = `/tournament-participant/${tournamentId}/register/${teamId}`;
      const res = await ApiService.post(url, {});
      return res.data;
    } catch (error: any) {
      if (error.response) {
        const response = error.response;
        if (response.status == '400') {
          const data = response.data;
          if (data.errCode) {
            const errorMessage: errorCodes = errorDatas;
            return { isAxiosError: true, errors: errorMessage[data.errCode] || data.message };
          }
        }
        return { isAxiosError: true, errors: error.response.data };
      } else {
        return { isAxiosError: true, errors: error };
      }
    }
  },
);

export const submitTournamentPlayer = createAsyncThunk(
  'tournament/submit-tournament-regist',
  async ({
    tournamentId,
    teamId,
    captainId,
    nickname,
    gameProviderId,
    players,
  }: {
    tournamentId: string;
    teamId: string;
    captainId: string;
    nickname: string;
    gameProviderId: string;
    players: string[];
  }) => {
    try {
      const url = `/tournament-participant/team/members/invite`;
      const res = await ApiService.post(url, {
        tournamentId: tournamentId,
        teamId: teamId,
        captainId: captainId,
        nickname: nickname,
        gameProviderId: gameProviderId,
        players: players,
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        const response = error.response;
        if (response.status == '400') {
          const data = response.data;
          if (data.errCode) {
            const errorMessage: errorCodes = errorDatas;
            return { isAxiosError: true, errors: errorMessage[data.errCode] || data.message };
          }
        }
        return { isAxiosError: true, errors: error.response.data };
      } else {
        return { isAxiosError: true, errors: error };
      }
    }
  },
);

export const fetchTournamentList = createAsyncThunk(
  'tournament-list',
  async ({
    pageIndex,
    pageSize,
    gameId,
    typeId,
  }: {
    pageIndex: number;
    pageSize: number;
    gameId?: string;
    typeId?: number;
  }) => {
    const url = `/event-tournament/${pageIndex}/${pageSize}${gameId ? `?game_id=${gameId}` : ''}${
      typeId ? `?type=${typeId}` : ''
    }`;
    const res = await ApiService.get<TournamentListResponse>(url);
    return res.data;
  },
);

export const fetchTournamentListMyTeam = createAsyncThunk(
  'team-profile/tournament',
  async ({
    teamId,
    pageIndex,
    pageSize,
  }: {
    teamId: string;
    pageIndex: number;
    pageSize: number;
  }) => {
    const url = `/event-tournament/${teamId}/${pageIndex}/${pageSize}`;
    const res = await ApiService.get<TournamentListMyTeamResponse>(url);
    return res.data;
  },
);

export const approveTournamentInvitationMember = createAsyncThunk(
  'team-profile/tournament/member-invitation',
  async ({
    tournamentId,
    teamId,
    playerId,
    nickname,
    gameProviderId,
  }: {
    tournamentId: string;
    teamId: string;
    playerId: string;
    nickname: string;
    gameProviderId: string;
  }) => {
    try {
      const url = `/tournament-participant/team/members/accept/${tournamentId}/${teamId}`;
      const res = await ApiService.put(url, {
        tournamentId: tournamentId,
        teamId: teamId,
        playerId: playerId,
        nickname: nickname,
        gameProviderId: gameProviderId,
      });

      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);

export const postTournamentResult = createAsyncThunk(
  '/tournament-participant/result',
  async ({ matchId, result }: { matchId: string; result: MatchRound[] }) => {
    try {
      const url = `/tournament-participant/result`;
      var formData = new FormData();
      formData.append('MatchId', matchId);
      result.forEach((rest, index) => {
        formData.append(`Results[${index}].Round`, rest.round.toString());
        formData.append(`Results[${index}].isWin`, Boolean(rest.isWin).toString());
        formData.append(
          `Results[${index}].AttachmentFile`,
          rest?.attachmentFile ? rest.attachmentFile : '',
        );
      });
      const res = await ApiService.post(url, formData);

      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data, statusCode: err.response.status };
      }
    }
  },
);

export const fetchActiveTournamentListPlayer = createAsyncThunk(
  'tournament-list/player-active',
  async ({ playerId }: { playerId?: string }) => {
    const url = `/event-tournament/${playerId}/active`;
    const res = await ApiService.get<ActiveTournamentListByPlayerResponse>(url);
    return res.data;
  },
);
