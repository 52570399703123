import { createSlice } from '@reduxjs/toolkit';
import { ValueOf } from 'type-fest';
import { Status } from 'types';

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  status: ValueOf<Status>;
}

const initialState: AuthState = {
  isAuthenticated: false,
  loading: false,
  status: 'idle',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginAction: state => {
      state.loading = true;
      state.status = 'running';
    },
    registerAction: state => {
      state.loading = true;
      state.status = 'running';
    },
    logoutAction: state => {
      state.loading = true;
      state.status = 'running';
    },
    successAuthAction: (state) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.status = 'success';
    },
    failedAuthAction: state => {
      state.isAuthenticated = false;
      state.loading = false;
      state.status = 'error';
    },
  },
});

export const { loginAction, logoutAction, registerAction, successAuthAction, failedAuthAction } = authSlice.actions;
export default authSlice.reducer;
