import FAQContent from './data/FAQContent';
import SupportLayout, { SupportLayoutProps } from 'components/Layout/SupportLayout';
import { ImageSource } from 'modules/assetpath';
import { dateFormat } from 'modules/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FAQPage = () => {
  const { t } = useTranslation();
  const faqProps: SupportLayoutProps = {
    headers: {
      headerBackground: ImageSource('card.background', 'jpg'),
      title: t('player_account_other_link_faq'),
      update: t('contact_us_privacy_latest_update', {
        updateDate: dateFormat(new Date(1637773200000)).format('DD MMMM YYYY'),
      }),
    },
    content: FAQContent(),
  };
  return <SupportLayout {...faqProps} />;
};

export default FAQPage;
