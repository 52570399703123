import { Box, Stack } from '@mui/material';
import CounterCircle from 'design/Counter/Circle';
import { Text } from 'design/Text';
import React, { ReactNode } from 'react';

export interface CardTeamBattleProps {
  home: {
    name?: string;
    player?: ReactNode;
    maxPlayer: number;
    currentPlayer: number;
  };
  away: {
    name?: string;
    player?: ReactNode;
    maxPlayer: number;
    currentPlayer: number;
  };
  showCounter?: boolean;
}

export const CardTeamBattle = ({ home, away, showCounter }: CardTeamBattleProps) => {
  return (
    <Box
      sx={theme => ({
        borderRadius: '8px',
        background: theme.palette.neutral700,
        border: '1px solid #242628',
      })}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderBottom: '1px solid rgba(212, 214, 216, 0.1)', padding: '12px' }}
      >
        <Stack direction="row">
          {showCounter && (
            <CounterCircle
              maxNumber={home.maxPlayer}
              currentNumber={home.currentPlayer}
              isColor={home?.currentPlayer >= home?.maxPlayer ? true : false}
            />
          )}
          <Text variant="label" sx={{ ml: '6px' }}>
            {home?.name}
          </Text>
        </Stack>
        <Stack direction="row">
          <Text variant="label" sx={{ mr: '6px' }}>
            {away?.name}
          </Text>
          {showCounter && (
            <CounterCircle
              maxNumber={away.maxPlayer}
              currentNumber={away.currentPlayer}
              isColor={away?.currentPlayer >= away?.maxPlayer ? true : false}
            />
          )}
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ flexWrap: 'nowrap', padding: '12px 0' }}>
        <Box sx={{ width: '50%' }}>
          <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            {home?.player}
          </Stack>
        </Box>
        <Box sx={{ width: '50%' }}>
          <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            {away?.player}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
