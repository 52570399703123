import { Avatar } from 'components/styled/avatar.styled';
import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import React, { FC, ReactNode } from 'react';
import { BorderInput } from './styles';

export interface ISelectTeamProps {
  ids?: string;
  label?: ReactNode;
  visible?: boolean;
  onClick?: () => void;
}
export const SelectTeam: FC<ISelectTeamProps> = ({ ids, label, visible = true, onClick }) => (
  <BorderInput
    id={ids}
    onClick={onClick}
    style={{
      height: '52px',
      marginTop: '12px',
      display: visible ? 'flex' : 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      cursor: 'pointer',
    }}
  >
    <Stack align="between" style={{ margin: '15px' }}>
      <NormalText
        fontSize="14px"
        fontWeight="bold"
        fontStyle="normal"
        color="white"
        lineHeight="140%"
        margin="0px -2px"
      >
        {label}
      </NormalText>
      <Avatar
        src={IconSource('ic-caret-forward-small-dark')}
        alt="Forward icon"
        width="24px"
        height="24px"
        margin="-2px 2px"
        onClick={onClick}
        style={{ filter: 'invert(1)' }}
      />
    </Stack>
  </BorderInput>
);

export default SelectTeam;
