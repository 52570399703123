import { Box, Avatar, useTheme } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { CardAward } from 'design/Cards/CardAward';
import { CompetitionStatus } from 'design/Tag/CompetitionStatus';
import { ImageSource } from 'modules/assetpath';
import React, { useState, memo } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface CarouselHighlightedProps {
  dataCarousel: any;
}

const CarouselHighlighted = ({ dataCarousel }: CarouselHighlightedProps) => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step: React.SetStateAction<number>) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={theme => ({
        position: 'relative',
        p: '16px 0 24px 0',
        borderBottom: `1px solid ${theme.palette.neutral600}`,
      })}
    >
      <Avatar
        variant="square"
        src={ImageSource('bg_awards_detail', 'png')}
        sx={{
          height: '283px',
          width: '100%',
          zIndex: '-1',
          position: 'absolute',
          top: '0',
          left: '0',
          '.MuiAvatar-img': {
            objectFit: 'fill',
          },
        }}
      />
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {dataCarousel?.map((carousel: any) => (
          <CardAward
            image={carousel.logoUrl}
            label={<CompetitionStatus variant="pink" label={carousel.title} />}
            title={carousel.eventName}
            isChampion={true}
            imageSx={{
              width: '140px',
              height: '140px',
              top: '10px',
            }}
            sx={{ height: 'auto', m: '0 16px' }}
          />
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={dataCarousel ? dataCarousel?.length : 0}
        position="static"
        activeStep={activeStep}
        nextButton={''}
        backButton={''}
        sx={{
          backgroundColor: 'transparent',
          justifyContent: 'center',
          paddingTop: '11px',
          ' .MuiMobileStepper-dots': {
            alignItems: 'center',
            ' .MuiMobileStepper-dot': {
              backgroundColor: '#4E5156',
              width: '4px',
              height: '4px',
            },
            ' .MuiMobileStepper-dotActive': {
              backgroundColor: '#EAEAEC',
              width: '6px',
              height: '6px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            },
          },
        }}
      />
    </Box>
  );
};

export default memo(CarouselHighlighted);
