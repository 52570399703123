import { Box, Divider, Stack } from '@mui/material';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { DrawerBottom } from 'design/Drawer/DrawerBottom';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

interface ProfileGameProps {
  isDrawerOpen: boolean;
  handleDrawerOpen: () => void;
  handleOpenLogoutModal: () => void;
}

const AccountDrawer = ({
  isDrawerOpen,
  handleDrawerOpen,
  handleOpenLogoutModal,
}: ProfileGameProps) => {
  const accountSettings = [
    {
      icon: 'ic-account-password',
      name: 'Ubah Kata Sandi',
      title: 'Ubah Kata Sandi',
      link: '/change-password',
    },
    {
      icon: 'ic-account-faq',
      name: 'FAQ',
      title: 'FAQ',
      link: '/faq',
    },
    {
      icon: 'ic-account-term',
      name: 'Syarat & Ketentuan',
      title: 'Syarat & Ketentuan',
      link: '/term',
    },
    {
      icon: 'ic-account-privacy',
      name: 'Kebijakan Privasi',
      title: 'Kebijakan Privasi',
      link: '/privacy',
    },
    {
      icon: '',
      name: 'Logout',
      title: (
        <Text variant="body" sx={{ color: 'primaryRed400' }}>
          Logout
        </Text>
      ),
      onClick: handleOpenLogoutModal,
    },
  ];

  return (
    <DrawerBottom
      open={isDrawerOpen}
      title={<Text variant="h2">Pengaturan Akun</Text>}
      onClose={handleDrawerOpen}
      onOpen={handleDrawerOpen}
      cta={
        <Box>
          <Divider
            sx={theme => ({
              m: '8px 0',
              borderColor: 'transparent',
              backgroundImage: `repeating-linear-gradient(to right, ${theme.palette.neutral530} 0%, ${theme.palette.neutral530} 50%, ${theme.palette.neutral700} 50%, ${theme.palette.neutral700} 100%)`,
              backgroundSize: '10px 2px',
            })}
          />
          <Stack sx={{ py: '16px' }}>
            <SecondaryButton wide onClick={handleDrawerOpen}>
              Tutup
            </SecondaryButton>
          </Stack>
        </Box>
      }
      sx={{ '.drawer-content': { p: 0 } }}
    >
      <Stack spacing={1} sx={{ mt: '8px', p: '0 16px 16px 16px' }}>
        {accountSettings.map(menu => (
          <Box onClick={menu.onClick ?? undefined} sx={{ cursor: 'pointer' }}>
            <Link
              to={menu.link ?? ''}
              style={{
                textDecoration: 'none',
                pointerEvents: menu.onClick ? 'none' : 'auto',
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing="4px"
                sx={theme => ({
                  position: 'relative',
                  p: '12px',
                  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.32)',
                  borderRadius: theme.borderRadius.md,
                  zIndex: -1,
                })}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  {menu.icon && <img src={IconSource(menu.icon)} alt={menu.name} />}
                  <Text>{menu.title}</Text>
                </Stack>
                <img src={IconSource('ic-caret-forward-small-white')} alt={menu.name} width={24} />
              </Stack>
            </Link>
          </Box>
        ))}
      </Stack>
    </DrawerBottom>
  );
};

export default memo(AccountDrawer);
