import AccountPage from 'pages/Account';
import AccountCompleteData from 'pages/Account/CompleteData';
import EditProfile from 'pages/Account/EditProfile.page';
import GameEditPage from 'pages/Account/GameEditPage.page';
import VerificationCode from 'pages/Account/VerificationCode.page';
import GameRegisPage from 'pages/Account/gameregistration.page';
import PublicProfileFilled from 'pages/Account/publicProfileFilled';
import CompetitionPage from 'pages/Competition';
import AwardsDetailPage from 'pages/Competition/state/Awards/AwardsDetail';
import HighSchoolTournamentPage from 'pages/Competition/state/HighSchoolTournament';
import NationalChampionshipPage from 'pages/Competition/state/NationalChampionship';
import OthersCompetition from 'pages/Competition/state/OthersCompetition';
import AddSchool from 'pages/ContactUs/AddSchool.page';
import CreateTournament from 'pages/ContactUs/CreateTournament.page';
import ContactUsGuest from 'pages/ContactUs/Guest.page';
import JoinSchool from 'pages/ContactUs/JoinSchool.page';
import ReportCheat from 'pages/ContactUs/ReportCheat.page';
import EventDetail from 'pages/Event/Detail';
import HomePage from 'pages/Home';
import MainPage from 'pages/MainPage';
import MatchWO from 'pages/MatchDetail/MatchWO/MatchWO.page';
import ReviewByReferee from 'pages/MatchDetail/ReviewByReferee/ReviewByReferee';
import RoundFill from 'pages/MatchDetail/RoundFill/RoundFill.pages';
import WaitTeam from 'pages/MatchDetail/waitTeam.pages';
import UploadResult from 'pages/MatchUpload/matchUpload.page';
import MyTeamPage from 'pages/MyTeam';
import ChooseGamePage from 'pages/MyTeam/create/choosegame.page';
import TeamListPage from 'pages/MyTeam/create/teamlists.page';
import TeamProfilePage from 'pages/MyTeamProfile';
import ChoosePlayerTeamPage from 'pages/MyTeamProfile/create/choosePlayer';
import CreateTeamPage from 'pages/MyTeamProfile/create/teamCreate';
import UpdateTeamPage from 'pages/MyTeamProfile/edit';
import MatchChooseTeamPlayer from 'pages/MyTeamProfile/private/MatchChoosePlayer';
import PlayerProfilePage from 'pages/PlayerProfile';
import PublicProfile from 'pages/PlayerProfile/public';
import ComingSoon from 'pages/Ranking';
import ExplorePage from 'pages/Scrim';
import CreateScrim from 'pages/Scrim/Create';
import ScrimChoosePlayer from 'pages/Scrim/Create/ChoosePlayer';
import ChooseTeam from 'pages/Scrim/Create/chooseteam.pages';
import ScrimMatchDetailPage from 'pages/Scrim/ScrimMatchDetail';
import StatisticPlayerAwards from 'pages/Statistic/Player/AwardList';
import StatisticPlayerTournament from 'pages/Statistic/Player/TournamentList';
import StatisticTeamAwards from 'pages/Statistic/Team/AwardList';
import StatisticTeamTournaments from 'pages/Statistic/Team/TournamentList';
import ChangePassword from 'pages/Support/ChangePassword';
import FAQPage from 'pages/Support/FAQ';
import NotificationPage from 'pages/Support/Notification/NotificationPage';
import PrivacyPolicy from 'pages/Support/PrivacyPolicy';
import TermsAndCondition from 'pages/Support/TermAndCondition';
import TournamentPage from 'pages/Tournament';
import TournamentDetailPage from 'pages/TournamentDetail';
import TournamentMatchDetail from 'pages/TournamentDetail/state/Match/Detail';
import TournamentMatchDetailUpload from 'pages/TournamentDetail/state/Match/Detail/Upload';
import TournamentRegisterPage from 'pages/TournamentRegister/index.page';
import MatchDetail from 'pages/match/MatchDetail';
import MatchPage from 'pages/match/MatchPage';
import EmptyAccountPage from 'pages/state/EmptyAccount';
import SignRedirectCallback from 'pages/state/SignRedirectCallback';
import SignSilentCallback from 'pages/state/SignSilentCallback';
import NotFound from 'routes/NotFound';

type ComponentPagesProps = {
  [key: string]: any;
};

export const ComponentPages: ComponentPagesProps = {
  ScrimMatchDetailPage: ScrimMatchDetailPage,
  MatchDetail: MatchDetail,
  CreateScrim: CreateScrim,
  ChooseTeam: ChooseTeam,
  ScrimChoosePlayer: ScrimChoosePlayer,
  MyTeamPage: MyTeamPage,
  CreateTeamPage: CreateTeamPage,
  ChooseGamePage: ChooseGamePage,
  ChoosePlayerTeamPage: ChoosePlayerTeamPage,
  TeamListPage: TeamListPage,
  UpdateTeamPage: UpdateTeamPage,
  AccountPage: AccountPage,
  PlayerProfilePage: PlayerProfilePage,
  GameRegisPage: GameRegisPage,
  GameEditPage: GameEditPage,
  ChangePassword: ChangePassword,
  TeamProfilePage: TeamProfilePage,
  MatchChooseTeamPlayer: MatchChooseTeamPlayer,
  MatchPage: MatchPage,
  NotificationPage: NotificationPage,
  UploadResult: UploadResult,
  WaitTeam: WaitTeam,
  RoundFill: RoundFill,
  ReviewByReferee: ReviewByReferee,
  EditProfile: EditProfile,
  VerificationCode: VerificationCode,
  SignRedirectCallback: SignRedirectCallback,
  SignSilentCallback: SignSilentCallback,
  EmptyAccountPage: EmptyAccountPage,
  HomePage: HomePage,
  EventDetail: EventDetail,
  ExplorePage: ExplorePage,
  MainPage: MainPage,
  PublicProfile: PublicProfile,
  StatisticPlayerTournament: StatisticPlayerTournament,
  PublicProfileFilled: PublicProfileFilled,
  FAQPage: FAQPage,
  PrivacyPolicy: PrivacyPolicy,
  TermsAndCondition: TermsAndCondition,
  MatchWO: MatchWO,
  ComingSoon: ComingSoon,
  ContactUsGuest: ContactUsGuest,
  ReportCheat: ReportCheat,
  CreateTournament: CreateTournament,
  JoinSchool: JoinSchool,
  AddSchool: AddSchool,
  TournamentDetailPage: TournamentDetailPage,
  TournamentRegisterPage: TournamentRegisterPage,
  TournamentPage: TournamentPage,
  AccountCompleteData: AccountCompleteData,
  TournamentMatchDetail: TournamentMatchDetail,
  TournamentMatchDetailUpload: TournamentMatchDetailUpload,
  CompetitionPage: CompetitionPage,
  HighSchoolTournamentPage: HighSchoolTournamentPage,
  NationalChampionshipPage: NationalChampionshipPage,
  OthersCompetition: OthersCompetition,
  AwardsDetailPage: AwardsDetailPage,
  StatisticPlayerAwards: StatisticPlayerAwards,
  StatisticTeamAwards: StatisticTeamAwards,
  StatisticTeamTournaments: StatisticTeamTournaments,
  NotFound: NotFound,
};
