import { styled, SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { Text } from 'design/Text';

export interface ImgPreviewProps {
  sx?: SxProps<Theme>;
  theme?: Theme;
}

export const BoxPreview = styled(Box)(({ theme }: any) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '5px',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  borderRadius: theme.borderRadius.md,
  border: `1px solid ${theme.palette.neutral500}`,
  background:
    'linear-gradient(252.29deg, #010101 0%, rgba(1, 1, 1, 0.4) 16.39%, rgba(1, 1, 1, 0) 49.88%), linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.6) 10.95%, rgba(0, 0, 0, 0) 50%), #27282B',
}));

export const ImgPreview = styled('img')<ImgPreviewProps>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const TextUpload: any = styled(Text, { shouldForwardProp: props => props !== 'gradient' })<{
  gradient: boolean;
}>(({ theme, gradient }: any) => ({
  background: gradient ? theme.palette.primaryGradient : 'none',
  backgroundClip: gradient ? 'text' : 'unset',
  textFillColor: gradient ? 'transparent' : theme.palette.neutral500,
  fontFamily: theme.fontFamily.lexendDeca,
  fontWeight: 600,
  color: theme.palette.neutral500,
  margin: 0,
}));
