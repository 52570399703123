import React, { FC, ReactNode, ChangeEventHandler } from 'react';
import {
  BorderLogo,
  CardRadioStyle,
  TeamLabel,
  GameType,
  MatchTotal,
  RadioButtonWrappers,
  RadioButtonCircles,
  RadioButtonInputs,
} from './styles';

export type RadioButtonProps = {
  label?: string;
  value?: string;
  checked?: boolean;
  onChange: (value: number | string) => void | ChangeEventHandler;
  disabled?: boolean;
  children?: ReactNode;
  gameText: ReactNode;
  matchText: ReactNode;
  bg?:
    | string
    | 'cubedragon-logo'
    | 'astralis-logo'
    | 'flight-logo'
    | 'porsche-logo'
    | 'exitindo-logo';
};

export interface BorderIcon {
  bg?:
    | string
    | 'cubedragon-logo'
    | 'astralis-logo'
    | 'flight-logo'
    | 'porsche-logo'
    | 'exitindo-logo';
  onClick?: () => void;
}
export const Border: FC<BorderIcon> = ({ bg }) => <BorderLogo bg={bg}></BorderLogo>;

export const CardRadio = ({
  gameText,
  children,
  matchText,
  value,
  checked,
  onChange,
  bg,
}: RadioButtonProps) => {
  return (
    <CardRadioStyle onClick={() => onChange(value ?? '1')}>
      <GameType>{gameText}</GameType>
      <TeamLabel>{children}</TeamLabel>
      <MatchTotal>{matchText}</MatchTotal>
      <RadioButtonWrappers>
        <RadioButtonInputs
          type="radio"
          name="radio"
          value={value}
          checked={checked}
        />
        <RadioButtonCircles />
      </RadioButtonWrappers>
      <Border bg={bg}></Border>
    </CardRadioStyle>
  );
};
