import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

export interface SeasonResponse {
    name:         string;
    split:        string;
    startAt:      Date;
    endAt:        Date;
}

export interface SeasonListResponse {
  name:         string;
  code:        string;
  startAt:      Date;
  endAt:        Date;
}

export interface SeasonState {
  currentSeason: SeasonResponse | undefined;
  seasonList: SeasonListResponse[] | undefined;
  status: {
    currentSeason: 'idle' | 'loading' | 'succeeded' | 'failed';
    seasonList: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
  error: {
    currentSeason: string | undefined;
    seasonList: string | undefined;
  };
}

const initialState: SeasonState = {
  currentSeason: undefined,
  seasonList: undefined,
  status: {
    currentSeason: 'idle',
    seasonList: 'idle',
  },
  error: {
    currentSeason: undefined,
    seasonList: undefined,
  },
};

export const fetchSeasonCurrent = createAsyncThunk(
  'season/current',
  async () => {
    const url = `/setting-api/season/current`;
    const res = await ApiService.get(url);
    return res.data;
  },
);

export const fetchSeasonList = createAsyncThunk(
  'season',
  async ()=> {
    const url = '/setting-api/season';
    const res = await ApiService.get(url);
    return res.data;
  },
);

const SeasonSlice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    resetSeason: () => initialState,
    resetSeasonCurrent: state => {
      state.currentSeason = initialState.currentSeason;
    },
    resetSeasonList: state => {
      state.seasonList = initialState.seasonList;
    },
  },
  extraReducers: builder => {
      builder.addCase(fetchSeasonCurrent.pending, (state, _action) => {
        state.status.currentSeason = 'loading';
      })
      .addCase(fetchSeasonCurrent.fulfilled, (state, action) => {
        state.status.currentSeason = 'succeeded';
        state.currentSeason = action.payload.data;
      })
      .addCase(fetchSeasonCurrent.rejected, (state, action) => {
        state.error.currentSeason = action?.error?.message ?? action?.error?.stack ?? '';
        state.status.currentSeason = 'failed';
      })
      .addCase(fetchSeasonList.pending, (state, _action) => {
        state.status.seasonList = 'loading';
      })
      .addCase(fetchSeasonList.fulfilled, (state, action) => {
        state.status.seasonList = 'succeeded';
        state.seasonList = action.payload.seasons;
      })
      .addCase(fetchSeasonList.rejected, (state, action) => {
        state.error.seasonList = action?.error?.message ?? action?.error?.stack ?? '';
        state.status.seasonList = 'failed';
      });
    },
});

export const { resetSeason, resetSeasonCurrent, resetSeasonList } =
  SeasonSlice.actions;

export default SeasonSlice.reducer;
