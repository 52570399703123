import { Stack } from '@mui/material';
import GradientButton from 'components/Buttons/GradientButton';
import { NormalText } from 'components/styled/text.styled';
import { LayoutContext } from 'context/LayoutContext';
import { STATUS } from 'literals';
import { IconSource, ImageSource } from 'modules/assetpath';
import React, { useEffect, useContext } from 'react';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { loginAction, registerAction } from 'redux/auth/AuthReducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import theme from 'theme';

const EmptyAccountPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const height = use100vh();

  const { status } = useAppSelector(({ auth }) => ({ status: auth.status }));

  const handleClickLogin = () => {
    dispatch(loginAction());
  };

  const handleClickRegister = () => {
    dispatch(registerAction());
  };

  const heightContent = height! - (theme.headerHeight + theme.bottomBarHeight + 2);

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: 'MABAR',
      background: { image: ImageSource('bg-myteam', 'png'), opacity: '0.5' },
      appBar: {
        logo: true,
      },
      padding: '0px',
      bottomBar: {
        show: true,
      },
    });
  }, []);

  return (
    <Stack justifyContent="center" sx={{ height: heightContent }}>
      <Stack alignContent="center" alignItems="center" justifyContent="center">
        <img src={IconSource('mabar-typeface-fullwhite')} alt="mabar-typeface-fullwhite" />
      </Stack>
      <Stack
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: '30.45px' }}
      >
        <GradientButton
          key="Register"
          data-testid="Register"
          onClick={handleClickRegister}
          variant="primary"
          styled={{ backgroundSize: '100%' }}
          aria-busy={status === STATUS.RUNNING}
        >
          <span style={{ padding: '20px' }}>{t(`player_team_empty_state_register`)}</span>
        </GradientButton>
      </Stack>
      <Stack direction="row" justifyContent="center" sx={{ mt: '14.25px' }}>
        <NormalText
          color="#969393"
          textAlign="center"
          fontStyle="normal"
          fontWeight="400"
          fontSize="14px"
          lineHeight="20.44px"
        >
          {t('player_team_empty_state_message')}
        </NormalText>
        <GradientButton
          key="Login"
          data-testid="Login"
          onClick={handleClickLogin}
          variant="cta"
          aria-busy={status === STATUS.RUNNING}
          margin="0px 0px 0px 3px"
          padding="0px"
          styled={{ backgroundSize: '100%' }}
        >
          {t('player_team_empty_state_login')}
        </GradientButton>
      </Stack>
    </Stack>
  );
};

export default EmptyAccountPage;
