import React, { FC } from 'react';
import DetailPlayer, { DetailPlayerProps } from './MatchDetailInfo/DetailPlayer';
import DetailRules, { DetailRulesProps } from './MatchDetailInfo/DetailRules';

export interface MatchDetailNavType {
  component: boolean;
  detailRules: DetailRulesProps;
  detailPlayers: DetailPlayerProps;
}
const MatchDetailNav: FC<MatchDetailNavType> = ({ component, detailRules, detailPlayers }) => {
  return <>{component ? <DetailRules {...detailRules} /> : <DetailPlayer {...detailPlayers} />}</>;
};

export default MatchDetailNav;
