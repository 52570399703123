import { Stack, Avatar } from '@mui/material';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardEmptyParticipation } from 'design/Cards/CardEmptyParticipation';
import { CardSection } from 'design/Cards/CardSection';
import { CardTournamentParticipation } from 'design/Cards/CardTournamentParticipation';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { eventsType } from 'redux/tournament/TournamentProps';

interface TournamentParticipantProps {
  handleButtonRoster?: any;
  urlParams?: {
    gameSlug?: string;
    schoolSlug?: string;
    teamSlug?: string;
  };
}

const TournamentParticipant = ({ handleButtonRoster, urlParams }: TournamentParticipantProps) => {
  const { tournamentList } = useAppSelector(({ tournament }) => ({
    tournamentList: tournament?.tournamentParticipantTeam?.events,
    tournamentListStatus: tournament?.status?.tournamentParticipantTeam,
  }));

  const handleTournamentParticipantRank = (rank: String) => {
    switch (rank) {
      case '1':
        return 'Champion';
      case '2':
        return '2nd Place';
      case '3':
        return '3rd Place';
      default:
        return 'Participant';
    }
  };

  return (
    <>
      <CardSection
        title={
          <Text
            variant="label"
            component="h5"
            sx={{ textTransform: 'capitalize', letterSpacing: 0 }}
          >
            Partisipasi Turnamen
          </Text>
        }
        sx={{ bgcolor: 'transparent', mb: '16px' }}
      >
        {(tournamentList ?? []).length > 0 ? (
          <Stack direction="column" spacing={2}>
            {tournamentList?.map((event: eventsType, idx: number) =>
              idx < 3 ? (
                <CardTournamentParticipation
                  title={event?.eventName}
                  description={`${event?.season} • ${
                    event?.split
                  } • ${handleTournamentParticipantRank(event?.rank)}`}
                  cta={
                    <Stack direction="row" alignItems="center">
                      <Avatar
                        src={IconSource('ic-tim-outline')}
                        sx={{ width: '14px', height: '14px', mr: '4.67px', borderRadius: '0' }}
                      />
                      <Text
                        variant="button"
                        onClick={() => handleButtonRoster(event)}
                        style={{ cursor: 'pointer' }}
                      >
                        Lihat Roster
                      </Text>
                    </Stack>
                  }
                  sx={{ height: 'auto' }}
                />
              ) : null,
            )}

            {(tournamentList ?? []).length > 3 ? (
              <Link
                to={`/team/${urlParams?.gameSlug}/${urlParams?.schoolSlug}/${urlParams?.teamSlug}/tournaments`}
              >
                <SecondaryButton wide variant="small">
                  Lihat Semua
                </SecondaryButton>
              </Link>
            ) : null}
          </Stack>
        ) : (
          <CardEmptyParticipation
            title="Kamu belum ikut turnamen nih"
            cta={
              <Link to="/explore/tournament">
                <SecondaryButton variant="small" sx={{ whiteSpace: 'nowrap' }}>
                  Cari Turnamen
                </SecondaryButton>
              </Link>
            }
          />
        )}
      </CardSection>
    </>
  );
};

export default memo(TournamentParticipant);
