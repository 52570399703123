import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  failedGetMyTeamAction,
  getMyTeamAction,
  MyTeamResponse,
  successGetMyTeamAction,
} from './MyTeamReducer';
import { getMyTeamService } from './TeamService';

interface Res<T> {
  data: T;
  status: number;
}

function* fetchMyTeamSaga(action: AnyAction) {
  try {
    const res: Res<MyTeamResponse> = yield call(getMyTeamService, action.payload);
    if (res.status !== 200) {
      throw res;
    }else{
        yield put(successGetMyTeamAction(res.data));
    }
  } catch (e) {
    yield put(failedGetMyTeamAction({ error: e }));
  }
}

export default function* myTeamSaga() {
  yield takeLatest(getMyTeamAction.type, fetchMyTeamSaga);
}
