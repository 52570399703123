import { Avatar, Box, CardMedia, Stack } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import competitionJson from 'data/competitions/competitionLanding.json';
import hstClosedJson from 'data/competitions/highSchoolTournament/closed.json';
import hstFinishedJson from 'data/competitions/highSchoolTournament/finished.json';
import hstOpeningJson from 'data/competitions/highSchoolTournament/opening.json';
import hstUpcomingJson from 'data/competitions/highSchoolTournament/upcoming.json';
import navigationMenu from 'data/navigationMenu.json';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardBanner } from 'design/Cards/CardBanner';
import { Champion, CandidateProps } from 'design/Section/Champion';
import { News } from 'design/Section/News';
import { CompetitionStatus } from 'design/Tag/CompetitionStatus';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import { onClickShare } from 'modules/helpers';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

interface StatisticProps {
  label: string;
  value: string;
}

export interface HighSchoolTournamentProps {
  status: 'upcoming' | 'opening' | 'closed' | 'finished';
}

const HighSchoolTournamentPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [hstData, setHstData] = useState<any>();

  const handleHSTData = (status: HighSchoolTournamentProps['status']) => {
    switch (status) {
      case 'upcoming':
        return hstUpcomingJson;
      case 'opening':
        return hstOpeningJson;
      case 'closed':
        return hstClosedJson;
      case 'finished':
        return hstFinishedJson;
    }
  };

  useEffect(() => {
    setHstData(
      handleHSTData(
        competitionJson.competitionList[0].status as HighSchoolTournamentProps['status'],
      ),
    );
  }, []);

  const restrutureCandidateData: any = (data: []) => {
    return data.map((data: CandidateProps) => ({
      ...data,
      schoolLogo: `${process.env.PUBLIC_URL}/media/images/sekolah/${data.schoolLogo}.png`,
      teamLogo: `${process.env.PUBLIC_URL}/media/images/tim/${data.teamLogo}.png`,
    }));
  };

  const handleTagVariant = (status: HighSchoolTournamentProps['status']) => {
    switch (status) {
      case 'opening':
        return 'green';
      case 'closed':
        return 'pink';
      default:
        return undefined;
    }
  };

  const handleBoxGradient = (status: HighSchoolTournamentProps['status']) => {
    switch (status) {
      case 'opening':
        return 'linear-gradient(180deg, rgba(38, 189, 135, 0.2) 0%, rgba(10, 10, 11, 0) 100%)';
      case 'closed':
        return 'linear-gradient(180deg, rgba(184, 23, 30, 0.24) 0%, rgba(10, 10, 11, 0) 100%)';
      default:
        return 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(10, 10, 11, 0) 100%)';
    }
  };

  const handleLabelRegister = () => {
    if (competitionJson.competitionList[0].status === 'closed') {
      return { fontSize: '12px', color: 'neutral400' };
    }
    return { color: 'neutral350' };
  };

  const { setValue } = useContext(LayoutContext);

  const handleBack = () => {
    if (history.action === 'POP') {
      return '/competition';
    }
    return 'goback';
  };

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'MABAR High School Tournament' }),
      appBar: {
        logo: false,
        back: {
          to: handleBack(),
        },
        menu: navigationMenu,
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: t('tournament_page_title', { title: 'MABAR High School Tournament' }),
              handleIconShare: () => handleShare(),
            });
          }
        },
        background: 'linear-gradient(180deg, #1A1817 0%, rgba(10, 10, 11, 0) 100%)',
      },
      padding: '0px',
      bottomBar: {
        show: false,
      },
    });
  }, []);

  return (
    <>
      <Parallax
        translateY={[0, 25, 'easeInOut']}
        opacity={[1, 0]}
        shouldAlwaysCompleteAnimation={true}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'relative',
            mt: '-56px',
            height: '281px',
            background: `url(${ImageSource(
              'bg-section-competition',
              'png',
            )}) no-repeat center / cover`,
            '&:before': {
              content: "''",
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '40px',
              background: 'linear-gradient(360deg, #0A0A0B 0%, rgba(10, 10, 11, 0) 87.18%)',
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              '&:before': {
                content: "''",
                position: 'absolute',
                width: '74px',
                height: '74px',
                borderRadius: '50%',
                inset: 0,
                margin: 'auto',
                boxShadow: '0px 6.58824px 100.8px rgba(200, 105, 37, 0.8)',
                zIndex: 0,
              },
            }}
          >
            <Avatar
              src={ImageSource(hstData?.banner.logo, 'png')}
              alt={hstData?.banner.title}
              sx={{ position: 'relative', width: '128px', height: '128px', img: { objectFit: 'contain' } }}
            />
          </Box>
          <Text
            variant="h2"
            sx={{ fontSize: '20px', lineHeight: '28px', fontWeight: 700, mb: '4px' }}
          >
            {hstData?.banner.title}
          </Text>
          <Text variant="button" sx={{ color: 'neutral400' }}>
            {hstData?.banner.description}
          </Text>
        </Stack>
      </Parallax>

      <Stack sx={{ position: 'relative', bgcolor: 'neutral900', zIndex: 3 }}>
        <CompetitionStatus
          variant={handleTagVariant(
            competitionJson.competitionList[0].status as HighSchoolTournamentProps['status'],
          )}
          label={hstData?.tag.title}
          sx={{ top: '-10px' }}
        />

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '44px',
            background: handleBoxGradient(
              competitionJson.competitionList[0].status as HighSchoolTournamentProps['status'],
            ),
            top: '-19px',
          }}
        />

        {hstData?.cardBanner && (
          <CardBanner
            title={
              <Box component="span" sx={{ color: 'neutral150' }}>
                {hstData?.cardBanner.title}
              </Box>
            }
            description="Congratulations for all provincial Champions!"
            cta={
              <Link to={hstData?.cardBanner.link}>
                <PrimaryButton variant="small">{hstData?.cardBanner.textButton}</PrimaryButton>
              </Link>
            }
            sx={{ m: '0 16px 50px 16px' }}
          />
        )}

        <News
          title={hstData?.news1.title}
          content={
            <>
              {hstData?.news1.content.text}
              <Stack
                sx={theme => ({
                  mt: '24px',
                  flexFlow: 'row wrap',
                  minHeight: '258px',
                  bgcolor: 'neutral700',
                  borderRadius: theme.borderRadius.sm,
                  '.statistic-list': {
                    borderBottom: `1px solid ${theme.palette.neutral600}`,
                    '&:nth-last-child(1), &:nth-last-child(2)': {
                      borderBottom: 'none',
                    },
                    '&:nth-child(odd)': {
                      borderRight: `1px solid ${theme.palette.neutral600}`,
                    },
                  },
                })}
              >
                {hstData?.news1.content.statistic.map((data: StatisticProps) => (
                  <Stack
                    className="statistic-list"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: 'calc(50% - 1px)' }}
                  >
                    <Text variant="h1" gradient>
                      {data.value}
                    </Text>
                    <Text variant="label" sx={{ color: 'neutral400', letterSpacing: '0.6px' }}>
                      {data.label}
                    </Text>
                  </Stack>
                ))}
              </Stack>
            </>
          }
          sx={{ m: '0 16px 40px 16px' }}
        />

        {hstData?.champion && (
          <Champion
            title={hstData?.champion.title}
            description={hstData?.champion.description.toUpperCase()}
            candidate={restrutureCandidateData(hstData?.champion.candidate)}
            cta={
              <Link to={hstData?.champion.link}>
                <SecondaryButton variant="small" wide sx={{ outline: 0 }}>
                  {hstData?.champion.textButton}
                </SecondaryButton>
              </Link>
            }
            sx={{ mb: '40px' }}
          />
        )}

        <News
          title={hstData?.news2.title}
          content={
            <>
              <Box sx={{ pl: '16px' }}>
                <CardMedia
                  component="img"
                  src={ImageSource(hstData?.news2.content.image, 'png')}
                  alt={hstData?.news2.title}
                  sx={{ mt: '-40px', mb: '24px' }}
                />
              </Box>
              <Box sx={{ px: '16px' }}>{hstData?.news2.content.text}</Box>
            </>
          }
          cta={
            <Box sx={{ px: '16px' }}>
              <a href={hstData?.news2.link} target="_blank">
                <SecondaryButton variant="small" wide>
                  {hstData?.news2.textButton}
                </SecondaryButton>
              </a>
            </Box>
          }
          sx={{ mb: '30px', h2: { px: '16px' } }}
        />

        {hstData?.register && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={theme => ({
              position: 'sticky',
              bottom: 0,
              bgcolor: 'neutral900',
              p: '14px 16px',
              borderTop: `1px solid ${theme.palette.neutral530}`,
              pointerEvents: hstData?.register.link ? 'unset' : 'none',
              zIndex: 5,
            })}
          >
            <Text variant="body" sx={{ mr: '12px', ...handleLabelRegister() }}>
              {hstData?.register.label}
            </Text>
            <Link to={hstData?.register.link}>
              {competitionJson.competitionList[0].status === 'closed' ? (
                <SecondaryButton variant="small" sx={{ whiteSpace: 'nowrap' }}>
                  {hstData?.register.textButton}
                </SecondaryButton>
              ) : (
                <PrimaryButton
                  variant="small"
                  sx={{ whiteSpace: 'nowrap', minWidth: '100px' }}
                  disabled={hstData?.register.disabled}
                >
                  {hstData?.register.textButton}
                </PrimaryButton>
              )}
            </Link>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default HighSchoolTournamentPage;
