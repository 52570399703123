import { Box } from '@mui/material';
import { TeamPlayer } from 'design/Avatars/TeamPlayer';
import { Player } from 'design/Cards/Player';
import { TeamInfo } from 'design/Cards/TeamInfo';
import { Text } from 'design/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TournamentDetailResponse } from 'redux/tournament/TournamentProps';

interface WinnerProps {
  teams?: TournamentDetailResponse['winners'] | null;
}

const Winner = ({ teams }: WinnerProps) => {
  const { t } = useTranslation();

  return (
    <>
      {teams?.map(team => (
        <TeamInfo
          key={team.rankOrder}
          teamLogo={team.avatarFile}
          teamLabel={t('winner_label', { rank: team.rank })}
          teamName={team.teamName}
          teamLocation={team.schoolName}
          teamAvatar={
            <TeamPlayer
              player={team.players?.map(player => ({
                name: player.name,
                src: player.avatarFile,
              }))}
              // point={team.point}
            />
          }
          players={team.players
            ?.slice()
            .sort(player => (player?.isCaptain ? -1 : 1))
            .map(player => (
              <Box key={player.gameProviderId} sx={{ pl: '16px' }}>
                <Player
                  playerName={
                    <Text variant="label" sx={{ color: 'white', fontWeight: 700 }}>
                      {player.name}
                    </Text>
                  }
                  playerInfo={
                    <Text variant="bodySmall" sx={{ color: 'white', opacity: 0.7 }}>
                      @{player.nickname}, Game ID.{player.gameProviderId}
                    </Text>
                  }
                  playerPhoto={player.avatarFile}
                  isCaptain={player.isCaptain}
                />
              </Box>
            ))}
          expanded={false}
          gradient={Boolean(team.rank === '1')}
        />
      ))}
    </>
  );
};

export default Winner;
