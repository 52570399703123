import axios from 'axios';
import history from 'modules/history';
import { userManager } from './AuthService';
import { Constants } from './Constants';
import { loginAction } from 'redux/auth/AuthReducer';

const isEnvDevelopment = process.env.NODE_ENV === 'development';

function ApiService() {
  let instance = axios.create({
    baseURL: Constants.apiRoot,
    headers: {
      accept: '*/*',
      'x-api-version': '1.0',
      'Access-Control-Allow-Origin': '*',
      Vary: 'Origin',
      'Cross-Origin-Resource-Policy': 'cross-origin',
    },
  });
  userManager
    .getUser()
    .then(user => {
      if (user) {
        if (user.access_token && user.expired === false) {
          instance.defaults.headers.common['Authorization'] = `${user.token_type ?? 'Bearer'} ${
            user.access_token
          }`;
        }
      }
    })
    .catch(err => {
      if (isEnvDevelopment) console.error(err);
    });
    instance.interceptors.response.use(
      res => {
        return res;
      },
      err => {
        if (err.response.status === 401) {
          window.store.dispatch(loginAction())
        }
        if (err.response.status === 503) {
          history.push('/503')
        }
        if (err.response.status === 500) {
          history.push('/500')
        }
        return Promise.reject(err);
      },
    );
  return instance;
}

export default ApiService();
