import { styled } from '@mui/system';

export interface CardThumbnailWrapperProps {
  bgOverlay?: Boolean;
}

export const CardThumbnailWrapper = styled('div')<CardThumbnailWrapperProps>(({ bgOverlay }) => ({
  position: 'absolute',
  zIndex: -2,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  ...(bgOverlay && {
    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(47.68deg, rgba(0, 0, 0, 0.9) 52.77%, rgba(0, 0, 0, 0.6) 73.75%)',
      position: 'absolute',
      left: 0,
      bottom: 0,
      borderRadius: '8px',
    },
  }),
}));

export interface ImgThumbnailStyledProps {
  bw?: boolean;
}

export const ImgThumbnail = styled('img')<ImgThumbnailStyledProps>(() => ({
  width: '100%',
  height: '100%',
  display: 'block',
  borderRadius: '8px',
  backgroundSize: 'cover',
}));

export const CardThumbnailRedOverlay = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'linear-gradient(359.64deg, #E01515 42.22%, #EA882D 99.69%)',
  left: 0,
  bottom: 0,
  borderRadius: '8px',
  opacity: '40%',
}));
