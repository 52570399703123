import { Avatar } from 'components/styled/avatar.styled';
import { IconSource } from 'modules/assetpath';
import React, { FC, ReactNode } from 'react';
import { GButton } from './button.style';

export interface GButtonProps {
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  value?: ((string | number | readonly string[]) & string) | undefined;
  children?: ReactNode | null;
  variant: 'primary' | 'secondary' | 'cta' | 'flying' | 'discord' | 'whatsapp' | 'disabled';
  height?: string;
  width?: string;
  padding?: string;
  margin?: string;
  radius?: string;
  backgroundSize?: string;
  styled?: React.CSSProperties;
}
export const GradientButton: FC<GButtonProps> = ({
  onClick,
  type,
  value,
  children,
  variant,
  height,
  width,
  padding,
  margin,
  backgroundSize,
  styled,
}) => (
  <GButton
    onClick={onClick}
    variant={variant}
    height={height}
    width={width}
    margin={margin}
    padding={padding}
    backgroundSize={backgroundSize}
    value={value}
    type={type}
    style={styled}
  >
    {variant === 'whatsapp' || variant === 'discord' ? (
      <Avatar
        alt="btn-wa-dc"
        width="100%"
        height="24px"
        src={variant === 'whatsapp' ? IconSource('whatsapps') : IconSource('discord')}
      />
    ) : (
      children
    )}
  </GButton>
);

export default GradientButton;
