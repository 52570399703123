import { Box, Stack, Avatar } from '@mui/material';
import Fade from '@mui/material/Fade';
import StatusAnggotaTim from 'components/StatusAnggotaTim';
import dayjs from 'dayjs';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardTournamentList, CardTournamentChild } from 'design/Cards/CardTournament';
import { TournamentStatus } from 'design/Cards/CardTournament/TournamentStatus';
import { InfoModal } from 'design/Cards/InfoModal';
import { TextInput } from 'design/Forms/TextInput';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import { TOURNAMENT_TEAM_STATE, TOURNAMENT_PLAYER_STATUS } from 'dictionaries/tournament';
import { IconSource } from 'modules/assetpath';
import moment from 'moment';
import { SkeletonGroup } from 'pages/Tournament/state/TournamentList';
import { HandleTagStatusProps } from 'pages/Tournament/state/TournamentList/CardTournament';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { TournamentItemTeamProfile } from 'redux/tournament/TournamentProps';
import { approveTournamentInvitationMember } from 'redux/tournament/TournamentServices';
import {
  fetchTournamentDetail,
  fetchMyTeamFromTournament,
  fetchTournamentListMyTeam,
} from 'redux/tournament/TournamentServices';
import AuthService from 'services/AuthService';
import { APP_DATE_FORMAT } from 'store/constants/date';
import * as STATE from 'store/constants/tournament';
import { setTimeout } from 'timers';

interface ProfileTournamentList {
  teamSlug: string;
  setPageTitle: any;
  handleFetchTournamentInvited: () => void;
  setOpenLoader: (value: boolean) => void;
}

const ProfileTournamentList = ({
  teamSlug,
  setPageTitle,
  handleFetchTournamentInvited,
  setOpenLoader,
}: ProfileTournamentList) => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const dispatch = useAppThunkDispatch();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();

  const [detailFromTournamentList, setDetailFromTournamentList] =
    useState<TournamentItemTeamProfile>();

  const {
    teamDetail,
    tournamentListMyTeam,
    tournamentListMyTeamStatus,
    tournamentDetail,
    myTeamFromTournament,
    playerInfo,
  } = useAppSelector(({ account, tournament, teamProfile }) => ({
    tournamentListMyTeam: tournament?.tournamentListMyTeam,
    tournamentListMyTeamStatus: tournament?.status?.tournamentListMyTeam,
    tournamentDetail: tournament?.tournamentDetail,
    myTeamFromTournament: tournament?.myTeamFromTournament,
    playerInfo: account?.data,
    teamDetail: teamProfile?.teamDetail,
  }));

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState('');
  const [inputNickname, setInputNickname] = useState('');
  const [inputGameID, setInputGameID] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [lastWarningPopup, setLastWarningPopup] = useState(false);
  const [getErrorInputNickname, setErrorInputNickname] = useState(false);
  const [getErrorInputGameID, setErrorInputGameID] = useState(false);
  const [getErrorInputGameIDMessage, setErrorInputGameIDMessage] = useState('');

  const myRole = isAuthenticated
    ? playerInfo?.games?.find(game => game?.team?.urlSlug === teamSlug)
    : undefined;
  const isCaptain = myRole?.team?.level?.value === 'Captain';

  const handleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleFetchTournamentListMyTeam = (teamId: string) => {
    dispatch(fetchTournamentListMyTeam({ teamId: teamId, pageIndex: 1, pageSize: 100 }));
  };

  const handleFetchTournamentDetail = (tournamentSlug: string) => {
    dispatch(fetchTournamentDetail({ tournamentSlug: tournamentSlug }));
  };

  const handleFetchMyTeamFromTournament = (tournamentId: string, teamId: string) => {
    dispatch(
      fetchMyTeamFromTournament({
        tournamentId: tournamentId,
        teamId: teamId,
      }),
    );
  };

  const handleInputNumberOnlyGameID = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == '' || regex.test(e.target.value)) {
      setErrorInputGameID(false);
      setErrorInputGameIDMessage('');
      setInputGameID(e.target.value);
    } else {
      setErrorInputGameIDMessage('Hanya bisa angka saja');
      setErrorInputGameID(true);
    }
  };

  const handleFetchCallback = useCallback(() => {
    if (teamDetail) {
      handleFetchTournamentListMyTeam(teamDetail.id);
    }
  }, [teamDetail?.id]);

  useEffect(() => {
    // get lastest data from teamDetail loop from handleFetchCallback() & filter with condition
    // this method to solving double fetching
    if (teamSlug === teamDetail?.urlSlug) {
      handleFetchCallback();
    }
  }, [handleFetchCallback]);

  useEffect(() => {
    if (detailFromTournamentList) {
      handleFetchTournamentDetail(detailFromTournamentList.urlSlug);
    }
  }, [detailFromTournamentList]);

  const playerAccepted =
    myTeamFromTournament?.players?.filter(
      player => TOURNAMENT_PLAYER_STATUS[player.state!] === 'Accepted',
    ) ?? [];

  const handlePlayers =
    (TOURNAMENT_TEAM_STATE[tournamentDetail?.teamState!] === 'Joined'
      ? playerAccepted
      : myTeamFromTournament?.players) || [];

  const isMyTeamRegistered = teamDetail
    ? tournamentDetail?.teams?.filter(team => team?.id === teamDetail.id)[0]
    : false;

  const confirmNickId = () => {
    if (inputNickname != '' && inputGameID != '') {
      return Promise.all([
        setLastWarningPopup(true),
        setErrorInputNickname(false),
        setErrorInputGameID(false),
        setErrorInputGameIDMessage(''),
      ]);
    }
    if (inputNickname === '') {
      setErrorInputNickname(true);
    }
    if (inputGameID === '') {
      setErrorInputGameIDMessage('Game ID tidak boleh kosong');
      setErrorInputGameID(true);
    }

    return false;
  };

  const cancelApproveInvitation = () => {
    setOpenModalConfirm(false);
    setErrorInputNickname(false);
    setErrorInputGameID(false);
    setInputNickname('');
    setInputGameID('');
  };

  const approveInvitation = () => {
    setOpenModalConfirm(false);
    setOpenLoader(true);
    if (playerInfo && teamDetail) {
      if (inputNickname != '' || inputGameID != '') {
        setConfirmButtonDisabled(true);
        const approveInvitationThunk = approveTournamentInvitationMember({
          tournamentId: detailFromTournamentList?.id ?? '',
          teamId: teamDetail.id,
          playerId: playerInfo?.id ?? '',
          nickname: inputNickname,
          gameProviderId: inputGameID,
        });
        dispatch(approveInvitationThunk)
          .unwrap()
          .then(res => {
            if (res && res.isAxiosError && res.data?.status >= 400) {
              setOpenLoader(false);
              if (res.data.status == 401) {
                setModalErrorMsg('Harus login terlebih dahulu');
              } else if (res.data.status == 403) {
                setModalErrorMsg('Anda tidak memiliki hak untuk menerima undangan');
              } else {
                setModalErrorMsg(res.data.title);
              }

              setOpenModalError(true);
            } else {
              if (detailFromTournamentList) {
                handleFetchMyTeamFromTournament(detailFromTournamentList.id, teamDetail.id);
                handleFetchTournamentListMyTeam(teamDetail.id);
              }
              setTimeout(() => {
                handleFetchTournamentInvited();
                handleDrawerOpen();
                setOpenLoader(false);
              }, 1500);
            }

            setConfirmButtonDisabled(false);
            setLastWarningPopup(false);
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      } else {
        setModalErrorMsg('Data tidak boleh kosong');
        setLastWarningPopup(false);
        setOpenModalConfirm(false);
        setOpenModalError(true);
      }
    }
  };

  const handleStatus = ({
    state,
    teamState,
    playerState,
    startAt,
    winnerTeam,
    requireParticipantApproval,
    totalAcceptedPlayer,
    totalInvitedPlayer,
  }: HandleTagStatusProps) => {
    const now = dayjs();
    const diffDays = dayjs(startAt).diff(now, 'day');

    if (teamState == '1' && playerState == '1') {
      const countInvitedPlayer = totalInvitedPlayer ? totalInvitedPlayer : 0;
      const countAcceptedPlayer = totalAcceptedPlayer ? totalAcceptedPlayer : 0;
      const totalMember = countInvitedPlayer - countAcceptedPlayer;

      if (totalMember > 0)
        return <TournamentStatus status="warning" title={t('waiting_confirmation_member')} />;
    }

    if (diffDays <= 3 && diffDays > 0 && teamState === '2') {
      return (
        <TournamentStatus status="closed" title={t('counter_date_match', { dateLeft: diffDays })} />
      );
    }

    if (teamState === '0') {
      return <TournamentStatus status="warning" title={t('waiting_confirmation_member')} />;
    }

    if (teamState === '2') {
      return <TournamentStatus status="success" title={t('team_registered')} />;
    }

    if (teamState === '1') {
      return (
        <TournamentStatus
          status={requireParticipantApproval ? 'waiting-admin' : 'success'}
          title={requireParticipantApproval ? t('tournament_tim_menunggu_admin') : ''}
        />
      );
    }

    if (state === STATE.FINISHED && winnerTeam?.id !== '' && winnerTeam?.schoolName != '') {
      return (
        <TournamentStatus
          status="complete"
          name={winnerTeam?.schoolName}
          logo={winnerTeam?.avatarFile}
        />
      );
    }

    return false;
  };

  useEffect(() => {
    setPageTitle(
      t('mabar_page_title', {
        title: 'My Team > Tournament',
      }),
    );
  }, [setPageTitle]);

  const tournamentListData = tournamentListMyTeam?.items?.map((item, index) => {
    const linkDetail = `/tournament/${item.gameSlug}/${item.urlSlug}`;
    let returnData = {
      key: index,
      title: item?.title,
      gameSlug: item?.gameSlug,
      urlSlug: item?.urlSlug,
      banner: item?.banner,
      schedule: `${dayjs(item.startAt).format(APP_DATE_FORMAT)} - ${dayjs(item.endAt).format(
        APP_DATE_FORMAT,
      )}`,
      status: handleStatus({
        state: item.state,
        teamState: item.teamState,
        playerState: item.playerState,
        startAt: item.startAt,
        winnerTeam: item.winnerTeam,
        requireParticipantApproval: item.requireParticipantApproval,
        totalAcceptedPlayer: item.totalAcceptedPlayer,
        totalInvitedPlayer: item.totalInvitedPlayer,
      }),
      to:
        item.state == STATE.REGISTRATION_OPENED &&
        item.teamState !== '3' &&
        item.playerState === '0'
          ? undefined
          : {
              pathname: linkDetail,
              state: {
                pathname: location.pathname,
                hash: '#tournament',
              },
            },
    };

    return (
      <CardTournamentList {...returnData}>
        {item.state == STATE.REGISTRATION_OPENED &&
          item.teamState !== '3' &&
          item.playerState === '0' && (
            <CardTournamentChild>
              <Text variant="bodySmall" sx={theme => ({ color: theme.palette.neutral400 })}>
                Kapten sedang menunggu respon kamu untuk partisipasi anggota tim di turnamen ini (
                {item.totalAcceptedPlayer}/{item.totalInvitedPlayer})
              </Text>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: '12px' }}>
                <Link
                  to={{
                    pathname: linkDetail,
                    state: {
                      pathname: location.pathname,
                      hash: '#tournament',
                    },
                  }}
                >
                  <SecondaryButton variant="small" sx={{ mr: '10px' }}>
                    Detail
                  </SecondaryButton>
                </Link>
                <PrimaryButton
                  variant="small"
                  wide
                  onClick={() => {
                    setOpenModalConfirm(true);
                    setDetailFromTournamentList(item);
                  }}
                >
                  Konfirmasi Undangan
                </PrimaryButton>
              </Box>
            </CardTournamentChild>
          )}

        {item.teamState === '0' && item.playerState === '1' && !isCaptain && (
          <CardTournamentChild>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Text
                variant="bodySmall"
                sx={theme => ({ color: theme.palette.neutral150, mr: '4px' })}
              >
                Terima kasih untuk responmu
              </Text>
              <img src={IconSource('ic-thumb')} />
            </Box>
            <Text variant="bodySmall" sx={theme => ({ color: theme.palette.neutral400 })}>
              Kapten sedang menunggu konfirmasi anggota lain ({item.totalAcceptedPlayer}/
              {item.totalInvitedPlayer})
            </Text>
          </CardTournamentChild>
        )}
      </CardTournamentList>
    );
  });

  return (
    <>
      <Box>
        {tournamentListMyTeamStatus === 'loading' ? (
          <>
            <SkeletonGroup />
            <SkeletonGroup />
            <SkeletonGroup />
          </>
        ) : (
          <>
            {!tournamentListData ? (
              <Stack
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{ pt: '55px' }}
              >
                <Avatar
                  src={IconSource('ic-tournament-trophy')}
                  variant="square"
                  sx={{ width: '112px', height: '112px' }}
                />
                <Text>Belum ada turnamen yg terdaftar</Text>
                <Link to="/explore/tournament">
                  <PrimaryButton>Cari Tournament</PrimaryButton>
                </Link>
              </Stack>
            ) : (
              <>
                <Text variant="h3" sx={{ color: 'neutral200', py: '10px' }}>
                  Tournament
                </Text>
                <Stack direction="column" spacing={3}>
                  {tournamentListData}
                </Stack>
              </>
            )}
          </>
        )}
      </Box>

      <Modal show={openModalConfirm}>
        <InfoModal
          cta={
            <Box sx={{ position: 'relative', width: '100%' }}>
              <Fade in={!lastWarningPopup} timeout={{ enter: 700, exit: 0 }}>
                <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                  <SecondaryButton sx={{ mr: '16px' }} onClick={() => cancelApproveInvitation()}>
                    Nanti Saja
                  </SecondaryButton>
                  <PrimaryButton onClick={() => confirmNickId()}>Konfirmasi</PrimaryButton>
                </Stack>
              </Fade>
              <Fade in={lastWarningPopup} timeout={{ enter: 700, exit: 0 }}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ width: '100%', position: 'absolute', top: 0, right: 0 }}
                >
                  <SecondaryButton sx={{ mr: '16px' }} onClick={() => setLastWarningPopup(false)}>
                    Ubah
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => approveInvitation()}
                    disabled={confirmButtonDisabled}
                  >
                    Konfirmasi
                  </PrimaryButton>
                </Stack>
              </Fade>
            </Box>
          }
        >
          <Box
            sx={{
              minHeight: !lastWarningPopup ? '220px' : '0',
              height: lastWarningPopup ? '205px' : 'auto',
              position: 'relative',
            }}
          >
            <Fade in={!lastWarningPopup} timeout={{ enter: 700, exit: 0 }}>
              <Box sx={{ width: '100%', textAlign: 'left' }}>
                <Text variant="h2" sx={{ mb: '8px' }}>
                  Konfirmasi Identitas
                </Text>
                <Text sx={theme => ({ color: theme.palette.neutral400 })}>
                  Pastikan informasi di bawah ini sudah sesuai sebelum menyelesaikan proses
                  registrasi. Data ini akan digunakan untuk pembuatan sertifikat kamu.
                </Text>
                <Stack direction="column" spacing={2} sx={{ mt: '16px' }}>
                  <Box>
                    <TextInput
                      id="inputnickname"
                      label="Nickname Game"
                      onChange={e => {
                        setInputNickname(e.target.value);
                      }}
                      error={getErrorInputNickname}
                    />
                    {getErrorInputNickname ? (
                      <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                        Nickname tidak boleh kosong
                      </Text>
                    ) : null}
                  </Box>
                  <Box>
                    <TextInput
                      id="inputgameid"
                      label="ID Game Saya"
                      onChange={e => handleInputNumberOnlyGameID(e)}
                      value={inputGameID}
                      error={getErrorInputGameID}
                    />
                    {getErrorInputGameID ? (
                      <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                        {getErrorInputGameIDMessage}
                      </Text>
                    ) : null}
                  </Box>
                </Stack>
              </Box>
            </Fade>
            <Fade in={lastWarningPopup} timeout={{ enter: 700, exit: 0 }}>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Text variant="h2" sx={{ mb: '8px' }}>
                  &#9888; PENTING!
                </Text>
                <Text
                  sx={theme => ({
                    color: theme.palette.neutral400,
                    b: { color: theme.palette.danger600 },
                  })}
                >
                  Pastikan Nickname dan ID Game yang kamu masukkan sudah benar. Informasi ini{' '}
                  <b>tidak bisa diubah</b> ke depannya.
                </Text>
                <Box
                  sx={theme => ({
                    mt: '8px',
                    background: theme.palette.neutral900,
                    p: '0 12px',
                    borderRadius: '2px',
                  })}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ p: '14px 0', borderBottom: '1px dashed #323437' }}
                  >
                    <Text variant="ribbon" sx={{ color: 'neutral400', lineHeight: '16px' }}>
                      Nickname Game
                    </Text>
                    <Text>{inputNickname}</Text>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ p: '14px 0' }}
                  >
                    <Text variant="ribbon" sx={{ color: 'neutral400', lineHeight: '16px' }}>
                      ID Game Saya
                    </Text>
                    <Text>{inputGameID}</Text>
                  </Stack>
                </Box>
              </Box>
            </Fade>
          </Box>
        </InfoModal>
      </Modal>

      <Modal show={openModalError}>
        <InfoModal
          cta={
            <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
              <PrimaryButton onClick={() => setOpenModalError(false)}>Kembali</PrimaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%' }}>
            <Text variant="h2" mb="12px">
              Konfirmasi Gagal
            </Text>
            <Text>{modalErrorMsg}</Text>
          </Box>
        </InfoModal>
      </Modal>

      <StatusAnggotaTim
        importantInfo={{
          title: t('tournament_register_end', {
            date: moment(tournamentDetail?.closeRegAt).format('dddd, DD MMMM YYYY'),
          }),
          content: t('tournament_tim_belum_resmi'),
        }}
        myId={playerInfo?.id}
        isMyTeamInTournament={Boolean(isMyTeamRegistered)}
        tournamentState={tournamentDetail?.state}
        teamState={tournamentDetail?.teamState}
        players={handlePlayers}
        isDrawerOpen={isDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
    </>
  );
};

export default ProfileTournamentList;
