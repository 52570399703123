import { DetailContent } from './Details';
import { Stack } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { Modal } from 'design/Modal';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { registerAction } from 'redux/auth/AuthReducer';
import { useAppDispatch } from 'redux/hooks';
import { TournamentDetailResponse } from 'redux/tournament/TournamentProps';

interface StatusRegisterProps extends BoxRegisterProps {
  maxTeam?: BoxRegisterProps['maxTeam'];
  teamsAccepted?: BoxRegisterProps['teamsAccepted'];
  handleRegister?: () => void;
}

const StatusRegister = ({ maxTeam, teamsAccepted, handleRegister }: StatusRegisterProps) => {
  const { t } = useTranslation();

  const tournamentSlotIsFull = maxTeam && (teamsAccepted?.length ?? 0) >= maxTeam;
  const tournamentSlotRemaining = maxTeam && maxTeam - (teamsAccepted?.length ?? 0) <= 10;

  const TournamentSlotPenuh = () => (
    <Text variant="bodySmall" sx={{ display: 'block', width: '100%', textAlign: 'center' }}>
      {t('tournament_slot_full')}
    </Text>
  );

  if (tournamentSlotIsFull) {
    return <TournamentSlotPenuh />;
  }

  return (
    <>
      <DetailContent
        title={Boolean(maxTeam) && <Text variant="bodySmall">{t('tournament_tim_terdaftar')}</Text>}
        value={
          tournamentSlotRemaining ? (
            <Text
              variant="body"
              sx={{ color: 'primary600' }}
              dangerouslySetInnerHTML={{
                __html: t('tournament_slot_left', {
                  count: maxTeam - (teamsAccepted?.length ?? 0),
                }),
              }}
            />
          ) : (
            maxTeam && (
              <Text sx={{ color: 'neutral350' }}>{`${
                teamsAccepted?.length ?? 0
              } / ${maxTeam}`}</Text>
            )
          )
        }
      />
      <PrimaryButton
        variant="small"
        icon={IconSource('ic-arrow-right-square-black')}
        sx={{ svg: { width: '18px' } }}
        onClick={handleRegister}
      >
        {t('tournament_daftar_sekarang')}
      </PrimaryButton>
    </>
  );
};

export interface BoxRegisterProps {
  isAuthenticated?: boolean;
  registerValidation?: {
    isCaptain?: boolean;
    haveTeam?: boolean;
    rulesMatch?: boolean;
  };
  teamsAccepted?: Array<any>;
  maxTeam?: TournamentDetailResponse['maxTeam'];
  slug?: {
    gameSlug?: string;
    tournamentSlug?: string;
  };
}

const BoxRegister = ({
  isAuthenticated,
  registerValidation,
  teamsAccepted,
  maxTeam,
  slug,
}: BoxRegisterProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openModalError, setOpenModalError] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  const handleRegister = () => {
    if (isAuthenticated) {
      if (registerValidation?.isCaptain && registerValidation?.haveTeam) {
        if (registerValidation?.rulesMatch) {
          return history.push(`/tournament/${slug?.gameSlug}/${slug?.tournamentSlug}/register`);
        }
        return setOpenModalError(true);
      }
      return setOpenModalError(true);
    }
    return dispatch(registerAction());
  };

  const handleCloseModal = () => {
    setOpenModalError(false);
  };

  const handleCopyLink = () => {
    Promise.all([setOpenModalError(false), setOpenToast(true)]).then(() => {
      return navigator.clipboard.writeText(window.location.toString());
    });
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={theme => ({
        position: 'sticky',
        bottom: 0,
        minHeight: '60px',
        bgcolor: 'neutral900',
        p: '0 16px',
        borderTop: `1px solid ${theme.palette.neutral530}`,
      })}
    >
      <StatusRegister
        maxTeam={maxTeam}
        teamsAccepted={teamsAccepted}
        handleRegister={handleRegister}
      />

      <Modal show={openModalError}>
        {/* jika bukan kapten */}
        {registerValidation?.isCaptain == false && registerValidation?.haveTeam == true && (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <SecondaryButton onClick={handleCloseModal}>
                  {t('tournament_tutup')}
                </SecondaryButton>
                <PrimaryButton onClick={handleCopyLink}>
                  {t('tournament_bagikan_link')}
                </PrimaryButton>
              </Stack>
            }
          >
            <Stack textAlign="left" spacing={1}>
              <Text variant="h2">{t('tournament_ups')}</Text>
              <Text>{t('tournament_hanya_kapten_tim')}</Text>
            </Stack>
          </InfoModal>
        )}

        {/* jika tidak punya tim */}
        {registerValidation?.isCaptain == false && registerValidation?.haveTeam == false && (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <SecondaryButton onClick={handleCloseModal}>
                  {t('tournament_tutup')}
                </SecondaryButton>
                <PrimaryButton onClick={() => history.push('/my-team')}>
                  {t('tournament_buat_tim')}
                </PrimaryButton>
              </Stack>
            }
          >
            <Stack textAlign="left" spacing={1}>
              <Text variant="h2">{t('tournament_belum_ada_tim')}</Text>
              <Text>{t('tournament_mendaftarkan_diri')}</Text>
            </Stack>
          </InfoModal>
        )}

        {/* jika tidak sesuai persyaratan  */}
        {registerValidation?.isCaptain == true &&
          registerValidation?.haveTeam == true &&
          registerValidation?.rulesMatch == false && (
            <InfoModal
              cta={
                <PrimaryButton onClick={handleCloseModal}>{t('tournament_tutup')}</PrimaryButton>
              }
            >
              <Text textAlign="center">{t('tournament_tidak_sesuai_syarat')}</Text>
            </InfoModal>
          )}
      </Modal>

      <Toast show={openToast} onClose={handleCloseToast}>
        <Ribbon
          icon={IconSource('check-gameon')}
          label={
            <Text variant="body" sx={{ color: 'success900' }}>
              {t('tournament_link_copy')}
            </Text>
          }
          sx={GameOnToastStyle}
        />
      </Toast>
    </Stack>
  );
};

export default BoxRegister;
