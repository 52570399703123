import styled from 'styled-components';

export const FrameInvitation = styled.div `
  position: relative;
  background-image: url(${`${process.env.PUBLIC_URL}/media/images/dummy/background-invitation.png`});
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 140px;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 16px 0;
`
export const BackImage = styled.div `
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: right;
    background-image: url(${`${process.env.PUBLIC_URL}/media/images/dummy/invitation-bg-1.png`});
    background-repeat: no-repeat;
    border-radius : 12px;
    /* background-size: cover; */
    box-sizing: border-box;
`