import { Theme, Box, Stack } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Text } from 'design/Text';
import React, { ReactNode } from 'react';

export interface CardSectionStyledProps {
  theme: Theme;
  background?: string;
  sx?: any;
}

const CardSectionStyled: any = styled(Box, {
  name: 'card-section',
})(({ theme, background, sx }: CardSectionStyledProps) => ({
  padding: '16px',
  backgroundColor: 'neutral700',
  borderRadius: theme.borderRadius.md,
  background: `${theme.palette.neutral700} url(${background}) no-repeat right top / 90px 165px`,
  ...sx,
}));

export interface CardSectionProps {
  title: string | ReactNode;
  cta?: ReactNode;
  children: ReactNode;
  background?: string;
  sx?: SxProps<Theme>;
}

export const CardSection = ({ title, cta, children, background, sx }: CardSectionProps) => {
  return (
    <CardSectionStyled background={background} sx={sx}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: '16px' }}>
        <Text
          variant="ribbon"
          component={typeof title === 'string' ? 'h5' : 'div'}
          sx={{
            color: 'neutral300',
            fontWeight: '400',
            letterSpacing: '1px',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Text>
        {cta}
      </Stack>
      {children}
    </CardSectionStyled>
  );
};
