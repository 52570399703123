import GradientButton from 'components/Buttons/GradientButton';
import HeaderMatchDetails from 'components/cards/HeaderMatchDetails';
import PlayerCard from 'components/cards/PlayerCard';
import { TabButtonType } from 'components/layouts/TabButton';
import TabMenu from 'components/layouts/TabMenu';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { ImageSource } from 'modules/assetpath';
import { Status } from 'components/Status/index';
import { Checkbox } from 'components/Inputs/checkbox';
import React, { useState, useEffect } from 'react';
import AppLayout from 'components/layouts/AppLayout';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getScrimDetail } from 'redux/scrim/ScrimDetailReducer';
import { MatchCardType } from 'components/cards/MatchCard';
import { TeamCardType } from 'components/cards/TeamCard';
import { IconSource } from 'modules/assetpath';
import { useParams } from 'react-router-dom';
import { ContainerToast } from 'components/styled/toast.styled';
import { toast } from 'react-toastify';
import history from 'modules/history';
import { LogDev } from 'modules/helpers';


const ScrimMatchDetailPage = () => {
  //@ts-ignore
  let { scrim_id } = useParams();

  const CopyText = (playerId : string, titleToast : string) => {
    navigator.clipboard.writeText(playerId)
    toast.info(titleToast, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      });
  }
  const dispatch = useAppDispatch();
  const {data} = useAppSelector((state) => state.scrimDetail);  
  let [getDataScrim, setDataScrim] = React.useState(data);
  const [dataPlayers, setDataPlayers] = useState(getDataScrim.firstTeamPlayers);

  useEffect(() => {
    dispatch(getScrimDetail({id: scrim_id}))
  },[dispatch])

  const scrimTime = new Date(data.startAt).toLocaleTimeString('id-ID', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  const teamLeft: TeamCardType = {
    teamPosition: 'left',
    teamLogo: getDataScrim.firstTeamAvatar,
    teamName: getDataScrim.firstTeam,
    teamSchool: getDataScrim.firstTeamSchool,
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
  };
  const teamRight: TeamCardType = {
    teamPosition: 'right',
    teamLogo: getDataScrim.secondTeamAvatar,
    teamName: getDataScrim.secondTeam,
    teamSchool: getDataScrim.secondTeamSchool,
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
  };
  
  const matchResut: MatchCardType = {
    status:  scrimTime.replace(',',':'),
    matchType: 'Best of 3',
  };

  document.addEventListener('DOMContentLoaded', function() {
    setDataScrim(data)
 }, false);

  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(false);

  const DataTab: TabButtonType[] = [
    {
      id: 1,
      title: getDataScrim.firstTeam + ' (' + getDataScrim.firstTeamPlayers.length + '/5)',
      isSelected: true,
    },
    {
      id: 2,
      title: getDataScrim.secondTeam + ' (' + getDataScrim.secondTeamPlayers.length + '/5)',
      isSelected: false,
    },
  ];
  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
        },
        navright: {
          bg: 'white',
          variant: 'menu-options',
        },
        isDisable: false,
        title: `Detail Scrim ${scrim_id ? '#'+scrim_id : '' }`,
      }}
      noMargin={true}
      backgroundImage={ImageSource('app.background')}
      showModal={showModal}
      modalContent={
        <Box padding="16px" width="100%" >
          <Stack direction="column" margin="16px">
            <NormalText
              textAlign="center"
              color="#FFFFFF"
              fontSize="16px"
              fontWeight="bold"
              margin="8px 0px"
            >
              Konfirmasi Kebenaran Informasi
            </NormalText>
            <NormalText
              textAlign="left"
              color="#FFFFFF"
              fontSize="12px"
              fontWeight="bold"
              margin="8px 0px"
            >
              1. Integritas dan Kejujuran
            </NormalText>
            <NormalText
              textAlign="left"
              color="#FFFFFF"
              fontSize="12px"
              fontWeight="normal"
              margin="8px 0px"
            >
              MABAR dibangun berlandaskan atas integritas dan kejujuran, kecurangan dan pelanggaran adalah masalah serius dan dapat ditindaklanjuti.
            </NormalText>
            <NormalText
              textAlign="left"
              color="#FFFFFF"
              fontSize="12px"
              fontWeight="bold"
              margin="8px 0px"
            >
              2. Nickname Game
            </NormalText>
            <NormalText
              textAlign="left"
              color="#FFFFFF"
              fontSize="12px"
              fontWeight="normal"
              margin="8px 0px"
            >
              Hanya nickname game yang terdaftar di MABAR yang akan mendapatkan poin
            </NormalText>
            <NormalText
              textAlign="left"
              color="#FFFFFF"
              fontSize="12px"
              fontWeight="bold"
              margin="8px 0px"
            >
              3. Contoh Screenshot Hasil Pertandingan
            </NormalText>
            <img src={ImageSource('screenshot-game', 'png')} alt="" />            
            <Box margin="16px 0px 0px 0px">
              <Stack direction="column">
                <label style={{ position: 'relative', top: '19px', width: 'fit-content' }}>
                  <Checkbox
                    onChange={() => null}
                    onClick={() => setState(!state)}
                    checked={state}
                  />
                  <span style={{ marginLeft: 8, color: 'white' }}>Saya mengerti harus memberikan <br/> informasi yang benar</span>
                </label>
                <NormalText color="#FF4D6D" textAlign="right" onClick={() => history.push(`/match/mobile-legend/${scrim_id}/upload-result`)}>
                  Lanjut
                </NormalText>
              </Stack>
            </Box>
          </Stack>
        </Box>
      }
    >
      <Status
        label={<p>
          Silakan masuk ke Game Mobile Legends dan tunggu undangan
          <span style={{ color: 'yellow' }}> {getDataScrim.secondTeamCaptain} (ID. {getDataScrim.secondTeamPlayers[0]?.gameProviderId})</span> kemudian undang
          member timmu
        </p>}
        position="absolute"
        onClick={() => null}
      />
      <div style={{marginBottom: '63px'}}></div>
      <HeaderMatchDetails
        headerBackground={ImageSource('card.background')}
        teamLeft={{
          ...teamLeft,
          onWaButtonClick: () => {
            location.href = `https://wa.me/`+getDataScrim.firstTeamCaptainPhoneNumber
          },
        }}
        teamRight={{
          ...teamRight,
          onWaButtonClick: () => {
            LogDev('INFO',data.secondTeamCaptainPhoneNumber);
          },
        }}
        matchResult={matchResut}
        styled={{
          marginTop: '40px',
          padding: '0px',
          alignSelf: 'center',
          width: '100%',
        }}
      />
      <br />
      <TabMenu
        data={DataTab}
        variant="left"
        onSelected={tab => {
          tab.id === 1 ? setDataPlayers(getDataScrim.firstTeamPlayers) : setDataPlayers(getDataScrim.secondTeamPlayers);
        }}
      />
      <Box width="100%">
        <Stack direction="column" margin="0px 16px 0px 16px">
                     
        <ContainerToast
          theme='dark'
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover  
          />
          {dataPlayers.map((team, index: number) => {
            return <PlayerCard key={index} {...{
              playerName: `${team.name?? ''}`,
              playerId: `@${team.nickName?? ''}, Game ID. ${team.gameProviderId}`,
              playerPhoto: team.picture?? '',
              isCaptain: data.firstTeamCaptain === team.name || team.name === data.secondTeamCaptain? true : false,
              iconRight: IconSource('like'),
              onCopyText: () => {CopyText(team.gameProviderId, 'Game ID berhasil di salin')},
              textRight: index === 0 ? 'Salin' : ''
            }} />;
          })}
        </Stack>
      </Box>
      <Box
        padding="24px 16px"
        position="fixed"
        style={{ zIndex: 2, bottom: '0px', left: '0px', right: '0px' }}
      >
        <Stack direction="row" align="center">
          <GradientButton
            styled={{ backgroundSize: '100%' }}
            variant="primary"
            onClick={() => setShowModal(true)}
          >
            4:29 - Kirim Hasil Pertandingan
          </GradientButton>
        </Stack>
      </Box>
    </AppLayout>
  );
};

export default ScrimMatchDetailPage;
