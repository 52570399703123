import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
  RadioButtonInput,
  RadioButtonCircle,
  RadioButtonWrapper,
  RadioLabel,
  Frame,
} from './styles';

export type RadioButtonProps = {
  label?: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  checked?: boolean;
  onChange: (value: string | ReadonlyArray<string> | number | undefined) => void;
  disabled?: boolean;
  color?: string;
  id?: string;
  name?: string;
};

export const App = () => {
  const [selectedValue, setSelectedValue] = useState<string | ReadonlyArray<string> | number | undefined>('');

  const handleInputChange = (value: string | ReadonlyArray<string> | number | undefined): void => {
    setSelectedValue(value);
  };

  return (
    <>
      <RadioButton
        onChange={handleInputChange}
        label="first"
        value={selectedValue}
        checked={selectedValue === 'first'}
      />
    </>
  );
};

export const RadioButton = ({id, name, label, value, checked, onChange }: RadioButtonProps) => {
  return (
    <>
      <Frame>
        <RadioButtonWrapper>
          <RadioButtonInput
            onChange={() => onChange(value ?? '1')}
            id={id}
            name={name}
            type="radio"
            value={value}
            checked={checked}
          />
          <RadioButtonCircle />
        </RadioButtonWrapper>
        <RadioLabel>{label}</RadioLabel>
      </Frame>
    </>
  );
};

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
