import { Stack } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import React, { memo } from 'react';

interface JoinFailedModalProps {
  openJoinFailedModal: boolean;
  handleCloseJoinFailedModal: () => void;
  errorMessage: string;
}

const JoinFailedModal = ({
  openJoinFailedModal,
  handleCloseJoinFailedModal,
  errorMessage,
}: JoinFailedModalProps) => {
  return (
    <Modal show={openJoinFailedModal}>
      <InfoModal
        cta={
          <Stack direction="row" justifyContent="end" width="100%">
            <PrimaryButton onClick={handleCloseJoinFailedModal}>Tutup</PrimaryButton>
          </Stack>
        }
      >
        <Text variant="h2" sx={{ width: '100%', textAlign: 'left', mb: '8px' }}>
          Permintaan Gagal
        </Text>
        <Text sx={{ width: '100%', textAlign: 'left', mb: '8px', color: 'neutral300' }}>
          {errorMessage}
        </Text>
      </InfoModal>
    </Modal>
  );
};

export default memo(JoinFailedModal);
