import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export const PHONE_NUMBER_PATTERN = /^([0|\+[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9])*$/g;
export const OTP_CODE_PATTERN = /^[0-9]{4}$/g;
export interface FormError {
  message: string;
  type: string;
  ref: Ref;
}

export interface Ref {
  name: string;
}

export type EditProfileFormValues = {
  newPassword?: FormError;
  newEmail?: FormError;
  token?: FormError;
  currentPhoneNumber?: FormError;
  newPhoneNumber?: FormError;
  Id?: FormError;
  Picture?: FormError;
  Email?: FormError;
  PhoneNumber?: FormError;
  ProvinceCode?: FormError;
  CityCode?: FormError;
  SchoolId?: FormError;
  Generation?: FormError;
  StudentIdNumber?: FormError;
  StudentCardFile?: FormError;
};

const inputEmailValidationSchema = Yup.object().shape({
  newEmail: Yup.string()
    .required('Diperlukan email')
    .min(3, 'Email harus minimal 3 karakter')
    .email('Email tidak valid'),
});

const inputPhoneNumberValidationSchema = Yup.object().shape({
  newPhoneNumber: Yup.string()
    .required('Diperlukan nomor ponsel')
    .min(8, 'Nomor ponsel harus minimal 8 karakter')
    .max(16, 'Nomor ponsel maksimal 16 karakter')
    .matches(
      PHONE_NUMBER_PATTERN,
      'Nomor ponsel harus berupa nomor yang valid dalam format +6282212345678',
    ),
});

const inputVerifyOTPCodeValidationSchema = Yup.object().shape({
  token: Yup.string()
    .required('Diperlukan kode verifikasi')
    .min(4, 'Nomor ponsel harus minimal 4 digit')
    .max(4, 'Nomor ponsel maksimal 4 digit')
    .matches(OTP_CODE_PATTERN, 'Kode verifikasi harus berupa nomor yang valid dalam format 1234'),
});

const EditPlayerValidationSchema = Yup.object().shape({
  StudentIdNumber: Yup.string()
    .required('Wajib Diisi')
    .matches(/^[0-9]+$/, 'Harus angka'),
});

export const inputEmailFormOptions = { resolver: yupResolver(inputEmailValidationSchema) };

export const inputPhoneNumberFormOptions = {
  resolver: yupResolver(inputPhoneNumberValidationSchema),
};

export const inputOTPCodeFormOptions = {
  resolver: yupResolver(inputVerifyOTPCodeValidationSchema),
};

export const EditPlayerFormOptions = { resolver: yupResolver(EditPlayerValidationSchema) };
