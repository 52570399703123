import { Box, Stack, FormControl, RadioGroup } from '@mui/material';
import GradientButton from 'components/Buttons/GradientButton';
import { Avatar as AvatarStyle } from 'components/styled/avatar.styled';
import { Box as BoxLayout, Stack as StackLayout } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { PrimaryRedButton } from 'design/Buttons/PrimaryRedButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardMatchActivity } from 'design/Cards/CardMatchActivity';
import { DrawerBottom } from 'design/Drawer/DrawerBottom';
import { RadioList } from 'design/Forms/RadioList';
import { Modal } from 'design/Modal';
import { FilterTabs } from 'design/Tabs/Filter';
import { TagCard } from 'design/Tag/TagCard';
import { Text } from 'design/Text';
import _ from 'lodash';
import { IconSource } from 'modules/assetpath';
import { dateFormat, LogDev } from 'modules/helpers';
import history from 'modules/history';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { getPlayerProfileFromSession } from 'redux/account/AccountServices';
import { useAppSelector } from 'redux/hooks';
import { fetchSeasonList, resetSeason } from 'redux/season/slice';
import { useAppThunkDispatch } from 'redux/store';
import { MatchHistory } from 'redux/teamProfile/TeamProfileResponse';
import { fetchTeamHistory, rejectInvitation, checkIn } from 'redux/teamProfile/TeamProfileService';
import AuthService from 'services/AuthService';

interface PertandinganProps {
  gameSlug: string;
  teamSlug: string;
  setPageTitle?: any;
  setOpenLoader: (value: boolean) => void;
}

const Pertandingan = ({ gameSlug, teamSlug, setPageTitle, setOpenLoader }: PertandinganProps) => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<MatchHistory>();
  // const [checkinTimeEnd, setCheckinTimeEnd] = useState(false);
  const myProfileId = getPlayerProfileFromSession()?.id;

  const { playerInfo, teamHistory, teamHistoryStatus, teamHistoryError, teamDetail, seasonList } =
    useAppSelector(({ account, teamProfile, season }) => ({
      playerInfo: account?.data,
      teamHistory: teamProfile?.teamHistory,
      teamHistoryStatus: teamProfile?.status?.teamHistory,
      teamHistoryError: teamProfile?.error?.teamHistory,
      teamDetail: teamProfile?.teamDetail,
      seasonList: season?.seasonList,
    }));
  
  const myRole = isAuthenticated
    ? playerInfo?.games?.find(game => game?.team?.urlSlug === teamSlug)
    : undefined;
  const isCaptain = myRole?.team?.level?.value === 'Captain';

  const handleFetchTeamHistory = (
    teamSlug: string,
    pageIndex: number = 1,
    pageSize: number = 100,
  ) => {
    dispatch(
      fetchTeamHistory({
        teamSlug: teamSlug,
        pageIndex: pageIndex,
        pageSize: pageSize,
        version: '',
      }),
    );
  };

  const handleResetSeasonList = () => {
    dispatch(resetSeason());
  };

  const handleFetchSeasonList = () => {
    dispatch(fetchSeasonList());
  };

  useEffect(() => {
    Promise.all([
      handleResetSeasonList(),
      handleFetchTeamHistory(teamSlug),
      handleFetchSeasonList(),
    ]);
  }, []);

  useEffect(() => {
    if (teamHistoryError) {
      return setShowErrorModal(true);
    }
  }, [teamHistoryError]);

  const reject = async (matchId: string) => {
    setShowModal(false); // close previous modal;
    const thunk = rejectInvitation({ matchId });
    dispatch(thunk)
      .unwrap()
      .then((result: any) => {
        if (result && result.data.status >= 400) {
          setShowErrorModal(true);
        }
        handleFetchTeamHistory(teamSlug);
      })
      .catch(rejectedValueOrSerializedError => {
        LogDev('INFO', 'rejectedValueOrSerializedError', rejectedValueOrSerializedError);
      });
  };

  const checkInMatch = async (matchId: string) => {
    setShowCheckInModal(false); // close previous modal;
    const thunk = checkIn({ matchId });
    dispatch(thunk)
      .unwrap()
      .then((result: any) => {
        if (result && result.data.status >= 400) {
          setShowErrorModal(true);
          return;
        }
        history.push(`/scrims/${gameSlug}/${matchId}`);
      })
      .catch(rejectedValueOrSerializedError => {
        LogDev('INFO', 'rejectedValueOrSerializedError', rejectedValueOrSerializedError);
      });
  };

  const modalConfirmation = () => {
    return (
      <BoxLayout padding="24px 0px 24px 0px">
        <StackLayout direction="column" align="center">
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight="700"
            margin="16px 52px 0px 52px"
          >
            {t('player_scrim_match_scrim_accept_message')}
          </NormalText>
          <BoxLayout
            background="linear-gradient(295.84deg, rgba(213, 31, 64, 0.2) 22.62%, rgba(243, 112, 36, 0.2) 74.92%)"
            margin="16px 0px 0px 0px"
            style={{
              maxWidth: '500px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flex: 'none',
              padding: '0px',
              width: '100%',
              height: 'auto',
            }}
          >
            <StackLayout direction="column" align="start" padding="16px">
              <NormalText fontSize="16px" fontWeight="700" color="white" margin="0px 0px 0px 16px">
                {dateFormat(selectedMatch?.startAt, 'Asia/Jakarta', 'id').format(
                  'dddd, DD MMM YYYY - HH:mm',
                )}{' '}
                WIB
              </NormalText>
              <StackLayout direction="row" align="start">
                <AvatarStyle
                  src={selectedMatch?.firstTeamAvatar}
                  height="24px"
                  borderRadius="50%"
                  margin="16px 10px 0px 16px"
                />
                <NormalText
                  fontSize="14px"
                  fontWeight="500"
                  color="white"
                  margin="20px 0px 0px 0px"
                >
                  {t('player_scrim_match_scrim_accept_team_name', {
                    team_name: selectedMatch?.firstTeam,
                  })}
                </NormalText>
              </StackLayout>
            </StackLayout>
          </BoxLayout>

          <BoxLayout margin="16px 0px 0px 0px">
            <StackLayout direction="column" align="center">
              <GradientButton
                variant="primary"
                onClick={() => {
                  setShowModal(false); // close previous modal;
                  history.push(
                    `/team-match/choose-player/${teamSlug}/${
                      selectedMatch?.id || ''
                    }/?state=direct&game=${gameSlug}`,
                  );
                }}
                styled={{ backgroundSize: '100%' }}
              >
                {t('player_scrim_match_scrim_accept_accept_scrim')}
              </GradientButton>
              <GradientButton variant="cta" onClick={() => reject(selectedMatch?.id || '')}>
                {t('player_scrim_match_scrim_accept_reject_scrim')}
              </GradientButton>
            </StackLayout>
          </BoxLayout>
        </StackLayout>
      </BoxLayout>
    );
  };

  const modalCheckIn = (
    <BoxLayout padding="8px" width="100%">
      <StackLayout direction="column" align="center">
        <NormalText
          textAlign="center"
          color="#FFFFFF"
          fontSize="16px"
          fontWeight="700"
          margin="8px 0px"
        >
          {t('player_scrim_match_popup_checkin_title')}
        </NormalText>
        <NormalText
          textAlign="center"
          color="rgba(221, 216, 216, 1)"
          fontSize="14px"
          fontWeight="400"
          margin="8px 0px"
          lineHeight="140%"
        >
          {t('player_scrim_match_popup_checkin_message_time', {
            timeLeft: (
              <span style={{ fontSize: '14px', fontWeight: 700, color: '#FFFFFF' }}>30:00</span>
            ),
          })}
          <br /> {t('player_scrim_match_popup_checkin_message')}
        </NormalText>
        <BoxLayout width="100%" margin="16px 0px 0px 0px">
          <StackLayout direction="column" align="center">
            <GradientButton
              styled={{ backgroundSize: '100%' }}
              variant="primary"
              onClick={() => checkInMatch(selectedMatch?.id || '')}
            >
              {t('player_scrim_match_popup_checkin_button_confirm')}
            </GradientButton>

            <GradientButton variant="cta" onClick={() => setShowCheckInModal(false)}>
              {t('player_scrim_match_popup_checkin_button_cancel')}
            </GradientButton>
          </StackLayout>
        </BoxLayout>
      </StackLayout>
    </BoxLayout>
  );

  const modalError = () => {
    return (
      <BoxLayout padding="24px 0px 24px 0px">
        <StackLayout direction="column" margin="16px">
          <NormalText
            textAlign="center"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight="bold"
            margin="8px 0px"
          >
            Terjadi Kesalahan!
          </NormalText>
          <NormalText
            textAlign="center"
            color="red"
            fontSize="14px"
            fontWeight="normal"
            margin="8px 0px"
          >
            {teamHistoryError}
          </NormalText>
          <br />
          <BoxLayout padding="8px" width="100%">
            <StackLayout direction="column" align="center">
              <GradientButton
                onClick={() => setShowErrorModal(false)}
                styled={{
                  backgroundSize: '100%',
                  width: '180px',
                }}
                variant="primary"
              >
                {t('player_scrim_match_schedule_not_available_ok')}
              </GradientButton>
            </StackLayout>
          </BoxLayout>
        </StackLayout>
      </BoxLayout>
    );
  };

  const MatchEmptyPage = () => {
    return (
      <StackLayout
        align="center"
        position="relative"
        direction="column"
        margin="68px 0px 0px"
        disableDrag
        disableSelectText
      >
        <img
          style={{ backgroundRepeat: 'no-repeat' }}
          src={IconSource('ic-duel-big', 'png')}
          alt="mabar-typeface-fullwhite"
        />
        <NormalText
          color="#969393"
          textAlign="center"
          fontStyle="normal"
          fontWeight="400"
          fontSize="14px"
          margin="24px 0px"
        >
          {t('player_account_match_tournament_empty')}
        </NormalText>
        <GradientButton
          onClick={() => history.push('/explore/tournament')}
          variant="primary"
          padding="12.5px 35px 12.5px 35px"
          backgroundSize="100%"
          margin="4px 0px 0px"
        >
          {t('player_team_profile_button_search_match')}
        </GradientButton>
      </StackLayout>
    );
  };

  useEffect(() => {
    setPageTitle(
      t('mabar_page_title', {
        title: 'My Team > Match',
      }),
    );
  }, [setPageTitle]);

  // handle loading
  useEffect(() => {
    if (teamHistoryStatus === 'loading') {
      setOpenLoader(!Boolean((teamHistory?.items ?? []).length > 0));
    }
    if (teamHistoryStatus === 'succeeded') {
      setTimeout(() => setOpenLoader(false), 1000);
    }
  }, [teamHistoryStatus]);

  const handleContent = () => {
    if (teamHistoryStatus !== 'succeeded') {
      if ((teamHistory?.items ?? []).length > 0) return '';
      return 'none';
    }
    return '';
  };

  // conflict with loadmore
  // const delayArray: any = [5000, 10000, 15000, 20000, 25000, 30000, 35000];

  // useEffect(() => {
  //   if (checkinTimeEnd) {
  //     delayArray.map((time: number) => {
  //       delayExecute(time).promise.then((_msg: any) => {
  //         handleFetchTeamHistory(teamSlug, 1, (pageIndex > 1 ? pageIndex - 1 : pageIndex) * 10);
  //       });
  //     });
  //   }
  // }, [checkinTimeEnd]);

  var candidate = [
    {
      label: 'Scrim',
      value: 'Scrim',
    },
    {
      label: 'Tournament',
      value: 'Tournament',
    },
  ];

  const [menuVal, setMenuVal] = React.useState('');
  const [dropdownVal, setDropdownVal] = React.useState('');
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const [contentList, setContentList] = React.useState<MatchHistory[] | undefined>(undefined);
  const activeContent = contentList?.filter(
    val =>
      (val.status.value === 'Invitation' && val.firstTeamId != teamDetail?.id) ||
      val.status.value === 'CheckIn',
  );
  const notActiveContent = contentList?.filter(
    val =>
      val.status.value != 'Invitation' &&
      val.status.value != 'CheckIn' &&
      val.status.value != 'Open',
  );

  useEffect(() => {
    setContentList(teamHistory?.items);
  }, [teamHistory?.items]);

  const [selectorVal, setSelectorVal] = React.useState('season-all');

  const updateContentList = () => {
    if (menuVal != '' && selectorVal === 'season-all') {
      setContentList(teamHistory?.items?.filter(val => val?.kind?.value == menuVal));
    } else {
      if (seasonList && seasonList?.length > 0) {
        const result = seasonList.find(({ code }) => code === selectorVal);
        if (result && menuVal != '') {
          setContentList(
            teamHistory?.items?.filter(
              val =>
                val?.kind?.value == menuVal &&
                result?.startAt <= val.startAt &&
                val.startAt <= result?.endAt,
            ),
          );
        } else if (result) {
          setContentList(
            teamHistory?.items?.filter(
              val => result?.startAt <= val.startAt && val.startAt <= result?.endAt,
            ),
          );
        } else {
          setContentList(teamHistory?.items);
        }
      }
    }
  };

  useEffect(() => {
    updateContentList();
  }, [menuVal]);

  const handleSelectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectorVal((event.target as HTMLInputElement).value);
  };

  const onCLickSelectorSeason = () => {
    if (seasonList && seasonList?.length > 0) {
      const result = seasonList.find(({ code }) => code === selectorVal);
      if (result) {
        setDropdownVal(result.name);
      } else {
        setDropdownVal('');
      }
      updateContentList();
    }
    handleOpen();
  };

  const matchActivityContent = (contentList: MatchHistory[]) => {
    return (
      <Stack spacing="18px">
        {contentList != undefined &&
          contentList.map(function (data) {
            if (data.status.value === 'Scheduled') {
              var statusLabel = <TagCard title="Segera" />;
              var isActive = false;
            } else if (data.status.value === 'CheckIn') {
              var statusLabel = <></>;
              var isActive = true;
            } else if (data.status.value === 'ResultUpload') {
              var statusLabel = <TagCard title="Berlangsung" />;
              var isActive = false;
            } else if (data.status.value === 'Compete') {
              var statusLabel = <TagCard title="Berlangsung" />;
              var isActive = false;
            } else if (data.status.value === 'Dispute') {
              var statusLabel = <TagCard title="Dalam Validasi" />;
              var isActive = false;
            } else if (data.status.value === 'ResultCalculated') {
              var statusLabel = <TagCard title="Selesai" color="success500" />;
              var isActive = false;
            } else if (data.status.value === 'NotValid') {
              var statusLabel = <TagCard title="Tidak Valid" color="primaryRed250" />;
              var isActive = false;
            } else if (
              data?.status?.value === 'DataCompleted' &&
              data?.resultType?.value === 'Walkover'
            ) {
              var statusLabel = <TagCard title="Walk-out" />;
              var isActive = false;
            } else if (data.status.value === 'DataCompleted') {
              var statusLabel = <TagCard title="Selesai" color="success500" />;
              var isActive = false;
            } else if (data.status.value === 'Expired') {
              var statusLabel = <TagCard title="Expired" />;
              var isActive = false;
            } else if (data.status.value === 'Reject') {
              var statusLabel = <TagCard title="Rejected" color="primaryRed250" />;
              var isActive = false;
            } else if (data.status.value === 'Open') {
              var statusLabel = <></>;
              var isActive = false;
            } else if (data?.secondTeam == undefined || !data.secondTeam) {
              var statusLabel = <TagCard title="Win - BYE" color="success500" />;
              var isActive = false;
            } else {
              var statusLabel = <></>;
              var isActive = false;
            }

            var teamData =
              data.status.value === 'Open'
                ? []
                : data.status.value === 'Invitation'
                ? data.firstTeamId == teamDetail?.id
                  ? [
                      {
                        logo: data.secondTeamAvatar,
                        name: data.secondTeam,
                        school: data.secondTeamSchoolName,
                      },
                    ]
                  : [
                      {
                        logo: data.firstTeamAvatar,
                        name: data.firstTeam,
                        school: data.firstTeamSchoolName,
                      },
                    ]
                : data?.secondTeam == undefined || !data.secondTeam
                ? [
                    {
                      logo: data.secondTeamAvatar,
                      name: data.secondTeam,
                      school: data.secondTeamSchoolName,
                    },
                  ]
                : [
                    {
                      logo: data.firstTeamAvatar,
                      name: data.firstTeam,
                      school: data.firstTeamSchoolName,
                      score:
                        data.status.value === 'ResultCalculated' ||
                        data.status.value === 'DataCompleted' ? (
                          <Text
                            variant="h2"
                            sx={{
                              color:
                                data.firstTeamId == teamDetail?.id
                                  ? data.firstTeamScore > data.secondTeamScore
                                    ? 'success500'
                                    : data.firstTeamScore != data.secondTeamScore
                                    ? 'danger500'
                                    : ''
                                  : '',
                            }}
                          >
                            {data.firstTeamScore}
                          </Text>
                        ) : (
                          ''
                        ),
                    },
                    {
                      logo: data.secondTeamAvatar,
                      name: data.secondTeam,
                      school: data.secondTeamSchoolName,
                      score:
                        data.status.value === 'ResultCalculated' ||
                        data.status.value === 'DataCompleted' ? (
                          <Text
                            variant="h2"
                            sx={{
                              color:
                                data.secondTeamId == teamDetail?.id
                                  ? data.secondTeamScore > data.firstTeamScore
                                    ? 'success500'
                                    : data.firstTeamScore != data.secondTeamScore
                                    ? 'danger500'
                                    : ''
                                  : '',
                            }}
                          >
                            {data.secondTeamScore}
                          </Text>
                        ) : (
                          ''
                        ),
                    },
                  ];
            if (data.kind.key === '2') {
              var ctaData =
                data.status.value === 'CheckIn' || data.status.value === 'Scheduled' ? (
                  isCaptain ? (
                    <>
                      <PrimaryRedButton
                        variant="small"
                        disabled={data.status.value === 'CheckIn' ? false : true}
                        onClick={() =>
                          history.push(
                            `/tournament/${gameSlug}/${data?.eventSlug}/match/${data?.id}`,
                          )
                        }
                      >
                        Check In
                      </PrimaryRedButton>
                      <Text
                        variant="bodySmall"
                        sx={{
                          color: 'neutral400',
                          mt: '4px',
                          whiteSpace: 'nowrap',
                          minWidth: '87px',
                        }}
                      >
                        {data.status.value === 'CheckIn' ? (
                          <Countdown
                            date={data.checkInEndAt}
                            autoStart
                            intervalDelay={0}
                            renderer={({ minutes }) => minutes > 0 && `${minutes} menit tersisa`}
                          />
                        ) : (
                          'Check In belum dibuka'
                        )}
                      </Text>
                    </>
                  ) : (
                    <>
                      <PrimaryRedButton variant="small" disabled>
                        Check In
                      </PrimaryRedButton>
                      <Text
                        variant="bodySmall"
                        sx={{ color: 'neutral400', mt: '4px', whiteSpace: 'nowrap' }}
                      >
                        {data.players?.indexOf(myProfileId) > -1
                          ? t('player_team_profile_scrim_approval_captain')
                          : 'Kamu bukan peserta'}
                      </Text>
                    </>
                  )
                ) : (
                  <></>
                );
              const isLinkCard = () => {
                if (data.status.value === 'CheckIn' || data.status.value === 'WinBye') return null;

                return history.push({
                  pathname: `/tournament/${gameSlug}/${data?.eventSlug}/match/${data?.id}`,
                  state: { pathname: location.pathname },
                });
              };

              return (
                <>
                  <CardMatchActivity
                    type="tournament"
                    title={data.eventName ? data.eventName : ''}
                    description={
                      data.round
                        ? `${data.round} • ${dateFormat(data.startAt).format(
                            'DD MMM yyyy, HH:mm z',
                          )}`
                        : `${dateFormat(data.startAt).format('DD MMM yyyy, HH:mm z')}`
                    }
                    statusLabel={statusLabel}
                    team={teamData}
                    cta={ctaData}
                    active={isActive}
                    onClick={() => {
                      isLinkCard();
                    }}
                  />
                </>
              );
            } else {
              var ctaData =
                data.status.value === 'Invitation' && data.firstTeamId != teamDetail?.id ? (
                  isCaptain ? (
                    <>
                      <Stack direction="row" spacing="7px">
                        <SecondaryButton
                          variant="small"
                          onClick={() => history.push(`/scrim/${gameSlug}/${data.id}`)}
                        >
                          Detail
                        </SecondaryButton>
                        <PrimaryRedButton
                          variant="small"
                          onClick={() => {
                            setSelectedMatch(data);
                            setShowModal(true);
                          }}
                        >
                          Terima
                        </PrimaryRedButton>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Text
                        variant="bodySmall"
                        sx={{ color: 'neutral400', mt: '4px', whiteSpace: 'nowrap' }}
                      >
                        {t('player_team_profile_scrim_approval_captain')}
                      </Text>
                    </>
                  )
                ) : data.status.value === 'Scheduled' || data.status.value === 'CheckIn' ? (
                  data.players?.indexOf(myProfileId) > -1 ? (
                    <>
                      <PrimaryRedButton
                        variant="small"
                        disabled={data.status.value === 'CheckIn' ? false : true}
                        onClick={() => {
                          history.push(`/scrims/${gameSlug}/${data.id}`);
                        }}
                      >
                        Check In
                      </PrimaryRedButton>
                      <Text
                        variant="bodySmall"
                        sx={{
                          color: 'neutral400',
                          mt: '4px',
                          whiteSpace: 'nowrap',
                          minWidth: '87px',
                        }}
                      >
                        {data.status.value === 'CheckIn' ? (
                          <Countdown
                            date={data.checkInEndAt}
                            autoStart
                            intervalDelay={0}
                            renderer={({ minutes }) => minutes > 0 && `${minutes} menit tersisa`}
                          />
                        ) : (
                          'Check In belum dibuka'
                        )}
                      </Text>
                    </>
                  ) : (
                    <>
                      <PrimaryRedButton variant="small" disabled>
                        Check In
                      </PrimaryRedButton>
                      <Text
                        variant="bodySmall"
                        sx={{ color: 'neutral400', mt: '4px', whiteSpace: 'nowrap' }}
                      >
                        Kamu bukan peserta
                      </Text>
                    </>
                  )
                ) : data.status.value === 'Open' || data.status.value === 'Invitation' ? (
                  <>
                    <SecondaryButton variant="small">Batal</SecondaryButton>
                  </>
                ) : (
                  <></>
                );
              const isLinkCard = () => {
                if (
                  data.status.value === 'Invitation' ||
                  data.status.value === 'CheckIn' ||
                  data.status.value === 'WinBye' ||
                  data.status.value === 'Reject'
                ) {
                  return null;
                } else if (data.status.value === 'Open') {
                  return history.push({
                    pathname: `/scrim/${gameSlug}/${data.id}`,
                    state: { pathname: location.pathname },
                  });
                } else {
                  return history.push({
                    pathname: `/scrims/${gameSlug}/${data.id}`,
                    state: { pathname: location.pathname },
                  });
                }
              };

              var scrimTitle =
                data.status.value === 'Open'
                  ? 'Open Scrim'
                  : data.status.value === 'Invitation'
                  ? 'Scrim Invitation'
                  : 'Scrim Match';
              return (
                <>
                  <CardMatchActivity
                    type="scrim"
                    title={scrimTitle}
                    description={dateFormat(data.startAt).format('DD MMM yyyy, HH:mm z')}
                    statusLabel={statusLabel}
                    team={teamData}
                    cta={ctaData}
                    active={isActive}
                    onClick={() => {
                      isLinkCard();
                    }}
                  />
                </>
              );
            }
          })}
      </Stack>
    );
  };

  return (
    <Stack>
      <FilterTabs
        menus={candidate}
        menuValue={menuVal}
        SetMenuValue={setMenuVal}
        dropdownValue={dropdownVal}
        dropdownOnClick={() => handleOpen()}
      />
      {contentList != undefined ? (
        <Box sx={{ display: handleContent(), p: '11px 16px 24px' }}>
          {activeContent && activeContent?.length >= 1 && (
            <Stack sx={{ mb: '33px' }}>
              <Stack direction="row" sx={{ mb: '17px' }}>
                <Text variant="label">Menunggu Respon</Text>
                <Box
                  sx={{
                    background: '#27282B',
                    borderRadius: '2px',
                    fontFamily: 'Lexend Deca',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '15px',
                    textAlign: 'center',
                    letterSpacing: '0.08em',
                    textTransform: 'uppercase',
                    color: '#6B6E72',
                    p: '0 2px',
                    height: '15px',
                    ml: '4px',
                  }}
                >
                  {activeContent.length}
                </Box>
              </Stack>
              {activeContent && matchActivityContent(activeContent)}
            </Stack>
          )}

          <Stack>
            <Text variant="label" sx={{ mb: '17px' }}>
              Matches
            </Text>
            {notActiveContent && notActiveContent?.length > 0 ? (
              matchActivityContent(notActiveContent)
            ) : (
              <MatchEmptyPage/>
            )}
          </Stack>
        </Box>
      ) : (
        <MatchEmptyPage />
      )}

      <DrawerBottom
        open={isOpen}
        title={<Text variant="h2">Periode Match</Text>}
        onClose={handleOpen}
        onOpen={handleOpen}
        cta={
          <Stack spacing="20px" sx={{ py: '20px' }}>
            <PrimaryRedButton
              sx={theme => ({ borderRadius: theme.borderRadius.xs })}
              wide
              onClick={onCLickSelectorSeason}
            >
              Terapkan
            </PrimaryRedButton>
          </Stack>
        }
        sx={theme => ({
          '.drawer-title > div': {
            position: 'relative',
            '&::before': {
              content: "''",
              position: 'absolute',
              bottom: '-16px',
              left: 0,
              height: '1px',
              width: '100%',
              backgroundImage: `repeating-linear-gradient(to right, ${theme.palette.neutral530} 0%, ${theme.palette.neutral530} 50%, ${theme.palette.neutral700} 50%, ${theme.palette.neutral700} 100%)`,
              backgroundSize: '10px 2px',
            },
          },
        })}
      >
        <Stack justifyContent="center" alignItems="center">
          <FormControl>
            <RadioGroup row name="season" defaultValue="season-all" onChange={handleSelectorChange}>
              <RadioList value="season-all" title="Semua Periode" />
              {seasonList &&
                seasonList.length > 0 &&
                seasonList
                .slice()
                .sort((a, b) => (a.startAt < b.startAt ? 1 : -1))
                .map(function (data) {
                  return (
                    <RadioList
                      value={data.code}
                      title={data.name}
                      description={
                        dateFormat(data.startAt, 'Asia/Jakarta', 'id').format('DD MMM yyyy') +
                        ' - ' +
                        dateFormat(data.endAt, 'Asia/Jakarta', 'id').format('DD MMM yyyy')
                      }
                    />
                  );
                })}
            </RadioGroup>
          </FormControl>
        </Stack>
      </DrawerBottom>

      <Modal show={showModal}>{modalConfirmation()}</Modal>
      <Modal show={showErrorModal}>{modalError()}</Modal>
      <Modal show={showCheckInModal}>{modalCheckIn}</Modal>
    </Stack>
  );
};
export default Pertandingan;
