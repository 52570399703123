import { BoxPreview, ImgPreview } from './styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import imageCompression from 'browser-image-compression';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode, useRef, useState, useEffect } from 'react';
import { RefCallBack } from 'react-hook-form';
import { FILE_SIZE_LIMIT, FILE_TYPE_ALLOWED } from 'store/constants/uploadImage';

export interface UploadImageProps {
  imageAccept?: string;
  defaultImage?: any;
  fileSizeLimit?: number;
  onImageLoad?: ((this: FileReader, ev: ProgressEvent<FileReader>) => any) | null;
  getImageBlob?: (value: Blob) => void;
  getImageSource?: (value: string) => void;
  onImageChange?: (value: Blob) => void;
  isRequired?: boolean;
  variant?: 'icon' | 'iconCircle';
  title?: ReactNode;
  subtitle?: string;
  cta?: ReactNode;
  inputRef?: RefCallBack;
  getError?: (value: string | boolean) => void;
}

export const UploadImage = ({
  imageAccept = FILE_TYPE_ALLOWED,
  defaultImage,
  fileSizeLimit = FILE_SIZE_LIMIT,
  onImageLoad,
  getImageBlob,
  getImageSource,
  onImageChange,
  isRequired,
  variant,
  title = (variant=='icon' || variant=='iconCircle' ) ? 'Upload Logo Tim' : 'Upload Kartu Pelajar / Rapor Nilai / Halaman Depan Rapor',
  subtitle = 'Format file JPG, JPEG, PNG. Ukuran file maksimal 20 MB.',
  cta,
  inputRef,
  getError,
}: UploadImageProps) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [isCompressing, setIsCompressing] = useState(false);
  if (inputRef) inputRef(inputFile);
  const [getDefaultImage, setDefaultImage] = useState(defaultImage);
  const fileTypeList = imageAccept?.replace(' ', '').toLowerCase().split(',');
  const [validationText, setValidationText] = useState('');

  const onButtonClick = (e: any) => {
    e.preventDefault();
    inputFile?.current!!.click();
    if (inputRef) inputRef(inputFile);
  };

  const handleFileUpload = async (e: any) => {
    setValidationText('');
    try {
      const { files } = e.target;
      if (files && files.length) {
        var fileSize = files[0].size / 1024 / 1024; // MB
        var fileType = files[0].name.substring(
          files[0]?.name.lastIndexOf('.') + 1,
          files[0]?.name.length,
        );

        if (fileTypeList?.includes(fileType.toLowerCase())) {
          if (fileSize <= fileSizeLimit) {
            imageCompression(files[0], {
              maxSizeMB: fileSizeLimit - 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
              fileType: 'image/png',
              initialQuality: 0.98,
              onProgress: () => {
                setIsCompressing(true);
              },
            })
              .then(compressedFile => {
                setIsCompressing(false);

                if (onImageChange) {
                  onImageChange(compressedFile);
                }

                var imageReader = new FileReader();
                imageReader.readAsBinaryString(compressedFile);

                if (getImageBlob) {
                  getImageBlob(compressedFile);
                }
                if (onImageLoad) {
                  imageReader.onloadstart = onImageLoad;
                }

                imageReader.onloadend = function () {
                  const objectUrl = URL.createObjectURL(compressedFile);
                  setDefaultImage(objectUrl);

                  if (getImageSource) {
                    var iReader = new FileReader();
                    iReader.readAsDataURL(compressedFile);
                    iReader.onload = function (ev) {
                      getImageSource(ev.target?.result?.toString()!!);
                    };
                  }
                  setIsCompressing(false);

                  if (getError) getError(false);

                  return () => URL.revokeObjectURL(objectUrl);
                };

                imageReader.onerror = function (ev: any) {
                  setValidationText(ev.target?.error?.message);
                  if (getError) getError(ev.target?.error?.message);
                };
              })
              .catch((err: Error) => {
                setIsCompressing(false);
                if (err.message) {
                  setValidationText(err.message);
                  if (getError) getError(err.message);
                }
              });
          } else {
            setValidationText('Gagal upload! Ukuran file terlalu besar');
            if (getError) getError('Gagal upload! Ukuran file terlalu besar');
          }
        } else {
          setValidationText(`Gagal upload! Format file tidak didukung`);
          if (getError) getError('Gagal upload! Format file tidak didukung');
        }
      }
    } catch (err: any) {
      setIsCompressing(false);
      if (err.message) {
        setValidationText(err.message);
      }
    }
  };

  useEffect(() => {
    if (getDefaultImage) {
      srcToFile(getDefaultImage, 'mabar-default-image.png', 'image/png')
        .then(v => {
          if (getImageBlob) getImageBlob(v);
        })
        .catch(() => {});
    }
  }, [getDefaultImage]);

  if(variant=='icon' || variant=='iconCircle' ){
    return (
      <Box>
        <input
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          ref={inputFile}
          onChange={async e => {
            await handleFileUpload(e);
          }}
          hidden
          required={isRequired}
        />
        <Box
          sx={theme => ({
            background: theme.palette.neutral600,
            borderRadius: theme.borderRadius.md,
            padding: '12px',
            width: '100%',
            textAlign: 'center',
            boxSizing: 'border-box',
            position: 'relative',
            height: '226px',
            ...(validationText != '' && {
              border: '1px solid #D02B20',
            }),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <Stack direction="column" alignItems="center" justifyContent="center">
            {getDefaultImage ? (
              <Avatar
                sx={{
                  bgcolor: 'white',
                  width: variant=='iconCircle' ? '88px' : '86px',
                  height: variant=='iconCircle' ? '88px' : '86px',
                  border: variant=='iconCircle' ? '1px solid #2E3033' : '2px solid #F1EBEB',
                  borderRadius: variant=='iconCircle' ? '50%' : '8px',
                }}
                variant= {variant=='iconCircle' ? "circular" : "square"}
                src={getDefaultImage}
              />
            ):(
              <Avatar
                sx={{
                  bgcolor: '#1A181766',
                  width: '88px',
                  height: '88px',
                  border: '1px solid #2E3033',
                  borderRadius: variant=='iconCircle' ? '50%' : '8px',
                }}
                variant={variant=='iconCircle' ? "circular" : "square"}
              >
                <img src={IconSource('ic-camera')} width="33.3px" height="30px" />
              </Avatar>
            )}
            <Text variant="label" sx={theme => ({ color: theme.palette.neutral400, fontWeight: '300', mt: '8px' })}>{title}</Text>
            <Text
              mt='4px'
              mb='16px'
              variant="subtitle"
              sx={theme => ({ color: theme.palette.neutral400, opacity: '0.6' })}
            >
              {subtitle}
            </Text>

            {isCompressing ? (
              <Box sx={{ height: '32px' }}>
                <img
                  src={IconSource('load-orange-dark', 'gif')}
                  style={{ width: '24px', zIndex: 10 }}
                />
              </Box>
            ) : (
              <Box onClick={onButtonClick}>
                {cta ?? <SecondaryButton variant='small'>{getDefaultImage ? 'Ubah' : 'Pilih File'}</SecondaryButton>}
              </Box>
            )}
          </Stack>
        </Box>
        {validationText && (
          <Text variant="subtitle" sx={theme => ({ color: theme.palette.danger600, mt: '4px' })}>
            {validationText}
          </Text>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <input
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        ref={inputFile}
        onChange={async e => {
          await handleFileUpload(e);
        }}
        hidden
        required={isRequired}
      />
      <Box
        sx={theme => ({
          background: theme.palette.neutral600,
          borderRadius: theme.borderRadius.md,
          padding: '18px 32px',
          width: '100%',
          textAlign: 'center',
          boxSizing: 'border-box',
          position: 'relative',
          height: '200px',
          ...(validationText != '' && {
            border: '1px solid #D02B20',
          }),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
          <Avatar alt="Remy Sharp" src={IconSource('ic-camera')} sx={{ width: 28, height: 28 }} />
          <Text>{title}</Text>
          <Text
            variant="subtitle"
            sx={theme => ({ color: theme.palette.neutral400, opacity: '0.6' })}
          >
            {subtitle}
          </Text>

          {isCompressing ? (
            <img
              src={IconSource('load-orange-dark', 'gif')}
              style={{ width: '24px', zIndex: 10 }}
            />
          ) : (
            <Box onClick={onButtonClick}>
              {cta ?? <SecondaryButton>Pilih File</SecondaryButton>}
            </Box>
          )}
        </Stack>
        {getDefaultImage && (
          <BoxPreview>
            <ImgPreview src={getDefaultImage} />
            <SecondaryButton
              onClick={onButtonClick}
              sx={{
                background: 'rgba(0, 0, 0, 0.4)',
                position: 'absolute',
                top: '8px',
                right: '8px',
              }}
            >
              Ubah
            </SecondaryButton>
          </BoxPreview>
        )}
      </Box>
      {validationText && (
        <Text variant="subtitle" sx={theme => ({ color: theme.palette.danger600, mt: '4px' })}>
          {validationText}
        </Text>
      )}
    </Box>
  );
};

function srcToFile(src: string, fileName: string, mimeType: string) {
  return fetch(src, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'no-cors',
    headers: {
      'Content-Type': '*/*',
    },
  })
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}
