import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import _ from 'lodash';

// for header
export interface TeamPlayer {
  id: string;
  name: string;
  nickName: string;
  gameProviderId: string;
  picture: string;
}

export interface Type {
  key: string;
  value: string;
}

export interface ResultType {
  key: string;
  value: string;
}

export interface Status {
  key: string;
  value: string;
}

export interface ScrimHeaderDetailParams {
  id: string;
}

export interface ScrimHeaderDetailResponse {
  id: string;
  startAt: Date;
  endAt: Date;
  firstTeam: string;
  firstTeamId: string;
  firstTeamUrlSlug: string;
  firstTeamAvatar: string;
  firstTeamSchool: string;
  firstTeamSchoolCity: string;
  firstTeamWhatsAppPhoneNumber: string;
  firstTeamScore: number;
  firstTeamCaptain: string;
  firstTeamCaptainPhoneNumber: string;
  firstTeamCaptainEmail: string;
  firstTeamPlayers: TeamPlayer[];
  secondTeamUrlSlug: string;
  secondTeam: string;
  secondTeamId: string;
  secondTeamAvatar: string;
  secondTeamSchool: string;
  secondTeamSchoolCity: string;
  secondTeamWhatsAppPhoneNumber: string;
  secondTeamScore: number;
  secondTeamCaptain: string;
  secondTeamCaptainPhoneNumber: string;
  secondTeamCaptainEmail: string;
  secondTeamPlayers: TeamPlayer[];
  winnerTeam: string;
  type: ResultType;
  checkInStartAt: Date;
  checkInEndAt: Date;
  acceptDeadlineAt: Date;
  uploadDeadlineAt: Date;
  status: ResultType;
  resultType: ResultType;
  logs: string[];
}

export interface AddScrimDetailProps {
  matchId: string;
  result:  Result[];
}

export interface Result {
  Round:          number;
  isWin:          boolean;
  AttachmentFile: Blob;
}

export interface ScrimMatchDetailState {
  data?: ScrimHeaderDetailResponse;
  isLoading: boolean;
  status: string;
  error: string;
}

export const initialState: ScrimMatchDetailState = {
  isLoading: false,
  status: '',
  error: '',
};

const parseError = (errors: any) => {
  if (errors) {
    let errorsList: string[] = [];
    Object.values(errors).forEach((prop: any) => {
      errorsList = errorsList.concat(_.map(prop, _.property('message')));
    });
    return errorsList.join('\n');
  }
  return null;
};

export const getScrimDetailUpcoming = createAsyncThunk(
  'scrim/getScrimUpcoming',
  async ({ pageIndex, pageSize, version }: { pageIndex: string; pageSize: string; version?: string }) => {
    const url = `/${version ? `v${version}/` : ''}scrim/upcoming/${pageIndex}/${pageSize}`;
    const response = await ApiService.get(url);
    return response;
  },
);

export const getScrimDetailHeader = createAsyncThunk(
  'scrim/getScrimDetailHeader',
  async ({ id, version }: { id: string; version?: string }) => {
    const url = `/${version ? `v${version}/` : ''}scrim/${id}`;
    const response = await ApiService.get(url);
    return response;
  },
);

export const addScrimDetail = createAsyncThunk(
  'scrim-detail/mobile-legend',
  async ({
    matchId,
    result
  }: AddScrimDetailProps) => {
    try {
      const url = `/scrim-detail/mobile-legend`;
      var formData = new FormData();
      formData.append('MatchId', matchId);
      result.forEach((rest, index)=> {
        formData.append(`Results[${index}].Round`, rest.Round.toString())
        formData.append(`Results[${index}].isWin`, Boolean(rest.isWin).toString())
        formData.append(`Results[${index}].AttachmentFile`, rest.AttachmentFile)
      })      
      const res = await ApiService.post(url, formData);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, data: error.response.data };
      }
    }
  },
);

const ScrimMatchDetailSlice = createSlice({
  name: 'scrim/match-detail',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getScrimDetailHeader.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getScrimDetailHeader.pending, state => {
      state.error = ''
      state.isLoading = true;
    });
    builder.addCase(getScrimDetailHeader.rejected, (state, action) => {
      state.error = action?.error?.message ?? action?.error?.stack ?? '';
      state.isLoading = false;
    });
    builder.addCase(getScrimDetailUpcoming.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getScrimDetailUpcoming.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getScrimDetailUpcoming.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(addScrimDetail.fulfilled, (state, action) => {
      if (action.payload?.isAxiosError && action.payload?.data?.errors != null) {
        state.status = 'error';
        state.error =
          typeof action.payload.data === 'string'
            ? action.payload.data
            : parseError(action.payload.data.errors);
      } else {
        state.status = 'succeeded';
      }
    });
    builder.addCase(addScrimDetail.pending, state => {
      state.isLoading = true;
      state.status = 'loading';
    });
    builder.addCase(addScrimDetail.rejected, state => {
      state.isLoading = false;
      state.status = 'error';
    });
  },
});

export default ScrimMatchDetailSlice.reducer;
