import styled, { css } from 'styled-components';
import {
  HeaderBackgroundProps,
  HeaderNavigationIconProps,
  HeaderNavigationRightProps,
} from './index';

export const HeaderLayout = styled.nav<HeaderBackgroundProps>`
  position: ${props => props.pos ?? 'fixed'};
  z-index: 99;
  top: 0%;
  bottom: 0%;
  transition: 0.4s;
  display: flex;
  overflow-y: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  justify-content: space-between;
  margin: 0px auto;
  border-radius: ${props => props.bRadius ?? '0px'};
  width: 100%;
  height: ${props => props.height ?? '56px'};
  background: ${props => props.background ?? 'rgba(36, 39, 49, 0.94)'};
  box-shadow: ${props => props.boxShadow ?? 'none'};
  ${props =>
    props.isDisable === true &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}

  @media only screen and (min-width: 500px) {
    max-width: 500px;
  }
`;

export const HeaderNavLeft = styled.a<HeaderNavigationIconProps>`
  float: left;
  margin: 16px;
  width: ${props => props.width ?? '24px'};
  height: ${props => props.height ?? '24px'};
  cursor: pointer;
  background-image: ${props =>
    `url('${process.env.PUBLIC_URL}/media/icons/${props.bg}.svg')` ??
    `url('${process.env.PUBLIC_URL}/media/icons/ic-arrow-back-light.svg')`};
  background-repeat: no-repeat;
  background-size: contain;
  ${props =>
    props.isDisable === true &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
  ${props =>
    props.bg === 'ic-arrow-back-light' &&
    props.isDisable === false &&
    css`
      &:focus {
        opacity: 0.7;
        background-image: ${`url('${process.env.PUBLIC_URL}/media/icons/ic-arrow-back-gradient.svg')`};
      }
      &:active {
        opacity: 0.7;
        background-image: ${`url('${process.env.PUBLIC_URL}/media/icons/ic-arrow-back-gradient.svg')`};
      }
    `}
    -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const HeaderTitleCenter = styled.a<any>`
  display: block;
  color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  flex-grow: 1;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 140%;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const HeaderNavRight = styled.a<HeaderNavigationRightProps>`
  width: ${props => props.width ?? '24px'};
  height: ${props => props.height ?? '24px'};
  margin: 16px;
  cursor: pointer;
  ${props =>
    props.variant !== 'button' &&
    css`
      background-image: ${`url('${
        process.env.PUBLIC_URL
      }/media/icons/ic-${`${props.variant}-${props.bg}`}.svg')` ??
      `url('${process.env.PUBLIC_URL}/media/icons/ic-${props.variant}-white.svg')`};
      background-repeat: no-repeat;
      ${props.isDisable === true &&
      css`
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: 'none';
      `}
      ${props.isDisable === false &&
      css`
        pointer-events: 'auto';
        &:focus-within {
          opacity: 0.7;
          background-image: url('${process.env
            .PUBLIC_URL}/media/icons/ic-${props.variant}-gradient.svg');
        }
        &:focus {
          opacity: 0.7;
          background-image: url('${process.env
            .PUBLIC_URL}/media/icons/ic-${props.variant}-gradient.svg');
        }
        &:active {
          opacity: 0.7;
          background-image: url('${process.env
            .PUBLIC_URL}/media/icons/ic-${props.variant}-gradient.svg');
        }
      `}
    `}
  ${props =>
    props.variant === 'button' &&
    css`
      width: auto;
      height: auto;
      margin-right: 16px;
      font-style: normal;
      text-align: right;
      font-size: 13px;
      font-weight: bold;
      cursor: ${props.isDisable === true ? 'not-allowed' : 'pointer'};
      pointer-events: ${props.isDisable === true ? 'none' : 'auto'};
      background: ${props.isDisable === true ? '#969393' : props.bg ? props.bg : '#969393'};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      ${props.isDisable === false &&
      css`
        &:focus {
          opacity: 0.7;
        }
        &:active {
          opacity: 0.7;
        }
        &:focus-within {
          opacity: 0.7;
        }
      `}
    `}
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
