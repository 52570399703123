import { styled } from '@mui/system';

export const ImageBox = styled('div')`
  position: relative;
  height: 160px;
  width: 100%;
  margin-top: -24px;
`;

export const ImageBottom = styled('img')`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  object-position: 100% 0;
  z-index: 1;
`;
export const ImageTop = styled('img')`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;
