import { Action, applyMiddleware, createStore, Middleware } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import signalRMiddleware from 'middleware/signalRMiddleware';
import { useDispatch } from 'react-redux';
import reducers from './RootReducers';
import rootSaga from './RootSaga';
import middleware, { sagaMiddleware } from './middleware';


const configStore = (initialState: any = {}, additionalMiddleware: Middleware[] = []) => {
  const enhancers = process.env.NODE_ENV === 'production' ? 
  applyMiddleware(...additionalMiddleware, signalRMiddleware, thunkMiddleware, sagaMiddleware, ...middleware) :
  composeWithDevTools(
    applyMiddleware(...additionalMiddleware, signalRMiddleware, thunkMiddleware, sagaMiddleware, ...middleware),
  )
  const store = createStore(
    reducers,
    initialState,
    enhancers,
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('reducers', () => {
      store.replaceReducer(reducers);
    });
  }
 
  return {
    persistor: persistStore(store),
    store
  };
};

export const { persistor, store } = configStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export default configStore;
