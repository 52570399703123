import React, { FC, ReactNode } from 'react';
import { Border, InlineBorder, BorderGame, GameLogo, Row, Column } from './styles';
import { GradientText, NormalText } from 'components/styled/text.styled';

export interface CardInfoGameProps {
  onClick: () => void;
  placeholder?: string;
  label: ReactNode;
  src?: string;
  gameId?: ReactNode | undefined;
  copyText?: string;
}
export const CardInfoGame: FC<CardInfoGameProps> = ({ src, gameId, label, copyText, onClick }) => (
  <Border>
    <InlineBorder>
      <Row>
        <Column>
          <BorderGame>
            <GameLogo src={src} />
          </BorderGame>
        </Column>
        <Column style={{ marginTop: '10px', marginLeft: '18px' }}>
          <NormalText fontSize="14px" fontWeight="500" lineHeight="140%" color="white">
            {label}
          </NormalText>
          <NormalText
            margin="8px 0px 0px 0px"
            fontSize="12px"
            fontWeight="normal"
            lineHeight="140%"
            color="#969393"
            opacity="0.7"
          >
            {gameId}
          </NormalText>
        </Column>
        <Column
          style={{ width: 'fit-content', marginTop: '13px', marginRight: '13px', float: 'right' }}
        >
          <GradientText
            onClick={onClick}
            fontSize="14px"
            fontStyle="normal"
            fontWeight="bold"
            isPressable
            disableDrag
            disableSelectText
            color="#F3AA36">
            {copyText}
          </GradientText>
        </Column>
      </Row>
    </InlineBorder>
  </Border>
);

export default CardInfoGame;
