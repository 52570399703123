import styled from 'styled-components';
import { BorderIcon } from './CardRadio';

export const Frame = styled.div`
  /* align-items: center; */
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid white;
  width: 76%;
  margin: auto;
  /* min-height: 76px; */
  /* outline-style : solid;
  outline-color: white; */
`;
export const RadioButtonWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  height: 24px;
  width: fit-content;
  position: relative;
  margin-top: 13px;
  justify-content: center;
  left: 77%;
  /* justify-content: ru; */
  /* margin-left: -10px; */
`;
export const RadioButtonCircle = styled.label`
  position: absolute;
  top: 10%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #eee;
`;
export const RadioButtonInput = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  justify-content: space-between;
  &:hover ~ ${RadioButtonCircle} {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:checked + ${RadioButtonCircle} {
    background-color: #d51f40;
    transition: all 0.3s linear;
  }
  &:checked + ${RadioButtonCircle}::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);
  }
`;
export const RadioLabel = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-left: 12px;
  margin-top: -23px;
  color: white;
`;

export const CardRadioStyle = styled.div`
  display: block;
  align-items: center;
  border-radius: 18px;
  box-sizing: content-box;
  /* border: 1px solid white; */
  width: 100%;
  min-height: 91px;
  background: rgba(55, 57, 66, 0.6);
`;

export const TeamLabel = styled.div`
  position: absolute;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-left: 100px;
  margin-top: 42px;
  color: white;
`;

export const GameType = styled.div`
  position: absolute;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  margin-left: 100px;
  margin-top: 10px;
  color: #969393;
`;

export const MatchTotal = styled.div`
  position: absolute;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  margin-left: 100px;
  margin-top: 65px;
  color: #ddd8d8;
  opacity: 0.7;
`;

export const BorderLogo = styled.div<BorderIcon>`
  border-radius: 16px;
  box-sizing: border-box;
  border: 2px solid #f1ebeb;
  width: 90px;
  height: 90px;
  background: ${props =>
    `url('${props.bg}')` ?? `url('${process.env.PUBLIC_URL}/media/icons/cubedragon-logo.svg')`};
  background-size: 89px 89px;
  background-repeat: no-repeat;
  background-position: center;
`;

export const RadioButtonWrappers = styled.div`
  display: flex;
  /* align-items: center; */
  height: 0px;
  width: 100%;
  top: 34px;
  right: 14.25px;
  position: relative;
  justify-self: end;
`;
export const RadioButtonCircles = styled.label`
  position: absolute;
  top: 10%;
  right: 0px;
  width: 19.25px;
  height: 19.25px;
  border-radius: 50%;
  border: 2.25px solid #fff;
`;

export const RadioButtonInputs = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: -4px;
  border-radius: 50%;
  width: 19.25px;
  height: 19.25px;
  right: -3px;
  &:hover ~ ${RadioButtonCircles} {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:checked + ${RadioButtonCircles} {
    right: 0px;
    background: linear-gradient(
        131.42deg,
        #d51f40 46.48%,
        #e24134 62.51%,
        #e8542e 74.36%,
        #f37024 83.78%
      )
      no-repeat;
    transition: all 0.3s linear;
  }
  &:checked + ${RadioButtonCircles}::after {
    content: '';
    right: 0px;
    width: 23.15px;
    height: 23.15px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 2.25px solid transparent; /*2*/
    background: linear-gradient(
        131.42deg,
        #d51f40 46.48%,
        #e24134 62.51%,
        #e8542e 74.36%,
        #f37024 83.78%
      )
      border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; /*5'*/
    mask-composite: exclude;
    transform: translate(-50%, -50%);
    transition: all 0.3s linear;
  }
  &:active ~ ${RadioButtonCircles} {
    opacity: 0.7;
  }
`;
