import PrivacyContent from './data/PrivacyContent';
import SupportLayout, { SupportLayoutProps } from 'components/Layout/SupportLayout';
import { ImageSource } from 'modules/assetpath';
import { dateFormat } from 'modules/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const privacyProps: SupportLayoutProps = {
    headers: {
      headerBackground: ImageSource('card.background', 'jpg'),
      title: t('contact_us_privacy_header_title'),
      update: t('contact_us_privacy_latest_update', {
        updateDate: dateFormat(new Date(1638118800000)).format('DD MMMM YYYY'),
      }),
    },
    content: PrivacyContent(),
  };
  return <SupportLayout {...privacyProps} />;
};

export default PrivacyPolicy;
