import { Box, Stack } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React from 'react';

export interface TabsProps {
  menus: menuProps[];
  menuValue: string;
  SetMenuValue: React.Dispatch<React.SetStateAction<any>>;
  dropdownValue: String;
  dropdownOnClick: () => void;
}

interface menuProps {
  label: string;
  value: string;
}

export const FilterTabs = ({
  menus,
  menuValue,
  SetMenuValue,
  dropdownValue,
  dropdownOnClick,
}: TabsProps) => {
  return (
    <Box sx={{ bgcolor: '#161618', padding: '13px 16px' }}>
      <Stack direction="row" spacing="8px">
        {menus.map(menu => {
          return menu?.label == menuValue ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={theme => ({
                padding: '8px 10px 8px 12px',
                bgcolor: '#FFFFFF',
                border: `1px solid ${theme.palette.primaryRed400}`,
                boxShadow: '0px 0px 12px rgba(211, 31, 64, 0.6)',
                borderRadius: '24px',
                cursor: 'pointer',
              })}
              onClick={() => SetMenuValue('')}
            >
              <Text
                variant="labelSmall"
                sx={{
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: 'neutral700',
                  mr: '2px',
                  textTransform: 'capitalize',
                }}
              >
                {menu.label}
              </Text>
              <img src={IconSource('ic-close-dark')} height={15} />
            </Stack>
          ) : (
            <Box
              sx={theme => ({
                bgcolor: 'neutral600',
                border: `1px solid ${theme.palette.neutral550}`,
                borderRadius: '24px',
                padding: '8px 8px 8px 12px',
                gap: '2px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              })}
              onClick={() => SetMenuValue(menu.value)}
            >
              <Text
                variant="labelSmall"
                sx={{
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: 'neutral400',
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                }}
              >
                {menu.label}
              </Text>
            </Box>
          );
        })}

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={theme => ({
            padding: '8px 10px 8px 12px',
            bgcolor: dropdownValue ? '#FFFFFF' : 'neutral600',
            border: dropdownValue
              ? `1px solid ${theme.palette.primaryRed400}`
              : `1px solid ${theme.palette.neutral550}`,
            boxShadow: dropdownValue ? '0px 0px 12px rgba(211, 31, 64, 0.6)' : '',
            borderRadius: '24px',
            cursor: 'pointer',
          })}
          onClick={() => dropdownOnClick()}
        >
          <Text
            variant="labelSmall"
            sx={{
              fontSize: '12px',
              lineHeight: '15px',
              color: dropdownValue ? 'neutral700' : 'neutral400',
              textTransform: 'capitalize',
              mr: '2px',
            }}
          >
            {dropdownValue != '' ? dropdownValue : 'Periode Match'}
          </Text>
          <img src={IconSource('ic-caret-down-gray')} height={15} />
        </Stack>
      </Stack>
    </Box>
  );
};
