import { Box, Stack } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardImportantInfo } from 'design/Cards/CardImportantInfo';
import { InfoModal } from 'design/Cards/InfoModal';
import { OptionType as IOptions } from 'design/Forms/Select';
import { SelectInput as SelectOption } from 'design/Forms/Select';
import { TextInput as Input } from 'design/Forms/TextInput';
import { UploadImage } from 'design/Forms/UploadImage';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useEffect, useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountValidationProblemDetails } from 'redux/account/AccountResponse';
import {
  addNewGameInformation,
  fetchGameAvailableList,
  fetchPlayerProfile,
} from 'redux/account/AccountServices';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { EditProfileValidationOptions } from 'validation/account/EditGameValidation';

const GameEditPage = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, watch } = useForm(EditProfileValidationOptions);
  const dispatch = useAppThunkDispatch();
  const states = useAppSelector(state => state.account);
  const [getGameList, setGameList] = useState<IOptions[]>([]);
  const { playerInfo, gameDataList } = useAppSelector(({ account }) => ({
    playerInfo: account.data,
    gameDataList: account.gameAvailable,
  }));
  const [getConfirmModal, setConfirmModal] = useState(false);
  const [getConfirmModalFail, setConfirmModalFail] = useState(false);
  const [getConfirmModalSuccess, setConfirmModalSuccess] = useState(false);
  const [getClickSubmit, setClickSubmit] = useState(true);
  const [getValidationErrors, setValidationErrors] = useState<AccountValidationProblemDetails>();
  let isError = getValidationErrors && getValidationErrors?.errors;

  async function saveDataGame(data: any) {
    setClickSubmit(false);
    const thunk = addNewGameInformation({
      userId: playerInfo?.id ?? '',
      gameId: data.gameId,
      gameProviderId: data.gameProviderId,
      nickName: data.nickName,
      nickNameFile: data.nickNameFile.size == 0 ? '' : data.nickNameFile,
    });
    dispatch(thunk)
      .unwrap()
      .then(res => {
        setClickSubmit(true);
        if (res && res.data?.status >= 400) {
          setValidationErrors((states?.errors as AccountValidationProblemDetails) ?? {});
          setConfirmModal(false);
          setConfirmModalFail(true);
        } else {
          setValidationErrors({});
          setConfirmModal(false);
          setConfirmModalSuccess(true);
        }
      });
  }

  const handleFormClick = (e: any) => {
    e.preventDefault();
    setConfirmModal(true);
  };

  const fetchAccountProfile = (username: string) => {
    dispatch(fetchPlayerProfile({ username: username }));
  };

  const fetchGameList = (username: string) => {
    dispatch(fetchGameAvailableList({ playerSlug: username, pageIndex: 1, pageSize: 20 }));
  };

  useEffect(() => {
    if (playerInfo) {
      fetchAccountProfile(playerInfo?.userName);
      fetchGameList(playerInfo?.userName);
    }
  }, []);

  useEffect(() => {
    if (gameDataList) {
      setGameList(
        gameDataList.items.map(game => {
          return { name: game.name, value: game.id } as IOptions;
        }),
      );
    }
  }, [gameDataList]);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  
  const valueNickName = String(watch(['nickName']));
  const valueGameProviderId = String(watch(['gameProviderId']));
  const [imageEdited, setImageEdited] = useState(false);

  const NickNameData = playerInfo?.games[0]?.nickName ?? '';
  const GameProviderIdData = playerInfo?.games[0]?.gameProviderId ?? '';

  useEffect(() => {
    if (
      imageEdited ||
      valueNickName != NickNameData ||
      valueGameProviderId !== GameProviderIdData
    ) {
      return setButtonDisabled(false);
    }

    return setButtonDisabled(true);
  }, [imageEdited, valueNickName, valueGameProviderId]);

  const onError = () => {
    setConfirmModal(false)
  };

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('player_account_edit_game_header_title'),
      appBar: {
        separator: true,
        logo: false,
        back: {
          to: '/account',
        },
        title: t('player_account_edit_game_header_title'),
      },
      background: 'linear-gradient(180deg, #131318 19.34%, rgba(19, 19, 24, 0.94) 94.01%)',
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, []);

  return (
    <>
      <Modal show={getConfirmModalFail}>
        <InfoModal
          icon={ImageSource('Warning', 'svg')}
          cta={
            <PrimaryButton wide onClick={() => setConfirmModalFail(false)}>
              Oke, kembali ke halaman
            </PrimaryButton>
          }
        >
          <Text variant="h2" textAlign="center" mb="8px">
            gagal terkirim
          </Text>
          <Text textAlign="center">Mohon maaf form gagal terkirim, mohon di coba lagi..</Text>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmModalSuccess}>
        <InfoModal
          cta={
            <PrimaryButton onClick={() => history.push('/account')}  sx={{borderRadius: '4px'}}>
              Kembali ke Akun
            </PrimaryButton>
          }
        >
          <Text variant="h3" textAlign="center">
            Profil Game berhasil diperbarui
          </Text>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmModal}>
        <InfoModal
          icon={ImageSource('Warning', 'png')}
          cta={
            <>
              <SecondaryButton sx={{ mr: '16px' }} onClick={() => setConfirmModal(false)} wide>
                Batal
              </SecondaryButton>
              <PrimaryButton
                wide
                onClick={handleSubmit(saveDataGame, onError)}
                disabled={getClickSubmit ? false : true}
              >
                Ya, simpan
              </PrimaryButton>
            </>
          }
        >
          <Text variant="h2" textAlign="center" mb="8px">
            Perubahan Hanya Untuk Scrim
          </Text>
          <Text textAlign="center" sx={{color: 'neutral300'}}>
            Game ID dan Nickname Game yang diubah di sini hanya digunakan untuk fitur <Text component="span" sx={{color: 'neutral300', fontWeight: '700'}}>Scrim</Text> saja. Apakah kamu tetap ingin simpan data terbaru ini?
          </Text>
        </InfoModal>
      </Modal>

      <Stack direction="column" spacing={2} padding={'16px 16px 0px 16px'}>
        <CardImportantInfo
          title={t('game_edit_important_notice_title')}
          content={t('game_edit_important_notice_content')}
          margin="0"
        />
        <form noValidate autoComplete="off" autoCapitalize="off">
          <Box sx={{ mb: '16px' }}>
            <Text
              variant="label"
            >
              Profil Scrim
            </Text>
          </Box>
          <Box sx={{ mb: '24px' }}>
            <Controller
              control={control}
              name="gameId"
              defaultValue={playerInfo?.games ? playerInfo?.games[0]?.game?.id : ''}
              render={({ field: { onChange, ref } }) => (
                <SelectOption
                  label="Pilih Game"
                  placeholder={t('player_account_add_game_select_game_placeholder')}
                  options={getGameList}
                  disabled={true}
                  defaultValue={playerInfo?.games ? playerInfo?.games[0]?.game?.id : ''}
                  validationText={
                    isError && getValidationErrors?.errors?.game_GameId
                      ? getValidationErrors.errors?.game_GameId[0].message
                      : undefined
                  }
                  inputRef={ref}
                  onItemChange={(e: any) => {
                    onChange(e);
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ mb: '24px' }}>
            <Controller
              control={control}
              name="gameProviderId"
              defaultValue={playerInfo?.games[0]?.gameProviderId ?? undefined}
              render={({ field: { onChange, ref }, formState: { errors } }) => (
                <>
                  <Input
                    id="inputgameid"
                    label="Game ID"
                    defaultValue={playerInfo?.games[0]?.gameProviderId ?? undefined}
                    onChange={onChange}
                    inputRef={ref}
                    type="number"
                    inputMode="numeric"
                    error={errors.gameProviderId}
                  />
                  {(errors.gameProviderId) && (
                    <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                      {errors?.gameProviderId?.message}
                    </Text>
                  )}
                </>
              )}
            />
          </Box>
          <Box sx={{ mb: '24px' }}>
            <Controller
              control={control}
              name="nickName"
              defaultValue={playerInfo?.games[0]?.nickName ?? ''}
              render={({ field: { onChange, ref }, formState: { errors } }) => (
                <>
                  <Input
                    id="inputnickName"
                    label={t('player_account_add_game_nickname_game')}
                    defaultValue={playerInfo?.games[0]?.nickName ?? ''}
                    onChange={onChange}
                    inputRef={ref}
                    type="text"
                    inputMode="text"
                    error={errors.nickName}
                  />
                  {(errors.nickName) && (
                    <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                      {errors?.nickName?.message}
                    </Text>
                  )}
                </>
              )}
            />
          </Box>
          <Box sx={{ mb: '24px' }}>
            <Controller
              control={control}
              name="nickNameFile"
              render={({ field: { onChange, ref } }) => (
                <UploadImage
                  defaultImage={playerInfo?.games[0]?.nickNameFile}
                  title="Upload Screenshot Profil Game ID Kamu"
                  getImageSource={() => {
                    setImageEdited(true);
                  }}
                  getImageBlob={val => onChange(val)}
                  inputRef={ref}
                  onImageLoad={function noRefCheck() {}}
                />
              )}
            />
          </Box>
          <PrimaryButton
            wide
            onClick={handleFormClick}
            disabled={buttonDisabled}
            sx={{ borderRadius: '4px'}}
          >
            Simpan
          </PrimaryButton>
        </form>
      </Stack>
    </>
  );
};

export default GameEditPage;