import CardFeedAward from './CardFeedAward';
import { Box } from '@mui/material';
import { Text } from 'design/Text';
import moment from 'moment';
import React, { memo } from 'react';

interface FeedAwardProps {
  dataFeed?: any;
  handleButtonRoster?: any;
}

const FeedAward = ({ dataFeed, handleButtonRoster }: FeedAwardProps) => {
  return (
    <Box sx={{ pb: '24px' }}>
      {dataFeed?.map((season: any) => {
        return (
          <Box sx={{ px: '16px', mb: '13px' }}>
            <Box sx={{ p: '24px 0 12px 0' }}>
              <Text variant="h3" sx={{ mb: '4px' }}>
                {season.title}
              </Text>
              <Text
                variant="bodySmall"
                sx={{
                  fontSize: '10px',
                  lineHeight: '14px',
                  letterSpacing: '0.16px',
                  color: 'neutral400',
                }}
              >
                {`${moment(season?.splitStart).format('D MMMM YYYY')} - ${moment(
                  season?.splitEnd,
                ).format('D MMMM YYYY')}`}
              </Text>
            </Box>
            <Box>
              <CardFeedAward
                awardList={season.data ?? []}
                handleButtonRoster={handleButtonRoster}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(FeedAward);
