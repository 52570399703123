import TermsContent from './data/TermsContent';
import SupportLayout, { SupportLayoutProps } from 'components/Layout/SupportLayout';
import { ImageSource } from 'modules/assetpath';
import { dateFormat } from 'modules/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TermAndCondition = () => {
  const { t } = useTranslation();
  const termConditionProps: SupportLayoutProps = {
    headers: {
      headerBackground: ImageSource('card.background', 'jpg'),
      title: t('contact_us_term_header_title'),
      update: t('contact_us_term_latest_update', {
        updateDate: dateFormat(new Date(1638118800000)).format('DD MMMM YYYY'),
      }),
    },
    content: TermsContent(),
  };
  return <SupportLayout {...termConditionProps} />;
};

export default TermAndCondition;
