import ScrimListPage from './state/ScrimList';
import TournamentList from './state/TournamentList';
import TryOut from './state/tryOut';
import { Box } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import { CustomTabs, TabItem } from 'design/Tabs';
import history from 'modules/history';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchGameOptions, GameOptions, resetGameOptions } from 'redux/game/GameReducer';
import { useAppSelector } from 'redux/hooks';
import { getCountUnreadNotification } from 'redux/notification/NotificationReducer';
import { getGameSelectedAction } from 'redux/scrim/ScrimDraftReducer';
import { useAppThunkDispatch } from 'redux/store';
import AuthService from 'services/AuthService';

const TournamentPage = () => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();

  const dispatch = useAppThunkDispatch();
  const [haveNotifUnread, setHaveNotifUnread] = useState(false);
  const [pageTitleTab, setPageTitleTab] = useState(
    t('mabar_page_title', { title: 'Explore Tournament' }),
  );

  const { playerInfo, gameSelected, gameOption } = useAppSelector(
    ({ account, scrimDraft, game }) => ({
      playerInfo: account?.data,
      gameSelected: scrimDraft?.gameSelected,
      gameOption: game?.gameOption,
    }),
  );

  const { tabSlug } = useParams<{ tabSlug: string }>();

  const handleCountNotification = () => {
    if (playerInfo) {
      dispatch(
        getCountUnreadNotification({
          id: playerInfo.id,
        }),
      )
        .unwrap()
        .then(res => {
          if (res && res.status == 200 && res.data.count > 0) {
            setHaveNotifUnread(true);
          } else {
            setHaveNotifUnread(false);
          }
        });
    }
  };

  const handleFetchGameOption = () => {
    dispatch(fetchGameOptions({ pageIndex: 1, pageSize: 10 }));
  };

  const handleSaveGameSelected = useCallback(
    (game: GameOptions) => {
      dispatch(getGameSelectedAction({ game: game }));
    },
    [gameSelected],
  );

  useEffect(() => {
    Promise.all([dispatch(resetGameOptions()), handleFetchGameOption()]);
  }, []);

  useEffect(() => {
    if (isAuthenticated) handleCountNotification();
  }, [playerInfo]);

  useEffect(() => {
    if (gameOption) {
      gameOption.items.find(g => {
        if (g.release) {
          handleSaveGameSelected(g);
        }
      });
    }
  }, [gameOption, gameSelected]);

  const onChangeHandler = (value: any): void => {
    if (value === 0) {
      history.push('/explore/tournament');
      setPageTitleTab(t('mabar_page_title', { title: 'Explore Tournament' }));
    } else if (value === 1) {
      history.push(`/explore/scrim/${gameSelected.urlSlug}`);
      setPageTitleTab(t('mabar_page_title', { title: 'Explore Scrim' }));
    } else if (value === 2) {
      history.push('/explore/tryout');
      setPageTitleTab(t('mabar_page_title', { title: 'Explore tryout' }));
    }
  };

  const handleActiveTab = (tab: string) => {
    switch (tab) {
      case 'scrim':
        return 1;
      case 'tryout':
        return 2;
      default:
        return 0;
    }
  };

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: pageTitleTab,
      background: { color: '#0a0a0b' },
      appBar: {
        logo: true,
        notification: {
          show: isAuthenticated,
          haveNotifUnread: haveNotifUnread,
          onClick: () => history.push('/notification'),
        },
      },
      padding: '0px',
      bottomBar: {
        show: true,
      },
    });
  }, [haveNotifUnread, pageTitleTab]);

  return (
    <CustomTabs
      sx={theme => ({
        bgcolor: 'neutral700',
        padding: '0 16px',
        position: 'sticky',
        top: '56px',
        zIndex: 2,
        borderBottom: `1px solid ${theme.palette.neutral530}`,
      })}
      handleChange={onChangeHandler}
      activeTab={handleActiveTab(tabSlug)}
    >
      <TabItem title="Tournament">
        <TournamentList />
      </TabItem>
      <TabItem title="Scrim">
        <Box sx={{ pb: '50px' }}>
          <ScrimListPage />
        </Box>
      </TabItem>
      <TabItem title="E-sports Tryout">
        <TryOut />
      </TabItem>
    </CustomTabs>
  );
};

export default TournamentPage;
