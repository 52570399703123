import { Box } from '@mui/material';
import { CardImportantInfo } from 'design/Cards/CardImportantInfo';
import { Player } from 'design/Cards/Player';
import { DrawerStatusAnggotaTim } from 'design/Drawer/StatusAnggotaTim';
import { Ribbon } from 'design/Ribbon';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MyTeamFromTournamentResponse } from 'redux/tournament/TournamentProps';
import { STATE, TEAM_STATE } from 'store/constants/tournament';

interface StatusAnggotaTimProps {
  importantInfo: {
    title: string;
    content: string;
  };
  myId?: string;
  isMyTeamInTournament: boolean;
  tournamentState?: number;
  teamState?: string;
  players: MyTeamFromTournamentResponse['players'];
  isDrawerOpen: boolean;
  handleDrawerOpen: () => void;
  tournamentName?: string;
}

const StatusAnggotaTim = ({
  importantInfo,
  myId,
  isMyTeamInTournament,
  tournamentState,
  teamState,
  players,
  isDrawerOpen,
  handleDrawerOpen,
  tournamentName,
}: StatusAnggotaTimProps) => {
  const { t } = useTranslation();
  const playersAccepted = players?.reduce((a, b) => a + (b.state == 1 ? 1 : 0), 0);

  const teamJoinedButTournamentStillOpen =
    Boolean(isMyTeamInTournament) &&
    teamState === TEAM_STATE.JOINED &&
    tournamentState === STATE.REGISTRATION_OPENED;

  const teamQueuedButTournamentStillOpen =
    Boolean(isMyTeamInTournament) &&
    teamState === TEAM_STATE.QUEUED &&
    tournamentState === STATE.REGISTRATION_OPENED;

  const handleLabelStatusAnggotaTim = t('tournament_anggota_tim', {
    tournamentTitle: tournamentName,
    count: playersAccepted,
    total: players.length ?? 0,
  });

  const sortPlayer = players.slice().sort((a, b) => {
    if (a.state > b.state && a.state != 2) return -1;
    else {
      if (a.level < b.level) return 0;
      if (a.level > b.level) return 1;
      return 1;
    }
  });

  const handleIconStatusPlayer = (state: number) => {
    if (state == 1) return 'ic-check';
    if (state == 2) return 'ic-decline';

    return 'ic-timer';
  };

  const handleDescplayerStatus = (player: any) => {
    if (player.state == 1) {
      return `${player.nickName} | ${player.gameProviderId}`;
    }
    if (player.state == 2) {
      return 'Gagal konfirmasi anggota';
    }

    return (
      <Text
        variant="bodySmall"
        component="span"
        sx={{
          color: '#F68F54',
          mb: 0,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          letterSpacing: '0.16px',
          fontSize: '12px',
          lineHeight: '20px',
          display: 'block',
        }}
      >
        Menunggu konfirmasi anggota
      </Text>
    );
  };

  return (
    <DrawerStatusAnggotaTim
      title={handleLabelStatusAnggotaTim}
      position="bottom"
      open={isDrawerOpen}
      onClose={handleDrawerOpen}
    >
      {teamQueuedButTournamentStillOpen && (
        <CardImportantInfo
          title={importantInfo.title}
          content={importantInfo.content}
          margin="0 0 16px 0"
        />
      )}
      {teamJoinedButTournamentStillOpen && (
        <Box sx={{ mb: '16px' }}>
          <Ribbon variant="success" label={t('tournament_tim_terdaftar')} />
        </Box>
      )}

      <Box sx={{ mb: '16px' }}>
        {sortPlayer.map((player, idx) => (
          <Player
            key={player.id}
            playerName={player.name}
            playerInfo={handleDescplayerStatus(player)}
            playerPhoto={player.avatarFile}
            isCaptain={player.level == 1}
            isDeclined={player.state == 2}
            icon={
              teamJoinedButTournamentStillOpen
                ? IconSource(handleIconStatusPlayer(player.state))
                : ''
            }
            isYou={player.id === myId}
            sx={theme => ({
              position: 'relative',
              m: 0,
              p: '12px',
              zIndex: -1,
              ...(teamJoinedButTournamentStillOpen &&
                player.state == 1 && {
                  border: '1px solid #242628',
                  borderBottom: 0,
                  background: theme.palette.greenGradient,
                }),
              ...(teamJoinedButTournamentStillOpen &&
                playersAccepted &&
                idx === 0 && {
                  borderRadius: playersAccepted == 1 ? '12px' : '12px 12px 0 0',
                }),
              ...(teamJoinedButTournamentStillOpen &&
                playersAccepted > 1 &&
                idx === Number(playersAccepted) - 1 && {
                  borderBottom: '1px solid #242628',
                  borderRadius: '0 0 12px 12px',
                }),
            })}
          />
        ))}
      </Box>
    </DrawerStatusAnggotaTim>
  );
};

export default StatusAnggotaTim;
