import { Avatar, Box, CardMedia, Stack } from '@mui/material';
import ScrollView from 'components/ScrollView';
import { LayoutContext } from 'context/LayoutContext';
import competitionJson from 'data/competitions/competitionLanding.json';
import ncsHstInProgressJson from 'data/competitions/nasionalChampionship/hstInProgress.json';
import ncsOngoingGroupStageJson from 'data/competitions/nasionalChampionship/ongoingGroupStage.json';
import ncsOngoingPlayoffJson from 'data/competitions/nasionalChampionship/ongoingPlayoff.json';
import ncsSeasonFinishedJson from 'data/competitions/nasionalChampionship/seasonFinished.json';
import navigationMenu from 'data/navigationMenu.json';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardBanner, shadowOrangeStyled } from 'design/Cards/CardBanner';
import { CardSchool } from 'design/Cards/CardSchool';
import { Champion, CandidateProps } from 'design/Section/Champion';
import { News } from 'design/Section/News';
import { CompetitionStatus } from 'design/Tag/CompetitionStatus';
import { Text } from 'design/Text';
import { IconSource, ImageSource } from 'modules/assetpath';
import { onClickShare } from 'modules/helpers';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

export interface NationalChampionshipProps {
  status: 'ongoingGroupStage' | 'ongoingPlayoff' | 'seasonFinished' | 'hstInProgress';
}

const NationalChampionshipPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [hstData, setHstData] = useState<any>();

  const handleHSTData = (status: NationalChampionshipProps['status']) => {
    switch (status) {
      case 'ongoingGroupStage':
        return ncsOngoingGroupStageJson;
      case 'ongoingPlayoff':
        return ncsOngoingPlayoffJson;
      case 'seasonFinished':
        return ncsSeasonFinishedJson;
      case 'hstInProgress':
        return ncsHstInProgressJson;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    setHstData(
      handleHSTData(
        competitionJson.competitionList[0].status as NationalChampionshipProps['status'],
      ),
    );
  }, [location]);

  const restrutureCandidateData: any = (data: []) => {
    return data
      ? data.map((data: CandidateProps) => ({
          ...data,
          schoolLogo: `${process.env.PUBLIC_URL}/media/images/sekolah/${data.schoolLogo}.png`,
          teamLogo:
            data.teamLogo || data.teamLogo !== ''
              ? `${process.env.PUBLIC_URL}/media/images/tim/${data.teamLogo}.png`
              : undefined,
        }))
      : undefined;
  };

  const handleTagVariant = (status: NationalChampionshipProps['status']) => {
    if (status === 'ongoingGroupStage' || status === 'ongoingPlayoff') return 'orange';
    return undefined;
  };

  const handleBoxGradient = (status: NationalChampionshipProps['status']) => {
    if (status === 'ongoingGroupStage' || status === 'ongoingPlayoff')
      return 'linear-gradient(180deg, rgba(242, 108, 38, 0.24) 0%, rgba(10, 10, 11, 0) 100%)';
    return 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(10, 10, 11, 0) 100%);';
  };

  const { setValue } = useContext(LayoutContext);

  const handleBack = () => {
    if (history.action === 'POP') {
      return '/competition';
    }
    return 'goback';
  };

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'RRQ MABAR Esports Tournament' }),
      appBar: {
        logo: false,
        back: {
          to: handleBack(),
        },
        menu: navigationMenu,
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: t('tournament_page_title', { title: 'RRQ MABAR Esports Tournament' }),
              handleIconShare: () => handleShare(),
            });
          }
        },
        background: 'linear-gradient(180deg, #1A1817 0%, rgba(10, 10, 11, 0) 100%)',
      },
      padding: '0px',
      bottomBar: {
        show: false,
      },
    });
  }, []);

  return (
    <>
      <Parallax
        translateY={[0, 25, 'easeInOut']}
        opacity={[1, 0]}
        shouldAlwaysCompleteAnimation={true}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'relative',
            mt: '-56px',
            height: '281px',
            background: `url(${ImageSource(
              'bg-section-competition',
              'png',
            )}) no-repeat center / cover`,
            '&:before': {
              content: "''",
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '40px',
              background: 'linear-gradient(360deg, #0A0A0B 0%, rgba(10, 10, 11, 0) 87.18%)',
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              '&:before': {
                content: "''",
                position: 'absolute',
                width: '74px',
                height: '74px',
                borderRadius: '50%',
                inset: 0,
                margin: 'auto',
                boxShadow: '0px 6.58824px 100.8px rgba(200, 105, 37, 0.8)',
                zIndex: 0,
              },
            }}
          >
            <Avatar
              src={ImageSource(hstData?.banner.logo, 'png')}
              alt={hstData?.banner.title}
              sx={{
                position: 'relative',
                width: '128px',
                height: '128px',
                img: { objectFit: 'contain' },
              }}
            />
          </Box>
          <Text
            variant="h2"
            sx={{ fontSize: '20px', lineHeight: '28px', fontWeight: 700, mb: '4px' }}
          >
            {hstData?.banner.title}
          </Text>
          <Text variant="button" sx={{ color: 'neutral400' }}>
            {hstData?.banner.description}
          </Text>
        </Stack>
      </Parallax>

      <Stack sx={{ position: 'relative', bgcolor: 'neutral900', zIndex: 3 }}>
        <CompetitionStatus
          variant={handleTagVariant(
            competitionJson.competitionList[0].status as NationalChampionshipProps['status'],
          )}
          label={hstData?.tag.title}
          sx={{ top: '-10px' }}
        />

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '44px',
            background: handleBoxGradient(
              competitionJson.competitionList[0].status as NationalChampionshipProps['status'],
            ),
            top: '-19px',
          }}
        />

        {hstData?.contender && (
          <Box>
            <Text
              variant="h2"
              sx={{ color: 'neutral300', lineHeight: '34px', letterSpacing: '0.4px', px: '16px' }}
            >
              {hstData?.contender.label}
            </Text>
            <ScrollView
              direction="horizontal"
              margin={'16px 0 40px 0'}
              display="flex"
              align="start"
            >
              {hstData?.contender.candidate.map((data: CandidateProps, idx: number) => {
                const candidateLengthForIdx = hstData?.contender.candidate.length - 1;
                return (
                  <Box sx={{ '&:first-child': { ml: '16px' } }}>
                    <CardSchool
                      schoolLogo={`${process.env.PUBLIC_URL}/media/images/sekolah/${data.schoolLogo}.png`}
                      schoolName={data.schoolName}
                      teamLogo={
                        data.teamLogo
                          ? `${process.env.PUBLIC_URL}/media/images/tim/${data.teamLogo}.png`
                          : undefined
                      }
                      teamName={data.teamName}
                      sx={{ mr: idx === candidateLengthForIdx ? '16px' : '12px' }}
                    />
                  </Box>
                );
              })}
            </ScrollView>
          </Box>
        )}

        {hstData?.cardBanner && (
          <CardBanner
            title={
              <>
                <Box sx={{ width: 'calc(100% - 105px)' }}>{hstData?.cardBanner.title}</Box>
                <CardMedia
                  component="img"
                  src={ImageSource(hstData?.cardBanner.imgTitle, 'png')}
                  sx={{
                    position: 'absolute',
                    top: '15px',
                    right: 0,
                    width: '120px',
                    height: '128px',
                  }}
                />
              </>
            }
            cta={
              <Link to={hstData?.cardBanner.link} style={{ textDecoration: 'none' }}>
                <PrimaryButton variant="small">{hstData?.cardBanner.textButton}</PrimaryButton>
              </Link>
            }
            sx={{
              background: `url(${ImageSource(
                hstData?.cardBanner.background,
                'png',
              )}) no-repeat center / cover`,
              ...shadowOrangeStyled,
              m: '0 16px 50px 16px',
            }}
          />
        )}

        <News
          title={hstData?.news1.title}
          content={hstData?.news1.content.text}
          sx={{ m: '0 16px 40px 16px' }}
        />

        {hstData?.champion && (
          <Champion
            title={hstData?.champion.title}
            description={hstData?.champion.description.toUpperCase()}
            candidate={restrutureCandidateData(hstData?.champion.candidate)}
            cta={
              <Link to={hstData?.champion.link} style={{ textDecoration: 'none' }}>
                <SecondaryButton variant="small" wide sx={{ outline: 0 }}>
                  {hstData?.champion.textButton}
                </SecondaryButton>
              </Link>
            }
            backgroundImage={
              hstData?.champion.candidate
                ? ImageSource('bg_competition_hof_yellow', 'png')
                : ImageSource('bg_hof-empty', 'png')
            }
            sx={{ mb: '40px' }}
          />
        )}

        <News
          title={hstData?.news2.title}
          content={
            <>
              <Box sx={{ pl: '16px' }}>
                <CardMedia
                  component="img"
                  src={ImageSource(hstData?.news2.content.image, 'png')}
                  alt={hstData?.news2.title}
                  sx={{ mt: '-40px', mb: '24px' }}
                />
              </Box>
              <Box sx={{ px: '16px' }}>{hstData?.news2.content.text}</Box>
            </>
          }
          cta={
            <Box sx={{ px: '16px' }}>
              <a href={hstData?.news2.link} target="_blank">
                <SecondaryButton variant="small" wide>
                  {hstData?.news2.textButton}
                </SecondaryButton>
              </a>
            </Box>
          }
          sx={{ mb: '30px', h2: { px: '16px' } }}
        />

        {hstData?.register && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={theme => ({
              position: 'sticky',
              bottom: 0,
              bgcolor: 'neutral900',
              p: '14px 16px',
              borderTop: `1px solid ${theme.palette.neutral530}`,
              pointerEvents: hstData?.register.link ? 'unset' : 'none',
              zIndex: 5,
            })}
          >
            <Text
              variant="body"
              sx={{ mr: '12px', fontSize: '12px', lineHeight: '15px', color: 'neutral400' }}
            >
              {hstData?.register.label}
            </Text>
            <Link
              to={hstData?.register.link}
              target={hstData?.register.newTabLink ? '_blank' : '_self'}
              style={{ textDecoration: 'none' }}
            >
              <SecondaryButton variant="small" sx={{ whiteSpace: 'nowrap' }}>
                {competitionJson.competitionList[0].status === 'hstInProgress' && (
                  <img src={IconSource('wa_active')} alt="whatsapp mabar" width={18} />
                )}
                <Box component="span" sx={{ ml: '4px' }}>
                  {hstData?.register.textButton}
                </Box>
              </SecondaryButton>
            </Link>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default NationalChampionshipPage;
