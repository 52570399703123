import React, { useState } from 'react';
import { IconSource, ImageSource } from 'modules/assetpath';
import AppLayout from 'components/layouts/AppLayout';
import GameList from 'components/Lists/GameList';
import { Stack } from 'components/styled/layout.styled';
import { setGameSelectedAction } from 'redux/team/TeamDraftReducer';
import { GameOptions, GamePagedResponse, getGameTeamListService } from 'redux/game/GameReducer';
import { NormalText } from 'components/styled/text.styled';
import { useAppThunkDispatch } from 'redux/store';
import { useTranslation } from 'react-i18next';
import history from 'modules/history';
import { getPlayerProfileFromSession } from 'redux/account/AccountServices';
import GradientButton from 'components/Buttons/GradientButton';

const ChooseGamePage = () => {
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation()
  const [getErrors, setErrors] = useState<{ isAxiosError: boolean; errors: any; }>()
  const [loading, setLoading] = useState(false)
  let account = getPlayerProfileFromSession();

  let saveGameSelected = React.useCallback(
    (gameSelected: GameOptions) => dispatch(setGameSelectedAction({ game: gameSelected })),
    [dispatch],
  );

  const [getGameList, setGameList] = useState<GameOptions[]>();

  React.useEffect(() => {
    setLoading(true)
    dispatch(getGameTeamListService({playerSlug: account?.userName ?? '', pageIndex: 1, pageSize: 20})).unwrap().then((v)=> {
      const error = (v as { isAxiosError: boolean; errors: any; })
      const data = v as GamePagedResponse<GameOptions[]>
      if(error && error?.isAxiosError){
        setErrors(error)
        setLoading(false)
      }else{
        setGameList(data.items)
        setLoading(false)
      }
    }).catch((err)=> {
      console.error(err)
    })
  }, [dispatch]);

  let handleGameSelected = (e: GameOptions) => {
      saveGameSelected(e)
      setTimeout(()=> {        
        history.push('/create-team/team-list');
      }, 200)
  };

  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
          onClick: () => {
            history.push('/my-team');
          },
        },
        navright: {
          variant: '',
        },
        isDisable: false,
        title: 'Pilih Game',
      }}
      backgroundImage={ImageSource('app.background')}
      opacityBg="0.03"
      pageTitle="Create Team > Choose Game"
    >
      { getGameList && getGameList.length >= 0 ? (
      <Stack align="center" direction="column" margin="24px 0px 0px 0px">
      {getGameList.map(data => {
          return (
            <GameList
              key={data.id}
              width="100%"
              heigth="160px"
              gameOptions={data}
              gameBg={data.avatarUrl}
              gameInfo={`${data.teamCount?.toLocaleString() ?? 0} Tim, ${data.tournamentCount?.toLocaleString()?? 0} ${t('general_tournament')}, ${data.scrimCount?.toLocaleString()?? 0} ${t('general_scrim')}`}
              gameName={data.name}
              onClick={e => handleGameSelected(e)}
              variant="active"
              styled={{ marginBottom: '12px' }}
            />
          );
      })}
      </Stack>
      ) : !loading && <EmptyStateGame isAxiosError={getErrors?.isAxiosError} errors={getErrors?.errors}/>}
    </AppLayout>
  );
};

const EmptyStateGame = (p:{ isAxiosError?: boolean; errors?: any; }) => {
  const {t} = useTranslation()
  return (        
    <Stack direction="column" align="center" margin="54px 0px 0px 0px" disableSelectText disableDrag>
    <img alt="" src={IconSource('bg-game-empty-state')} />
    <NormalText
      fontSize="18px"
      fontWeight="700"
      color="white"
      lineHeight="26.28px"
      textAlign="center"
      margin="24px 0px 0px 0px"
    >
      {p.errors?.status === 1300 ? 'Kamu Belum Memiliki Game ID': p.errors?.status === 403 ? 
      t('mabar:errors.HTTP403') :  p.errors?.status === 503 ? t('mabar:errors.HTTP503') :
      'Kamu Sudah Mempunyai Tim'}
    </NormalText>
    <NormalText
      fontSize="14px"
      fontWeight="400"
      color="#969393"
      lineHeight="20.44px"
      textAlign="center"
      margin="10px 0px 0px 0px"
    >
      {p.errors?.status === 1300 ? 
      'Yuk lengkapi Game ID kamu!' : !p.errors?.status ? 
      'Kamu bisa Keluar dari tim lama untuk membuat tim baru' : ''}
    </NormalText>
    {p.errors?.status === 1300 && (
      <GradientButton variant='primary' backgroundSize='100%' margin='24px 0px 0px'
        onClick={()=> history.push('/account/add-game')}>
        <NormalText
          fontSize="14px"
          fontWeight="700"
          fontStyle='normal'
          color="white"
          lineHeight="19.6px"
          textAlign="center">
        Tambah Informasi Game
        </NormalText>
      </GradientButton>
    )
    }
  </Stack>
  )
}

export default ChooseGamePage;
