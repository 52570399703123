import { Theme, Box, Stack } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Text } from 'design/Text';
import React, { ReactNode } from 'react';

export interface CardActivityScrimStyledProps {
  theme: Theme;
  sx?: any;
}

const CardActivityScrimStyled: any = styled(Box, {
  name: 'card-section',
})(({ theme, sx }: CardActivityScrimStyledProps) => ({
  width: '100%',
  padding: '1px',
  paddingBottom: '0.5px',
  background: 'linear-gradient(140deg, rgba(243, 171, 54, 0.6) 3%, #17181A 22%, #17181A)',
  borderRadius: theme.borderRadius.md,
  boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.24)',
  boxSizing: 'border-box',
  ...sx,
}));

export interface CardActivityScrimProps {
  title: string;
  value: string | number;
  description: string | ReactNode;
  sx?: SxProps<Theme>;
}

export const CardActivityScrim = ({ title, value, description, sx }: CardActivityScrimProps) => {
  return (
    <CardActivityScrimStyled sx={sx}>
      <Stack
        sx={theme => ({ p: '12px', borderRadius: theme.borderRadius.md, bgcolor: 'neutral700' })}
      >
        <Text variant="labelSmall" sx={{ color: 'neutral400', textTransform: 'uppercase' }}>
          {title}
        </Text>
        <Text variant="h2" sx={{ fontWeight: 500, lineHeight: '30px', mt: '8px' }}>
          {value}
        </Text>
        <Text
          variant="ribbon"
          sx={{ color: 'neutral300', fontWeight: 500, letterSpacing: '0.16px' }}
        >
          {description}
        </Text>
      </Stack>
    </CardActivityScrimStyled>
  );
};
