import BackgroundImage from 'components/layouts/BackgroundImage';
import { Avatar } from 'components/styled/avatar.styled';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import React, { FC } from 'react';

export interface HeaderTeamProfileType {
  boxColor?: string | undefined;
  bgImage?: string | undefined;
  gameName?: string | undefined;
  teamName?: string | undefined;
  teamLogo?: string | undefined;
  teamRank?: string[] | undefined;
  teamInfo?: string | undefined;
  teamCreated?: string | undefined;
  iconTopRight?: string | undefined;
  iconTopRightClick?: (() => void) | undefined;
  onTeamClick?:
    | ((data?: any, element?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  dataTeam?: any;
  padding?: string;
  styled?: React.CSSProperties;
  variant?: 'Approve' | 'Waiting' | 'Locked' | undefined;
}

const HeaderTeamProfile: FC<HeaderTeamProfileType> = ({
  boxColor = '#292A2F',
  bgImage,
  gameName,
  teamName,
  teamLogo,
  teamRank,
  teamInfo,
  teamCreated,
  iconTopRightClick,
  onTeamClick,
  dataTeam,
  iconTopRight,
  padding,
  styled,
  variant
}) => {
  return (
    <Box
      width="100%"
      padding={padding ?? '16px 0px 16px 0px'}
      disableDrag
      isPressble={variant != 'Locked' && onTeamClick ? true : false}
    >
      <Box
        flexGrow={1}
        background={boxColor}
        borderRadius="20px"
        padding={boxColor === 'transparent' ? '0px' : '4px 4px 4px 4px'}
        onClick={e => {
          if (variant != 'Locked' && onTeamClick ) {
            onTeamClick(dataTeam, e);
          }
        }}
        style={styled}
      >
        <BackgroundImage
          borderRadius="16px"
          minHeight="154px"
          backgroundColor="#1D1D20"
          mask="linear-gradient(177deg, #1D1D20 20.56%, rgba(29, 29, 32, 0.22) 55.34%, rgba(29, 29, 32, 0.033) 79.09%, rgba(29, 29, 32, 0.033) 30.39%, rgba(29, 29, 32, 0.012) 69.5%)"
          src={bgImage}
        >
          <Stack direction="row" align="end">
            {iconTopRight && (
              <Avatar
                alt="Setting Icon"
                onClick={iconTopRightClick}
                background="rgba(255, 255, 255, 0.16)"
                margin="12px"
                width="36px"
                height="36px"
                borderRadius="8px"
                padding="8px"
                src={iconTopRight}
              />
            )}
          </Stack>
          <Box flexGrow={1} />
          <Stack direction="row" align="start" disableSelectText>
            <Avatar
              alt="Team Logo"
              margin="8px 8px 16px 16px"
              width="68px"
              height="68px"
              borderRadius="12px"
              border="2px solid #F1EBEB"
              fit={teamLogo ? "cover": "contain"}
              objectPos={teamLogo ? "center": "bottom"}
              background={teamLogo ? "transparent": "#B1B1B1"}
              src={teamLogo ?? IconSource('union-placeholder')}
              onError={(e) => {
                e.currentTarget.style.backgroundColor = "#B1B1B1"
                e.currentTarget.src = IconSource('union-placeholder')
                e.currentTarget.style.objectFit = "contain"
                e.currentTarget.style.objectPosition = "bottom"
              }}
            />
            <Box padding="0px 8px" height="100%">
              <Stack
                direction="column"
                align="start"
                style={{ justifyContent: 'flex-end', margin: '-8px 0px' }}
              >
                {gameName && (
                  <NormalText
                    margin="8px 0px"
                    color="white"
                    fontStyle="normal"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    {gameName}
                  </NormalText>
                )}
                <NormalText
                  margin={teamRank ? '8px 0px' : '2px 0px'}
                  color="white"
                  fontStyle="normal"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  {teamName}
                </NormalText>
                {teamRank && (
                  <Box>
                    <Stack direction="row" align="start" style={{ flexWrap: 'wrap' }}>
                      {teamRank.map((rank: string, index: number) => {
                        return (
                          <Avatar
                            key={index}
                            alt={`image${index}`}
                            height="24px"
                            width="24px"
                            padding="2px"
                            src={rank}
                          />
                        );
                      })}
                    </Stack>
                  </Box>
                )}
                {teamInfo !== undefined && (
                  <NormalText
                    color="rgba(255, 255, 255, 0.7)"
                    fontStyle="normal"
                    fontSize="12px"
                    fontWeight="400"
                    margin="3px 0px 0px 0px"
                  >
                    {teamInfo}
                  </NormalText>
                )}
                <NormalText
                  color="rgba(150, 147, 147, 1)"
                  fontStyle="normal"
                  fontSize="8px"
                  fontWeight="500"
                  margin="4px 0px 0px 0px"
                >
                  {teamCreated}
                </NormalText>
                <Box height="24px" />
              </Stack>
            </Box>
          </Stack>                      
        {variant === 'Waiting' && (
            <Box
              style={{ position: 'absolute' }}
              borderRadius="20px"
              padding={boxColor === 'transparent' ? '0px' : '4px 4px 4px 4px'}
              width='100%'
              height='100%'
              background="rgba(46, 50, 56, 0.85)"
            >
              <Stack direction="column" align="center" disableSelectText>
                <Avatar width="30px" height="30px" src={IconSource('ic-information-circle-white')} />
                <NormalText
                  margin="8px 0px"
                  color="white"
                  fontStyle="normal"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="22.4px"
                >
                  Menunggu Persetujuan
                </NormalText>
              </Stack>
            </Box>
          )}   
        {variant === 'Locked' && (
            <Box
              style={{ position: 'absolute' }}
              borderRadius="20px"
              padding={boxColor === 'transparent' ? '0px' : '4px 4px 4px 4px'}
              width='100%'
              height='100%'
              background="rgba(46, 50, 56, 0.85)"
            >
              <Stack direction="column" align="center" disableSelectText>
                <Avatar width="30px" height="32px" src={IconSource('ic-lock-closed-white')} />
                <NormalText
                  margin="8px 0px"
                  color="white"
                  fontStyle="normal"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="22.4px"
                >
                  Team di non-aktifkan
                </NormalText>
              </Stack>
            </Box>
          )}
        </BackgroundImage> 
      </Box>
    </Box>
  );
};

export default HeaderTeamProfile;
