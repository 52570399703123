import { Stack, Box, Theme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface TeamSchoolItemProp {
  image?: string;
  title?: string;
  subTitle?: {
    icon?: string;
    text?: string | ReactNode;
  };
  onClickCardNew?: () => void;
  cta?: ReactNode;
  sx?: SxProps<Theme>;
  bgImage?: string;
  overlay?: boolean;
}

export const TeamSchool = ({
  image,
  title,
  subTitle,
  onClickCardNew,
  cta,
  sx,
  bgImage,
  overlay = false,
}: TeamSchoolItemProp) => {
  return (
    <Box sx={{ position: 'relative', mb: '16px' }}>
      {onClickCardNew ? (
        <>
          <Box
            sx={{
              minHeight: '87px',
              borderRadius: '8px',
              boxShadow:
                '-2px 4px 8px rgba(208, 43, 32, 0.25), 2px 4px 8px rgba(243, 112, 36, 0.25)',
              border: '1px solid #F37024',
              bgcolor: '#27282B',
              ...sx,
            }}
            onClick={onClickCardNew}
          >
            <Stack direction="row">
              <Avatar
                variant="rounded"
                alt="Remy Sharp"
                src={image}
                sx={{
                  width: '87px',
                  height: '87px',
                  borderRight: '1px solid #F37024',
                  borderRadius: '0',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  background: '#1A1817',
                  img: {
                    objectFit: 'contain',
                  },
                }}
              />
              <Stack direction="column" sx={{ ml: '12px', mr: '5px' }}>
                <Text
                  variant="labelSmall"
                  sx={{
                    fontFamily: 'Lexend Deca',
                    fontStyle: 'SemiBold',
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: '600',
                    letterSpacing: '0px',
                    mt: '8px',
                    color: '#D4D6D8',
                  }}
                >
                  {title}
                </Text>
                {subTitle?.text && (
                  <Text
                    variant="subtitle"
                    sx={{
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '16px',
                      mt: '4px',
                      color: '#94989E',
                      letterSpacing: '0px',
                    }}
                  >
                    {subTitle?.text}
                  </Text>
                )}
              </Stack>
              <Stack direction="column" justifyContent="center" alignItems="center">
                <Avatar
                  variant="rounded"
                  sx={{
                    width: '26.67px',
                    height: '26.67px',
                    mr: '13.67px',
                  }}
                  alt="ADD"
                  src={IconSource('ic-add-plus')}
                />
              </Stack>
            </Stack>
          </Box>
        </>
      ) : (
        <>
          {overlay && (
            <>
              <Box
                sx={{
                  minHeight: '90px',
                  borderRadius: '8px',
                  boxShadow: '#2E3033 0px 0px 0px 1px',
                  bgcolor: 'rgba(26, 24, 23, 0.5)',
                  position: 'absolute',
                  zIndex: 2,
                  width: '100%',
                  display: 'block',
                }}
              ></Box>
            </>
          )}

          <Box
            sx={{
              minHeight: '90px',
              borderRadius: '8px',
              boxShadow: '#2E3033 0px 0px 0px 1px',
              bgcolor: 'rgba(26, 24, 23, 1)',
              ...(bgImage && {
                background: `url(${bgImage}) no-repeat center right / cover`,
              }),
              ...sx,
            }}
          >
            <Stack direction="row" alignItems="center">
              <Avatar
                variant="rounded"
                alt="Remy Sharp"
                src={image}
                sx={{
                  width: '87px',
                  height: '87px',
                  border: onClickCardNew ? '#F37024 solid 2px' : '#F1EBEB solid 2px',
                  borderRadius: '8px',
                }}
              />
              <Stack
                direction="column"
                justifyContent="flex-start"
                sx={{ width: '100%', p: '0 12px' }}
              >
                <Text variant="label">{title}</Text>

                {subTitle?.text && (
                  <Stack sx={{ mt: '4px' }} direction="row" alignItems="center">
                    {subTitle?.icon && (
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: '12px',
                          height: '12px',
                          mr: '4px',
                        }}
                        alt="Subtitle Icon"
                        src={subTitle?.icon}
                      />
                    )}
                    <Text
                      variant="subtitle"
                      sx={theme => ({
                        color: theme.palette.neutral400,
                      })}
                    >
                      {subTitle?.text}
                    </Text>
                  </Stack>
                )}

                {cta}
              </Stack>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};
