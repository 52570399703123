import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { TagProps } from './';

const handleBackground = (color: any, theme: any) => {
  switch (color) {
    case 'green':
      return theme.palette.greenGradientRadial;
    case 'yellow':
      return theme.palette.yellowGradientRadial;
    case 'orange':
      return theme.palette.orangeGradientRadial;
    case 'gray':
      return theme.palette.grayGradientRadial;
    case 'red':
      return theme.palette.redGradientRadial;
    case 'black':
      return theme.palette.neutral700;
    default:
      return theme.palette.grayGradientRadial;
  }
};

const handleBackgroundShadow = (color: any, theme: any) => {
  switch (color) {
    case 'green':
      return theme.palette.success600;
    case 'yellow':
      return theme.palette.yellow02;
    case 'orange':
      return theme.palette.orange01;
    case 'gray':
      return theme.palette.neutral400;
    case 'red':
      return theme.palette.danger600;
    case 'black':
      return theme.palette.neutral900;
    default:
      return theme.palette.neutral400;
  }
};

export const CustomTag: any = styled(Box, {
  shouldForwardProp: props => props !== 'variant',
})<TagProps>(({ theme, variant }: any) => ({
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  backgroundBlendMode: 'overlay, normal',
  background: handleBackground(variant, theme),
  span: {
    padding: '3px 8px',
    margin: 0,
    textTransform: 'uppercase',
    color: variant == 'black' ? theme.palette.neutral200 : theme.palette.neutral800,
  },
}));

export const CustomTagTournamentCard = styled(CustomTag)<TagProps>(({ theme, variant }: any) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  borderBottomLeftRadius: '8px',
  '&:before': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: handleBackgroundShadow(variant, theme),
    bottom: '-4px',
    left: '4px',
    zIndex: 0,
    borderBottomLeftRadius: '8px',
  },
  span: {
    display: 'block',
    width: '100%',
    height: '100%',
    background: 'inherit',
    position: 'relative',
    zIndex: 1,
    borderBottomLeftRadius: '8px',
  },
}));
