import { CustomTextButton } from './styles';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text, CustomTextProps } from 'design/Text';
import React, { HTMLAttributes, ReactNode } from 'react';
import SVG from 'react-inlinesvg';

export interface TextButtonProps extends HTMLAttributes<HTMLButtonElement> {
  theme?: Theme;
  sx?: SxProps<Theme>;
  icon?: string;
  disabled?: boolean;
  textsx?: CustomTextProps['sx'];
  variant?: CustomTextProps['variant'];
  gradient?: CustomTextProps['gradient'];
  children?: ReactNode;
}

export const TextButton = (props: TextButtonProps) => {
  return (
    <CustomTextButton disabled={props.disabled} {...props}>
      {props.icon && <SVG src={props.icon} />}
      <Text
        variant={props.variant || 'button'}
        component="span"
        gradient={props.gradient}
        sx={{
          svg: {
            mr: props.children ? '6px' : 0,
          },
          ...props.textsx,
        }}
      >
        {props.children}
      </Text>
    </CustomTextButton>
  );
};
