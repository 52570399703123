import { Theme, Box, Avatar, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { Text } from 'design/Text';
import { IconSource, ImageSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface CandidateProps {
  schoolLogo: string;
  schoolName: string;
  teamLogo?: string;
  teamName: string;
}

export interface ChampionProps {
  title: string;
  description: string | ReactNode;
  candidate: CandidateProps[];
  cta?: ReactNode;
  backgroundImage?: string;
  sx?: SxProps<Theme>;
}

export const Champion = ({
  title,
  description,
  candidate,
  cta,
  backgroundImage,
  sx,
}: ChampionProps) => {
  const handleMarginTop = (idx: number) => {
    if (idx == 0) return '0';
    return '18px !important';
  };

  const handleCrownTitle = (idx: number) => {
    if (idx == 0) {
      return (
        <>
          <img
            src={IconSource('ic-crown')}
            alt="crown"
            style={{ display: 'block', margin: '0 auto' }}
          />
          1st Place
        </>
      );
    }
    if (idx == 1) return '2nd Place';
    return '3rd Place';
  };

  const handleOpacityCrownTitle = (idx: number) => {
    if (idx == 0) return 1;
    return 0.8;
  };

  const handleOrder = (idx: number) => {
    if (idx == 0) return 1;
    if (idx == 1) return 0;
    return 2;
  };

  const handleSchoolLogo = (idx: number) => {
    if (idx == 0) return { width: 60, height: 60 };
    return { width: 40, height: 40 };
  };

  const handleBorderRadiusLogo = (idx: number) => {
    if (idx == 0) return '9.84px';
    return '6.56px';
  };

  const handleEmptySchoolLogo = (candidate: any, idx: number) => {
    if (!candidate) {
      if (idx == 0)
        return {
          width: '25px',
          height: 'auto',
        };
      return {
        width: '16px',
        height: 'auto',
      };
    }
    return undefined;
  };

  return (
    <Box
      sx={{
        p: '24px 16px',
        textAlign: 'center',
        background: 'linear-gradient(135.22deg, #3A0102 -18.64%, #640012 45%)',
        backgroundImage: `url(${
          backgroundImage ? backgroundImage : ImageSource('bg_hof-plain', 'png')
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        ...sx,
      }}
    >
      <Text variant="h3" sx={{ color: 'neutral100', mb: '6px' }}>
        {title}
      </Text>
      <Text
        variant="ribbon"
        component="span"
        sx={{
          position: 'relative',
          display: 'block',
          zIndex: 1,
          color: 'white',
          opacity: typeof description !== 'string' ? 1 : 0.5,
          letterSpacing: '0.5px',
          span: {
            color: 'primaryOrange1200'
          }
        }}
      >
        {description}
      </Text>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mt: '16px', mb: '24px' }}
      >
        {(candidate ?? [{}, {}, {}]).map((team, idx) => (
          <Box
            className="candidate"
            sx={{
              textAlign: 'center',
              order: handleOrder(idx),
              width: 110,
              mt: handleMarginTop(idx),
            }}
          >
            <Text
              variant="labelSmall"
              sx={{
                fontFamily: 'Inter',
                fontWeight: '700',
                lineHeight: '8px',
                color: 'white',
                display: 'block',
                letterSpacing: '0',
                opacity: handleOpacityCrownTitle(idx),
              }}
            >
              {handleCrownTitle(idx)}
            </Text>
            <Box
              sx={theme => ({
                position: 'relative',
                mx: 'auto',
                mt: '4px',
                mb: idx == 0 ? '5px' : '4px',
                ...handleSchoolLogo(idx),
                '&:before': {
                  content: "''",
                  position: 'absolute',
                  top: candidate ? '20%' : '22%',
                  left: 0,
                  right: 0,
                  m: '0 auto',
                  width: '96%',
                  height: '84%',
                  bgcolor: candidate ? '#FFA576' : theme.palette.neutral500,
                  borderRadius: handleBorderRadiusLogo(idx),
                  zIndex: 0,
                },
              })}
            >
              <Avatar
                src={candidate ? team.schoolLogo : IconSource('ic-question')}
                sx={theme => ({
                  ...handleSchoolLogo(idx),
                  borderRadius: handleBorderRadiusLogo(idx),
                  bgcolor: candidate ? 'white' : theme.palette.neutral500,
                  border: candidate ? 'none' : `1px solid white`,
                  img: { zIndex: 0, ...handleEmptySchoolLogo(candidate, idx) },
                  '&:before': {
                    content: "''",
                    background:
                      candidate && `url(${IconSource('union-placeholder')}) center no-repeat`,
                    backgroundSize: 'contain',
                    width: idx == 0 ? '45px' : '25px',
                    height: '100%',
                    position: 'absolute',
                    zIndex: 0,
                  },
                  svg: {
                    display: 'none',
                  },
                })}
              />
            </Box>
            <Text
              variant="labelSmall"
              sx={{
                display: 'block',
                fontFamily: 'Inter',
                fontSize: '10px',
                letterSpacing: '0.16px',
                lineHeight: '140%',
                color: cta ? 'white' : 'neutral100',
              }}
            >
              {team.schoolName ?? 'Coming Soon'}
            </Text>
            {team.teamName && (
              <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: '2px' }}>
                {team.teamLogo ? (
                  <Avatar
                    src={team.teamLogo}
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '1.7px',
                      bgcolor: 'white',
                      mr: '3px',
                    }}
                  />
                ) : null}
                <Text
                  variant="labelSmall"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '10px',
                    letterSpacing: '0.16px',
                    lineHeight: '140%',
                    color: cta ? 'white' : 'neutral300',
                    opacity: cta ? 0.6 : 1,
                  }}
                >
                  {team.teamName}
                </Text>
              </Stack>
            )}
          </Box>
        ))}
      </Stack>

      {cta}
    </Box>
  );
};
