import { Stack } from '@mui/material';
import { CardActivityScrim } from 'design/Cards/CardActivityScrim';
import { CardSection } from 'design/Cards/CardSection';
import { Text } from 'design/Text';
import React, { memo, useCallback, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { fetchScrimTeamStatistic } from 'redux/scrim/ScrimService';
import { useAppThunkDispatch } from 'redux/store';

const ActivityScrim = () => {
  const dispatch = useAppThunkDispatch();

  const { teamDetail, scrimStatistic } = useAppSelector(({ teamProfile, scrimList }) => ({
    teamDetail: teamProfile?.teamDetail,
    scrimStatistic: scrimList?.scrimTeamStatistic,
  }));

  const handleFetchScrimTeamStatistic = (teamId: string) => {
    dispatch(
      fetchScrimTeamStatistic({
        teamId: teamId,
      }),
    ).unwrap();
  };

  const handleFetchCallback = useCallback(() => {
    if (teamDetail) {
      handleFetchScrimTeamStatistic(teamDetail?.id);
    }
  }, [teamDetail?.id]);

  useEffect(() => {
    handleFetchCallback();
  }, [handleFetchCallback]);

  const dataAktivitasScrim = [
    {
      title: 'Total Scrim',
      value: scrimStatistic?.totalScrim ?? 0,
      description: 'Match',
    },
    {
      title: 'Total Lawan',
      value: scrimStatistic?.totalSchool ?? 0,
      description: (
        <>
          Sekolah dari <strong>{scrimStatistic?.totalProvince ?? 0}</strong> Provinsi
        </>
      ),
    },
  ];

  return (
    <CardSection
      title={
        <Text variant="label" component="h5" sx={{ textTransform: 'capitalize', letterSpacing: 0 }}>
          Aktivitas Scrim
        </Text>
      }
      cta={
        <Text variant="subtitle" sx={{ color: 'neutral400' }}>
          3 Bulan Terakhir
        </Text>
      }
      sx={{ bgcolor: 'transparent', pt: '24px' }}
    >
      <Stack direction="row" spacing="12px">
        {dataAktivitasScrim.map(dataAktivitasScrim => (
          <CardActivityScrim
            key={dataAktivitasScrim.title}
            title={dataAktivitasScrim.title}
            value={dataAktivitasScrim.value}
            description={dataAktivitasScrim.description}
            sx={{
              background:
                'linear-gradient(140deg, rgba(225, 53, 85, 0.6) 3%, #27282B 28%, #27282B)',
              boxShadow: 'none',
            }}
          />
        ))}
      </Stack>
    </CardSection>
  );
};

export default memo(ActivityScrim);
