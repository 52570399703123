export const STATUS = {
  UNKNOWN: 0,
  EXPIRED: 1,
  REJECT: 2,
  OPEN: 3,
  INVITATION: 4,
  SCHEDULED: 5,
  CHECKIN: 6,
  COMPETE: 7,
  NOTVALID: 8,
  RESULTUPLOAD: 9,
  DISPUTE: 10,
  RESULTCALCULATED: 11,
  DATACOMPLETED: 12,
  BYE: 13,
};

export const RESULT_TYPE = {
  NORMAL_WIN: 1,
  WALKOVER: 2,
  NOMATCH: 3,
};

export const TYPE = {
  BO1: 1,
  BO3: 3,
  BO5: 5,
};
