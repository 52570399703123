import { PlayerData } from '../Member';
import { Stack, Avatar } from '@mui/material';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardPlayerDetail } from 'design/Cards/CardPlayerDetail';
import { DrawerBottom } from 'design/Drawer/DrawerBottom';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface PlayerDetailDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  playerData?: PlayerData;
  isCaptain?: boolean;
  acceptMember: (playerId: string, teamId: string) => void;
  removeMember: (playerId: string, teamId: string) => void;
  setIsOpenPlayerDetail: (value: boolean) => void;
  setModalAction: (
    value:
      | 'confirm-action'
      | 'reject-request-join'
      | 'kick-member'
      | 'failed-kick-player'
      | 'failed-delegate-captain'
      | 'delegate-captain'
      | 'api-failed',
  ) => void;
  setOpenModalConfirmation: (value: boolean) => void;
  checkActiveTournamentMember: (playerIdToCheck: string) => void;
}

const PlayerDetailDrawer = ({
  open,
  onClose,
  onOpen,
  playerData,
  isCaptain,
  acceptMember,
  removeMember,
  setIsOpenPlayerDetail,
  setModalAction,
  setOpenModalConfirmation,
  checkActiveTournamentMember,
}: PlayerDetailDrawerProps) => {
  const { t } = useTranslation();

  return (
    <DrawerBottom
      open={open}
      iconClose={false}
      onClose={onClose}
      onOpen={onOpen}
      paperSx={{ overflow: 'visible' }}
    >
      <CardPlayerDetail
        playerName={playerData?.playerName}
        playerPhoto={playerData?.playerPhoto}
        gameId={playerData?.gameId ?? ''}
        nickname={playerData?.nickname ?? ''}
        url={`/player/${playerData?.urlSlug}`}
        cta={
          isCaptain && playerData?.status === 'Waiting' && playerData?.type === 'Request' ? (
            <Stack
              direction="row"
              spacing={1}
              sx={theme => ({
                borderTop: `1px solid ${theme.palette.neutral530}`,
                pt: '16px',
              })}
            >
              <PrimaryButton
                wide
                variant="small"
                onClick={() => acceptMember(playerData?.playerId ?? '', playerData?.teamId ?? '')}
              >
                {t('invitation_accept')}
              </PrimaryButton>
              <SecondaryButton
                wide
                variant="small"
                onClick={() => {
                  setIsOpenPlayerDetail(false);
                  setModalAction('reject-request-join');
                  setOpenModalConfirmation(true);
                }}
              >
                {t('invitation_reject')}
              </SecondaryButton>
            </Stack>
          ) : isCaptain && playerData?.status === 'Waiting' && playerData?.type == 'Invite' ? (
            <Stack
              direction="row"
              alignItems="center"
              sx={theme => ({
                borderTop: `1px solid ${theme.palette.neutral530}`,
                cursor: 'pointer',
                p: '16px 0 6px',
              })}
              onClick={() => removeMember(playerData?.playerId ?? '', playerData?.teamId ?? '')}
            >
              <Avatar src={IconSource('ic-circle-close')} sx={{ width: '24px', height: '24px' }} />
              <Text variant="button" sx={{ ml: '8px' }}>
                Batalkan Undangan Bergabung
              </Text>
            </Stack>
          ) : isCaptain && playerData?.level !== 'Captain' ? (
            <Stack
              direction="column"
              sx={theme => ({ borderTop: `1px solid ${theme.palette.neutral530}` })}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ cursor: 'pointer', p: '16px 0' }}
                onClick={() => {
                  setIsOpenPlayerDetail(false);
                  setModalAction('delegate-captain');
                  setOpenModalConfirmation(true);
                }}
              >
                <Avatar
                  src={IconSource('ic-switch-captain')}
                  sx={{ width: '24px', height: '24px' }}
                />
                <Text variant="button" sx={{ ml: '8px' }}>
                  Pilih Jadi Kapten Baru
                </Text>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={theme => ({
                  cursor: 'pointer',
                  p: '16px 0 6px',
                  borderTop: `1px solid ${theme.palette.neutral530}`,
                })}
              >
                <Avatar src={IconSource('ic-trash')} sx={{ width: '24px', height: '24px' }} />
                <Text
                  variant="button"
                  sx={{ ml: '8px' }}
                  onClick={() => {
                    checkActiveTournamentMember(playerData?.playerId ?? '');
                  }}
                >
                  Keluarkan Anggota
                </Text>
              </Stack>
            </Stack>
          ) : undefined
        }
      />
    </DrawerBottom>
  );
};

export default memo(PlayerDetailDrawer);
