// import GradientButton from 'components/Buttons/GradientButton';
import HeaderMatchDetails from 'components/cards/HeaderMatchDetails';
import PlayerCard from 'components/cards/PlayerCard';
import { TabButtonType } from 'components/layouts/TabButton';
import TabMenu from 'components/layouts/TabMenu';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { ImageSource } from 'modules/assetpath';
// import { Status } from 'components/Status/index';
import { Checkbox } from 'components/Inputs/checkbox';
import React, { useState, useEffect } from 'react';
import AppLayout from 'components/layouts/AppLayout';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getScrimDetail } from 'redux/scrim/ScrimDetailReducer';
import { MatchCardType } from 'components/cards/MatchCard';
import { TeamCardType } from 'components/cards/TeamCard';
import { IconSource } from 'modules/assetpath';
import { useParams } from 'react-router-dom';

const WaitTeam = () => {
    //@ts-ignore
    let { scrim_id } = useParams();
  const dispatch = useAppDispatch();
  const {data} = useAppSelector((state) => state.scrimDetail);  
  let [getDataScrim, setDataScrim] = React.useState(data);
  const [dataPlayers, setDataPlayers] = useState(getDataScrim.firstTeamPlayers);

  useEffect(() => {
    dispatch(getScrimDetail({id: scrim_id}))
  },[dispatch])

  const teamLeft: TeamCardType = {
    teamPosition: 'left',
    teamLogo: getDataScrim.firstTeamAvatar,
    teamName: getDataScrim.firstTeam,
    teamSchool: getDataScrim.firstTeamSchool,
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
  };
  const teamRight: TeamCardType = {
    teamPosition: 'right',
    teamLogo: getDataScrim.secondTeamAvatar,
    teamName: getDataScrim.secondTeam,
    teamSchool: getDataScrim.secondTeamSchool,
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 90.77%)',
  };
  
  const scrimTime = new Date(data.startAt).toLocaleTimeString('id-ID', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  const matchResult: MatchCardType = {
    status: scrimTime.replace('.', ':'),
    matchType: 'Best of 3',
  };

  document.addEventListener('DOMContentLoaded', function() {
    setDataScrim(data)
 }, false);

  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(false);

  const DataTab: TabButtonType[] = [
    {
      id: 1,
      title: getDataScrim.firstTeam,
      isSelected: true,
    },
    {
      id: 2,
      title: getDataScrim.secondTeam,
      isSelected: false,
    },
  ];
  return (
    <AppLayout
      appBar={{
        bg: {},
        navleft: {
          bg: 'ic-arrow-back-light',
          width: '24px',
          height: '24px',
          onClick: () =>  window.history.back()
        },
        navright: {
          bg: 'white',
          variant: 'menu-options',
        },
        isDisable: false,
        title: `Detail Scrim #` + scrim_id,
      }}
      noMargin={true}
      backgroundImage={ImageSource('app.background')}
      showModal={showModal}
      modalContent={
        <Box padding="16px" width="100%" >
          <Stack direction="column" margin="16px">
            <NormalText
              textAlign="center"
              color="#FFFFFF"
              fontSize="16px"
              fontWeight="bold"
              margin="8px 0px"
            >
              Silahkan dibaca terlebih dahulu sebelum memulai pertandingan
            </NormalText>
            <NormalText
              textAlign="left"
              color="#FFFFFF"
              fontSize="12px"
              fontWeight="bold"
              margin="8px 0px"
            >
              1. Nickname game harus sama
            </NormalText>
            <NormalText
              textAlign="left"
              color="#FFFFFF"
              fontSize="12px"
              fontWeight="normal"
              margin="8px 0px"
            >
              Pastikan nickname game sama dengan nickname yang kamu daftarkan ke Mabar.gg
            </NormalText>
            <Box margin="16px 0px 0px 0px">
              <Stack direction="column">
                <label style={{ position: 'relative', top: '19px', width: 'fit-content' }}>
                  <Checkbox
                    onChange={() => null}
                    onClick={() => setState(!state)}
                    checked={state}
                  />
                  <span style={{ marginLeft: 8, color: 'white' }}>Pastikan kamu telah membaca</span>
                </label>
                <NormalText color="#FF4D6D" textAlign="right" onClick={() => setShowModal(false)}>
                  Batal
                </NormalText>
              </Stack>
            </Box>
          </Stack>
        </Box>
      }
    >
      {/* <Status
        label="Menunggu Seluruh Tim Check In"
        position="fixed"
        onClick={() => setShowModal(true)}
      />
      <br /> */}
      <HeaderMatchDetails
        headerBackground={ImageSource('card.background')}
        teamLeft={{
          ...teamLeft,
          onWaButtonClick: 'none',
        }}
        teamRight={{
          ...teamRight,
          onWaButtonClick: 'none',
        }}
        matchResult={matchResult}
        styled={{
          // marginTop: '20px',
          padding: '0px',
          alignSelf: 'center',
          width: '100%',
        }}
      />
      <br />
      <TabMenu
        data={DataTab}
        variant="left"
        onSelected={tab => {
          tab.id === 1 ? setDataPlayers(getDataScrim.firstTeamPlayers) : setDataPlayers(getDataScrim.secondTeamPlayers);
        }}
      />
      <Box width="100%">
        <Stack direction="column" margin="0px 16px 0px 16px">
          {dataPlayers.map((team, index: number) => {
            return <PlayerCard key={index} {...{
              playerName: `${team.name?? ''}`,
              playerId: `@${team.nickName?? ''}, Game ID. ${team.gameProviderId}`,
              playerPhoto: team.picture?? '',
              isCaptain: data.firstTeamCaptain === team.name || team.name === data.secondTeamCaptain? true : false,
              iconRight: IconSource('like')
            }} />;
          })}
        </Stack>
      </Box>
    </AppLayout>
  );
};

export default WaitTeam;
