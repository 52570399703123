import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getScrimMatchRound } from './ScrimService';

export interface ScrimDetailGameResponse {
  matchId: string;
  round: number;
  matchTime: { [key: string]: number };
  winnerTeamId: string;
  winnerTeam: string;
  loserTeamId: string;
  loserTeam: string;
  firstTeam: string;
  firstTeamAvatar: string;
  firstTeamScreenshot: string;
  firstTeamTotalKill: number;
  secondTeam: string;
  secondTeamAvatar: string;
  secondTeamScreenshot: string;
  secondTeamTotalKill: number;
  firstTeamPlayers: TeamPlayer[];
  secondTeamPlayers: TeamPlayer[];
  resultType: ResultType;
  logs: string[];
}

export interface TeamPlayer {
  id: string;
  name: string;
  nickName: string;
  gameProviderId: string;
  picture: string;
  kill: number;
  assist: number;
  death: number;
  gold: number;
  hero: string;
  isMvp: boolean;
  isCaptain: boolean;
}

export interface ResultType {
  key: string;
  value: string;
}

export interface ScrimDetailGameState {
  data: ScrimDetailGameResponse;
  tabTitle: string;
  loading: boolean;
  errorMessage: string;
}

const initialState: ScrimDetailGameState = {
  data: {
    matchId: '',
    round: 0,
    winnerTeam: '',
    firstTeam: '',
    firstTeamAvatar: '',
    firstTeamScreenshot: '',
    firstTeamTotalKill: 0,
    secondTeam: '',
    secondTeamAvatar: '',
    secondTeamScreenshot: '',
    secondTeamTotalKill: 0,
    firstTeamPlayers: [],
    secondTeamPlayers: [],
    loserTeam: '',
    matchTime: {},
    resultType: {
      key: '',
      value: '',
    },
    logs: [],
    loserTeamId: '',
    winnerTeamId: ''
  },
  tabTitle: '',
  loading: false,
  errorMessage: '',
};

const scrimDetailGameSlice = createSlice({
  name: 'scrimDetailGame',
  initialState: initialState,
  reducers: {
    getScrimDetailGame: (state, action: PayloadAction<{ matchId: string; round: number }>) => {
      state.loading = true;
      state.data = state.data;
      state.data.matchId = action.payload.matchId;
      state.tabTitle = state.data.firstTeam;
    },
    resetScrimDetailGame: () => initialState,
    successGetScrimDetailGame: (state, action) => {
      state.data = action.payload;
      state.tabTitle = action.payload.firstTeam;
      state.loading = false;
    },
    failedGetScrimDetailGame: (state, action) => {
      state.errorMessage = action.payload.error;
    },
  },
  extraReducers: (b) => {
    b.addCase(getScrimMatchRound.pending, (state)=> {
      state.loading = true;
    })
    .addCase(getScrimMatchRound.rejected, (state, action)=> {
      state.loading = true;
      state.errorMessage = action.error.message ?? '';
    })
    .addCase(getScrimMatchRound.fulfilled, (state, action)=> {
      state.loading = true;
      state.data = action.payload.data;
    })
  }
});

export const {
  resetScrimDetailGame,
  getScrimDetailGame,
  successGetScrimDetailGame,
  failedGetScrimDetailGame,
} = scrimDetailGameSlice.actions;

export default scrimDetailGameSlice.reducer;
