import { Stack, Box } from '@mui/material';
import { IconButton } from 'design/Buttons/IconButton';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { Player as PlayerCard } from 'design/Cards/Player';
import { DrawerBottom } from 'design/Drawer/DrawerBottom';
import { TextInputSearch } from 'design/Forms/TextInputSearch';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { memo, useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { Game } from 'redux/account/AccountResponse';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { Player } from 'redux/team/TeamMemberReducer';
import { inviteMember } from 'redux/teamProfile/TeamProfileService';

interface AddMemberDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  myRole?: Game;
  selectedPlayers: Player[];
  resetInputSearch: boolean;
  handleFetchPlayerList: (
    schoolId: string,
    gameId: string,
    pI: number,
    pS: number,
    search?: string,
  ) => void;
  setRefetchTeamProfile: (value: boolean) => void;
  setIsOpenAddMember: (value: boolean) => void;
  setOpenLoader: (value: boolean) => void;
  setToastErrorMessages: (value: string) => void;
  setOpenErrorToast: (value: boolean) => void;
  setToastMessages: (value: string) => void;
  setOpenToast: (value: boolean) => void;
  setError: (value: { name: string; message: string; stack: string }) => void;
}

const AddMemberDrawer = ({
  open,
  onClose,
  onOpen,
  myRole,
  handleFetchPlayerList,
  setRefetchTeamProfile,
  setIsOpenAddMember,
  setOpenLoader,
  setToastErrorMessages,
  setOpenErrorToast,
  setToastMessages,
  setOpenToast,
  setError,
}: AddMemberDrawerProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useAppThunkDispatch();
  const height = use100vh();

  const { accountInfo, teamMember, playerList, playerListStatus, gameDetail } = useAppSelector(
    ({ account, teamProfile, teamMember, game }) => ({
      accountInfo: account?.data,
      teamMember: teamProfile?.teamMember,
      playerList: teamMember?.playerSchool,
      playerListStatus: teamMember?.status?.playerSchool,
      gameDetail: game?.game,
    }),
  );

  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [resetInputSearch, setResetInputSearch] = useState(false);
  const [activeButton, setActiveButton] = useState(false);

  const maxChoosePlayer = gameDetail?.maxTeamMember ?? 0;

  const totalCheckPlayer = () => {
    let totalPlayerSelected = selectedPlayers?.length ?? 0;
    let totalPlayerMember = teamMember?.totalCount ?? 0;
    let totalPlayerCheck = totalPlayerMember + totalPlayerSelected;
    return totalPlayerCheck ?? 0;
  };

  const checkPlayerState = (playerSelected: any) => {
    if (totalCheckPlayer() >= maxChoosePlayer) {
      return selectedPlayers?.find(e => {
        return e.id === playerSelected.id;
      })
        ? false
        : true;
    } else {
      return false;
    }
  };

  const handleAddPlayerClick = (player: Player) => {
    const isChecked = !selectedPlayers.find(e => {
      return e.id === player.id;
    });
    let newData: Player[] = [];
    if (selectedPlayers?.length < maxChoosePlayer) {
      if (isChecked) {
        newData = [...selectedPlayers];
        if (
          !newData.find(e => {
            return e.id === player.id;
          })
        ) {
          newData.push(player);
        } else {
          newData = selectedPlayers.filter(el => el.id !== player.id);
        }
      } else {
        newData = selectedPlayers.filter(el => el.id !== player.id);
      }
      setSelectedPlayers(newData);
    } else {
      if (!isChecked) {
        newData = [...selectedPlayers];
        if (
          newData.find(e => {
            return e.id === player.id;
          })
        ) {
          newData = selectedPlayers.filter(el => el.id !== player.id);
          setSelectedPlayers(newData);
        }
      }
    }
  };

  const msgCountLengthPlayer = (maxMember: any) => {
    let maxPlayer = maxMember ?? 0;
    return t('create_add_player_error_full_team', { maxPlayer: maxPlayer });
  };

  const msgLoopError = (errors: any) => {
    if (errors) {
      let errorsList: string[] = [];
      Object.values(errors).forEach((prop: any) => {
        if (prop[0].code == 'RX1302') {
          errorsList = errorsList.concat(
            msgCountLengthPlayer(prop[0].formatedValue['memberCount']),
          );
        }
        // else {
        //   errorsList = errorsList.concat(_.map(prop, _.property('message')));
        // }
      });
      return errorsList.join('<br>');
    }
    return '';
  };

  const handleClickSave = () => {
    if (myRole) {
      setOpenLoader(true);
      thunkDispatch(
        inviteMember({ teamId: myRole.team.id, members: selectedPlayers.map(m => m.id) }),
      )
        .unwrap()
        .then(res => {
          setOpenLoader(false);
          setIsOpenAddMember(false);
          if (res && res?.isAxiosError && res?.data?.errors) {
            setToastErrorMessages(msgLoopError(res?.data?.errors));
            setOpenErrorToast(true);
          } else {
            setToastMessages('Undangan bergabung terkirim');
            setOpenToast(true);
            if (myRole.game)
              handleFetchPlayerList(accountInfo?.school?.id!, myRole.game.id, 1, 200);

            // clear checkbox and inputbox search
            setSelectedPlayers([]);
            setResetInputSearch(true);
          }
          setRefetchTeamProfile(true);
        })
        .catch(error => setError(error));
    }
  };

  useEffect(() => {
    if (selectedPlayers?.length >= 1) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  }, [selectedPlayers]);

  return (
    <DrawerBottom
      open={open}
      iconClose={true}
      title={<Text variant="h3">Tambah Anggota</Text>}
      onClose={onClose}
      onOpen={onOpen}
      cta={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={theme => ({
            minHeight: '60px',
            bgcolor: 'neutral900',
            borderTop: `1px solid ${theme.palette.neutral530}`,
          })}
        >
          <Box sx={{ pl: '16px' }}>
            <Text>Player terpilih</Text>
            <Text>{`${totalCheckPlayer()}/${gameDetail?.maxTeamMember ?? 0}`}</Text>
          </Box>
          <Box sx={{ pr: '16px' }}>
            <PrimaryButton
              variant="small"
              disabled={!activeButton}
              onClick={() => handleClickSave()}
            >
              Kirim Undangan
            </PrimaryButton>
          </Box>
        </Stack>
      }
      paperSx={{
        height: `calc(${height}px - 10px)`,
        maxHeight: 'initial',
      }}
      sx={{
        '.drawer-content': {
          p: 0,
          '& > .MuiBox-root': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
        },
        '.drawer-cta': {
          p: 0,
        },
      }}
    >
      <>
        <Box
          sx={theme => ({
            flex: 0,
            p: '0 16px 16px',
            borderBottom: `1px solid ${theme.palette.neutral530}`,
          })}
        >
          <Text sx={{ mb: '16px' }}>{t('select_and_send_invitation')}</Text>
          <TextInputSearch
            bgcolor="neutral600"
            placeholder="Cari player"
            resetValue={resetInputSearch}
            onChange={(value: any) => {
              if (accountInfo && myRole) {
                handleFetchPlayerList(
                  accountInfo.school.id,
                  myRole.game.id,
                  1,
                  200,
                  value ?? undefined,
                );
                setResetInputSearch(false);
              }
            }}
          />
        </Box>

        <Box sx={{ overflow: 'auto', flex: 1 }}>
          {playerListStatus === 'succeeded' && playerList && (
            <Stack direction="column">
              {playerList?.items?.map(data => {
                return (
                  <PlayerCard
                    playerName={data.name}
                    playerInfo={`${data.nickName} | ${data.gameProviderId}`}
                    playerPhoto={data.picture}
                    isCaptain={false}
                    sx={theme => ({
                      mb: '0px',
                      padding: '12px',
                      borderBottom: `1px solid ${theme.palette.neutral530}`,
                      opacity: checkPlayerState(data) ? '0.5' : '1',
                      cursor: checkPlayerState(data) ? 'default' : 'pointer',
                      pointerEvents: checkPlayerState(data) ? 'none' : 'auto',
                    })}
                    cta={
                      <IconButton
                        sx={{
                          p: '0px',
                          border: 'none',
                        }}
                        onClick={() => {
                          handleAddPlayerClick(data);
                        }}
                        icon={
                          selectedPlayers?.find(e => {
                            return e.id === data.id;
                          }) ?? false
                            ? IconSource('checklist.orange.active')
                            : IconSource('checkbox-empty')
                        }
                      />
                    }
                  />
                );
              })}
            </Stack>
          )}

          {playerListStatus === 'succeeded' &&
            playerList &&
            ((playerList.items && playerList.items.length == 0) || !playerList.items) && (
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Text sx={{ mt: '40px', color: '#8D8784' }}>{t('search_not_found')}</Text>
              </Stack>
            )}
        </Box>
      </>
    </DrawerBottom>
  );
};

export default memo(AddMemberDrawer);
