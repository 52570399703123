import { Box } from '@mui/material';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: '10%' }}>
      <Box sx={{ textAlign: 'center', mb: '8px' }}>
        <img src={IconSource('ic-duel-big', 'png')} alt="ic-duel-big" />
      </Box>
      <Text variant="subtitle" sx={{ color: '#8D8784' }}>
        {t('match_belum_tersedia')}
      </Text>
    </Box>
  );
};

export default NotFound;
