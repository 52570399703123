import { Box } from '@mui/material';
import copy from 'copy-to-clipboard';
import { CardProfileGame } from 'design/Cards/CardProfileGame';
import { DrawerBottom } from 'design/Drawer/DrawerBottom';
import React, { memo } from 'react';

interface ProfileGameDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  setOpenToast: (value: boolean) => void;
  nickname: string;
  gameId: string;
}

const ProfileGameDrawer = ({
  open,
  onClose,
  onOpen,
  setOpenToast,
  nickname,
  gameId,
}: ProfileGameDrawerProps) => {
  const handleCopyGameId = (playerId: string) => {
    Promise.all([copy(playerId), onClose()]).then(() => {
      return setOpenToast(true);
    });
  };

  const dataCopy = [
    {
      title: 'Game Nickname',
      content: nickname,
      handleCopy: () => handleCopyGameId(nickname),
    },
    {
      title: 'Game Id',
      content: gameId,
      handleCopy: () => handleCopyGameId(gameId),
    },
  ];

  return (
    <DrawerBottom
      open={open}
      title="Profile Game"
      iconClose={true}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Box sx={{ mb: '16px' }}>
        <CardProfileGame
          gameName="Mobile Legend: Bang Bang"
          gameLogo={`${process.env.PUBLIC_URL}/media/images/game/logo-mobile-legends@2x.png`}
          dataCopy={dataCopy}
        />
      </Box>
    </DrawerBottom>
  );
};

export default memo(ProfileGameDrawer);
