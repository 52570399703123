import styled, { css } from 'styled-components';
import { TextViewProps } from './TextView';

export const TextStyle = styled.a<TextViewProps>`
  font-family: 'Roboto';
  font-size: ${props => props.tSize ?? '12px'};
  font-weight: ${props => props.tWeight ?? '400'};
  font-style: ${props => props.tStyle ?? 'normal'};
  ${props =>
    props.variant === 'Gradient' &&
    css`
      box-sizing: border-box;
      flex: none;
      order: 0;
      flex-grow: 0;
      position: relative;
      font-style: normal;
      text-align: center;
      font-size: ${props.tSize ?? '12px'};
      font-style: ${props.tStyle ?? 'normal'};
      cursor: pointer;
      background-image: linear-gradient(
        131.42deg,
        #e75570 32.19%,
        #ed676a 53.45%,
        #f17267 66.7%,
        #fd965b 83.78%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    `};
  ${props =>
    props.variant === 'NavButton' &&
    css`
      font-style: normal;
      text-align: right;
      font-size: 12px;
      font-weight: 700;
      cursor: pointer;
      background: #969393;
      &:focus {
        background: linear-gradient(
          131.42deg,
          #d51f40 46.48%,
          #e24134 62.51%,
          #e8542e 74.36%,
          #f37024 83.78%
        );
      }
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    `};
`;
