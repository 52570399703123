import { Box, Stack, Skeleton, Link } from '@mui/material';
import socmedLink from 'data/socmedLink.json';
import { IconButton } from 'design/Buttons/IconButton';
import { SocialMediaButton } from 'design/Buttons/SocialMediaButton';
import { Text } from 'design/Text';
import { TOURNAMENT_STATE, TOURNAMENT_MODE } from 'dictionaries/tournament';
import he from 'he';
import { IconSource } from 'modules/assetpath';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { TournamentDetailResponse } from 'redux/tournament/TournamentProps';

interface DetailContentProps {
  title?: string | ReactNode;
  value?: string | ReactNode;
  width?: string;
}

export const DetailContent = ({ title, value, width }: DetailContentProps) => (
  <Stack sx={{ width: width }}>
    {title ? (
      <Text variant="label" sx={{ mb: '2px' }} component="div">
        {title}
      </Text>
    ) : (
      <Skeleton animation="wave" variant="text" width="30%" />
    )}

    {value ? (
      <Text sx={{ color: 'neutral400' }} component="div">
        {value}
      </Text>
    ) : (
      <Skeleton animation="wave" variant="text" height={25} />
    )}
  </Stack>
);

interface DetailsProps {
  tournamentDetail?: TournamentDetailResponse;
  teamsAccepted?: Array<any>;
}

export const colorBasedTournamentState = (key: number | undefined) => {
  switch (key) {
    case 0:
      return 'neutral150';
    case 1:
      return 'warning200';
    case 2:
      return 'neutral150';
    case 3:
      return 'neutral400';
    case 4:
      return 'danger500';
    case 5:
      return 'success500';
    case 6:
      return 'danger500';
    case 7:
      return 'primary500';
    default:
      return 'neutral150';
  }
};

const Details = ({ tournamentDetail, teamsAccepted }: DetailsProps) => {
  const { t } = useTranslation();

  const teamsAcceptedLength = teamsAccepted?.length ?? 0;
  return (
    <Stack spacing={3}>
      <DetailContent
        title={Boolean(tournamentDetail) && t('tournament_status')}
        value={
          tournamentDetail?.state && (
            <Text
              sx={{ color: colorBasedTournamentState(tournamentDetail?.state), fontWeight: 600 }}
            >
              {t(TOURNAMENT_STATE[tournamentDetail?.state!] ?? '')}
            </Text>
          )
        }
      />
      <DetailContent
        title={Boolean(tournamentDetail) && t('tournament_description')}
        value={
          Boolean(tournamentDetail) && (
            <Box
              sx={{ a: { color: '#F3AB36' } }}
              dangerouslySetInnerHTML={{
                __html: he.decode(tournamentDetail?.richDescription) ?? '',
              }}
            />
          )
        }
      />
      <Stack direction="row" spacing="12px">
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_season')}
          value={Boolean(tournamentDetail) && (tournamentDetail?.season.season ?? '')}
          width="50%"
        />
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_split')}
          value={Boolean(tournamentDetail) && (tournamentDetail?.season.split ?? '')}
          width="50%"
        />
      </Stack>
      <Stack direction="row" spacing="12px">
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_mode')}
          value={Boolean(tournamentDetail) && (TOURNAMENT_MODE[tournamentDetail?.mode!] ?? '')}
          width="50%"
        />
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_biaya')}
          value={
            Boolean(tournamentDetail) && (
              <Text
                sx={{
                  color: tournamentDetail?.registrationFee ? 'inherit' : 'success500',
                  fontWeight: tournamentDetail?.registrationFee ? 'inherit' : 600,
                }}
              >
                {tournamentDetail?.registrationFee
                  ? t('tournament_rp', {
                      number: tournamentDetail?.registrationFee.toLocaleString().replace(/,/g, '.'),
                    })
                  : t('tournament_gratis')}
              </Text>
            )
          }
          width="50%"
        />
      </Stack>
      <Stack direction="row" spacing="12px">
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_pendaftaran_dibuka')}
          value={
            Boolean(tournamentDetail) &&
            (moment(tournamentDetail?.openRegAt).format('dddd, DD MMMM YYYY') ?? '')
          }
          width="50%"
        />
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_pendaftaran_ditutup')}
          value={
            Boolean(tournamentDetail) &&
            (moment(tournamentDetail?.closeRegAt).format('dddd, DD MMMM YYYY') ?? '')
          }
          width="50%"
        />
      </Stack>
      <Stack direction="row" spacing="12px">
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_start')}
          value={
            Boolean(tournamentDetail) &&
            (moment(tournamentDetail?.startAt).format('dddd, DD MMMM YYYY') ?? '')
          }
          width="50%"
        />
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_end')}
          value={
            Boolean(tournamentDetail) &&
            (moment(tournamentDetail?.endAt).format('dddd, DD MMMM YYYY') ?? '')
          }
          width="50%"
        />
      </Stack>
      <Stack direction="row" spacing="12px">
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_min_roster')}
          value={Boolean(tournamentDetail) && (tournamentDetail?.minRosterPerTeam ?? '')}
          width="50%"
        />
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_max_roster')}
          value={Boolean(tournamentDetail) && (tournamentDetail?.maxRosterPerTeam ?? '')}
          width="50%"
        />
      </Stack>
      <Stack direction="row" spacing="12px">
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_venue')}
          value={Boolean(tournamentDetail) && (tournamentDetail?.location ?? '')}
          width="50%"
        />
        <DetailContent
          title={Boolean(tournamentDetail) && t('tournament_url_discord')}
          value={
            tournamentDetail?.discordUrl ? (
              <Link underline="none" href={tournamentDetail?.discordUrl!} target="_blank">
                <SocialMediaButton sx={{ bgcolor: '#7289DA', minWidth: '138px' }}>
                  <SVG src={IconSource('discord')} />
                </SocialMediaButton>
              </Link>
            ) : (
              ''
            )
          }
          width="50%"
        />
      </Stack>
      <DetailContent
        title={Boolean(tournamentDetail) && t('tournament_jumlah_terdaftar')}
        value={
          tournamentDetail?.maxTeam ? (
            <Text sx={{ color: 'neutral400' }}>
              {teamsAcceptedLength > tournamentDetail?.maxTeam
                ? tournamentDetail?.maxTeam
                : teamsAcceptedLength}{' '}
              / {tournamentDetail?.maxTeam}
              {/* hide dulu dari designer */}
              {/* <TextButton variant="label" gradient="primaryGradient3" sx={{ ml: '12px' }}>
                  {t('tournament_lihat_peserta')}
                </TextButton> */}
            </Text>
          ) : (
            ''
          )
        }
      />

      <DetailContent
        title={Boolean(tournamentDetail) && t('tournament_follow')}
        value={
          <Stack direction="row" spacing={2} sx={{ mt: '6px' }}>
            {socmedLink.map(socmed => (
              <a key={socmed.name} href={socmed.url} target="_blank">
                <IconButton
                  icon={IconSource(socmed.icon)}
                  sx={{ bgcolor: 'neutral600', width: '42px', height: '42px' }}
                />
              </a>
            ))}
          </Stack>
        }
      />
    </Stack>
  );
};

export default Details;
