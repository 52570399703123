import { Stack, Box, Divider, Avatar } from '@mui/material';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { Player } from 'design/Cards/Player';
import { DrawerBottom } from 'design/Drawer/DrawerBottom';
import { Text } from 'design/Text';
import React, { memo } from 'react';
import AuthService from 'services/AuthService';

interface RosterDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  dataEvent: any;
  playerId?: string;
}

const RosterDrawer = ({ open, onClose, onOpen, dataEvent, playerId }: RosterDrawerProps) => {
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();

  return (
    <DrawerBottom
      open={open}
      iconClose={false}
      title={
        <Box sx={{ mt: '4px' }}>
          <Text
            variant="ribbon"
            component="h5"
            sx={{ color: 'neutral300', fontWeight: 400, letterSpacing: '1px', mb: '8px' }}
          >
            {dataEvent?.type.toUpperCase()}
          </Text>
          <Text variant="h3" sx={{ mb: '4px' }}>
            {dataEvent?.eventName}
          </Text>
          <Text variant="bodySmall" sx={{ color: 'neutral300', mb: '16px' }}>
            {dataEvent?.title}
          </Text>
          <Divider
            sx={theme => ({
              borderColor: 'transparent',
              backgroundImage: `repeating-linear-gradient(to right, ${theme.palette.neutral530} 0%, ${theme.palette.neutral530} 50%, ${theme.palette.neutral700} 50%, ${theme.palette.neutral700} 100%)`,
              backgroundSize: '10px 2px',
            })}
          />
        </Box>
      }
      onClose={onClose}
      onOpen={onOpen}
      cta={
        <Stack sx={{ py: '0' }}>
          <SecondaryButton wide onClick={onClose}>
            Tutup
          </SecondaryButton>
        </Stack>
      }
      sx={{
        '.drawer-title': {
          alignItems: 'flex-start',
          flexDirection: 'column',
          mb: 0,
          'div + div': {
            ml: 0,
          },
        },
      }}
    >
      <Box sx={{ pt: '16px', pb: '20px' }}>
        <Box>
          <Text
            variant="ribbon"
            component="p"
            sx={{ color: 'neutral300', fontWeight: 400, letterSpacing: '1px', mb: '8px' }}
          >
            TIM
          </Text>
          <Stack direction="row" alignItems="center" spacing="12px" sx={{ mb: '16px' }}>
            <Avatar
              src={dataEvent?.teamAvatarUrl}
              alt={dataEvent?.teamName}
              sx={theme => ({
                width: '40px',
                height: '40px',
                bgcolor: 'gray02',
                borderRadius: theme.borderRadius.sm,
                border: `1.5px solid ${theme.palette.gray02}`,
              })}
            />
            <Stack>
              <Text variant="label">{dataEvent?.teamName}</Text>
              <Text
                variant="bodySmall"
                sx={{ fontSize: '10px', color: 'neutral300', letterSpacing: '0.16px' }}
              >
                {[dataEvent?.schoolName, dataEvent?.teamProvinceName]
                  .join(', ')
                  .replace(/^,|,\s*$/, '')}
              </Text>
            </Stack>
          </Stack>
        </Box>

        {dataEvent?.rosters
          ?.slice()
          .sort((roster: any) => (roster.level == 1 ? -1 : 1))
          .map((roster: any, index: number) => (
            <Player
              key={index}
              playerName={roster.playerName}
              playerInfo={`${roster.nickname} | ${roster.gameProviderId}`}
              playerPhoto={roster.avatarUrl}
              isCaptain={roster.level === 1}
              isYou={isAuthenticated && roster.playerId === playerId}
              sx={{
                position: 'relative',
                m: 0,
                p: '12px 0',
                borderBottom: '1px solid #242628',
                zIndex: -1,
              }}
            />
          ))}
      </Box>
    </DrawerBottom>
  );
};

export default memo(RosterDrawer);
