import { Box, Stack } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { NormalText } from 'components/styled/text.styled';
import { LayoutContext } from 'context/LayoutContext';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { TeamSchool as CardTeamSchool } from 'design/Cards/TeamSchool';
import { TextInputSearch } from 'design/Forms/TextInputSearch';
import { Modal } from 'design/Modal';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle, WarningStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import _ from 'lodash';
import { IconSource, ImageSource } from 'modules/assetpath';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fetchPlayerProfile } from 'redux/account/AccountServices';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { getMyTeamAction } from 'redux/team/MyTeamReducer';
import { kickMember } from 'redux/team/TeamApproval';
import { approveInvite, rejectInvite } from 'redux/team/TeamApproval';
import { setTeamSelectedAction, setInfoMessageAction } from 'redux/team/TeamDraftReducer';
import {
  getTeamInvitationList,
  setTeamInvitationSelectedAction,
  TeamInvitation,
} from 'redux/team/TeamInvitationReducer';
import { fetchTeamSuggestionList, TeamSchool } from 'redux/team/TeamListReducer';
import { ErrorResponse, Errors, requestJoinTeam } from 'redux/team/TeamMemberReducer';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';

const TeamListPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const { setValue } = useContext(LayoutContext);

  const currentTeam = getMyCurrentTeamFromSession();
  const {
    teamSchoolList,
    teamSchoolListStatus,
    gameTeamDraft,
    teamInvitationList,
    itemsDataMyTeam,
    playerInfo,
  } = useAppSelector(({ teamList, teamDraft, teamInvitation, myTeam, account }) => ({
    teamSchoolList: teamList?.teamSchool?.items,
    teamSchoolListStatus: teamList?.status,
    gameTeamDraft: teamDraft?.game,
    teamInvitationList: teamInvitation?.data?.items,
    itemsDataMyTeam: myTeam?.data?.items,
    playerInfo: account?.data,
  }));

  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [isDisableButtonjoin, setDisableButtonjoin] = useState(false);
  const [getConfirmModal, setConfirmModal] = useState(false);
  const [getConfirmChangeRequestJoinModal, setConfirmChangeRequestJoinModal] = useState(false);
  const [getConfirmChangeInviteTeamModal, setConfirmChangeInviteTeamModal] = useState(false);
  const [getConfirmModalFail, setConfirmModalFail] = useState(false);
  const [getConfirmModalSuccsess, setConfirmModalSuccsess] = useState(false);
  const [getConfirmModalCancelJoin, setConfirmModalCancelJoin] = useState(false);
  const [getConfirmModalCancelJoinFail, setConfirmModalCancelJoinFail] = useState(false);
  const [getConfirmGameModal, setConfirmGameModal] = useState(false);
  const [showModalLoading, setShowModalLoading] = useState(false);

  const [getTeamSelected, setTeamSelected] = useState<TeamSchool>();
  const [getErrorList, setErrorList] = useState<ErrorResponse | any>();

  const [openToast, setOpenToast] = useState(false);
  const [openErrorToast, setOpenErrorToast] = useState(false);

  const handleFetchPlayerProfile = (username: string) => {
    thunkDispatch(fetchPlayerProfile({ username: username }))
      .unwrap()
      .catch(error => setError(error));
  };

  const fetchInvitation = (username: string) => {
    thunkDispatch(
      getTeamInvitationList({
        playerSlug: username,
        pageIndex: 1,
        pageSize: 50,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const getTeamSchool = React.useCallback(
    (u: string, pI: number, pS: number, q?: string, v?: string) => {
      thunkDispatch(
        fetchTeamSuggestionList({
          username: u,
          pageIndex: pI,
          pageSize: pS,
          search: q,
          version: v,
        }),
      )
        .unwrap()
        .then(res => {
          if ((res && res?.isAxiosError) || res.errors) {
            setErrorList(res.errors);
          }
        })
        .catch(error => setError(error));
    },
    [thunkDispatch, teamSchoolList],
  );

  const handleOpenToast = (openToast: boolean, openErrorToast: boolean) => {
    if (openToast) {
      setOpenErrorToast(false);
      return openToast;
    }
    if (openErrorToast) {
      setOpenErrorToast(false);
      return openErrorToast;
    }
    return false;
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setOpenErrorToast(false);
  };

  const joinTeam = React.useCallback(
    (id: string, ts: TeamSchool, directJoin?: boolean, v?: string) => {
      setErrorList({});
      if (!directJoin) {
        setDisableButtonjoin(true);
      }
      thunkDispatch(requestJoinTeam({ teamId: ts.id, playerId: id, version: v }))
        .unwrap()
        .then(res => {
          if ((res && res?.isAxiosError) || res.errors) {
            setErrorList(res.errors);
            setConfirmModalFail(true);
            setDisableButtonjoin(false);
          } else {
            setDisableButtonjoin(false);
            setErrorList(undefined as any);
            setConfirmModal(false);
            saveTeamSelected(ts);
            setConfirmModalSuccsess(true);
          }
        })
        .catch(error => setError(error));
    },
    [thunkDispatch, teamSchoolList],
  );

  const cancelJoin = React.useCallback(
    (id: string, ts: TeamSchool, appliedTeamId: string, directJoin?: boolean) => {
      setErrorList({});
      setDisableButtonjoin(true);

      thunkDispatch(kickMember({ playerId: id, teamId: directJoin ? appliedTeamId : ts.id }))
        .unwrap()
        .then(res => {
          if ((res && res?.isAxiosError) || res.errors) {
            setErrorList(res.errors);
            setConfirmModalCancelJoinFail(true);
            setDisableButtonjoin(false);
          } else {
            setErrorList(undefined as any);
            if (directJoin) {
              joinTeam(id, ts, true);
            } else {
              saveTeamSelected(ts);
              if (playerInfo) {
                dispatch(
                  getMyTeamAction({ pageIndex: 1, pageSize: 20, playerSlug: playerInfo?.userName }),
                );
              }
              getTeamSchool(playerInfo?.userName ?? '', 1, 100);
              setDisableButtonjoin(false);
              setConfirmModalCancelJoin(false);
            }
          }
        })
        .catch(error => setError(error));
    },
    [thunkDispatch],
  );

  const approveInvitation = async (playerId: string, data: TeamInvitation) => {
    setShowModalLoading(true);
    await thunkDispatch(
      approveInvite({
        playerId: playerId,
        teamId: data.id,
      }),
    )
      .unwrap()
      .then(async res => {
        if (res && res?.isAxiosError) {
          setErrorList(res);
          setOpenErrorToast(true);
          if (playerInfo) {
            fetchInvitation(playerInfo.userName);
          }
        } else {
          saveInfoMessage(true, t('invitation_msg_success'));
          saveTeamInviteSelected(data);
          setTimeout(() => {
            if (playerInfo) {
              fetchInvitation(playerInfo.userName);
              setShowModalLoading(false);
              history.push(`/my-team`);
            }
          }, 2000);
        }
      })
      .catch(error => setError(error));
  };

  const cancelInvitation = async (
    playerId: string,
    dataTeamInviting: any,
    teamSelectedJoin?: any,
    directJoin?: boolean,
  ) => {
    if (!directJoin) {
      setShowModalLoading(true);
    }
    await thunkDispatch(
      rejectInvite({
        playerId: playerId,
        teamId: dataTeamInviting.id,
      }),
    )
      .unwrap()
      .then(async res => {
        if (res && res?.isAxiosError) {
          setErrorList(res);
          setOpenErrorToast(true);
          if (playerInfo) {
            fetchInvitation(playerInfo.userName);
          }
        } else {
          if (directJoin) {
            joinTeam(playerId, teamSelectedJoin, true);
          } else {
            setTimeout(() => {
              saveTeamInviteSelected(dataTeamInviting);
              if (playerInfo) {
                dispatch(
                  getMyTeamAction({ pageIndex: 1, pageSize: 20, playerSlug: playerInfo?.userName }),
                );
                fetchInvitation(playerInfo.userName);
              }
              getTeamSchool(playerInfo?.userName ?? '', 1, 100);
              setShowModalLoading(false);
              setOpenToast(true);
            }, 3000);
          }
        }
      })
      .catch(error => setError(error));
  };

  const saveTeamSelected = React.useCallback(
    (team: TeamSchool) => dispatch(setTeamSelectedAction({ teamSelected: team })),
    [dispatch],
  );

  const saveTeamInviteSelected = React.useCallback(
    (team: TeamInvitation) =>
      dispatch(setTeamInvitationSelectedAction({ teamInviteSelected: team })),
    [dispatch],
  );

  const saveInfoMessage = React.useCallback(
    (show: boolean, message: string) =>
      dispatch(setInfoMessageAction({ infoMessage: { show: show, message: message } })),
    [dispatch],
  );

  const playerRequestedJoinTeam = itemsDataMyTeam?.find(
    data =>
      data?.game?.id === gameTeamDraft?.id &&
      data?.status?.key == '1' &&
      data?.invitationType?.key === '3',
  );
  const playerInvitedJoinTeam = itemsDataMyTeam?.find(
    data =>
      data?.game?.id === gameTeamDraft?.id &&
      data?.status?.key == '1' &&
      data?.invitationType?.key === '2',
  );

  const handleTeamSelected = (e: React.MouseEvent, data: TeamSchool) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorList(undefined as any);
    setTeamSelected(data);
    if (Boolean(playerRequestedJoinTeam)) {
      return setConfirmChangeRequestJoinModal(true);
    }
    if (Boolean(playerInvitedJoinTeam)) {
      return setConfirmChangeInviteTeamModal(true);
    }
    return setConfirmModal(true);
  };

  const handleTeamRejectSelected = (e: React.MouseEvent, data: any) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorList(undefined as any);
    setConfirmModalCancelJoin(true);
    setTeamSelected(data);
  };

  async function searchTeamInMySchool(search: string) {
    setIsSearching(true);
    getTeamSchool(playerInfo?.userName ?? '', 1, 100, search);
  }

  const parseError = (errors: Errors | any) => {
    let err = errors as Errors;
    if (err) {
      let errorsList: string[] = [];
      if (err?.playerId) {
        errorsList = errorsList.concat(
          err.playerId.map(e => {
            if (e.code === 'RX1302') {
              console.error('e.code', e);
              if (e.formatedValue) {
                console.error('e.formatedValue', e.formatedValue);
                if (e.formatedValue?.playerId && e.formatedValue?.gameId) {
                  console.error(
                    'e.formatedValue?.playerId && e.formatedValue?.gameId',
                    e.formatedValue,
                  );
                  const myTeam = currentTeam?.find(v => v.gameId === e.formatedValue.gameId);
                  console.error('myTeam', myTeam);
                  if (e.formatedValue.gameId === myTeam?.gameId) {
                    return `Kamu sudah mempunyai tim di Game ${getTeamSelected?.game?.name}`;
                  }
                }
              } else {
                Object.values(err).forEach((prop: any) => {
                  return _.map(prop, _.property('message'));
                });
              }
            } else {
              Object.values(err).forEach((prop: any) => {
                return _.map(prop, _.property('message'));
              });
            }
            return e.message;
          }),
        );
        return errorsList.join('\r\n');
      } else {
        Object.values(err).forEach((prop: any) => {
          return _.map(prop, _.property('message'));
        });
      }
      return errorsList.join('\r\n');
    }
    return null;
  };

  const handleApproveInvitation = (
    e: React.MouseEvent,
    playerId: string,
    invitationJoinByGame: any,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (invitationJoinByGame) approveInvitation(playerId, invitationJoinByGame);
  };

  const handleCancelInvitation = (
    e: React.MouseEvent,
    playerId: string,
    invitationJoinByGame: any,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (invitationJoinByGame) cancelInvitation(playerId, invitationJoinByGame);
  };

  useEffect(() => {
    if (!gameTeamDraft?.id) return history.push('/my-team');
  }, []);

  const teamProfilePublicLink = (teamSlug: string) => {
    history.push({
      pathname: `/team/${gameTeamDraft?.urlSlug}/${playerInfo?.school?.urlSlug}/${teamSlug}`,
      state: {
        pathname: location.pathname,
      },
    });
  };

  const handleLoading = useCallback((status: boolean) => {
    if (status) {
      return !Boolean((teamSchoolList ?? []).length > 0);
    }
    return false;
  }, []);

  useEffect(() => {
    if (!gameTeamDraft) {
      return setConfirmGameModal(true);
    }

    if (playerInfo) {
      handleFetchPlayerProfile(playerInfo?.userName);
      fetchInvitation(playerInfo?.userName);

      dispatch(getMyTeamAction({ pageIndex: 1, pageSize: 20, playerSlug: playerInfo?.userName }));
    }

    if (playerInfo && !isSearching) {
      getTeamSchool(playerInfo?.userName ?? '', 1, 100);
    }

    setValue({
      pageTitle: t('team_page_title', { title: gameTeamDraft?.name }),
      background: { color: '#0a0a0b' },
      appBar: {
        title: t('team_page_title', { title: gameTeamDraft?.name }),
        logo: false,
        back: { to: '/my-team' },
        separator: true,
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
      additional: (
        <>
          <Toast
            show={handleOpenToast(openToast, openErrorToast)}
            onClose={handleCloseToast}
            duration={3000}
          >
            {openToast && (
              <Ribbon
                icon={IconSource('check-gameon')}
                label={
                  <Text variant="body" sx={{ color: 'success900' }}>
                    {t('invitation_msg_cancel')}
                  </Text>
                }
                sx={GameOnToastStyle}
              />
            )}
            {openErrorToast && (
              <Ribbon
                label={
                  <Text variant="body" sx={{ color: 'success900' }}>
                    {parseError(getErrorList?.errors) ??
                      getErrorList?.detail ??
                      'Ada kesalahan, Silahkan kamu coba lagi!'}
                  </Text>
                }
                sx={WarningStyle}
              />
            )}
          </Toast>
        </>
      ),
    });
  }, [gameTeamDraft, openToast, openErrorToast]);

  return (
    <>
      <Box sx={{ m: '0px 16px' }}>
        {itemsDataMyTeam ? (
          <Box sx={{ pt: '17px' }}>
            <>
              {itemsDataMyTeam.map(item => {
                const invitationJoinByGame = teamInvitationList?.find(
                  data => data?.gameSlug === item?.game?.urlSlug,
                );

                if (item.status.key == '1' && item?.invitationType?.key === '2') {
                  return (
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() => teamProfilePublicLink(item.urlSlug)}
                    >
                      <CardTeamSchool
                        key={'waiting' + item.id}
                        image={item.avatarUrl}
                        title={item.name}
                        subTitle={{
                          icon: IconSource('ic-captain'),
                          text: invitationJoinByGame?.invitedBy?.name,
                        }}
                        cta={
                          <Stack direction="row" spacing={1} sx={{ mt: '8px' }}>
                            <SecondaryButton
                              variant="small"
                              onClick={e =>
                                handleCancelInvitation(
                                  e,
                                  playerInfo?.id ?? '',
                                  invitationJoinByGame ?? '',
                                )
                              }
                            >
                              Tolak
                            </SecondaryButton>
                            <PrimaryButton
                              variant="small"
                              onClick={e =>
                                handleApproveInvitation(
                                  e,
                                  playerInfo?.id ?? '',
                                  invitationJoinByGame ?? '',
                                )
                              }
                            >
                              Terima Undangan
                            </PrimaryButton>
                          </Stack>
                        }
                        sx={{
                          padding: '12px',
                        }}
                        bgImage={ImageSource('game-mlbb', 'png')}
                      />
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() => teamProfilePublicLink(item.urlSlug)}
                    >
                      <CardTeamSchool
                        key={'waiting' + item.id}
                        image={item.avatarUrl}
                        title={item.name}
                        subTitle={{ text: 'Menunggu konfirmasi kapten' }}
                        cta={
                          <Box sx={{ mt: '8px' }}>
                            <SecondaryButton
                              variant="small"
                              onClick={e => handleTeamRejectSelected(e, item)}
                            >
                              Batal Join
                            </SecondaryButton>
                          </Box>
                        }
                        sx={{
                          padding: '12px',
                        }}
                        bgImage={ImageSource('game-mlbb', 'png')}
                      />
                    </Box>
                  );
                }
              })}
            </>
          </Box>
        ) : (
          <Box sx={{ pt: '17px' }}>
            <CardTeamSchool
              key="create_new_2"
              image={gameTeamDraft?.iconUrl ?? IconSource('game_logo_freefire', 'png')}
              title="Buat Tim Baru"
              subTitle={{ text: 'Dengan membuat tim baru kamu akan menjadi kapten' }}
              onClickCardNew={() => {
                history.push('/create-team');
              }}
              sx={{ cursor: 'pointer' }}
            />
          </Box>
        )}

        <Box sx={{ mt: '24px' }}>
          <Text variant="label" sx={{ fontSize: '16px' }}>
            Tim di Sekolah mu
          </Text>
        </Box>

        <Box sx={{ mt: '16px' }}>
          <TextInputSearch
            id={'search-team-school'}
            placeholder={'Cari tim yang ada di sekolahmu'}
            onChange={(value: any) => searchTeamInMySchool(value)}
          />
        </Box>

        {(teamSchoolList ?? []).length > 0 ? (
          <>
            <Stack sx={{ mt: '16px' }} direction="column">
              {teamSchoolList.map((item, index) => {
                const isGameHaveTeam = itemsDataMyTeam?.find((e: { id: any }) => e.id === item.id);

                const memberFullOrHaveJoined =
                  Boolean(isGameHaveTeam) || item.memberCount >= gameTeamDraft?.maxMember;

                return (
                  <Box
                    sx={{
                      cursor: 'pointer',
                      pointerEvents: memberFullOrHaveJoined ? 'none' : 'auto',
                    }}
                    onClick={() => teamProfilePublicLink(item.urlSlug)}
                  >
                    <CardTeamSchool
                      key={index}
                      image={item.avatarUrl}
                      title={item.name}
                      subTitle={{
                        icon: IconSource('ic-captain'),
                        text: (
                          <Text
                            variant="subtitle"
                            sx={theme => ({
                              color: theme.palette.black03,
                            })}
                          >
                            {item?.captain ?? ''}
                          </Text>
                        ),
                      }}
                      overlay={memberFullOrHaveJoined}
                      cta={
                        <Stack
                          sx={{ mt: '12px' }}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box sx={{ textAlign: 'left' }}>
                            <Text
                              variant="subtitle"
                              sx={theme => ({
                                color: theme.palette.black03,
                              })}
                            >
                              {`${item.memberCount ?? 0}/${gameTeamDraft?.maxMember ?? 0} Anggota`}
                            </Text>
                          </Box>

                          <Box sx={{ textAlign: 'right' }}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center">
                              <Text
                                variant="button"
                                sx={{
                                  background: memberFullOrHaveJoined
                                    ? '#4E5156'
                                    : 'linear-gradient(#D51F40, #F37024)',
                                  WebkitBackgroundClip: 'text',
                                  WebkitTextFillColor: 'transparent',
                                  cursor: 'pointer',
                                }}
                                onClick={e => handleTeamSelected(e, item)}
                              >
                                Join Tim
                              </Text>
                            </Stack>
                          </Box>
                        </Stack>
                      }
                    />
                  </Box>
                );
              })}
            </Stack>
          </>
        ) : (
          <Stack sx={{ mt: '16px' }} direction="column" justifyContent="center" alignItems="center">
            <img alt="" src={IconSource('mabar-typeface-fullwhite')} />
            <NormalText
              fontSize="14px"
              fontWeight="400"
              color={getErrorList && getErrorList?.errors ? '#FF4D6D' : '#969393'}
              lineHeight="20.44px"
              textAlign="center"
            >
              {isSearching
                ? `Tidak ada tim '${
                    (document.getElementById('search-team-school') as HTMLInputElement).value
                  }' di sekolah kamu`
                : getErrorList && getErrorList.errors
                ? parseError(getErrorList.errors)
                : 'Sekolah kamu belum memiliki tim. Ayo buat tim kamu sekarang'}
            </NormalText>
          </Stack>
        )}
      </Box>

      <Modal show={getConfirmGameModal}>
        <InfoModal
          cta={
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ width: '100%' }}>
              <PrimaryButton onClick={() => history.push(`/my-team`)}>Back</PrimaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%' }}>
            <Text variant="h2" mb="12px">
              Warning!!
            </Text>
            <Text>Please choice the game</Text>
          </Box>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmModal}>
        <InfoModal
          cta={
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ width: '100%' }}>
              <SecondaryButton onClick={() => setConfirmModal(false)}>Batal</SecondaryButton>
              <PrimaryButton
                onClick={() => joinTeam(playerInfo?.id ?? '', getTeamSelected!)}
                disabled={isDisableButtonjoin}
              >
                {isDisableButtonjoin ? 'Please wait..' : 'Join'}
              </PrimaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%' }}>
            <Text variant="h2" mb="12px">
              Join Tim
            </Text>
            <Text>
              Apakah kamu yakin ingin join tim <b>{getTeamSelected?.name ?? ''}?</b>
            </Text>
          </Box>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmChangeRequestJoinModal}>
        <InfoModal
          cta={
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ width: '100%' }}>
              <SecondaryButton onClick={() => setConfirmChangeRequestJoinModal(false)}>
                Batal
              </SecondaryButton>
              <PrimaryButton
                onClick={() =>
                  cancelJoin(
                    playerInfo?.id ?? '',
                    getTeamSelected!,
                    playerRequestedJoinTeam?.id ?? '',
                    true,
                  )
                }
                disabled={isDisableButtonjoin}
              >
                {isDisableButtonjoin ? 'Please wait..' : 'Konfirmasi Join'}
              </PrimaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%' }}>
            <Text variant="h2" mb="12px">
              Join Tim
            </Text>
            <Text>
              Dengan mengirimkan request join ke tim <b>{getTeamSelected?.name ?? ''}</b>, kamu
              telah setuju untuk <b style={{ color: '#D02B20' }}>membatalkan</b> request join dengan
              tim {playerRequestedJoinTeam?.name}.
            </Text>
          </Box>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmChangeInviteTeamModal}>
        <InfoModal
          cta={
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ width: '100%' }}>
              <SecondaryButton onClick={() => setConfirmChangeInviteTeamModal(false)}>
                Batal
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  setDisableButtonjoin(true);
                  cancelInvitation(
                    playerInfo?.id ?? '',
                    playerInvitedJoinTeam,
                    getTeamSelected!,
                    true,
                  );
                }}
                disabled={isDisableButtonjoin}
              >
                {isDisableButtonjoin ? 'Please wait..' : 'Konfirmasi Join'}
              </PrimaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%' }}>
            <Text variant="h2" mb="12px">
              Join Tim
            </Text>
            <Text>
              Dengan mengirimkan request join ke tim <b>{getTeamSelected?.name ?? ''}</b>, kamu
              telah setuju untuk <b style={{ color: '#D02B20' }}>menolak</b> undangan join dari tim{' '}
              {playerRequestedJoinTeam?.name}.
            </Text>
          </Box>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmModalFail}>
        <InfoModal
          icon={ImageSource('Warning', 'svg')}
          cta={
            <PrimaryButton wide onClick={() => setConfirmModalFail(false)}>
              Oke, kembali ke halaman
            </PrimaryButton>
          }
        >
          <Text variant="h2" textAlign="center" mb="8px">
            Undangan gagal terkirim
          </Text>
          <Text textAlign="center">Mohon maaf undangan gagal terkirim, mohon di coba lagi..</Text>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmModalCancelJoinFail}>
        <InfoModal
          icon={ImageSource('Warning', 'svg')}
          cta={
            <PrimaryButton wide onClick={() => setConfirmModalCancelJoinFail(false)}>
              Oke, kembali ke halaman
            </PrimaryButton>
          }
        >
          <Text variant="h2" textAlign="center" mb="8px">
            Reject team gagal terkirim
          </Text>
          <Text textAlign="center">
            Mohon maaf reject team gagal terkirim, mohon di coba lagi..
          </Text>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmModalSuccsess}>
        <InfoModal
          icon={ImageSource('Paperplane', 'svg')}
          cta={
            <PrimaryButton wide onClick={() => history.push('/my-team')}>
              Kembali ke Tim Saya
            </PrimaryButton>
          }
        >
          <Text variant="h2" textAlign="center" mb="8px">
            Permintaan Join terkirim
          </Text>
          <Text textAlign="center">Tunggu konfirmasi dari kapten tim</Text>
        </InfoModal>
      </Modal>

      <Modal show={getConfirmModalCancelJoin}>
        <InfoModal
          cta={
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ width: '100%' }}>
              <SecondaryButton onClick={() => setConfirmModalCancelJoin(false)}>
                Batal
              </SecondaryButton>
              <PrimaryButton
                onClick={() => cancelJoin(playerInfo?.id ?? '', getTeamSelected!, '')}
                disabled={isDisableButtonjoin}
              >
                {isDisableButtonjoin ? 'Please wait..' : 'Batalkan Request'}
              </PrimaryButton>
            </Stack>
          }
        >
          <Box sx={{ width: '100%' }}>
            <Text variant="h2" mb="12px">
              Batal Join
            </Text>
            <Text>
              Apakah kamu yakin ingin membatalkan request join dengan tim{' '}
              <b>{getTeamSelected?.name ?? ''}?</b>
            </Text>
          </Box>
        </InfoModal>
      </Modal>

      <LoadingModal
        show={error ? true : handleLoading(teamSchoolListStatus === 'loading') || showModalLoading}
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default TeamListPage;
