import MenuHeaderModal from './modal/MenuHeaderModal';
import TeamProfileNavigation from './navigation';
import HeaderSection from './state/HeaderSection';
import LoadingModal from 'components/LoadingModal';
import { LayoutContext } from 'context/LayoutContext';
import { Ribbon } from 'design/Ribbon';
import { GameOnToastStyle, WarningStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource } from 'modules/assetpath';
import { onClickShare } from 'modules/helpers';
import history from 'modules/history';
import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fetchPlayerProfile } from 'redux/account/AccountServices';
import { resetAward } from 'redux/award/AwardReducer';
import { fetchTournamentParticipantAwardsFrame } from 'redux/award/AwardService';
import { useAppSelector } from 'redux/hooks';
import { resetScrimList } from 'redux/scrim/ScrimReducer';
import { useAppThunkDispatch } from 'redux/store';
import { resetInfoMessageAction } from 'redux/team/TeamDraftReducer';
import { resetTeamProfile } from 'redux/teamProfile/TeamProfileReducer';
import { fetchTeamDetail } from 'redux/teamProfile/TeamProfileService';
import { resetTournament } from 'redux/tournament/TournamentReducer';
import { fetchActiveTournamentListPlayer } from 'redux/tournament/TournamentServices';
import AuthService from 'services/AuthService';

export interface ModalActionTypes {
  status:
    | 'share-action'
    | 'confirm-action'
    | 'delete-team-failed-member'
    | 'state-internal-server-error'
    | 'delete-team-failed-match'
    | 'confirm-delete-team'
    | 'confirm-leave-team'
    | 'confirm-leave-team-have-active-tournament'
    | 'api-failed';
}

const MyTeamProfile = () => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const location = useLocation<any>();

  const params = useParams() as { gameSlug: string; schoolSlug: string; teamSlug: string };

  const [error, setError] = useState<{ name: string; message: string; stack: string }>();
  const [openLoader, setOpenLoader] = useState(false);
  const [modalActionStatus, setModalActionStatus] =
    useState<ModalActionTypes['status']>('confirm-action');
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const { setValue } = useContext(LayoutContext);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessages, setToastMessages] = useState<any>('');
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [toastErrorMessages, setToastErrorMessages] = useState<any>('');
  const [maxMemberCount, setMaxMemberCount] = useState<any>('');

  const { playerInfo, teamDetail, teamDetailStatus, infoMessage } = useAppSelector(
    ({ account, teamProfile, teamDraft }) => ({
      playerInfo: account?.data,
      teamDetail: teamProfile?.teamDetail,
      teamDetailStatus: teamProfile?.status?.teamDetail,
      infoMessage: teamDraft?.infoMessage,
    }),
  );

  const handleFetchPlayerProfile = (username: string) => {
    dispatch(fetchPlayerProfile({ username: username }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTeamDetail = (teamSlug: string) => {
    dispatch(fetchTeamDetail({ teamSlug: teamSlug, version: '' }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwardsFrame = () => {
    dispatch(fetchTournamentParticipantAwardsFrame())
      .unwrap()
      .catch(error => setError(error));
  };

  const handleResetTeamProfile = () => {
    // only for the first time load
    if (params.teamSlug !== teamDetail?.urlSlug) {
      dispatch(resetTeamProfile());
    }
  };

  const handleResetTournament = () => {
    // only for the first time load
    if (params.teamSlug !== teamDetail?.urlSlug) {
      dispatch(resetTournament());
    }
  };

  const handleResetScrimList = () => {
    // only for the first time load
    if (params.teamSlug !== teamDetail?.urlSlug) {
      dispatch(resetScrimList());
    }
  };

  const handleResetAward = () => {
    // only for the first time load
    if (params.teamSlug !== teamDetail?.urlSlug) {
      dispatch(resetAward());
    }
  };

  const myRole = isAuthenticated
    ? playerInfo?.games?.find(game => game?.game?.urlSlug === params.gameSlug)
    : undefined;
  const isCaptain = myRole?.team?.level?.value === 'Captain';

  useEffect(() => {
    Promise.all([
      handleResetTeamProfile(),
      handleResetTournament(),
      handleResetScrimList(),
      handleResetAward(),
      handleFetchTeamDetail(params.teamSlug),
      handleFetchTournamentParticipantAwardsFrame(),
    ]);

    if (isAuthenticated && playerInfo) {
      handleFetchPlayerProfile(playerInfo?.userName);
    }
  }, []);

  useEffect(() => {
    if (teamDetail) {
      setMaxMemberCount(teamDetail?.memberCount ?? 0);
    }
  }, [teamDetail]);

  useEffect(() => {
    if (infoMessage && infoMessage.show) {
      setToastMessages(infoMessage.message);
      setOpenToast(infoMessage.show);
    } else {
      setToastMessages('');
      setOpenToast(false);
    }
  }, [infoMessage]);

  const resetTostrMessage = () => {
    dispatch(resetInfoMessageAction());
  };

  const handleDeleteTeam = useCallback(() => {
    if (isCaptain)
      if (maxMemberCount > 1) {
        setModalActionStatus('delete-team-failed-member');
        setOpenModalConfirmation(true);
      } else {
        setModalActionStatus('confirm-delete-team');
        setOpenModalConfirmation(true);
      }
  }, [isCaptain, maxMemberCount]);

  const handleLeaveTeam = () => {
    const thunk = fetchActiveTournamentListPlayer({
      playerId: playerInfo?.id,
    });
    dispatch(thunk)
      .unwrap()
      .then(res => {
        if (!openModalConfirmation) {
          setOpenModalConfirmation(true);
          if (res.tournaments) {
            if (res.tournaments.length >= 1) {
              setModalActionStatus('confirm-leave-team-have-active-tournament');
            } else {
              setModalActionStatus('confirm-leave-team');
            }
          } else {
            setModalActionStatus('confirm-leave-team');
          }
        }
      })
      .catch(() => {
        setModalActionStatus('api-failed');
        setOpenModalConfirmation(true);
      });
  };

  const navigationMenu = [
    {
      title: <Text>{t('header_action_leave_team')}</Text>,
      icon: 'ic-exit',
      onClick: () => handleLeaveTeam(),
    },
    {
      title: <Text>{t('not_found_button')}</Text>,
      icon: 'ic-home',
      dest: `/`,
    },
  ];

  const navigationMenuForCaptain = [
    {
      title: <Text>{t('edit_team_profile')}</Text>,
      icon: 'ic-edit',
      dest: `/team/${teamDetail?.game?.urlSlug}/${teamDetail?.urlSlug}/edit`,
    },
    {
      title: <Text>{t('player_team_profile_captain_disband_team')}</Text>,
      icon: 'ic-exit',
      onClick: () => handleDeleteTeam(),
    },
    {
      title: <Text>{t('not_found_button')}</Text>,
      icon: 'ic-home',
      dest: `/`,
    },
  ];

  const navigationMenuForPublicProfile = [
    {
      title: <Text>{t('not_found_button')}</Text>,
      icon: 'ic-home',
      dest: `/`,
    },
  ];

  const handleOpenToast = useMemo(() => {
    if (openToast) {
      setOpenErrorToast(false);
      return openToast;
    }
    if (openErrorToast) {
      setOpenToast(false);
      return openErrorToast;
    }
    return false;
  }, [openToast, openErrorToast]);

  const handleCloseToast = () => {
    setOpenToast(false);
    setOpenErrorToast(false);
    resetTostrMessage();
  };

  const isJoinApproved = myRole?.team?.status?.value === 'Approved';
  const isPrivateNav = params.teamSlug === myRole?.team?.urlSlug && isJoinApproved;

  const handleNavigationMenu = useMemo(() => {
    if (isPrivateNav) {
      if (isCaptain) {
        return navigationMenuForCaptain;
      }
      return navigationMenu;
    }
    return navigationMenuForPublicProfile;
  }, [isPrivateNav, isCaptain, teamDetail?.id]);

  const handleLoading = useCallback((status: boolean) => {
    if (status) {
      return params.teamSlug !== teamDetail?.urlSlug;
    }
    return false;
  }, []);

  useEffect(() => {
    setValue({
      pageTitle: pageTitle,
      appBar: {
        logo: false,
        back: { to: location.state?.pathname ?? '/my-team' },
        menu: handleNavigationMenu,
        share: (shareOpen: boolean, handleShare: () => void) => {
          if (shareOpen == true) {
            onClickShare({
              title: 'Match > Tim Profile',
              handleIconShare: () => handleShare(),
            });
          }
        },
        background: 'linear-gradient(180deg, #1A1817 0%, rgba(26, 24, 23, 0) 100%)',
      },
      bottomBar: {
        show: false,
      },
      additional: (
        <>
          <Toast show={handleOpenToast} onClose={handleCloseToast} duration={3000}>
            {openToast && (
              <Ribbon
                icon={IconSource('check-gameon')}
                label={
                  <Text variant="body" sx={{ color: 'success900' }}>
                    {toastMessages}
                  </Text>
                }
                sx={GameOnToastStyle}
              />
            )}
            {openErrorToast && (
              <Ribbon
                label={
                  <Text variant="body" sx={{ color: 'success900' }}>
                    {toastErrorMessages}
                  </Text>
                }
                sx={WarningStyle}
              />
            )}
          </Toast>
        </>
      ),
    });
  }, [playerInfo, pageTitle, teamDetail, handleOpenToast, maxMemberCount]);

  return (
    <>
      <HeaderSection
        paramTeamSlug={params.teamSlug}
        paramGameSlug={params.gameSlug}
        setError={setError}
        setOpenToast={setOpenToast}
        setToastMessages={setToastMessages}
        setOpenLoader={setOpenLoader}
      />

      <TeamProfileNavigation
        hash={history?.location?.hash}
        paramGameSlug={params.gameSlug}
        paramTeamSlug={params.teamSlug}
        setPageTitle={setPageTitle}
        setOpenToast={setOpenToast}
        setToastMessages={setToastMessages}
        setOpenErrorToast={setOpenErrorToast}
        setToastErrorMessages={setToastErrorMessages}
        setOpenLoader={setOpenLoader}
      />

      <MenuHeaderModal
        paramTeamSlug={params.teamSlug}
        openModalConfirmation={openModalConfirmation}
        setModalActionStatus={setModalActionStatus}
        modalActionStatus={modalActionStatus}
        setOpenModalConfirmation={setOpenModalConfirmation}
      />

      <LoadingModal
        show={error ? true : handleLoading(teamDetailStatus === 'loading') || openLoader}
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default MyTeamProfile;
