import GradientButton from 'components/Buttons/GradientButton';
import ScrimItem from 'components/Lists/ScrimItem';
import ScrollView from 'components/ScrollView';
import { Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import { IconSource } from 'modules/assetpath';
import { dateFormat } from 'modules/helpers';
import history from 'modules/history';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetScrimDraftAction } from 'redux/scrim/ScrimDraftReducer';
import { resetScrimList } from 'redux/scrim/ScrimReducer';
import { fetchScrimList } from 'redux/scrim/ScrimService';
import { useAppThunkDispatch } from 'redux/store';
import { getMyCurrentTeamFromSession } from 'redux/team/TeamService';

export default function ScrimListPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const myCurrentTeam = getMyCurrentTeamFromSession();
  const { scrimList, isScrimListLoading, gameSelected } = useAppSelector(
    ({ scrimList, scrimDraft }) => ({
      scrimList: scrimList.data,
      isScrimListLoading: scrimList?.status?.data === 'loading',
      gameSelected: scrimDraft.gameSelected,
    }),
  );

  const isHaveTeam = myCurrentTeam && myCurrentTeam.length >= 1;
  const isCaptain =
    isHaveTeam && myCurrentTeam.find(v => v.gameId === gameSelected.id)?.level.code == 1;

  const handleFetchScrimList = () => {
    thunkDispatch(fetchScrimList({ gameSlug: gameSelected.urlSlug, pageIndex: 1, pageSize: 20 }));
  };

  useEffect(() => {
    Promise.all([dispatch(resetScrimList), handleFetchScrimList()]);
  }, []);

  const goCreateScrim = () => {
    dispatch(resetScrimDraftAction());
    history.push('/create-scrim');
  };

  return (
    <>
      {scrimList.count >= 1 ? (
        <>
          <ScrollView id="ScrimList" margin="0px 16px 16px 16px" direction="vertical">
            {scrimList.items &&
              scrimList.items.map(item => {
                return (
                  <ScrimItem
                    key={item.id.replace('#', '')}
                    id={item.id}
                    data={item}
                    startAt={dateFormat(item.startAt, 'Asia/Jakarta', 'id').format(
                      'dddd, DD MMM YYYY - HH:mm z',
                    )}
                    teamChallengerLogo={item.avatar}
                    teamChallengerName={item.firstTeam}
                    schemaMatch={
                      item.type.value.replace('BO3', 'Best of 3').replace('BO5', 'Best of 5') ??
                      'Unknown'
                    }
                    onScrimClick={d => {
                      history.push(`/scrim/${gameSelected.urlSlug}/${d.id}`);
                    }}
                  />
                );
              })}
          </ScrollView>
          {isCaptain && (
            <div
              style={{
                position: 'sticky',
                zIndex: 199,
                flexDirection: 'row',
                alignSelf: 'center',
                justifyContent: 'center',
                bottom: '80px',
                left: '50%',
                transform: 'translateX(-50%)',
                maxWidth: '500px',
                width: 'max-content',
              }}
            >
              <GradientButton
                variant="primary"
                onClick={goCreateScrim}
                padding="12px 20px 12px 20px"
                styled={{
                  backgroundSize: '100%',
                }}
              >
                {t('player_explore_scrim_create_scrim')}
              </GradientButton>
            </div>
          )}
        </>
      ) : (
        !isScrimListLoading && (
          <Stack
            direction="column"
            align="center"
            margin="70px 0px 0px 0px"
            disableSelectText
            disableDrag
          >
            <img alt="" src={IconSource('mabar-typeface-fullwhite')} />
            <NormalText
              fontSize="14px"
              fontWeight="400"
              color="#969393"
              lineHeight="20.44px"
              textAlign="center"
              margin="24px 0px 30px 0px"
            >
              {isHaveTeam
                ? t('player_explore_scrim_empty_state')
                : t('player_explore_scrim_empty_state_team')}
              <br />
            </NormalText>
            {isCaptain && (
              <GradientButton
                variant="primary"
                onClick={goCreateScrim}
                padding="12px 20px 12px 20px"
                styled={{
                  backgroundSize: '100%',
                }}
              >
                {t('player_explore_scrim_create_scrim')}
              </GradientButton>
            )}
          </Stack>
        )
      )}
    </>
  );
}
