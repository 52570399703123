import { Box, Stack } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import { LayoutContext } from 'context/LayoutContext';
import { ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { getCountUnreadNotification } from 'redux/notification/NotificationReducer';
import { useAppThunkDispatch } from 'redux/store';
import AuthService from 'services/AuthService';

const ComingSoon = () => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const dispatch = useAppThunkDispatch();

  const [haveNotifUnread, setHaveNotifUnread] = useState(false);
  const { playerInfo } = useAppSelector(({ account }) => ({
    playerInfo: account.data,
  }));

  const handleCountNotification = () => {
    if (playerInfo) {
      dispatch(
        getCountUnreadNotification({
          id: playerInfo.id,
        }),
      )
        .unwrap()
        .then(res => {
          if (res && res.status == 200 && res.data.count > 0) {
            setHaveNotifUnread(true);
          } else {
            setHaveNotifUnread(false);
          }
        });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleCountNotification();
    }
  }, [playerInfo]);

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Ranking' }),
      background: { image: ImageSource('app.background', 'png') },
      appBar: {
        logo: true,
        notification: {
          show: isAuthenticated,
          haveNotifUnread: haveNotifUnread,
          onClick: () => history.push('/notification'),
        },
      },
      padding: '16px',
      bottomBar: {
        show: true,
      },
    });
  }, [haveNotifUnread]);

  return (
    <>
      <img src={ImageSource('comingsoon')} alt="" width="100%" />
      <NormalText
        margin="10px"
        fontStyle="normal"
        fontWeight="bold"
        fontSize="16px"
        color="white"
        textAlign="left"
      >
        Nantikan dan Ketahui Apa Saja yang Bisa Kamu Lihat di Page Ranking
      </NormalText>

      <Stack
        alignItems="flex-start"
        sx={{ p: '16px', bgcolor: 'rgba(55, 57, 66, 0.6)', borderRadius: '16px' }}
      >
        <NormalText
          fontStyle="normal"
          fontWeight="bold"
          fontSize="14px"
          color="#969393"
          textAlign="left"
          lineHeight="140%"
        >
          All Time Rank Sekolah
        </NormalText>
        <br />
        <NormalText
          fontStyle="normal"
          fontWeight="normal"
          fontSize="12px"
          color="white"
          textAlign="left"
          lineHeight="140%"
        >
          Wakili dan dukung sekolahmu untuk menjadi sekolah terbaik sepanjang masa dengan
          mengumpulkan juara per season.
        </NormalText>
        <Box padding="8px" width="100%">
          <Stack alignItems="center">
            <img src={ImageSource('rank.comingsoon')} alt="" />
          </Stack>
        </Box>
        <NormalText
          fontStyle="normal"
          fontWeight="bold"
          fontSize="14px"
          color="#969393"
          textAlign="left"
          lineHeight="140%"
        >
          Info Rank Kamu Secara Real-Time
        </NormalText>
        <br />
        <NormalText
          fontStyle="normal"
          fontWeight="normal"
          fontSize="12px"
          color="white"
          textAlign="left"
          lineHeight="140%"
        >
          Kamu bisa melihat rank kamu secara real-time dan jadilah pemain terbaik di sekolahmu
          maupun terbaik antar sekolah.
        </NormalText>
        <Box padding="8px" width="100%">
          <Stack alignItems="center">
            <img src={ImageSource('rank.comingsoon1')} alt="" />
          </Stack>
        </Box>
        <NormalText
          fontStyle="normal"
          fontWeight="bold"
          fontSize="14px"
          color="#969393"
          textAlign="left"
          lineHeight="140%"
        >
          Informasi Poin dan Rank Tim
        </NormalText>
        <br />
        <NormalText
          fontStyle="normal"
          fontWeight="normal"
          fontSize="12px"
          color="white"
          textAlign="left"
          lineHeight="140%"
        >
          Kamu bisa pantau terus Rank dari tim kamu sendiri dan juga Rank tim yang ada di MABAR
        </NormalText>
        <Box padding="8px" width="100%">
          <Stack alignItems="center">
            <img src={ImageSource('rank.comingsoon2')} alt="" />
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default ComingSoon;
