export interface FramePhotoProps {
  [key: string]: '1st' | '2nd' | '3rd' | 'mvp';
}

export interface FrameLogoProps {
  [key: string]: '1st' | '2nd' | '3rd' | 'favorite' | 'crown';
}

export const PHOTO_VARIANT = {
  FIRST: '1st',
  SECOND: '2nd',
  THIRD: '3rd',
  MVP: 'mvp',
} as FramePhotoProps;

export const LOGO_VARIANT = {
  FIRST: '1st',
  SECOND: '2nd',
  THIRD: '3rd',
  FAVORITE: 'favorite',
  CROWN: 'crown',
} as FrameLogoProps;
