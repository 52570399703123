import GradientButton, { GButtonProps } from 'components/Buttons/GradientButton';
import { Box, Stack } from 'components/styled/layout.styled';
import { NormalText } from 'components/styled/text.styled';
import React, { ReactNode } from 'react';

interface ConfirmModalProps {
  title?: ReactNode;
  bgColor?: string | undefined;
  borderRadius?: string | undefined;
  height?: string | "auto";
  customeBox?: boolean | undefined;
  btnConfirmText?: ReactNode;
  onConfirmClick?: () => void;
  btnCancelText?: ReactNode;
  onCancelClick?: () => void;
  children?: ReactNode;
  btnConfirm?: GButtonProps;
  btnCancel?: GButtonProps;
}

function ConfirmModal({
  title,
  children,
  btnConfirmText,
  onConfirmClick,
  btnCancelText,
  onCancelClick,
  btnConfirm,
  btnCancel,
  bgColor,
  customeBox,
  borderRadius,
  height,
}: ConfirmModalProps) {
  return (
    <Box
      width="100%"
      height={height}
      padding="24px 0px 24px 0px"
      background={bgColor}
      borderRadius={borderRadius}
    >
      {customeBox == undefined || customeBox == false ? (
        <Stack direction="column" align="center" margin=" 0px 24px 0px 24px">
            <NormalText
              color="white"
              fontSize="16px"
              fontWeight="700"
              textAlign="center"
              lineHeight="22.4px"
              margin="0px 0px 20px 0px"
            >
              {title}
            </NormalText>
          {children}
          <Stack direction="column" align="center" margin="24px 0px 0px 0px">
              <GradientButton
                variant="primary"
                padding="12px"
                onClick={onConfirmClick}
                styled={{ backgroundSize: '100%' }}
                {...btnConfirm}
              >
                {btnConfirmText}
              </GradientButton>
            <GradientButton
              variant="cta"
              padding="0px"
              onClick={onCancelClick}
              margin="16px 0px 0px 0px"
              {...btnCancel}
            >
              {btnCancelText}
            </GradientButton>
          </Stack>
        </Stack>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
}

export default ConfirmModal;
