import ActivityScrim from './About/ActivityScrim';
import Awards from './About/Awards';
import PlayerList from './About/PlayerList';
import TournamentParticipant from './About/TournamentParticipant';
import PlayerDetailDrawer from './Drawer/PlayerDetailDrawer';
import RosterDrawer from './Drawer/RosterDrawer';
import { Box, Stack } from '@mui/material';
import LoadingModal from 'components/LoadingModal';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CardSocmedTeam } from 'design/Cards/CardSocmedTeam';
import { InfoModal } from 'design/Cards/InfoModal';
import { TextInput } from 'design/Forms/TextInput';
import { Modal } from 'design/Modal';
import { Text } from 'design/Text';
import he from 'he';
import { ImageSource } from 'modules/assetpath';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchPlayerProfile } from 'redux/account/AccountServices';
import {
  fetchTournamentParticipantAwardsPlayer,
  fetchTournamentParticipantAwardsTeamHighlighted,
  fetchTournamentParticipantAwardsTeam,
} from 'redux/award/AwardService';
import { fetchGameDetail } from 'redux/game/GameReducer';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { approveJoin, rejectJoin, kickMember } from 'redux/team/TeamApproval';
import { editDelegateCaptain } from 'redux/team/TeamMemberReducer';
import { fetchMyTeamList, fetchPlayerSchool } from 'redux/team/TeamService';
import { TeamMember } from 'redux/teamProfile/TeamProfileResponse';
import { fetchTeamMember, fetchTeamDetail } from 'redux/teamProfile/TeamProfileService';
import {
  fetchActiveTournamentListPlayer,
  fetchTournamentParticipantTeam,
} from 'redux/tournament/TournamentServices';
import AuthService from 'services/AuthService';

interface MemberPageProps {
  gameSlug: string;
  teamSlug: string;
  setPageTitle?: any;
  setOpenToast: (value: boolean) => void;
  setToastMessages: (value: string) => void;
  setOpenErrorToast: (value: boolean) => void;
  setToastErrorMessages: (value: string) => void;
}

export interface PlayerData {
  playerName?: string;
  playerPhoto?: string;
  gameId?: string;
  nickname?: string;
  urlSlug?: string;
  status?: string;
  type?: string;
  teamId?: string;
  playerId?: string;
  level?: string;
}

const MemberPage = ({
  gameSlug,
  teamSlug,
  setPageTitle,
  setOpenToast,
  setToastMessages,
  setOpenErrorToast,
  setToastErrorMessages,
}: MemberPageProps) => {
  const thunkDispatch = useAppThunkDispatch();
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();

  const [isOpenPlayerDetail, setIsOpenPlayerDetail] = useState(false);

  const [playerData, setPlayerData] = useState<PlayerData>();
  const [error, setError] = useState<{ name: string; message: string; stack: string }>();

  const {
    accountInfo,
    teamMember,
    teamMemberStatus,
    gameDetail,
    playerListStatus,
    teamDetail,
    activeTournament,
    awardsHighlighted,
    awards,
    awardsHighlightedStatus,
    awardsListStatus,
  } = useAppSelector(({ account, teamProfile, game, teamMember, tournament, award }) => ({
    accountInfo: account?.data,
    teamMember: teamProfile?.teamMember,
    teamMemberStatus: teamProfile?.status?.teamMember,
    gameDetail: game?.game,
    playerListStatus: teamMember?.status?.playerSchool,
    teamDetail: teamProfile?.teamDetail,
    activeTournament: tournament.activeTournamentList?.tournaments
      ? tournament.activeTournamentList?.tournaments.map(arr => arr.name)
      : [],
    awardsHighlighted: award?.tournamentParticipantAwardsTeamHighlighted,
    awards: award?.tournamentParticipantAwardsTeam,
    awardsHighlightedStatus: award?.status?.tournamentParticipantAwardsTeamHighlighted,
    awardsListStatus: award?.status?.tournamentParticipantAwardsTeam,
  }));

  const [refetchTeamProfile, setRefetchTeamProfile] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [getModalAction, setModalAction] = useState<
    | 'confirm-action'
    | 'reject-request-join'
    | 'kick-member'
    | 'failed-kick-player'
    | 'failed-delegate-captain'
    | 'delegate-captain'
    | 'api-failed'
  >('confirm-action');

  const [inputTeamName, setInputTeamName] = useState('');
  const [inputTeamNameError, setInputTeamNameError] = useState('');
  const [contentPrintListTournament, setContentPrintListTournament] = useState('');
  const [openDrawerRoster, setIsOpenDrawerRoster] = useState(false);
  const [dataDrawerRoster, setDataDrawerRoster] = useState<any>([]);

  useEffect(() => {
    if (activeTournament.length == 1) {
      setContentPrintListTournament('<b>' + activeTournament[0] + '</b>');
    } else if (activeTournament.length == 2) {
      setContentPrintListTournament('<b>' + activeTournament.join('</b> dan <b>') + '</b>');
    } else if (activeTournament.length >= 3) {
      setContentPrintListTournament(
        '<b>' +
          activeTournament.slice(0, -1).join('</b>, <b>') +
          '</b> dan <b>' +
          activeTournament.slice(-1) +
          '</b>',
      );
    } else {
      setContentPrintListTournament('');
    }
  }, [activeTournament]);

  const myRole = isAuthenticated
    ? accountInfo?.games?.find(game => game?.team?.urlSlug === teamSlug)
    : undefined;
  const isCaptain = myRole?.team?.level?.value === 'Captain';

  const handleFetchTeamDetail = (teamSlug: string) => {
    thunkDispatch(fetchTeamDetail({ teamSlug: teamSlug, version: '' }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTeamMember = (teamSlug: string) => {
    thunkDispatch(
      fetchTeamMember({
        approved: false,
        teamSlug: teamSlug,
        pageIndex: 1,
        pageSize: 20,
        version: '',
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchPlayerList = (
    schoolId: string,
    gameId: string,
    pI: number,
    pS: number,
    search?: string,
  ) => {
    thunkDispatch(
      fetchPlayerSchool({
        schoolId: schoolId,
        gameId: gameId,
        pageIndex: pI,
        pageSize: pS,
        search: search,
      }),
    )
      .unwrap()
      .then(() => {
        setTimeout(() => setOpenLoader(false), 300);
      })
      .catch(error => setError(error));
  };

  const handleFetchGameDetail = (gameSlug: string) => {
    thunkDispatch(fetchGameDetail({ gameSlug: gameSlug }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchPlayerProfile = (username: string) => {
    thunkDispatch(
      fetchPlayerProfile({
        username: username,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchMyTeamList = (username: string) => {
    thunkDispatch(
      fetchMyTeamList({
        playerSlug: username,
        pageIndex: 1,
        pageSize: 10,
      }),
    )
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantTeam = (teamId: string) => {
    thunkDispatch(fetchTournamentParticipantTeam({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwardsPlayer = (playerId: string) => {
    thunkDispatch(fetchTournamentParticipantAwardsPlayer({ playerId: playerId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwardsTeam = (teamId: string) => {
    thunkDispatch(fetchTournamentParticipantAwardsTeam({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const handleFetchTournamentParticipantAwardsTeamHighlighted = (teamId: string) => {
    thunkDispatch(fetchTournamentParticipantAwardsTeamHighlighted({ teamId: teamId }))
      .unwrap()
      .catch(error => setError(error));
  };

  const linkWA = teamDetail?.whatsAppGroupUrl;
  const linkDiscord = teamDetail?.discordUrl;

  const acceptMember = (playerId: string, teamId: string) => {
    if (playerId && teamId) {
      setOpenLoader(true);
      const thunk = approveJoin({ playerId: playerId, teamId: teamId });
      thunkDispatch(thunk)
        .unwrap()
        .then(res => {
          if (res && res?.isAxiosError && res?.data?.status != 204) {
            setToastErrorMessages(res?.data?.detail);
            setOpenErrorToast(true);
          } else {
            setToastMessages('Player bergabung dengan tim');
            setTimeout(() => {
              setOpenLoader(false);
              setOpenToast(true);
            }, 4000);
          }
          setRefetchTeamProfile(true);
        })
        .catch(error => setError(error));
    }
  };

  const rejectMember = (props: { playerId: string; teamId: string }) => {
    setOpenLoader(true);
    const thunk = rejectJoin(props);
    if (accountInfo && myRole) {
      thunkDispatch(thunk)
        .unwrap()
        .then(res => {
          setOpenModalConfirmation(false);
          if (res && res?.isAxiosError && res?.data?.status != 204) {
            setOpenLoader(false);
            setToastErrorMessages(res?.data?.detail);
            setOpenErrorToast(true);
          }
          setRefetchTeamProfile(true);
          handleFetchPlayerList(accountInfo?.school?.id!, myRole.game.id, 1, 200);
          setTimeout(() => {
            setOpenLoader(false);
          }, 4000);
        })
        .catch(error => setError(error));
    }
  };

  const removeMember = (playerId: string, teamId: string) => {
    if (playerId && teamId) {
      setOpenLoader(true);
      setOpenModalConfirmation(false);
      const thunk = kickMember({ playerId: playerId, teamId: teamId });
      thunkDispatch(thunk)
        .unwrap()
        .then(res => {
          setIsOpenPlayerDetail(false);
          if (res && res?.isAxiosError && res?.data?.status != 204) {
            setOpenLoader(false);
            if (res?.data?.detail != undefined) {
              if (res?.data?.status == 1300) {
                setModalAction('failed-kick-player');
                setOpenModalConfirmation(true);
              }
            }
          } else {
            if (playerData?.status === 'Waiting' && playerData?.type == 'Invite') {
              setToastMessages('Undangan dibatalkan');
            } else {
              setToastMessages('Anggota telah dikeluarkan');
            }
            setTimeout(() => {
              handleFetchTeamDetail(teamSlug);
              setOpenLoader(false);
              setOpenToast(true);
            }, 4000);
          }
          setRefetchTeamProfile(true);
        })
        .catch(error => setError(error));
    }
  };

  const handleEditDelegateCaptain = () => {
    setInputTeamNameError('');

    if (inputTeamName.toLowerCase() == teamDetail?.name.toLowerCase()) {
      const thunk = editDelegateCaptain({
        teamId: teamDetail?.id,
        playerId: playerData?.playerId,
      });
      setOpenLoader(true);

      thunkDispatch(thunk)
        .unwrap()
        .then(res => {
          setOpenLoader(false);
          setOpenModalConfirmation(false);

          if (res && res?.isAxiosError && res?.data?.status != 204) {
            if (res?.data?.detail != undefined) {
              if (res?.data?.status == 1300) {
                setModalAction('failed-delegate-captain');
                setOpenModalConfirmation(true);
              } else {
                setToastErrorMessages(res?.data?.errors);
                setOpenErrorToast(true);
              }
            }
          } else {
            setRefetchTeamProfile(true);
            setToastMessages('Kapten baru berhasil terpilih');
            setOpenToast(true);
          }
        })
        .catch(error => setError(error));
    } else {
      setInputTeamNameError('Nama tim yang dimasukkan salah');
    }
  };

  const handleButtonRosterAward = (award: any) => {
    setDataDrawerRoster({
      ...award,
      titleDrawer: 'AWARD',
      teamAvatarUrl: award?.teamAvatarUrl ?? teamDetail?.avatarUrl,
    });
    setIsOpenDrawerRoster(true);
  };

  const handleButtonRosterTournament = (tournament: any) => {
    setDataDrawerRoster({
      ...tournament,
      titleDrawer: 'TURNAMEN',
      teamAvatarUrl: tournament?.teamAvatarUrl ?? teamDetail?.avatarUrl,
    });
    setIsOpenDrawerRoster(true);
  };

  const handleOpenDrawerRoster = () => {
    setIsOpenDrawerRoster(!openDrawerRoster);
  };

  const checkActiveTournamentMember = (playerIdToCheck: string) => {
    const thunk = fetchActiveTournamentListPlayer({
      playerId: playerIdToCheck,
    });
    thunkDispatch(thunk)
      .unwrap()
      .then(() => {
        setIsOpenPlayerDetail(false);
        setModalAction('kick-member');
        setOpenModalConfirmation(true);
      })
      .catch(() => {
        setIsOpenPlayerDetail(false);
        setModalAction('api-failed');
        setOpenModalConfirmation(true);
      });
  };

  const actionPlayerOnClickList = (player: TeamMember) => {
    setPlayerData({
      playerName: player?.player.name,
      playerPhoto: player?.player.picture ?? undefined,
      gameId: player?.player.gameProviderId,
      nickname: player?.player.nickName,
      urlSlug: player?.player.urlSlug,
      status: player?.status.value,
      type: player?.type.value,
      teamId: player?.teamId,
      playerId: player?.player.id,
      level: player?.level.value,
    });

    setIsOpenPlayerDetail(true);
  };

  const actionPlayerRejectJoin = (player: TeamMember) => {
    setPlayerData({
      playerName: player?.player.name,
      playerPhoto: player?.player.picture ?? undefined,
      gameId: player?.player.gameProviderId,
      nickname: player?.player.nickName,
      urlSlug: player?.player.urlSlug,
      status: player?.status.value,
      type: player?.type.value,
      teamId: player?.teamId,
      playerId: player?.player.id,
      level: player?.level.value,
    });

    setModalAction('reject-request-join');
    setOpenModalConfirmation(true);
  };

  const handleFetchCallback = useCallback(() => {
    if (accountInfo && teamDetail) {
      handleFetchTournamentParticipantAwardsPlayer(accountInfo?.id);
      handleFetchTournamentParticipantTeam(teamDetail?.id);
      handleFetchTournamentParticipantAwardsTeam(teamDetail?.id);
      handleFetchTournamentParticipantAwardsTeamHighlighted(teamDetail?.id);
    }
  }, [teamDetail?.id]);

  useEffect(() => {
    // get lastest data from teamDetail loop from handleFetchCallback() & filter with condition
    // this method to solving double fetching
    if (teamSlug === teamDetail?.urlSlug) {
      Promise.all([handleFetchTeamMember(teamSlug), handleFetchCallback()]);
    }
  }, [handleFetchCallback]);

  useEffect(() => {
    if (
      playerListStatus === 'succeeded' ||
      awardsHighlightedStatus === 'succeeded' ||
      awardsListStatus === 'succeeded'
    ) {
      setOpenLoader(false);
    }
  }, [playerListStatus, awardsHighlightedStatus, awardsListStatus]);

  useEffect(() => {
    if (refetchTeamProfile && accountInfo) {
      setRefetchTeamProfile(false);
      handleFetchPlayerProfile(accountInfo.userName);
      handleFetchTeamMember(teamSlug);
      handleFetchTeamDetail(teamSlug);
      handleFetchMyTeamList(accountInfo.userName);
    }
  }, [refetchTeamProfile]);

  useEffect(() => {
    if (!gameDetail) {
      handleFetchGameDetail(gameSlug);
    }
  }, [gameDetail]);

  const handleLoading = (status: boolean) => {
    if (status) {
      return !Boolean(teamMember || awardsHighlighted || awards);
    }
    return false;
  };

  useEffect(() => {
    setPageTitle(
      t('mabar_page_title', {
        title: 'My Team > Member',
      }),
    );
  }, [setPageTitle]);

  return (
    <>
      <CardSocmedTeam linkWhatsapp={linkWA} linkDiscord={linkDiscord} />

      <PlayerList
        myRole={myRole}
        isCaptain={isCaptain}
        handleFetchPlayerList={handleFetchPlayerList}
        acceptMember={acceptMember}
        actionPlayerOnClickList={actionPlayerOnClickList}
        actionPlayerRejectJoin={actionPlayerRejectJoin}
        setRefetchTeamProfile={setRefetchTeamProfile}
        setOpenLoader={setOpenLoader}
        setToastErrorMessages={setToastErrorMessages}
        setOpenErrorToast={setOpenErrorToast}
        setToastMessages={setToastMessages}
        setOpenToast={setOpenToast}
        setError={setError}
      />

      <Awards
        handleButtonRoster={handleButtonRosterAward}
        urlParams={{
          gameSlug: gameSlug,
          schoolSlug: teamDetail?.school.urlSlug,
          teamSlug: teamSlug,
        }}
      />

      <ActivityScrim />

      <TournamentParticipant
        handleButtonRoster={handleButtonRosterTournament}
        urlParams={{
          gameSlug: gameSlug,
          schoolSlug: teamDetail?.school.urlSlug,
          teamSlug: teamSlug,
        }}
      />

      <PlayerDetailDrawer
        open={isOpenPlayerDetail}
        onClose={() => setIsOpenPlayerDetail(false)}
        onOpen={() => setIsOpenPlayerDetail(true)}
        playerData={playerData}
        isCaptain={isCaptain}
        acceptMember={acceptMember}
        removeMember={removeMember}
        setIsOpenPlayerDetail={setIsOpenPlayerDetail}
        setModalAction={setModalAction}
        setOpenModalConfirmation={setOpenModalConfirmation}
        checkActiveTournamentMember={checkActiveTournamentMember}
      />

      <Modal show={openModalConfirmation}>
        {getModalAction === 'reject-request-join' ? (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="right" spacing={1} sx={{ width: '100%' }}>
                <SecondaryButton onClick={() => setOpenModalConfirmation(false)}>
                  {t('home_player_invite_player_email_cancel')}
                </SecondaryButton>
                <PrimaryButton
                  sx={theme => ({ background: theme.palette.danger600 })}
                  onClick={() => {
                    rejectMember({
                      playerId: playerData?.playerId ?? '',
                      teamId: playerData?.teamId ?? '',
                    });
                  }}
                >
                  {t('invitation_reject')}
                </PrimaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%', textAlign: 'left' }}>
              <Text variant="h2" sx={{ mb: '8px' }}>
                {t('reject_request')}
              </Text>
              <Text
                sx={theme => ({
                  color: theme.palette.neutral400,
                  b: { color: theme.palette.neutral300 },
                })}
                dangerouslySetInnerHTML={{
                  __html: he.decode(
                    t('reject_request_message', { playerName: playerData?.playerName }),
                  ),
                }}
              ></Text>
            </Box>
          </InfoModal>
        ) : getModalAction === 'failed-kick-player' ? (
          <InfoModal
            icon={ImageSource('Warning', 'png')}
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <SecondaryButton onClick={() => setOpenModalConfirmation(false)} wide>
                  {t('confirmation_ok')}
                </SecondaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%' }}>
              <Text variant="h2" mb="8px" textAlign="center">
                {t('request_failed')}
              </Text>
              <Text textAlign="center">{t('kick_member_message_failed')}</Text>
            </Box>
          </InfoModal>
        ) : getModalAction === 'failed-delegate-captain' ? (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <PrimaryButton onClick={() => setOpenModalConfirmation(false)}>
                  {t('home_player_invite_success_close')}
                </PrimaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%' }}>
              <Text variant="h2" mb="8px">
                {t('request_failed')}
              </Text>
              <Text>{t('delegate_captain_confirm_message_failed')}</Text>
            </Box>
          </InfoModal>
        ) : getModalAction === 'delegate-captain' ? (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                <SecondaryButton
                  sx={{ mr: '16px' }}
                  onClick={() => {
                    setInputTeamName('');
                    setInputTeamNameError('');
                    setOpenModalConfirmation(false);
                  }}
                >
                  Batal
                </SecondaryButton>
                <PrimaryButton onClick={() => handleEditDelegateCaptain()}>
                  Konfirmasi
                </PrimaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%', textAlign: 'left' }}>
              <Text variant="h2" sx={{ mb: '8px' }}>
                {t('delegate_captain_confirm')}
              </Text>
              <Text
                sx={theme => ({ color: theme.palette.neutral400 })}
                dangerouslySetInnerHTML={{
                  __html: he.decode(
                    t('delegate_captain_confirm_message', { playerName: playerData?.playerName }),
                  ),
                }}
              ></Text>
              <Stack direction="column" spacing={2} sx={{ mt: '16px' }}>
                <Box>
                  <TextInput
                    id="inputtimname"
                    label="Nama Tim"
                    onChange={e => {
                      setInputTeamName(e.target.value);
                    }}
                  />
                  {inputTeamNameError && (
                    <Text variant="subtitle" sx={{ color: 'danger600', mt: '3px' }}>
                      {inputTeamNameError}
                    </Text>
                  )}
                </Box>
              </Stack>
            </Box>
          </InfoModal>
        ) : getModalAction === 'kick-member' ? (
          <InfoModal
            cta={
              <Stack direction="row" justifyContent="right" spacing={1} sx={{ width: '100%' }}>
                <SecondaryButton onClick={() => setOpenModalConfirmation(false)}>
                  {t('home_player_invite_player_email_cancel')}
                </SecondaryButton>
                <PrimaryButton
                  sx={theme => ({ background: theme.palette.danger600 })}
                  onClick={() => {
                    removeMember(playerData?.playerId ?? '', playerData?.teamId ?? '');
                  }}
                >
                  {t('kick_confirmation')}
                </PrimaryButton>
              </Stack>
            }
          >
            <Box sx={{ width: '100%', textAlign: 'left' }}>
              <Text variant="h2" sx={{ mb: '8px' }}>
                {t('kick_member')}
              </Text>
              {activeTournament.length >= 1 ? (
                <Text
                  sx={theme => ({
                    color: theme.palette.neutral400,
                    b: { color: theme.palette.neutral300 },
                  })}
                  dangerouslySetInnerHTML={{
                    __html: he.decode(
                      t('kick_member_have_tournament_message', {
                        playerName: playerData?.playerName,
                        teamName: teamDetail?.name,
                        tournamentName: contentPrintListTournament,
                      }),
                    ),
                  }}
                ></Text>
              ) : (
                <Text
                  sx={theme => ({
                    color: theme.palette.neutral400,
                    b: { color: theme.palette.neutral300 },
                  })}
                  dangerouslySetInnerHTML={{
                    __html: he.decode(
                      t('kick_member_message', {
                        playerName: playerData?.playerName,
                        teamName: teamDetail?.name,
                      }),
                    ),
                  }}
                ></Text>
              )}
            </Box>
          </InfoModal>
        ) : (
          getModalAction === 'api-failed' && (
            <InfoModal
              cta={
                <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                  <SecondaryButton
                    sx={{ mr: '16px' }}
                    onClick={() => setOpenModalConfirmation(false)}
                  >
                    Batal
                  </SecondaryButton>
                </Stack>
              }
            >
              <Box sx={{ width: '100%' }}>
                <Text variant="h2" mb="8px" textAlign="center">
                  Permintaan Gagal
                </Text>
                <Text textAlign="center">
                  Telah terjadi kesalahan, mohon dicoba beberapa saat lagi.
                </Text>
              </Box>
            </InfoModal>
          )
        )}
      </Modal>

      <RosterDrawer
        open={openDrawerRoster}
        onClose={handleOpenDrawerRoster}
        onOpen={handleOpenDrawerRoster}
        dataEvent={dataDrawerRoster}
        playerId={accountInfo?.id ?? ''}
        title={dataDrawerRoster.titleDrawer}
        eventStatus={dataDrawerRoster?.title}
      />

      <LoadingModal
        show={
          error
            ? true
            : handleLoading(
                teamMemberStatus === 'loading' ||
                  awardsHighlightedStatus === 'loading' ||
                  awardsListStatus === 'loading',
              ) || openLoader
        }
        isError={Boolean(error?.message)}
        errorMessage={error?.message}
      />
    </>
  );
};

export default MemberPage;
