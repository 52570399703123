import { SINGLE_ID, DOUBLE_ID, SINGLE_ROUND_ROBIN } from 'store/constants/tournament';

interface TournamentModeProps {
  [key: string]: string;
}

export const TOURNAMENT_MODE: TournamentModeProps = {
  [SINGLE_ID]: 'Single Elimination',
  [DOUBLE_ID]: 'Double Elimination',
  [SINGLE_ROUND_ROBIN]: 'Single Round Robin',
};

interface TournamentStateProps {
  [key: number]: string;
}

export const TOURNAMENT_STATE: TournamentStateProps = {
  [0]: 'Draft',
  [1]: 'Upcoming',
  [2]: 'Bracket Generated',
  [3]: 'Finished',
  [4]: 'Cancelled',
  [5]: 'Registration Opened',
  [6]: 'Registration Closed',
  [7]: 'Competition Stated',
};

interface TournamentTeamStateProps {
  [key: string]: string;
}

export const TOURNAMENT_TEAM_STATE: TournamentTeamStateProps = {
  ['']: 'Members Invitation Stated',
  ['0']: 'Members Invitation Stated',
  ['1']: 'Queued',
  ['2']: 'Joined',
  ['3']: 'Disqualified',
  ['4']: 'Rejected',
};

interface TournamentTeamStatusProps {
  [key: string]: string;
}

export const TOURNAMENT_TEAM_STATUS: TournamentTeamStatusProps = {
  ['0']: 'Pending',
  ['2']: 'Accepted',
  ['3']: 'Disqualified',
  ['4']: 'Rejected',
};

interface TournamentPlayerStatusProps {
  [key: string]: string;
}

export const TOURNAMENT_PLAYER_STATUS: TournamentPlayerStatusProps = {
  ['0']: 'Pending',
  ['1']: 'Accepted',
};
