import React, { FC, ReactNode } from 'react';
import { TextAreaField, BorderTextArea, Label } from './styles';

export interface TextAreaProps {
  id?: string;
  placeholder?: string;
  label: ReactNode;
  minLength?: number;
  maxLength?: number;
  value?: string | number | readonly string[] | undefined;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  type?: string;
  margin?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}
export const TextArea: FC<TextAreaProps> = ({
  id,
  label,
  placeholder,
  minLength,
  maxLength,
  value,
  inputMode,
  margin,
}) => (
  <BorderTextArea margin={margin}>
    <Label>{label}</Label>
    <TextAreaField
      id={id}
      placeholder={placeholder}
      inputMode={inputMode}
      minLength={minLength}
      maxLength={maxLength}
      value={value}
    />
  </BorderTextArea>
);

export default TextArea;
