import { Avatar, Stack } from '@mui/material';
import { LayoutContext } from 'context/LayoutContext';
import othersCompetitionLandingJson from 'data/competitions/othersCompetitionLanding.json';
import { Text } from 'design/Text';
import { IconSource, ImageSource } from 'modules/assetpath';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const OtherCompetitions = () => {
  const { t } = useTranslation();
  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Others Competition' }),
      appBar: {
        logo: false,
        back: {
          to: '/competition',
        },
        title: 'Kompetisi Lainnya',
        background: '#17181A',
        separator: true,
      },
      background: { color: '#0A0A0B' },
      padding: '0 20px',
      bottomBar: {
        show: false,
      },
    });
  }, []);

  return othersCompetitionLandingJson.othersCompetition.map(data => (
    <Link to={data.link}>
      <Stack
        direction="row"
        alignItems="center"
        sx={theme => ({ py: '16px', borderBottom: `1px solid ${theme.palette.neutral530}` })}
      >
        <Avatar
          src={ImageSource(data.logo, 'png')}
          sx={theme => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '80px',
            height: '80px',
            p: '9.2px',
            mr: '16px',
            background: 'linear-gradient(180deg, #1A1817 0%, #0A0A0B 100%)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            border: `1px solid ${theme.palette.neutral600}`,
            borderRadius: theme.borderRadius.md,
            img: { objectFit: 'contain' },
          })}
        />
        <Stack>
          <Text variant="label" sx={{ display: 'flex', mb: '5px', cursor: 'pointer' }}>
            {data.title}
            <img src={IconSource('ic-chevron-right')} width={22} />
          </Text>
          <Text variant="subtitle" sx={{ color: 'neutral400' }}>
            {data.description}
          </Text>
        </Stack>
      </Stack>
    </Link>
  ));
};

export default OtherCompetitions;
