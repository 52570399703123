import React from 'react';
import { ExploreNavigation } from './Nav/ScrimNavigation';
import AppLayout from 'components/layouts/AppLayout';
import { ImageSource } from 'modules/assetpath';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import GameList from 'components/Lists/GameList';
import { useParams } from 'react-router-dom';
import ScrollView from 'components/ScrollView';
import { fetchGameOptions, GameOptions } from 'redux/game/GameReducer';
import { getGameSelectedAction } from 'redux/scrim/ScrimDraftReducer';
import history from 'modules/history';
import { useTranslation } from 'react-i18next';
import { useAppThunkDispatch } from 'redux/store';
import TournamentListPage from './state/tournamentList';
import ScrimListPage from './state/scrimList';

const ExplorePage = () => {
  const { t } = useTranslation();
  const handleClickLogo = () => {
    history.push('/');
  };
  let {} = useParams() as { tab: string; game_slug: string };
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const { data, loading } = useAppSelector(state => state.game);
  const gameSelected = useAppSelector(state => state.scrimDraft.gameSelected);
  const [getGameList, setGameList] = React.useState(data.items);
  const [currentGame, setCurrentGame] = React.useState<GameOptions>(gameSelected);

  let ScrimNavLinks = [
    {
      title: t('general_tournament'),
      to: `/explore/tournament${currentGame?.urlSlug ? '/' + currentGame.urlSlug : ''}`,
      isSelected: false,
      tab: 'tournament',
      component: () => <TournamentListPage />,
    },
    {
      title: 'Scrim',
      to: `/explore/scrim${currentGame?.urlSlug ? '/' + currentGame.urlSlug : ''}`,
      isSelected: false,
      tab: 'scrim',
      component: () => <ScrimListPage />,
    },
  ];

  const [getScrimNavLinks, setScrimNavLinks] = React.useState(ScrimNavLinks);

  const saveGameSelected = React.useCallback(
    (game: GameOptions) => dispatch(getGameSelectedAction({ game: game })),
    [dispatch],
  );

  React.useEffect(() => {
    thunkDispatch(fetchGameOptions({ pageIndex: 1, pageSize: 10 }))
      .unwrap()
      .then(res => {
        if (res && res.count && res.items) {
          setGameList(res.items);
          if (
            gameSelected.id === '' ||
            history.location.pathname.toLowerCase() === '/explore' ||
            history.location.pathname.toLowerCase() === '/explore/tournament'
          ) {
            res.items.find(g => {
              if (g.release) {
                saveGameSelected(g);
                setCurrentGame(g);
                setScrimNavLinks([...ScrimNavLinks, { ...ScrimNavLinks[0], isSelected: true }]);
                history.push(`/explore/tournament${g?.urlSlug ? '/' + g?.urlSlug : ''}`);
              }
            });
          }
        }
      })
      .catch();
  }, [thunkDispatch, history.location.pathname]);

  return (
    <AppLayout
      showBottomMenu
      noMargin
      appBar={{
        bg: {},
        navleft: {
          bg: 'mabar-typeface-white',
          onClick: handleClickLogo,
        },
        navright: {
          variant: '',
        },
        isDisable: false,
        title: '',
      }}
      backgroundImage={ImageSource('app.background')}
      opacityBg="0.02"
    >
      <ScrollView direction="horizontal">
        {getGameList &&
          getGameList.length > 0 &&
          getGameList.map((item, index) => {
            if (item.release) {
              return (
                <GameList
                  key={item.id}
                  variant={item.release ? 'small-default' : 'small-soon'}
                  width="250px"
                  heigth="110px"
                  gameName={item.name}
                  gameInfo={`${item.tournamentCount?.toLocaleString() ?? 0} ${t(
                    'general_tournament',
                  )} ${item.scrimCount?.toLocaleString() ?? 0} ${t('general_scrim')}`}
                  onClick={game => {
                    if (item.release) {
                      if (loading === false) {
                        saveGameSelected(item);
                        history.push('' + game.urlSlug);
                      }
                    }
                  }}
                  gameOptions={item}
                  gameBg={item.avatarUrl}
                  margin={index !== data?.count!! - 1 ? '0px 10px 0px 0px' : '0px'}
                />
              );
            }
            return undefined;
          })}
      </ScrollView>
      {getScrimNavLinks && (
        <ExploreNavigation
          ScrimNavLinks={getScrimNavLinks}
          onTabChange={v => v}
        />
      )}
    </AppLayout>
  );
};

export default ExplorePage;
