import React, { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { ImageBox, ImageTop, ImageBottom } from './styles';
import { ImageSource } from 'modules/assetpath';
import { Theme, Stack, Box } from '@mui/material';

interface InformationModalProps {
  icon?: string;
  cta?: ReactNode;
  children?: ReactNode;
  sx?: SxProps<Theme>;
  theme?: Theme;
}

const InformationBoxStyled: any = ({
  theme,
  sx,
}: InformationModalProps) => {
  return {
    position: 'relative',
    height: 'auto',
    minHeight: '48px',
    bgcolor: 'neutral700',
    border: `1px solid ${theme?.palette.neutral550}`,
    borderRadius: '12px',
    padding: '24px',
    ...sx,
  };
};

export function InfoModal({ icon, cta, children, sx }: InformationModalProps) {
  return (
      <Box sx={theme => InformationBoxStyled({ theme, sx })}>
        <Stack justifyContent="center" alignItems="center" width="100%">
          {icon && (
            <ImageBox>
              <ImageBottom src={ImageSource('pop-up-blur-bg')} />
              <ImageTop src={icon} />
            </ImageBox>
          )}
          {children}
          <Stack
            justifyContent="center"
            alignItems="center"
            width="100%"
            flexDirection="row"
            mt={icon ? '40px' : '16px'}
          >
            {cta}
          </Stack>
        </Stack>
      </Box>
  );
}
