import React, { FC, ReactNode } from 'react';

import { TextStyle } from './textview.styles';

export interface TextViewProps {
  variant:
    | 'TitleRank'
    | 'SkorRank'
    | 'TitleRasio'
    | 'SkorRasio'
    | 'TitleMenang'
    | 'SkorMenang'
    | 'TitleTanding'
    | 'SkorTanding'
    | 'FrameCss'
    | 'Tanggal'
    | 'NavButton'
    | 'Gradient';
  tSize?: string;
  tStyle?: 'normal' | 'italic';
  tWeight?: '400' | '500' | '700' | 'bold';
  styled?: React.CSSProperties;
  children?: ReactNode;
  onClick?: () => void;
}

export const TextView: FC<TextViewProps> = ({
  variant,
  tSize = '12px',
  tStyle = 'normal',
  tWeight = '400',
  styled,
  children,
  onClick,
}) => (
  <TextStyle
    variant={variant}
    tSize={tSize}
    tStyle={tStyle}
    tWeight={tWeight}
    onClick={onClick}
    style={styled}
  >
    {children}
  </TextStyle>
);

export default TextView;
