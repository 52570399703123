import { styled } from '@mui/system';
import { MatchRadioButtonProps } from './index';

const handleBackground = ({ iswin }: MatchRadioButtonProps) => {
    switch (iswin) {
      case '1':
        return '#09271D';
      case '0':
        return '#3E1C19';
      default:
        return '#27282B';
    }
};

const handleButtonColor = ({ iswin }: MatchRadioButtonProps) => {
    switch (iswin) {
      case '1':
        return '#26BD87';
      case '0':
        return '#D02B20';
      default:
        return '#27282B';
    }
};

export const Frame = styled('label', { name: 'frame' })<MatchRadioButtonProps>`
    background: ${({ iswin }) => handleBackground({ iswin })};
    width: 100%;
    height: 44px;
    border-radius: 8px;
    box-sizing: border-box;
    margin: auto;
    padding: 10px 12px;
    alignItems: 'center',
`;

export const RadioLabel = styled('div', { name: 'radioLabel' })<MatchRadioButtonProps>`
    ${({ theme }) => theme.typography.button}
    width: 100%;
    margin-left: 34px;
`;

export const RadioButtonWrapper = styled('div', { name: 'radioButtonWrapper' })`
    width: 24px;
    height: 24px;
    position: relative;
    justify-content: center;
    float: left;
`;

export const RadioButtonCircle = styled('div', { name: 'radioButtonCircle'})`
    position: absolute;
    width: 20px;
    height: 20px;
    left: 2px;
    top: 2px;
    border: 1.6px solid #94989E;
    box-sizing: border-box;
    border-radius: 50%;
`;

export const RadioButtonInput = styled('input', { name: 'RadioButtonInput' })<MatchRadioButtonProps>`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  justify-content: space-between;
  &:hover ~ div {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:checked + div {
    border: 1.6px solid;
    border-color: ${({ iswin }) => handleButtonColor({ iswin })};
    box-sizing: border-box;
    background-color:  ${({ iswin }) => handleBackground({ iswin })};
    transition: all 0.3s linear;
  }
  &:checked + div::after {
    content: '';
    background-color: ${({ iswin }) => handleButtonColor({ iswin })};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;