import { ScrimListResponse, ScrimTeamStatisticResponse } from './ScrimProps';
import { fetchScrimList, fetchScrimTeamStatistic } from './ScrimService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ScrimListState {
  data: ScrimListResponse;
  scrimTeamStatistic: ScrimTeamStatisticResponse | undefined;
  errors: {
    data: {} | string | undefined;
    scrimTeamStatistic: string | undefined;
  };
  status: {
    data: 'idle' | 'loading' | 'succeeded' | 'failed';
    scrimTeamStatistic: 'idle' | 'loading' | 'succeeded' | 'failed';
  };
}

const initialState: ScrimListState = {
  data: {
    pageIndex: 0,
    pageSize: 0,
    count: 0,
    totalCount: 0,
    totalPages: 0,
    items: [],
  },
  scrimTeamStatistic: undefined,
  errors: {
    data: '',
    scrimTeamStatistic: '',
  },
  status: {
    data: 'idle',
    scrimTeamStatistic: 'idle',
  },
};

const scrimListSlice = createSlice({
  name: 'scrimList',
  initialState: initialState,
  reducers: {
    getScrimList: (state, action: PayloadAction<{ pageIndex: number; pageSize: number }>) => {
      state.status.data = 'loading';
      state.data = {
        ...state.data,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
      };
      // will reset after login or logout
    },
    resetScrimList: () => initialState,
    successGetScrimList: (state, action) => {
      state.data = action.payload;
      state.status.data = 'succeeded';
    },
    failedGetScrimList: (state, action) => {
      state.errors.data = action.payload.error;
      state.status.data = 'failed';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchScrimList.pending, state => {
        state.status.data = 'loading';
      })
      .addCase(fetchScrimList.rejected, (state, action) => {
        state.errors.data = action.error;
        state.status.data = 'failed';
      })
      .addCase(fetchScrimList.fulfilled, (state, action) => {
        if (action.payload && action.payload.status >= 400) {
          state.errors.data = action.payload.data;
          state.status.data = 'failed';
        } else {
          state.data = action.payload.data;
          state.status.data = 'succeeded';
        }
      })

      .addCase(fetchScrimTeamStatistic.pending, (state, _action) => {
        state.status.scrimTeamStatistic = 'loading';
      })
      .addCase(fetchScrimTeamStatistic.rejected, (state, action) => {
        state.status.scrimTeamStatistic = 'failed';
        state.errors.scrimTeamStatistic = action.error.message;
      })
      .addCase(fetchScrimTeamStatistic.fulfilled, (state, action) => {
        state.status.scrimTeamStatistic = 'succeeded';
        state.scrimTeamStatistic = action.payload;
      });
  },
});

export const { resetScrimList, getScrimList, successGetScrimList, failedGetScrimList } =
  scrimListSlice.actions;

export default scrimListSlice.reducer;
