import React, { FC } from 'react';
import { InputSearch } from './styles';

export interface InputSearchProps {
  id?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}
export const SearchBox: FC<InputSearchProps> = ({ id, placeholder, onChange, onKeyDown }) => (
  <InputSearch id={id} placeholder={placeholder} onChange={onChange} onKeyDown={onKeyDown} />
);

export default SearchBox;
