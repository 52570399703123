import { Box, Stack, Modal as MUIModal, Fade, Backdrop } from '@mui/material';
import { AxiosError } from 'axios';
import { LayoutContext } from 'context/LayoutContext';
import { IconButton } from 'design/Buttons/IconButton';
import { PrimaryButton } from 'design/Buttons/PrimaryButton';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { InfoModal } from 'design/Cards/InfoModal';
import { Player as PlayerCard } from 'design/Cards/Player';
import { TextInputSearch } from 'design/Forms/TextInputSearch';
import { Modal } from 'design/Modal';
import { Ribbon } from 'design/Ribbon';
import { WarningStyle } from 'design/Ribbon/styles';
import { Text } from 'design/Text';
import { Toast } from 'design/Toast';
import { IconSource } from 'modules/assetpath';
import { base64toBlob } from 'modules/helpers';
import history from 'modules/history';
import React, { useState, useEffect, useContext } from 'react';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import {
  fetchPlayerProfile,
  fetchUserClaim,
  getPlayerProfileFromSession,
} from 'redux/account/AccountServices';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { resetTeamDraftAction } from 'redux/team/TeamDraftReducer';
import { Player } from 'redux/team/TeamMemberReducer';
import { fetchPlayerSchool } from 'redux/team/TeamService';
import { Constants } from 'services/Constants';
import TeamManagementService from 'services/TeamManagementService';
import TeamBody from 'types/API/request/TeamRequest';
import { MyCurrentTeam } from 'types/team';

const ChoosePlayerPage = () => {
  const height = use100vh();
  const { t } = useTranslation();
  const { setValue } = useContext(LayoutContext);
  const thunkDispatch = useAppThunkDispatch();
  const playerInfo = getPlayerProfileFromSession();

  const { playerInvited, game, accountInfo, playerList, playerListStatus, teamFormInfo } =
    useAppSelector(({ teamDraft, account, teamMember }) => ({
      playerInvited: teamDraft?.members,
      game: teamDraft?.game,
      playerList: teamMember?.playerSchool,
      accountInfo: account?.data,
      playerListStatus: teamMember?.status?.playerSchool,
      teamFormInfo: teamDraft,
    }));

  const [getPlayerProfile, setPlayerProfile] = useState(accountInfo);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>(playerInvited ?? []);
  const [openLoader, setOpenLoader] = useState(false);
  const [searchPlayerList, setSearchPlayerList] = useState('');
  const [playerListResult, setPlayerListResult] = useState<any>();
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [toastErrorMessages, setToastErrorMessages] = useState<any>('');
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const handleFetchAccountProfile = () => {
    thunkDispatch(
      fetchPlayerProfile({
        username: playerInfo.userName,
      }),
    )
      .unwrap()
      .then((res: any) => {
        res && setPlayerProfile(res);
      });
  };

  const handleFetchPlayerList = (
    schoolId: string,
    gameId: string,
    pI: number,
    pS: number,
    search?: string,
  ) => {
    thunkDispatch(
      fetchPlayerSchool({
        schoolId: schoolId,
        gameId: gameId,
        pageIndex: pI,
        pageSize: pS,
        search: search,
      }),
    )
      .unwrap()
      .then(_res => {
        setOpenLoader(false);
      });
  };

  const maxChoosePlayer = game?.maxMember - 1;
  const minLimitPlayer = game?.minMember ? game?.minMember - 1 : 0;

  const handleAddPlayerClick = (player: Player) => {
    const isChecked = !selectedPlayers.find(e => {
      return e.id === player.id;
    });
    let newData: Player[] = [];
    if (selectedPlayers?.length < maxChoosePlayer) {
      if (isChecked) {
        newData = [...selectedPlayers];
        if (
          !newData.find(e => {
            return e.id === player.id;
          })
        ) {
          newData.push(player);
        } else {
          newData = selectedPlayers.filter(el => el.id !== player.id);
        }
      } else {
        newData = selectedPlayers.filter(el => el.id !== player.id);
      }
      setSelectedPlayers(newData);
    } else {
      if (!isChecked) {
        newData = [...selectedPlayers];
        if (
          newData.find(e => {
            return e.id === player.id;
          })
        ) {
          newData = selectedPlayers.filter(el => el.id !== player.id);
          setSelectedPlayers(newData);
        }
      }
    }
  };

  const checkPlayerState = (playerSelected: any) => {
    if (selectedPlayers?.length >= maxChoosePlayer) {
      return selectedPlayers?.find(e => {
        return e.id === playerSelected.id;
      })
        ? false
        : true;
    } else {
      return false;
    }
  };

  const handleCloseToast: any = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorToast(false);
  };

  const parseError = (errors: any) => {
    if (errors) {
      let errorsList: string[] = [];
      Object.values(errors).forEach((value: any) => {
        if (value[0].code == 'EM1022') {
          errorsList = errorsList.concat(t('whatsapp_format_opponent'));
        } else {
          errorsList = errorsList.concat(value[0].message);
        }
      });
      return errorsList.join('\n');
    }
    return null;
  };

  const handleSearch = (value: string) => {
    setSearchPlayerList(value);
  };

  const searchResult = (search: string) =>
    (playerList?.items ?? []).filter(match => {
      const searchName = match.name ?? '';
      const searchNickname = match.nickName ?? '';
      const searchGameID = match.gameProviderId ?? '';

      const target = `${searchName} ${searchNickname} ${searchGameID}`;

      return target.toLowerCase().includes(search.toLowerCase());
    });

  const players = searchPlayerList ? playerListResult : playerList?.items ?? [];

  const handlePlayers = (players ?? []).length > 0 ? players : null;

  const closeModalConfirmation = () => {
    setOpenModalConfirmation(false);
  };

  const handleCreateTeam = () => {
    let memberList: string[] = [getPlayerProfile?.id ?? playerInfo?.id];
    selectedPlayers?.forEach(player => {
      if (player.id !== (getPlayerProfile?.id ?? playerInfo?.id)) memberList.push(player.id);
    });
    const teamBody: TeamBody = {
      avatarFile: base64toBlob(teamFormInfo.teamLogoUrl),
      name: teamFormInfo.teamName,
      gameId: teamFormInfo.game.id,
      whatsAppGroupUrl: teamFormInfo.WhatsAppGroupUrl,
      whatsAppPhoneNumber: teamFormInfo.WhatsAppPhoneNumber,
      discordUrl: teamFormInfo.DiscordUrl,
      members: memberList,
    };

    setOpenLoader(true);
    if (selectedPlayers?.length >= minLimitPlayer) {
      if (teamBody) {
        TeamManagementService.createTeamPost(teamBody)
          .then(async res => {
            if (res.status <= 204) {
              thunkDispatch(fetchUserClaim({ userId: getPlayerProfile?.id ?? playerInfo.id ?? '' }))
                .unwrap()
                .then(res => {
                  if (res && res.status <= 204 && res.data) {
                    const currentTeam: MyCurrentTeam[] = [];
                    setTimeout(() => {
                      setOpenLoader(false);
                      res.data.forEach(v => {
                        if (v.type === 'team') {
                          currentTeam.push(JSON.parse(v.value));
                        }
                      });
                      if (currentTeam.length >= 1) {
                        Constants.encryptStorage.setItem(
                          'current_team',
                          JSON.stringify(currentTeam),
                        );
                        history.push('/my-team');
                      } else {
                        history.push('/my-team');
                      }
                      thunkDispatch(resetTeamDraftAction());
                    }, 2000);
                  } else {
                    setOpenLoader(false);
                    history.push('/my-team');
                    thunkDispatch(resetTeamDraftAction());
                  }
                })
                .catch(() => {
                  setOpenLoader(false);
                  history.push('/my-team');
                });
            }
          })
          .catch((err: AxiosError) => {
            setOpenLoader(false);
            if (err.response && err.response?.status >= 400) {
              let message = err.response.data;
              if (message.errCode) {
                message.errors = [[{ code: message.errCode }]];
              }
              console.log(message);
              setToastErrorMessages(parseError(message.errors));
              setOpenErrorToast(true);
            }
          });
      }
    } else {
      setOpenLoader(false);
      setOpenModalConfirmation(true);
    }
  };

  useEffect(() => {
    handleFetchAccountProfile();
    setOpenLoader(true);
    if (getPlayerProfile && accountInfo)
      handleFetchPlayerList(getPlayerProfile.school.id ?? accountInfo.school.id, game.id, 1, 200);
  }, [thunkDispatch]);

  useEffect(() => {
    if (searchPlayerList) setPlayerListResult(searchResult(searchPlayerList));
  }, [searchPlayerList, playerList]);

  useEffect(() => {
    setValue({
      pageTitle: 'Add Member',
      background: { color: '#0A0A0B' },
      appBar: {
        logo: false,
        title: (
          <Stack direction="column" sx={{ textAlign: 'center' }}>
            <Text variant="h3" sx={{ fontWeight: 700, letterSpacing: '0.12px' }}>
              Tambah Anggota
            </Text>
            <Text variant="subtitle" sx={{ color: 'neutral400', mt: '2px' }}>
              Step 2 dari 2
            </Text>
          </Stack>
        ),
        back: { to: '/create-team' },
        separator: true,
      },
      padding: '0',
      bottomBar: {
        show: false,
      },
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          p: '16px',
          maxHeight: height! - (56 + 32 + 61),
          overflow: 'auto',
        }}
      >
        <Box
          sx={theme => ({
            bgcolor: 'neutral700',
            borderRadius: '12px',
            border: `1px solid ${theme.palette.neutral550}`,
          })}
        >
          <Box sx={{ p: '12px 16px' }}>
            <Text variant="h3" mb="8px">
              Undang Player Untuk Bergabung
            </Text>
            <Text sx={{ mb: '16px', width: '85%', color: 'neutral400' }}>
              {game?.minMember
                ? `Undang minimal ${minLimitPlayer} player. Player yang tersedia hanya yang berasal dari sekolah yang sama.`
                : 'Pilih player dan kirim undangan untuk bergabung dengan tim mu'}
            </Text>
            <TextInputSearch
              placeholder="Cari player"
              onChange={value => handleSearch(value)}
              bgcolor="neutral600"
            />
          </Box>
          {playerListStatus === 'succeeded' && playerList && (
            <Stack
              direction="column"
              sx={theme => ({
                borderTop: `1px solid ${theme.palette.neutral530}`,
              })}
            >
              {handlePlayers ? (
                handlePlayers?.map((data: any) => {
                  return (
                    <PlayerCard
                      playerName={data.name}
                      playerInfo={`${data.nickName} | ${data.gameProviderId}`}
                      playerPhoto={data.picture}
                      isCaptain={false}
                      sx={theme => ({
                        mb: '0px',
                        padding: '12px',
                        borderBottom: `1px solid ${theme.palette.neutral530}`,
                        opacity: checkPlayerState(data) ? '0.5' : '1',
                        cursor: checkPlayerState(data) ? 'default' : 'pointer',
                        pointerEvents: checkPlayerState(data) ? 'none' : 'auto',
                        '&:last-child': {
                          borderBottom: '0px',
                        },
                      })}
                      cta={
                        <IconButton
                          sx={{
                            p: '0px',
                            border: 'none',
                          }}
                          onClick={() => {
                            handleAddPlayerClick(data);
                          }}
                          icon={
                            selectedPlayers?.find(e => {
                              return e.id === data.id;
                            }) ?? false
                              ? IconSource('checklist.orange.active')
                              : IconSource('checkbox-empty')
                          }
                        />
                      }
                    />
                  );
                })
              ) : (
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <Text sx={{ m: '40px 0', color: '#8D8784' }}>Pencarian tidak ditemukan</Text>
                </Stack>
              )}
            </Stack>
          )}
          {playerListStatus === 'loading' && (
            <Stack justifyContent="center" alignItems="center">
              <SVG src={IconSource('loading')} />
            </Stack>
          )}
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={theme => ({
          position: 'absolute',
          bottom: '0',
          left: 0,
          width: '100%',
          minHeight: '60px',
          bgcolor: 'neutral900',
          borderTop: `1px solid ${theme.palette.neutral530}`,
        })}
      >
        <Box sx={{ pl: '16px' }}>
          <Text>Player terpilih</Text>
          <Text>{`${selectedPlayers?.length ?? 0}/${maxChoosePlayer ?? 0}`}</Text>
        </Box>
        <Box sx={{ pr: '16px' }}>
          <PrimaryButton variant="small" onClick={() => handleCreateTeam()}>
            {t('home_player_invite_player_email_submit')}
          </PrimaryButton>
        </Box>
      </Stack>

      <Toast show={openErrorToast} onClose={handleCloseToast} duration={3000}>
        <Ribbon
          label={
            <Text variant="body" sx={{ color: 'success900' }}>
              {toastErrorMessages}
            </Text>
          }
          sx={WarningStyle}
        />
      </Toast>

      <Modal show={openModalConfirmation}>
        <InfoModal
          cta={
            <SecondaryButton wide onClick={closeModalConfirmation} sx={{ mt: '8px' }}>
              Tutup
            </SecondaryButton>
          }
        >
          <Box sx={{ width: '100%' }}>
            <Text variant="h2" mb="8px">
              {t('team_create_player_not_enough')}
            </Text>
            <Text
              sx={{ color: 'neutral400' }}
            >{`Kamu bisa mengundang minimum ${minLimitPlayer} player dan maksimal ${maxChoosePlayer} player untuk melanjutkan proses pembuatan tim.`}</Text>
          </Box>
        </InfoModal>
      </Modal>

      <MUIModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLoader}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'transparent',
        }}
      >
        <Fade in={openLoader}>
          <img src={IconSource('ic-loading')} style={{ position: 'relative', outline: 0 }} />
        </Fade>
      </MUIModal>
    </>
  );
};

export default ChoosePlayerPage;
