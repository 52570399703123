import HomeHeader from './home.header';
import HomePageRegister from './home.register';
import HomePageTeam from './home.team';
import SocialMediaRowButton from './home.social';
import ModalInvitation from './navigation/modalInvitation';
import { Stack } from '@mui/material';
import BackgroundImage from 'components/layouts/BackgroundImage';
import { TabButtonType } from 'components/layouts/TabButton';
import TabMenu from 'components/layouts/TabMenu';
import { LayoutContext } from 'context/LayoutContext';
import { ImageSource } from 'modules/assetpath';
import history from 'modules/history';
import ScrimUpcomingAccount from 'pages/Account/navigation/scrimUpcoming';
import TournamentHistoryAccount from 'pages/Account/navigation/tournamentHistory';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerUpcomingScrim } from 'redux/account/AccountResponse';
import { fetchPlayerScrimHistory } from 'redux/account/AccountServices';
import { useAppSelector } from 'redux/hooks';
import { getCountUnreadNotification } from 'redux/notification/NotificationReducer';
import { getScrimDetailUpcoming } from 'redux/scrim/match.details/ScrimMatchDetailReducer';
import { useAppThunkDispatch } from 'redux/store';
import AuthService from 'services/AuthService';

const DataTab: TabButtonType[] = [
  {
    id: 1,
    title: 'Turnamen',
    isSelected: true,
  },
  {
    id: 2,
    title: 'Scrim',
    isSelected: false,
  },
];

const HomePage = () => {
  const { t } = useTranslation();
  const auth = new AuthService();
  const isAuthenticated = auth.isAuthenticated();
  const dispatch = useAppThunkDispatch();

  const [haveNotifUnread, setHaveNotifUnread] = useState(false);
  const [getTabAccount, setTabAccount] = useState(false);
  const [getScrimUpcoming, setScrimUpcoming] = useState<PlayerUpcomingScrim[]>([]);

  const { playerInfo } = useAppSelector(({ account }) => ({
    playerInfo: account.data,
    // playerScrimHisory: account.playerScrimHisory,
    // ScrimUpcoming: scrimMatchDetail.data,
    // scrimHistoryList: account.playerScrimHisory,
    // isRegionLoading: account.status === 'loading' ? true : false,
  }));

  const handleCountNotification = () => {
    if (playerInfo) {
      dispatch(
        getCountUnreadNotification({
          id: playerInfo.id,
        }),
      )
        .unwrap()
        .then(res => {
          if (res && res.status == 200 && res.data.count > 0) {
            setHaveNotifUnread(true);
          } else {
            setHaveNotifUnread(false);
          }
        });
    }
  };

  const handleFetchPlayerScrimHistory = () => {
    if (playerInfo) {
      dispatch(
        fetchPlayerScrimHistory({
          playerSlug: playerInfo?.userName,
          pageIndex: 1,
          pageSize: 20,
        }),
      );
    }
  };

  const handleFetchScrimDetailUpcoming = () => {
    if (playerInfo) {
      dispatch(getScrimDetailUpcoming({ pageIndex: '1', pageSize: '20' }))
        .unwrap()
        .then(res => {
          if (res && res.data) {
            setScrimUpcoming(res.data?.items);
          }
        });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleCountNotification();
      handleFetchPlayerScrimHistory();
      handleFetchScrimDetailUpcoming();
    }
  }, [playerInfo]);

  const { setValue } = useContext(LayoutContext);

  useEffect(() => {
    setValue({
      pageTitle: t('mabar_page_title', { title: 'Home' }),
      background: { image: ImageSource('app.background', 'png') },
      appBar: {
        logo: true,
        notification: {
          show: isAuthenticated,
          haveNotifUnread: haveNotifUnread,
          onClick: () => history.push('/notification'),
        },
       // sponsor: '',
      },
      padding: '6px 16px 16px 16px',
      bottomBar: {
        show: true,
      },
    });
  }, [haveNotifUnread]);

  return (
    <>
      <HomeHeader />
      {isAuthenticated ? <ModalInvitation /> : <HomePageRegister />}
      {isAuthenticated && (
        <>
          <HomePageTeam />
          <BackgroundImage src={ImageSource('home-bg-history-scrim')} backgroundColor="">
            <TabMenu
              data={DataTab}
              variant="left"
              shadowColor="none"
              backgroundColor="transparent"
              onSelected={tab => {
                switch (tab.id) {
                  case 1:
                    return setTabAccount(false);
                  case 2:
                    return setTabAccount(true);
                  default:
                    return undefined;
                }
              }}
            />
            <Stack
              direction="column"
              sx={{ position: 'relative', width: '100%', m: '0px 0px 24px' }}
            >
              {!getTabAccount ? (
                <TournamentHistoryAccount />
              ) : (
                <ScrimUpcomingAccount playerScrimUpcoming={getScrimUpcoming} />
              )}
            </Stack>
          </BackgroundImage>
        </>
      )}
      <SocialMediaRowButton/>
    </>
  );
};

export default HomePage;
