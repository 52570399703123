import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import { Team } from 'types/team';
import { FluentValidationProblemDetails } from './TeamInvitationReducer';

export interface TeamSchoolResponse {
  pageIndex?: number;
  pageSize?: number;
  count?: number;
  totalCount?: number;
  totalPages?: number;
  items: TeamSchool[];
}

export interface TeamSchool {
  id: string;
  name: string;
  urlSlug: string;
  avatarUrl: string;
  game: Game;
  captain: string;
  memberCount: number;
  matchCount: number;
}

export interface Game {
  id: string;
  name: string;
  urlSlug: string;
}

export interface TeamListState {
  teamSchool: TeamSchoolResponse;
  loading: boolean;
  errors: FluentValidationProblemDetails;
  errorCode: number;
  status: string | null;
  error: string | undefined | null;
  items?: TeamSchool[] | Team[];
}

const initialState: TeamListState = {
  teamSchool: {
    pageIndex: 0,
    pageSize: 0,
    count: 0,
    totalCount: 0,
    totalPages: 0,
    items: [],
  },
  loading: false,
  errors: {},
  errorCode: 0,
  error: null,
  status: null,
};

export const fetchTeamList = createAsyncThunk(
  'team/team-list',
  async ({
    pageIndex,
    pageSize,
    version,
  }: {
    version?: string;
    pageIndex: number;
    pageSize: number;
  }) => {
    const url = `/${version ? `v${version}/` : ''}team/${pageIndex}/${pageSize}`;
    const res = await ApiService.get(url);
    return res.data;
  },
);

export const fetchTeamChallangeList = createAsyncThunk(
  'team/team-challange-list',
  async ({
    pageIndex,
    pageSize,
    version,
    search,
    gameId,
    matchTime,
  }: {
    version?: string;
    search?: string;
    gameId?: string;
    matchTime?: String;
    pageIndex: number;
    pageSize: number;
  }) => {
    try {
      const url = `/${
        version ? `v${version}/` : ''
      }scrim/challenged-team/${pageIndex}/${pageSize}?matchTime=${matchTime}&gameId=${gameId}${
        search ? `&search=${search}` : ''
      }`;
      const res = await ApiService.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, errors: error.response.data };
      }
    }
  },
);

export const fetchTeamSuggestionList = createAsyncThunk(
  'team/team-suggestion-list',
  async ({
    pageIndex,
    pageSize,
    version,
    search,
    username
  }: {
    version?: string;
    search?: string;
    username?: string;
    pageIndex: number;
    pageSize: number;
  }) => {
    try {
      const url = `/${
        version ? `v${version}/` : ''
      }user/player/team-suggestion/${username}/${pageIndex}/${pageSize}${
        search ? `?search=${search}` : ''
      }`;
      const res = await ApiService.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return { isAxiosError: true, errors: error.response.data };
      }else{
        return { isAxiosError: true, errors: error };
      }
    }
  },
);

const TeamListSlice = createSlice({
  name: 'TeamList',
  initialState,
  reducers: {
    getTeamSchoolListAction: (
      state,
      action: PayloadAction<{
        idSchool: string;
        pageIndex: number;
        pageSize: number;
        search?: string;
        version?: string;
      }>,
    ) => {
      state.loading = true;
      state.teamSchool = {
        ...state.teamSchool,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
      };
    },
    successTeamSchoolListAction: (
      state,
      action: PayloadAction<{ data: TeamSchoolResponse; code: number }>,
    ) => {
      state.errorCode = action.payload.code;
      state.teamSchool = action.payload.data;
      state.loading = false;
    },
    failedTeamSchoolListAction: (state, action) => {
      state.loading = false;
      if (action.payload.errors !== undefined) {
        state.errors = action.payload.errors;
        state.errorCode = state.errors.status!!;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamList.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchTeamList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload as Team[];;
      })
      .addCase(fetchTeamList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.items = undefined;
      })
      .addCase(fetchTeamChallangeList.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchTeamChallangeList.fulfilled, (state, action) => {
        if (action.payload?.isAxiosError && action.payload.errors) {
          state.status = 'failed';
          state.errors = action.payload.errors;
        } else {
          state.status = 'succeeded';
          state.items = action.payload as Team[];
        }
      })
      .addCase(fetchTeamChallangeList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.items = undefined;
      })
      .addCase(fetchTeamSuggestionList.pending, (state, _action) => {
        state.status = 'loading';
      })
      .addCase(fetchTeamSuggestionList.fulfilled, (state, action) => {
        if (action.payload?.isAxiosError && action.payload.errors) {
          state.status = 'failed';
          state.errors = action.payload.errors;
        } else {
          state.status = 'succeeded';
          state.teamSchool = action.payload as TeamSchoolResponse;
        }
      })
      .addCase(fetchTeamSuggestionList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.items = undefined;
      });
  },
});

export const { getTeamSchoolListAction, successTeamSchoolListAction, failedTeamSchoolListAction } =
  TeamListSlice.actions;

export default TeamListSlice.reducer;
