import { getGameNewGameListProps, addNewGameInformationProps, EditAccountProfileProps, UpdateCompleteDataProps } from './AccountProps';
import { AccountPagedResponse, AccountProfile, NewGameItem, PlayerScrimHistory, UserClaim } from './AccountResponse';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestHeaders } from 'axios';
import ApiService from 'services/ApiService';
import { Constants } from 'services/Constants';


export async function getProfileByUsername(
  username: string,
  version?: string,
  customHeader?: AxiosRequestHeaders,
) {
  const apiUrl = `/${version ? `v${version}/` : ''}user/player/profile/${username}`;
  return await ApiService.get<AccountProfile>(apiUrl, { headers: customHeader });
}

export function getPlayerProfileFromSession(): AccountProfile {
  const json = Constants.encryptStorage.getItem('player_profile_info');
  if (json && json.startsWith('{', 0)) {
    return JSON.parse(json ? json : '{}') as AccountProfile;
  }
  return JSON.parse('{}');
}

export const fetchPlayerProfile = createAsyncThunk(
  'account/player-profile',
  async ({
    username,
    version,
    accessToken,
  }: {
    username?: string;
    version?: string;
    accessToken?: string;
  }) => {
    const url = `/${version ? `v${version}/` : ''}user/player/profile/${username}`;
    let config = {} as AxiosRequestHeaders;
    if (accessToken) {
      config = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    const res = await ApiService.get<AccountProfile>(url, { headers: config });
    return res.data;
  },
);

export const fetchGameAvailableList = createAsyncThunk(
  'account/game-available',
  async ({ pageIndex, pageSize, version }: getGameNewGameListProps) => {
    const url = `/${version ? `v${version}/` : ''}game/options/${pageIndex}/${pageSize}`;

    const res = await ApiService.get<AccountPagedResponse<NewGameItem[]>>(url);
    return res.data;
  },
);

export const addNewGameInformation = createAsyncThunk(
  'account/add-game',
  async ({
    userId,
    gameId,
    gameProviderId,
    nickName,
    nickNameFile,
    version,
  }: addNewGameInformationProps) => {
    try {
      var url = encodeURI(
        version ? `/v${version}/user/player/game/${userId}` : `/user/player/game/${userId}`,
      );
      var formData = new FormData();
      formData.append('Id', userId);
      formData.append('Game.GameId', gameId);
      formData.append('Game.GameProviderId', gameProviderId);
      formData.append('Game.NickName', nickName);
      formData.append('Game.NickNameFile', nickNameFile);
      const res = await ApiService.put(url, formData);
      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);

export const fetchPlayerScrimHistory = createAsyncThunk(
  'account/player-scrim-history',
  async ({ playerSlug, pageIndex, pageSize, search, version }: getGameNewGameListProps) => {
    const url = `/${
      version ? `v${version}/` : ''
    }scrim/player-history/${playerSlug}/${pageIndex}/${pageSize}${
      search ? `?search=${search}` : ''
    }`;
    const res = await ApiService.get<AccountPagedResponse<PlayerScrimHistory[]>>(url);
    return res.data;
  },
);

export const editAccountProfile = createAsyncThunk(
  'account/edit-profile',
  async ({
    Id,
    Picture,
    Generation,
    StudentIdNumber,
    StudentCardFile,
    version,
  }: EditAccountProfileProps) => {
    try {
      var url = decodeURI(
        version ? `/v${version}/user/player/profile/${Id}` : `/user/player/profile/${Id}`,
      );
      var formData = new FormData();
      var isUpdate = false;
      
      if (Picture) { formData.append('Picture', Picture); isUpdate=true; }
      //if (Email) { formData.append('Email', Email); isUpdate=true; }
      //if (PhoneNumber) { formData.append('PhoneNumber', PhoneNumber);isUpdate=true; }
      if(Generation) { formData.append('Generation', Generation?.toString()); isUpdate=true; }
      if(StudentIdNumber) { formData.append('StudentIdNumber', StudentIdNumber); isUpdate=true; }
      if(StudentCardFile) { formData.append('StudentCardFile', StudentCardFile);isUpdate=true; }
    
      if(isUpdate ) {
        formData.append('Id', Id);
        const res = await ApiService.put(url, formData);
        return res.data;
      }
      return "";
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);

export const updateCompleteData = createAsyncThunk(
  'complete-data',
  async ({
    id,
    name,
    emailOrPhoneNumber,
    provinceCode,
    cityCode,
    schoolId,
    generation,
    studentIdNumber,
    studentCardFile,
    gameId,
    gameProviderId,
    nickName,
    nickNameFile,
  }: UpdateCompleteDataProps) => {
    try {
      var url = `/user/next-register/player/${id}`;
      var formData = new FormData();
      formData.append('Id', id);
      formData.append('Name', name);
      formData.append('EmailOrPhoneNumber', emailOrPhoneNumber);
      formData.append('ProvinceCode', provinceCode);
      formData.append('CityCode', cityCode);
      formData.append('SchoolId', schoolId);
      formData.append('Generation', generation.toString());
      formData.append('StudentIdNumber', studentIdNumber);
      formData.append('StudentCardFile', studentCardFile);
      formData.append('Game.GameId', gameId);
      formData.append('Game.GameProviderId', gameProviderId);
      formData.append('Game.NickName', nickName);
      formData.append('Game.NickNameFile', nickNameFile);

      const res = await ApiService.put(url, formData);
      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);

export const changeEmail = createAsyncThunk(
  'account/change-email',
  async ({
    currentEmail,
    newEmail,
    version,
  }: {
    currentEmail?: string;
    newEmail?: string;
    version?: string;
  }) => {
    try {
      var url = version
        ? `/v${version}/identity-api/user/change/email`
        : `/identity-api/user/change/email`;
      const res = await ApiService.post(url, {
        currentEmail: currentEmail,
        newEmail: newEmail,
      });
      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);

export const verifyEmail = createAsyncThunk(
  'account/verify-email',
  async ({ newEmail, token, version }: { newEmail: string; token: number; version?: string }) => {
    try {
      var url = version
        ? `/v${version}/identity-api/user/verify/email`
        : `/identity-api/user/verify/email`;
      const res = await ApiService.post(url, {
        newEmail: newEmail,
        token: token,
      });
      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);

export const changePhoneNumber = createAsyncThunk(
  'account/change-phone-number',
  async ({
    currentPhoneNumber,
    newPhoneNumber,
    version,
  }: {
    currentPhoneNumber: string;
    newPhoneNumber: string;
    version?: string;
  }) => {
    try {
      var url = version
        ? `/v${version}/identity-api/user/change/phone-number`
        : `/identity-api/user/change/phone-number`;
      const res = await ApiService.post(url, {
        currentPhoneNumber: currentPhoneNumber,
        newPhoneNumber: newPhoneNumber,
      });
      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);

export const verifyPhoneNumber = createAsyncThunk(
  'account/verify-phone-number',
  async ({
    newPhoneNumber,
    token,
    version,
  }: {
    newPhoneNumber: string;
    token: number;
    version?: string;
  }) => {
    try {
      var url = version
        ? `/v${version}/identity-api/user/verify/phone-number`
        : `/identity-api/user/verify/phone-number`;
      const res = await ApiService.post(url, {
        newPhoneNumber: newPhoneNumber,
        token: token,
      });
      return res.data;
    } catch (err: any) {
      if (err.response) {
        return { isAxiosError: true, data: err.response.data };
      }
    }
  },
);

export const fetchUserClaim = createAsyncThunk(
  'account/user-claim',
  async ({ userId, version }: { userId: string; version?: string }) => {
    const url = `/${version ? `v${version}/` : ''}user/claim/${userId}`;
    const res = await ApiService.get<UserClaim[]>(url);
    return res;
  },
);

export const changePassword = createAsyncThunk(
  'account/change-password',
  async ({
    userId,
    currentPassword,
    newPassword,
    version,
  }: {
    userId: string;
    currentPassword: string;
    newPassword: string;
    version?: string;
  }) => {
    try {
      var url = version
        ? `/v${version}/identity-api/user/change/password`
        : `/identity-api/user/change/password`;
      const res = await ApiService.post(url, {
        userId: userId,
        currentPassword: currentPassword,
        newPassword: newPassword,
      });
      return res.data;
    } catch (err: any) {
      const addCurrentPasswordData = (data: { status: Number; errors: Object }) => ({
        ...data,
        errors: {
          ...data.errors,
          ...{
            currentPassword: [
              {
                code: '',
                message: '',
              },
            ],
          },
        },
      });

      if (err.response) {
        return {
          isAxiosError: true,
          data:
            err.response.data.status !== 200 && currentPassword
              ? addCurrentPasswordData(err.response.data)
              : err.response.data,
        };
      }
    }
  },
);