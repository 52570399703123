import { Theme, Box, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { FramePhoto } from 'design/Avatars/FramePhoto';
import { Text } from 'design/Text';
import { IconSource } from 'modules/assetpath';
import React, { ReactNode } from 'react';

export interface CardHeaderAccountProps {
  fullname: string;
  description: string;
  photo: string;
  frame?: {
    image?: string;
    text?: string | ReactNode;
  };
  cta: ReactNode;
  sx?: SxProps<Theme>;
}

const CardBg = () => {
  const triangleWrapper: any = (theme: Theme) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    borderRadius: theme.borderRadius.md,
    overflow: 'hidden',
  });

  const triangle = {
    content: "''",
    position: 'absolute',
    width: '60%',
    height: '100%',
    background:
      'radial-gradient(75% 90.8% at 0% 46.84%, rgba(16, 18, 22, 0.9) 0%, rgba(16, 18, 22, 0) 100%)',
    clipPath: 'polygon(0px 0px, 0% 100%, 100% 100%)',
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: '147.5px',
          height: '14px',
          top: 0,
          m: 'auto',
          bgcolor: 'neutral800',
          clipPath: 'polygon(12% 14px, 88% 14px, 100% 8%, 0px 8%)',
          opacity: 0.6,
        }}
      />
      <Box
        sx={theme => ({
          ...triangleWrapper(theme),
          ':before': {
            ...triangle,
            left: 0,
          },
          ':after': {
            ...triangle,
            right: 0,
            transform: 'scaleX(-1)',
          },
        })}
      />
      <Box
        sx={theme => ({
          ...triangleWrapper(theme),
          ':before': {
            ...triangle,
            top: '35%',
            left: 0,
            mixBlendMode: 'difference',
            opacity: 0.2,
          },
          ':after': {
            ...triangle,
            top: '35%',
            right: 0,
            transform: 'scaleX(-1)',
            mixBlendMode: 'difference',
            opacity: 0.2,
          },
        })}
      />
    </>
  );
};

export const CardHeaderAccount = ({
  fullname,
  description,
  photo,
  frame,
  cta,
  sx,
}: CardHeaderAccountProps) => {
  return (
    <Stack sx={sx}>
      <Stack
        alignItems="center"
        sx={theme => ({
          position: 'relative',
          bgcolor: 'neutral700',
          borderRadius: theme.borderRadius.md,
          p: '0 16px 16px 16px',
          mt: '115px',
          filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.48))',
          ':before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            inset: 0,
            background: `${theme.palette.neutral700} url(${IconSource(
              'grunge',
              'png',
            )}) top / contain`,
            borderRadius: theme.borderRadius.md,
            opacity: 0.15,
          },
        })}
      >
        <CardBg />
        <Stack
          alignItems="center"
          sx={{
            position: 'absolute',
            transform: 'translate(0%, -58%)',
          }}
        >
          <FramePhoto
            frameImage={frame?.image}
            frameText={frame?.text}
            image={photo}
            sx={{
              width: frame ? '121px' : '110px',
              height: frame ? '129px' : '110px',
              backgroundSize: 'contain',
              backgroundPosition: 'center top',
              img: {
                width: frame ? '93.3px' : 'inherit',
                height: frame ? '93.3px' : 'inherit',
                filter: 'drop-shadow(0px 2.33333px 2.66667px #181818)',
                borderRadius: '100%',
              },
            }}
            sxTag={{
              height: '18px',
              bottom: '6px',
              p: '0 8px',
              '&:before': {
                borderRadius: '5px',
                borderTopWidth: '2px',
                transform: 'perspective(55px) rotateX(15deg)',
              },
              label: { fontSize: '13.33px', mt: '2px' },
              div: { height: '10px' },
            }}
          />
        </Stack>
        <Text
          variant="h3"
          sx={{ position: 'relative', mt: '61px', mb: '6px', textAlign: 'center' }}
        >
          {fullname}
        </Text>
        <Text
          variant="body"
          sx={{ color: 'neutral300', position: 'relative', mb: '12px', textAlign: 'center' }}
        >
          {description}
        </Text>
        {cta}
      </Stack>
    </Stack>
  );
};
