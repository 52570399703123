import { Button } from 'components/styled/button.sytled';
import { NormalText } from 'components/styled/text.styled';
import React, { FC } from 'react';

export interface TabButtonType {
  id?: number | undefined;
  title?: string | undefined;
  isSelected?: boolean | undefined;
  onClickButton?: (() => void) | undefined;
}

const TabButton: FC<TabButtonType> = ({ title, isSelected, onClickButton }) => {
  return (
    <Button
      onClick={() => {
        if (onClickButton) {
          onClickButton();
        }
      }}
      background={isSelected ? 'rgba(55, 57, 66, 0.4)' : 'none'}
      borderRadius="8px"
      padding="8px 16px"
    >
      {isSelected ? (
        <NormalText
          fontSize="14px"
          fontWeight="700"
          color="#F3AA36"
        >
          {title}
        </NormalText>
      ) : (
        <NormalText fontSize="14px" fontWeight="700" color="rgba(106, 99, 99, 1)">
          {title}
        </NormalText>
      )}
    </Button>
  );
};

export default TabButton;
