import styled from 'styled-components';

export const HeaderAccountContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0px;
  margin: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface ImageProfileProps {
  width: string;
  height: string;
  fit?: string | undefined;
  borderRadius?: string | undefined;
}

interface ProfileTextProps {
  fontSize?: string | undefined;
  fontWeight?: string | undefined;
  fontStyle?: string | undefined;
  color?: string | undefined;
  padding?: string | undefined;
  margin?: string | undefined;
}

export const ProfileText = styled.p<ProfileTextProps>`
  font-size: ${p => p.fontSize};
  font-weight: ${p => p.fontWeight};
  font-style: ${p => p.fontStyle};
  font-family: 'Roboto';
  color: ${p => p.color};
  padding: ${p => p.padding ?? '0px'};
  margin: ${p => p.margin ?? '0px'};
`;

export const ImageProfile = styled.img<ImageProfileProps>`
  width: ${p => p.width};
  height: ${p => p.height};
  border-radius: ${p => p.borderRadius};
  object-fit: ${p => p.fit};
`;

export const PhotoProfileBackground = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 42px 16px 52px 16px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: linear-gradient(
    131.42deg,
    #d51f40 16.85%,
    rgba(234, 88, 44, 0.297578) 48.91%,
    rgba(240, 105, 38, 0.0853196) 69.28%,
    rgba(242, 109, 37, 0.0418083) 71.77%,
    rgba(243, 112, 36, 0) 82.91%
  );
  filter: drop-shadow(-10px 10px 40px rgba(213, 31, 64, 0.26));
  transform: rotate(-90deg);
`;

export const PhotoProfileInnerBackground = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(55, 57, 66, 0.94);
  width: 140px;
  height: 140px;
  border-radius: 50%;
  transform: rotate(90deg);
`;

export const ProfileInnerGradient = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: linear-gradient(121.41deg, #e04763 53.52%, #f5894a 93.54%);
  box-shadow: -6px 10px 18px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
`;

interface PhotoProfileInnerBorderProps {
  isEmpty?: boolean | undefined;
}

export const PhotoProfileInnerBorder = styled.div<PhotoProfileInnerBorderProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 122px;
  z-index: 1;
  height: 122px;
  border: ${p => (p.isEmpty ? '1px dashed #969393' : 'none')};
  border-radius: 50%;
`;

export const IcProfileBackground = styled.div`
  background: rgba(55, 57, 66, 0.6);
  border-radius: 6px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
